// @ts-nocheck
import React from "react";
import custom from "../assets/icons/Social_icons/add.svg";
import website from "../assets/icons/Social_icons/button_website_icon.svg";
import call_icon from "../assets/icons/Social_icons/button_phone_icon.svg";
import WhatsApp from "../assets/icons/Social_icons/button_wp_icon.svg";
import map from "../assets/icons/Social_icons/button_map_icon.svg";
import instagram from "../assets/icons/Social_icons/button_insta_icon.svg";
import facebook from "../assets/icons/Social_icons/button_fb_icon.svg";
import linkedin from "../assets/icons/Social_icons/button_linkedin_icon.svg";
import twitter from "../assets/icons/Social_icons/button_twitter_icon.svg";
import youtube from "../assets/icons/Social_icons/button_yt_icon.svg";
import snapchat from "../assets/icons/Social_icons/button_snapChat_icon.svg";
import thread from "../assets/icons/Social_icons/button_thread_icon.svg";
import spotify from "../assets/icons/Social_icons/button_spotify_icon.svg";
import pinterest from "../assets/icons/Social_icons/button_pinterest_icon.svg";
import clubhouse from "../assets/icons/Social_icons/button_clubhouse_icon.svg";
import podcast from "../assets/icons/Social_icons/button_podcast_icon.svg";
import soundcloud from "../assets/icons/Social_icons/button_soundcloud_icon.svg";
import tiktok from "../assets/icons/Social_icons/button_tiktok_icon.svg";
import twitch from "../assets/icons/Social_icons/button_twitch_icon.svg";
import music from "../assets/icons/Social_icons/button_music_icon.svg";
import book from "../assets/icons/Social_icons/button_book_icon.svg";
import nft from "../assets/icons/Social_icons/button_nft_icon.svg";
import event_image from "../assets/icons/Social_icons/event_image.svg";

const businessUserName = localStorage.getItem("RestrauntName");

export const event = {
  eventname: "DJ Night by Stephen",
  eventdate: "09 May",
  eventtime: "8:00 PM onwards",
  eventage: "21+",
  eventposter: event_image,
};
const event1 = {
  eventname: "Live music",
  eventdate: "10 May",
  eventtime: "8:00 PM onwards",
  eventage: "21+",
  eventposter: event_image,
};
export const event_lists = [event, event1, event];
export const lists = ["Our Menu", "Connect on whatsapp", "Reserve your table"];
export const header = {
  hotelimage: "https://ironhillindia.com/images/iron-hill-100.png",
  hotelname: "Iron Hill Bengaluru",
  hotelType: "Bar,Pub,Brewery",
  user: "ironhill.bengaluru",
  likes: 0,
  description:
    "United by craft is the one gospel that keeps our taps flowing. We are on a mission to unite India, unite cities and unite people by a brand new signature craft culture. A culture so bold and leaps, it doesn’t weave stories, it weaves legends.",
  announcement:
    "This is a very important announcement, in case of festive ugadi tomorrow. we will not be serving liquor.kkkkkkkkkkkkkkkkkkkkkkkkkkkkkk;f salkdfhsdjlf hsdnljfhsjflkshdafjlksdlfkjsdfl aslkdjf sdlkafj slkdafj salkdfj sadokfl jasdflsadjfklsadjflksa;d fjkal",
};
const header1 = {
  hotelimage: "https://ironhillindia.com/images/iron-hill-100.png",
  hotelname: "Iron Hill",
  hotelType: "Place,Tourist Place",
  user: "ironhill",
  likes: 0,
  description:
    "United by craft is the one gospel that keeps our taps flowing. We are on a mission to unite India, unite cities and unite people by a brand new signature craft culture. A culture so bold and leaps, it doesn’t weave stories, it weaves legends.",
};

export const search = [header, header1, header, header1, header];
export const like = [header, header1, header, header1, header];

export const userOptions = [
  {
    option: "Bookings",
    route: "/my-bookings",
  },
  {
    option: "All Social Profiles",
    route: "/allprofiles",
  },
  // {
  //   option:"Help"
  // },
  {
    option: "Connect with eatoes customer care",
    route: "/contact",
  },
  // {
  //   option:"About Eatoes"
  // }
];

export const heading = [
  {
    option: "Announcement",
    route: "Announcement",
  },
  {
    option: "Bio",
    route: "Bio",
  },
  {
    option: "External Links",
    route: "Links",
  },
  {
    option: "Photos",
    route: "Photos",
  },
  {
    option: "Events",
    route: "Updatesevents",
  },
  {
    option: "Contacts Details",
    route: "Contactsdetails",
  },
];

export const ExtraSetting = [
  {
    option: "Change Prices",
    route: "Changeprices",
  },
  {
    option: "Visiblity Timing",
    route: "Visibilitytimings",
  },
];
export const profile = [
  {
    option: "Edit Profile",
    route: "Profilesetting",
  },
  {
    option: "Check User Access",
    route: "Profileaccess",
  },
];
export const social = [
  {
    option: "Social Profile",
    route: "socialprofile",
  },
  {
    option: "Digital Menu",
    route: `edit/menu/${businessUserName}`,
  },
  // {
  //   option: "Google table reservation",
  //   route: "tablereservation"
  // },
  // {
  //   option: "Whatsapp api",
  //   route: "whatsappapi"
  // },
];
export const media = [{ option: "Ambience" }, { option: "Food" }];
export const tnc = [
  { option: "No person will be allowed without a  ticket in advanced" },
  { option: "No two deals can be clubbed together" },
  { option: "Ticket once book cannot be exchange or refunded" },
  { option: "Rights of admisson reserved" },
];
export const buttons = [
  {
    image: custom,
    option: "Add link",
    button_index: 0,
  },
  {
    image: custom,
    option: "Custom",
    button_index: 1,
  },
  {
    image: website,
    option: "Website",
    button_index: 2,
  },
  {
    image: call_icon,
    option: "Call",
    button_index: 3,
  },
  {
    image: WhatsApp,
    option: "WhatsApp",
    button_index: 4,
  },
  {
    image: map,
    option: "Google Map",
    button_index: 5,
  },
  {
    image: instagram,
    option: "Instagram",
    button_index: 6,
  },
  {
    image: facebook,
    option: "Facebook",
    button_index: 7,
  },
  {
    image: linkedin,
    option: "LinkedIn",
    button_index: 8,
  },
  {
    image: twitter,
    option: "X",
    button_index: 9,
  },
  {
    image: youtube,
    option: "YouTube",
    button_index: 10,
  },
  {
    image: snapchat,
    option: "Snapchat",
    button_index: 11,
  },
  {
    image: thread,
    option: "Thread",
    button_index: 12,
  },
  {
    image: spotify,
    option: "Spotify",
    button_index: 13,
  },
  {
    image: pinterest,
    option: "Pinterest",
    button_index: 14,
  },
  {
    image: clubhouse,
    option: "Clubhouse",
    button_index: 15,
  },
  {
    image: podcast,
    option: "Podcast",
    button_index: 16,
  },
  {
    image: soundcloud,
    option: "Soundcloud",
    button_index: 17,
  },
  {
    image: tiktok,
    option: "Tiktok",
    button_index: 18,
  },
  {
    image: twitch,
    option: "Twitch",
    button_index: 19,
  },
  {
    image: music,
    option: "Music",
    button_index: 20,
  },
  {
    image: book,
    option: "Book",
    button_index: 21,
  },
  {
    image: nft,
    option: "NFT",
    button_index: 22,
  },
];
export const photos = [
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
  {
    Food_type: "food",
    photo: " ",
  },
];
