// @ts-nocheck

import React, { forwardRef } from "react"

export const DraggableItem = forwardRef(({ id, style, isDragging, MappedEl, isDragOverlay, isCategorySection, ...props }, ref) => {

  const inlineStyle = {
    opacity: isDragging && !isDragOverlay ? "0.3" : "1",
    backgroundColor: isDragOverlay && "rgb(253, 243, 238, 0.8)",
    ...style
  }
  return (
    <div ref={ref} className={`${isDragOverlay && "shadow-lg rounded-2"} user-select-none`} style={inlineStyle} {...props}>
      {MappedEl}
    </div>
  )
})