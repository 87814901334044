import { ORDER_APIS, ORDER_SUCCESS_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
// const baseURL = "https://api-connect.eatoes.com";

export const getOrderID = async (amount: number) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    amount: amount,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${ORDER_APIS.GET_ORDER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const orderSuccess = async (
  orderID: string,
  paymentID: string,
  signature: string,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    razorpay_payment_id: paymentID,
    razorpay_order_id: orderID,
    razorpay_signature: signature,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `${baseURL}${ORDER_SUCCESS_API.ORDER_SUCCESS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status == 200) {
      console.log("orderConfirm api response data", data);
      return data;
    } else {
      console.error("order confirm is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
