//@ts-nocheck
import React, { useEffect, useState } from "react";
import Select from "react-select";
import add from "../assets/icons/Social_icons/add.svg";
import deleteButton from "../assets/icons/Social_icons/deleteButton.svg";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IOSSwitch } from "../utils/LiveSwitchcustomization";
import "../styleSheets/AddLinksPage.css";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { customStyles } from "./react_select_styles";
import Delete from "../RestaurantMenu/Modals Layout/Delete";
import DialogBox from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateLinks } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import { GreenButton } from "../Components/Button.style";
import pencil from "../assets/icons/Social_icons/pencil.png";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const AddLinksPage = ({ buttons }) => {
  const dispatch = useDispatch();

  const api = useSelector((state) => state.fastSocial.apiData);

  const [toDeleteId, setToDeleteId] = useState({
    isFromAPI: true,
    _id: 0,
  });
  const [extractedData, setExtractedData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);

  const [dataArr, setDataArr] = useState([]);

  const [index, setIndex] = useState();

  const [selectedOption, setSelectedOption] = useState({
    value: "add-link",
    label: "Create New Link",
    // imageUrl: add,
  });
  const navigate = useNavigate();
  const [deleteState, setDeleteState] = useState(false);

  const options = [];
  buttons.forEach((el) => {
    options.push({
      value: el.option,
      label: el.option,
      imageUrl: el.image,
      isDisabled: el.option === "Add link",
    });
  });

  const handleChange = (selected) => {
    const pushedEl = {
      icon: selected.imageUrl,
      live: true,
      name: selected.value,
      url: "",
    };
    setDataArr((dataArr) => [...dataArr, pushedEl]);
  };

  const handleDelete = () => {
    const tempArr = [...dataArr]; //delete logic to be shifted in modal
    tempArr.splice(toDeleteId._id, 1);
    setDataArr(tempArr);
  };

  const handelDeleteClick = (index) => {
    setIndex(index);
    setDeleteState(true);
  };

  const handleLiveStatus = (index) => {
    const tempArr = [...dataArr];
    tempArr[index].live = !tempArr[index].live;
    setDataArr(tempArr);
  };

  const handelNameChange = (e, index) => {
    const tempArr = [...dataArr];
    tempArr[index].name = e.target.value;
    setDataArr(tempArr);
  };

  const handelUrlChange = (e, index) => {
    const tempArr = [...dataArr];
    tempArr[index].url = e.target.value;
    setDataArr(tempArr);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleOpenDialog = async () => {
    const dataReceived = await addUpdateLinks(dataArr, state);
    updateNestedRedux(
      api,
      "Links",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setExtractedData(api["Links"]);
  }, [api]);

  useEffect(() => {
    setDataArr(extractedData?.link);
  }, [extractedData]);
  console.log(extractedData);
  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/Linkspage`, "Links Page");
  }, []);

  return (
    <>
      <div className=" Socialformpage d-flex justify-content-center">
        <div className="mobile-pc-width mx-auto">
          <div className="d-flex container m-auto mb-5 social-profile-header">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <KeyboardBackspaceIcon
                className="back-arrow-click ms-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <div className="mx-auto">
                <h1 className="socio-prof-header me-3">
                  {api?.["Basic Details"]?.account?.userName} | Links
                </h1>
              </div>
            </div>
          </div>
          {/* <div
          className={`mx-4 liveradio-wrapper`}
        >
          <RadioGroup
            aria-labelledby="live-radio-buttons-group"
            value={statusState}
            name="radio-buttons-group"
            onChange={()=>setStatusState(!statusState)}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#03A884",
                    },
                    zIndex: "0"
                  }}
                />
              }
              label="Live"
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 23,
                },
              }}
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#03A884",
                    },
                    zIndex: "0"
                  }}
                />
              }
              label="Not live"
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 23,
                },
              }}
            />
          </RadioGroup>
        </div> */}

          {dataArr?.map(({ icon, name, live, url, _id }, index) => {
            return (
              <div key={`${index}-data`} className="details-wraper">
                <div className="d-flex mt-3">
                  <div className="w-100 position-relative">
                    <div className="Link-imagediv w-100 rounded-3 d-flex align-items-center">
                      <div style={{ width: "68px", height: "68px" }}>
                        <img src={icon} className="w-100 h-100 ms-2" />
                      </div>
                      <div className=" w-100">
                        <div className="ms-3 mb-2 input-div position-relative">
                          <input
                            type="text"
                            value={name}
                            style={{ border: "0px" }}
                            onChange={(e) => handelNameChange(e, index)}
                            placeholder="Title"
                          />
                          <img
                            src={pencil}
                            className="pencil-img-links1 position-absolute"
                          />
                        </div>

                        <div className="ms-3 input-div position-relative">
                          <input
                            type="text"
                            value={url}
                            style={{ border: "0px" }}
                            onChange={(e) => handelUrlChange(e, index)}
                            placeholder={`Link`}
                          />
                          <img
                            src={pencil}
                            className="pencil-img-links1 position-absolute"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-center del-live-btn">
                    <div className="">
                      <img
                        src={deleteButton}
                        className="delete-button cursor-pointer"
                        onClick={() => {
                          setDeleteState(true);
                          if (_id) setToDeleteId({ isFromAPI: true, _id: _id });
                          else setToDeleteId({ isFromAPI: false, _id: index });
                        }}
                      />
                    </div>
                    <div className="live-div">
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 3 }} />}
                        label={live ? "Live" : "Not Live"}
                        labelPlacement="bottom"
                        className="Live-switch"
                        id={`switch`}
                        checked={live}
                        onChange={() => handleLiveStatus(index)}
                      />
                    </div>
                  </div>
                </div>
                <hr className="ms-3" style={{ width: "90%" }} />
              </div>
            );
          })}

          <div className="Add-button my-4 bg-transparent ">
            <Select
              onChange={handleChange}
              value={selectedOption}
              styles={customStyles}
              options={options}
              menuShouldScrollIntoView
              getOptionLabel={(e) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    {e.imageUrl && (
                      <img
                        src={e.imageUrl}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                          marginRight: "15px",
                        }}
                      />
                    )}
                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                  </div>
                );
              }}
              isSearchable={false}
            />
          </div>
          {dataArr?.length > 0 && (
            <div
              className="d-flex justify-content-center ms-5 mt-0"
              style={{ paddingBottom: "320px" }}
            >
              <GreenButton
                backgroundColor="#7ECAB0"
                variant="contained"
                className="Link-savebutton mt-1"
                onClick={handleOpenDialog}
              >
                Save
              </GreenButton>
            </div>
          )}
        </div>
      </div>

      <Delete
        open={deleteState}
        setOpen={setDeleteState}
        type={"Link"}
        data={api}
        objId={toDeleteId}
        setIsDeleted={handleDelete}
      />
    </>
  );
};

export default AddLinksPage;
