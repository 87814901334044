import Demo from "../assets/images/ScheduleDemo.jpg";
import classes from "../styleSheets/ScheduleDemo.module.css";
import Header1 from "../Components/Header1";
import Form from "../Components/Form";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { useEffect } from "react";

const ScheduleDemo = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/ScheduleDemo`, "ScheduleDemo Page");
  }, []);

  return (
    <>
      {/* <div className={classes.background} > 
     <div >
     <Header1/>
     </div>
    <div className={classes.row}>
     <div className={classes.container}>
        <div className={classes.text1}>It’s demo time</div>
        <div className={classes.text2}>This is your gateway to the magical land of Connect by Eatoes, where we help you improve relationships 
         with your customers.
         </div>
         <div style={{marginTop:'50px'}} className={classes.img}>
         <img src={Demo} className={classes.image}/>
         </div>
     </div>
     <div className={classes.center}><Form/></div>
     </div>
     
     </div> */}
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          paddingTop: "50vh",
          margin: "10",
        }}
      >
        <h1>Call on 9634339954 for scheduling a demo</h1>
      </div>
    </>
  );
};

export default ScheduleDemo;
