//@ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import facebook_icon from "../assets/icons/Social_icons/facebook_icon.svg";
import whatsapp_icon from "../assets/icons/Social_icons/whatsapp_icon.png";
import instagram_icon from "../assets/icons/Social_icons/instagram_icon.svg";
import Popup from "./Popup"; // Adjust the path as needed
import { getCurrentDay } from "../utils/getCurrentDay";

const instagramBaseUrl = "https://www.instagram.com/";
const whatsappBaseUrl = "https://wa.me/";

export const ContactInfoSection = ({ api }) => {
  const [isOwnProfile, setIsOwnProfile] = useState(false); // Added state to determine if it's own profile
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const contactLinks = api["Contact Details"]?.links || {};

  const contactDetails = api?.["Contact Details"] || {};
  const operationalHours = api?.["Contact Details"]?.operationalHours || {};
  console.log(operationalHours);

  const currentDay = getCurrentDay(); // Get the current day

  // Extract current day's operational timings
  const todayHours = operationalHours[currentDay] || {};
  const { shift1 = {}, shift2 = {} } = todayHours.timings || {};
  const formattedTimings =
    shift1.open && shift1.close ? `${shift1.open} - ${shift1.close}` : "Closed";
  const businessId = localStorage.getItem("businessId");

  let check = true;
  const checkFollowUsButton = () => {
    if (
      contactLinks?.facebook?.url &&
      contactLinks?.instagram?.url &&
      contactLinks?.whatsapp?.url
    ) {
      check = !check;
      return check;
    } else return check;
  };

  useEffect(() => {
    const apiUsername = api["Basic Details"]?.account?.userName;
    const localStorageRestrauntName = localStorage.getItem("RestrauntName"); // Adjust the key as per your actual local storage key

    // Compare API username with the local storage username
    if (apiUsername === localStorageRestrauntName) {
      setIsOwnProfile(true);
    } else {
      setIsOwnProfile(false);
    }
    checkFollowUsButton();
    console.log(check, "-----------");
  }, [api]);

  const buttonStyle = {
    background: "transparent",
    color: "white",
    padding: "2px 6px",
    border: "1px solid white",
    cursor: "pointer",
    borderRadius: "4px",
    fontSize: "10px",
  };

  const commonTextStyle = {
    fontFamily: "Inter",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "14px",
    textAlign: "left",
  };

  const linkStyle = {
    ...commonTextStyle,
    color: "#ffffff",
    textDecoration: "none",
  };

  const arrowStyle = {
    ...commonTextStyle,
    color: "#27AE60",
    textDecoration: "none",
  };

  const labelStyle = {
    ...commonTextStyle,
    color: "#7ECAB0",
    marginRight: "10px",
  };

  const valueStyle = {
    ...commonTextStyle,
    color: "#ffffff",
  };

  const handleOpenPopup = () => {
    setPopupContent(operationalHours); // Set the entire operationalHours object
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setPopupContent("");
  };

  const handleSetWeeklyTimings = () => {
    // Navigate to the URL with the businessId
    navigate(`/FastSocial/Contactsdetailspage?accountId=${businessId}`);
  };

  return (
    <div
      className="container"
      //style={{ marginTop: "16px", marginBottom: "16px" }}
    >
      <div
        className="card"
        style={{
          //padding: "16px",
          backgroundColor: "#27343C",
          color: "#ffffff",
          border: "none",
          boxShadow: "none",
        }}
      >
        <h4
          style={{
            color: "#48b59f",
            fontSize: "14px",
            fontWeight: "700",
            //marginBottom: "16px",
            textAlign: "left",
          }}
        >
          Contact Info
        </h4>
        <ul
          className="list-unstyled"
          style={{
            listStyleType: "none",
            padding: "0",
            textAlign: "left",
            marginBottom: 0,
          }}
        >
          {contactDetails.address?.live && (
            <li style={{ display: "flex", marginTop: "16px" }}>
              <strong style={labelStyle}>Address:</strong>
              <span style={valueStyle}>
                {contactDetails.address?.content}
                <br />
                <div className="mt-2">
                  <a
                    href="#"
                    style={arrowStyle}
                    onClick={() => handleOpenPopup("Get direction")}
                  >
                    Get direction →
                  </a>
                </div>
              </span>
            </li>
          )}
          {contactLinks.call?.url && contactLinks.call?.cCode && (
            <li style={{ display: "flex", marginTop: "16px" }}>
              <strong style={labelStyle}>Call Us:</strong>
              <a
                href={`tel:${contactLinks.call.cCode}${contactLinks.call.url}`}
                style={linkStyle}
              >
                {`${contactLinks.call.cCode} ${contactLinks.call.url}`}
              </a>
            </li>
          )}
          {contactLinks.email?.url && (
            <li style={{ display: "flex", marginTop: "16px" }}>
              <strong style={labelStyle}>Email id:</strong>
              <a href={`mailto:${contactLinks.email.url}`} style={linkStyle}>
                {contactLinks.email.url}
              </a>
            </li>
          )}
          <li style={{ display: "flex", marginTop: "16px" }}>
            <strong style={labelStyle}>Timings:</strong>
            <span style={valueStyle}>
              {currentDay} <span style={buttonStyle}>{formattedTimings}</span>
              <br />
              <div className="mt-3">
                <p
                  style={arrowStyle}
                  onClick={
                    isOwnProfile ? handleSetWeeklyTimings : handleOpenPopup
                  }
                >
                  {isOwnProfile
                    ? "Set Weekly Timings →"
                    : "See Weekly Timings →"}
                </p>
              </div>
            </span>
          </li>
        </ul>
        {check ? null : (
          <div
            className="d-flex align-items-center"
            style={{
              marginTop: "32px",
              justifyContent: "space-between",
            }}
          >
            <span style={arrowStyle}>Follow Us: </span>
            <div
              className="ms-3"
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              {contactLinks?.facebook?.url && (
                <a
                  href={contactLinks.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebook_icon}
                    alt="Facebook icon"
                    width={24}
                    height={24}
                  />
                </a>
              )}
              {contactLinks?.instagram?.url && (
                <a
                  href={`${instagramBaseUrl}${contactLinks.instagram.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagram_icon}
                    alt="Instagram icon"
                    width={24}
                    height={24}
                  />
                </a>
              )}
              {contactLinks?.whatsapp?.url && (
                <a
                  href={`${whatsappBaseUrl}${contactLinks.whatsapp.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={whatsapp_icon}
                    alt="WhatsApp icon"
                    width={24}
                    height={24}
                  />
                </a>
              )}
            </div>
          </div>
        )}
      </div>

      <Popup
        isOpen={isPopupOpen}
        content={popupContent}
        onClose={handleClosePopup}
      />
    </div>
  );
};
