import React from "react";
import Title from "./Title";
import SubTitle from "./SubTitle";
import PropTypes from "prop-types";

const TitleSubtitle = ({ title, subtitle }) => {
  return (
    <>
      <Title text={title} />
      <SubTitle text={subtitle} />
    </>
  );
};

TitleSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
};

export default TitleSubtitle;
