import React from "react";
import BackButton from "../../assets/icons/svgCommonIcons/Group 875 (1).svg";

export const Header = ({sectionName}: {sectionName: string}) => {
  return (
    <div className="d-flex align-items-center position-relative">
      <div>
        <img src={BackButton} alt="back icon" />
      </div>
      <div className="position-absolute" style={{left: "50%", transform: "translate(-50%"}}>
        {sectionName}
      </div>
    </div>
  );
};
