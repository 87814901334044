// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../CategoryPage/Header";
import SubCategoryHeader from "./SubCategoryHeader";
import SubCategoryHeaderSection from "./SubCategoryHeaderSection";
import classes from "../../styleSheets/FoodMenu.module.css";
import SearchBar from "./SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import { USER } from "../../constants/localStorageKeys";
import { baseURL, MENU_API } from "../../constants/Api";
import { menuAPI } from "../../API/MenuApi";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { getUserProfile } from "../../API/authMenuAPIs";
import { useLocation, useNavigate } from "react-router-dom";
import { menuAccessAPI } from "../../API/menu_role_access";
import { useDispatch } from "react-redux";
import { setMenuAccess } from "../../redux/actions";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const SubCategory = () => {
  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);
  const [storedItems, setStoredItems] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  const [active, setActive] = useState(false);
  const sidebar = localStorage.getItem(USER.sidebar);
  // const name = localStorage.getItem("name");

  const [data, setData] = useState();
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const callApis = async (savedToken) => {
    const data1 = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    setData(data1);

    if (savedToken) {
      const returnedData = await getUserProfile(
        `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
        navigate,
      );
      setUserData(returnedData);

      const menuAccessData = await menuAccessAPI(data1, navigate);
      dispatch(setMenuAccess(menuAccessData));
      if (menuAccessData) {
        localStorage.setItem(USER.USERNAME, data1?.restrauntName);
        localStorage.setItem(USER.businessId, data1?.user);
        localStorage.setItem(USER.businessName, data1?.title);
      }
    } else {
      dispatch(setMenuAccess(false));
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
    callApis(savedToken);
  }, []);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/menu/SubCategory`, "SubCategory Page");
    setAnalytics(analyticsInstance);
  }, []);

  return (
    <>
      <div className={classes.container1}>
        <div className={classes.container2}>
          <SubCategoryHeader
            userData={userData}
            setUserData={setUserData}
            data={data}
          />

          {!active && (
            <SubCategoryHeaderSection
              storedItems={storedItems}
              setStoredItems={setStoredItems}
              setWholeData={setData}
              data={data}
              openSearch={active}
              setOpenSearch={setActive}
              analytics={analytics}
              setAnalytics={setAnalytics}
            />
          )}
          {active && <SearchBar active={active} setActive={setActive} />}
        </div>
      </div>
    </>
  );
};

export default SubCategory;
