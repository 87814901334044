//@ts-nocheck
import React, { useEffect, useState } from "react";
import { tnc } from "../Social_components/MockData";
import { useLocation, useNavigate } from "react-router-dom";
import "../styleSheets/EditEventsForm.css";
import "../styleSheets/tnc_page.css";
import "../styleSheets/medipage.css";
import checked from "../assets/icons/Social_icons/checked_box.svg";
import unchecked from "../assets/icons/Social_icons/unchecked_box.svg";
import camera_icon from "../assets/icons/Social_icons/photo_camera_icon.svg";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { EventActionInput } from "./sub_components/event_action_input";
import { CCodesInput } from "./sub_components/cCodes_input";
import { EventActionCallView } from "./sub_components/event_action_call_view";
import { Event_action_website_view } from "./sub_components/event_action_website_view";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { addUpdateEvent } from "../API/fast_social_apis";
import { EventMediaUpload } from "./sub_components/event_media_upload";
import { useDispatch, useSelector } from "react-redux";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import { RequiredInput } from "../utils/required_validators";
import { GreenButton } from "../Components/Button.style";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const EditEventForm = () => {
  const dispatch = useDispatch();
  const fastSocialApiData = useSelector((state) => state?.fastSocial?.apiData);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [imgEdit, setImgEdit] = useState(false);
  const [images, setImages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const urlAccountId = urlParams.get("accountId");
  const urlEventId = urlParams.get("eventId");

  const options = [
    { value: "call", label: "Phone No." },
    { value: "website", label: "Visit Website" },
  ];

  const cCodeOptions = [
    { value: "+91", label: "+91" },
    { value: "+93", label: "+93" },
    { value: "+98", label: "+98" },
    { value: "+355", label: "+355" },
    { value: "+253", label: "+253" },
    { value: "+213", label: "+213" },
    { value: "+54", label: "+54" },
  ];

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [media, setMedia] = useState("");
  const [mediaObj, setMediaObj] = useState("");
  const [live, setLive] = useState(true);
  const [selectedAction, setSelectedAction] = useState(options[0]);
  const [btnTitle, setBtnTitle] = useState("");
  const [cCode, setCCode] = useState(cCodeOptions[0]);
  const [url, setUrl] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const handleStatesChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleOpenDialog = async () => {
    setIsSubmitted(true);
    if (!name || !date || !media) return;
    let toSendMediaObj = "";

    if (media) {
      const response = await fetch(media);
      const blob = await response.blob();
      toSendMediaObj = new File([blob], "event_image", {
        type: blob.type,
        lastModified: new Date().getTime(),
      });
    }

    const dataReceived = await addUpdateEvent(
      urlAccountId,
      live,
      name,
      date,
      selectedAction,
      btnTitle,
      url,
      cCode,
      toSendMediaObj,
      urlEventId,
    );
    updateNestedRedux(
      fastSocialApiData,
      "Events",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSelectUpdate = (setState, selectOption, data) => {
    const searchOption = selectOption.find((option) => option == data);
    if (searchOption) setState(searchOption);
    else setState(selectOption[0]);
  };

  useEffect(() => {
    console.log(urlEventId);
    if (urlEventId && Object.keys(fastSocialApiData).length !== 0) {
      const eventsDataArr = fastSocialApiData.Events;
      const findEvent = eventsDataArr.find((event) => event._id == urlEventId);

      if (findEvent) {
        const eventAction = findEvent?.action;
        setName(findEvent?.name);
        setDate(findEvent?.date);
        findEvent?.media && setMedia(`${imgBaseUrl}/${findEvent?.media}`);
        setLive(findEvent?.live);
        setBtnTitle(eventAction?.title);
        setUrl(eventAction?.url);
        eventAction?.type &&
          handleSelectUpdate(setSelectedAction, options, eventAction?.type);
        eventAction?.cCode &&
          handleSelectUpdate(setCCode, cCodeOptions, eventAction?.cCode);
      }
    }
  }, [fastSocialApiData]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/editeventform`, "EditEventForm Page");
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <TopHeader name="Events" />
      <div
        className="Socialformpage"
        style={{
          height: "100svh",
          overflowY: "auto",
          paddingBottom: "100px",
          width: "95%",
          margin: "auto",
        }}
      >
        <div className="d-flex justify-content-center  mx-3">
          <div
            className="details-wraper"
            style={{ width: "100%", margin: "auto" }}
          >
            {/*  <div className="d-flex justify-content-between align-items-center sticky-bg py-5 position-sticky top-0 w-100">
            <div>
              <span
                className="fs-6 check-text invisible"
                onClick={() => navigate("/FastSocial/tncs")}
              >
                All TnC's
              </span>
            </div>
          </div> */}
            <div className="mt-4 ps-1 create-events-body-txt">
              Enter Event Name
            </div>
            <div className="m-1 mb-3">
              <input
                type="text"
                className="w-100 field-border p-2 rounded event_custom_placeholder"
                value={name}
                onChange={(e) => handleStatesChange(e, setName)}
                placeholder="Enter Event Name"
              />

              <RequiredInput
                styleObj={{ marginBottom: "-8px" }}
                dependentState={name}
                isSubmitted={isSubmitted}
              />
            </div>
            <div className="ps-1 create-events-body-txt">Event date</div>
            <div className="m-1 mb-4">
              <input
                type="date"
                className="w-100 field-border p-2 rounded"
                value={date}
                onChange={(e) => handleStatesChange(e, setDate)}
                min={getTodayDate()}
              />
              <RequiredInput
                styleObj={{ marginBottom: "-8px" }}
                dependentState={date}
                isSubmitted={isSubmitted}
              />
            </div>
            <div className="mb-3 d-flex flex-column">
              <div
                className="rounded event_img_edit"
                // style={{"backgroundImage": media ?  `url(${media})` : null}}
                // style={{"backgroundImage": media ?  `url(${imgBaseUrl}/${media})` : null}}
              >
                <img
                  src={camera_icon}
                  className="camera_icon_img"
                  onClick={() => setImgEdit(true)}
                />
                {media && (
                  <img
                    className="border border-5 rounded main-img"
                    src={media}
                  />
                )}
              </div>

              <RequiredInput dependentState={media} isSubmitted={isSubmitted} />
            </div>

            {/* <EventActionInput/>
          <CCodesInput/> */}

            {/* <EventActionCallView/> */}
            {/* <Event_action_website_view/> */}
            {/* <EventActionCallView/> */}
            <div className="pb-1 create-events-body-txt">Action Button</div>
            <EventActionInput
              selectedOption={selectedAction}
              setSelectedOption={setSelectedAction}
              options={options}
              btnTitle={btnTitle}
              setBtnTitle={setBtnTitle}
              handleStatesChange={handleStatesChange}
              setUrl={setUrl}
            />

            {selectedAction.value == "call" ? (
              <EventActionCallView
                url={url}
                setUrl={setUrl}
                cCode={cCode}
                setCCode={setCCode}
                handleStatesChange={handleStatesChange}
                options={cCodeOptions}
              />
            ) : (
              <Event_action_website_view
                url={url}
                setUrl={setUrl}
                handleStatesChange={handleStatesChange}
              />
            )}

            <div
              className=" pb-5 d-flex justify-content-center ms-4 mt-5 pb-5"
              style={customizeMUIComponents.row}
            >
              <GreenButton
                backgroundColor="#7ECAB0"
                varient="contained"
                className="text-white Link-savebutton"
                onClick={handleOpenDialog}
              >
                Save
              </GreenButton>
            </div>
          </div>
        </div>

        <EventMediaUpload
          imgEdit={imgEdit}
          setImgEdit={setImgEdit}
          images={images}
          setImages={setImages}
          media={media}
          setMedia={setMedia}
          setMediaObj={setMediaObj}
          accountId={urlAccountId}
        />
      </div>
    </>
  );
};

export default EditEventForm;
