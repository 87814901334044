/* import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function TopHeader({ name }: { name: string }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        background: "#27343C",
        padding: "10px 0",
        position: "relative",
        width: "inherit",
        margin: "auto",
      }}
    >
      <div style={styles.headerContainer}>
        <div style={styles.backArrow} onClick={() => navigate(-1)}>
          <FaArrowLeft style={styles.arrowIcon} />
        </div>

        <h1 style={styles.headerText}>{name}</h1>
      </div>
    </div>
  );
}

const styles = {
  headerContainer: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  backArrow: {
    cursor: "pointer",
    marginRight: "50px",
  },
  arrowIcon: {
    fontSize: "20px",
    color: "white",
  },
  headerText: {
    color: "white",
    fontWeight: "normal",
    fontSize: "16px",
  },
}; */

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

export default function TopHeader({ name }: { name: string }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "#27343C",
        padding: "10px 0",
        position: "relative",
        width: "inherit",
        /* width: "360px", */
        margin: "auto",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="cursor-pointer ms-2" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon className="back-arrow-click" />
        </div>
        <div>
          <h1
            className="text-white fw-normal"
            style={{
              position: "absolute",
              fontSize: "16px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {name}
          </h1>
        </div>
      </div>
    </div>
  );
}
