// @ts-nocheck
import React, { useState } from "react";
import Switch from '@mui/material/Switch';


export const MuiSwitch = ({ thumbColor, borderColor, trackColor, switchState, setSwitchState, type, label }) => {
  const handleSwitchUpdate = () => {
    setSwitchState((prevState)=>{
      return {
        ...prevState, [type] : !prevState[type]
      }
    })
  }
  return (
    <div className="d-flex align-items-center">
      <Switch
      checked={switchState}
      size="small"
      // className="mt-1"
      onChange={(e)=>handleSwitchUpdate(e)}
        sx={{
          ".MuiSwitch-thumb": {
            backgroundColor: thumbColor,
            border: `2px solid ${borderColor}`
          },
          ".MuiSwitch-track": {
            backgroundColor: "white",
            opacity: "1",
          },
          ".Mui-checked + .MuiSwitch-track": {
            backgroundColor: `${trackColor} !important`,
            opacity: "1 !important"
          }
          
        }}
      />
      <div className="text-center text-black" style={{fontSize: "10px", marginTop: "-6px"}}>{label}</div>
    </div>
  )
}