// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";

import classes from "../styleSheets/UpdateProfile.module.css";
import ProfilePic from "../Components/ProfilePic";
// import classes1 from '../styleSheets/chooseCategory.module.css'
import loaderStyler from "../styleSheets/loader.module.css";
import Radio from "@mui/material/Radio";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import RadioGroup from "@mui/material/RadioGroup";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import MultipleSelect from "../Social_components/MultipleSelect";
import AccordionUse from "../Social_components/AccordionUse";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { Button, Grid, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  createCategory,
  getSocialProfile,
  getbusinessUsers,
  updateSocialProfile,
} from "../API/authAPIs";
import { createSocialProfile } from "../API/authAPIs";
import { USER } from "../constants/localStorageKeys";
import classes1 from "../styleSheets/chooseCategory.module.css";
import Stack from "@mui/material/Stack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MENU_API, baseURL } from "../constants/Api";
import { addCategory, getAllCategories } from "../API/authMenuAPIs";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ChooseCategoryPage from "./ChooseCategoryPage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CircularUnderLoad from "../Components/CircularLoader";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { checkImageUrl } from "../utils/checkImageUrl";
import { color } from "html2canvas/dist/types/css/types/color";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const CreateBusiness = ({ profileCreated, setProfileCreated }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [checkNum, setCheckNum] = useState(false);
  const [available, setAvailable] = useState(true);
  const [touched, setTouched] = React.useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkName, setCheckName] = useState(false);
  const [checkUserName, setCheckUserName] = useState(false);
  const [checkProfileType, setCheckProfileType] = useState(false);
  const [checkMobileNumber, setCheckMobileNumber] = useState();
  const [array, setArray] = useState([]);
  const [categorySearch, setCategorySearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imgEdit, setImgEdit] = useState(false);
  const [isFilepicked, setIsFilePicked] = useState(false);
  const [profile, setProfile] = useState();
  const [searchParams] = useSearchParams();
  const [businessProfileType, setBusinessProfileType] = useState(false);
  const [prevUserName, setPrevUserName] = useState("");
  const [storeImage, setStoreImage] = useState("");
  const [validateErr, setValidateErr] = useState(false);
  const [loader, setLoader] = useState(false);

  const [tempCheckUsernameAvail, setTempCheckUsernameAvail] = useState("");
  const [checkUsernameAvail, setCheckUsernameAvail] = useState(null);
  // const [checkName,setCheckName]=usestate(false)
  const id = searchParams.get("id");
  const [value, setValue] = React.useState("business");
  const [profileAddress, setProfileAddress] = useState({});
  const [gst, setGst] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    console.log("get the value", value);
  };
  //    console.log(' to get id', id);

  var arr = [];
  const callApis = async () => {
    setLoader(true);

    const socialProfile = await getSocialProfile(
      `${baseURL}${MENU_API.GET_SOCIAL_PROFILE}/?id=${id}`,
    );
    setPrevUserName(socialProfile?.userName);
    setProfile(socialProfile);
    setBusinessName(socialProfile?.name);
    setQuery(socialProfile?.userName);
    setTempCheckUsernameAvail(socialProfile?.userName);
    setPhoneNumber(socialProfile?.businessWhatsappPhoneNumber);
    const newArray = [];
    for (var i = 0; i < socialProfile?.associatedCategories?.length; i++) {
      newArray.push(socialProfile?.associatedCategories[i].name);
    }
    console.log(newArray);
    setArray(newArray);
    if (socialProfile?.profile_type) {
      setValue(socialProfile?.profile_type);
    } else {
      setValue("personal");
    }

    setSelectedImage(socialProfile?.image);
    if (socialProfile?.profile_type) {
      setBusinessProfileType(true);
    }
    console.log("businessProfiles222", socialProfile);
    setLoader(false);
  };

  const usernameCall = async () => {
    setCheckUsernameAvail(null);
    const data = await getbusinessUsers(
      `${baseURL}${MENU_API.GET_BUSINESS_USERS}/?userName=${query}`,
    );
    // setBusinessUsers(data);

    console.log(query, "temp");
    if (tempCheckUsernameAvail && query != "") {
      if (query.toLowerCase() === tempCheckUsernameAvail.toLowerCase()) {
        setCheckUsernameAvail(true);
      } else {
        setCheckUsernameAvail(data);
      }
    } else if (!query || query == "") {
      console.log("enteredNull");
      setCheckUsernameAvail(null);
    } else {
      setCheckUsernameAvail(data);
    }
    console.log("data 01", data);
  };

  useEffect(() => {
    callApis();
  }, []);

  useEffect(() => {
    getUserName();
    console.log("testin");
  }, [query]);

  const debounce = (cb, wait) => {
    let timer;

    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, wait);
    };
  };

  const getUserName = debounce(() => {
    usernameCall();
  }, 500);

  // console.log(array, 'arr')
  const handleCreateProfile = async () => {
    if (phoneNumber && !phoneNumber.toString().match(/^\d{10}$/)) {
      setCheckNum(true);
      return;
    }

    if (businessName && query && available) {
      setLoading(true);

      const profileFn = id ? updateSocialProfile : createSocialProfile;
      const { body } = await profileFn(
        businessName,
        query,
        selectedImage,
        value,
        array,
        id,
        phoneNumber,
        profileAddress,
        gst,
        navigate,
      );

      console.log("Selected image:", selectedImage);

      const userDetails = {
        [USER.businessName]: body.name,
        [USER.USERNAME]: body.userName,
        [USER.businessId]: body.profileId,
        [USER.PROFILE_TYPE]: body.profile_type,
      };
      Object.entries(userDetails).forEach(([key, value]) =>
        localStorage.setItem(key, value),
      );

      setProfileCreated(true);
      navigate("/allprofiles");
      setLoading(false);
    } else {
      console.log("Validation failed");

      setCheckName(!businessName);
      setCheckUserName(!query);
      setTouched(!!query);
      setCheckProfileType(!value);
      setCheckMobileNumber(!phoneNumber);
    }
  };

  const handleImageClick = () => {
    setStoreImage(selectedImage);
    setImgEdit(true);
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/CreateBusiness`, "CreateBusiness Page");
  }, []);

  return (
    <>
      <TopHeader name={id ? "Update Profile" : "Create Profile"} />
      <div style={{ marginBottom: "90px" }} className={classes.mainContainer}>
        {loader ? (
          <div className={loaderStyler.userContainer}>
            <CircularUnderLoad />
          </div>
        ) : (
          <div
          // className={classes.parentDiv}
          >
            {!categorySearch ? (
              <>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "50vh",
                      justifyContent: "center",
                    }}
                  >
                    Updating.....
                  </div>
                ) : (
                  <div
                    style={{
                      maxWidth: "450px",
                      margin: "auto",
                      backgroundColor: "#e8e8e8",
                      height: "100vh",
                    }}
                    // className={classes.main}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(228 227 227 / 44%)",
                        padding: "10px",
                      }}
                      className={classes.picDiv}
                    >
                      <form>
                        <input hidden />
                        <div className={classes.pic} onClick={handleImageClick}>
                          {!selectedImage && (
                            <AddPhotoAlternateIcon
                              sx={{
                                color: "#fff",
                                height: "50px",
                                width: "50px",
                              }}
                            />
                          )}
                          {selectedImage && (
                            <>
                              {checkImageUrl(selectedImage) == "" ? (
                                <img
                                  src={selectedImage}
                                  className={classes.pic}
                                />
                              ) : (
                                <img
                                  src={`${imgBaseUrl}/${selectedImage}`}
                                  className={classes.pic}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </form>

                      {imgEdit && (
                        <ProfilePic
                          selectedImage={selectedImage}
                          setSelectedImage={setSelectedImage}
                          imgEdit={imgEdit}
                          setImgEdit={setImgEdit}
                          isFilepicked={isFilepicked}
                          setIsFilePicked={setIsFilePicked}
                          loading={loading}
                          setLoading={setLoading}
                          storeImage={storeImage}
                          setStoreImage={setStoreImage}
                        />
                      )}
                      <p
                        style={{
                          color: "black",
                          fontSize: "12px",
                          marginTop: "6px",
                        }}
                      >
                        Update Profile Picture
                      </p>
                    </div>

                    <div
                      style={{
                        padding: "20px",
                        backgroundColor: "rgb(228 227 227 / 44%)",
                      }}
                      className={classes.innerDiv}
                    >
                      <form style={{ marginTop: "0px" }}>
                        <label se className={classes.formText}>
                          Profile Name{" "}
                          {checkName && (
                            <span style={{ color: "#EF6C00" }}>*</span>
                          )}
                          <br />
                          <input
                            id="profileName"
                            className={classes.inpField}
                            type="text"
                            name="name"
                            value={businessName}
                            onChange={(e) => {
                              setBusinessName(e.target.value);
                            }}
                            required
                          />
                        </label>
                        {checkName && (
                          <div className={classes.requiredText}>required</div>
                        )}
                        <br />
                        <label className={classes.formText}>
                          Whatsapp Phone Number <br />
                          <input
                            className={classes.inpField}
                            type="tel"
                            name="number"
                            value={phoneNumber}
                            maxLength={10}
                            minLength={10}
                            onChange={(e) => {
                              console.log(e.target.value, "num");

                              setPhoneNumber(e.target.value);
                            }}
                            required
                          />
                        </label>
                        {checkNum && (
                          <div className={classes.requiredText}>
                            Add Correct Mobile Number
                          </div>
                        )}
                        {checkMobileNumber && (
                          <div className={classes.requiredText}>required</div>
                        )}
                        <br />

                        <label className={classes.formText}>
                          Username{" "}
                          {checkUserName && (
                            <span style={{ color: "#EF6C00" }}>*</span>
                          )}
                          <br />
                          <div className={classes.wrapper}>
                            {checkUsernameAvail == true &&
                            validateErr == false ? (
                              <div className={classes.icon}>
                                {query === prevUserName ? (
                                  ""
                                ) : (
                                  <CheckCircleIcon sx={{ color: "#03A884" }} />
                                )}
                              </div>
                            ) : checkUsernameAvail == false ? (
                              <div className={classes.icon}>
                                {query === prevUserName ? (
                                  ""
                                ) : (
                                  <CancelIcon sx={{ color: "#EF6C00" }} />
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <input
                              id="username"
                              className={classes.inpField}
                              type="text"
                              name="username"
                              autoComplete="off"
                              value={query}
                              onChange={(e) => {
                                setQuery(e.target.value);

                                const validUsername = new RegExp(
                                  "^[A-Za-z0-9]{0,30}$",
                                );
                                if (!validUsername.test(e.target.value)) {
                                  setValidateErr(true);
                                  return;
                                } else {
                                  setValidateErr(false);
                                }
                              }}
                              required
                            ></input>
                          </div>
                        </label>
                        {checkUserName && (
                          <div className={classes.requiredText}>required</div>
                        )}
                        {/* {touched && query ? (available ? <div className={classes.availableText}>Username available </div> : <div className={classes.requiredText}>Username Not Available</div>) : null} */}
                        {checkUsernameAvail && validateErr == false ? (
                          <div className={classes.availText1}>
                            {query === prevUserName ? "" : "Username available"}
                          </div>
                        ) : checkUsernameAvail == false ? (
                          <div className={classes.availText}>
                            {query === prevUserName
                              ? ""
                              : "Username not available"}
                          </div>
                        ) : (
                          ""
                        )}
                        {validateErr == true ? (
                          <div
                            style={{
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                // marginTop: "-11px",
                                fontSize: "11px",
                                textAlign: "left",
                                color: "#EF6C00",
                              }}
                            >
                              *Shouldn't include any special characters
                              <br /> *username can have 30 character
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        <br />
                      </form>
                      {/* <div className={classes.radioButton}> */}

                      {/* <FormControl>
                      <FormLabel id="radio-buttons">
                        <p className={`${classes.profileTypeText} mb-2 mt-4`}>
                          Choose Profile type
                        </p>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        // defaultValue='personal'
                      >
                        <div className={[classes.rdBTN]}>
                          <FormControlLabel
                            sx={{
                              fontSize: "13px",
                              color: "#0D0C22",
                              fontWeight: "300",
                            }}
                            disabled={businessProfileType}
                            value="personal"
                            control={
                              <Radio
                                sx={{
                                  color: "#6A75CA",
                                  "&.Mui-checked": {
                                    color: "green",
                                  },
                                }}
                              />
                            }
                            label="Personal"
                          />
                          <FormControlLabel
                            sx={{
                              position: "absolute",
                              right: "-5px",
                              fontSize: "13px",
                              color: "#0D0C22",
                              fontWeight: "300",
                            }}
                            value="business"
                            control={
                              <Radio
                                sx={{
                                  color: "#6A75CA",
                                  "&.Mui-checked": {
                                    color: "green",
                                  },
                                }}
                              />
                            }
                            label="Business Profile"
                          />
                        </div>
                      </RadioGroup>
                      {checkProfileType && (
                        <div className={classes.requiredText}>required</div>
                      )}
                    </FormControl> */}

                      <div className={`${classes.additionalDetails}`}>
                        <div
                          className={classes.detailBox}
                          onClick={() => navigate(`/BusinessAccess?id=${id}`)}
                        >
                          <div
                            style={{
                              marginTop: "9px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            className={classes.option}
                          >
                            Check Roles And Authorities
                            <ArrowForwardIcon />
                          </div>
                        </div>

                        <div
                          onClick={() => setCategorySearch(true)}
                          className={classes.detailBox}
                          style={{ padding: "0 10px 0 3px" }}
                        >
                          <div
                            className={classes.option}
                            style={{ textWrap: "nowrap" }}
                          >
                            Account Category
                          </div>
                          <label className={classes.radio}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Radio
                                checked={true}
                                sx={{
                                  height: "0px",
                                  width: "0px",
                                  marginRight: "4px",
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "white",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#7ECAB0",
                                  },
                                }}
                              />
                              <h3
                                style={{
                                  color: "#fff",
                                  fontSize: "small",
                                  margin: "1px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {value}
                              </h3>
                            </div>
                          </label>
                        </div>

                        <AccordionUse />
                      </div>

                      {value === "personal" ? (
                        <></>
                      ) : value === "business" ? (
                        <>
                          <div></div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div
                        className={classes.center}
                        style={{
                          background: "#e8e8e8",
                          marginTop: "-20px",
                          paddingBottom: "150px",
                        }}
                      >
                        {!businessName ||
                        !query ||
                        (value === "business" && array.length === 0) ? (
                          <Button
                            onClick={handleCreateProfile}
                            sx={customizeMUIComponents.saveButton}
                            variant="contained"
                            disabled
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            style={{
                              marginBottom: "35px",
                              border: "none",
                              borderRadius: "25px",
                              height: "35px",
                              backgroundColor: "#0003",
                              color: "black",
                            }}
                            onClick={handleCreateProfile}
                            sx={customizeMUIComponents.saveButton}
                            variant="contained"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                      {/* <Button
                                sx={{
                                    width: '250px',
                                    marginTop: '30px',
                                    //   textTransform:'lowercase'
                                }}
                                variant="outlined" onClick={handleCreateProfile}>{loading?'Creating Account...':'Create Account'}</Button> */}
                    </div>
                    {/* </div> */}
                  </div>
                )}
              </>
            ) : (
              <>
                <ChooseCategoryPage
                  array={array}
                  setArray={setArray}
                  setCategorySearch={setCategorySearch}
                  Type={value}
                  setType={setValue}
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CreateBusiness;
