import React, { useEffect, useState } from "react";
import Images from "./Images";
import "./Common.css";
import TitleSubtitle from "./TitleSubtitle";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";

function Communication() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const title = ["Clear Communication", "Real Time", "Visibility"];
  const Subtitle = [
    <>
      <a
        href="https://www.eatoes.com"
        className="text-decoration-none text-primary"
      >
        WhatsApp/Email
      </a>{" "}
      so that customer or business gets instant updates
    </>,
    "Slot That Changes Instantly on All Portals.",
    "Helps Your Customers Find You. Even When It's Crowded.",
  ];

  const handleNextClick = () => {
    if (currentIndex < title.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      // TODO: add logic to navigate table reservation search screen
      setCurrentIndex(0);
    }
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/communication`, "Communication Page");
  }, []);

  return (
    <>
      <TopHeader name={title[currentIndex]} />
      <div
        className="d-flex flex-column align-items-center justify-content-center vh-100 bg-light position-relative"
        style={{ width: "360px", margin: "auto" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
          <Images currentIndex={currentIndex} />
          <TitleSubtitle
            title={title[currentIndex]}
            subtitle={Subtitle[currentIndex]}
          />
        </div>
        <button
          className="btn btn-primary position-absolute custom-next-button"
          onClick={handleNextClick}
        >
          {currentIndex === title.length - 1 ? "Done" : "Next"}
        </button>
      </div>
    </>
  );
}

export default Communication;
