// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER } from "../constants/localStorageKeys";
import vector from "../assets/images/ActivateBusiness.png";
import { baseURL, MENU_API } from "../constants/Api";
import classes from "../styleSheets/UpdateProfile.module.css";
import classes1 from "../styleSheets/chooseCategory.module.css";
import ProfilePic from "../Components/ProfilePic";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import loaderStyler from "../styleSheets/loader.module.css";
import "../styleSheets/user-business-box.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  getUsers,
  updateSocialProfile,
  updateUserProfile,
} from "../API/authAPIs";
import ImageEdit from "../RestaurantMenu/Modals Layout/ImageEdit";
import { getUserProfile } from "../API/authMenuAPIs";
import { getbusinessProfiles } from "../API/authAPIs";
import CircularUnderLoad from "../Components/CircularLoader";
import { checkImageUrl } from "../utils/checkImageUrl";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { GreenButton } from "../Components/Button.style";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const UserProfile = () => {
  const [open, setOpen] = React.useState(false);
  const [tempCheckUsernameAvail, setTempCheckUsernameAvail] = useState("");
  const [checkUsernameAvail, setCheckUsernameAvail] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [profile, setProfile] = useState([]);
  const storedPhoneNumber = localStorage.getItem(USER.MOBILE_NUMBER);

  const userName = localStorage.getItem(USER.USERNAME);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [query, setQuery] = useState("");
  const [available, setAvailable] = useState(true);
  const [touched, setTouched] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState(storedPhoneNumber);
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [imgEdit, setImgEdit] = useState(false);
  const [isFilepicked, setIsFilePicked] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [loading, setLoading] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkUserName, setCheckUserName] = useState(false);
  const [checkMobileNumber, setCheckMobileNumber] = useState();
  const [busProfile, setBusProfile] = useState([]);
  const [prevUserName, setPrevUserName] = useState("");
  const [storeImage, setStoreImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [validateErr, setValidateErr] = useState(false);

  useEffect(() => {
    const callApis = async () => {
      setLoader(true);
      const profData = await getUserProfile(
        `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
        navigate,
      );
      console.log("profiledata", profData);
      setPrevUserName(profData?.userName);

      setProfile(profData);
      setName(profData?.name);
      setSelectedImage(profData?.image);

      setQuery(profData?.userName);
      setTempCheckUsernameAvail(profData?.userName);
      setLoader(false);

      const data1 = await getbusinessProfiles(
        `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
        navigate,
      );

      setBusProfile(data1);
    };
    callApis();
  }, [navigate]);

  useEffect(() => {
    // getUserName();

    const usernameCall = async () => {
      setCheckUsernameAvail(null);
      // if (!query) return;
      const data = await getUsers(
        `${baseURL}${MENU_API.GET_USERS_NAME}?userName=${query}`,
      );
      console.log("data value gor avail", data);
      console.log(query, tempCheckUsernameAvail, "checking");

      if (tempCheckUsernameAvail && query) {
        if (query.toLowerCase() === tempCheckUsernameAvail.toLowerCase()) {
          setCheckUsernameAvail(true);
        } else if (!query) {
          console.log("enteredNull");
          setCheckUsernameAvail(null);
        } else {
          setCheckUsernameAvail(data);
          // console.log(checkUsernameAvail,'temp')
        }
      } else if (query) {
        setCheckUsernameAvail(data);
      }
      console.log(selectedImage, "image");

      //  console.log("11");
    };

    const getdata = setTimeout(() => {
      usernameCall();
    }, 500);
    return () => clearTimeout(getdata);
  }, [query, selectedImage, tempCheckUsernameAvail]);

  const handleImageClick = () => {
    setStoreImage(selectedImage);
    setImgEdit(true);
  };
  console.log("businessProfiles", storedPhoneNumber);

  const debounce = (cb, wait) => {
    let timer: NodeJS.Timeout;

    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, wait);
    };
  };

  const getUserName = debounce(async () => {
    await usernameCall();
  }, 500);

  console.log("checking username avail", checkUsernameAvail);

  const handleUpdateProfile = async () => {
    if (validateErr) {
      alert("username is not correct!!");
      return;
    }
    if (name && query && available === true && phoneNumber) {
      setLoading(true);
      const createProfile = await updateUserProfile(
        name,
        phoneNumber,
        selectedImage,
        query,
        navigate,
      );
      setTouched(false);
      setProfile(createProfile);
      setCheckUsernameAvail(null);

      setLoading(false);

      if (state?.from === "signup" && state?.profileId) {
        await updateSocialProfile(
          name,
          query,
          selectedImage,
          "personal",
          [],
          state?.profileId,
          "",
          "",
          "",
          navigate,
        );
        console.log(name, query, "SIGNUP");

        localStorage.setItem("RestrauntName", query);
        localStorage.setItem("profileType", "personal");
        localStorage.setItem("businessName", name);
        localStorage.setItem("adminUserName", query);
        localStorage.setItem("adminName", name);
        localStorage.setItem("businessId", state?.profileId);
      }

      navigate("/");
    } else if (!name || !query || !userName || !available) {
      if (!name) {
        setCheckName(true);
      } else {
        setCheckName(false);
      }
      if (!query) {
        setCheckUserName(true);
        setTouched(false);
        setProfile(createProfile);
        setCheckUsernameAvail(null);

        setLoading(false);

        if (state?.from === "signup" && state?.profileId) {
          await updateSocialProfile(
            name,
            query,
            selectedImage,
            "personal",
            [],
            state?.profileId,
            "",
            "",
            "",
            navigate,
          );
          localStorage.setItem("RestrauntName", userName);
          localStorage.setItem("profileType", "personal");
          localStorage.setItem("businessName", name);
          localStorage.setItem("businessId", state?.profileId);
        }

        navigate("/");
      } else if (!name || !query || !userName || available === false) {
        if (!name) {
          setCheckName(true);
        } else {
          setCheckName(false);
        }
        if (!query) {
          setCheckUserName(true);
          setTouched(false);
        } else {
          setCheckUserName(false);
        }
        if (!phoneNumber) {
          setCheckMobileNumber(true);
          setTouched(false);
        } else {
          setCheckMobileNumber(false);
        }
      }
    }
  };
  console.log("value name", name, userName);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView("/UpdateProfile", "UpdateProfile Page");
  }, []);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50vh",
          }}
        >
          Updating...
        </div>
      ) : (
        <>
          <div
            style={{
              maxWidth: "400px",
              margin: "auto",
              background: "#EEF0EF",
              height: "100vh",
            }}
          >
            <div
              className="py-2 position-relative"
              style={{ background: "#27343C" }}
            >
              <div className={classes.row}>
                <KeyboardBackspaceIcon
                  onClick={() => {
                    return state?.from === "signup"
                      ? navigate("/")
                      : navigate(-1);
                  }}
                  className="back-arrow-click ms-3"
                />
                <div
                  className={`${classes.title} text-white position-absolute`}
                  style={{
                    fontSize: "14px",
                    margin: "auto 0",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  Admin Account Settings
                </div>
              </div>
            </div>
            {loader ? (
              <div className={loaderStyler.userContainer}>
                <CircularUnderLoad />
              </div>
            ) : (
              <div className="px-4">
                <div className={classes.picDiv}>
                  <input hidden />
                  <div
                    className={classes.pic}
                    onClick={handleImageClick}
                    onKeyUp={() => false}
                  >
                    {!selectedImage && (
                      <AddAPhotoIcon sx={{ color: "#6E6D7A" }} />
                    )}
                    {selectedImage && (
                      <>
                        {!checkImageUrl ? (
                          <img
                            src={selectedImage}
                            className={classes.pic}
                            alt="default profile"
                          />
                        ) : (
                          <img
                            src={`${imgBaseUrl}/${selectedImage}`}
                            className={classes.pic}
                            alt="user profile"
                          />
                        )}
                      </>
                    )}
                  </div>

                  {imgEdit && (
                    <ProfilePic
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      imgEdit={imgEdit}
                      setImgEdit={setImgEdit}
                      isFilepicked={isFilepicked}
                      setIsFilePicked={setIsFilePicked}
                      loading={loading}
                      setLoading={setLoading}
                      storeImage={storeImage}
                      setStoreImage={setStoreImage}
                    />
                  )}
                  <p className={classes.text}>Update Profile Picture</p>
                </div>

                <form style={{ marginTop: "30px" }}>
                  <label className={classes.formText}>
                    Enter profile name{" "}
                    <span style={{ color: "#EF6C00" }}>*</span>
                    <br />
                    <input
                      className={`${classes.inpField} update-prf-pic-txtbox w-100`}
                      type="text"
                      name="Name"
                      placeholder={name}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </label>
                  <br />
                  <label className={classes.formText}>
                    Enter mobile number
                    <br />
                    <div className="update-prf-pic-mob-txtbox bg-white">
                      <select
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        style={{
                          paddingLeft: "5px",
                          color: "#27343C",
                          padding: "1px",
                          marginRight: "1px",
                          borderStyle: "none",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "12px",
                          textAlign: "center",
                          outline: "none",
                        }}
                      >
                        <option className="drop-down-country-code" value="+91">
                          +91
                        </option>
                        <option className="drop-down-country-code" value="+62">
                          +62
                        </option>
                      </select>
                      <span className="vertical-bar">|</span>
                      <input
                        disabled
                        className="mob-no-txt-input"
                        type="tel"
                        name="number"
                        value={phoneNumber}
                        maxLength={10}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    </div>
                  </label>
                  {checkMobileNumber ? (
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "-10px",
                        fontSize: "12px",
                        textAlign: "right",
                        color: "#EF6C00",
                      }}
                    >
                      required
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  <label className={classes.formText}>
                    Enter user name <span style={{ color: "#EF6C00" }}>*</span>
                    <br />
                    <div className={classes.wrapper}>
                      {checkUsernameAvail === true && validateErr === false ? (
                        <div className={classes.icon}>
                          <CheckCircleIcon sx={{ color: "#03A884" }} />
                        </div>
                      ) : checkUsernameAvail === false ? (
                        <div className={classes.icon}>
                          <CancelIcon sx={{ color: "#FF0000" }} />
                        </div>
                      ) : (
                        ""
                      )}

                      <input
                        // style={{border : "2px solid red"}}
                        className={`${classes.inpField} update-prf-pic-txtbox w-100`}
                        type="text"
                        name="username"
                        value={query}
                        autoComplete="off"
                        onChange={(e) => {
                          setQuery(
                            e.target.value.toLowerCase().substring(0, 30),
                          );

                          const validUsername = /^[a-zA-Z0-9-_'.]+$/;
                          if (!validUsername.test(e.target.value)) {
                            return setValidateErr(true);
                          }
                          setValidateErr(false);
                        }}
                        required
                      />
                    </div>
                  </label>
                  {checkUsernameAvail && validateErr === false ? (
                    <div className={classes.availText1}>Username available</div>
                  ) : checkUsernameAvail === false ? (
                    <div
                      className={classes.availText}
                      style={{ color: "#FF0000" }}
                    >
                      Username not available
                    </div>
                  ) : (
                    ""
                  )}{" "}
                  {validateErr === true ? (
                    <div
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "11px",
                          textAlign: "left",
                          color: "#EF6C00",
                          marginTop: "15px",
                        }}
                      >
                        *only dashes (-), underscores (_), apostrophes ('), and
                        periods (.) are allowed as special characters
                        <br /> *should not contain any space
                        <br /> *can have only 30 characters
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
                <div className={classes.center}>
                  {!(name && query && checkUsernameAvail) ? (
                    <GreenButton
                      backgroundColor="#D1D1D1"
                      marginTop="50px"
                      sx={customizeMUIComponents.saveButton}
                      variant="contained"
                      disabled
                    >
                      Save
                    </GreenButton>
                  ) : (
                    <div>
                      <GreenButton
                        backgroundColor=" #7ECAB0"
                        marginTop="50px"
                        onClick={handleUpdateProfile}
                        sx={customizeMUIComponents.saveButton}
                        variant="contained"
                        // disabled
                      >
                        Save
                      </GreenButton>
                    </div>
                  )}
                </div>
                {/* <p className={classes.text2}>All social profiles</p> */}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
