// @ts-nocheck

import React, { useState, useEffect } from "react"
import _ from "lodash"
import { FormControlLabel } from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import { IOSSwitch } from "../utils/LiveSwitchcustomization";
import solidArrow from "../assets/icons/Social_icons/solidArrow.svg"
import "../styleSheets/AddLinksPage.css"

export const ContactPageAddressTimings = ({ wholeData, addressSwitchPath, timingsSwitchPath, handleStates }) => {
  const navigate = useNavigate()
  const [addressSwitchState, setAddressSwitchState] = useState(false)
  const [timingsSwitchState, setTimingsSwitchState] = useState(false)
  const location=useLocation()
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");
  console.log(state)

  useEffect(() => {
    setAddressSwitchState(_.get(wholeData, addressSwitchPath))
    setTimingsSwitchState(_.get(wholeData, timingsSwitchPath))
  }, [wholeData])

  return (
    <>
      <div style={{marginLeft: "-15px", marginRight: "15px"}}>
        <div>
          <div className="d-flex mt-4 align-items-center">
            <div className="live-div">
              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} />}
                label={addressSwitchState ? "Live" : "Not Live"}
                labelPlacement="bottom"
                className="Live-switch"
                checked={addressSwitchState}
                onChange={(e) => handleStates(e, addressSwitchPath, "switch")}
              />
            </div>
            <div className="w-100 my-auto">
              <div className="Link-imagediv w-100 d-flex align-items-center mb-2 cursor-pointer" onClick={() => navigate(`/FastSocial/address?accountId=${state}`)}>
                Address
                <img src={solidArrow} className="ms-auto" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 ">
          <div className="live-div">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              label={timingsSwitchState ? "Live" : "Not Live"}
              labelPlacement="bottom"
              className="Live-switch"
              checked={timingsSwitchState}
              onChange={(e) => handleStates(e, timingsSwitchPath, "switch")}
            />
          </div>
          <div className="w-100 my-auto" style={{ marginTop: "-12px" }}>
            <div className="Link-imagediv w-100 d-flex align-items-center mb-2 cursor-pointer"
              onClick={() => navigate(`/FastSocial/operational_hours?accountId=${state}`)}>
              Operational hours
              <img src={solidArrow} className="ms-auto" />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}