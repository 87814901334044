// @ts-nocheck
import React, { useState, useEffect } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import ItemSection from "./ItemSection";
import { MENU_API, baseURL } from "../../constants/Api";
import MenuModal from "../Modals Layout/MenuModal";
import { USER } from "../../constants/localStorageKeys";
import "../../styleSheets/itemsection-header.css";
import { useSelector } from "react-redux";

const Items = (id: any) => {
  const fullData = id.data;

  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const [data1, setData1] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const token = localStorage.getItem("accessToken");
  const name = localStorage.getItem("name");
  const resName = localStorage.getItem(USER.USERNAME);

  const fetchData = () => {
    setData1(id?.data?.items);
  };
  useEffect(() => {
    fetchData();
  }, [id?.data]);

  var arr = [];
  var itemId = id?.id;
  //  var id1= id.id1.id
  if (itemId == "" || id?.id1?.id != id?.id2) {
    itemId = id?.a;
  }
  {
    data1?.map((item) => {
      if (itemId == item.subCategory_id) {
        arr.push(item);
      }
    });
  }
  useEffect(() => {
    const filteredItems = data1?.filter(
      (item) => itemId == item.subCategory_id /* ;
      console.log("YEH HAI WOH ITEM :", item); */,
    );
    setItemCount(filteredItems?.length);
    console.log("COUNTS : ", itemCount);
    console.log("itemId", itemId);
  }, [data1, itemId]);
  return (
    <>
      <div
        // className={classes.column}
        style={{ overflow: "hidden" }}
      >
        <div className={classes.itemHeader}>
          <div className={classes.itemMargin}>
            {id?.subdata?.map((item, index) => {
              if (itemId == item._id) {
                return (
                  <>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          maxWidth: "900px",
                          backgroundColor: "#FDF3EE",
                        }}
                        key={index}
                      >
                        <div
                          className={classes.subCatMargin}
                          style={{ width: "100%" }}
                        >
                          <div className={`Subcategory-Name my-auto`}>
                            {" "}
                            {item?.subCategory}
                          </div>
                          <div
                            className={`${classes.count} my-auto ps-2 me-3 text-nowrap`}
                          >
                            {itemCount} items
                          </div>

                          <div className={`${classes.editIconMargin} my-auto`}>
                            {" "}
                            {menuAccessState && (
                              <MenuModal
                                setWholeData={id?.setWholeData}
                                objData={id.subdata[index]}
                                objName={id.subdata[index].subCategory}
                                objImage={id.subdata[index].cloudinary_url}
                                type={"SubCategory"}
                                objId={id.subdata[index]._id}
                                data={id.subdata}
                                setData={id.setsubData}
                                publish={id.subdata[index].publish}
                                itemId={itemId}
                                fullData={fullData}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
        <ItemSection
          storedItems={id.storedItems}
          setStoredItems={id.setStoredItems}
          setIsDeleted={setIsDeleted}
          setItemCount={setItemCount}
          id={itemId}
          data={id?.data}
          setWholeData={id?.setWholeData}
          analytics={id.analytics}
          setAnalytics={id.setAnalytics}
        />
      </div>
    </>
  );
};

export default Items;
