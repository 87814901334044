import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Pages.scss";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../constants/Api";
import { USER } from "../../constants/localStorageKeys";
import locationIcon from "../../assets/icons/location.svg";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import NewFooter from "../../Components/ProfileFooter";
import { useDispatch, useSelector } from "react-redux";
import ProfileFooter from "../../Components/ProfileFooter";
import ClipLoader from "react-spinners/ClipLoader";

const TableReservation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fastSocialAPiData = useSelector((state) => state.fastSocial.apiData);

  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantAvailable, setRestaurantAvailable] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [dropdownState, setDropDownState] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSearch = async () => {
    if (restaurantName.length > 2) {
      try {
        // Replace the direct Google Places API call with your server endpoint
        const response = await axios.get(
          `${baseURL}/v1/search/searchRestaurants/${restaurantName}`,
        );

        setSearchData(response.data);
        setDropDownState(true);
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    } else {
      setSearchData([]);
      setDropDownState(false);
    }
  };

  const handlePageChange = (item) => {
    // Save the result in local storage and move to next page

    localStorage.setItem("result_formatted_address", item.formatted_address);
    localStorage.setItem("result_latitude", item.latitude);
    localStorage.setItem("result_longitude", item.longitude);
    localStorage.setItem("result_name", item.name);
    localStorage.setItem("result_rating", item.rating);
    localStorage.setItem("result_placeId", item.place_id);

    navigate("/reservation/list/");
  };

  useEffect(() => {
    console.log("search", searchData);
  }, [searchData]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    /*  console.log('comes', restaurantName);

    console.log('username:',localStorage.getItem(USER.USERNAME));
    console.log('businessId:', localStorage.getItem(USER.businessId));
    console.log('businessname:',localStorage.getItem(USER.businessName));
    console.log('profileType:',localStorage.getItem(USER.PROFILE_TYPE));

    console.log('accessToken:',localStorage.getItem(USER.JWT_ACCESS_TOKEN));

    */

    //  const delayDebounceFn = setTimeout(() => {
    handleSearch();
    // }, 1000);

    // return () => clearTimeout(delayDebounceFn);

    // Take user to the slots management page, if the profile has a restaurant
    checkRestaurantForProfile();
  }, [restaurantName]);

  const checkRestaurantForProfile = async () => {
    try {
      const profileId = localStorage.getItem(USER.businessId);

      // Make the API call
      const response = await axios.get(
        `${baseURL}/v1/restaurant/checkRestaurantForProfile/${profileId}`,
      );

      console.log("response.data", response.data);

      // Check the result and update the state
      if (response.data?.restaurant) {
        setRestaurantAvailable(true);
        localStorage.setItem("restaurantId", response.data.restaurant.id);
        navigate("/reservation/bookings");
      } else {
        setRestaurantAvailable(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error checking restaurant for profile:", error);
      // Handle errors if needed
    }
  };
  if (loading)
    return (
      <div style={{ display: "flex", justifyContent: "center",height:'80vh',alignItems:'center' }}>
        <ClipLoader
          color={"#40525D"}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  return (
    <div className="Dashboard-main">
      <form className={`searchBar-con ${dropdownState ? "dropdownOpen" : ""}`}>
        {dropdownState && (
          <div className="searchBarResultCon">
            {searchData?.length > 0 ? (
              searchData.map((item) => (
                <div
                  key={item.place_id}
                  onClick={() => handlePageChange(item)}
                  onKeyUp={() => false}
                  className="searchBarResult-item"
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <img src={locationIcon} width={20} height={20} alt="img" />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: 5,
                    }}
                  >
                    <span style={{ fontWeight: 500, fontSize: 14 }}>
                      {item.name}
                    </span>
                    <div className="subtitle">{item.formatted_address}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className="searchBarResult-item">No location found</div>
            )}
          </div>
        )}
        <input
          type="text"
          className="searchBox"
          onChange={(e) => setRestaurantName(e.target.value)}
          placeholder="Search for Your Restaurant"
          style={{
            color: "#000",
            "::placeholder": { color: "#DADADA" },
            fontSize: 20,
          }}
        />
        <div className="searchbarIcon-con">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="#DCDCDC"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </form>
      <div className="hero-offer text-center">
        <span style={{ color: "#27343C" }}>Free to try for first</span>{" "}
        <span className="colored-text">25 bookings*</span>
      </div>
      {/* <ProfileFooter /> */}
    </div>
  );
};

export default TableReservation;
