import React, { useEffect } from "react";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const PricingPage = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/reservation/pricing`, "ReservationPricing Page");
  }, []);
  return (
    <div className="pricingPage">
      <h1 className="page-title">Pricing & Plans</h1>
      <div className="lineFW"></div>

      <div className="pricingItemCon">
        <PricingItem />
        <PricingItem />
        <PricingItem />
      </div>
    </div>
  );
};

export default PricingPage;

function PricingItem({}) {
  return (
    <div className="PricingItem">
      <h2>Basic</h2>
      <h3>Lorem ipsum dolor sit amet</h3>
      <h4>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor
      </h4>
      <div className="priceLine"></div>
      <ul>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
              fill="#28353D"
            />
            <mask
              id="mask0_122_880"
              maskUnits="userSpaceOnUse"
              x="4"
              y="7"
              width="15"
              height="12"
            >
              <path
                d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_122_880)">
              <rect width="24" height="24" fill="#28353D" />
            </g>
          </svg>
          Unlimited
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
              fill="#28353D"
            />
            <mask
              id="mask0_122_880"
              maskUnits="userSpaceOnUse"
              x="4"
              y="7"
              width="15"
              height="12"
            >
              <path
                d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_122_880)">
              <rect width="24" height="24" fill="#28353D" />
            </g>
          </svg>
          Books per month
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
              fill="#28353D"
            />
            <mask
              id="mask0_122_880"
              maskUnits="userSpaceOnUse"
              x="4"
              y="7"
              width="15"
              height="12"
            >
              <path
                d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_122_880)">
              <rect width="24" height="24" fill="#28353D" />
            </g>
          </svg>
          30 users
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
              fill="#28353D"
            />
            <mask
              id="mask0_122_880"
              maskUnits="userSpaceOnUse"
              x="4"
              y="7"
              width="15"
              height="12"
            >
              <path
                d="M9.59 15.58L6.12461 12.1146C5.73506 11.7251 5.10343 11.7244 4.71251 12.1125C4.31963 12.5026 4.31811 13.1381 4.70961 13.5296L9.59 18.41L18.5174 9.38414C18.9031 8.99424 18.9013 8.36605 18.5135 7.97827C18.1227 7.58738 17.4883 7.58923 17.0997 7.98239L9.59 15.58Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_122_880)">
              <rect width="24" height="24" fill="#28353D" />
            </g>
          </svg>
          Write something
        </li>
      </ul>
      <div className="priceLine"></div>
      <h5>
        $30 <span>/per month</span>
      </h5>
      <button className="custom-btn custom-btn-bordered mt-3 w-100">
        Get Started
      </button>
    </div>
  );
}
