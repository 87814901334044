// @ts-nocheck
import { baseURL, MENU_API, WALLET_SERVICES_API } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import { sessionExpireLogic } from "../utils/functionUtils";

export const fastSocialVerification = async (navigate: any) => {
    const userId = localStorage.getItem(USER.businessId);
    const body = JSON.stringify({
        associatedBusinessId: userId,
    });
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${baseURL}${WALLET_SERVICES_API.ENABLE_FAST_SOCIAL_SERVICE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      const data = await response.json();
      console.log("*********", response);
      if (response && response.status == 200) {
        // console.log('category response data',data);
        return data;
      } else if (response.status == 401) {
        sessionExpireLogic()
        alert("Session expired");
        navigate("/Login");
      } else {
        alert("Oops !! Something went wrong .");
        console.error("fast social api is failing");
      }
    } catch (err) {
      console.log("something went wrong");
    }
  };
  
  export const digitalMenu = async (navigate: any) => {
    const userId = localStorage.getItem(USER.businessId);
    const body = JSON.stringify({
        associatedBusinessId: userId,
    });
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${baseURL}${WALLET_SERVICES_API.ENABLE_DIGITAL_MENU}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      const data = await response.json();
      console.log("*********", response);
      if (response && response.status == 200) {
        // console.log('category response data',data);
        return data;
      } else if (response.status == 401) {
        sessionExpireLogic()
        alert("Session expired");
        navigate("/Login");
      } else {
        alert("Oops !! Something went wrong .");
        console.error("digital Menu is failing");
      }
    } catch (err) {
      console.log("something went wrong");
    }
  };
  

  