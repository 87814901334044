//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import "../styleSheets/Eventsection.css";
import _3dot from "../assets/icons/Social_icons/3dot.svg";
import Pop_up_2option from "../utils/Pop_up_2option";
import { USER } from "../constants/localStorageKeys";
import { useSelector } from "react-redux";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { formatDate } from "../utils/functionUtils";
import { SingleImgLightbox } from "../utils/Lightbox/SingleImgLightbox";

const EventSection = ({ events, data }) => {
  const api = useSelector(state => state.fastSocial.apiData)
  const rolesData=useSelector(state=>state.roles.apiData)

  const [dataArr, setDataArr] = useState([])

  const handleBtnClick = (action) => {
    if(action.url){
      window.open(action.type == "call" ?  `tel: ${action.url}` : action.url, "_blank");
    }else{

    }
  }


  let name 
  rolesData?.responseData ? name=[{name:'Edit Events',route:`/FastSocial/Updateseventspage?accountId=${api["Basic Details"]?.accountId}`}]:name=[]

  useEffect(() => {
    setDataArr(api?.Events)
  })
  if (Object.keys(api).length == 0) {
    return
  }
  return (
    <div>
      {dataArr?.filter(event => event.live == true)?.slice(0, 1)?.map(event => {
        return (
          <div className="m-3 mt-0 mb-0 card text-white rounded-4 pb-3 shadow">
            <div className="mx-3 my-3">
              <div className=" d-flex justify-content-between align-items-center">
                <h1 className="fw-semibold fs-1">Events</h1>
                <div>
                  <Pop_up_2option name={name} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  {event.date && (
                    <div className="eventname mt-3">
                      {formatDate(event?.date)}
                    </div>
                  )}
                  <div className="event_new_name">{event?.name}</div>
                </div>
                {/* <div className="d-flex flex-column align-item-start">
              <div className="age-text mt-5 pe-1">*For Age</div>
              <div className="age mt-0 ms-2 fs-1">{events[0].eventage}</div>
            </div> */}
              </div>
              {event.media && (
                <SingleImgLightbox
                  actualSrc={`${imgBaseUrl}/${event.media}`}
                  thumbnail={`${imgBaseUrl}/${event.media}`}
                  imgClassName="rounded-2 mt-4 mb-2 w-100 h-100 ratio-1x1"
                  imgStyleObj={{
                    border: "0.5px solid #F5C377",
                    filter:
                      "drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.15))",
                  }}
                />
              )}
              <div className="d-flex justify-content-between mt-4">
                {/* <div>
              <div className="by-text fw-6">by</div>
              <div className="hotel-title-event fw-6">{data.hotelname}</div>
              <div className="hotel-type-event">{data.hotelType}</div>
            </div> */}
                <div
                  onClick={() => handleBtnClick(event?.action)}
                  className={`${
                    !event.action.title && "d-none"
                  } event-buy-now d-flex justify-content-center align-item-start w-100`}
                >
                  <button className="event-buy-now-text border-0 bg-transparent text-black fs-5">
                    {event?.action?.title}
                  </button>
                </div>
              </div>
              <Link
                to="events"
                className="d-flex justify-content-center check-button p-3 w-100"
              >
                <div className="check-button-text bg-transparent text-center shadow-lg d-flex align-items-center">
                  Check all events or details
                </div>
              </Link>
            </div>
          </div>
        );
      })}



    </div>
  );
};

export default EventSection;
