//@ts-nocheck
import React, { useEffect, useState } from "react";
import backArrow from "../assets/icons/Social_icons/backArrow.svg";
import deleteButton from "../assets/icons/Social_icons/deleteButton.svg";
import addButton from "../assets/icons/Social_icons/nobgadd.svg";
import checked_box from "../assets/icons/Social_icons/checked_box.svg";
import unchecked_box from "../assets/icons/Social_icons/unchecked_box.svg";
import { Button } from "@mui/material";
import "../styleSheets/Timingpage.css";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useLocation, useNavigate } from "react-router-dom";
import "../styleSheets/fast_social_utility_classes.css";
import { useDispatch, useSelector } from "react-redux";
import { object } from "yup";
import { updateTiming } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Timingpage = () => {
  const dispatch = useDispatch();
  const api = useSelector((state) => state?.fastSocial?.apiData);

  const [saperateDayData, setSaperateDayData] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleOpenDialog = async () => {
    const dataReceived = await updateTiming(saperateDayData, state);
    updateNestedRedux(
      api,
      "Contact Details",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [add, setAdd] = useState({
    add0: false,
    add1: false,
    add2: false,
    add3: false,
    add4: false,
    add5: false,
    add6: false,
  });

  const handelAddButton = (buttonname) => {
    setAdd((prev) => ({ ...prev, [buttonname]: !prev[buttonname] }));
  };

  const open = async () => {
    setSaperateDayData((prev) => {
      const newData = { ...prev };
      newData.monday.check = false;
      newData.tuesday.check = false;
      newData.wednesday.check = false;
      newData.thursday.check = false;
      newData.friday.check = false;
      newData.saturday.check = false;
      newData.sunday.check = false;
      console.log(newData);
      return newData;
    });
  };

  const otherDays = async () => {
    setSaperateDayData((prev) => {
      const newData = { ...prev };
      newData.openAll = false;
      console.log(newData);
      return newData;
    });
  };

  const handeltickClick = async (dayName) => {
    if (dayName == "openAll") {
      setSaperateDayData((prev) => {
        const newData = { ...prev };
        newData[dayName] = !newData[dayName];
        return newData;
      });
      await open();
    } else {
      setSaperateDayData((prev) => {
        const newData = { ...prev };
        const oldState = newData[dayName].check;
        newData[dayName].check = !oldState;
        return newData;
      });
      await otherDays();
    }
  };

  const handelShift = (e, dayName, shiftNumber, timeType) => {
    setSaperateDayData((prev) => {
      const newValue = { ...prev };
      newValue[dayName].timings[shiftNumber][timeType] = e.target.value;
      return newValue;
    });
  };

  const updateStates = () => {
    if (saperateDayData) {
      const newAddState = { ...add };
      Object.keys(saperateDayData)
        .filter((data) => data !== "live" && data !== "openAll")
        .forEach((day, index) => {
          newAddState[`add${index}`] = saperateDayData[day]?.timings.shift2.open
            ? true
            : false;
        });
      setAdd(newAddState);
    }
  };

  useEffect(() => {
    setSaperateDayData(api["Contact Details"]?.operationalHours);
  }, [api]);

  useEffect(() => {
    updateStates();
  }, [saperateDayData]);

  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/operational_hours`, "Timing Page");
  }, []);
  return (
    <div className="Socialformpage">
      <div className="d-flex flex-column align-items-center">
        <div className="small-devices-padding">
          <div
            className=" d-flex  justify-content-between z-1 sticky-bg pt-5 ps-md-0 ps-2 pb-5"
            style={{ position: "sticky", top: "0", left: "0" }}
          >
            <img
              src={backArrow}
              alt=""
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <div className=" me-auto ms-auto">
              <span className="fs-5 me-4">Operation hours</span>
            </div>
          </div>
          <div className="d-flex justify-content-start ps-2 ps-md-1">
            <div onClick={() => handeltickClick("openAll")}>
              <div className="me-1">
                {saperateDayData?.openAll ? (
                  <img src={checked_box} />
                ) : (
                  <img src={unchecked_box} />
                )}
                <span className="ms-1 day-name">Open 24 x 7</span>
              </div>
              <div className=" my-3 text-start day-name">or</div>
            </div>
          </div>
          {saperateDayData &&
            Object.keys(saperateDayData)
              .filter((data) => data !== "live" && data !== "openAll")
              .map((day, index) => (
                <div
                  key={`days-${index}`}
                  className="d-flex justify-content-between mb-4 ps-2 ps-md-0"
                >
                  <div className="d-flex ">
                    {saperateDayData[day].check ? (
                      <img
                        src={checked_box}
                        onClick={() => handeltickClick(day)}
                        className="pb-5 mt-1"
                      />
                    ) : (
                      <img
                        src={unchecked_box}
                        onClick={() => handeltickClick(day)}
                        className="pb-5 mt-1"
                      />
                    )}
                    <div className="ms-1 pe-4 day-name">{day}</div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className=" position-relative time-input-div">
                          <input
                            type="text"
                            value={
                              saperateDayData[`${day}`]?.timings?.shift1?.open
                            }
                            onChange={(openevent) =>
                              handelShift(openevent, day, "shift1", "open")
                            }
                            className="w-100 border-0 time-input"
                          />
                          <span className="position-absolute  translate-middle fw-light time-badge">
                            opens at
                          </span>
                        </div>
                      </div>
                      <div className="dash-div mx-2"></div>
                      <div className=" position-relative time-input-div">
                        <input
                          type="text"
                          value={
                            saperateDayData[`${day}`]?.timings?.shift1?.close
                          }
                          onChange={(closeevent) =>
                            handelShift(closeevent, day, "shift1", "close")
                          }
                          className=" w-100 border-0 time-input"
                        />
                        <span className="position-absolute  translate-middle fw-light time-badge">
                          Closes at
                        </span>
                      </div>
                      <div
                        className={`ms-2 ${
                          add[`add${index}`] ? "invisible" : "visible"
                        } `}
                      >
                        <img
                          src={addButton}
                          onClick={() => handelAddButton(`add${index}`)}
                        />
                      </div>
                    </div>
                    <div
                      className={`d-flex align-items-center mt-2 ${
                        add[`add${index}`] ? "visible" : "invisible"
                      }`}
                    >
                      <div>
                        <div className=" position-relative time-input-div">
                          <input
                            type="text"
                            value={
                              saperateDayData[`${day}`]?.timings?.shift2?.open
                            }
                            onChange={(openevent) =>
                              handelShift(openevent, day, "shift2", "open")
                            }
                            className="w-100 border-0 time-input"
                          />
                          <span className="position-absolute  translate-middle fw-light time-badge">
                            opens at
                          </span>
                        </div>
                      </div>
                      <div className="dash-div mx-2"></div>
                      <div className=" position-relative time-input-div">
                        <input
                          type="text"
                          value={
                            saperateDayData[`${day}`]?.timings?.shift2?.close
                          }
                          onChange={(openevent) =>
                            handelShift(openevent, day, "shift2", "close")
                          }
                          className=" w-100 border-0 time-input"
                        />
                        <span className="position-absolute  translate-middle fw-light time-badge">
                          Closes at
                        </span>
                      </div>
                      <div>
                        <img
                          src={deleteButton}
                          className="ms-2"
                          onClick={() => handelAddButton(`add${index}`)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div
          className="d-flex justify-content-center mb-5 pb-5"
          style={customizeMUIComponents.row}
        >
          <Button
            varient="contained"
            sx={customizeMUIComponents.editButton2}
            className="text-white Link-savebutton ms-3"
            onClick={handleOpenDialog}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Timingpage;
