// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import { USER } from "../constants/localStorageKeys";
import classes from "../styleSheets/Dashboard.module.css";
import { MENU_API, baseURL } from "../constants/Api";
import { menuAPI } from "../API/MenuApi";
import { addBusinessName } from "../API/authMenuAPIs";
import utilityFunctions from "../utils/UtilityFunction";
import icon from "../RestaurantMenu/Images/repete.png";
import image1 from "../assets/icons/BrowserIcon/logo2 1.png";
import iconPlaceHolder from "../RestaurantMenu/Images/placeholders/image.jpeg";
import { Grid } from "@mui/material";
import { Image, Padding } from "@mui/icons-material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import lgstyle from "../styleSheets/logout.module.css";
import { getbusinessProfiles } from "../API/authMenuAPIs";

// import logclass from '../styleSheets/logout.module.css'
// const classess = import('../styleSheets/logout.css')

export const Logout = ({ businessName, setBusinessName ,profile,setProfile,fetchProfile,setFetchProfile}) => {
  const isMobile = window.innerWidth <= 900;
  const [value, setValue] = useState([]);
  const [inputName, setInputName] = useState("");
  let resName = localStorage.getItem(USER.USERNAME);

  let navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState("");
  const callApis = async () => {
    const data1 = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
    );
    setValue(data1);

  };
  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
    if (savedToken) {
      setAuthenticated(savedToken);
    }
    setBusinessName(resName);
    callApis();
  }, []);

  const name = localStorage.getItem("name");

  const { getImages } = utilityFunctions();
  const data = menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
  // const data2 = addBusinessName(`${baseURL}${MENU_API.ADD_BUSINESS_NAME}`)
  const isAMobile = window.innerWidth;
  console.log(data, "data");

  const style = {
    display: "grid",
    gridTemplateColumns: "2.5rem 1fr 1fr 0rem",
    padding: "20px",
  };

  const onClick = async () => {
    console.log(businessName, "business name");
    addBusinessName(inputName);
    setBusinessName(inputName);

    // const saveBusinessName={data2}
    // const RestrauntName = businessName;
    inputName && localStorage.setItem(USER.USERNAME, inputName);
  };

  const handlechange = async () => {
    // localStorage.removeItem(USER.JWT_ACCESS_TOKEN);
    // localStorage.removeItem(USER.USERNAME);
    // localStorage.removeItem(USER.NAME);
    // localStorage.removeItem(USER.businessId);
    // localStorage.removeItem(USER.businessName);
    // localStorage.removeItem(USER.MOBILE_NUMBER);
    // swal.fire({

    //   text: "You have been Logged out",
    //   icon: "error",
    //   confirmButtonText: 'LogIn',
    //   confirmButtonColor: "red",
    // })
    localStorage.clear()
    setProfile(null)
    setFetchProfile(false)
    navigate("/Login");
  };

  return (
    <div className={lgstyle.mainContainerLgout}>
      {/* <div style={{  }}> */}
      {/* <div 
      
        className={lgstyle.user_name_container}
        >
    
        
        <div style={{
          display:'flex',
          padding:'15px'

        }}> */}

      {/* <>
            
              <RestaurantMenuIcon fontSize='small' 
               sx={{ width: '35px', borderRadius: '50%',margin:'0px 0 0 0'}}
               onClick={()=> navigate(`/profile`)} />
            
          </>
          
          
           <div className={lgstyle.lgout_card_name}>
           <div style={{marginLeft:'10px'}}>Menu</div>
         </div> */}

      {/* </div>
      

        
      </div>  */}
      {/* <hr /> */}
      <br />

      <div className={lgstyle.logoutBtn_div}>
        <LogoutIcon
          className={lgstyle.lgout_icon}
          fontSize="small"
          onClick={handlechange}
          sx={{
            position: "relative",
            top: "8px",
            left: "14px",
          }}
        />
        <button
        
          className={lgstyle.logoutBtn}
          // onClick={() => navigate("/adminprofile")}
          onClick={() => import.meta.env.VITE_NEW_UI !== "false" ? navigate("/adminprofile") : navigate('/UpdateProfile')}
        >
          Update Profile
        </button>
      </div>
      <hr />
      <div className={lgstyle.logoutBtn_div}>
        <LogoutIcon
          className={lgstyle.lgout_icon}
          fontSize="small"
          onClick={handlechange}
          sx={{
            position: "relative",
            top: "8px",
            left: "14px",
          }}
        />
        <button className={lgstyle.logoutBtn} onClick={handlechange}>
          Log out
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};
