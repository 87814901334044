// @ts-nocheck

import { CCodesInput } from "./cCodes_input";

export const EventActionCallView = ({
  url,
  handleStatesChange,
  setUrl,
  cCode,
  setCCode,
  options,
}) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ gap: "10px" }}
    >
      <CCodesInput
        options={options}
        selectedOption={cCode}
        setSelectedOption={setCCode}
      />
      <input
        value={url}
        onChange={(e) => handleStatesChange(e, setUrl)}
        type="text"
        placeholder="Enter Phone Number"
        className="w-100 field-border p-2 rounded event_custom_placeholder"
        style={{ height: "45px" }}
      />
    </div>
  );
};
