//@ts-nocheck
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { USER } from "../constants/localStorageKeys";
import "../styleSheets/pop_up_2option.css";
import OptionIcon from "../assets/icons/Social_icons/3dot.svg";
import Share from "../Components/Share";
import { useNavigate, useParams } from "react-router-dom";
import { web_url } from "../constants/web_url";

const Pop_up_2option = ({ name, urlProp }) => {

  const navigate=useNavigate()
  
  const [headerOptionEl, setHeaderOptionEl] = useState(null);
  
  const optionState = Boolean(headerOptionEl);
  const handleClick = (event) => {
    event.stopPropagation()
    const Option = event.currentTarget.querySelector("img"); // Find the MoreVertIcon element
    setHeaderOptionEl(Option);
  };
  const handleClose = (e) => {
    e.stopPropagation()
    setHeaderOptionEl(null);
  };

  const handleNavigation=(data)=> {
    if(data.name==="Logout")
    {
    localStorage.removeItem(USER.JWT_ACCESS_TOKEN);
    localStorage.removeItem(USER.USERNAME);
    localStorage.removeItem(USER.NAME);
    localStorage.removeItem(USER.businessId);
    localStorage.removeItem(USER.businessName);
    localStorage.removeItem(USER.MOBILE_NUMBER);
    navigate(data.route)
  }else{
    navigate(data.route)
  }
}

  const location=useParams()
  const url = urlProp || `${web_url}/${location.userName}`
  return (
    <div className="z-3">
      <IconButton
        id="pop-2option"
        aria-controls={optionState ? "header-section" : undefined}
        aria-haspopup="true"
        aria-expanded={optionState ? "true" : undefined}
        onClick={handleClick}
        disableRipple
        className="p-2"
      >
        <img src={OptionIcon} className="pb-2" />
      </IconButton>

      <Menu
        onKeyDownCapture={(e) => e.stopPropagation()}
        anchorEl={headerOptionEl}
        open={optionState}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="pop-up-class-2"
      >
        <div className=" rounded-5 d-flex flex-column justify-content-center p-0 w-fit pop-up-2">
          <div>
          <button className="m-2 d-flex justify-content-center align-items-center pop-up-button-2">
            <Share type={'whole-menu'} url={url}/>
            </button>
            <button className="m-2 pop-up-button-2 d-flex justify-content-center align-items-center" onClick={() => navigator.clipboard.writeText(url)}>
              Copy Link
            </button>
            {name.map((data,item)=>(
              <button key={item} className="m-2 bg-white text-black pop-up-button-2 d-flex justify-content-center text-nowrap align-items-center" onClick={() => handleNavigation(data)}>
               {data.name}
              </button>
            ))}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default Pop_up_2option;
