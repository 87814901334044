import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDateToCustomString } from "./functionUtils";
import AnalyticsUtil from "./GoogleAnalytics";

export const TempPage = () => {
  const { state } = useLocation();

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`reserve/temp-route`, "TempRoute Page");
  }, []);
  return (
    <div className="mt-5 text-center">
      The data received :-
      <br />
      No. of diners: {state?.selectedPeople}
      <br />
      Selected Date: {formatDateToCustomString(state?.selectedDate)}
      <br />
      Selected Time: {state?.selectedTime}
      <br />
      slotId: {state?.slotId}
      <br />
      <br />
      user's name: {state?.userInfo?.name}
      <br />
      user's mobile num: {state?.userInfo?.mobileNum}
      <br />
      user's email: {state?.userInfo?.email}
      <br />
      <br />
      <br />
      Now it is ready for API call
    </div>
  );
};
