// @ts-nocheck

import React from "react";

export const TextLimiter = ({ maxLimit, value }) => {
  return (
    <div className="w-100 d-flex">
      <span className="text-danger fs-6 ms-auto me-2">
        {value?.length}/{maxLimit}
      </span>
    </div>
  );
};
