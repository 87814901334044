// @ts-nocheck
import * as React from "react";
import * as htmlToImage from "html-to-image";
import { domToJpeg } from 'modern-screenshot'
import { saveAs } from "file-saver";

import { useState, useRef, useEffect } from "react";
import QRCodeStyling from "qr-code-styling";
import { baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import classes from "../styleSheets/FoodMenu.module.css";
import Share from "./Share";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReplyIcon from "@mui/icons-material/Reply";
import "../styleSheets/qrcode.css";
import { PopupModal } from "../utils/Popup-Modal";
import QRLogo from "./4.png"
import { web_url } from "../constants/web_url";
import { useParams } from "react-router-dom";

const QrCode = () => {
  const [open, setOpen] = React.useState(false);
  const [popupOpen, setPopupOpen] = useState(false)
  const [popupMessage, setPopupMessage] = useState("")
  const {name1, name2} = useParams()

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);



  const url = `${web_url}/menu/${name1 ? name1 : name2}`;

  const imgSetting = {
    src: "/assets/connectapp-logo.svg",
    height: "35",
    width: "25",
    excavate: true,
  };

  const handleCopyUrl = () =>{
    setPopupMessage("Link has been copied to clipboard")
    setPopupOpen(true)
    navigator.clipboard.writeText(`${web_url}/menu/${name1 ? name1: name2}`);
  }


  const qrCodeGen = new QRCodeStyling({
    data: url,
    type: "svg",
    image: QRLogo,
    dotsOptions: {
      type: "dots"
    },
    imageOptions: {
      crossOrigin: "anonymous",
    },
    cornersSquareOptions: {
      type:  "extra-rounded"
    },
    cornersDotOptions: {
      color: "#0e7528",
      type: "dot"
    }
  });

  const downloadQRCode = async () => {
    setPopupMessage("QR download started")
    setPopupOpen(true)
    const qrImage = document.getElementById("123456");

    const dataUrl = await domToJpeg(qrImage, {
      quality: 1,
      scale: 10,
      timeout: 60000,
      drawImageInterval: 300
    })

    setTimeout(async () => {
      const link = document.createElement('a')
      link.href = await dataUrl
      link.download = 'qrCode.jpeg'
      link.click()
    }, 0)
  };


  const qrDivRef = React.useCallback(node=>{
    if(!node?.hasChildNodes()) qrCodeGen.append(node)
  }, [])

  return (
    <div>
      <PopupModal open={popupOpen} setOpen={setPopupOpen} message={popupMessage} />
      <div style={{ cursor: "pointer" }} onClick={handleOpen}>
        Link / QR{" "}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.qrBox}>

          <div className="pb-4">
            <div className="Upper-Div d-flex flex-column align-items-center p-2">
              <div className="Qr-options-div">
                <div className="Qr-div text-center text-center">
                  <div className="mb-2" id="123456" ref={qrDivRef} />
                </div>

                <div className="options-div d-flex justify-content-evenly gap-4 mt-1">
                  <div
                    onClick={downloadQRCode}
                    className="download-div d-flex justify-content-center align-items-center cursor-pointer"
                  >
                    <img
                      className="qr-icons me-1"
                      src="/assets/qr-download-icon.svg"
                      alt="Download Icon"
                    />
                    Download QR
                  </div>

                  <div className="share-div d-flex justify-content-center align-items-center cursor-pointer">
                    <img
                      className="qr-icons me-1"
                      src="/assets/qr-share-icon.svg"
                      alt="Share Icon"
                    />
                    <Share url={url} />
                  </div>
                </div>
              </div>
            </div>

            <div className="lower-div text-center">
              <span
                className="link-text cursor-pointer"
                onClick={() => window.open(url, "_blank")}
              >
                {web_url}/menu/{name1 ? name1: name2}
              </span>

              <div className="Link-div mt-3 d-flex justify-content-center align-items-center">
                <img
                  onClick={handleCopyUrl}
                  className="qr-icons me-1 cursor-pointer"
                  src="/assets/qr-copy-icon.svg"
                  alt="Copy icon"
                />
                <span onClick={handleCopyUrl} className="cursor-pointer">
                  Copy Link
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default QrCode;
