import React, { useState } from 'react';

const TabSection: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Social');

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: 'white',
    }}>
      {['Social', 'About'].map((tab) => (
        <div
          key={tab}
          style={{
            padding: '10px 0',
            width: '50%',
            textAlign: 'center',
            fontWeight: activeTab === tab ? 'bold' : 'normal',
            borderBottom: activeTab === tab ? '2px solid #27343C' : 'none',
            color: activeTab === tab ? '#27343C' : '#828282',
            cursor: 'pointer',
          }}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default TabSection;