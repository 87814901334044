import React from "react";
import line1 from "../assets/icons/svgCommonIcons/line1.svg";
import line2 from "../assets/icons/svgCommonIcons/line2.svg";
import phone from "../assets/icons/svgSpecificIcons/phone.svg";
import sms from "../assets/icons/svgSpecificIcons/sms.svg";
import Image1 from "../assets/images/loginPageImage1.png";
import Image2 from "../assets/images/loginPageImage2.png";
import Image3 from "../assets/images/loginPageImage3.png";
import Image4 from "../assets/images/loginPageImage4.png";
import "../App.css";
const Collage = () => {
  return (
    <div>
      <div className="container2">
        <div className="imagesContainer1">
          <div style={{ zIndex: 5 }}>
            <img src={Image1} className="image1" />
          </div>
          <div style={{ zIndex: 5 }}>
            <img src={Image2} className="image1" />
          </div>
          <div>
            <img src={line2} style={{ marginTop: -30, marginLeft: 110 }} />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                zIndex: "10",
                borderRadius: "50%",
                width: "60px",
                height: "60px",
                boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                marginTop: -20,
                marginLeft: 80,
              }}
            >
              <img
                src={sms}
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              />
            </div>
            <img src={line1} style={{ marginRight: -150 }} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3em",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {" "}
            <div style={{ marginLeft: -70 }}>
              <img src={line1} style={{ marginBottom: "0" }} />
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "50%",
                width: "60px",
                height: "60px",
                boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                marginTop: -20,
                marginLeft: -10,
              }}
            >
              <img
                src={phone}
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>
          <div style={{ marginBottom: -50 }}>
            <img src={line2} style={{ marginLeft: 120 }} />
          </div>
          <img src={Image3} className="image2" />
          <div style={{ zIndex: 5 }}>
            <img src={Image4} className="image2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collage;
