// @ts-nocheck
import React,{useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import Bin from "../RestaurantMenu/Images/Bin.png";
import { deleteMenu } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../API/authAPIs";
import { ROLES } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import "../styleSheets/delete-user-business.css"
import { GreenButton, AddUserButton } from "../Components/Button.style";

function DeleteUser({
  id,
  delUser,
  setDeleteUser,
  setAnchorEl,
  setProfile,
  setName,
  setRole,
  setPhoneNumber,
  profileId,
  profiles,
  role
}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openWarning,setOpenWarning]=useState(false)
  const handleCloseWarning=()=>{
    setOpenWarning(false)
  }
  const handleClose = () => {
    setOpen(false);
    setDeleteUser(false);
    // console.log('del user', delUser )
    setAnchorEl(false);
  };

  async function handleDelete() {
    setLoading(true);
    const data = await deleteUser(id, profileId, navigate);
    //    console.log(menuData)

    setProfile(data.body);
    setDeleteUser(false);
    console.log("del user", delUser);
    setAnchorEl(false);
    setName("");
    setRole("");
    setPhoneNumber("");
    setLoading(false);
    setOpen(false);
  }
   async function onClickDeleteButton(){
    
    const userDetails= profiles?.filter((i)=>{
      
      return i.mobileNumber === Number(localStorage.getItem(USER.MOBILE_NUMBER))
      })
      const accountUserRole =  ROLES(userDetails[0]?.role)
      console.log(accountUserRole,role);
      if((accountUserRole === 'Manager' && role === 'Admin') ||((accountUserRole ==='Staff' || accountUserRole==='Accounts') && (role ==='Admin' || role ==='Manager'))||(profiles?.length<2))
      {
        setOpenWarning(true);
      }
      else{
        setOpen(true);
      }
   }
  return (
    <div>
      <div className="edit-div-btn2" onClick={onClickDeleteButton}>Delete</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.deleteModal}>
          {loading ? (
            <>Deleting...</>
          ) : (
            <>
              {/* <img src={Bin} /> */}              
              <Typography sx={customizeMUIComponents.title}>
              <div className="t1-delete-btn">Are You Sure, You Want To Delete This User?</div>
              <p className="t2-delete-btn"> This action can't be undone</p>
              </Typography>
              <div style={customizeMUIComponents.row}>
                <AddUserButton backgroundColor="#D1D1D1"
                marginTop="15px"
                  variant="outlined"
                  onClick={handleClose}
                >
                  No
                </AddUserButton>
                <AddUserButton backgroundColor="#D1D1D1"
                marginTop="15px"
                  variant="contained"
                  onClick={handleDelete}
                >
                  Yes
                </AddUserButton>
              </div>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.warningModal}>
        <Typography sx={customizeMUIComponents.title}>
                This Action cannot be performed
              </Typography>
          
        </Box>
      </Modal>
    </div>
  );
}
export default DeleteUser;
