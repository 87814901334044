import React from "react";
import two from "../assets/images/whatsappicon.png";
import mail from "../assets/images/inbox.png";
import search from "../assets/images/time.png";
import timer from "../assets/images/visibility.png";
import "./Common.css";

const Images = ({ currentIndex }) => {
  return (
    <div className="d-flex justify-content-center align-items-center custom-circle mb-4">
      {currentIndex === 0 && (
        <>
          <img
            src={two}
            className="img-fluid custom-icon"
            alt="WhatsApp logo"
          />
          <img src={mail} className="img-fluid custom-icon" alt="Email logo" />
        </>
      )}
      {currentIndex === 1 && (
        <img src={search} className="img-fluid custom-icon" alt="Search icon" />
      )}
      {currentIndex === 2 && (
        <img src={timer} className="img-fluid" alt="Timer icon" />
      )}
    </div>
  );
};

export default Images;
