// @ts-nocheck
import * as React from "react";
import {useDispatch} from "react-redux"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import Bin from "../../RestaurantMenu/Images/Bin.png";
import { deleteMenu } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { setApiData } from "../../redux/actions";

function DeleteCarousel({ type, objId, data, setData, delImage, setDelImage }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  console.log(objId, "idd");
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDelImage(false);
  };

  async function handleDelete() {
    const menuData = await deleteMenu(objId, type, navigate);

    if (type == "Caraousel") {
      await setData(menuData.body.HomePageImagesurl);
      dispatch(setApiData(menuData.body))
    }

    setOpen(false);
    setDelImage(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.deleteModal} className="rounded-3">
          <div className="p-2">
            <p className="heading">Do you want to delete this Cover Image</p>
            <p className="action-undone">This action can't be undone</p>
          </div>

          <div className="d-flex justify-content-between pt-2 pb-1">
            <button className="no-btn" onClick={handleClose}>
              No
            </button>
            <button className="yes-btn" onClick={handleDelete}>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default DeleteCarousel;
