//@ts-nocheck
import { useEffect } from "react";
import {
  Route,
  Routes,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  event,
  event_lists,
  header,
  like,
  lists,
  search,
  heading,
  profile,
  social,
  media,
  tnc,
  buttons,
  photos,
} from "./MockData";
import Socialpage from "./Socialpage";
import Social_profile_page from "./Social_profile_page";
import CreateBusiness from "../pages/CreateBusiness";
import BusinessAccess from "../pages/BusinessAccess";
import Profilepage from "./Profilepage";
import Announcementpage from "./Announcementpage";
import Bio_page from "./Bio_page";
import Socialcontactpage from "./Socialcontactpage";
import Addresspage from "./Addresspage";
import Timingpage from "./Timingpage";
import AddLinksPage from "./AddLinksPage";
import MainMenu from "../RestaurantMenu/MainMenu";
import Photopage from "./Photopage";
import AddEventsorupdatepage from "./AddEventsorupdatepage";
import Tnc_page from "./Tnc_page";
import Media_page from "./Media_page";
import { useDispatch, useSelector } from "react-redux";
import { getAllData } from "../API/fast_social_apis";
import { setFastSocialData } from "../redux/actions";
import EditEventForm from "./EditEventForm";
import Extrasetting from "./ExtraSetting";
import Visibilitytimings from "./Visibilitytimings";
import Changeprices from "./Changeprices";

const Fast_Social_routes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  // ONLY FOR TESING PURPOSE
  // const state = "OneGolf Brewery"

  const fastSocialAPiData = useSelector((state) => state.fastSocial.apiData);
  const timestamp = useSelector((state) => state.fastSocial.timestamp);
  const currentTime = new Date();
  const timeDiff = currentTime - timestamp;
  const minutesDiff = timeDiff / (1000 * 60);

  const ApiCall = async () => {
    // const dataReceived = await getAllData(state, navigate, "profileId")
    // ONLY FOR TESTING PURPOSE"
    const dataReceived = await getAllData(state, navigate, "accountId");
    dispatch(setFastSocialData(dataReceived));
  };

  useEffect(() => {
    if (
      minutesDiff > 20 ||
      (Object.keys(fastSocialAPiData).length === 0 && state)
    )
      ApiCall();
  }, [state]);

  console.log(
    "avinaaaaaaaaaaaaaaaaaaassssssssssssssssshhhhhhhhhhhhhhhhhhhhhhhhhhh",
    fastSocialAPiData,
  );

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={<Socialpage social={social} profile={profile} />}
        />
        <Route exact path="/Profilesettingpage" element={<CreateBusiness />} />
        <Route exact path="/Profileaccesspage" element={<BusinessAccess />} />
        <Route
          exact
          path="/socialprofilepage"
          element={<Social_profile_page data={heading} />}
        />
        <Route
          exact
          path="/Digital Store/Digital Menupage"
          element={<MainMenu />}
        />
        <Route exact path="/Announcementpage" element={<Announcementpage />} />
        <Route exact path="/Biopage" element={<Bio_page />} />
        <Route
          exact
          path="/Contactsdetailspage"
          element={<Socialcontactpage />}
        />
        <Route exact path="/Changepricespage" element={<Changeprices />} />
        <Route
          exact
          path="/Visibilitytimingspage"
          element={<Visibilitytimings />}
        />
        <Route exact path="/Extrasettingspage" element={<Extrasetting />} />
        <Route exact path="/address" element={<Addresspage />} />
        <Route exact path="/operational_hours" element={<Timingpage />} />
        <Route
          exact
          path="/Linkspage"
          element={<AddLinksPage buttons={buttons} />}
        />
        <Route
          exact
          path="/Updateseventspage"
          element={<AddEventsorupdatepage />}
        />
        <Route exact path="/editeventform" element={<EditEventForm />} />
        <Route exact path="/tncs" element={<Tnc_page tnc={tnc} />} />
        <Route exact path="/Photospage" element={<Media_page />} />
        <Route
          exact
          path="/Photoaddpage"
          element={<Photopage photo={photos} />}
        />
      </Routes>
    </div>
  );
};

export default Fast_Social_routes;
