//@ts-nocheck
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import div from '@mui/material/div';
import "../styleSheets/phot_tab.css";
import "../styleSheets/Listsection.css";
import { media } from "./MockData";
import { useSelector } from "react-redux";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { MultipleImgLightbox } from "../utils/Lightbox/multipleImgLightbox";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="mt-4 mb-4 mx-2">
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Photo_tab() {
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [lightboxProps, setLightboxProps] = React.useState({
    index: 0,
    wholeData: [],
  });
  const handleLightbox = (index, wholeData) => {
    const tempState = wholeData.map((data) => ({
      src: `${imgBaseUrl}/${data?.mediaUrl}`,
      downloadFilename: "image-eatoes",
    }));
    setLightboxProps((prevData) => ({
      ...prevData,
      index,
      wholeData: tempState,
    }));
    setOpen(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="">
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          sx={{
            ".Mui-selected": {
              color: `rgba(245, 195, 119, 1) !important`,
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "rgba(245, 195, 119, 1)",
            },
          }}
        >
          {api["Media Section"]?.mediaCategories?.length !== 0 && (
            <Tab label="All" sx={{ color: "white" }} {...a11yProps(0)} />
          )}
          {api["Media Section"]?.mediaCategories?.map((option, index) => (
            <Tab
              key={index}
              label={option.name}
              sx={{ color: "white" }}
              {...a11yProps(index + 1)}
            />
          ))}
        </Tabs>
      </div>
      <div className="mui-photo">
        <CustomTabPanel value={value} index={0}>
          <div className=" row row-cols-3">
            {api["Media Section"]?.media
              ?.filter((val, index) => index <= 8)
              .map((imageUrl, index) => (
                <div className="photo-item" key={index}>
                  <img
                    className=" img-fluid"
                    src={`${imgBaseUrl}/${imageUrl.mediaUrl}`}
                    onClick={() =>
                      handleLightbox(index, api["Media Section"]?.media)
                    }
                  />
                </div>
              ))}
          </div>
        </CustomTabPanel>
        {api["Media Section"]?.mediaCategories?.map((media, index) => (
          <CustomTabPanel key={index} value={value} index={index + 1}>
            <div className="row row-cols-3">
              {api["Media Section"]?.media
                ?.filter((val) => media._id === val.category._id)
                .filter((val, index) => index <= 8)
                .map((imageUrl, index) => (
                  <div className="photo-item" key={index}>
                    <img
                      className="img-fluid"
                      src={`${imgBaseUrl}/${imageUrl.mediaUrl}`}
                      onClick={() =>
                        handleLightbox(
                          index,
                          api["Media Section"]?.media?.filter(
                            (val) => media._id === val.category._id,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
            </div>
          </CustomTabPanel>
        ))}

        {/* <div
          className="items-list d-flex justify-content-center mt-3 mb-4 fs-6 rounded-3 fw-lighter"
        >
          <div className="m-auto list-item text-center">See all photos</div>
        </div> */}
      </div>

      <MultipleImgLightbox
        wholeData={lightboxProps.wholeData}
        index={lightboxProps.index}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}
