//@ts-nocheck
import React, { useEffect, useState } from "react";
import HeaderSection from "../Social_components/HeaderSection";
import ListSection from "../Social_components/ListSection";
import EventSection from "../Social_components/EventSection";
import classes from "../styleSheets/FoodMenu.module.css";
import Contactfasthome from "./Contactfasthome";
import ContactPhotoSection from "./ContactPhotoSection";
import { FastSocialRoleManager, getAllData } from "../API/fast_social_apis";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setFastSocialData,
  setRoleslData as setRolesData,
} from "../redux/actions";
import { USER } from "../constants/localStorageKeys";
import Navbar from "../Components/Navbar";
import classe from "../styleSheets/Dashboard.module.css";
import { useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../Components/Sidebar";
import { FastSocialHomePage } from "./Fast_Social_Home_Page";
import Event_list from "./Event_list";
import Search from "./Search";
import AccessFooter from "../Components/AccessFooter";
import ProfileFooter from "../Components/ProfileFooter";

const Landing = ({
  event,
  header,
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  setId,
  profileType,
  setProfileType,
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
  adminUserName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down(900));

  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const paramObj = useParams();

  const userName = paramObj.userName || adminUserName;

  const fastSocialAPiData = useSelector((state) => state.fastSocial.apiData);
  const timestamp = useSelector((state) => state.fastSocial.timestamp);

  const currentTime = new Date();
  const timeDiff = currentTime - timestamp;
  const minutesDiff = timeDiff / (1000 * 60);
  const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const ApiCall = async () => {
      setIsLoading(true);
      const dataReceived = await getAllData(userName, navigate);

      if (jwtToken && dataReceived) {
        const roleData = await FastSocialRoleManager(
          dataReceived["Basic Details"].accountId,
        );
        dispatch(setRolesData(roleData));
      }
      dispatch(setFastSocialData(dataReceived));
      setIsLoading(false);
    };

    if (
      location.state?.prevRoute === "menu" ||
      minutesDiff > 20 ||
      Object.keys(fastSocialAPiData).length === 0 ||
      fastSocialAPiData?.["Basic Details"]?.account?.userName !== userName ||
      fastSocialAPiData?.["Basic Details"]?.account?.userName !==
        businessUserName
    )
      ApiCall();
  }, [
    paramObj,
    businessName,
    selectedProfile,
    businessProfileName,
    businessUserName,
    id,
    profileType,
    profile,
    /*
    // paramObj,
    // businessName,
    // selectedProfile,
    // businessProfileName,
    businessUserName,
    // id,
    // profileType,
    // profile,
    dispatch,
    fastSocialAPiData,
    jwtToken,
    navigate,
    userName,
    location.state?.prevRoute,
    minutesDiff,*/
  ]);

  console.log(fastSocialAPiData);

  return (
    <>
      <div className={`${!jwtToken && "m-auto"}`}>
        {/* <section>
        {jwtToken &&
          <Navbar
            businessName={businessName}
            setBusinessName={setBusinessName}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            businessProfileName={businessProfileName}
            setBusinessProfileName={setBusinessProfileName}
            businessUserName={businessUserName}
            setBusinessUserName={setBusinessUserName}
            id={id}
            profileType={profileType}
            setProfileType={setProfileType}
          />
        }
      </section> */}

        {/* <section> */}
        {/* {jwtToken &&
          <Sidebar
            businessName={businessName}
            setBusinessName={setBusinessName}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            businessProfileName={businessProfileName}
            setBusinessProfileName={setBusinessProfileName}
            id={id}
            setId={setId}
            businessUserName={businessUserName}
            setBusinessUserName={setBusinessUserName}
            profileType={profileType}
            setProfileType={setProfileType}
          />

        } */}
        <Routes>
          <Route
            path="/"
            element={
              <FastSocialHomePage
                event={event}
                header={header}
                fastSocialAPiData={fastSocialAPiData}
                isLoading={isLoading}
              />
            }
          />

          <Route
            path="/events"
            element={<Event_list eventlist={fastSocialAPiData?.Events} />}
          />

          <Route path="/search" element={<Search />} />
        </Routes>
        {/* </section> */}
        {isMatch && localStorage.getItem(USER.JWT_ACCESS_TOKEN) && (
          <div>
            <AccessFooter
              businessName={businessName}
              setBusinessName={setBusinessName}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              businessProfileName={businessProfileName}
              setBusinessProfileName={setBusinessProfileName}
              businessUserName={businessUserName}
              setBusinessUserName={setBusinessUserName}
              id={id}
              setId={setId}
              profileType={profileType}
              setProfileType={setProfileType}
              profile={profile}
              setProfile={setProfile}
              fetchProfile={fetchProfile}
              setFetchProfile={setFetchProfile}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Landing;
