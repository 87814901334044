
// @ts-nocheck
import React,{useState,useEffect,useContext} from 'react'
import moment from 'moment'
import classes from '../styleSheets/transactions/transactions.module.css'
import accessStyles from '../styleSheets/QuickAccess.module.css'
import { Grid } from '@mui/material'
import downArrow from '../assets/icons/svgCommonIcons/downArrow.svg'
import { getWalletTransactions } from '../API/WalletAPIs'
import { TRANSACTIONS_API, baseURL } from '../constants/Api'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import downloadIcon from '../assets/icons/BrowserIcon/download.svg'
import divider from '../assets/icons/svgSpecificIcons/divider.svg'
import Document1, { downloadPdf } from './Document'
import { useNavigate } from 'react-router-dom'
import { USER } from '../constants/localStorageKeys'
import CircularUnderLoad from './CircularLoader'
import Transactionlist from './Transactionlist'

const Transactions = ({selectedProfile,setSelectedProfile}) => {
  const currentMonth =moment().format('MMMM');
  const currentYear =moment().format('YYYY')
  const [data,setData]=useState([])
  const [selectedMonth,setSelectedMonth]=useState(currentMonth)
  const[selectedYear,setSelectedYear]=useState(currentYear)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const[transactions,setTransactions]=useState([])
  const navigate= useNavigate()
  const open = Boolean(anchorEl);
  const open1=Boolean(anchorEl1)
  // const {documentStatus, setDocumentStatus} =useContext(PdfContext)
  const handleDownload=(id) =>{
 
      localStorage.setItem(USER.TRANSACTION_ID,id)
      console.log(localStorage.getItem(USER.TRANSACTION_ID),'id');
      
      navigate('/pdf')
  
  }
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  
  };
  const handleClose = (e) => {
    setAnchorEl(null);   
  };
  const handleCloseYear = (e) => {
    setAnchorEl1(null);   
  };
  const filterData=async(list)=>{
    const filteredList=list?.filter(item=>
      item?.year===selectedYear && item?.month ===selectedMonth
    )
    setData(filteredList);
  }
  const callApis = async () => {
    
    const list = await getWalletTransactions(
      `${baseURL}${TRANSACTIONS_API.WALLET_TRANSACTIONS}/?id=${selectedProfile}`,
    );
    setTransactions(list)
   await filterData(list)
  };
 let listOfMonths =moment.months()
 let listOfYears = [currentYear,String(currentYear-1)]
 
  useEffect(() => {
    selectedProfile&& filterData(transactions);
  },[selectedMonth,selectedYear])
    
  useEffect(()=>{
    selectedProfile&& callApis();
  },[selectedProfile])
  return (
    <div className={classes.mainContainer} style={{paddingBottom:'100px'}}>
        <div className={classes.header}>
            <Grid container spacing={0} >
        <Grid item xs={4} className={classes.text}>₹ {(!data ||data?.length===0)?'0.00':<span>{parseFloat((data[0]?.DigitalMenu?.totalExpense)+(data[0]?.FastSocialVerification?.totalExpense)+(data[0]?.TableReservation?.totalExpense)+(data[0]?.WhatsappAPI?.expense)).toFixed(2)}</span>}</Grid>
        <Grid item xs={4} className={classes.textHeader} onClick={handleClick1}>{selectedYear}<span><img src={downArrow}/></span></Grid>
        <Grid item xs={4} className={classes.textHeader} onClick={handleClick}>{selectedMonth} <img src={downArrow}/></Grid>
          </Grid>
        </div>
        <div className={classes.subContainer}>
         {data?.length>0?<div><div>
          {(data[0]?.DigitalMenu?.totalExpense > 0)&&<div><Grid container spacing={1} >
            <Grid item xs={7} ><div className={classes.serviceText}>Digital Menu</div><div className={classes.text2}>For month - {selectedMonth}</div></Grid>
            <Grid item xs={3}> <div className={classes.amountText}>₹{data[0]?.DigitalMenu?.totalExpense}</div>
            {data[0]?.DigitalMenu?.status==='Paid'&&<div className={classes.text3}>{data[0]?.DigitalMenu?.status}</div>}
            {data[0]?.DigitalMenu?.status==='Pending'&&<div className={classes.text4}>{data[0]?.DigitalMenu?.status}</div>}
            {data[0]?.DigitalMenu?.status==='Partially Paid'&&<div className={classes.text5}>{data[0]?.DigitalMenu?.status}</div>}
            </Grid>
            {data[0]?.DigitalMenu?.status === 'Paid'&&<Grid item xs={2} className={classes.downloadText} onClick={()=>handleDownload(data[0]?.DigitalMenu?.uuid)}><div>Download Invoice</div><div><img style={{height:'8px',width:'10px',marginTop:'4px'}}src={downloadIcon}/></div></Grid>}
            </Grid>
            {((data[0]?.FastSocialVerification?.totalExpense > 0)||(data[0]?.WalletCredited?.amount > 0 )||(data[0]?.WhatsappAPI?.expense > 0)||((data[0]?.TableReservation?.totalExpense > 0)))&&<img style={{margin:'10px 0 ',width:'100%'}} src={divider}/>}
         </div>}
           
         {(data[0]?.FastSocialVerification?.totalExpense > 0)&&<div><Grid container spacing={1} >
            <Grid item xs={7} ><div className={classes.serviceText}>Fast Social Verification</div><div className={classes.text2}>For month - {selectedMonth}</div></Grid>
            <Grid item xs={3}> <div className={classes.amountText}>₹{data[0]?.FastSocialVerification?.totalExpense}</div>
            {data[0]?.FastSocialVerification?.status==='Paid'&&<div className={classes.text3}>{data[0]?.FastSocialVerification?.status}</div>}
            {data[0]?.FastSocialVerification?.status==='Pending'&&<div className={classes.text4}>{data[0]?.FastSocialVerification?.status}</div>}
            {data[0]?.FastSocialVerification?.status==='Partially Paid'&&<div className={classes.text5}>{data[0]?.FastSocialVerification?.status}</div>}
            </Grid>
            {data[0]?.FastSocialVerification?.status === 'Paid'&&<Grid item xs={2} className={classes.downloadText} onClick={()=>handleDownload(data[0]?.FastSocialVerification?.uuid)}><div>Download Invoice</div><div><img style={{height:'8px',width:'10px',marginTop:'4px'}}src={downloadIcon}/></div></Grid>}
            </Grid>
            {((data[0]?.WalletCredited?.amount > 0 )||(data[0]?.WhatsappAPI?.expense > 0)||((data[0]?.TableReservation?.totalExpense > 0)))&&<img style={{margin:'10px  0',width:'100%'}} src={divider}/>}
         </div>}

         {(data[0]?.TableReservation?.totalExpense > 0)&&<div><Grid container spacing={1} >
            <Grid item xs={7} ><div className={classes.serviceText}>Table Reservation</div><div className={classes.text2}>For month - {selectedMonth}</div></Grid>
            <Grid item xs={3}> <div className={classes.amountText}>₹{data[0]?.TableReservation?.totalExpense}</div>
            {data[0]?.TableReservation?.status==='Paid'&&<div className={classes.text3}>{data[0]?.TableReservation?.status}</div>}
            {data[0]?.TableReservation?.status==='Pending'&&<div className={classes.text4}>{data[0]?.TableReservation?.status}</div>}
            {data[0]?.TableReservation?.status==='Partially Paid'&&<div className={classes.text5}>{data[0]?.TableReservation?.status}</div>}
            </Grid>
            {data[0]?.TableReservation?.status === 'Paid'&&<Grid item xs={2} className={classes.downloadText} onClick={()=>handleDownload(data[0]?.TableReservation?.uuid)}><div>Download Invoice</div><div><img style={{height:'8px',width:'10px',marginTop:'4px'}}src={downloadIcon}/></div></Grid>}
            </Grid>
            {((data[0]?.WalletCredited?.amount > 0 )||(data[0]?.WhatsappAPI?.expense > 0))&&<img style={{margin:'10px 0 ',width:'100%'}} src={divider}/>}
         </div>}

         {(data[0]?.WhatsappAPI?.expense > 0)&&<div><Grid container spacing={1} >
            <Grid item xs={7} ><div className={classes.serviceText}>Whatsapp API</div><div className={classes.text2}>For month - {selectedMonth}</div></Grid>
            <Grid item xs={3}> <div className={classes.amountText}>₹{data[0]?.WhatsappAPI?.expense}</div>
            {data[0]?.WhatsappAPI?.status==='Paid'&&<div className={classes.text3}>{data[0]?.WhatsappAPI?.status}</div>}
            {data[0]?.WhatsappAPI?.status==='Pending'&&<div className={classes.text4}>{data[0]?.WhatsappAPI?.status}</div>}
            {data[0]?.WhatsappAPI?.status==='Partially Paid'&&<div className={classes.text5}>{data[0]?.WhatsappAPI?.status}</div>}
            </Grid>
            {data[0]?.WhatsappAPI.status === 'Paid'&&<Grid item xs={2} onClick={()=>handleDownload(data[0]?.WhatsappAPI?.uuid)}className={classes.downloadText}>
              <div>Download Invoice</div><div><img style={{height:'8px',width:'10px',marginTop:'4px'}}src={downloadIcon} /></div></Grid>}
            </Grid>
            {((data[0]?.WalletCredited?.amount > 0 ))&&<img style={{margin:'10px 0 ',width:'100%'}} src={divider}/>}
         </div>}

         {(data[0]?.WalletCredited?.amount > 0)&&<div><Grid container spacing={1} >
            <Grid item xs={7} ><div className={classes.serviceText}>Wallet Credited</div><div className={classes.text2}>For month - {selectedMonth}</div></Grid>
            <Grid item xs={3}> <div className={classes.amountText}>₹{data[0]?.WalletCredited.amount}</div>
            {data[0]?.WalletCredited?.status==='Credited'&&<div className={classes.text3}>{data[0]?.WalletCredited?.status}</div>}
            {data[0]?.WalletCredited?.status==='Failed'&&<div className={classes.text4}>{data[0]?.WalletCredited?.status}</div>}
            
            </Grid>
            {data[0]?.WalletCredited.status === 'Credited'&&<Grid item xs={2} 
            onClick={()=>{handleDownload(data[0]?.WalletCredited?.uuid)}
          }className={classes.downloadText}>
              <div>Download Invoice</div><div><img style={{height:'8px',width:'10px',marginTop:'4px'}}src={downloadIcon}/></div></Grid>}
            </Grid>
         </div>}
            
          </div>
           {/* <div style={{display:'none'}}><Document1 documentStatus={documentStatus} setDocumentStatus={setDocumentStatus}/></div> */}
           </div>
         :<div className={classes.centerText}>
          No Transactions Found
          </div>}
        </div>
        <Menu sx={{height:'200px'}}
        id="demo-positioned-menu"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {listOfMonths?.map((item)=>{
          return <MenuItem onClick={(e)=>{setSelectedMonth(item)
            handleClose(e)
          }}>{item}</MenuItem>
        })}
        
        
      </Menu>
      <Menu sx={{height:'200px'}}
        id="demo-positioned-menu"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleCloseYear}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {listOfYears?.map((item)=>{
          return <MenuItem onClick={(e)=>{setSelectedYear(item)
            handleCloseYear(e)
          }}>{item}</MenuItem>
        })}
        
        
      </Menu>
        </div>
        
  )
}

export default Transactions