// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SubCategorySidebar from "./SubCategorySidebar";
import "../../styleSheets/subcategory-header.css";
import SearchIcon from "../../assets/icons/svgCommonIcons/search-icon.svg";
import { MenuFilter } from "../ItemsSection/Menu_Filter/menu_filter";
import { menuFilterData } from "../ItemsSection/Menu_Filter/filter_handlers";
import ImageBox from "./ImageBox";
import _ from "lodash";

const SubCategoryHeaderSection = ({
  reservoirData,
  data,
  openSearch,
  setOpenSearch,
  setWholeData,
}) => {
  const [data1, setData1] = useState([]);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));
  const [query, setQuery] = useState("");
  const [filterStates, setFilterStates] = useState({
    veg: false,
    nonVeg: false,
    egg: false,
    chefRecommend: false,
    "": true,
    na: true,

    // for handling old data:-
    get Veg() {
      return this.veg;
    },
    get "Non-Veg"() {
      return this.nonVeg;
    },
    get "Contains Egg"() {
      return this.egg;
    },
  });
  const [itemData, setitemData] = useState([]);
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(true);
  function subCat() {
    setActive(!active);
    // setId(id)
  }
  const fetchData1 = async () => {
    setData1(data?.categories);
  };

  const callApis = async () => {
    const filteredData = menuFilterData(reservoirData?.items, filterStates);

    const newCheck = filteredData?.filter((i) => {
      if (query === "") {
        return i;
      } else if (i?.itemName?.toLowerCase().includes(query.toLowerCase())) {
        return i;
      }
    });
    const filteredSubCategoryIdArray = newCheck.map(
      (element) => element.subCategory_id,
    );

    const finalSubCategory = reservoirData.subCategories?.filter((element) =>
      filteredSubCategoryIdArray.includes(element._id),
    );
    console.log(finalSubCategory, ")))))))))))))))))))))))))");

    setWholeData((prevdata) => ({
      ...reservoirData,
      items: newCheck,
      subCategories: finalSubCategory,
    }));
    // setWholeData([])
  };

  useEffect(() => {
    fetchData1();
  }, [data]);

  useEffect(() => {
    callApis();
  }, [filterStates, query]);
  console.log(data, ">>>>>>>>>");

  /*   const handleSearchQuerryInput = (e) => {
    const TempArr = _.cloneDeep(itemData);
    setQuery(e.target.value);

    const filterArray = TempArr?.filter((i) => {
      if (query === "") {
        return i.subCategory_id;
      } else if (
        i?.itemName?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return i.subCategory_id;
      }
    });
    console.log(filterArray, "HELLLO AMIT");
  }; */
  return (
    <div className="container-1">
      <div className="mx-2">
        <div
          className={`d-flex overflow-auto  position-sticky top-50`}
          style={{ scrollbarWidth: "none", background: "white" }}
        >
          {data1?.map((item, index) => {
            if (item._id == id) {
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    background: "#9EC172",
                    margin: "1rem 0.4rem",
                    borderRadius: "0.6rem",
                    boxShadow: "0px 6px 4px 0px #00000040",
                    height: "8.5rem",
                  }}
                  onClick={subCat}
                >
                  {<ImageBox item={item} />}
                  <div
                    style={{ textWrap: "nowrap" }}
                    className={`py-2 text-center`}
                  >
                    {item?.category.length > 7
                      ? (item?.category).slice(0, 7).concat("...")
                      : item?.category}
                  </div>
                </div>
              );
            }
            if (item._id != id) {
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    background: "#C9D0C0",
                    margin: "1rem 0.4rem",
                    borderRadius: "0.6rem",
                    boxShadow: " 0px 3px 4px 0px #00000040",
                    height: "8.5rem",
                  }}
                  onClick={() => {
                    subCat();
                    setId(item?._id);
                  }}
                >
                  {show && <ImageBox item={item} />}
                  <div
                    style={{ textWrap: "nowrap" }}
                    className={`py-2 text-center`}
                  >
                    {item?.category.length > 7
                      ? (item?.category).slice(0, 7).concat("...")
                      : item?.category}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="header-search-new d-flex justify-content-between mx-3 align-items-center pt-3 pb-4">
        <div
          className="rounded-pill d-flex overflow-hidden"
          style={{
            background: "#F5F5F7",
            boxShadow: "0px 4px 4px 0px #00000040",
            width: "80%",
          }}
        >
          <img src={SearchIcon} className="ms-4 me-3" />
          {/* <span className=" text-opacity-50 text-dark">Search</span> */}
          <input
            style={{ border: "none", outline: "none", background: "#F5F5F7" }}
            placeholder="Search"
            className="w-100 p-2 h-100 border-0"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {/* <div> */}
        <MenuFilter
          filterStates={filterStates}
          setFilterStates={setFilterStates}
        />
        {/* </div> */}
      </div>
      <SubCategorySidebar id={id} data={data} setWholeData={setWholeData} />
    </div>
  );
};

export default SubCategoryHeaderSection;
