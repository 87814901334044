// @ts-nocheck

import { imgBaseUrl } from "../constants/ImageBaseUrl"
import { checkImageUrl } from "./checkImageUrl"

export const imageChecker = (imgData, classNames, placeholderImg) => {
  return imgData ?
    checkImageUrl(imgData) == "" ? (
      <img
        src={imgData}
        className={`${classNames}`}
      />
    ) : (
      <img
        src={`${imgBaseUrl}/${imgData}`}
        className={`${classNames}`}
      />
    )
    :
    <img src={placeholderImg} className={`${classNames}`} />
}