// @ts-nocheck
import React, { useState, useEffect, CSSProperties } from "react";
import classes from "../styleSheets/fastSocialVerification.module.css";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import backButton from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import tickIcon from "../assets/icons/svgSpecificIcons/tick.svg";
import tick from "../assets/images/images/tick.svg";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { getSocialProfile } from "../API/authAPIs";
import { USER } from "../constants/localStorageKeys";
import {
  baseURL,
  CURRENT_BALANCE_API,
  MENU_API,
  WALLET_SERVICES_API,
} from "../constants/Api";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { getbalance } from "../API/WalletAPIs";
import RechargeAmount from "../payment/RechargeAmount";
import fastSocialReducer from "../redux/reducer_components/fast_social_reducer";
import CircularProgress from "@mui/material/CircularProgress";
import { fastSocialVerification } from "../API/WalletServicesAPI";
import ClipLoader from "react-spinners/ClipLoader";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#7ECAB0",
};
const FastSocialVerification = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [profile, setProfile] = useState([]);
  const [active, setActive] = useState(false);
  const [balance, setBalance] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleNextButton = () => {
    setActive(true);
  };
  const handleBackButton = () => {
    if (active) {
      setActive(false);
    } else {
      navigate("/QuickAccess");
    }
  };
  const handleConfirmButton = async () => {
    if (balance < 589) setOpenModal(true);
    else {
      setOpenModal1(true);
      setLoading(true);
      const fastSocialResponse = await fastSocialVerification(navigate);
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleClose1 = () => {
    setOpenModal1(false);
    navigate("/QuickAccess");
  };
  const profileId = localStorage.getItem(USER.businessId);
  const callApis = async () => {
    const data = await getSocialProfile(
      `${baseURL}${MENU_API.GET_SOCIAL_PROFILE}/?id=${profileId}`,
    );
    setProfile(data);
    const balanceResponse = await getbalance(
      `${baseURL}${CURRENT_BALANCE_API.CURRENT_BALANCE}/?id=${profileId}`,
      navigate,
    );
    setBalance(balanceResponse.balance);
    console.log(balanceResponse, "bal");
  };
  useEffect(() => {
    callApis();
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      `/FastSocialVerification`,
      "FastSocialVerification Page",
    );
  }, []);

  return (
    <>
      <TopHeader name="Verification" />
      <div className={classes.mainContainer}>
        {/* <Grid container spacing={0}>
        <Grid item xs={2}><img onClick={handleBackButton} className={classes.backButton}src={backButton}/></Grid>
        <Grid item xs={8}className={classes.headerText}>Verification</Grid>
        </Grid>  */}

        <div className={classes.subContainer}>
          <div className={classes.titleText}>
            {" "}
            Build Trust With Eatoes Verified{" "}
          </div>
          <div className={classes.profilePic}>
            {profile?.image && (
              <img
                style={{ width: "100px", height: "100px" }}
                src={`${imgBaseUrl}/${profile?.image}`}
              />
            )}
          </div>
          <div className={classes.profileName}>
            {profile?.userName} <img src={tickIcon} />{" "}
          </div>
        </div>
        {active ? (
          <>
            <div className={classes.instructionsContainer}>
              <div className={classes.text1}>
                You will be billed Rs. 499 per month on this account.
              </div>
              <div className={classes.text2}>
                Once you confirm, it might take up to 48 hours for the
                verification badge to appear on your profile.
              </div>
            </div>

            <div className={classes.text3} style={{ paddingTop: "14px" }}>
              By tapping “Confirm and pay”, you agree to our Terms and Policies{" "}
              <a className="privacyText">Read our Terms and Policies</a>. Your
              wallet will be automatically charged Rs. 499 every month. You can
              cancel the subscription at least 24 hours before your next payment
              date.
            </div>

            <div className={classes.buttonDiv}>
              <button className={classes.button2} onClick={handleConfirmButton}>
                Confirm and pay
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.instructionsContainer}>
              <div className={classes.text1}>A Verified Badge</div>
              <div className={classes.text2}>
                Your Audience Can Trust That You Are A Real Person.
              </div>
              <div className={classes.text1} style={{ paddingTop: "19px" }}>
                Increased account security
              </div>
              <div className={classes.text2}>
                Worry less about impersonation with proactive identity
                monitoring.
              </div>
            </div>

            <div className={classes.text3} style={{ paddingTop: "16px" }}>
              By tapping “Next”, you agree that the information you provide for
              your eatoes verified subscription is subject to our{" "}
              <a className="privacyText">Privacy Policy</a>
            </div>

            <div className={classes.buttonDiv}>
              <button className={classes.nextButton} onClick={handleNextButton}>
                NEXT
              </button>
            </div>
          </>
        )}
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={customizeMUIComponents.lowBalanceModal}>
            <div className={classes.paddingModal}>
              <div className={classes.modalTitle}>
                Attention: Low Balance Alert!
              </div>
              <div>
                <div className={classes.modalContent}>
                  Uh-oh! It seems like your account balance is running low, and
                  access to your Social Profile is at risk. To continue enjoying
                  all the social goodness, kindly recharge your account now.
                  It's quick, easy, and ensures uninterrupted access to your
                  favorite platform.
                </div>
                <div className={classes.modalContent}>
                  Click the button below to top up and keep the good times
                  rolling!{" "}
                </div>
              </div>
              <div className={classes.buttonDiv}>
                <button
                  className={classes.rechargeButton}
                  onClick={() => {
                    setOpenModal(false);
                    RechargeAmount();
                    navigate("/");
                  }}
                >
                  Recharge Now
                </button>
              </div>
              <div className={classes.modalContent}>
                Thank you for staying connected!
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {openModal1 && (
        <Modal open={openModal1} onClose={handleClose1}>
          <Box sx={customizeMUIComponents.lowBalanceModal}>
            <div className={classes.paddingModal}>
              {loading ? (
                <>
                  <ClipLoader
                    color={"#40525D"}
                    loading={loading}
                    cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </>
              ) : (
                <>
                  <div className={classes.centerDiv}>
                    <img
                      style={{
                        width: "130px",
                        height: "130px",
                        marginBottom: "20px",
                      }}
                      src={tick}
                    />
                  </div>
                  <div className={classes.modal_text}>Payment</div>
                  <div className={classes.modal_text}>successful</div>
                </>
              )}{" "}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FastSocialVerification;
