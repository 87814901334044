import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Accordian from "./Accordian";
import { Navigate } from "react-router-dom";
import AnalyticsUtil from "../utils/GoogleAnalytics";
const Accordian1 = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/Accordian`, "Accordian Page");
  }, []);

  return (
    <div style={{ padding: "40px" }}>
      <div style={{ paddingLeft: "auto" }}>
        <CloseIcon
          onClick={() => {
            history.back();
          }}
        ></CloseIcon>
      </div>
      <div style={{ textAlign: "center" }}>
        <h2>FAQ’s</h2>
      </div>
      <Accordian />
    </div>
  );
};

export default Accordian1;
