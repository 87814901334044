import React from 'react';
import ProfileInfo from '../Components/profilepage/ProfileInfo';
import ActionButtons from '../Components/profilepage/ActionButtons';
import TabSection from '../Components/profilepage/TabSection';
import ImageGrid from '../Components/profilepage/ImageGrid';

const ProfilePage: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <ProfileInfo />
      <ActionButtons />
      <TabSection />
      <ImageGrid />
    </div>
  );
};

export default ProfilePage;