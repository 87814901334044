//@ts-nocheck
import React, { useEffect, useState } from "react";
import backArrow from "../assets/icons/Social_icons/backArrow.svg";
import add from "../assets/icons/Social_icons/event_add_icon.svg";
import deleteButton from "../assets/icons/Social_icons/deleteButton.svg";
import { Button, FormControlLabel } from "@mui/material";
import { IOSSwitch } from "../utils/LiveSwitchcustomization";
import "../styleSheets/AddLinksPage.css";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useLocation, useNavigate } from "react-router-dom";
import Delete from "../RestaurantMenu/Modals Layout/Delete";
import DialogBox from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import { updateLiveEvents } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import "../styleSheets/fast_social_events.css";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import { GreenButton } from "../Components/Button.style";
import image from "../assets/icons/Social_icons/image.png";
import delBtn from "../assets/icons/Social_icons/delBtn.png";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const AddEventsorupdatepage = () => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState([]);

  const api = useSelector((state) => state.fastSocial.apiData);

  const [deleteController, setDeleteController] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const [deleteState, setDeleteState] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleLiveStatus = (index) => {
    const tempArr = [...eventData];
    tempArr[index].live = !tempArr[index].live;
    setEventData(tempArr);
  };

  const handleOpenDialog = async () => {
    setOpenDialog(true);
    const dataReceived = await updateLiveEvents(state, eventData);
    updateNestedRedux(
      api,
      "Events",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setEventData(api.Events);
  }, [api]);

  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      `/FastSocial/Updateseventspage`,
      "UpdatesEvents Page",
    );
  }, []);
  return (
    <div className=" Socialformpage d-flex flex-column align-items-center">
      <div className="w-100">
        <div className="d-flex w-100">
          <div className="d-flex m-auto social-profile-header">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <KeyboardBackspaceIcon
                className="back-arrow-click ms-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <div className="mx-auto">
                <h1 className="socio-prof-header me-3">
                  {api?.["Basic Details"]?.account?.userName} | Events
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="ms-4 cursor-pointer" onClick={() => navigate(-1)} >
            <KeyboardBackspaceIcon className="back-arrow-click"/>
          </div>
          <div>
            <span className="links-head-txt ms-3">Events</span>
          </div>
          <div>
            <span
              className="check-text me-4"
              onClick={() => navigate(`/${api["Basic Details"]?.account?.userName}`)}
            >
              Check Profile
            </span>
          </div> */}
        </div>
        <div className=" position-relative">
          {eventData?.map(({ name, date, media, live, _id }, index) => {
            return (
              <div key={`${index}-data`} className="events-box m-auto mb-4">
                <div className="d-flex flex-column h-100 position-relative">
                  <div className="m-auto mt-2 ">
                    {media ? (
                      <img
                        src={`${imgBaseUrl}/${media}`}
                        className={`Link-image-button rounded-3 object-fit-cover `}
                      />
                    ) : (
                      <div className="Link-image-button" />
                    )}
                    <input
                      type="text"
                      value={name}
                      readOnly
                      // onChange={(e) => handelNameChange(e, index)}
                      className="position-absolute text-field-events-top"
                    />
                  </div>
                  <div className="position-absolute del-btn-btn">
                    <img
                      src={delBtn}
                      className="delete-button cursor-pointer"
                      onClick={() => {
                        setDeleteState(true);
                        setDeleteController(_id);
                      }}
                    />
                  </div>
                  <div className="live-div text-white ms-3 position-absolute">
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={live ? "Live" : "Not Live"}
                      labelPlacement="bottom"
                      className="Live-switch"
                      id={`switch`}
                      checked={live}
                      onChange={() => handleLiveStatus(index)}
                    />
                  </div>
                  {/* <div className="d-flex m-auto mt-0 position-absolute del-live-div">
                    <div className="">
                      <img
                        src={deleteButton}
                        className="delete-button cursor-pointer"
                        onClick={() => {
                          setDeleteState(true);
                          setDeleteController(_id);
                        }}
                      />
                    </div>
                    <div className="live-div text-white ms-3">
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1}} />}
                        label={live ? "Live" : "Not Live"}
                        labelPlacement="bottom"
                        className="Live-switch"
                        id={`switch`}
                        checked={live}
                        onChange={() => handleLiveStatus(index)}
                      />
                    </div>
                  </div> */}
                  <div className="w-100">
                    <div className="Link-imagediv w-100 rounded-3 d-flex align-items-center">
                      {/* <div>
                        {media ? (
                          <img
                            src={`${imgBaseUrl}/${media}`}
                            className={`Link-image-button rounded-3 object-fit-cover`}
                          />
                        ) : (
                          <div className="Link-image-button" />
                        )}
                      </div> */}
                      <div className=" w-100">
                        <div className="ms-1">
                          <input
                            type="text"
                            value={name}
                            readOnly
                            // onChange={(e) => handelNameChange(e, index)}
                            className="ms-2 text-field-events"
                          />
                          <img
                            src={image}
                            alt=""
                            className="penc-img-events1"
                          />
                        </div>
                        <hr className="hr-line-styling" />
                        <div className="ms-1 d-flex">
                          <input
                            type="text"
                            value={date}
                            readOnly
                            // onChange={(e) => handelUrlChange(e, index)}
                            // placeholder={`your ${name} link`}
                            className="ms-2 mb-2 text-field-events pe-none"
                          />
                          <span
                            onClick={() =>
                              navigate(
                                `/FastSocial/editeventform?accountId=${state}&eventId=${_id}`,
                              )
                            }
                            className="edit_btn_label my-auto mx-2 cursor-pointer"
                          >
                            <img
                              src={image}
                              alt=""
                              className="penc-img-events2"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="Add-button my-4 bg-transparent">
          <Select
            onChange={handleChange}
            value={selectedOption}
            styles={customStyles}
            options={options}
            menuShouldScrollIntoView
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={e.imageUrl}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                />
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
            isSearchable={false}
          />
        </div> */}

        <div
          className="Add-button text-center "
          style={{ background: "#7ECAB0" }}
          onClick={() =>
            navigate(`/FastSocial/editeventform?accountId=${state}`)
          }
        >
          <Button
            className="add-btn-text pt-2"
            style={{ textTransform: "none" }}
          >
            Create Event
          </Button>
        </div>

        {/* <div
          className=" pb-5 d-flex justify-content-center ms-4 mt-5"
          style={customizeMUIComponents.row}
        >
          <Button
            varient="contained"
            sx={customizeMUIComponents.editButton2}
            className="text-white Link-savebutton mt-4"
            onClick={handleOpenDialog}

          >
            Save
          </Button>
          < DialogBox open={openDialog} onClose={handleCloseDialog} />
        </div> */}
      </div>

      <Delete
        data={api}
        type={"Event"}
        open={deleteState}
        setOpen={setDeleteState}
        objId={deleteController}
      />
    </div>
  );
};

export default AddEventsorupdatepage;
