//@ts-nocheck
import React, { useState, useEffect } from "react";
import backArrow from "../assets/icons/Social_icons/backArrow.svg";
import "../styleSheets/Bio_page.css";
import "../styleSheets/responsive.css";
import { useLocation, useNavigate } from "react-router-dom";
import DialogBox from "./Dialog";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateAddress } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Addresspage = () => {
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = async () => {
    const dataReceived = await updateAddress(inputValue, state);
    updateNestedRedux(
      api,
      "Contact Details",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue.substring(0, 500));
  };

  useEffect(() => {
    setInputValue(api["Contact Details"]?.address?.content);
  }, [api]);

  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/address`, "Address Page");
  }, []);
  return (
    <div className="Socialformpage d-flex justify-content-center ">
      <div className="container custom-width">
        <div className=" d-flex pt-5">
          <div className="w-100 mx-3 d-flex justify-content-between align-items-center mb-5">
            <div onClick={() => navigate(-1)} className="cursor-pointer">
              <img src={backArrow} />
            </div>
            <div>
              <h1 className="fs-5 ms-4">Address</h1>
            </div>
            <div>
              <span
                className="fs-6 check-text"
                onClick={() =>
                  navigate(`/${api["Basic Details"]?.account?.userName}`)
                }
              >
                Check Profile
              </span>
            </div>
          </div>
        </div>
        <div className="justify-content-center d-flex mb-4">
          <textarea
            type="text"
            id="textInput"
            className="Bio-textfield bg-transparent mb-2 desc-textarea"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex container">
          <div className="ms-auto me-1 me-md-4">
            {500 - (inputValue?.length ? inputValue?.length : 0)} words
          </div>
        </div>
        <div
          className="d-flex justify-content-center mt-5 pb-5 "
          style={customizeMUIComponents.row}
        >
          <Button
            varient="contained"
            sx={customizeMUIComponents.editButton2}
            className="text-white Link-savebutton ms-4"
            onClick={handleOpenDialog}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Addresspage;
