// @ts-nocheck

import { useLocation, useNavigate, useParams } from "react-router-dom"
import Pop_up_2option from "../../utils/Pop_up_2option"
import { useDispatch } from "react-redux"
import { resetAllReduxStates, setFastSocialData } from "../../redux/actions"
import { imageChecker } from "../../utils/imageChecker"
import PlacholderImage from "../../RestaurantMenu/Images/placeholders/image.jpeg"
import { web_url } from "../../constants/web_url"

export const SearchResultStr = ({data}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const associatedTags = data?.account?.associatedCategories || []

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(resetAllReduxStates())
    navigate(`/${data?.account?.userName}`, {state: {
      prevRoute: "search"
    }})
  }

  return (
    <div className="d-flex search-result-outer-div cursor-pointer" onClick={handleClick}>
      <div className="search-result-photo-div">

        {imageChecker(data?.account?.image, "w-100 h-100", PlacholderImage)}

      </div>

      <div className="m-auto ms-2 ">

        <div className="title-div mb-1">
          <div className="main-text">{data?.account?.name}</div>
          <div className="search-result-category">
            {associatedTags && associatedTags.length !==  0 && 
            <div className="text-capitalize">
              ({associatedTags.map(tag => tag.name).join(", ")})
            </div>
            }
          </div>
        </div>

        <div className="main-text">
        {data?.account?.userName}
        </div>

      </div>

      <div className="mt-2 ms-auto">
        <Pop_up_2option name={[]} urlProp={`${web_url}/${data?.account?.userName}`} />
      </div>
    </div>
  )
}