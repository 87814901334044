// @ts-nocheck
import React, { useEffect, useState } from "react";
import CloseIcon from "../../assets/icons/svgCommonIcons/new_close_icon.svg"
import "../../styleSheets/Drag_Drop/Carousel_Image.css"
import { DndContext, DragOverlay, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { UseSortableFile } from "../../utils/dnd/useSortableFile";
import { MappedCoverImage } from "./mappedCoverImage";
import { autoScrollProp, handleDragEnd, handleDragOver, handleDragStart, modifierProp } from "../../utils/dnd/handlers";
import { DraggableItem } from "../../utils/dnd/draggableItem";
import { Loader } from "../../utils/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteCarousel from "./DeleteCarousel";

import {restrictToVerticalAxis} from "@dnd-kit/modifiers"


export const CarouselImageDragDrop = ({ setCoverDragState, carouselData, setCarouselData, data, setData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [coverData, setCoverData] = useState([])
  const [activeId, setActiveId] = useState(null)
  const [loaderState, setLoaderState] = useState(false)
  const [uploadLoader, setUploadLoader] = useState(false)
  const [dltState, setDltState] = useState(false)
  const [controllerIndex, setControllerIndex] = useState(0)
  const [imgEdit, setImgEdit] = useState(false)
  const [images, setImages] = useState(false)

  useEffect(() => {
    setCoverData(carouselData)
  }, [carouselData])

  return (
    <div style={{ position: "relative", paddingBottom: "15rem", minHeight: "100svh"}}>
      <div className="d-flex justify-content-end">
        <div onClick={() => setCoverDragState(false)} className="position-fixed user-select-none mt-2 me-2 p-3 rounded-circle d-flex justify-content-center align-items-center" style={{ backgroundColor: "#848484" }}>
          <img src={CloseIcon} />
        </div>
      </div>

      <DndContext
        autoScroll={autoScrollProp}
        modifiers={[restrictToVerticalAxis]}
        sensors={useSensors(
          useSensor(MouseSensor, {
            activationConstraint: {
              delay: 500,
              tolerance: 5
            }
          }),
          useSensor(TouchSensor, {
            activationConstraint: {
              delay: 500,
              tolerance: 5
            }
          })
        )}

        onDragStart={(e) => handleDragStart(e, setActiveId)}
        onDragOver={(e)=>handleDragOver(e, setCarouselData)}
        onDragEnd={()=>handleDragEnd(setActiveId, data?.HomePageImagesurl, setData, coverData, "carousel", navigate, setLoaderState, dispatch)}
        collisionDetection={closestCenter}
      >
        <SortableContext items={coverData || []}>

          {coverData?.map((data, index) => {
            return (
              <>
                <div style={{ marginTop: "46px" }} className="d-flex justify-content-evenly align-items-center">
                  <div className="text-center" style={{ width: "2.5rem", height: "22px" }}>
                    {index + 1}.
                  </div>

                  <UseSortableFile
                    propId={data}
                    MappedEl={<MappedCoverImage
                      data={data}
                    />
                    }
                  />

                  <div>
                    <img className="cursor-pointer" onClick={() => { setControllerIndex(index); setDltState(true) }} style={{width: "2.5rem", height: "22px"}} src="/assets/delete-carousel.svg" />
                  </div>

                </div>
                <div onClick={()=>{ setControllerIndex(index + 1); setImgEdit(true)}} className="add_btn mx-auto" style={{ marginTop: "17px" }}>
                  <img src="/assets/add-carousel.svg" />
                </div>

              </>
            )

          })}

          <DragOverlay>
            {activeId ? <DraggableItem
              id={activeId}
              isDragOverlay={true}
              MappedEl={<MappedCoverImage
                data={activeId}
              />
              }
              isDragging />
              : null
            }
          </DragOverlay>

        </SortableContext>

      </DndContext>

      <Loader open={loaderState} setOpen={setLoaderState} message={"Loading...."} />

      {dltState &&
        <DeleteCarousel
          type="Caraousel"
          objId={carouselData[controllerIndex]._id}
          data={carouselData}
          setData={setCarouselData}
          delImage={dltState}
          setDelImage={setDltState}
        />
      }

    </div >
  )
}