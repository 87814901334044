import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../styleSheets/calendarUtil.css";
import { useLocation, useNavigate } from "react-router-dom";

function MyCalendar() {
  type ValuePiece = Date | null;
  type Range<T> = [T, T];
  type Value = ValuePiece | Range<ValuePiece>;

  const [date, setDate] = useState<Value>(new Date());
  const navigate = useNavigate()
  const location = useLocation()

  const onChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDate(value);
    navigate("../select-time", {state: {
      ...location.state, selectedDate : value
    }})

  };

  const formatShortWeekday = (locale: any, date: Date) =>
    ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];

  return (
    <div className="calendar-util">
      <Calendar
        minDate={new Date()}
        onChange={onChange}
        value={date}
        formatShortWeekday={(locale, date) => formatShortWeekday(locale, date)}
      />
    </div>
  );
}

export default MyCalendar;
