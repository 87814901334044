//@ts-nocheck
import React from 'react';

const EventCard = ({ title, description, buttonLabel, image }) => {
  return (
    <div style={styles.cardContainer}>
      <img src={image} alt={title} style={styles.image} />
      <div style={styles.detailsContainer}>
        <h3 style={styles.title}>{title}</h3>
        <p style={styles.description}>{description}</p>
        <div style={styles.buttonContainer}>
          <button style={styles.button}>{buttonLabel}</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    minWidth: '200px',       // Minimum width for the card
    maxHeight: '300px',      // Minimum height for the card
    textAlign: 'center',
    marginBottom: '5px',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
  },
  detailsContainer: {
    padding: '5px 15px 10px 10px',
  },
  title: {
    fontFamily: 'Inter',      // Added Inter font family
    fontSize: '12px',         // Font size as provided
    fontWeight: '500',        // Font weight 500 as provided
    lineHeight: '24px',       // Line height as provided
    textAlign: 'center',      // Center the text horizontally
    marginBottom: '3px',
  },
  description: {
    fontSize: '8px',
    color: 'gray',
    lineHeight: '5px',     
  },
  buttonContainer: {
    display: 'flex',           // Use flexbox to center
    justifyContent: 'center',  // Center the button horizontally
    alignItems: 'center',      // Align items in the center vertically
  },
  button: {
    backgroundColor: '#1a73e8',
    width: '110px',
    height: '24px',
    color: 'white',
    border: 'none',
    borderRadius: '50px',
    padding: '10px',
    cursor: 'pointer',
    marginTop: '3px',
    fontSize: '10px',
    display: 'flex',           // Ensure the button text is centered
    justifyContent: 'center',  // Center text horizontally
    alignItems: 'center',      // Center text vertically
  },
  buttonHover: {
    backgroundColor: '#155ab2',
  },
};

export default EventCard;
