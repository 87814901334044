// @ts-nocheck
import React from "react";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { useNavigate } from "react-router-dom";

export const Events = ({...props}) => {
  const {eventData, accountUserName} = props
  console.log(eventData.date, "bro consoled")
  const navigate = useNavigate()

  function formatDate(inputDate) {
    const date = new Date(inputDate);
      const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const day = date.toLocaleDateString('en-US', { weekday: 'short' });
    const dateNumber = date.getDate();
    const month = monthNames[date.getMonth()];
  
    const formattedDate = `${dateNumber} ${month}, ${date.getFullYear()}`;
  
    return formattedDate;
  }
  return(
    <div className="menu-event-outer-div" style={{background: `linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,1)), url(${imgBaseUrl}/${eventData.media})`, backgroundSize: "cover", backgroundRepeat: "no-repeat",height:"13rem"}}
    onClick={() => navigate(`/${accountUserName}/events`, {state: {
      prevRoute: "menu"
    }})}
    >
      <div className="menu-event-date user-select-none text-white">{formatDate(eventData.date)}</div>
    </div>
  )
}