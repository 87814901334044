// @ts-nocheck

import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "../utils/LiveSwitchcustomization";
import "../styleSheets/AddLinksPage.css";
import _ from "lodash"



export const ContactPageLinks = ({switchName, icon, placeholder, sizeException=false, elementToRender, switchPath, contentPath, wholeData, handleStates}) => {
  const [switchState, setSwitchState] = useState(false)
  const [contentState, setContentState] = useState("")
  

  useEffect(()=>{
    setSwitchState(_.get(wholeData, switchPath))
    setContentState(_.get(wholeData, contentPath))
  }, [wholeData])

  

  return (
    <>
      
        <div className="d-flex mt-4">          
          <div className="w-100">
            <div className="Link-imagediv w-100 d-flex align-items-center">
              <div className={` radius icon-border`}>
                <img src={icon} className={`Link-image ${!sizeException && "icon-size"}`} />
              </div>
              <div className=" w-100">
                {/* <div className="ms-1">
                  <input
                    type="text"
                    className="text-field"
                    name={"url"}
                    placeholder={placeholder}
                    value={contentState}
                    onChange={(e)=>{
                      setContentState(e.target.value)
                      handleStates(e, contentPath)
                    }}
                  />
                </div> */}
                {elementToRender}
              </div>
            </div>
          </div>

          <div className="live-div mt-2 ms-2">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              label={switchState ? "Live" : "Not Live"}
              labelPlacement="bottom"
              className="Live-switch"
              value={"wp-live"}
              checked={switchState}
              onChange={(e)=>handleStates(e, switchPath, "switch")}
            />
          </div>
        </div>
    </>
  )
}