// @ts-nocheck
import React, { useEffect, useState } from "react";
import _ from "lodash";
import "../../styleSheets/AddLinksPage.css";

export const PhoneInput = ({ type, cCodePath, urlPath, wholeData, setWholeData, handleStates }) => {
  const [cCodeState, setCCodeState] = useState("")
  const [urlState, setUrlState] = useState("")

  const handleStatesWithValidator = (e, path) => {
    setWholeData((prevState) => {
      let inputValue = e.target.value
      inputValue = inputValue.replace(/[^0-9]/g, '').substring(0, 10)
      const tempState = _.cloneDeep(prevState)
      _.set(tempState, path, inputValue)
      return tempState
    })
  }

  useEffect(()=>{
    setCCodeState(_.get(wholeData, cCodePath))
    setUrlState(_.get(wholeData, urlPath))
  }, [wholeData])
  return (
    <>
      <div className="d-flex w-100 align-items-center">

        {/* {type == "whatsapp" &&
          <span className="ms-1 light-text">https://wa.me/</span>
        } */}

        {/* <div className="ms-1 c-code">
          <input
            type="text"
            className="text-field me-2"
            name={"url"}
            placeholder="+91"
            value={cCodeState}
            onChange={(e)=>{
              setCCodeState(e.target.value)
              handleStates(e, cCodePath)
            }}
          />
        </div> */}
        <div className="p-number">
          <input
            type="tel"
            className="text-field-contacts"
            pattern="[0-9]"
            value={urlState}
            name={"url"}
            placeholder="add number"
            onChange={(e)=>{
              setUrlState(e.target.value)
              handleStatesWithValidator(e, urlPath)
            }}
          />
        </div>        
      </div>
      <hr className="hr-line-styling2"/>
    </>
  )
}