import "./Components.scss";

export const FloatingInput = ({
  id,
  label,
  type,
  name,
  value,
  disabled,
  onChange,
  placeholder,
  className,
  style,
  ...rest
}: {
  id?: string;
  label: string;
  type: string;
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  style?: object;
  [key: string]: unknown;
}) => {
  return (
    <label
      htmlFor={id}
      className={`floating-control ${className ? className : ""}`}
    >
      <div className="floating-label">{label}</div>

      <input
        id={id}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        className="floating-input"
        style={{ backgroundColor: "inherit", ...style }}
        {...rest}
      />
    </label>
  );
};

export const FloatingContainer = ({
  id,
  label,
  className,
  children,
  ...rest
}: {
  id?: string;
  label: string;
  className?: string;
  children: React.ReactNode;
  [key: string]: unknown;
}) => {
  return (
    <label
      htmlFor={id}
      className={`floating-control floating-container ${
        className ? className : ""
      }`}
      {...rest}
    >
      <div className="floating-label">{label}</div>

      {children}
    </label>
  );
};
