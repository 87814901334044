import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  OutlinedInput,
  outlinedInputClasses,
  responsiveFontSizes,
} from "@mui/material";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "../images/left.png";
import { CssBaseline } from "@mui/material";
import Ellipse from "../assets/icons/svgCommonIcons/Ellipse.svg";
import { useState } from "react";
import classes from "../styleSheets/LandingPage.module.css";
import addIcon from "../assets/icons/svgCommonIcons/addIcon.svg";
import closeIcon from "../assets/icons/svgCommonIcons/closeIcon.svg";
import { color } from "@mui/system";
const Accordian = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const data = [
    {
      question: "How to start the automation?",
      answer:
        "You can call us at 9634339954 or mail us at support@eatoes.com with your business name, location, contact name, and mobile number. Our team will connect with you at earliest",
    },
    {
      question: "How secure is my payment? ",
      answer:
        " We use Razorpay as our payment partner. They use the utmost security for any account safety and regulations. ",
    },
    {
      question: "What does WhatsApp automation cost?",
      answer:
        "Its cost is Rs. 2499/month/account. Plus, you will have to pay a one-time maintenance fee of 4999 rupees, and the team from connecting will do all the verification and account setup for you.   ",
    },
    {
      question: "What does Google table reservation charges?",
      answer:
        " It costs 999/month for an unlimited number of table bookings. The charges are enabling the service for table reservation at google. Else it costs you zero rupees to make a reservation.",
    },
    {
      question: "What is the per-message cost?",
      answer:
        " It costs 99 paise if you are using connect app WhatsApp account else it will cost you 79 paisa if you have your own business account.   ",
    },
    {
      question: "How to start a table reservation with google?   ",
      answer: "Once this feature is out, we will notify you.   ",
    },
    {
      question: "How to recharge my account?   ",
      answer:
        "You can always click on the recharge button in your dashboard.   ",
    },
    {
      question: "How can I change my opted plans?   ",
      answer:
        "The plans are according to the usage, whichever services you opt-out from. You do not pay for the same. If you are willing to make any changes. Please drop us an email at support@eatoes.com   ",
    },
  ];
  const CustomAccordionSummary = styled(AccordionSummary)(() => {
    return {
      color: `${isExpanded ? "grey" : "pink"}`,
    };
  });
  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <img
          className="expandIconWrapper "
          src={closeIcon}
          style={{ width: "20px" }}
        ></img>
        <img
          className="collapsIconWrapper"
          src={addIcon}
          style={{ width: "20px" }}
        ></img>
      </Box>
    );
  };
  const toggleAcordion = () => {
    setIsExpanded((prev) => !prev);
    console.log("i am clicked");
  };

  return (
    <div className={classes.accordian}>
      <CssBaseline />
      {data.map((item, index) => {
        return (
          <Accordion
            key={index}
            elevation={0}
            disableGutters={true}
            onClick={toggleAcordion}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon />}
              sx={{ letterSpacing: 0.5 }}
            >
              <div className={classes.text3}>
                <span>
                  <img
                    src={Ellipse}
                    style={{ marginRight: "10px", maxWidth: "9px" }}
                  />
                </span>
                <span>{item.question}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.text4}>{item.answer}</div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Accordian;
