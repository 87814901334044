// @ts-nocheck

import { MENU_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import { sessionExpireLogic } from "../utils/functionUtils";

export const DownloadMenuAsExcelAPI = async (navigate, name, data) => {

  const actualURL = data?.selectedPublicTheme === 'v1' ? MENU_API.DOWNLOAD_MENU_AS_EXCEL : MENU_API.DOWNLOAD_MENU_V3_AS_EXCEL
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    userId: userId,
  });

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(
      `${baseURL}${actualURL}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    if (response && response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}-eatoes.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic()
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
