// @ts-nocheck

export const sessionExpireLogic = () => {
  localStorage.clear();
};

export function formatDate(inputDate: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateParts = inputDate.split("-");
  if (dateParts.length !== 3) {
    return "Invalid Date";
  }

  const year = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = parseInt(dateParts[2], 10);

  return `${day} ${month} ${year}`;
}

export const formatWhatsappNumber = (number) =>
  number.replace(/[^a-zA-Z0-9]/g, "");

const checkLive = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key].live) return true;
    else if (obj.live) return true;
  }

  return false;
};

export const whetherToShowContactDetailsOrNot = (mainObj) => {
  if (mainObj) {
    const links = mainObj.links;
    const address = mainObj.address;
    const operationalHour = mainObj.operationalHours;
    return checkLive(links) || checkLive(address) || checkLive(operationalHour);
  }
};

export function formatDateToCustomString(dateString) {
  const date = new Date(dateString);

  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
}

export function dateFormat_3(date) {
  // format: 2024-01-28

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Month is zero-based, so add 1
  const day = date.getDate();
  const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
  return formattedDate
}

export const formatTimeSlot = (timeSlot: string) => {
  const timeSlotArr = timeSlot.split(":").map(Number);
  const hourSlot = timeSlotArr[0];
  const minuteSlot = timeSlot.split(":")[1];

  const formattedHour = hourSlot % 12 === 0 ? 12 : hourSlot % 12;
  return `${
    formattedHour.toString().length < 2 ? `0${formattedHour}` : formattedHour
  }:${minuteSlot}${hourSlot < 12 ? " AM" : " PM"}`;
};