// @ts-nocheck

import foodImage from "../../RestaurantMenu/Images/FoodPlaceholder.jpeg";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import Items from "../ItemsSection/Items";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import accordionIcon from "../../RestaurantMenu/Images/accordionIcon.svg";
import "../css/accordion.css";
import { useEffect, useState } from "react";

export const MappedSubcategory = ({
  index,
  id1,
  item,
  a,
  setId1,
  setActive,
  setSubCat,
  id,
  subCat,
  subCatData,
  setSubCatData,
  expanded,
  handleChange,
}) => {
  const [selectedSubSubCatId, setselectedSubSubCatId] = useState("");

  return (
    <>
      <div
        key={index}
        className={`${id1 == item?._id && id1 != ""} ${item.publish === 0 && "opacity-50"} w-100 ${expanded === `panel${index}` ? "rounded-4 my-3 openAccordion" : "closed"}`}
        style={{ background: "#A3BD83", marginBottom: "1px" }}
      >
        <div
          onClick={() => {
            setId1(item?._id);
            setActive(true);
            setSubCat(item?.subCategory_id);
            setselectedSubSubCatId(item._id);
          }}
          className="pt-1"
        >
          <Accordion
            expanded={expanded === `panel${index}`}
            elevation={0}
            onChange={() => handleChange(`panel${index}`)}
            className="rounded-5"
            sx={{
              "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
              "& .MuiAccordionDetails-root": {
                display: expanded ? "block" : "none",
              },
              background: "#A3BD83",
            }}
          >
            <AccordionSummary
              expandIcon={<img src={accordionIcon} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                ".MuiAccordionSummary-content , .MuiAccordionSummary-content.Mui-expanded":
                  { margin: 0 },
              }}
            >
              <div className="d-flex align-items-center" id="scroll">
                {item.cloudinary_url ? (
                  <>
                    {checkImageUrl(item?.cloudinary_url) == "" ? (
                      <img
                        src={item.cloudinary_url}
                        className={` rounded-3 my-2 me-2`}
                        style={{
                          width: "40px",
                          height: "40px",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                        }}
                      />
                    ) : (
                      <img
                        src={`${imgBaseUrl}/${item?.cloudinary_url}`}
                        className={` rounded-3 my-2 me-2`}
                        style={{
                          width: "40px",
                          height: "40px",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div
                    className={` rounded-3 my-2 me-2`}
                    style={{
                      width: "40px",
                      height: "40px",
                      // boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  ></div>
                )}
                <span className="text-nowrap text-start">
                  {item?.subSubCategory?.length > 20
                    ? item?.subSubCategory?.slice(0, 23)?.concat("...")
                    : item?.subSubCategory}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <Items
                id1={id}
                id={id1}
                id2={subCat}
                a={a}
                subdata={subCatData}
                setsubData={setSubCatData}
                data={id?.data}
                setWholeData={id?.setWholeData}
                selectedSubSubCatId={selectedSubSubCatId}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};
