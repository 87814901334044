export const dinersData = Array.from({ length: 10 }, (_, index) => ({
  name: index + 1,
  value: index + 1,
}));

let timeArray = [];

for (let hour = 12; hour <= 23; hour++) {
  for (let minute of ["00", "30"]) {
    let formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    let time = `${formattedHour}:${minute}${hour < 12 ? "AM" : "PM"}`;
    timeArray.push(time);
  }
}
export const timeData = timeArray.map((time) => ({
  name: time,
  value: time,
}));
