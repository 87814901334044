//@ts-nocheck
import React, { useEffect, useRef } from 'react';

const Popup = ({ isOpen, content, onClose }) => {
  const popupRef = useRef(null);

  // Close the popup if clicking outside the content area
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Add event listener when the component is mounted
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Remove event listener when the component is unmounted or when isOpen changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Function to format the shift timing
  const formatShift = (shift) => {
    const { open, close } = shift || {};
    if (open && close) {
      return `${open} - ${close}`;
    }
    return 'Not Specified';
  };

  // Render the weekly timings
  const renderTimings = (hours) => {
    return Object.keys(hours)
      .filter((day) => !['live', 'openAll'].includes(day)) // Exclude unwanted properties
      .map((day) => {
        const { check, timings } = hours[day] || {};
        const { shift1 = {}, shift2 = {} } = timings || {};

        const shift1Formatted = formatShift(shift1);
        const shift2Formatted = formatShift(shift2);

        return (
          <div
            key={day}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
              padding: '10px',
              borderRadius: '10px',
              color: '#fff', // White text for contrast
            }}
          >
            <span
              style={{
                width: '80px',
                padding: '10px',
                borderRadius: '8px',
                backgroundColor: '#4b5b63', // Button-like background
                textAlign: 'center',
              }}
            >
              {day.slice(0, 3).toUpperCase()}
            </span>
            {check ? (
              <div
                style={{
                  width: '150px',
                  padding: '10px',
                  borderRadius: '8px',
                  width: '180px',
                  border: '2px solid #3c4850', // Border for timing
                  textAlign: 'start',
                  backgroundColor: '#27343c',
                  color: '#ffffff',
                  maxHeight: '50px', // Ensure the height doesn't get too high
                  overflow: 'hidden', // Prevent overflow content from showing
                  display: 'flex',
                  alignItems: 'center', // Vertically center the content
                  justifyContent: 'center', // Horizontally center the content
                }}
              >
                {shift1Formatted}
              </div>
            ) : (
              <span>Not Specified</span>
            )}
          </div>
        );
      });
  };

  return (
    <>
      <div
        className="popup-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <div
          className="popup-content"
          ref={popupRef}
          style={{
            backgroundColor: '#27343C',
            color: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            width: '350px',
            textAlign: 'center',
          }}
        >
          <div style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
            {renderTimings(content)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
