// @ts-nocheck
import React, { useState } from "react";
import Select from "react-select";
import "../../styleSheets/react_select_css.css";
import { reactSelectStyle } from "../react_select_styles";

export const EventActionInput = ({
  selectedOption,
  setSelectedOption,
  options,
  handleStatesChange,
  btnTitle,
  setBtnTitle,
  setUrl,
}) => {
  const handleChange = (selected) => {
    setUrl("");
    setSelectedOption(selected);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ gap: "10px", paddingBottom: "13px" }}
    >
      <Select
        options={options}
        styles={reactSelectStyle}
        value={selectedOption}
        onChange={handleChange}
        isSearchable={false}
        menuShouldScrollIntoView={true}
        classNamePrefix={"event_action_input"}
      />
      <input
        value={btnTitle}
        onChange={(e) => handleStatesChange(e, setBtnTitle)}
        type="text"
        placeholder="Enter Button Text"
        className="w-100 field-border p-2 rounded event_custom_placeholder"
      />
    </div>
  );
};
