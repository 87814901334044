// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPublishStatus } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import publishImage from "../../RestaurantMenu/Images/publish.png";
import unPublishImage from "../../RestaurantMenu/Images/unpublish.png";
import "../../styleSheets/confirmation-dialog.css";
import "../../styleSheets/more-options.css";
import { setApiData } from "../../redux/actions";

const Unpublish = ({
  type,
  objId,
  data,
  setData,
  publish,
  setWholeData,
  objData,
  open,
  setOpen,
}) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const dispatch = useDispatch();
  const handleClose = () => setOpen((prev) => ({ ...prev, pubUnpub: false }));
  async function ClickedPublish() {
    const Data = await addPublishStatus(objId, type, 1, navigate);
    if (type == "Category") {
      await setData(Data.body.menu.categories);
    }
    if (type == "SubCategory") {
      await setWholeData(Data.body.menu);
    }
    if (type == "Item") {
      await setWholeData(Data.body.menu);
    }
    dispatch(setApiData(Data.body.menu));
    setOpen((prev) => ({ ...prev, pubUnpub: false }));
  }
  async function ClickedUnPublish() {
    const Data = await addPublishStatus(objId, type, 0, navigate);
    if (type == "Category") {
      await setData(Data.body.menu.categories);
    }
    if (type == "SubCategory") {
      await setWholeData(Data.body.menu);
    }
    if (type == "Item") {
      await setData(Data.body.menu.items);
    }
    dispatch(setApiData(Data.body.menu));
    setOpen((prev) => ({ ...prev, pubUnpub: false }));
  }

  const nameObj = {
    Category: [objData.category],
    SubCategory: [objData.subCategory],
    Item: [objData.itemName],
  };

  const alertFunc = (type, name, isPublishing) => {
    if (isPublishing) {
      if (type == "Category")
        return `publishing "${name}" will publish all sub category and dish item under this category`;
      if (type == "SubCategory")
        return `publishing "${name}" will publish all dish item under this sub category`;
      if (type == "Item") return "";
    } else {
      if (type == "Category")
        return `unpublishing "${name}" will unpublish all sub category and dish item under this category`;
      if (type == "SubCategory")
        return `unpublishing "${name}" will unpublish all dish item under this sub category`;
      if (type == "Item") return "";
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.unpublish} className="rounded-3">
          {publish == "0" ? (
            <>
              <div className="p-2">
                <p className="unpublish-heading">
                  Do you want to publish "{nameObj[type]}"?
                </p>
                <p className="unpublish-alert">
                  {alertFunc(type, nameObj[type], true)}
                </p>
              </div>

              <div className="d-flex justify-content-evenly pt-2 pb-1">
                <button className="no-btn" onClick={handleClose}>
                  Go Back
                </button>
                <button className="yes-btn" onClick={ClickedPublish}>
                  Publish
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="p-2">
                <p className="unpublish-heading">
                  Do you want to unpublish "{nameObj[type]}"?
                </p>
                <p className="unpublish-alert">
                  {alertFunc(type, nameObj[type], false)}
                </p>
              </div>

              <div className="d-flex justify-content-evenly pt-2 pb-1">
                <button className="no-btn" onClick={handleClose}>
                  Go Back
                </button>
                <button className="yes-btn" onClick={ClickedUnPublish}>
                  Unpublish
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Unpublish;
