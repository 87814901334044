// @ts-nocheck

import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import "../styleSheets/share.css";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { RWebShare } from "react-web-share";
import LinkCopyIcon from "../RestaurantMenu/Images/header-share.svg";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Share = ({ url, type }) => {
  const [open, setOpen] = React.useState(false);

  // Initialize AnalyticsUtil
  const analytics = new AnalyticsUtil(import.meta.env.VITE_GOOGLE_TRACKING_ID);

  const handleOpen = () => {
    setOpen(true);
    analytics.sendEvent("Modal", "Opened", "Share modal opened");
  };

  const handleClose = () => {
    setOpen(false);
    analytics.sendEvent("Modal", "Closed", "Share modal closed");
  };

  return (
    <div>
      <div
        style={{ cursor: "pointer" }}
        onClick={handleOpen}
        onKeyUp={() => false}
      >
        {type === "whole-menu" ? "Share to" : "Share QR"}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.shareBoxStyle}>
          <div className="Share-Outer-Div">
            {type === "whole-menu" ? (
              <div
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  analytics.sendEvent(
                    "Link",
                    "Copied",
                    "Link copied to clipboard",
                  );
                }}
                onKeyUp={()=>false}
                className="Share-Divs"
                style={{ cursor: "pointer" }}
              >
                <img
                  className="Share-icons-small"
                  alt="icon"
                  src={LinkCopyIcon}
                />
                <span className="Share-Texts">Copy Link</span>
              </div>
            ) : (
              <RWebShare
                data={{
                  url: { url },
                }}
                onClick={() =>
                  analytics.sendEvent(
                    "Share",
                    "WebShare",
                    "Shared via web share",
                  )
                }
              >
                <div className="Share-Divs" style={{ cursor: "pointer" }}>
                  <img
                    className="Share-icons"
                    alt="icon"
                    src="/assets/share-to-icon.svg"
                  />
                  <span className="Share-Texts">Share to</span>
                </div>
              </RWebShare>
            )}

            <FacebookShareButton
              className="d-flex w-100"
              url={url}
              onClick={() =>
                analytics.sendEvent("Share", "Facebook", "Shared on Facebook")
              }
            >
              <div className="Share-Divs">
                <img
                  alt="icon"
                  className="Share-icons-small"
                  src="/assets/share-fb-icon.svg"
                />
                <span className="Share-Texts">Share to Facebook</span>
              </div>
            </FacebookShareButton>

            <TwitterShareButton
              className="d-flex w-100"
              url={url}
              onClick={() =>
                analytics.sendEvent("Share", "Twitter", "Shared on Twitter")
              }
            >
              <div className="Share-Divs">
                <img
                  className="Share-icons-small"
                  alt="icon"
                  src="/assets/share-twitter-icon.svg"
                />
                <span className="Share-Texts">Share to Twitter</span>
              </div>
            </TwitterShareButton>

            <WhatsappShareButton
              className="d-flex w-100"
              url={url}
              onClick={() =>
                analytics.sendEvent("Share", "WhatsApp", "Shared on WhatsApp")
              }
            >
              <div className="Share-Divs">
                <img
                  alt="icon"
                  className="Share-icons"
                  src="/assets/share-whatsapp-icon.svg"
                />
                <span className="Share-Texts">Share to WhatsApp</span>
              </div>
            </WhatsappShareButton>

            {type === "whole-menu" && (
              <RWebShare
                data={{
                  url: { url },
                }}
                onClick={() =>
                  analytics.sendEvent(
                    "Share",
                    "WebShare",
                    "Shared via web share",
                  )
                }
              >
                <div className="Share-Divs" style={{ cursor: "pointer" }}>
                  <img
                    className="Share-icons"
                    src="/assets/share-to-icon.svg"
                    alt="icon"
                  />
                  <span className="Share-Texts">Share to</span>
                </div>
              </RWebShare>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Share;
