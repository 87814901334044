import React, { useState } from "react";
import TopHeader from "../utils/TopHeader";
import "../styleSheets/Visibilitytimings.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack } from "@mui/system";
import { Dayjs } from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toastify CSS
import { useLocation } from "react-router-dom";
import { date } from "yup";
import { baseURL, MENU_API } from "../constants/Api";

const Visibilitytimings = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const catId = queryParams.get("catId");
  const subCatId = queryParams.get("subCatId");
  console.log(subCatId, "subCatId");

  const checkSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsClosed(event.target.checked);
  };

  const handleSubmit = async () => {
    // Function to combine date and time into a single Date object
    const combineDateAndTime = (date: Dayjs | null, time: Dayjs | null) => {
      if (!date || !time) return null;
      return new Date(
        date.year(),
        date.month(),
        date.date(),
        time.hour(),
        time.minute(),
      );
    };

    const finalStartTime = combineDateAndTime(selectedDate, startTime);
    const finalEndTime = combineDateAndTime(selectedDate, endTime);

    const menu_id = localStorage.getItem("MenuId");
    const category_id = catId;
    const openTime = finalStartTime;
    const closeTime = finalEndTime;
    const subCategory_id = subCatId;

    const data = {
      menu_id,
      category_id,
      openTime,
      closeTime,
      subCategory_id,
    };

    try {
      setLoading(true);
      const response = await fetch(`${baseURL}/${MENU_API.TIMING_CHANGE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success("Category times updated successfully", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        toast.error(result.message || "Failed to update category times", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating category times:", error);
      toast.error("Error updating category times", {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TopHeader name="Visibility Timing" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="btn-container mt-4">
          <label className="switch btn-color-mode-switch">
            <input
              value="1"
              id="color_mode"
              name="color_mode"
              type="checkbox"
              onChange={checkSwitch}
            />
            <label
              className="btn-color-mode-switch-inner"
              data-off="OPEN"
              data-on="CLOSE"
              htmlFor="color_mode"
            ></label>
          </label>
        </div>
      </div>

      <div className="date-picker-container mt-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Select a Day"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              slotProps={{
                textField: { fullWidth: true },
              }}
            />
            {/*             <SlCalender /> */}
          </DemoContainer>
          <Stack
            spacing={3}
            direction="row"
            className="mt-4"
            //style={{ margin: "15px" }}
          >
            <div className="time-slot">
              <TimePicker
                label="Starts with"
                value={startTime}
                onChange={(newTime) => setStartTime(newTime)}
                slotProps={{
                  textField: { size: "small", fullWidth: true },
                }}
                disabled={isClosed}
              />
            </div>
            <div className="time-slot">
              <TimePicker
                label="Ends with"
                value={endTime}
                onChange={(newTime) => setEndTime(newTime)}
                slotProps={{
                  textField: { size: "small", fullWidth: true },
                }}
                disabled={!isClosed}
              />
            </div>
          </Stack>
        </LocalizationProvider>
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={handleSubmit} className="btn btn-primary  save-button">
          Submit
        </button>
      </div>

      {/* ToastContainer to display toasts */}
      <ToastContainer />
    </>
  );
};

export default Visibilitytimings;
