import React from "react";
import PropTypes from "prop-types";

const Title = ({ text }) => {
  return <h3 className="mb-2 custom-h3">{text}</h3>;
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Title;
