// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { displayRazorPay } from "./Razorpay";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { AnalyticEvents } from "../utils/GoogleAnalytics/Events";

const RechargeAmount = ({ onClose }) => {
  // const navigate= useNavigate();

  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView("/RechargeAmount", "RechargeAmount Page");
    setAnalytics(analyticsInstance);
  }, []);

  useEffect(() => {
    Swal.fire({
      text: "Enter the Amount",
      input: "text",
      inputPlaceholder: "Enter the amount",
      inputAttributes: {
        required: "true",
      },
      showCancelButton: true,
      confirmButtonText: "OK",
      confirmButtonColor: "green",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
    }).then((result) => {
      if (analytics) {
        if (result.value != null) {
          const amount = result.value;
          displayRazorPay(+amount);
          /* analytics.sendEvent("Wallet", "Amount Entered", amount.toString()); */
          analytics.sendEvent(
            AnalyticEvents.Wallet.Recharge,
            AnalyticEvents.WalletAction.Amount,
            amount.toString(),
          );
          console.log("Result: " + amount);
        } else {
          /*  analytics.sendEvent(
            "Wallet",
            "Amount Not Entered",
            "User did not enter the amount",
          ); */
          analytics.sendEvent(
            AnalyticEvents.Wallet.Recharge,
            AnalyticEvents.WalletAction.Amount,
            "User Did not Entered The Amount",
          );
          return "enter";
        }
      }
      onClose();
    });
  }, [analytics]);

  return null;
};
// else if (result.isDismissed) {
//   //  history.back()
//   navigate('/')
//   }
// else if(result.value== null){
//     Swal.fire({text:"Please Enter the amount to Continue"})
//     .then((result) => {
//         RechargeAmount();
//       })
// }
// else{
//   Swal.fire({text:"Please Enter the amount to Continue",
//   confirmButtonColor: "green",
//   confirmButtonText: "OK",  })
//   .then((result) => {
//       RechargeAmount();
//     })
// }

export default RechargeAmount;
