// @ts-nocheck

import React, { useState } from "react";
import { addCategory, addItem, addSubCategory } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import "../../styleSheets/react-transition.css";
import "../../styleSheets/Modal-Box.css";
import { Overlay_Animation } from "../../utils/Overlay-animation";
import { Item_Layout } from "../ItemsSection/Item-Layout";
import { Warning } from "./Warning";

export const Add_Item = ({ type, setData, data, objId, setItemCount, setWholeData, callApis }) => {
  // console.log('type of component---->', type)
  // console.log('data prop----->', data)
  // console.log('objId---->', objId)
  const [categoryName, setCategoryName] = useState("");
  const [dishName, setDishName] = useState("");
  const [price, setPrice] = useState("");
  const [dishType, setDishType] = useState("na");
  const [varietyInputsState, setVarietyInputsState] = useState([]);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(false);
  const [chefR, setChefR] = useState(false);
  const [addDesc, setAddDesc] = useState(false);
  const [descInput, setDescInput] = useState("");
  const [warningState, setWarningState] = useState(false);
  const navigate = useNavigate();
  const maxLimit = 250;

  // console.log(dishName, '<---- dishName')
  // console.log(price, '<----- price')
  // console.log(varietyInputsState, '<----- input states')
  // // console.log(btnState, '<----- btnState')
  // console.log(dishType, '<------ dishType')

  const handleDescChange = (e) => {
    const inputValue = e.target.value;

    setDescInput(inputValue.substring(0, maxLimit));
  };

  const handleOverlayShow = () => {
    if (!objId) setWarningState(true);
    else setShowOverlay(true);
  };
  const handleEnter = () => setShowDiv(true);

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes("add_item_div") ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay(false);
      setShowDiv(false);
      setDishName("");
      setPrice("");
      setDishType("na");
      setVarietyInputsState([]);
      setChefR(false);
      setAddDesc(false);
      setDescInput("");

      setError(false);
    }
  };

  const selectedImage = "";
  // console.log('data obtained in typesssss ----->', data)

  const editDetails = async () => {
    console.log("editdetails called");
    const response = await addItem(
      objId,
      dishName,
      price,
      dishType,
      descInput,
      varietyInputsState,
      chefR,
      navigate,
    );
    await setData(response.body.menu.items);
    await setWholeData(response.body.menu)
    setItemCount((itemCount) => itemCount + 1);
    setDishName("");
    setPrice("");
    setDishType("na");
    setVarietyInputsState([]);
    setChefR(false);
    setAddDesc(false);
    setDescInput("");
    setShowOverlay(false);
    setShowDiv(false);
    await callApis()

  };

  return (
    <div id="edit">
      <div id="edit" style={{ cursor: "pointer" }} onClick={handleOverlayShow}>
        Add {type === "Category" && "New"} {type}{" "}
      </div>
      <Warning
        message="You can't add items without subcategory, Add Subcategory first"
        warningState={warningState}
        setWarningState={setWarningState}
      />

      <Overlay_Animation
        className="add_item_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <Item_Layout
            dishName={dishName}
            setDishName={setDishName}
            price={price}
            setPrice={setPrice}
            setDishType={setDishType}
            varietyInputsState={varietyInputsState}
            setVarietyInputsState={setVarietyInputsState}
            chefR={chefR}
            setChefR={setChefR}
            addDesc={addDesc}
            setAddDesc={setAddDesc}
            descInput={descInput}
            handleDescChange={handleDescChange}
            onClickBtn={editDetails}
          />
        }
      />
    </div>
  );
};
