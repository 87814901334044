
import React, { useState, useRef, useEffect } from 'react';
import Header from './Header';

// SVG component for the three dots icon
const ThreeDotsIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Main ProfileInfo component
const ProfileInfo: React.FC = () => {
  // State variables for managing popups and dialogs
  const [showPopup, setShowPopup] = useState(false);
  const [showHamburgerPopup, setShowHamburgerPopup] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Refs for DOM elements
  const popupRef = useRef<HTMLDivElement>(null);
  const hamburgerPopupRef = useRef<HTMLDivElement>(null);
  const threeDotsRef = useRef<HTMLDivElement>(null);
  const confirmDialogRef = useRef<HTMLDivElement>(null);

  // Toggle functions for popups
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setShowHamburgerPopup(false);
  };

  const toggleHamburgerPopup = () => {
    setShowHamburgerPopup(!showHamburgerPopup);
    setShowPopup(false);
  };

  // Handlers for delete functionality
  const handleDelete = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    // Implement delete functionality here
    console.log("Deleted");
    setShowConfirmDialog(false);
    setShowPopup(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmDialog(false);
  };

  // Effect for handling clicks outside of popups and dialogs
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the popup and the three dots icon, and if the confirm dialog is not shown
      if (popupRef.current && !popupRef.current.contains(event.target as Node) &&
          threeDotsRef.current && !threeDotsRef.current.contains(event.target as Node) &&
          !showConfirmDialog) {
        setShowPopup(false);
      }
      
      // Check if the click is outside the hamburger popup
      if (hamburgerPopupRef.current && !hamburgerPopupRef.current.contains(event.target as Node)) {
        setShowHamburgerPopup(false);
      }
      
      // Check if the click is outside the confirm dialog
      if (confirmDialogRef.current && !confirmDialogRef.current.contains(event.target as Node)) {
        setShowConfirmDialog(false);
      }
    };

    // Add event listener when popups or dialogs are shown
    if (showPopup || showHamburgerPopup || showConfirmDialog) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup, showHamburgerPopup, showConfirmDialog]);

  return (
    <div style={{ 
      position: 'relative',
      height: '350px',
    }}>
      {/* Header component with hamburger menu */}
      <Header onHamburgerClick={toggleHamburgerPopup} />

      {/* Background image container */}
      <div style={{
        backgroundImage: "url('https://s3-alpha-sig.figma.com/img/344e/13d6/b3473dde093d8f70f15545ea0ab30904?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JSUBR5O2fIonQXhKhG05ZQBdxohrPUkuNDPf76nI07h6BPlGmF9U1nGWhYiCv99sdoHQ~NRftEaTt2aqsg2j9vRq-QWR5JarAWIv1nCFNSoy6QM5YxRdoyVAC1gA1UDW-eJXmimLE7Emwyu2XiKRGLA5B6PeZKEFbARjm9Lc9BI4SWjuC~x0s66rdGu30OE2Ph6Rnj4KLV2QfYRpkuHa-OUPNsReZsylYq4hZTJSNPcbm9MMon8gaIxTaAbn1GHPQql72Xp9lmGJpB2xbeUK6fn9KSYgvqIs6Gi6KXb7hF4hs-18BUG9sJv~ftphXjxXY~dI1i0TciqOgBD-zA~gxg__')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '414px',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}>
        {/* Three dots icon for options */}
        <div 
          ref={threeDotsRef}
          style={{
            position: 'absolute',
            top: '90px',
            right: '15px',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: '30%',
            padding: '4px',
            zIndex: 1001,
          }} 
          onClick={togglePopup}
        >
          <ThreeDotsIcon />
        </div>
      </div>

      {/* Popup for edit and delete options */}
      {showPopup && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }} />
          <div 
            ref={popupRef}
            style={{
              position: 'absolute',
              width: '140px',
              top: '90px',
              right: '15px',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
              zIndex: showConfirmDialog ? 1001 : 1002,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              opacity: showConfirmDialog ? 1 : 1,
              pointerEvents: showConfirmDialog ? 'none' : 'auto',
            }}
          >
            <div style={{ padding: '10px', cursor: 'pointer' }}>Edit Photo</div>
            <div style={{ padding: '10px', cursor: 'pointer' }} onClick={handleDelete}>Delete</div>
          </div>
        </>
      )}

      {/* Confirmation dialog for delete action */}
      {showConfirmDialog && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1003,
        }}>
          <div 
            ref={confirmDialogRef}
            style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              width: '70%',
              height: '193px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '20px',
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}>
              <h3 style={{ 
                textAlign: 'center', 
                margin: '0',
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '19px',
              }}>
                Are You Sure, You Want
              </h3>
              <h3 style={{ 
                textAlign: 'center', 
                margin: '0',
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '19px',
              }}>
                To Delete?
              </h3>
              <p style={{ 
                textAlign: 'center', 
                color: '#666', 
                margin: '10px 0 0', 
                fontWeight: 400, 
                fontSize: '10px' 
              }}>
                This action can't be undone
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              <button
                onClick={handleCancelDelete}
                style={{
                  padding: '10px 30px',
                  borderRadius: '50px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f0f0f0',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                style={{
                  padding: '10px 30px',
                  borderRadius: '50px',
                  border: 'none',
                  backgroundColor: '#FF5A5A',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Hamburger menu popup */}
      {showHamburgerPopup && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }} />
          <div 
            ref={hamburgerPopupRef}
            style={{
              position: 'absolute',
              width: '140px',
              top: '15px',
              right: '20px',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
              zIndex: 1002,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ padding: '10px', cursor: 'pointer' }}>Share</div>
            <div style={{ padding: '10px', cursor: 'pointer' }}>Logout</div>
          </div>
        </>
      )}

      {/* Profile information section */}
      <div style={{
        position: 'absolute',
        top: '245px',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '50px',
        padding: '20px',
        paddingTop: '30px',
        zIndex: 2,
      }}>
        {/* Followers, profile picture, and following count */}
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-around', 
          alignItems: 'center', 
          marginBottom: '30px',
        }}>
          <div style={{ textAlign: 'center', fontSize: '14px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>254</div>
            <div style={{ color: '#666' }}>Followers</div>
          </div>
          <img 
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT8z8ildDLmflI6owdEKK8f1vKtTB2W1c4QA&s" 
            alt="Ironhill Logo" 
            style={{ 
              width: '120px', 
              height: '120px', 
              borderRadius: '50%',
              border: '3px solid white',
              marginTop: '-80px',
            }} 
          />
          <div style={{ textAlign: 'center', fontSize: '14px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>2540</div>
            <div style={{ color: '#666' }}>Following</div>
          </div>
        </div>

        {/* Profile name and description */}
        <div style={{ textAlign: 'center', marginBottom: '15px' }}>
          <h2 style={{ margin: '0 0 5px', fontSize: '20px', fontWeight: 'bold' }}>Iron Hill India</h2>
          <p style={{ margin: '0', fontSize: '14px', color: '#666' }}>Bengaluru Brewery, Bengaluru Pub, Bar</p>
        </div>

        {/* Edit profile button */}
        <button style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#27343C',
          color: 'white',
          border: 'none',
          borderRadius: '50px',
          cursor: 'pointer',
        }}>
          Edit Profile
        </button>
      </div>
    </div>
  );
};

export default ProfileInfo;