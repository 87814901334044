// @ts-nocheck
import React, { useEffect, useState } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import icon from "../../RestaurantMenu/Images/repete.png";
import icon1 from "../../RestaurantMenu/Images/revolt.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { MENU_API, baseURL } from "../../constants/Api";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import iconPlaceHolder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import utilityFunctions from "../../utils/UtilityFunction";
import { menuAPI, authMenuApi } from "../../API/MenuApi";
import { USER } from "../../constants/localStorageKeys";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { HeaderOptions } from "../CommonFiles/Header-Options";
import { useSelector } from "react-redux";
const SubCategoryHeader = ({ data, userData, setUserData }) => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const image = api["Basic Details"]?.account?.image;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  // const name = localStorage.getItem("name");
  const [menuData, setMenuData] = useState();

  const navigate = useNavigate();
  // const data=menuAPI(`${baseURL}${MENU_API.MENU}${name}`)

  const locale = "en";
  const [today, setDate] = React.useState(new Date());
  const { getImages } = utilityFunctions();

  const callApis = async () => {
    //const data = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    setMenuData(data);
  };

  // useEffect(()=>{
  //   callApis()
  // },[])
  const nameInputted = localStorage.getItem("name");
  const UserRestraunt = localStorage.getItem(USER.USERNAME);
  React.useEffect(() => {
    callApis();
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 40 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [data]);
  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  const handleOnClickTitle = () => {
    navigate(`/${name}`, { replace: true });
  };

  return (
    <div className={`${classes.header} position-sticky top-0 z-2`}>
      <div className={classes.headerMargin}>
        <div className={classes.row}>
          <div
            style={{ color: "white", margin: "5px 5px" }}
            onClick={() => {
              menuAccessState
                ? navigate(`/edit/menu/${name}`)
                : navigate(`/menu/${name}`);
            }}
          >
            <KeyboardArrowLeftIcon />
          </div>
          <span>
            {menuData?.cloudinary_Iconurl && (
              <>
                {checkImageUrl(menuData?.cloudinary_Iconurl) == "" ? (
                  <img
                    src={menuData?.cloudinary_Iconurl}
                    className={classes.menuIcon}
                  />
                ) : (
                  <img
                    src={`${imgBaseUrl}/${menuData?.cloudinary_Iconurl}`}
                    className={classes.menuIcon}
                  />
                )}
              </>
            )}
            {(menuData?.cloudinary_Iconurl == "null" ||
              !menuData?.cloudinary_Iconurl) && (
              <img
                src={`${imgBaseUrl}/${image}` || iconPlaceHolder}
                className={classes.menuIcon}
              />
            )}
          </span>
          <div className={classes.headerText}>
            <span onClick={handleOnClickTitle}>{menuData?.title}</span>
            <div className={classes.headerText1}>{menuData?.address}</div>
          </div>
          <div className="ms-auto">
            <HeaderOptions userImage={userData?.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryHeader;
