// @ts-nocheck
import _ from "lodash"

const typeFilter = (dish, filterStates) => {
  if (dish.varietyArr && dish?.varietyArr?.length !== 0) {
    dish.varietyArr = dish.varietyArr.filter((variety) => {
      return filterStates[variety.type];
    });

    return dish.varietyArr.length > 0;
  } else {
    return filterStates[dish.type];
  }
}


export function menuFilterData(dataArr=[], filterStates) {
  const checkingCheck = _.cloneDeep(dataArr)
  const typeFilterStatesArr = ["veg", "nonVeg", "egg"]
  const isAllTypeFilterFalse = typeFilterStatesArr.every(element => filterStates[element] == false)
  return checkingCheck?.filter((dish) => {
    let checkTypeFilter = true
    if(!isAllTypeFilterFalse) checkTypeFilter = typeFilter(dish, filterStates)

    const checkChefRFilter = filterStates.chefRecommend && Object.hasOwn(dish, "chefRecommend") ? dish.chefRecommend : true;

    return checkTypeFilter && checkChefRFilter
  });
}
