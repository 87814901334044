//@ts-nocheck
const initialState = {
    apiData: {},
  };
  const rolesManagerReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ROLES_DATA':
        return {
          ...state,
          apiData: action.payload,
          timestamp: new Date()
        };
      default:
        return state;
    }
  };
  
  export default rolesManagerReducer;