// @ts-nocheck

import { baseURL } from "../constants/Api";
import { FAST_SOCIAL_API } from "../constants/fast_social";
import { sessionExpireLogic } from "../utils/functionUtils";

export const getAllData = async (searchParam, navigate, type?) => {
  // const userId = localStorage.getItem(USER.businessId);
  // const userId = "fdc36110-41e8-4b14-8289-15684345d7cf";
  // ONLY FOR TESTING PURPOSE

  const token = localStorage.getItem("accessToken");
  let queryCondition: string;
  if (type === "accountId") queryCondition = `accountId=${searchParam}`;
  else queryCondition = `userName=${searchParam}`;

  try {
   const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.GET_ALL_DATA}?${queryCondition}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else if (response.status == 404) {
      alert("Account Not Found with provided username");
      navigate("/");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const updateBio = async (bioState, accountId) => {
  const token = localStorage.getItem("accessToken");
  // pehle body ko JSON mein bhejenge
  const body = JSON.stringify({
    accountId: accountId,
    basicDetails: {
      bio: bioState,
    },
  });

  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.POST_BASIC_DETAILS}`,
      {
        // yahan pe fetch ke paranthesis mein api address dete h
        method: "POST",
        // GET, POST, PUT, DELETE
        // yahan api method bhejte h
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // JWT Token bhejte h
        },
        body: body,
        // yahan pe body pass krte h
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    }if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const updateAnnouncement = async (addressState, accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId: accountId,
    basicDetails: {
      announcement: addressState,
    },
  });
  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.POST_BASIC_DETAILS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const updateAddress = async (addressState, accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId: accountId,
    contactDetails: {
      address: {
        content: addressState,
      },
    },
  });
  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.POST_CONTACT_DETAILS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const updateTiming = async (operationalHours, accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId,
    contactDetails: {
      operationalHours: operationalHours,
    },
  });
  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.POST_CONTACT_DETAILS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const updateContactDetails = async (contactDetailsState, accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId: accountId,
    contactDetails: contactDetailsState,
  });

  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.POST_CONTACT_DETAILS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const renameMediaCategory = async (
  updatedName,
  categoryId,
  accountId,
) => {
  const profileId = localStorage.getItem("businessId");
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    updatedName: updatedName,
    categoryId: categoryId,
    accountId: accountId,
  });

  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.PUT_MEDIA_NAME}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const addUpdateMedia = async (
  tosendImage,
  categoryId,
  indexPosition,
  accountId,
) => {
  const token = localStorage.getItem("accessToken");
  const restaurantName = "AmitConnect1";

  const formData = new FormData();
  formData.append("file", tosendImage);
  formData.append("folderName", `fast-social/${restaurantName}`);
  formData.append("accountId", accountId);
  formData.append("categoryId", categoryId);
  formData.append("indexPosition", indexPosition);

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.POST_IMAGE}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const addUpdateLinks = async (linkArrState, accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId,
    links: {
      link: linkArrState,
    },
  });

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.POST_LINKS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const deletePhoto = async (accountId, deleteImageId) => {
  const token = localStorage.getItem("accessToken");

  const body = JSON.stringify({
    accountId,
  });

  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.DELETE_IMAGE}/${accountId}/${deleteImageId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const FastSocialRoleManager = async (accountId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId,
  });

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.POST_ROLE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const deleteLink = async (accountId, deleteLinkId) => {
  const token = localStorage.getItem("accessToken");

  const body = JSON.stringify({
    accountId,
    deleteLinkId,
  });

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.DELETE_LINKS}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const addUpdateEvent = async (
  accountId,
  live,
  name,
  date,
  actionType,
  actionTitle,
  actionUrl,
  cCode,
  tosendImage,
  eventId,
) => {
  const token = localStorage.getItem("accessToken");
  const restaurantName = "AmitConnect1";

  const event = {
    live,
    name,
    date,
  };

  const action = {
    type: actionType.value,
    title: actionTitle,
    url: actionUrl,
  };
  if (actionType.value == "website") action.cCode = "";
  else action.cCode = cCode.value;

  console.log(action, "acccccccccccccccccccccccc", actionTitle);

  event.action = action;

  const formData = new FormData();
  formData.append("file", tosendImage);
  formData.append("folderName", `fast-social/${restaurantName}`);
  formData.append("event", JSON.stringify(event));
  formData.append("accountId", accountId);

  if (eventId) formData.append("eventId", eventId);

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.POST_EVENT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const updateLiveEvents = async (accountId, updatedData) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId,
    updatedData,
  });

  try {
    const response = await fetch(
      `${baseURL}/${FAST_SOCIAL_API.PUT_LIVE_EVENT}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const deleteEvent = async (accountId, deleteEventId) => {
  const token = localStorage.getItem("accessToken");
  const body = JSON.stringify({
    accountId,
    deleteEventId,
  });

  try {
    const response = await fetch(`${baseURL}/${FAST_SOCIAL_API.DELETE_EVENT}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};

export const searchProfile = async (searchInput) => {
  const body = JSON.stringify({
    searchInput,
  });

  const serverURL = baseURL

  try {
    const response = await fetch(serverURL + "/fast-social/search-profiles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    const res = await response.json();
    return res?.responseData;
  } catch (err) {
    console.log("API Error: ", err);
  }
};
