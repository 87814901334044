export const customizeMUIComponents = {
  saveButton: {
    width: "100px",
    height: "30px",
    marginTop: "50px",
    textTransform: "none",
    border: "0.5px solid #D1D1D1",
    backgroundColor: "#6a75ca",
    color: "#FFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
  },

  qrIcons: {
    fontSize: "12px",
    position: "relative",
    top: "12px",
    transform: "scaleX(-1)",
    cursor: "pointer",
  },
  copyLink: {
    width: "60px",
    margin: "auto",
    fontSize: "10px",
    padding: "0px",
    textTransform: "capitalize",
    textDecoration: "underline",
    marginTop: "5px",
  },

  boxStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 400, sm: 350, xs: 270 },
    // maxWidth:'600px',
    // bgcolor: 'background.paper',
    background: "rgb(204, 200, 200)",
    border: "none",
    borderRadius: "15px",
    boxShadow: 24,
    p: 3,
    zIndex: 100,
  },

  qrBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "10px",
    background: "#F5F5F7",
    boxShadow: 24,
    // p: 3,
    zIndex: 100,
  },

  excelUploadBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 380, sm: 390, xs: 320 },
    borderRadius: "10px",
    background: "#F5F5F7",
    padding: "14px 14px 0 14px",
  },

  shareBoxStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "3px",
    background: "#F0F3F8",

    p: 0.2,
    zIndex: 100,
  },

  footerLeftOptions: {
    fontWeight: 500,
    fontSize: 16,
    color: "#0D0C22",
    justifyContent: "flex-start",
    textTransform: "none",
    paddingRight: 4,
  },
  footersRightOption: {
    color: "#6E6D7A",
    textTransform: "none",
  },
  toggleButtonsPadding: {
    marginLeft: "auto",
  },
  button: {
    background: "white",
    fontSize: "14px",
    fontWeight: "400",
    fontLetter: "-0.5%",
    color: "black",
  },
  buttonActive: {
    background: "#6A75CA",
    fontSize: "14px",
    fontWeight: "400",
    fontLetter: "-0.5%",
    color: "#FFFFFF",
  },

  textModal: {
    margin: "0 auto",
    width: { xs: "100%", sm: 520 },
    bgcolor: "#F5F5F7",
    pb: 17,
    pt: 3,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
    borderRadius: "50px 50px 0px 0px",
  },
  businessModal: {
    margin: "0 auto",
    width: { xs: "100%", sm: 520 },
    bgcolor: "#27343C",
    p: 5,
    pt: 2,
    // alignItems: "center",
    // textAlign: "center",

    textAlign: "start",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
    borderRadius: "50px 50px 0px 0px",
  },

  textModal1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 260, sm: 320 },
    // height: '100%',

    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 3,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
    borderRadius: "10px",
  },

  addItemModal: {
    margin: "0 auto",
    width: { xs: "100%", sm: 520 },
    bgcolor: "#4C565F",
    pb: 3,
    pt: 3,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
    borderRadius: "50px 50px 0px 0px",
  },

  ModalLabel: {
    maxWidth: "300px",
    width: "100%",
    fontSize: "14px",
    // marginLeft: '-5px',
    textAlign: "left",
    color: "#D1D1D1",
  },

  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { xs: 312, sm: 300 },
    width: "17rem",
    outline: "none",
    bgcolor: "#F5F5F7",
    borderRadius: "10px",
    // boxShadow: 24,
    p: 4,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
  },

  unpublish: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { xs: 312, sm: 300 },
    width: "21rem",
    minHeight: "13rem",
    outline: "none",
    bgcolor: "#F5F5F7",
    // boxShadow: 24,
    p: 4,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
  },

  title: {
    fontWeight: 700,
    fontSize: "11px",
  },

  busTitle: {
    fontSize: "18px",

    // textAlign:'c'
    fontWeight: 700,
    // fontSize:'11px'
  },

  imageText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#A1ACB7",
    p: 1,
  },
  text1: {
    fontSize: "12px",
    color: "#222F2C",
    padding: "15px 0 5px 0",
  },

  inputBox: {
    maxWidth: "300px",
    width: "100%",
    border: "none",
    height: "45px",
    fontSize: "13px",
    color: "#222F2C",
    paddingLeft: "10px",
    marginTop: "-10px",
    borderRadius: "3px",
    background: "#FFF",
    padding: "20px",

    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },

  businessAccessInputBox: {
    textAlign: "left",
    maxWidth: "250px",
    width: "100%",
    border: "none",
    height: "35px",
    backgroundColor: "#EBF0F5",
    fontSize: "11px",
    color: "#222F2C",
    paddingLeft: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
  },

  addUserText: {
    textAlign: "left",
    color: "#6A7683",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },

  descriptionBox: {
    maxWidth: "300px",
    width: "100%",
    border: "none",
    height: "90px",
    minHeight: "90px",
    maxHeight: "150px",
    fontSize: "13px",
    color: "#222F2C",
    paddingLeft: "10px",
    marginTop: "-10px",
    borderRadius: "3px",
    background: "#FFF",
    padding: "20px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },

  editButton: {
    width: "120px",
    height: "30px",
    fontSize: "12px",
    border: "1px solid #FF8D5F",
    color: "#FF8D5F",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      width: "120px",
      height: "30px",
      fontSize: "12px",
      border: "1px solid #FF8D5F",
      color: "#FF8D5F",
      marginTop: "20px",
      textAlign: "center",
      textTransform: "none",
    },
  },

  editButton1: {
    width: "120px",
    height: "30px",
    fontSize: "12px",
    border: "1px solid #6E6D7A",
    color: "#6E6D7A",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "none",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  editButton2: {
    whiteSpace: "nowrap",
    width: "120px",
    height: "30px",
    fontSize: "14px",
    backgroundColor: "#7781D1",
    marginTop: "20px",
    textTransform: "none",

    marginLeft: "10px",
    // padding: "20px 70px",
    "&:hover": {
      width: "120px",
      height: "30px",
      backgroundColor: "#6d7cf4",
      textTransform: "none",
    },
  },

  editButton3: {
    width: "120px",
    height: "30px",
    fontSize: "12px",
    backgroundColor: "#6A75CA",
    textTransform: "none",
    marginTop: "20px",
    marginLeft: "10px",

    "&:hover": {
      width: "120px",
      height: "30px",
      fontSize: "12px",
      backgroundColor: "#6A75CA",
      textTransform: "none",
      marginTop: "20px",
      marginLeft: "10px",
    },
  },

  imageModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    alignItems: "center",
    textAlign: "center",
  },

  profilePic: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width:{ md: 400, sm: 350, xs: 270 },
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "none",
    // borderRadius: '15px',
    boxShadow: 24,
    p: 2,
    zIndex: 100,
  },

  text2: {
    textAlign: "left",
    fontSize: "12px",
    color: "#222F2C",
    padding: "5px 0 5px 0",
  },
  radioBtn: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6A7683",
  },

  popupModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 230,
    bgcolor: "#848484",
    borderRadius: "5px",

    boxShadow: "inset 0 0 500px rgb(0 0 0 / 0.4)",
    // boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
    p: 2,
    alignItems: "center",
    textAlign: "center",
    border: "none",
    outline: "none",
  },
  lowBalanceModal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 273, sm: 300 },
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 2,
    outline: "none",
    alignItems: "center",
  },
  warningModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { xs: 312, sm: 300 },

    outline: "none",
    bgcolor: "#F5F5F7",
    // boxShadow: 24,
    p: 4,
    alignItems: "center",
    textAlign: "center",
    boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
  },
};
