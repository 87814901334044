// @ts-nocheck
import React, { useEffect, useState } from "react"
import _ from "lodash"

export const DefaultInput = ({ placeholder, type, wholeData, urlPath, handleStates, isEmailValid = true }) => {
  const [contentState, setContentState] = useState("")
  useEffect(()=>{
    setContentState(_.get(wholeData, urlPath))
  }, [wholeData])
  return (
    <>
      <div className="ms-1">
        <input
          type={type}
          className="text-field-contacts"
          name={"url"}
          placeholder={placeholder}
          value={contentState}
          onChange={(e)=>{
            setContentState(e.target.value)
            handleStates(e, urlPath, false, type)
          }}
        />
        {!isEmailValid && (
          <p style={{ color: 'red', fontSize: "12px", position: "absolute" }}>Please enter a valid email address.</p>
        )}
      </div>
      <hr/>
    </>
  )
}