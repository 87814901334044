// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import OptionIcon from "../../assets/icons/svgCommonIcons/header-options.svg";
import "../../styleSheets/more-options.css";
import classes from "../../styleSheets/FoodMenu.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userPlaceholder from "../../RestaurantMenu/Images/user-placeholder.jpeg";
import Share from "../../Components/Share";
import { USER } from "../../constants/localStorageKeys";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { web_url } from "../../constants/web_url";

export const HeaderOptions = ({ userImage }) => {
  const iconRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [headerOptionEl, setHeaderOptionEl] = useState(null);
  const { name1, name2 } = useParams();

  const optionState = Boolean(headerOptionEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    const Option = event.currentTarget.querySelector("img"); // Find the MoreVertIcon element
    setHeaderOptionEl(Option);
  };
  const handleClose = () => {
    setHeaderOptionEl(null);
  };

  const handleImgClick = () => {
    iconRef?.current?.click();
  };

  const token = localStorage.getItem("accessToken");

  const url = `${web_url}/menu/${name1 ? name1 : name2}`;
  return (
    <div className="d-flex">
      {userImage ? (
        checkImageUrl == "" ? (
          <img
            onClick={handleImgClick}
            src={userImage}
            className={`${classes.menuIcon} my-auto me-2 cursor-pointer`}
          />
        ) : (
          <img
            onClick={handleImgClick}
            src={`${imgBaseUrl}/${userImage}`}
            className={`${classes.menuIcon} my-auto me-2 cursor-pointer`}
          />
        )
      ) : (
        <img
          onClick={handleImgClick}
          className={`${classes.menuIcon} my-auto me-2 cursor-pointer`}
          src={userPlaceholder}
        />
      )}
      <div>
        <IconButton
          ref={iconRef}
          id="header-option"
          aria-controls={optionState ? "header-section" : undefined}
          aria-haspopup="true"
          aria-expanded={optionState ? "true" : undefined}
          onClick={handleClick}
          disableRipple
          className="p-0"
        >
          <img src={OptionIcon} />
        </IconButton>

        <Menu
          onKeyDownCapture={(e) => e.stopPropagation()}
          anchorEl={headerOptionEl}
          open={optionState}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "header-option",
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="main-div">
            {token ? (
              <div
                onClick={() => navigate("/UpdateProfile")}
                className="options d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                Profile settings
              </div>
            ) : (
              <div
                onClick={() =>
                  navigate(`/Login?to_redirect=yes&path=${location.pathname}`)
                }
                className="options d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                Login/Signup
              </div>
            )}
            <div
              className="options d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <Share type="whole-menu" url={url} />
            </div>
            {token && (
              <div
                onClick={() => navigate("/")}
                className="options d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                Go to dashboard
              </div>
            )}
          </div>
        </Menu>
      </div>
    </div>
  );
};
