//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import facebook_icon from "../assets/icons/Social_icons/facebook_icon.svg";
import whatsapp_icon from "../assets/icons/Social_icons/whatsapp_icon.png";
import instagram_icon from "../assets/icons/Social_icons/instagram_icon.svg";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import eatoesLogo from "../Components/logo_eatoes colour 1.png";
import { getUserProfile } from "../API/authMenuAPIs";
import { MENU_API, baseURL } from "../constants/Api";

const instagramBaseUrl = "https://www.instagram.com/";
const whatsappBaseUrl = "https://wa.me/";

import userPlaceholder from "../RestaurantMenu/Images/user-placeholder.jpeg";
import FollowButton from "../pages/FollowButton";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import ButtonGroupFooter from "./ButtonGroupFooter";
import { ContactInfoSection } from "./ContactInfoSection";
import { getAllData } from "../API/fast_social_apis";
import { setFastSocialData } from "../redux/actions";
import { object } from "yup";

const ProfileFooter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paramObj = useParams();

  const userName = paramObj.name1;
  const { pathname } = useLocation();
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const [userApiImage, setUserApiImage] = useState(); // Set default placeholder
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const profileId = api["Basic Details"]?.account?.profileId;
  const [username, setUserName] = useState("");
  const [showFollow, setShowFollow] = useState<boolean>(false);
  const EatoesImg = "connectApp/src/Components/4.png";

  const localStorageRestrauntName = localStorage.getItem("RestrauntName");
  console.log(localStorageRestrauntName, "////////////////////////");
  console.log(api, "YYYYYYYYYYYYYY");

  const image = userApiImage;
  const name = api["Basic Details"]?.account?.name || "Business Name";
  const bio = api["Basic Details"]?.bio || "Business Bio"; //todo it should categories
  const contactLinks = api["Links"]?.links || {};
  const ProfileUserName = api["Basic Details"]?.account?.userName;

  /*   const Data = async () => {
    const returnedData = await getUserProfile(
      `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
    );
    setUserName(returnedData.userName);
    console.log(username, "RRRRRRRRRRRRRRR");
  }; */

  const followText = () => {
    if (
      api["Contact Details"]?.links?.facebook?.live ||
      api["Contact Details"]?.links?.instagram?.live ||
      api["Contact Details"]?.links?.whatsapp?.live
    ) {
      setShowFollow(true);
    } else {
      setShowFollow(false);
    }
    console.log(showFollow, "-------------");
  };

  useEffect(() => {
    const callApi = async () => {
      if (userName) {
        const api = await getAllData(userName, navigate);
      }
      if (api) {
        dispatch(setFastSocialData(api));
      }
    };
    callApi();
  }, []);

  useEffect(() => {
    const fetchUserImage = async () => {
      try {
        const apiUsername = api["Basic Details"]?.account?.userName;
        console.log(apiUsername, "UUUUUUUUUUUUUUUUUUUUU");
        // Adjust the key as per your actual local storage key
        console.log(localStorageRestrauntName, "LLLLLLLLLLLLLLLLLL");

        // Compare API username with the local storage username
        if (apiUsername === localStorageRestrauntName) {
          setIsOwnProfile(true);
        } else {
          setIsOwnProfile(false);
        }

        const imageUrl = api["Basic Details"]?.account?.image
          ? `${imgBaseUrl}/${api["Basic Details"]?.account?.image}`
          : userPlaceholder;
        setUserApiImage(imageUrl);
      } catch (error) {
        console.error("Error fetching user profile image:", error);
        //setUserApiImage(userPlaceholder); // Fallback to placeholder on error
      }
    };

    fetchUserImage();
    followText();
  }, [api]); // Added api to the dependency array

  const handleOnClickNavigate = () => {
    navigate(`/${ProfileUserName}`, { replace: true });
  };

  return (
    <footer
      className="footer-container text-center mt-5"
      style={{
        backgroundColor: "#27343C",
        padding: "40px 20px 30px 20px",
        borderRadius: "20px 50px 0px 0px",
        color: "white",
      }}
    >
      <div
        className="d-flex justify-content-start align-items-center mb-4"
        style={{ position: "relative" }}
      >
        {/* Logo that overlaps out of the div */}
        <img
          src={image} // Use API image or default placeholder
          alt="profile"
          className="img-fluid rounded-circle"
          style={{
            width: "110px",
            height: "110px",
            borderRadius: "50%",
            position: "absolute",
            top: "-90px", // Push the logo up to overlap the div
            left: "0", // Adjust the left positioning if needed
          }}
        />

        {/* Name and bio, with space adjusted for logo */}
        <div className="ms-8 text-start" style={{ marginLeft: "120px" }}>
          <h5
            className="fw-bold"
            style={{
              fontSize: "16px",
              fontWeight: "700px",
              lineHeight: "19.36px",
              letterSpacing: "0.2%",
            }}
            onClick={handleOnClickNavigate}
          >
            {name}
          </h5>
          <div className="mt-2" style={{ fontSize: "14px" }}>
            {api["Basic Details"]?.account?.associatedCategories?.map(
              (category, index, array) => (
                <React.Fragment key={category._id}>
                  <span
                    style={{
                      color: "#E0E0E0",
                      fontSize: "10px",
                      lineHeight: "12.1px",
                      letterSpacing: "0.2",
                    }}
                  >
                    {category.name}
                  </span>
                  {index < array.length - 1 && ", "}
                </React.Fragment>
              ),
            )}
          </div>
        </div>
      </div>

      <FollowButton isOwnProfile={isOwnProfile} profileId={profileId} />

      {/* services */}
      {/*  <ButtonGroupFooter fastSocialAPiData={fastSocialAPiData} /> */}
      <ContactInfoSection api={api} />

      {/* Social Media Links and Branding */}
      <div
        className="d-flex  mt-2 mb-2"
        style={{ justifyContent: "space-between" }}
      >
        {/* Follow Us section on the left */}
        <div className="d-flex flex-column align-items-start">
          <h5
            className="mt-2"
            style={{
              color: "#7ECAB0",
              fontSize: "10px",
              fontWeight: "400",
              fontFamily: "inter",
              marginLeft: "15px",
            }}
          >
            {showFollow ? "Follow us" : null}
          </h5>
        </div>

        {/* Branding on the right */}
        <div className="d-flex flex-column gap-3">
          <div>
            {api["Contact Details"]?.links?.facebook?.live && (
              <a
                href={contactLinks?.facebook}
                className="me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook_icon}
                  alt="Facebook"
                  style={{ width: "24px" }}
                />
              </a>
            )}
            {api["Contact Details"]?.links?.instagram?.live && (
              <a
                href={`${instagramBaseUrl}${api["Contact Details"]?.links?.instagram?.url}`}
                className="me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram_icon}
                  alt="Instagram"
                  style={{ width: "24px" }}
                />
              </a>
            )}
            {api["Contact Details"]?.links?.whatsapp?.live && (
              <a
                href={`${whatsappBaseUrl}${api["Contact Details"]?.links?.whatsapp?.url}`}
                className="me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapp_icon}
                  alt="Whatsapp"
                  style={{ width: "24px" }}
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <h6
          className=""
          style={{
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "'Freehand', cursive",
            color: "white",
          }}
        >
          created using{" "}
          <span
            className="fw-bold"
            style={{ fontSize: "28px", fontFamily: "inter", color: "white" }}
          >
            <img src={eatoesLogo} style={{ objectFit: "cover" }} />
          </span>
        </h6>
      </div>
    </footer>
  );
};

export default ProfileFooter;
