import { useState } from "react";
import { Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "./Components.scss";

const ProfileThumbsSlider = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState();

  return (
    <div className="profile-photo-slider">
      <Swiper
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[Thumbs, Pagination]}
        className="slider-main"
        pagination={true}
        spaceBetween={10}
      >
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={image} alt="Restaurant" width={492} height={333} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={16}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[Thumbs]}
        className="slider-thumbs"
      >
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt="Restaurant Thumbnail"
                width={80}
                height={80}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ProfileThumbsSlider;
