import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularUnderLoad() {
  return (
    <div>
      <CircularProgress disableShrink />
    </div>
  );
}
