// @ts-nocheck
import React, { useEffect, useState } from "react";
import classes from "../../styleSheets/QuickAccess.module.css";
import { Grid, useMediaQuery } from "@mui/material";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { useNavigate, useLocation } from "react-router-dom";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";

const BusinessDropdown = ({
  hideName,
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  profileType,
  setProfileType,
  profile,
  setProfile,
  analytics,
  setAnalytics
}) => {
  // Simplified BusinessDropdown component without arrow
  return (
    <div style={{ backgroundColor: 'white', color: 'black' }}>
      {/* Add your simplified BusinessDropdown content here */}
    </div>
  );
};

const FeedFooter = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName='himanshu',
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  setId,
  profileType,
  setProfileType,
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
  customDesign = false,
  ...props
}) => {
  const [profileImage, setProfileImage] = useState("");
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:500px)");
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(profile, "profileFooter", setProfile, selectedProfile);
    profile?.map?.((i) => {
      if (i?.profileId === selectedProfile) {
        setProfileImage(i?.image);
      }
    });
  }, [profile, selectedProfile, setProfile]);

  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/${selectedProfile}`, "FeedFooter Page");
    setAnalytics(analyticsInstance);
  }, []);

  return (
    <div className={`${classes.footer}`}>
      <div
        className={classes.ellipse}
        style={
          customDesign
            ? {
                width: "inherit",
              }
            : {}
        }
      >
        <Grid container>
          <Grid
            item
            xs={2}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => navigate("/home")}
              className={classes.footerButton1}
              style={{
                width: "35px",
                height: "35px",
                background: `${pathname === "/home" ? "#7ECAB0" : "transparent"}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
              }}
              onKeyUp={() => false}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 18.9998V12.2668C21 11.7242 20.8896 11.1873 20.6756 10.6888C20.4616 10.1902 20.1483 9.74045 19.755 9.36677L13.378 3.30977C13.0063 2.95665 12.5132 2.75977 12.0005 2.75977C11.4878 2.75977 10.9947 2.95665 10.623 3.30977L4.245 9.36677C3.85165 9.74045 3.53844 10.1902 3.3244 10.6888C3.11037 11.1873 3 11.7242 3 12.2668V18.9998C3 19.5302 3.21071 20.0389 3.58579 20.414C3.96086 20.7891 4.46957 20.9998 5 20.9998H19C19.5304 20.9998 20.0391 20.7891 20.4142 20.414C20.7893 20.0389 21 19.5302 21 18.9998Z" fill="#7ECAB0" stroke="#7ECAB0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  fontSize: "10px",
                  textWrap: "nowrap",
                  color: pathname === "/home" ? "white" : "white",
                  marginBottom: "-10px",
                  textTransform: "none",
                }}
              >
                HOME
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div
              className={`${classes.footerButton1} align-items-center gap-2`}
            >
              <div
                onClick={() => navigate(`/${businessUserName}`)}
                className={`${classes.footerButton2}`}
                style={{
                  color: "#27343C",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  cursor: "pointer",
                  marginRight: "0px",
                  padding: "0px",
                  justifyContent: "center",
                  textWrap: "noWrap",
                  background: "white",
                }}
                onKeyUp={() => false}
              >
                {businessProfileName}
              </div>
              <div
                className="cursor-pointer"
                style={{
                  marginTop: "5px",
                }}
              >
                <BusinessDropdown
                  hideName={true}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                  businessProfileName={businessProfileName}
                  setBusinessProfileName={setBusinessProfileName}
                  businessUserName={businessUserName}
                  setBusinessUserName={setBusinessUserName}
                  id={id}
                  profileType={profileType}
                  setProfileType={setProfileType}
                  profile={profile}
                  setProfile={setProfile}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => navigate("/profile")}
              className={classes.footerButton1}
              style={{
                width: "35px",
                height: "35px",
                background: `${pathname === "/profile" ? "#7ECAB0" : "transparent"}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
              }}
              onKeyUp={() => false}
            >
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" stroke-width="2"/>
<path d="M17.0001 14H17.3521C18.0831 14.0002 18.789 14.2674 19.337 14.7513C19.885 15.2352 20.2374 15.9026 20.3281 16.628L20.7191 19.752C20.7542 20.0334 20.7291 20.3191 20.6455 20.5901C20.5618 20.8611 20.4214 21.1112 20.2337 21.3238C20.046 21.5364 19.8152 21.7066 19.5566 21.8232C19.2981 21.9398 19.0177 22.0001 18.7341 22H5.26606C4.98244 22.0001 4.70206 21.9398 4.44351 21.8232C4.18496 21.7066 3.95416 21.5364 3.76644 21.3238C3.57871 21.1112 3.43835 20.8611 3.35467 20.5901C3.27098 20.3191 3.24589 20.0334 3.28106 19.752L3.67106 16.628C3.76176 15.9022 4.11448 15.2346 4.66289 14.7506C5.21131 14.2667 5.91764 13.9997 6.64906 14H7.00006" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  fontSize: "10px",
                  textWrap: "nowrap",
                  color: pathname === "/profile" ? "white" : "white",
                  marginBottom: "-10px",
                  textTransform: "none",
                }}
              >
                PROFILE
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FeedFooter;