import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "../../assets/stylesheets/utilities.min.css";
import "./ReservationLayout.scss";
import Navbar from "../Navbar";
import EatoesLogo from "../../assets/icons/eatoes.png";
import Sidebar from "../Sidebar";

import backButton from "../../assets/icons/backicon.svg";
import moreButton from "../../assets/icons/moreicon.svg";
import { WindowSharp } from "@mui/icons-material";
import BottomSVG from "./BottomSVG";

const menuItemStyles = (
  activeItem: string,
  currentPageName: string,
): React.CSSProperties => ({});

type Pages =
  | "Profile"
  | "Bookings"
  | "Slots"
  | "Manage Working Hours"
  | "Terms and Conditions"
  | "Search Results"
  | "Google Table Reservation";

const ReservationLayout = (props: React.PropsWithChildren) => {
  const navigate = useNavigate();

  const [id, setId] = useState("1");
  const [businessName, setBusinessName] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [profileType, setProfileType] = useState("");
  const [businessProfileName, setBusinessProfileName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [currentPageName, setCurrentPageName] = useState<Pages>("Profile");

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const menuRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!menuVisible) {
      setMenuVisible(true);
      // Calculate the position of the menu relative to the button
      const buttonRect = e.currentTarget.getBoundingClientRect();
      setMenuPosition({ x: buttonRect.left, y: buttonRect.bottom });
    } else {
      setMenuVisible(false);
    }
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (menuRef.current && !menuRef.current.contains(event.currentTarget)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    const root = document.documentElement;
    if (isSidebarOpen) {
      root.classList.add("sidebar-open");
    } else {
      root.classList.remove("sidebar-open");
    }
  }, [isSidebarOpen]);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    if (window.location.href.includes("reservation/bookings")) {
      setCurrentPageName("Bookings");
    } else if (window.location.href.includes("reservation/manage-slots")) {
      setCurrentPageName("Slots");
    } else if (window.location.href.includes("reservation/profile")) {
      setCurrentPageName("Profile");
    } else if (
      window.location.href.includes("reservation/manage-working-hours")
    ) {
      setCurrentPageName("Manage Working Hours");
    } else if (
      window.location.href.includes("reservation/terms-and-conditions")
    ) {
      setCurrentPageName("Terms and Conditions");
    } else if (window.location.href.includes("reservation/list")) {
      setCurrentPageName("Search Results");
    } else {
      setCurrentPageName("Google Table Reservation");
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (window.location.href.includes("reservation/bookings")) {
      setCurrentPageName("Bookings");
    } else if (window.location.href.includes("reservation/manage-slots")) {
      setCurrentPageName("Slots");
    } else if (window.location.href.includes("reservation/profile")) {
      setCurrentPageName("Profile");
    } else if (
      window.location.href.includes("reservation/manage-working-hours")
    ) {
      setCurrentPageName("Manage Working Hours");
    } else if (
      window.location.href.includes("reservation/terms-and-conditions")
    ) {
      setCurrentPageName("Terms and Conditions");
    } else if (window.location.href.includes("reservation/list")) {
      setCurrentPageName("Search Results");
    } else {
      setCurrentPageName("Google Table Reservation");
    }
  }, [window.location.href]);

  const goBack = () => {
    if (window.location.href.includes("/reservation/manage-slots")) {
      //window.location.href = '/';
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  const goToBookings = () => {
    navigate("/reservation/bookings");
    setMenuVisible(false);
  };

  const goToSlots = () => {
    navigate("/reservation/manage-slots");
    setMenuVisible(false);
  };

  const goToWorkingHours = () => {
    navigate("/reservation/manage-working-hours");
    setMenuVisible(false);
  };

  const goToTnC = () => {
    navigate("/reservation/terms-and-conditions");
    setMenuVisible(false);
  };

  const goToProfile = () => {
    navigate("/reservation/profile");
    setMenuVisible(false);
  };

  return (
    <div className="PageL" onClick={handleClickOutside} onKeyUp={() => false}>
      <div className="PageLT">
        <Navbar
          // logo={EatoesLogo}
          businessName={businessName}
          setBusinessName={setBusinessName}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          businessProfileName={businessProfileName}
          setBusinessProfileName={setBusinessProfileName}
          businessUserName={businessUserName}
          setBusinessUserName={setBusinessUserName}
          id={id}
          profileType={profileType}
          setProfileType={setProfileType}
        />
      </div>

      <div className="d-flex full-height">
        <Sidebar className="main-sidebar" />

        <div className="main-container" style={{ maxWidth: "100%" }}>
          <button
            type="button"
            className="sidebar-toggle"
            onClick={handleSidebarToggle}
          >
            <span className="line line1" />
            <span className="line line2" />
            <span className="line line3" />
          </button>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#27343C",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <button
              type="button"
              className="onlyMobile"
              onClick={goBack}
              style={{
                backgroundColor: "transparent",
                border: "0px solid black",
              }}
            >
              <img src={backButton} alt="back" />
            </button>

            <span
              className="mobileHeader"
              style={{ color: "#fff", fontSize: 15 }}
            >
              {currentPageName}
            </span>

            <button
              type="button"
              className="onlyMobile"
              onClick={handleButtonClick}
              style={{
                backgroundColor: "transparent",
                border: "0px solid black",
              }}
            >
              <img src={moreButton} alt="more" />
            </button>

            {menuVisible && (
              <div
                className="floating-menu"
                ref={menuRef}
                style={{
                  position: "absolute",
                  top: 60,
                  right: 10,
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 1000,
                  padding: 10,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  borderRadius: "0.5rem",
                }}
              >
                <button
                  type="button"
                  className="menu-item"
                  onClick={goToProfile}
                  style={{
                    color: "#000",
                    paddingInline: "1rem",
                    paddingBlock: "0.5rem",
                    backgroundColor:
                      currentPageName === "Profile" ? "#D3D3D3" : "white",
                    borderWidth: 0,
                    marginTop: 3,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Profile
                </button>
                <button
                  type="button"
                  className="menu-item"
                  onClick={goToWorkingHours}
                  style={{
                    color: "#000",
                    paddingInline: "1rem",
                    paddingBlock: "0.5rem",
                    backgroundColor:
                      currentPageName === "Manage Working Hours" ? "#D3D3D3" : "white",
                    borderWidth: 0,
                    marginTop: 3,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Working Hours
                </button>
                <button
                  type="button"
                  className="menu-item"
                  onClick={goToSlots}
                  style={{
                    color: "#000",
                    paddingInline: "1rem",
                    paddingBlock: "0.5rem",
                    backgroundColor:
                      currentPageName === "Slots" ? "#D3D3D3" : "white",
                    borderWidth: 0,
                    marginTop: 3,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Slots Management
                </button>
                <button
                  type="button"
                  className="menu-item"
                  onClick={goToTnC}
                  style={{
                    color: "#000",
                    paddingInline: "1rem",
                    paddingBlock: "0.5rem",
                    backgroundColor:
                      currentPageName === "Terms and Conditions" ? "#D3D3D3" : "white",
                    borderWidth: 0,
                    marginTop: 3,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Terms and Conditions
                </button>
                <button
                  type="button"
                  className="menu-item"
                  onClick={goToBookings}
                  style={{
                    color: "#000",
                    paddingInline: "1rem",
                    paddingBlock: "0.5rem",
                    backgroundColor:
                      currentPageName === "Bookings" ? "#D3D3D3" : "white",
                    borderWidth: 0,
                    marginTop: 3,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Booking Management
                </button>
              </div>
            )}
          </div>

          {currentPageName === "Google Table Reservation" && (
            <div className="phone-logo">
              <img
                src="https://connectapp.fra1.cdn.digitaloceanspaces.com/logo_new.png"
                style={{
                  objectFit: "contain",
                  marginTop: 50,
                  marginBottom: 30,
                  width: 100,
                }}
                alt="img"
              />
            </div>
          )}

          {props.children}
        </div>
      </div>

      <div className="footer">
        <div className="main-container">
          <div className="footer-inner" style={{ display: "none" }}>
            <div className="footer-L" style={{ display: "none" }}>
              Ⓒ 2023 <span> ABC COMPANY </span> ., All Rights Reserved.
            </div>
            <div className="footer-R">
              <BottomSVG />
              Designed & Developed by <span> ABC COMPANY </span> Designs
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationLayout;
