// @ts-nocheck

import { MENU_API, baseURL } from "../constants/Api";
import { sessionExpireLogic } from "../utils/functionUtils";

export const menuAccessAPI = async (business, navigate) => {

  const body = JSON.stringify({
    business
  })
  
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.MENU_ACCESS_ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    if (response && response.status == 200) {
      console.log("accesssssssssssssssssssssssssssssssssssssssssssssss", data)
      return data.responseData;
      
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic()
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};