// @ts-nocheck
import React, { useState, useEffect } from "react";

import ellipse from "../assets/images/images/Ellipse.svg";
import { baseURL, MENU_API } from "../constants/Api";
import { Grid } from "@mui/material";
import { getbusinessProfiles, getSocialProfile } from "../API/authAPIs";
import { USER } from "../constants/localStorageKeys";
import classes from "../styleSheets/QuickAccess.module.css";
import walletIcon from "../assets/icons/svgCommonIcons/walletIcon.svg";
import line from "../assets/icons/svgCommonIcons/Line 41.svg";
import Rightarrow from "../assets/icons/BrowserIcon/Right.svg";
import Tick from "../assets/icons/svgSpecificIcons/tick.svg";
import {
  useNavigate,
  createSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import backButton from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import {
  DigitalMenuStatus,
  FastSocialVerificationStatus,
  TableReservationStatus,
  WhatsAppStatus,
} from "../utils/WalletServices";
import Document1, { downloadPdf } from "../Components/Document";
import CircularUnderLoad from "../Components/CircularLoader";
import { setApiData, setMenuAccess } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { AnalyticEvents } from "../utils/GoogleAnalytics/Events";
const QuickAccess = ({ profile, setProfile }) => {
  const paramsObj = useParams();
  const location = useLocation();

  console.log(location);
  const [id, setId] = useState("1");
  const navigate = useNavigate();
  console.log("PROFILE DATA", profile);

  // const [profile, setProfile] = useState([]);
  const isMobile = window.screen.width;
  const [selectedProfile, setSelectedProfile] = useState(
    localStorage.getItem(USER.businessId),
  );
  const [businessName, setBusinessName] = useState(
    localStorage.getItem(USER.businessName),
  );
  const [profileType, setProfileType] = useState(
    localStorage.getItem(USER.PROFILE_TYPE),
  );
  const [businessProfileName, setBusinessProfileName] = useState(
    localStorage.getItem(USER.businessName),
  );
  const [businessUserName, setBusinessUserName] = useState(
    localStorage.getItem(USER.USERNAME),
  );
  const [profileData, setProfileData] = useState([]);
  const [socialProfileStatus, setSocialProfileStatus] = useState("");
  const [digitalMenuStatus, setDigitalMenuStatus] = useState("");
  const [whatsappServiceStatus, setWhatsappServiceStatus] = useState("");
  const [tableReservationStatus, setTableReservationStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const selectedRedux = useSelector((state) => state.selectedProfile);
  const dispatch = useDispatch();

  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/QuickAccess`, "QuickAccess Page");
    setAnalytics(analyticsInstance);
  }, []);

  const navigateToTableReservation = async () => {
    analytics?.sendEvent(
      AnalyticEvents.Services.TableReservation,
      AnalyticEvents.ProfileAction.Activated,
      "QuickAccess",
    );
    navigate("/reservation");
  };

  const callApis = async () => {
    setLoader(true);
    const data = await getSocialProfile(
      `${baseURL}${MENU_API.GET_SOCIAL_PROFILE}/?id=${selectedProfile}`,
    );
    console.log("DAATAA", data);

    // const socialProfile = await getbusinessProfiles(
    //   `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`
    // );

    console.log("data.services:", data.services);

    // setProfile(socialProfile);
    setProfileData(data.services);
    setSocialProfileStatus(FastSocialVerificationStatus(data.services));
    setDigitalMenuStatus(DigitalMenuStatus(data.services));
    setWhatsappServiceStatus(WhatsAppStatus(data.services));
    setTableReservationStatus(TableReservationStatus(data.services));

    setLoader(false);
  };

  useEffect(() => {
    callApis();
  }, [selectedProfile]);

  const handleSocialProfile = () => {
    if (socialProfileStatus === "Verify Now") {
      navigate("/FastSocialVerification");
    } else {
      if (socialProfileStatus === "Verified") {
        navigate(`/${businessUserName}`);
      }
    }
  };
  const handleDigitalMenu = () => {
    if (digitalMenuStatus === "Activate Now") {
      analytics?.sendEvent(
        AnalyticEvents.Services.DigitalMenu,
        AnalyticEvents.ProfileAction.Deactivate,
        "QuickAccess",
      );
      navigate("/DigitalMenuPlans");
    } else {
      if (digitalMenuStatus === "Activated") {
        analytics?.sendEvent(
          AnalyticEvents.Services.DigitalMenu,
          AnalyticEvents.ProfileAction.Activated,
          "QuickAccess",
        );
        dispatch(setApiData({}));
        dispatch(setMenuAccess(true));
        localStorage.setItem(
          "menu-back-route",
          location.pathname + location.search,
        );
        navigate(`/edit/menu/${businessUserName}`);
      }
    }
  };
  const handleTableReservation = () => {
    if (tableReservationStatus === "Activate Now") {
      navigate("/Communication");
    }
  };

  const handleWhatsapp = () => {
    if (tableReservationStatus === "Activate Now") {
      /*       analytics?.sendEvent(
        AnalyticEvents.Services.WhatsAppBusiness,
        AnalyticEvents.ProfileAction.Activated,
        "got clicked",
      ); */
      navigate("/contact");
    }
    if (tableReservationStatus === "Activated") {
      analytics?.sendEvent(
        AnalyticEvents.Services.WhatsAppBusiness,
        AnalyticEvents.ProfileAction.Activated,
        "QuickAccess",
      );
    }
  };

  return (
    <>
      {loader ? (
        <div className={classes.centerDiv}>
          <CircularUnderLoad />
        </div>
      ) : (
        <>
          <div className={classes.accessContainer}>
            <div
              className={classes.headingText}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "55px",
                background: "#27343C",
                color: "white",
              }}
            >
              <div
                className="position-absolute text-nowrap"
                style={{ left: "50%", transform: "translateX(-50%)" }}
              >
                {businessUserName}
              </div>
            </div>
            <div
              className={classes.mainContainer1}
              style={{ paddingTop: "40px" }}
            >
              {(socialProfileStatus === "Expired" ||
                digitalMenuStatus === "Expired" ||
                tableReservationStatus === "Expired" ||
                whatsappServiceStatus === "Expired") && (
                <div className={classes.warningMsg}>
                  You services are Expired
                </div>
              )}
              <div className={classes.headingText}>Wallet</div>
              <div
                className={classes.textBox}
                onClick={() => {
                  navigate("/wallet");
                }}
              >
                <img className={classes.walletImage} src={walletIcon} />{" "}
                <img src={line} />
                <span className={classes.boxText}>Wallet</span>{" "}
                <span className={classes.marginLeftAuto}>
                  <img src={Rightarrow} />
                </span>
              </div>
              <div className={classes.headingText}>All Services</div>
              <div className={classes.textBox} onClick={handleSocialProfile}>
                <span className={classes.boxText2}>Social Profile</span>
                <span className={classes.marginLeftAuto}>
                  <span
                    style={{
                      color:
                        socialProfileStatus === "Verify Now"
                          ? "#E96F6F"
                          : "#229378",
                    }}
                    className={classes.activateText}
                  >
                    {socialProfileStatus}
                    {socialProfileStatus == "Verified" ? (
                      <img src={Tick} />
                    ) : (
                      ""
                    )}
                  </span>
                </span>
              </div>
              <div className={classes.textBox} onClick={handleDigitalMenu}>
                <span className={classes.boxText2}>Digital Menu</span>
                <span className={classes.marginLeftAuto}>
                  <span
                    style={{
                      color:
                        digitalMenuStatus === "Activate Now"
                          ? "#E96F6F"
                          : "#229378",
                    }}
                    className={classes.activateText}
                  >
                    {" "}
                    {digitalMenuStatus}
                  </span>
                </span>
              </div>
              <div
                className={classes.textBox}
                style={{ cursor: "pointer" }}
                onClick={navigateToTableReservation}
              >
                <span className={classes.boxText2}>Table Reservation</span>
                <span className={classes.marginLeftAuto}>
                  <span
                    className={classes.activateText}
                    style={
                      tableReservationStatus === "Activated"
                        ? { color: "#03a884" }
                        : {}
                    }
                  >
                    {tableReservationStatus}
                  </span>
                </span>
              </div>
              <div className={classes.textBox} onClick={handleWhatsapp}>
                <span className={classes.boxText2}>WhatsApp Bussiness API</span>
                <span className={classes.marginLeftAuto}>
                  <span className={classes.activateText}>
                    {whatsappServiceStatus}
                  </span>
                </span>
              </div>
              <div className={classes.headingText}>Account</div>
              <div
                className={classes.textBox}
                onClick={() => {
                  navigate({
                    pathname: "/CreateBusiness",
                    search: `id=${selectedProfile}`,
                  });
                }}
              >
                <span className={classes.boxText2}>Edit Business Profile</span>
              </div>
              <div
                className={classes.textBox}
                onClick={() => {
                  navigate({
                    pathname: "/BusinessAccess",
                    search: `id=${selectedProfile}`,
                  });
                }}
              >
                <span className={classes.boxText2}>Roles and Authorities</span>
              </div>
              {/* <Document1/> */}
            </div>
            <div style={{ paddingBottom: "100px" }}></div>
          </div>
        </>
      )}
    </>
  );
};

export default QuickAccess;
