// @ts-nocheck

export const customStyles = {
  control: (provided) => ({
    ...provided,
    color:"#27343C",
    width: "156px",
    height: "36px",
    fontSize: "12px",
    fontWeight: 400,
    textAlign:"center",
    paddingLeft:"18px",
    borderRadius: "3rem",
    background: "#7ECAB0",
    gap:"10px",
    padding:"12px, 69px, 12px, 69px",
    boxShadow: "none",
    border: "none",
  }),

  option: (provided, state) => ({
    ...provided,
    color: "#000",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "10px",
    background: "#F5F5F7",
    margin: "8px 0",
    width: "100%",
  }),

  menu: (provided, state) => ({
    ...provided,
    padding: "0 8px",
    width: "200px",
    minHeight: "400px",
    marginTop: "-60px",
    position: "relative",
    marginBottom: "50px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: "none", // Hide the dropdown arrow
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide the indicator separator line
  }),
  menuList: (provided, state) => ({
    ...provided,
    "&::-webkit-scrollbar": {
      width: "0", // Adjust the width as needed
    },
    minHeight: "400px",
  }),
};


export const reactSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '5px',
    border: '1px solid #7ECAB0',
    background: 'FFFFFF',
    height: "45px",
    boxShadow: "none",
    '&:hover': {
      border: '1px solid #7ECAB0',
    },
    color: "#6E6D7A",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "115px",
    fontSize: "12px"
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#7ECAB0" : '#F5F5F7',
    boxShadow: "none",
    color: "#27343C",
    paddingTop: "12px",
    paddingBottom: "12px",
    "&:active": {
      backgroundColor: "#7ECAB0",
      color: "#1D1D1F",
    },
  }),

  
  singleValue: (provided, state) => ({
    ...provided,
    color: "#27343C",
  }),


  menuList: (provided, state) => ({
    ...provided,
    padding: "0",
    borderRadius: "5px",
    border: "1px solid #7ECAB0",
    background: "#F5F5F7",
    scrollBehavior: "auto",
    "&::-webkit-scrollbar": {
      width: "0",
    },
    fontSize: "12px",
    maxHeight: "150px",
  }),

  menu: (provided, state) => ({
    ...provided,
    marginTop: "2px",
    padding: "0",
    boxShadow: "none",
    // maxHeight: "2px",
    // height: "2px"
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 2px 0 0"
  })

}
