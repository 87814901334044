//@ts-nocheck
import React, { useState, useEffect } from "react";
import TopHeader from "../utils/TopHeader"; // Assuming you have this component
import axios from "axios"; // Using axios for the API call
import { baseURL, MENU_API } from "../constants/Api";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const ChangePrices = () => {
  const [activeTab, setActiveTab] = useState("percent"); // State for switching between tabs
  const [percentPrice, setPercentPrice] = useState(""); // Default price value as a string for percent
  const [plusMinusPrice, setPlusMinusPrice] = useState(""); // Default price value as a string for plus-minus
  const [loading, setLoading] = useState(false); // For handling loading state
  const [message, setMessage] = useState(""); // To show success or error messages
  const [fullData, setFullData] = useState(null); // State to store fullData
  const [selectedCategoryId, setSelectedCategoryId] = useState(null); // State to store the selected category ID

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("state");
  const subCatId = queryParams.get("subCatId");

  const handleSave = async () => {
    // Determine which price input to use based on the active tab
    const priceValue = activeTab === "percent" ? percentPrice : plusMinusPrice;
    if (!priceValue) {
      setMessage("Please enter a price value");
      return;
    }

    // Check if the input is valid and determine the type (inc or dec)
    let type;
    const numericValue = parseFloat(priceValue.replace("%", "")); // Remove % for processing

    if (isNaN(numericValue)) {
      setMessage("Invalid price input");
      return;
    }

    // Determine type based on whether the value is positive or negative
    if (numericValue > 0) {
      type = "inc";
    } else if (numericValue < 0) {
      type = "dec";
    } else {
      setMessage("Price change cannot be zero");
      return;
    }

    // Determine the method type (percent or value) based on the active tab
    const method = {
      type: activeTab === "percent" ? "percent" : "value",
      amount: Math.abs(numericValue),
    };

    // Make the API call to update prices
    try {
      setLoading(true);

      const response = await axios.post(`${baseURL}/${MENU_API.CHANGE_PRICE}`, {
        menu_id: localStorage.getItem("MenuId"), // Use cloudinary_Iconurl as menu_id
        category_id: state, // Use selected category ID
        type: type,
        method: method, // Send method object with type and amount
        subcategory_id: subCatId,
      });

      if (response.status === 200) {
        toast.success("Price updated successfully");
      } else {
        toast.error("Unable to update price");
      }
    } catch (error) {
      setMessage("Error occurred while updating prices");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TopHeader name="Extra Setting" />
      <div style={styles.container}>
        {/* Tab Navigation */}
        <div style={styles.tabs}>
          <div
            style={{
              ...styles.tabItem,
              ...(activeTab === "percent" ? styles.activeTab : {}),
            }}
            onClick={() => setActiveTab("percent")}
          >
            % Change
          </div>
          <div
            style={{
              ...styles.tabItem,
              ...(activeTab === "plus-minus" ? styles.activeTab : {}),
            }}
            onClick={() => setActiveTab("plus-minus")}
          >
            +/- Change
          </div>
        </div>

        {/* Content based on Active Tab */}
        <div style={styles.content}>
          <span style={styles.label}>Actual Price</span>
          {activeTab === "percent" ? (
            <input
              type="text" // Allow user to enter a percentage value
              style={styles.priceInput}
              value={percentPrice}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers, +, -, and optional % symbol
                if (/^[+-]?\d*%?$/.test(value)) {
                  setPercentPrice(value);
                }
              }} // Update value as user types
              placeholder="e.g +20%"
            />
          ) : (
            <input
              type="text" // Allow user to enter + or - value
              style={styles.priceInput}
              value={plusMinusPrice}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers, + or - signs
                if (/^[+-]?\d*$/.test(value)) {
                  setPlusMinusPrice(value);
                }
              }} // Update value as user types
              placeholder="e.g +/-20"
            />
          )}
        </div>

        {/* Save Button */}
        <button
          style={styles.saveButton}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>

        {/* Display Message */}
        {message && <div style={styles.message}>{message}</div>}
      </div>
    </>
  );
};

// Inline Styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
  },
  tabs: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    marginBottom: "20px",
    borderBottom: "1px solid #ccc",
    backgroundColor: "#27343C", // Set the background for the tab container
    position: "relative",
    top: "-10px", // Move the tabs up to touch the TopHeader
  },
  tabItem: {
    padding: "15px 65px", // Increase padding to make it visually connected
    fontSize: "16px",
    color: "#D3D3D3", // Change tab text color to grey
    cursor: "pointer",
    backgroundColor: "#27343C", // Default tab background color
    borderBottom: "2px solid transparent",
  },
  activeTab: {
    color: "#5cd6b6", // Active text color
    borderBottom: "2px solid #5cd6b6", // Wider active tab line
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginBottom: "20px",
    fontSize: "18px",
  },
  label: {
    flex: 1,
    fontWeight: "600", // Semi-bold font weight for actual price label
  },
  priceInput: {
    width: "70px",
    border: "none",
    outline: "none", // Remove outline
    boxShadow: "none", // Remove box shadow
    fontSize: "18px",
    textAlign: "center", // Center the text
    backgroundColor: "transparent",
    borderBottom: "2px solid black", // Change input border color to black
  },
  saveButton: {
    backgroundColor: "#5cd6b6",
    margin: "80px 0px 0px 0px",
    color: "#fff",
    padding: "10px 100px",
    border: "none",
    borderRadius: "20px",
    fontSize: "16px",
    cursor: "pointer",
  },
  message: {
    marginTop: "20px",
    color: "#ff4d4d", // Message color (red for error)
  },
};

export default ChangePrices;
