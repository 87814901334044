// @ts-nocheck
import React, { useEffect, useState } from "react";
import WalletCard from "../Components/WalletCard";
import { Grid } from "@mui/material";
import { Modal, Box } from "@mui/material";
import left from "../assets/icons/svgCommonIcons/leftArrow.svg";
import accountIcon from "../assets/icons/svgSpecificIcons/accountIcon.svg";
import classes from "../styleSheets/Dashboard.module.css";
import loadingStyle from "../styleSheets/loader.module.css";
import lgcard from "../styleSheets/logout.module.css";
import { Logout } from "../Components/Logout";
import { useNavigate, createSearchParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import { AUTH_MENU_API, MENU_API, baseURL } from "../constants/Api";
import { subCategoryAPI } from "../API/MenuApi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { USER } from "../constants/localStorageKeys";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../Components/Sidebar";
import addProfile from "../assets/images/ActivateBusiness.png";
import style from "../styleSheets/logout.module.css";
import { getbusinessProfiles } from "../API/authMenuAPIs";
import { data } from "../Data";
import BusinessProfileDropdown from "../Components/BusinessProfileDropdown";
import WalletDrawer from "../Components/WalletDrawer";
import CircularUnderLoad from "../Components/CircularLoader";
import Transactionlist from "../Components/Transactionlist";
import Transactions from "../Components/Transactions";
import classes1 from "../styleSheets/fastSocialVerification.module.css";
import backButton from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import TopHeader from "../utils/TopHeader";
const WalletPage = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  setId,
  profileType,
  setProfileType,
  profile,
  setProfile,
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const open1 = Boolean(anchorEl);
  console.log(selectedProfile, "walletpage");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  // const [profile, setProfile] = useState([]);
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(900));

  const navigate = useNavigate();

  const name = localStorage.getItem("name");

  const callApis = async () => {
    setLoader(true);
    const data = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILES}`,
    );
    setProfile(data);
    setLoader(false);
  };

  useEffect(() => {
    // callApis();
  }, []);
  const checkId = localStorage.getItem(USER.businessId);
  // const [selectedProfile, setSelectedProfile] = useState(businessId);

  return (
    <>
      <div>
        {/* <img src={left} className={classes.left} onClick={()=>{navigate('/')}}></img> */}
        {isMatch && <TopHeader name="Wallet" />}

        <div
          className={classes.hideImage}
          style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}
        >
          {/* <div style={{ marginTop: "-10px" }}>
            <BusinessProfileDropdown
              businessName={businessName}
              setBusinessName={setBusinessName}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              businessProfileName={businessProfileName}
              setBusinessProfileName={setBusinessProfileName}
              businessUserName={businessUserName}
              setBusinessUserName={setBusinessUserName}
              id={id}
              profileType={profileType}
              setProfileType={setProfileType}
            />
          </div> */}
        </div>
      </div>
      {loader && (
        <div className={loadingStyle.container}>
          <CircularUnderLoad />
        </div>
      )}
      {!loader && (
        <div className="m-3">
          {selectedProfile === null || selectedProfile === "undefined" ? (
            <div className={classes.placeholderContainer}>
              <div style={{ textAlign: "center" }}>
                {" "}
                Add a new Social Profile and begin your journey!!
              </div>
              <img src={addProfile} className={classes.placeholderImage} />
              {/* <img style={{textAlign:'center',width:'35vw',height:'35vw',margin:'2vh 0 0 30vw'}}src={addProfile}/> */}
            </div>
          ) : (
            <>
              <div>
                <WalletCard
                  selectedProfile={selectedProfile}
                  businessUserName={businessUserName}
                  setId={setId}
                />
              </div>

              {isMatch && (
                <div>
                  <Transactions
                    selectedProfile={selectedProfile}
                    setSelectedProfile={setSelectedProfile}
                  />
                </div>
              )}
            </>
          )}{" "}
        </div>
      )}
    </>
  );
};

export default WalletPage;
