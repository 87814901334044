// @ts-nocheck

// actions.js


export const resetAllReduxStates = (data) => ({
  type: "RESET_REDUX"
})


export const setApiData = (data) => ({
  type: 'SET_API_DATA',
  payload: data,
});

export const setFastSocialData = (data) => ({
  type: 'SET_FAST_SOCIAL_DATA',
  payload: data,
});

export const setRoleslData = (data) => ({
  type: 'SET_ROLES_DATA',
  payload: data,
});

export const setMenuAccess = (data) => ({
  type: "SET_MENU_ACCESS",
  payload: data
})

export const setUserDetails = (data) => ({
  type: "SET_USER_DETAILS",
  payload: data
})

export const setSelectedProfile = (data) => ({
  type: "SET_SELECTED_PROFILE",
  payload: data
})

export const setProfileData = (profileId, data) => ({
  type: 'SET_PROFILE_DATA',
  payload: { profileId, data },
});

export const clearCurrentProfile = () => ({
  type: 'CLEAR_CURRENT_PROFILE',
});

export const fetchProfileData = (profileId) => {
  return async (dispatch) => {
    try {
      // Replace this with your actual API call
      const response = await fetch(`/api/profile/${profileId}`);
      const data = await response.json();
      
      dispatch(setProfileData(profileId, data));
    } catch (error) {
      console.error('Error fetching profile data:', error);
      // Handle error if needed
    }
  };
};