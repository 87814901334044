//@ts-nocheck
import React from 'react';
import PostHeader from './PostHeader';
import PostFooter from './PostFooter';

const PostComponent = ({ profilePic, name, date, postPic, likes, shares }) => {
  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto', backgroundColor:'white',marginTop:'40px' }}>
      <PostHeader profilePic={profilePic} name={name} date={date} />
      
      {/* Post Image */}
      <div
        style={{
          width: '100%', // Use 100% to match the container width
          maxWidth: '395px', // Set a max width for the image container
          height: '356px', // Fixed height for the image
          borderRadius: '12px', // Maintain rounded corners
          overflow: 'hidden', // To make sure the corners are rounded
          margin: '0 auto', // Center the image container
          position: 'relative', // For any future absolute positioning if needed
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.3)' // Smoother box shadow
          
        }}
      >
        <img
          src={postPic} // Keep the image link intact
          alt="Post"
          style={{
            width: '100%', // Ensure the image covers the container width
            height: '100%', // Set height to fill the container
            objectFit: 'cover', // Ensure the image covers the area
            borderRadius: '12px', // Ensure the image itself is also rounded
          }}
        />
      </div>

      <PostFooter likes={likes} shares={shares} />
    </div>
  );
};

export default PostComponent;