// @ts-nocheck
import { useEffect, useState } from "react";
import { baseURL, MENU_API } from "../constants/Api";
import { Navigate } from "react-router-dom";

export const menuAPI = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.menuitems;
    console.log("dataaaa", data);

    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};

export const itemsAPI = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.menuitems?.items;
    return data;
  } catch (err) {
    console.log("itemApi Error", err);
  }
};

export const carouselImage = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.menuitems?.HomePageImagesurl;

    return data;
  } catch (err) {
    console.log("carouselImage Error", err);
  }
};

export const category = async (url) => {
  // const [data, setData] = useState([]);
  // useEffect(() => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.menuitems?.categories;
    return data;
  } catch (err) {
    console.log("Category Error", err);
  }
};
export const subCategoryAPI = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.menuitems?.subCategories;
    return data;
  } catch (err) {
    console.log("subCategory Error", err);
  }
};

export const getMenuPlanDetails = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.plans;
    return data;
  } catch (e) {
    console.log("getplansapi error", e);
  }
};
export const fetchCurrentTheme = async (menuId) => {
  const token = localStorage.getItem("accessToken");
  // pehle body ko JSON mein bhejenge
  const body = JSON.stringify({
    menuId: menuId,
  });

  try {
    const response = await fetch(`${baseURL}/${MENU_API.FETCH_MENU_THEME}`, {
      // yahan pe fetch ke paranthesis mein api address dete h
      method: "POST",
      // GET, POST, PUT, DELETE
      // yahan api method bhejte h
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // JWT Token bhejte h
      },
      body: body,
      // yahan pe body pass krte h
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};
export const updateTheme = async (menuId, theme) => {
  const token = localStorage.getItem("accessToken");
  // pehle body ko JSON mein bhejenge
  const body = JSON.stringify({
    menuId: menuId,
    selectedPublicTheme: theme,
  });

  try {
    const response = await fetch(`${baseURL}/${MENU_API.UPDATE_MENU_THEME}`, {
      // yahan pe fetch ke paranthesis mein api address dete h
      method: "POST",
      // GET, POST, PUT, DELETE
      // yahan api method bhejte h
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // JWT Token bhejte h
      },
      body: body,
      // yahan pe body pass krte h
    });
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("api is failing");
    }
  } catch (err) {
    console.log("something went wrong", err);
  }
};
