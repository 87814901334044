// @ts-nocheck
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDateToCustomString, formatTimeSlot } from "../../utils/functionUtils";
import { TncModal } from "../tncModal";


export function BookingDetails({bookingData}: {bookingData: object}) {
  const [open, setOpen] = React.useState(false)

  console.log("dateeeeeeeeeeeeeeeeeeee", bookingData)

  const formatText = (text) => {
    if(text === "CONFIRMED") return "Accepted"
    if(text === "cancelled") return "Cancelled"
    return text
  }

  const dataObj = {
    "Booked By": localStorage.getItem("adminName"),
    "Mobile Number": localStorage.getItem("usermobilenumber"),
    "Email ID": bookingData?.emailAddress,
    "Date of booking": bookingData?.slot?.slotDate,
    "Time of booking": bookingData?.slot?.slotStartTime,
    "No. of diners": bookingData?.partySize,
    "Booking status": bookingData?.status,
  }
  
  return (
    <TableContainer className="mt-4">
      <Table sx={{ minWidth: "100%" }}>
        <TableBody>
          {Object.keys(dataObj).map((dataKey: string) => (
            dataObj[dataKey] && 
            <TableRow
              sx={{ border: "none" }}
            >
              <TableCell
              className="booking-details-key"
              >
                {dataKey}
              </TableCell>
              <TableCell className={`booking-details-value ${formatText(dataObj[dataKey]) === "Accepted" ? "success-col" : formatText(dataObj[dataKey]) === "Cancelled" ? "cancel-col" : ""}`}>{formatText(dataObj[dataKey])}</TableCell>
            </TableRow>
          ))}
          <div className="booking-table-tnc my-3" onClick={() => setOpen(true)}>Booking Tnc’s</div>
        </TableBody>
      </Table>
      <TncModal open={open} setOpen={setOpen} />
    </TableContainer>
  );
}
