// @ts-nocheck

import React, { useState } from "react";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import "../../styleSheets/Modal-Box.css";
import { ItemTypeBtns } from "./Item-Type-Btns";

export const Price_Variety_Inputs = ({
  price,
  setPrice,
  setDishType,
  varietyInputsState,
  setVarietyInputsState,
  dishType,
}) => {
  // const [price, setPrice] = useState('')
  const [controller, setController] = useState(varietyInputsState.length);
  // const [varietyInputsState, setVarietyInputsState] = useState([])
  const [varietyInputsArr, setVarietyInputsArr] = useState(varietyInputsState);

  const handleInputChange = (e, index, type) => {
    setVarietyInputsState((prev) => {
      const newArr = [...prev];
      newArr[index][type] = e.target.value;
      return newArr;
    });
  };

  const handleVarietyClick = () => {
    if (controller === 3) return;
    setPrice("");
    setDishType("na");
    const newInputArr = {
      id: controller + 1,
    };
    // const newInputStateName = `varietyInput${controller + 1}`

    const newInputState = {
      name: "",
      price: "",
      type: "na",
    };
    setVarietyInputsState((prev) => [...prev, newInputState]);
    setVarietyInputsArr((prev) => [...prev, newInputArr]);
    setController(controller + 1);
  };

  const handleRemoveClick = () => {
    setVarietyInputsState((prev) => {
      const newArr = [...prev];
      newArr.pop();
      return newArr;
    });

    setVarietyInputsArr((prev) => {
      const newArr = [...prev];
      newArr.pop();
      return newArr;
    });

    setController(controller - 1);
  };

  // console.log('variety input states----->', varietyInputsState)

  return (
    <div className=" w-100">
      {controller === 0 ? (
        <>
          {/* <p className="my-3 mx-auto" style={customizeMUIComponents.ModalLabel}>Price</p> */}

          <div className="mb-2 d-flex align-items-center">
            <input
              placeholder="Price"
              className="my-3"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={customizeMUIComponents.inputBox}
            />
            <div className="mt-1">
              <ItemTypeBtns
                forType={"wholeDish"}
                setDishType={setDishType}
                dishType={dishType}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="">
          {varietyInputsArr.map((varietyInput, index) => {
            return (
              <div className=" d-flex mt-1">
                {index + 1 === controller && (
                  <div className="my-auto me-2" style={{}}>
                    <img
                      src="/assets/delete-icon.svg"
                      className="pt-3 img-fluid"
                      style={{ width: "2.5rem" }}
                      onClick={handleRemoveClick}
                    />
                  </div>
                )}
                {/* <div> */}
                <div className="me-2">
                  <input
                    className="mt-3"
                    placeholder={`Variety ${varietyInput?.id}`}
                    value={varietyInputsState?.[index]?.name}
                    onChange={(e) => handleInputChange(e, index, "name")}
                    style={customizeMUIComponents.inputBox}
                  />
                </div>

                {/* </div> */}

                {/* <div> */}
                <div className="d-flex">
                  <input
                    className="mt-3"
                    placeholder="Price"
                    value={varietyInputsState?.[index]?.price}
                    onChange={(e) => handleInputChange(e, index, "price")}
                    style={customizeMUIComponents.inputBox}
                  />
                </div>
                {/* </div> */}

                <div style={{ marginTop: "13px" }}>
                  <ItemTypeBtns
                    forType={"varieties"}
                    index={index}
                    varietyInputsState={varietyInputsState}
                    setVarietyInputsState={setVarietyInputsState}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {controller !== 3 && (
        <div className="w-100 d-flex">
          <button
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              lineHeight: "normal",
            }}
            className="add_variety_btn mb-4 mt-1"
            onClick={handleVarietyClick}
          >
            Add Variety
          </button>
        </div>
      )}
    </div>
  );
};
