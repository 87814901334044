// @ts-nocheck
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { USER } from "../constants/localStorageKeys";
import MainMenuTheme2 from "./MainMenuTheme2";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const TempParent = ({
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
  loading,
  setLoading,
}) => {
  // console.log('Hey, I am Dashboard component')
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();
  const [id, setId] = useState("1");
  const isMobile = window.screen.width;
  // console.log(isMobile,profile, "width");
  // window.history.pushState(null, 'page', window.location.href);
  // window.onpopstate = function () {
  //     window.history.go(1);
  const num = localStorage.getItem(USER.MOBILE_NUMBER);
  // console.log(num,'iddd')
  const businessId = localStorage.getItem("businessId");
  const [selectedProfile, setSelectedProfile] = useState("");
  const businessName1 = localStorage.getItem(USER.businessName);
  const businessUserName1 = localStorage.getItem(USER.USERNAME);
  const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
  const [profileType, setProfileType] = useState("");
  const [businessProfileName, setBusinessProfileName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");
  // const[loading,setLoading] =useState(false)
  //  const[profile,setProfile]=useState([])
  const callApi = () => {
    // setLoading(true)
    const selectedProfileId = localStorage.getItem(USER.businessId);

    {
      if (selectedProfileId == null || selectedProfileId == "undefined") {
        setBusinessProfileName(profile[0]?.name);
        setBusinessUserName(profile[0]?.userName);
        setSelectedProfile(profile[0]?.profileId);
        setProfileType(profile[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, profile[0]?.userName);
        localStorage.setItem(USER.businessName, profile[0]?.name);
        localStorage.setItem(USER.businessId, profile[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, profile[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    console.log(profile, "profile");

    callApi();
  }, [profile]);

  console.log(businessProfileName, "name");

  return (
    <>
      <Routes>
        <Route
          path="/:name2"
          element={
            <MainMenuTheme2
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              setId={setId}
              setBusinessProfileName={setBusinessProfileName}
              setBusinessUserName={setBusinessUserName}
            />
          }
        />
      </Routes>
    </>
  );
};

export default TempParent;
