// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "../../styleSheets/FoodMenu.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubCategorySidebar from "./SubCategorySidebar";
import { MENU_API, baseURL } from "../../constants/Api";
import arrow from "../../assets/images/images/downArrow.png";
import "../../styleSheets/subcategory-header.css";
// import SearchIcon from "@mui/icons-material/Search";
import SearchIcon from "../../assets/icons/svgCommonIcons/search-icon.svg";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import { toast, Toaster } from "react-hot-toast";

const SubCategoryHeaderSection = ({
  data,
  openSearch,
  setOpenSearch,
  setWholeData,
  storedItems,
  setStoredItems,
  analytics,
  setAnalytics,
}) => {
  const [data1, setData1] = useState([]);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));

  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  // const id=searchParams.get("id");
  const token = localStorage.getItem("accessToken");
  const name = localStorage.getItem("name");

  const currentTime = new Date();

  const isCategoryWorking = (openTime, closeTime) => {
    if (!openTime || !closeTime) return true;
    if (currentTime >= new Date(openTime) && currentTime <= new Date(closeTime))
      return true;
    return false;
  };

  const handleCategoryClick = (
    openTime,
    closeTime,
    categoryId,
    categoryName,
  ) => {
    if (isCategoryWorking(openTime, closeTime)) {
      setId(categoryId);
      setActive(!active);
      setShow(true);
    } else {
      toast.success(`${categoryName} is not available now`, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    setData1(data?.categories || []);
  }, [data]);

  if (id == data1?._id) {
    setShow(true);
  }
  return (
    <div className="container-1">
      <div
        className={classes.subContainer2}
        //  style={{border: '2px solid red'}}
      >
        {active && <div className="overlay" onClick={() => setActive(false)} />}

        <div className={`content overflow-y-scroll ${active && "height90vh"}`}>
          {data1?.map((item, index) => {
            if (item?._id == id) {
              // && true
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleCategoryClick(
                      item.openTime,
                      item.closeTime,
                      item._id,
                      item?.category,
                    )
                  }
                >
                  <div
                    style={{ wordWrap: "break-word" }}
                    className={classes.catName}
                  >
                    {!isCategoryWorking ? "" : <div> {item?.category}</div>}
                    {data1?.length <= 1 ? (
                      ""
                    ) : (
                      <>
                        <img src={arrow} className={classes.expandIcon} />
                      </>
                    )}
                  </div>
                </div>
              );
            }
          })}

          {data1?.map((item, index) => {
            if (active && item._id != id) {
              return (
                <div className=" d-flex flex-column align-items-center position-relative">
                  <div
                    style={{ wordBreak: "break-all" }}
                    className={classes.category}
                    onClick={() => {
                      setId(item?._id);
                      handleCategoryClick(
                        item.openTime,
                        item.closeTime,
                        item._id,
                        item.category,
                      );
                      analytics.sendEvent(
                        AnalyticEvents.Menu.DropDownCategory,
                        AnalyticEvents.MenuAction.Selected,
                        item?.category,
                      );
                    }}
                  >
                    {!isCategoryWorking ? "" : <>{item?.category}</>}
                  </div>

                  <img
                    onClick={() => setActive(false)}
                    className={`${!active && "d-none"} close-btn`}
                    src="/assets/circle-delete-icon.svg"
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      <Toaster />
      <div className="pt-5">
        <div
          style={{
            display: "flex",
            backgroundColor: "#F5F5F7",
            marginTop: "-3px",
          }}
          onClick={() => {
            setOpenSearch(true);
          }}
          className="p-1"
        >
          <img
            src={SearchIcon}
            className="py-1"
            style={{ marginLeft: "auto", marginRight: "0.7rem" }}
            onClick={() => {
              setOpenSearch(true);
            }}
          />
        </div>

        <SubCategorySidebar
          storedItems={storedItems}
          setStoredItems={setStoredItems}
          id={id}
          data={data}
          setWholeData={setWholeData}
          analytics={analytics}
          setAnalytics={setAnalytics}
        />
      </div>
    </div>
  );
};

export default SubCategoryHeaderSection;
