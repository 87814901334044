// @ts-nocheck

export const FAST_SOCIAL_API = {
    GET_ALL_DATA : "fast-social/all",
    POST_BASIC_DETAILS:"fast-social/basic-details",
    POST_CONTACT_DETAILS:"fast-social/contact-details",
    PUT_MEDIA_NAME: "fast-social/change-media-category-name",
    POST_IMAGE: "fast-social/upload-image",
    POST_LINKS: "fast-social/links",
    POST_ROLE: "fast-social/fast-social-role",
    DELETE_IMAGE: "fast-social/delete-image",
    DELETE_LINKS: "fast-social/links",
    POST_EVENT: "fast-social/event",
    DELETE_EVENT: "fast-social/event",
    PUT_LIVE_EVENT: "fast-social/event-live-update",
    SEARCH_PROFILES: "fast-social/search-profiles"

}