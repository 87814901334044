// @ts-nocheck
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classes from "../styleSheets/Dashboard.module.css";
import classes1 from "../styleSheets/GraphToggleButtons.module.css";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { data } from "../Data";
import { EXPENSES_GRAPH_API, baseURL } from "../constants/Api";
import GraphToggleButtons from "./GraphToggleButtons";
import moment from "moment";
import { expenseGraph, weeklyExpenseGraph } from "../API/WalletAPIs";
const Graph = ({ selectedProfile, setSelectedProfile }) => {
  const [active, setActive] = useState(true);
  const [active1, setActive1] = useState(false);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const token = localStorage.getItem("accessToken");
  const handleClick = () => {
    setActive1(false);
    setActive(true);
  };
  const handleClick1 = () => {
    setActive1(true);
    setActive(false);
  };
  const callApis = async () => {
    const expense = await expenseGraph(
      `${baseURL}${EXPENSES_GRAPH_API.EXPENSES_GRAPH}/?id=${selectedProfile}`,
    );
    setData1(expense);
    const weekExpense = await weeklyExpenseGraph(
      `${baseURL}${EXPENSES_GRAPH_API.EXPENSES_GRAPH}/?id=${selectedProfile}`,
    );
    setData2(weekExpense);
  };

  useEffect(() => {
    callApis();
  }, [selectedProfile]);

  console.log(data2, "arrayyy");
  const startOfMonth = moment().startOf("month").format("Do MMMM ");
  const endOfMonth = moment().endOf("month").format("Do MMMM");
  const startOfWeek = moment().startOf("week").format("Do MMMM ");
  const endOfWeek = moment().endOf("week").format("Do MMMM");
  var month = moment().format("MMMM");
  // const month = check.format('MMMM')
  console.log(startOfWeek, endOfWeek, "today");
  return (
    <>
      <div
        className={classes.sidebar}
        style={{ paddingTop: "1em", marginBottom: "6vw" }}
      >
        <div className={classes.txt2}>Expenses Trend</div>
        <Card sx={{ boxShadow: "none" }} className={classes.card}>
          <CardContent>
            <div
              style={{
                fontSize: "13px",
                color: "#272C2F",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Trends as of
              {active1 ? (
                <div style={{ color: "#6A75CA", margin: "0 0 0 5px" }}>
                  {startOfMonth} to {endOfMonth}
                </div>
              ) : (
                <div style={{ color: "#6A75CA", margin: "0 0 0 5px" }}>
                  {startOfWeek} to {endOfWeek}
                </div>
              )}
              <div className={classes1.left}>
                <button
                  className={active ? classes1.buttonActive : classes1.button}
                  onClick={handleClick}
                >
                  week
                </button>
                <button
                  className={active1 ? classes1.buttonActive : classes1.button}
                  onClick={handleClick1}
                >
                  month
                </button>
              </div>
            </div>
            {(active1 && data1?.length == 0) ||
            (active && data2?.length == 0) ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "95px 0 95px 0",
                  color: "#6A75CA",
                }}
              >
                No Expenses{" "}
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={215}>
                {active1 ? (
                  <AreaChart data={data1}>
                    <Area
                      type="monotone"
                      dataKey="monthExpense"
                      stroke="#6A75CA"
                      strokeWidth={"2px"}
                      fill="url(#color)"
                    />
                    <defs>
                      <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="34.83%"
                          stopColor="#3751FF"
                          stopOpacity={0.1}
                        />
                        <stop
                          offset="86.76%"
                          stopColor="rgba(55, 81, 255, 0)"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="monthlyCreatedAt"
                      interval="preserveStartEnd"
                      axisLine={false}
                      tickLine={false}
                      tickSize={20}
                    />
                    <YAxis
                      orientation="right"
                      interval="preserveStartEnd"
                      axisLine={false}
                      tickLine={false}
                      tickSize={20}
                    />
                    <CartesianGrid
                      stroke="#C4C4C4"
                      vertical={false}
                      opacity={0.5}
                    />
                    <Tooltip />
                  </AreaChart>
                ) : (
                  <AreaChart data={data2}>
                    <Area
                      type="monotone"
                      dataKey="expense"
                      stroke="#6A75CA"
                      strokeWidth={"2px"}
                      fill="url(#color)"
                    />
                    <defs>
                      <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="34.83%"
                          stopColor="#3751FF"
                          stopOpacity={0.1}
                        />
                        <stop
                          offset="86.76%"
                          stopColor="rgba(55, 81, 255, 0)"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="expenseDate"
                      interval="preserveStartEnd"
                      axisLine={false}
                      tickLine={false}
                      tickSize={20}
                    />
                    <YAxis
                      orientation="right"
                      interval="preserveStartEnd"
                      axisLine={false}
                      tickLine={false}
                      tickSize={20}
                    />
                    <CartesianGrid
                      stroke="#C4C4C4"
                      vertical={false}
                      opacity={0.5}
                    />
                    <Tooltip />
                  </AreaChart>
                )}
              </ResponsiveContainer>
            )}{" "}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Graph;
