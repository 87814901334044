// @ts-nocheck

import React, { useState } from "react";
import { PopupModal } from "../../utils/Popup-Modal";

const IconsObj = {
  veg: "/assets/veg-icon.svg",
  egg: "/assets/egg-icon.svg",
  nonVeg: "/assets/non-veg-icon.svg",
  vegan: "/assets/vegan-icon.svg",
  chefRecommend: "/assets/chef-true.svg",

  // for handling previous data
  Veg: "/assets/veg-icon.svg",
  "Non-Veg": "/assets/non-veg-icon.svg",
  "Contains Egg": "/assets/egg-icon.svg",
};

export const popupMessageObj = {
  veg: "This dish is vegetarian",
  egg: "This dish contains egg",
  nonVeg: "This dish is non-vegetarian",
  vegan: "This dish is vegan",
  chefRecommend: "This dish is recommended by chef",

  Veg: "This dish is vegetarian",
  "Non-Veg": "This dish is non-vegetarian",
  "Contains Egg": "This dish contains egg",
};

const handleArrPopup = (e, setopen, setmessage, type) => {
  if (e.target.offsetParent.classList.contains("MuiBox-root")) {
    setopen(true);
    setmessage(popupMessageObj[type]);
  }
};

const handlePopup = (setopen, setmessage, type) => {
  setopen(true);
  setmessage(popupMessageObj[type]);
};

export const VarietyIcons = ({ variety }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  return (
    <>
      <PopupModal
        open={popupOpen}
        setOpen={setPopupOpen}
        message={popupMessage}
      />
      <img
        onClick={() =>
          handlePopup(setPopupOpen, setPopupMessage, variety?.type)
        }
        src={IconsObj[variety?.type]}
      />
    </>
  );
};

export const IconsArr = ({ item }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const iconArr = [];
  if (item?.type) iconArr.push(item?.type);
  item?.varietyArr?.forEach((element) => {
    iconArr.push(element?.type);
  });
  if (item?.chefRecommend) iconArr.push("chefRecommend");

  return (
    <div className="d-flex my-auto">
      <PopupModal
        open={popupOpen}
        setOpen={setPopupOpen}
        message={popupMessage}
      />
      {[...new Set(iconArr)]
        .filter((icon) => icon !== "na" && icon)
        .map((icon) => {
          return (
            <div className="my-auto" style={{ marginRight: "5px" }}>
              <img
                onClick={(e) =>
                  handleArrPopup(e, setPopupOpen, setPopupMessage, icon)
                }
                src={IconsObj[icon]}
                style={{ width: "0.9rem" }}
              />
            </div>
          );
        })}
    </div>
  );
};

export const Item_names = (props) => {
  let nameStr;
  if (props?.varietyArr && props?.varietyArr?.length !== 0) {
    const varietyNames = props?.varietyArr
      ?.map((item) => item.name)
      ?.join(" / ");
    nameStr = `${props?.baseName} - ${varietyNames}`;
  } else {
    nameStr = props?.baseName;
  }

  return nameStr;
};

export const Item_prices = (props) => {
  let priceStr;
  if (props?.varietyArr && props?.varietyArr?.length !== 0) {
    const varietyPrices = props?.varietyArr
      ?.map((item) => item.price)
      ?.join(" / ");
    priceStr = varietyPrices;
  } else {
    priceStr = props?.price;
  }
  return priceStr;
};
