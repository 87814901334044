// @ts-nocheck
import React, { useEffect, useState } from "react";
import classes from "../styleSheets/QuickAccess.module.css";
import footerIcon1 from "../assets/icons/svgCommonIcons/FooterButton1.svg";
import BusinessProfileDropdown from "./BusinessProfileDropdown";
import { Grid, useMediaQuery } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WalletDrawer from "./WalletDrawer";
import { checkImageUrl } from "../utils/checkImageUrl";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import ImgComponent from "../assets/Group 851.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { BusinessDropdown } from "../RestaurantMenu/Modals Layout/BuisnessDropDown";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { AnalyticEvents } from "../utils/GoogleAnalytics/Events";
const AccessFooter = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  setId,
  profileType,
  setProfileType,
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
  customDesign = false,
  ...props
}) => {
  //     const [id, setId] = useState("1");
  //     const isMobile = window.screen.width;
  //     const [selectedProfile, setSelectedProfile] = useState("");
  //     const [businessName, setBusinessName] = useState("");
  //   //   const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN)
  //     const [profileType, setProfileType] = useState("");
  //     const [businessProfileName, setBusinessProfileName] = useState("");
  //     const [businessUserName, setBusinessUserName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:500px)");
  const { pathname } = useLocation();
  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    console.log(profile, "profileFooter", setProfile, selectedProfile);
    profile?.map?.((i) => {
      if (i?.profileId === selectedProfile) {
        setProfileImage(i?.image);
      }
    });
  }, [profile, selectedProfile, setProfile]);

  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/${selectedProfile}`, "AccessFooter Page");
    setAnalytics(analyticsInstance);
  }, []);

  return (
    <div className={`${classes.footer}`}>
      <div
        className={classes.ellipse}
        style={
          customDesign
            ? {
                width: "inherit",
              }
            : {}
        }
      >
        <Grid container>
          <Grid
            item
            xs={2}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <div
              onClick={() => navigate("/QuickAccess")}
              className={classes.footerButton1}
              style={{
                width: "35px",
                height: "35px",
                background: `${pathname === "/QuickAccess" ? "#7ECAB0" : "white"}`,
                borderRadius: "50%",
                padding: "12px",
              }}
              onKeyUp={() => false}
            >
              <img
                src={ImgComponent}
                style={{ width: "20px", height: "20px", marginTop: "-3.5px" }}
                alt="img"
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  fontSize: "10px",
                  textWrap: "nowrap",
                  color: "white",
                  marginBottom: "-10px",
                }}
              >
                Quick Access
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div
              className={`${classes.footerButton1} align-items-center gap-2`}
            >
              <div
                onClick={() => navigate(`/${businessUserName}`)}
                className={`${classes.footerButton2}`}
                style={{
                  color: "#27343C",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  cursor: "pointer",
                  marginRight: "0px",
                  padding: "0px",

                  // width: "132px",
                  justifyContent: "center",
                  textWrap: "noWrap",
                  background: `${pathname !== "/QuickAccess" ? "#7ECAB0" : "white"}`,
                }}
                onKeyUp={() => false}
              >
                {businessProfileName}
              </div>
              <div
                className="cursor-pointer"
                style={{
                  width: "20px",
                  paddingBottom: "2px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              >
                {/* <div
                  style={{
                    marginLeft: "1.5px",
                    marginBottom: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessProfileDropdown
                    hideName={true}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    selectedProfile={selectedProfile}
                    setSelectedProfile={setSelectedProfile}
                    businessProfileName={businessProfileName}
                    setBusinessProfileName={setBusinessProfileName}
                    businessUserName={businessUserName}
                    setBusinessUserName={setBusinessUserName}
                    id={id}
                    profileType={profileType}
                    setProfileType={setProfileType}
                    profile={profile}
                    setProfile={setProfile}
                  />
                </div> */}
                <BusinessDropdown
                  hideName={true}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                  businessProfileName={businessProfileName}
                  setBusinessProfileName={setBusinessProfileName}
                  businessUserName={businessUserName}
                  setBusinessUserName={setBusinessUserName}
                  id={id}
                  profileType={profileType}
                  setProfileType={setProfileType}
                  profile={profile}
                  setProfile={setProfile}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                />
              </div>
            </div>{" "}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <div
              className={classes.footerButton1}
              style={{ width: "35px", height: "35px" }}
            >
              {profileImage ? (
                <div
                  onClick={() => {
                    navigate("/adminprofile");
                    analytics.sendEvent(
                      AnalyticEvents.Profile.Footer,
                      AnalyticEvents.ProfileAction.clicked,
                      "Directed Towards Settings Page",
                    );
                  }}
                >
                  {checkImageUrl(profileImage) == "" ? (
                    <img
                      style={{ width: "35px", height: "35px" }}
                      className={classes.footerImage}
                      src={profileImage}
                      alt="profile"
                    />
                  ) : (
                    <img
                      className={classes.footerImage}
                      src={`${imgBaseUrl}/${profileImage}`}
                      style={{ width: "35px", height: "35px" }}
                      alt="footer"
                    />
                  )}
                </div>
              ) : (
                <div
                  onClick={() => {
                    navigate("/adminprofile");
                    analytics.sendEvent(
                      AnalyticEvents.Profile.Footer,
                      AnalyticEvents.ProfileAction.clicked,
                      "Directed Towards Settings Page",
                    );
                  }}
                  className={classes.footerButton3}
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onKeyUp={() => false}
                >
                  <AccountCircleIcon sx={{ fontSize: "52px" }} />
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  fontSize: "10px",
                  textWrap: "nowrap",
                  color: "white",
                  marginBottom: "-10px",
                }}
              >
                Settings
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AccessFooter;
