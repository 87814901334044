// @ts-nocheck
import "../../styleSheets/eventsMenu.css";
import React, { useRef, useState, useEffect } from "react";
import { Events } from "./event";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Pagination } from "swiper";
import classes from "../../styleSheets/FoodMenu.module.css";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { AddSlide } from "./addSlide";
import { useSelector } from "react-redux";

export const EventsInMenu = () => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const reduxFastSocialData = useSelector((state) => state.fastSocial.apiData);
  const reduxEventData = useSelector(
    (state) => state.fastSocial.apiData.Events,
  );
  const [eventData, setEventData] = useState(reduxEventData || []);

  if (!reduxEventData) {
    return;
  }

  console.log(menuAccessState, "EVENTDATA");

  const swiperArr = [FreeMode];
  if (!menuAccessState) swiperArr.push(Autoplay);

  return (
    <div className="mb-5 mt-3 mx-4">
      {eventData?.length === 0 && !menuAccessState ? (
        <div style={{ background: "red", marginTop: "-70px" }}></div>
      ) : (
        <div className={`menu-events-heading mb-4`}>Upcoming events</div>
      )}

      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={swiperArr}
        className="event-menu-swiper py-2 px-1"
        grabCursor={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {menuAccessState && (
          <SwiperSlide>
            <AddSlide
              accountId={reduxFastSocialData["Basic Details"].accountId}
            />
          </SwiperSlide>
        )}

        {eventData?.map((data) => {
          return (
            <SwiperSlide>
              <Events
                eventData={data}
                accountUserName={
                  reduxFastSocialData["Basic Details"].account.userName
                }
              />
            </SwiperSlide>
          );
        })}

        {/* {eventData?.length === 0 && !menuAccessState && 
        <div className="text-center fw-bold" style={{color: "gray"}}>No Upcoming Events</div>
        } */}
      </Swiper>
    </div>
  );
};
