// @ts-nocheck

import { imgBaseUrl } from "../../constants/ImageBaseUrl"
import { checkImageUrl } from "../../utils/checkImageUrl"

export const MappedCoverImage = ({data}) => {
  return (
    <div className="">
      {checkImageUrl(data?.image) == "" ? (
        <img
          className="img"
          src={data?.image}
        />
      ) : (
        <img
          className="img"
          src={`${imgBaseUrl}/${data?.image}`}
        />
      )}

    </div>
  )
}