// @ts-nocheck

const initialState = {
  apiData: {},
};

export const userDetailsReducer = (state = initialState, action) => {
  console.log("my stateeeeeeeeeee", state, action)
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return {
        ...state,
        apiData: action.payload,
        timestamp: new Date()
      };
    default:
      return state;
  }
};
