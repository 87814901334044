// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import { USER } from "../constants/localStorageKeys";
import AccessFooter from "../Components/AccessFooter";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { getbusinessProfiles } from "../API/authAPIs";
import { getUserProfile } from "../API/authMenuAPIs";
import { baseURL, MENU_API } from "../constants/Api";
import { setUserDetails } from "../redux/actions";

const PrivateRoutes = ({toShowAccessFooterOrNot = true}) => {
  const location = useLocation();

  const matches = useMediaQuery("(max-width: 900px)")
  const [navigateOnFailingAuth, setNavigateOnFailingAuth] = useState("/");
  const [authenticated, setauthenticated] = useState(false);
  const token = localStorage.getItem(USER.JWT_ACCESS_TOKEN);

  const dispatch = useDispatch();
  const fastEnvData = import.meta.env.VITE_FAST_SOCIAL;
  const [loader, setLoader] = useState();
  const [profile, setProfile] = useState([]);
  const [admin, setAdmin] = useState([]);
  // const theme = useTheme();

  // const isMatch = useMediaQuery(theme.breakpoints.down(900));
  const [businessName, setBusinessName] = useState("");
  const navigate = useNavigate();
  const [id, setId] = useState("1");
  const isMobile = window.screen.width;
  const num = localStorage.getItem(USER.MOBILE_NUMBER);
  const businessId = localStorage.getItem("businessId");
  const [selectedProfile, setSelectedProfile] = useState("");
  const businessName1 = localStorage.getItem(USER.businessName);
  const businessUserName1 = localStorage.getItem(USER.USERNAME);
  const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
  const [profileType, setProfileType] = useState("");
  const [businessProfileName, setBusinessProfileName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");
  const userDetailsRedux = useSelector((state) => state.userDetails.apiData);

  const callApis = async () => {
    setLoader(true);
    const data = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
    );
    console.log("DATA HAI", data);

    console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);

    setProfile(data);

    setLoader(false);
    console.log("businessProfiles", data);
  };

  const callApi = async () => {
    // setLoading(true)

    const selectedProfileId = localStorage.getItem(USER.businessId);

    {
      if ((selectedProfileId == null || selectedProfileId == "undefined") && profile?.length > 0 && profile[0]) {
        setBusinessProfileName(profile[0]?.name);
        setBusinessUserName(profile[0]?.userName);
        setSelectedProfile(profile[0]?.profileId);
        setProfileType(profile[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, profile[0]?.userName);
        localStorage.setItem(USER.businessName, profile[0]?.name);
        localStorage.setItem(USER.businessId, profile[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, profile[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    // if (Object.keys(userDetailsRedux).length === 0) callApis();
    // else {
    //   setAdmin(userDetailsRedux.userDetails);
    //   setProfile(userDetailsRedux.businessData);
    // }
    callApis();
  }, []);

  useEffect(() => {
    console.log(profile, "profile");
    callApi();
  }, [profile]);

  useEffect(() => {
    const pathnameArr = location.pathname.split("/");
    if (pathnameArr.includes("menu") && pathnameArr.includes("edit")) {
      const editElementIndex = pathnameArr.findIndex((el) => el == "edit");
      pathnameArr.splice(editElementIndex, 1);
      setNavigateOnFailingAuth(pathnameArr.join("/"));
    } else {
      setNavigateOnFailingAuth("/Home");
    }
  }, [location]);

  return (
    <div>
      {token ? (
        <>
          <div><Outlet /></div>
            {matches && toShowAccessFooterOrNot && <AccessFooter
              customDesign={true}
              businessName={businessName}
              setBusinessName={setBusinessName}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              businessProfileName={businessProfileName}
              setBusinessProfileName={setBusinessProfileName}
              businessUserName={businessUserName}
              setBusinessUserName={setBusinessUserName}
              id={id}
              setId={setId}
              profileType={profileType}
              setProfileType={setProfileType}
              profile={profile}
              setProfile={setProfile}
            />}
        </>
      ) : (
        <Navigate to={`${navigateOnFailingAuth}`} />
      )}
    </div>
  );
};

export default PrivateRoutes;
