import React from 'react';

const images = [
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
  'https://i.guim.co.uk/img/media/a6d708b3363d3537ce431767e9c8b1cc58927eeb/1063_5_2768_1661/master/2768.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=1def6c38e0b6db9a68ba5a6e2dbc9735',
];

const CameraIcon: React.FC = () => (
  <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.37677 37.7502C2.57618 37.7502 1.89528 37.4696 1.33406 36.9084C0.772812 36.3471 0.492188 35.6662 0.492188 34.8656V11.6324C0.492188 10.8338 0.772795 10.1534 1.33401 9.59115C1.89526 9.02892 2.57618 8.74781 3.37677 8.74781H10.0715L13.8736 4.41688H27.4954V9.70531H32.6917V14.9136H37.9922V34.8656C37.9922 35.6662 37.7111 36.3471 37.1489 36.9084C36.5866 37.4696 35.9062 37.7502 35.1076 37.7502H3.37677ZM19.2622 30.7871C21.3749 30.7871 23.1551 30.0632 24.6028 28.6156C26.0504 27.1679 26.7743 25.3878 26.7743 23.2751C26.7743 21.1276 26.0504 19.3321 24.6028 17.8884C23.1551 16.4448 21.3749 15.723 19.2622 15.723C17.1148 15.723 15.3192 16.4448 13.8756 17.8884C12.4319 19.3321 11.7101 21.1276 11.7101 23.2751C11.7101 25.3878 12.4319 27.1679 13.8756 28.6156C15.3192 30.0632 17.1148 30.7871 19.2622 30.7871ZM19.2622 29.1845C17.5555 29.1845 16.1372 28.6176 15.0074 27.4838C13.8776 26.35 13.3127 24.9471 13.3127 23.2751C13.3127 21.5683 13.8776 20.1501 15.0074 19.0203C16.1372 17.8904 17.5555 17.3255 19.2622 17.3255C20.9342 17.3255 22.3371 17.8904 23.4709 19.0203C24.6048 20.1501 25.1717 21.5683 25.1717 23.2751C25.1717 24.9471 24.6048 26.35 23.4709 27.4838C22.3371 28.6176 20.9342 29.1845 19.2622 29.1845ZM36.3776 10.3383V6.01943H32.0587V4.41688H36.3776V0.0859375H37.9802V4.41688H42.3111V6.01943H37.9802V10.3383H36.3776Z" fill="white"/>
  </svg>
);

const ImageGrid: React.FC = () => {
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '5px',
      padding: '10px 5px',
      backgroundColor: '#f0f0f0',
    }}>
      <div style={{
        aspectRatio: '1 / 1.36',
        overflow: 'hidden',
        backgroundColor: '#E0E0E0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
      }}>
        <div>
          <CameraIcon />
        </div>
        <div style={{
          position: 'absolute',
          width: '43px',
          height: '38px',
          filter: 'drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.75))',
          pointerEvents: 'none',
        }} />
      </div>
      {images.map((src, index) => (
        <div key={index} style={{
          aspectRatio: '1 / 1.36',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        }}>
          <img 
            src={src} 
            alt={`Grid item ${index + 1}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;