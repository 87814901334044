// @ts-nocheck
import { LOGIN_API } from "../constants/Api";
import { MENU_API } from "../constants/Api";
import { baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import { sessionExpireLogic } from "../utils/functionUtils";
// const baseURL = "https://api-connect.eatoes.com";

export const login = async (
  mobileNumber: number,
  isSignUp: boolean,
  isLogin: boolean,
) => {
  const body = JSON.stringify({
    phoneNumber: mobileNumber,
    isSignUp: isSignUp,
    isLogin: isLogin,
  });
  try {
    const response = await fetch(`${baseURL}${LOGIN_API.LOGIN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const data = await response.json();
    if (response && response.status === 200) {
      return data;
    }
    console.error("invalid response of login api");
  } catch (err) {
    console.log("something went wrong");
  }
};

export const mobileVerification = async (mobileNumber: string, otp: string) => {
  localStorage.setItem(USER.MOBILE_NUMBER, mobileNumber);
  const body = JSON.stringify({
    phoneNumber: mobileNumber,
    otp: otp,
  });

  const response = await fetch(`${baseURL}${LOGIN_API.OTP_VERIFICATION}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
  const data = await response.json();
  if (response && response.status === 200) {
    return data;
  }
  console.error("OTP verification api response is not Ok !!!");
};

export const getbusinessUsers = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.isUserNameAvailable;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};

export const updateUserProfile = async (
  name: string,
  phoneNumber: string,
  image: string,
  userName: string,
  navigate: (route: string) => void,
) => {
  const body = JSON.stringify({
    image: image,
    phoneNumber: phoneNumber,

    name: name,
    userName: userName,
  });
  const token = localStorage.getItem("accessToken");
  // console.log("user profile", userNumber)
  try {
    const response = await fetch(`${baseURL}${MENU_API.UPDATE_PROFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", data.userName);
    /*  localStorage.setItem(USER.USERNAME, data.userName); */

    if (response && response.status === 200) {
      console.log("order api response data", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const createSocialProfile = async (
  name: string,
  userName: string,
  image: string,
  profileType: string,
  array: string[],
  WhatsappPhoneNumber: string,
  businessNumber: string,
  gst: string,
  navigate: (route: string) => void,
) => {
  // const userId=localStorage.getItem(USER.businessId)

  const phoneNumber = localStorage.getItem(USER.MOBILE_NUMBER);
  console.log(phoneNumber);
  const body = JSON.stringify({
    name: name,
    userName: userName,
    image: image,
    profileType: profileType,
    categoryArray: array,
    WhatsappPhoneNumber,
    businessNumber,
    gst,
  });
  console.log(body, "body");

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(
      `${baseURL}${MENU_API.CREATE_BUSINESS_PROFILE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("businessProfile API", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error(" business api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const updateSocialProfile = async (
  name: string,
  userName: string,
  image: string,
  profileType: string,
  array: string[],
  id: string,
  WhatsappPhoneNumber: string,
  businessNumber: string,
  gst: string,
  navigate: (route: string) => void,
) => {
  // const userId=localStorage.getItem(USER.businessId)
  const phoneNumber = localStorage.getItem(USER.MOBILE_NUMBER);
  console.log(WhatsappPhoneNumber);
  const body = JSON.stringify({
    name: name,
    userName: userName,
    image: image,
    profileId: id,
    profileType: profileType,
    categoryArray: array,
    WhatsappPhoneNumber,
    businessNumber,
    gst,
    profileAddress: {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      state: "",
      pincode: "",
    },
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.EDIT_PROFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("businessProfile API", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error(" business api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const getUsers = async (url) => {
  try {
    const response = await fetch(url);
    const res = await response.json();
    const data = res?.body?.isUserNameAvailable;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};

export const getbusinessProfiles = async (url) => {
  const token = localStorage.getItem("accessToken");
  let response;
  try {
    if (token) {
      response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    const res = await response.json();
    console.log(res);
    if (response.status === 401) {
      sessionExpireLogic();
      // navigate("/Login");
    }

    console.log(response.status, "status");
    const data = res?.body;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};
export const getSocialProfile = async (url, navigate) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (response.status === 401) {
      sessionExpireLogic();
      navigate("/Login");
    }

    const data = res?.body;
    return data;
  } catch (err) {
    console.log("socialProfile", err);
  }
};

export const addNewUser = async (
  name: string,
  phoneNumber: number,
  role: string,
  profileId: string,
  navigate: (route: string) => void,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    name: name,
    mobileNumber: phoneNumber,
    role: role,
    restrauntId: profileId,
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_NEW_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("order api response data", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const editUser = async (
  id: string | number,
  Name: string,
  phoneNumber: number,
  role: string,
  profileId: string,
  navigate: (route: string) => void,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    userId: id,
    name: Name,
    phoneNumber: phoneNumber,
    restrauntId: profileId,
    role: role,
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.EDIT_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("order api response data", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error(" api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const deleteUser = async (
  id: string | number,
  profileId: string,
  navigate: (route: string) => void,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    userId: id,
    restrauntId: profileId,
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.DELETE_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("order api response data", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error(" api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const createCategory = async (
  name: string,
  navigate: (route: string) => void,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    name: name,
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.CREATE_CATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status === 200) {
      console.log("order api response data", data);
      return data;
    }
    if (response.status === 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error(" api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
