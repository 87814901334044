import React, { useEffect, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { timeData } from "../../devData/tableReserve";
import { useLocation, useNavigate } from "react-router-dom";
import { getTimeSlotsAPI } from "../../API/tableReserve/tableReserveAPI";
import { formatTimeSlot } from "../../utils/functionUtils";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

interface ComponentProps {
  numberOfDiners: number | null;
  reserveId: string;
}

interface radioType {
  slotStartTime: string;
  id: string;
}

export const TimeSelect = ({ numberOfDiners,reserveId }: ComponentProps) => {
  // const radios = timeData;
  const [radios, setRadios] = useState<Array<radioType>>([]);
  // console.log(useLocation().state.selectedTime)
  const navigate = useNavigate();
  const { state } = useLocation();
  const [radioValue, setRadioValue] = useState(radios[0]?.slotStartTime);
  const onChangeHandler = (e: any, slotId: string) => {
    setRadioValue(e.target.value);
    navigate("../user-info", {
      state: { ...state, selectedTime: e.target.value, slotId: slotId },
    });
  };

  const getTimeSlots = async () => {
    const dataReceived = await getTimeSlotsAPI(
      reserveId,
      state?.selectedDate,
      numberOfDiners,
      navigate,
    );
    console.log(dataReceived, "helloooooSlotsssssssssss");
    setRadios(dataReceived?.data);
    // console.log(dataReceived.data, "testing the featuressssss")
  };

  useEffect(() => {
    if (reserveId) getTimeSlots();
  }, [reserveId]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`reserve/select-time`, "TimeSelect Page");
  }, []);

  return (
    <div className="diner-select">
      <header className="select-headers">Slot ?</header>

      <div className=" row row-cols-3 diner-row">
        {radios.map((radio, idx) => (
          <div className="col">
            <ToggleButton
              key={idx}
              id={`tabSelector-${idx}`}
              type="radio"
              variant="outline-primary"
              name="tabSelector"
              value={radio?.slotStartTime}
              checked={radioValue === radio?.slotStartTime}
              onChange={(e) => onChangeHandler(e, radio?.id)}
              className="diner-radio"
            >
              {formatTimeSlot(radio?.slotStartTime)}
            </ToggleButton>
          </div>
        ))}
      </div>
    </div>
  );
};
