//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./ServiceCard.css";
import Stories from "react-insta-stories";
import { baseURL, MENU_API } from "../constants/Api";
import { getUserProfile } from "../API/authMenuAPIs";
import { json, Navigate } from "react-router-dom";
import { menuAPI } from "../API/MenuApi";
import { DigitalOceanFetch } from "../API/digitalocean-fetch-images";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { Upload_Images } from "../utils/Upload-Image";
import { useSelector } from "react-redux";

const StoriesFastSocials = ({ fastSocialAPiData }) => {
  const roleData = useSelector((state) => state.roles.apiData);
  const [stories, setStories] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);
  const id: string | undefined =
    fastSocialAPiData["Basic Details"]?.account?.id;
  const [imgEdit, setImgEdit] = useState<boolean>(false); // Control image modal
  const [images, setImages] = useState(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState(9 / 16); // Default aspect ratio
  const [isPaused, setIsPaused] = useState(false);
  const storyId: string[] =
    fastSocialAPiData["Basic Details"]?.account?.stories;

  const createStory = async (jsonPayLoad) => {
    try {
      const response = await fetch(`${baseURL}${MENU_API.CREATE_STORIES}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayLoad),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Failed to upload story", error);
    }
  };

  const fetchStories = async () => {
    try {
      const response = await fetch(`${baseURL}${MENU_API.GET_STORIES}/${id}`);
      if (!response.ok) throw new Error("Failed to fetch stories");
      const savedStories = await response.json();
      console.log(savedStories.stories, "============");

      setStories(savedStories.stories);
    } catch (error) {
      console.error("Error fetching stories", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchStories();
    }
  }, [id]);

  const uploadImgFunc = async (file) => {
    try {
      const fileUrl = await DigitalOceanFetch(file);

      const mediaType = file.type.startsWith("image") ? "image" : "video";
      const jsonPayLoad = {
        profileId: id,
        mediaContent: [
          {
            mediaType: mediaType,
            url: fileUrl,
          },
        ],
      };

      // Call createStory API to save the file URL in the story
      const newStory = await createStory(jsonPayLoad);

      console.log(newStory, ">>>>>>>>>>");

      if (newStory) {
        // Add the new story to the list of stories
        setStories([...stories, newStory.story]);
        storyId.push(newStory.story.id);
        console.log(storyId, "SSSSSSSSSSSSS");
      }
    } catch (error) {
      console.error("Error uploading file or saving story", error);
    }
    setIsUploading(false);
    setImgEdit(false);
  };

  const handleStoryEnd = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex(currentStoryIndex + 1);
    } else {
      setCurrentStoryIndex(-1);
    }
  };

  const handleStoryClick = (index, eventTarget) => {
    setCurrentStoryIndex(index);
    setShowMenu(false);
  };

  const handleDeleteStory = async (currentStoryIndex) => {
    console.log(currentStoryIndex, "CCCCCCCCCCCCC");

    const storyToDeleteId = storyId[currentStoryIndex];
    console.log(storyToDeleteId, "IIIIIIIIIIIIIIII");

    const updatedStories = await fetch(
      `${baseURL}${MENU_API.DELETE_STORIES}/${id}/${storyToDeleteId}`,
      {
        method: "DELETE",
      },
    );

    if (!updatedStories.ok) {
      throw new Error("Failed to delete story");
    }

    setStories((prevStories) =>
      prevStories.filter((story) => story.id !== storyToDeleteId),
    );
    storyId.splice(currentStoryIndex, 1);

    console.log(updatedStories, "PPPPPPPPPPPPPPPPPPPP");

    setCurrentStoryIndex(-1); // Close the story view after deletion
    setShowMenu(false);
  };

  const toggleMenu = (index) => {
    setCurrentStoryIndex(index);
    setShowMenu(!showMenu);
  };

  const handleOnNext = (currentStoryIndex) => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex(currentStoryIndex + 1);
    } else {
      setCurrentStoryIndex(-1);
    }
  };

  const handleOnPrevious = (currentStoryIndex) => {
    if (currentStoryIndex <= 0) {
      setCurrentStoryIndex(-1);
    } else {
      setCurrentStoryIndex(currentStoryIndex - 1);
    }
  };

  return (
    <>
      {roleData.responseData === "admin" ? (
        <div className="card links-card">
          <h5 className="card-title">Stories</h5>
          <div className="card-stories">
            <div className="links-container-story">
              <div className="completed-stories">
                <div className="stories-list">
                  {roleData.responseData === "admin" ? (
                    <button
                      className="btn custom-stories"
                      style={{ minWidth: "120px" }}
                      onClick={() => setImgEdit(true)}
                    >
                      <span
                        style={{
                          width: "24px",
                          height: "24px",
                          opacity: "0.5",
                        }}
                      >
                        +
                      </span>
                      <p style={{ opacity: "0.5", fontSize: "small" }}>
                        Add Stories
                      </p>
                    </button>
                  ) : (
                    ""
                  )}

                  {stories.map((story, index) => (
                    <div
                      key={index}
                      className="story-thumbnail"
                      onClick={(e) => handleStoryClick(index, e.target)}
                      style={{
                        cursor: "pointer",
                        width: "120px",
                        height: "170px",
                      }}
                    >
                      {story?.mediaContent[0]?.mediaType === "video" ? (
                        <video
                          src={`${imgBaseUrl}/${story?.mediaContent?.[0]?.url}`}
                          alt={`Story ${index}`}
                          width="120"
                          height="170"
                          style={{ borderRadius: "20px" }}
                        />
                      ) : (
                        <img
                          src={`${imgBaseUrl}/${story?.mediaContent?.[0]?.url}`}
                          alt={`Story ${index}`}
                          width="120"
                          height="170"
                          style={{ borderRadius: "20px" }}
                        />
                      )}
                      {roleData.responseData === "admin" ? (
                        <div
                          className="three-dot-button-thumbnail"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleMenu(index);
                          }}
                        >
                          ⋮
                        </div>
                      ) : (
                        ""
                      )}

                      {showMenu && currentStoryIndex === index && (
                        <div
                          className="story-menu-options"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={() => handleDeleteStory(currentStoryIndex)}
                            style={{ whiteSpace: "nowrap", fontSize: "small" }}
                          >
                            Delete Story
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {currentStoryIndex !== -1 && !showMenu && (
              <div className="fullscreen-story">
                <Stories
                  stories={stories.map((story) => ({
                    url: `${imgBaseUrl}/${story.mediaContent[0].url}`,
                    type: story.mediaContent[0].mediaType,
                  }))}
                  currentIndex={currentStoryIndex}
                  defaultInterval={10000}
                  width="100vw"
                  height="100vh"
                  onStoryEnd={handleStoryEnd}
                  onNext={() => handleOnNext(currentStoryIndex)}
                  onPrevious={() => handleOnPrevious(currentStoryIndex)}
                />
              </div>
            )}
            {/* Modal for Uploading Images */}
            <Upload_Images
              imgEdit={imgEdit}
              setImgEdit={setImgEdit}
              images={images}
              setImages={setImages}
              uploadImgFunc={uploadImgFunc}
              aspectRatio={aspectRatio}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              maxSizeMB={5}
              setAspectRatio={setAspectRatio}
              forType="FastSocial"
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default StoriesFastSocials;
