// @ts-nocheck
import { Box, Button, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchCurrentTheme, updateTheme } from "../API/MenuApi";

const ThemeRadioGroup = ({setShowOverlay, setShowDiv}) => {
  const [themes, setThemes] = useState([
    { name: "Theme v1" },
    { name: "Theme v2" },
  ]);
  const [apiTheme, setApiTheme] = useState("Theme v1");
  const [selectedValue, setSelectedValue] = useState("Theme v1");
  const menuId = localStorage.getItem("businessId");
  const apiCall = async () => {
    const current = await fetchCurrentTheme(menuId);
    if (current.responseData === "v1") {
      setSelectedValue("Theme v1");
      setApiTheme("Theme v1");
    }
    if (current.responseData === "v2") {
      setSelectedValue("Theme v2");
      setApiTheme("Theme v2");
    }
    console.log(current);
  };

  const handelClick = async () => {
    if (selectedValue === "Theme v1") {
      await updateTheme(menuId, "v1");
      setApiTheme("Theme v1");
    }
    if (selectedValue === "Theme v2") {
      const data = await updateTheme(menuId, "v2");
      setApiTheme("Theme v2");
      console.log(data);
    }

    setShowDiv(false)
    setShowOverlay(false)
  };

  useEffect(() => {
    apiCall();
  }, []);
  console.log(selectedValue);
  return (
    <div
      className="inner-div text-black"
      style={{ width: "100%", position: "fixed", bottom: "0" }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "100%", sm: 520 },
          bgcolor: "#27343C",
          pb: 3,
          pt: 3,
          alignItems: "center",
          textAlign: "center",
          boxShadow: " 0px -4px 15px rgba(0, 0, 0, 0.04)",
          borderRadius: "50px 50px 0px 0px",
        }}
      >
        <div style={{ color: "#7ECAB0" }}>CHOOSE YOUR MENU THEME</div>
        {themes.map((theme) => {
          return (
            <div className={` d-flex justify-content-between mx-5 mt-3`}>
              <div className="ms-2 text-white">{theme.name}</div>
              <Radio
                checked={selectedValue === theme.name}
                onChange={() => setSelectedValue(theme.name)}
                value={theme.name}
                name="theme-radio-buttons"
                sx={{
                  color: "#7ecab0",
                  "&.Mui-checked": {
                    color: "#7ecab0",
                  },
                }}
              />
            </div>
          );
        })}

        <Button
          sx={{ background: "#7ECAB0", color: "black", padding: "0.5rem 2rem" }}
          variant="contained"
          disabled={selectedValue == apiTheme}
          onClick={handelClick}
        >
          Apply
        </Button>
      </Box>
    </div>
  );
};

export default ThemeRadioGroup;
