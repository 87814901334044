// @ts-nocheck
import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./AddQRUser.module.css";
import { baseURL, QR_API } from "../constants/Api";
import TopHeader from "../utils/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";

const QRupdateUser = () => {
  const location = useLocation();
  const userData = location.state?.userData || {};
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: userData?.name || "",
    code: userData?.code || "",
    redirectUrl: userData?.redirectUrl || "",
    menuUrl: userData?.menuUrl || "",
    logo: userData?.logo || "",
    delivery: userData?.delivery || "",
    officialWebsite: userData?.officialWebsite || "",
    instagram: userData?.instagram || "",
    googleReview: userData?.googleReview || "",
    isOptedWhatsapp: userData?.isOptedWhatsapp || false,
    isSpinWheelOpted: userData?.isSpinWheelOpted || false,
    isMenuBypassOpted: userData?.isMenuBypassOpted || false,
    active: userData?.active || false,
    apiKey: userData?.apiKey || "",
  });

  const [initialData, setinitialData] = useState({});
  const [isChanged, setisChanged] = useState(false);

  useEffect(() => {
    setinitialData(formData);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: newValue };

      setisChanged(JSON.stringify(updatedData) !== JSON.stringify(initialData));

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isChanged) {
      console.log("No changes detected, Form will not get Updated");
      return;
    }

    try {
      const response = await axios.put(
        `${baseURL}${QR_API.UPDATE}/${userData.code}`,
        formData,
      );
      console.log("Form updated successfully:", response.data);
      navigate("/QRUserProfile");
    } catch (error) {
      console.error("There was an error updating the form:", error);
    }
  };

  return (
    <div className={styles.parentContainer}>
      <TopHeader name="Update User Details" />
      <div className={styles.formContainer}>
        <div className="container">
          <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="code">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="redirectUrl">Redirect URL</label>
              <input
                type="url"
                className="form-control"
                id="redirectUrl"
                name="redirectUrl"
                value={formData.redirectUrl}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="menuUrl">Menu URL</label>
              <input
                type="url"
                className="form-control"
                id="menuUrl"
                name="menuUrl"
                value={formData.menuUrl}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="logo">Logo</label>
              <input
                type="text"
                className="form-control"
                id="logo"
                name="logo"
                value={formData.logo}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="delivery">Delivery</label>
              <input
                type="text"
                className="form-control"
                id="delivery"
                name="delivery"
                value={formData.delivery}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="officialWebsite">Official Website</label>
              <input
                type="url"
                className="form-control"
                id="officialWebsite"
                name="officialWebsite"
                value={formData.officialWebsite}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="instagram">Instagram</label>
              <input
                type="url"
                className="form-control"
                id="instagram"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="googleReview">Google Review</label>
              <input
                type="url"
                className="form-control"
                id="googleReview"
                name="googleReview"
                value={formData.googleReview}
                onChange={handleChange}
              />
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isOptedWhatsapp">Opted for WhatsApp</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isOptedWhatsapp"
                  name="isOptedWhatsapp"
                  checked={formData.isOptedWhatsapp}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isSpinWheelOpted">Opted for Spin Wheel</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isSpinWheelOpted"
                  name="isSpinWheelOpted"
                  checked={formData.isSpinWheelOpted}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isMenuBypassOpted">Opted for Menu Bypass</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isMenuBypassOpted"
                  name="isMenuBypassOpted"
                  checked={formData.isMenuBypassOpted}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="Activate">Activate</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  checked={formData.active}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="apiKey">API Key</label>
              <input
                type="text"
                className="form-control"
                id="apiKey"
                name="apiKey"
                value={formData.apiKey}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isChanged}
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QRupdateUser;
