import React, { useEffect, useState } from "react";
import MyApp from "../../utils/calendarUtil";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

export const DateSelect = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`reserve/select-date/`, "DateSelect Page");
  }, []);
  return (
    <div className="diner-select">
      <header className="select-headers">Date ?</header>

      <div className=" d-flex justify-content-center">
        <MyApp />
      </div>
    </div>
  );
};
