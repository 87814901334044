// @ts-nocheck

import React from "react";
import { Modal } from "react-bootstrap";

import DragDrop from "./drag-n-drop";
import ImageCropper from "./image-cropper";
import "../styleSheets/bootstrap-modal.css";

export const Upload_Images = ({
  imgEdit,
  setImgEdit,
  images,
  setImages,
  uploadImgFunc,
  aspectRatio,
  isUploading,
  setIsUploading,
  maxSizeMB,
  select,
  setAspectRatio,
  forType,
}) => {
  const handleClose = () => {
    if (forType == "CarouselImg" || forType == "FastSocial") setImgEdit(false);
    else setImgEdit((prev) => ({ ...prev, imgEdit: false }));
    setImages("");
    setAspectRatio && setAspectRatio(9 / 16);
  };

  console.log(images, "UPLOAD IMAGES");
  const getFileUrl = (file) => {
    const fileExtension = file?.name.split(".").pop().toLowerCase();
    if (["mp4", "mov", "avi"]?.includes(fileExtension)) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  return (
    <>
      <Modal show={imgEdit} onHide={handleClose} className="bootstrap-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {aspectRatio === 2 / 1 ? "Add New Carousel" : `Change ${select}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form encType="multipart/form-data">
            {!images ? (
              <DragDrop setImages={setImages} maxSizeMB={maxSizeMB} />
            ) : (
              <ImageCropper
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                file={getFileUrl(images)}
                uploadImage={uploadImgFunc}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
