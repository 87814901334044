// @ts-nocheck
import React, { useEffect, useState } from "react";

import { getbusinessProfiles } from "../API/authMenuAPIs";
import { AUTH_MENU_API, MENU_API, TRANSACTIONS_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import Sidebar from "../Components/Sidebar";
import WalletCard from "../Components/WalletCard";
import WalletPage from "./WalletPage";
import classes1 from '../styleSheets/fastSocialVerification.module.css'
import Transactionlist from "../Components/Transactionlist";
import Graph from "../Components/Graph";
import classes from "../styleSheets/Dashboard.module.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import '../styleSheets/transactions/transactions.css'
import ellipse from '../assets/images/images/Ellipse.svg'
import Transactions from "../Components/Transactions";
import { getWalletTransactions } from "../API/WalletAPIs";
const Wallet = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  id,
  setId,
  businessUserName,
  setBusinessUserName,
  profileType,
  setProfileType,
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile
}) => {
  
  // const [transactionsData,setTransactionsData] = useState([]);
 
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(900));
  const navigate =useNavigate()
  
  const callApis = async () => {
    setLoading(true);
   
      const selectedProfileId = localStorage.getItem(USER.businessId);
      const data = await getbusinessProfiles(
        `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
        navigate,
      );
  
      setProfile(data);
      
      if (selectedProfileId == null || selectedProfileId == "undefined") {
        setBusinessProfileName(data[0]?.name);
        setBusinessUserName(data[0]?.userName);
        setSelectedProfile(data[0]?.profileId);
        setProfileType(data[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, data[0]?.userName);
        localStorage.setItem(USER.businessName, data[0]?.name);
        localStorage.setItem(USER.businessId, data[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, data[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
      
    setLoading(false);
    
  };
  useEffect(() => {
    // callApis();
    setId("1");
  }, []);
  console.log(businessProfileName, "buss");
  return (
    <>
      <div className={classes.display} style={{ overflow:'scroll'}}>
        <div className={classes.container} style={{margin: "0"}}>
          <WalletPage
            businessName={businessName}
            setBusinessName={setBusinessName}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            businessProfileName={businessProfileName}
            setBusinessProfileName={setBusinessProfileName}
            businessUserName={businessUserName}
            setBusinessUserName={setBusinessUserName}
            id={id}
            setId={setId}
            profileType={profileType}
            setProfileType={setProfileType}
            profile={profile}
            setProfile={setProfile}
            fetchProfile={fetchProfile} setFetchProfile={setFetchProfile}
          />
        
            <Graph
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
            />
          
        </div>

        
          <div
            className={classes.hide}
            // style={{ display:'flex', height: "100vh",overflow:'scroll'}}
          >
            <Transactions selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
             
              />
              
            {/* <Transactionlist
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
            /> */}
          </div>
      
      </div>
      
      
    </>
  );
};

export default Wallet;
