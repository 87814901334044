// @ts-nocheck
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import "../../styleSheets/react_select_css.css"
import { customStyles, reactSelectStyle } from '../react_select_styles';

export const CCodesInput = ({options, selectedOption, setSelectedOption}) => {
  // const options = [
  //   { value: '+91', label: '+91' },
  //   { value: '+93', label: '+93' },
  //   { value: '+98', label: '+98' },
  //   { value: '+355', label: '+355' },
  //   { value: '+253', label: '+253' },
  //   { value: '+213', label: '+213' },
  //   { value: '+54', label: '+54' }
  // ];

  // const [selectedOption, setSelectedOption] = useState(options[0])


  const handleChange = (selected) => {
    setSelectedOption(selected)
  }

  return (
    <div className='text-center'>
      <Select
        options={options}
        styles={reactSelectStyle}
        value={selectedOption}
        onChange={handleChange}
        menuShouldScrollIntoView={true}
        isSearchable={false}
        classNamePrefix={"event_action_input"}
      />
    </div>
  );
};