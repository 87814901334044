//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import contactphone_icon from "../assets/icons/Social_icons/contactphone_icon.svg";
import mail_icon from "../assets/icons/Social_icons/mail_icon.png";
import map_icon from "../assets/icons/Social_icons/map_icon.svg";
import facebook_icon from "../assets/icons/Social_icons/facebook_icon.svg";
import instagram_icon from "../assets/icons/Social_icons/instagram_icon.svg";
import hollow_wp_icon from "../assets/icons/Social_icons/whatsapp_icon.png";
import userPlaceholder from "../RestaurantMenu/Images/user-placeholder.jpeg";
import businessPlaceholder from "../RestaurantMenu/Images/placeholders/image.jpeg";
import "../styleSheets/Headersection.css";
import Pop_up_2option from "../utils/Pop_up_2option";

import Like_icon from "../assets/icons/Social_icons/like_icon.svg";
import search_icon from "../assets/icons/Social_icons/Search_icon.svg";
import Red_like_icon from "../assets/icons/Social_icons/Red_like_icon.svg";
import classes from "../styleSheets/FoodMenu.module.css";
import Marquee from "react-fast-marquee";
import Tick from "../assets/icons/svgSpecificIcons/tick.svg";
// import { api } from "./MockData";
import { useSelector } from "react-redux";
import { checkImageUrl } from "../utils/checkImageUrl";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { getUserProfile } from "../API/authMenuAPIs";
import { MENU_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import { formatWhatsappNumber } from "../utils/functionUtils";
import BackIcon from "../assets/icons/Social_icons/arrowSvg.svg";
import { SingleImgLightbox } from "../utils/Lightbox/SingleImgLightbox";

const HeaderSection = ({ data }) => {
  // console.log(data,'header data');
  const { state } = useLocation();
  // console.log(state,'staet hai ye');
  const api = useSelector((state) => state?.fastSocial?.apiData);
  // console.log(api,'api dat aheader')
  const rolesData = useSelector((state) => state.roles.apiData);
  const accountDetails = api?.["Basic Details"]?.account;
  const basicDetails = api?.["Basic Details"];
  const [userApiImage, setUserApiImage] = useState();
  const [click, setClick] = useState(false);
  const [name, setName] = useState([]);
  const activeProfileBusinessId=localStorage.getItem('businessId');
  console.log(activeProfileBusinessId);
  
  const handelclick = () => {
    setClick(!click);
    if (click) {
      data.likes = data.likes - 1;
    } else {
      data.likes = data.likes + 1;
    }
  };
  const token = localStorage.getItem(USER.JWT_ACCESS_TOKEN);

  const getUserImageFunc = async () => {
    const returnedData = await getUserProfile(
      `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
    );
    setUserApiImage(returnedData?.image);
  };
  function openWhatsAppChat() {
    const phoneNumber = formatWhatsappNumber(
      `${api["Contact Details"]?.links?.whatsapp?.cCode}${api["Contact Details"].links.whatsapp.url}`,
    );
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${encodedPhoneNumber}`;
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isAndroid) {
      const intentUrl = `intent://send/${encodedPhoneNumber}#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end`;
      window.location.href = intentUrl;
    } else if (isIOS) {
      const whatsappUrl = `https://wa.me/${encodedPhoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else {
      window.open(whatsappWebUrl, "_blank");
    }
  }

  const checkToken = () => {
    const check_name = name.find((element) => element.name === "SignIn");
    if (!token && !check_name) {
      setName(() => [
        ...name,
        {
          name: "SignIn",
          route: `/login?to_redirect=yes&path=/${api["Basic Details"]?.account?.userName}`,
        },
      ]);
    }
  };

  checkToken();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const checkAccess = async () => {
      // console.log("name in checkaccess", name);
      const searchOption = name.find((el) => el.name === "Edit Profile");
      if (!searchOption && rolesData?.responseData) {
        const tempVar = [
          {
            name: "Edit Profile",
            route: `/CreateBusiness?id=${activeProfileBusinessId}`,
        
          },
          ...name,
        ];

        setName(() => tempVar);
      }
    };
    checkAccess();
    checkToken();
  }, [api, rolesData, token]);

  if (Object.keys(api).length === 0) {
    return;
  }

  // const profileImg =
  //   api["Basic Details"]?.account.image ||
  //   `${imgBaseUrl}/${api["Basic Details"]?.account.image}`;

  return (
    <div className={`${classes.Container1}`}>
      <div className={`${classes.Container2}`}>
        <div className="text-white" id={"headsection"}>
          <div className="d-flex justify-content-between ms-4 me-4 pt-5 align-items-center">
            <Link to="search" className="">
              <img src={search_icon} className="p-2 pt-0" alt="search" />
            </Link>
            <div className="user-text ms-4">
              {api["Basic Details"]?.account?.userName}
              {api["Basic Details"]?.account?.services?.fastProfileVerification
                ?.enabled === true &&
                !(
                  api["Basic Details"]?.account?.services
                    ?.fastProfileVerification?.suspend === true
                ) && (
                  <span>
                    <img src={Tick} alt="tick" />
                  </span>
                )}
            </div>
            <div className="d-flex">
              <div
              
                className="image-container mt-2 "
                onLoad={token && getUserImageFunc}
              >
                {userApiImage ? (
                  !checkImageUrl(userApiImage) ? (
                    <img
                      src={userApiImage}
                      className="circular-image"
                      onLoad={token && getUserImageFunc}
                      alt="user img"
                    />
                  ) : (
                    <img
                      src={`${imgBaseUrl}/${userApiImage}`}
                      className="circular-image"
                      onLoad={token && getUserImageFunc}
                      alt="circle img"
                    />
                  )
                ) : token ? (
                  <img
                    src={userPlaceholder}
                    className="circular-image"
                    alt="default img"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="toppadding">
                <Pop_up_2option name={name} id="headsection" />
              </div>
            </div>
          </div>
          {api["Basic Details"]?.announcement && (
            <div className="text-announcement text-center mt-4 p-2 text-nowrap overflow-hidden">
              <Marquee pauseOnHover={true}>
                <div style={{ margin: "0 6rem" }}>
                  {api["Basic Details"]?.announcement}
                </div>
              </Marquee>
            </div>
          )}
          <div className="d-flex justify-content-center mt-5">
            
            {api["Basic Details"]?.account?.image ? (
              checkImageUrl(api["Basic Details"]?.account.image) === "" ? (
                <SingleImgLightbox
                  thumbnail={api["Basic Details"]?.account.image}
                  actualSrc={api["Basic Details"]?.account.image}
                  imgClassName="rounded-circle h-25 w-25 bg-white"
                />
              ) : (
                <SingleImgLightbox
                  thumbnail={`${imgBaseUrl}/${api["Basic Details"]?.account.image}`}
                  actualSrc={`${imgBaseUrl}/${api["Basic Details"]?.account.image}`}
                  imgClassName="rounded-circle h-25 w-25 bg-white"
                />
              )
            ) : (
              <SingleImgLightbox
                thumbnail={businessPlaceholder}
                actualSrc={businessPlaceholder}
                imgClassName="rounded-circle h-25 w-25 bg-white"
              />
            )}
          </div>
          <div className="flex justify-center text-center mt-3">
            <div>
              <div className="hotel-title fs-5">
                {api["Basic Details"]?.account?.name}
              </div>
              <div className="fs-6 mt-2">
                {api["Basic Details"]?.account?.associatedCategories
                  ?.map((obj) => obj.name)
                  .join(", ")}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            {api["Contact Details"].live &&
              api["Contact Details"].links.call.live && (
                <div className="mx-2">
                  <a
                    href={`tel:${api["Contact Details"]?.links?.call?.cCode} ${api["Contact Details"].links.call.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={contactphone_icon}
                      className="contact-icons rounded-2"
                      alt="contact"
                    />
                  </a>
                </div>
              )}
            {api["Contact Details"].live &&
              api["Contact Details"].links.email.live && (
                <div className="mx-2">
                  <a
                    href={`mailto: ${api["Contact Details"].links.email.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={mail_icon}
                      className="contact-icons"
                      alt="contact"
                    />
                  </a>
                </div>
              )}
            {api["Contact Details"].live &&
              api["Contact Details"].links.facebook.live && (
                <div className="mx-2">
                  <a
                    href={`https://www.facebook.com/${api["Contact Details"].links.facebook.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={facebook_icon}
                      className="contact-icons"
                      alt="contact"
                    />
                  </a>
                </div>
              )}
            {api["Contact Details"].live &&
              api["Contact Details"].links.instagram.live && (
                <div className="mx-2">
                  <a
                    href={`https://www.instagram.com/${api["Contact Details"].links.instagram.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagram_icon}
                      className="contact-icons"
                      alt="contact"
                    />
                  </a>
                </div>
              )}
            {api["Contact Details"].live &&
              api["Contact Details"].links.map.live && (
                <div className="mx-2">
                  <a
                    href={api["Contact Details"].links.map.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={map_icon}
                      className="contact-icons rounded-2"
                      alt="contact"
                    />
                  </a>
                </div>
              )}
            {api["Contact Details"].live &&
              api["Contact Details"].links.whatsapp.live && (
                <div className="mx-2">
                  <img
                    src={hollow_wp_icon}
                    className="contact-icons cursor-pointer"
                    onClick={openWhatsAppChat}
                    alt="contact"
                    onKeyUp={() => false}
                  />
                </div>
              )}
          </div>
          <div className="d-flex justify-content-center mt-4 visually-hidden ">
            <img
              src={click ? Red_like_icon : Like_icon}
              onClick={handelclick}
              onKeyUp={() => false}
              alt="contact"
            />
          </div>
          <div className="d-flex justify-content-center mt-1 visually-hidden">
            {/* <div className="me-2">{data.likes}</div> */}
            <Link
              to="/Sociallikepage"
              className="text-decoration-none text-white"
            >
              <div>Likes</div>
            </Link>
          </div>
          {api["Basic Details"]?.bio && (
            <div className=" text-center rounded-4 description py-4 mx-3 mt-4 shadow">
              <div className="bio-text">{api["Basic Details"]?.bio}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default HeaderSection;
