// @ts-nocheck
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import classes from "../styleSheets/Pdf.module.css";
import html2canvas from "html2canvas";
import { Grid } from "@mui/material";
import logo from "../assets/images/images/eatoes.jpg";
import { useNavigate } from "react-router-dom";
// import {PdfContext}  from '../Contexts/UseDownloadContext'
import classes1 from "../styleSheets/transactions/transactions.module.css";
import downloadIcon from "../assets/icons/BrowserIcon/download.svg";
import { getBill } from "../API/WalletAPIs";
import { MENU_API, WALLET_SERVICES_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import DownloadIcon from "@mui/icons-material/Download";
import { getSocialProfile } from "../API/authAPIs";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Document1 = ({ documentStatus, setDocumentStatus }) => {
  const navigate = useNavigate();
  console.log("entered");
  const profileId = localStorage.getItem(USER.businessId);
  const phoneNumber = localStorage.getItem(USER.MOBILE_NUMBER);
  const [profile, setProfile] = useState([]);
  const [active, setActive] = useState(false);
  const [data, setData] = useState([]);
  const act = async () => setActive(true);
  const callApi = async () => {
    const apiData = await getBill(
      `${baseURL}${WALLET_SERVICES_API.GET_BILL_DETAILS}/?transactionId=${localStorage.getItem(USER.TRANSACTION_ID)}`,
    );

    setData(apiData?.bill);
    const socialProfileDetails = await getSocialProfile(
      `${baseURL}${MENU_API.GET_SOCIAL_PROFILE}/?id=${profileId}`,
    );
    setProfile(socialProfileDetails);
    console.log(socialProfileDetails, "apiData");
  };
  const handledownload = async () => {
    await act();
    await callApi();

    console.log(data, "data");

    // await downloadPdf()
    // navigate('/')
  };
  const downloadPdf = async () => {
    const capture = document.getElementById("receipt");
    console.log("entered2", capture);
    await html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "JPEG", 0, 0, componentWidth, componentHeight);
      doc.save("receipt.pdf");
    });

    // setDocumentStatus(false)
  };
  useEffect(() => {
    handledownload();
  }, []);

  console.log(data, "data");

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/Pdf`, "Pdf Page");
  }, []);

  return (
    <>
      {/* <div style={{width:'100vw'}}><div><button onClick={handledownload}>Download</button></div> </div>  */}

      <div className={classes.button} onClick={downloadPdf}>
        {" "}
        <button className={classes.fullWidth}>
          <span>Download</span>{" "}
          <span>
            <DownloadIcon />
          </span>
        </button>
      </div>
      <div id="receipt" className={classes.mainContainer}>
        <div>
          <div className={classes.header}>
            <h2 className={classes.text}>INVOICE</h2>
          </div>
          <div className={classes.headerMargin}></div>
          <div className={classes.subHeader}>
            <div className={classes.text2}>ORIGINAL FOR RECEPIENT</div>
          </div>
          <div className={classes.subContainer1}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <div className={classes.text3}>EATOES WEB PRIVATE LIMITED</div>
                <div>
                  <span className={classes.text3}>GSTIN - </span>
                  <span className={classes.text4}>27AAHCE3076C1ZT</span>
                </div>
                <div>
                  <span className={classes.text3}>Address : </span>
                  <span className={classes.text4}>
                    Near Nav Bharat School, Sr. No. 1/3/4, Flat Number
                    E-2-103,Laxmi Shantiban Phase II, Jai Ganesh Group, NDA
                    ROAD, Shivane, Pune, Pune, Maharashtra, 411023
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.rightContainer}>
                <div>
                  <img src={logo} className={classes.logo} />
                </div>
                <Grid container>
                  <Grid item xs={4} className={classes.text3}>
                    Telephone
                  </Grid>
                  <Grid item xs={8} className={classes.text4}>
                    : 9634339954
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} className={classes.text3}>
                    Email
                  </Grid>
                  <Grid item xs={8} className={classes.text4}>
                    : connect@eatoes.com
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} className={classes.text3}>
                    Web
                  </Grid>
                  <Grid item xs={8} className={classes.text4}>
                    :{" "}
                    <a
                      className={classes.link}
                      href="https://connect.eatoes.com/"
                    >
                      www.eatoes.com
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* ************************* customer details  ****************************  */}
          <div className={classes.p17}>
            <div className={classes.subContainer2}>
              <div
                className={classes.text3}
                style={{ textAlign: "center", paddingBottom: "10px" }}
              >
                CUSTOMER DETAILS
              </div>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <div
                    className={classes.text3}
                    style={{ textTransform: "upperCase" }}
                  >
                    {localStorage.getItem(USER.businessName)}{" "}
                  </div>
                  {profile?.gst && (
                    <div>
                      <span className={classes.text3}>GST : </span>
                      <span className={classes.text3}>{profile?.gst}</span>
                    </div>
                  )}
                  <div>
                    <span className={classes.text3}>PHONE NUMBER : </span>
                    <span className={classes.text3}>{phoneNumber}</span>
                  </div>
                  {profile?.profileAddress && (
                    <div>
                      <span className={classes.text3}>Address : </span>
                      <span className={classes.text4}>
                        {profile?.profileAddress?.line1 &&
                          `${profile?.profileAddress?.line1} ,`}{" "}
                        {profile?.profileAddress?.line2 &&
                          `${profile?.profileAddress?.line2} ,`}{" "}
                        {profile?.profileAddress?.line3 &&
                          `${profile?.profileAddress?.line3} ,`}{" "}
                        {profile?.profileAddress?.city &&
                          `${profile?.profileAddress?.city} ,`}{" "}
                        {profile?.profileAddress?.state &&
                          `${profile?.profileAddress?.state} ,`}{" "}
                        {profile?.profileAddress?.pinCode &&
                          `${profile?.profileAddress?.pinCode}`}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} className={classes.rightContainer}>
                  <Grid container>
                    <Grid item xs={6} className={classes.text3}>
                      Invoice Number
                    </Grid>
                    <Grid item xs={6} className={classes.text4}>
                      : {data?.invoice}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} className={classes.text3}>
                      Invoice Date
                    </Grid>
                    <Grid item xs={6} className={classes.text4}>
                      : {data?.invoiceDate}
                    </Grid>
                  </Grid>
                  {data?.dueDate && (
                    <Grid container>
                      <Grid item xs={6} className={classes.text3}>
                        Due Date
                      </Grid>
                      <Grid item xs={6} className={classes.text4}>
                        : {data?.dueDate}
                      </Grid>{" "}
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={6} className={classes.text3}>
                      Bill Status
                    </Grid>
                    <Grid item xs={6} className={classes.text4}>
                      : Paid
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div></div>
          {/* ********************************  SERVICES ********************************* */}
          {data?.service === "wallet credited" && (
            <div className={classes.p17}>
              <div className={classes.subContainer3}>
                <Grid container spacing={0}>
                  <span className={classes.p4}></span>
                  <Grid item xs={8} className={classes.text3}>
                    Service name
                  </Grid>

                  <Grid item xs={3} className={classes.text3}>
                    Total price
                  </Grid>
                </Grid>
                <div>
                  <Grid container spacing={0} className={classes.subContainer4}>
                    <span className={classes.p4}></span>
                    <Grid item xs={8} className={classes.text4}>
                      Wallet Credited
                    </Grid>
                    <Grid item xs={3} className={classes.text4}>
                      <div>₹ {data?.details?.amount}</div>{" "}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.footer}>
                  <img className={classes.logo1} src={logo} />
                </div>
              </div>
            </div>
          )}
          {(data?.service === "digital menu" ||
            data?.service === "fast social verification") && (
            <div className={classes.p17}>
              <div className={classes.subContainer3}>
                <Grid container spacing={0}>
                  <span className={classes.p4}></span>
                  <Grid item xs={5} className={classes.text3}>
                    Service name
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.text3}
                    style={{ textAlign: "center" }}
                  >
                    Qty
                  </Grid>
                  <Grid item xs={2} className={classes.text3}>
                    Unit price
                  </Grid>
                  <Grid item xs={2} className={classes.text3}>
                    Total price
                  </Grid>
                </Grid>
                <div>
                  {data?.prepaid?.netAmount && (
                    <Grid
                      container
                      spacing={0}
                      className={classes.subContainer4}
                    >
                      <span className={classes.p4}></span>
                      <Grid item xs={5} className={classes.text4}>
                        {data?.service} Prepaid
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={classes.text4}
                        style={{ textAlign: "center" }}
                      >
                        <span>1</span>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text5}>IGST (18%)</div>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text4}>
                          ₹ {data?.prepaid?.igstAmount}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
                <div>
                  {data?.details?.expense && (
                    <Grid
                      container
                      spacing={0}
                      className={classes.subContainer4}
                    >
                      <span className={classes.p4}></span>
                      <Grid item xs={5} className={classes.text4}>
                        {data?.service}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={classes.text4}
                        style={{ textAlign: "center" }}
                      >
                        <span>1</span>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>{data?.details?.expense}</div>
                        <div className={classes.text5}>IGST (18%)</div>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>{data?.details?.expense}</div>
                        <div className={classes.text4}>
                          ₹ {data?.details?.gstAmount}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
                <div className={classes.footer}>
                  <img className={classes.logo1} src={logo} />
                </div>
              </div>
            </div>
          )}
          {data?.service === "google table reservation" && (
            <div className={classes.p17}>
              <div className={classes.subContainer3}>
                <Grid container spacing={0}>
                  <span className={classes.p4}></span>
                  <Grid item xs={6} className={classes.text3}>
                    Service name
                  </Grid>

                  <Grid item xs={2} className={classes.text3}>
                    Unit price
                  </Grid>
                  <Grid item xs={2} className={classes.text3}>
                    Total price
                  </Grid>
                </Grid>
                <div>
                  {data?.prepaid?.netAmount && (
                    <Grid
                      container
                      spacing={0}
                      className={classes.subContainer4}
                    >
                      <span className={classes.p4}></span>
                      <Grid item xs={6} className={classes.text4}>
                        {data?.service} Prepaid
                      </Grid>
                      {/* <Grid item xs={2}  className={classes.text4} style={{textAlign:'center'}}><span >1</span></Grid> */}
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text5}>IGST (18%)</div>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text4}>
                          ₹ {data?.prepaid?.igstAmount}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {data?.details?.expense && (
                    <Grid
                      container
                      spacing={0}
                      className={classes.subContainer4}
                    >
                      <span className={classes.p4}></span>
                      <Grid item xs={6} className={classes.text4}>
                        {data?.service}
                      </Grid>
                      {/* <Grid item xs={2}  className={classes.text4} style={{textAlign:'center'}}><span >1</span></Grid> */}
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.details?.expense}</div>
                        <div className={classes.text5}>IGST (18%)</div>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.details?.expense}</div>{" "}
                        <div>₹ {data?.details?.gstAmount}</div>{" "}
                      </Grid>
                    </Grid>
                  )}
                </div>
                <div className={classes.footer}>
                  <img className={classes.logo1} src={logo} />
                </div>
              </div>
            </div>
          )}
          {data?.service === "whatsapp API" && (
            <div className={classes.p17}>
              <div className={classes.subContainer3}>
                <Grid container spacing={0}>
                  <span className={classes.p4}></span>
                  <Grid item xs={5} className={classes.text3}>
                    Service name
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.text3}
                    style={{ textAlign: "center" }}
                  >
                    Qty
                  </Grid>
                  <Grid item xs={2} className={classes.text3}>
                    Unit price
                  </Grid>
                  <Grid item xs={2} className={classes.text3}>
                    Total price
                  </Grid>
                </Grid>
                <div>
                  {data?.prepaid?.netAmount && (
                    <Grid
                      container
                      spacing={0}
                      className={classes.subContainer4}
                    >
                      <span className={classes.p4}></span>
                      <Grid item xs={5} className={classes.text4}>
                        {data?.service} Prepaid
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={classes.text4}
                        style={{ textAlign: "center" }}
                      >
                        <span>1</span>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text5}>IGST (18%)</div>
                      </Grid>
                      <Grid item xs={2} className={classes.text4}>
                        <div>₹ {data?.prepaid.grossAmount}</div>
                        <div className={classes.text4}>
                          ₹ {data?.prepaid?.igstAmount}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {data?.details?.expense && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.subContainer4}
                      >
                        <span className={classes.p4}></span>
                        <Grid item xs={5} className={classes.text4}>
                          WhatsApp monthly subscription
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.text4}
                          style={{ textAlign: "center" }}
                        >
                          <span>{data?.details?.whatsappPlanUnits}</span>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>₹ {data?.details?.planAmount}</div>
                          <div className={classes.text5}>IGST (18%)</div>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>
                            ₹{" "}
                            {parseFloat(
                              data?.details?.planAmount *
                                data?.details?.whatsappPlanUnits,
                            ).toFixed(2)}
                          </div>
                          <div>₹ {data?.details?.gstOnAPIPlan}</div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={0}
                        className={classes.subContainer4}
                      >
                        <span className={classes.p4}></span>
                        <Grid item xs={5} className={classes.text4}>
                          UIC (user initiated conversation)
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.text4}
                          style={{ textAlign: "center" }}
                        >
                          <span>{data?.details?.uicUnits}</span>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>₹ {data?.details?.uicAmount}</div>
                          <div className={classes.text5}>IGST (18%)</div>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>
                            ₹{" "}
                            {data?.details?.uicAmount * data?.details?.uicUnits}
                          </div>
                          <div>
                            ₹{" "}
                            {parseFloat(
                              data?.details?.uicAmount *
                                data?.details?.gstOnUIC,
                            ).toFixed(2)}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.subContainer4}
                      >
                        <span className={classes.p4}></span>
                        <Grid item xs={5} className={classes.text4}>
                          BIC (business initiated conversation)
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.text4}
                          style={{ textAlign: "center" }}
                        >
                          <span>{data?.details?.bicUnits}</span>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>₹ {data?.details?.bicAmount}</div>
                          <div className={classes.text5}>IGST (18%)</div>
                        </Grid>
                        <Grid item xs={2} className={classes.text4}>
                          <div>
                            ₹{" "}
                            {data?.details?.bicAmount * data?.details?.bicUnits}
                          </div>
                          <div>
                            ₹{" "}
                            {parseFloat(
                              data?.details?.bicAmount *
                                data?.details?.gstOnBIC,
                            ).toFixed(2)}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
                <div className={classes.footer}>
                  <img className={classes.logo1} src={logo} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* : <div onClick={handledownload}>
            download */}
      {/* <div><div>Download Invoice</div><div>
                <img style={{height:'8px',width:'10px',marginTop:'4px',textAlign:'center',}}src={downloadIcon}/></div>
        
                </div> */}
      {/* </div> */}
    </>
  );
};

export default Document1;
