// @ts-nocheck
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import BusinessAccess from "./BusinessAccess";
import classes from "../styleSheets/UpdateProfile.module.css";
import React, { useEffect, useState } from "react";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { USER } from "../constants/localStorageKeys";
import { addNewUser, getbusinessProfiles } from "../API/authAPIs";
import { MENU_API, ROLES, baseURL } from "../constants/Api";
import "../styleSheets/AddUser.css";
import { AddUserButton } from "../Components/Button.style";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import TopHeader from "../utils/TopHeader";
import PhoneNumberInput from "../utils/PhoneNumberInput";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const AddUser = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState("Add");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get("id");
  const [loader, setLoader] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkRole, setCheckRole] = useState(false);
  const [checkNum, setCheckNum] = useState(false);

  const [profiles, setProfiles] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [id, setId] = useState("");
  const [delUser, setDeleteUser] = useState(false);
  const [checkNumber, setCheckNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };
  const checkEditAccess = async () => {
    let output;
    const userDetails = profiles?.filter((i) => {
      return (
        i.mobileNumber === Number(localStorage.getItem(USER.MOBILE_NUMBER))
      );
    });
    const accountUserRole = ROLES(userDetails[0]?.role);
    console.log(accountUserRole, role);
    if (
      (accountUserRole === "Manager" && role === "Admin") ||
      ((accountUserRole === "Staff" || accountUserRole === "Accounts") &&
        (role === "Admin" || role === "Manager"))
    ) {
      return (output = false);
    } else {
      return (output = true);
    }
  };

  const handleOpen = async (title) => {
    const result = await checkEditAccess();
    console.log(result);
    if (result === false) {
      setOpenWarning(true);
    } else {
      setOpen(true);
      setTitle(title);
    }
  };
  const handleClose = () => {
    setOpen(false);
    // setAnchorEl(false)
  };
  const handleReset = () => {
    // Reset all state values to their initial state or empty values
    setName("");
    setRole("");
    setCountryCode("+91");
    setPhoneNumber("");
  };

  const handleClick = (event, id, name, number, role) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setName(name);
    setPhoneNumber(number);
    setCheckNumber(number);
    setRole(ROLES(role));
    console.log("anchor", delUser);
  };
  const businessId = localStorage.getItem(USER.businessId);
  const handleClose1 = () => {
    setAnchorEl(null);
    setId("");
    setName("");
    setPhoneNumber("");
    setRole("");
    setCheckNumber("");
  };
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const callAPIs = async () => {
    setLoader(true);
    const businessData = await getbusinessProfiles(
      `${baseURL}${MENU_API.BUSINESS_USERS_PROFILE}/?id=${profileId}`,
      navigate,
    );
    console.log(businessData, "business");
    setProfiles(businessData);
    setLoader(false);
  };
  useEffect(() => {
    callAPIs();
  }, []);
  //  console.log(profiles,'business')
  const controlProps = (item) => ({
    checked: role === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleUser = async () => {
    // console.log("I am clicked")
    if (!phoneNumber.toString().match(/^\d{10}$/)) {
      console.log("1st condition");
      setCheckNum(true);
      return;
    }
    if (name === "") {
      console.log("2nd consoidtion");
      setCheckName(true);
      return;
    }
    if (role === "") {
      console.log("3rd lfskjflsjlfsfslk");
      setCheckRole(true);
      return;
    }

    console.log("Hello CHECK");

    if (title == "Add") {
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i].mobileNumber == phoneNumber) {
          alert("User with this number already exists");
          return;
        }
      }
      setLoading(true);
      const AddRole = ROLES(role);

      const addUser = await addNewUser(
        name,
        phoneNumber,
        AddRole,
        profileId,
        navigate,
      );

      setProfiles(addUser.body);
      setName("");
      setRole("");
      setPhoneNumber("");
      setLoading(false);
      setOpen(false);
    }
    if (title == "Edit") {
      for (var i = 0; i < profiles.length; i++) {
        if (
          profiles[i].mobileNumber == phoneNumber &&
          profiles[i].mobileNumber != checkNumber
        ) {
          alert("User with this number already exists");
          return;
        }
      }
      setLoading(true);
      const Role = await ROLES(role);
      const edit = await editUser(
        id,
        name,
        phoneNumber,
        Role,
        profileId,
        navigate,
      );
      console.log(profiles, "ab");
      console.log(edit.body, "up");
      setProfiles(edit.body);
      console.log(profiles, "up");
      setName("");
      setRole("");
      setPhoneNumber("");
      setLoading(false);
      setOpen(false);
      setAnchorEl(false);
    }
    navigate(-1);
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/Adduser`, "AddUser Page");
  }, []);

  return (
    <>
      <TopHeader name="Add Users" />
      <div
        className={`${classes.mainContainer} mt-5 justify-content-center mx-auto sudhar-js`}
      >
        {/* <div className={ `${classes.subcontainer}  add-user-body`}>
        <div className={ ` ${classes.row}`}>        
          <div className={`{classes.title} top-head-txt`}>Add Users</div>
        </div>
      </div> */}

        <div className="add-user-items">
          <Box
            className={`${classes.edit_dish}`}
            sx={customizeMUIComponents.ModalLabel}
          >
            {loading ? (
              <>Updating..</>
            ) : (
              <>
                <div
                  className="ms-2"
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      width: "146px",
                      height: "15px",
                      fontSize: "9px",
                      color: "#27343C",
                      fontWeight: "400",
                      lineHeight: "10.67px",
                      marginTop: "30px",
                    }}
                  >
                    Your Full Name{" "}
                    {checkName && (
                      <span /*  style={{ color: "#EF6C00" }} */>*</span>
                    )}
                  </Typography>

                  <div>
                    <input
                      type="text"
                      className="f-name-box"
                      placeholder="Enter Your Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div>
                    <Typography
                      sx={{
                        width: "146px",
                        height: "15px",
                        fontSize: "9px",
                        color: "#27343C",
                        fontWeight: "400",
                        lineHeight: "10.67px",
                      }}
                    >
                      Select User Role{" "}
                      {checkRole && (
                        <span /*  style={{ color: "#EF6C00" }} */>*</span>
                      )}
                    </Typography>
                    <div>
                      <FormControl
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "12px",
                            color: "#27343C",
                            width: "63px",
                            height: "15px",
                          },
                        }}
                      >
                        <RadioGroup
                          sx={{
                            "& .PrivateSwitchBase-input": {
                              width: "20px",
                              height: "0px",
                              backgroundColor: "red",
                              display: "inline-flex",
                              justifyContent: "left",
                            },
                          }}
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="none"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Admin"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Admin")}
                                sx={{
                                  color: "#27343C",
                                  marginLeft: "20px",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Admin"
                          />
                          <FormControlLabel
                            value="Manager"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Manager")}
                                sx={{
                                  color: "#27343C",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Manager"
                          />
                          <FormControlLabel
                            value="Accounts"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Accounts")}
                                sx={{
                                  color: "#27343C",
                                  marginLeft: "20px",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Accounts"
                          />
                          <FormControlLabel
                            value="Staff"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Staff")}
                                sx={{
                                  color: "#27343C",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Staff"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <Typography
                      sx={{
                        width: "146px",
                        height: "15px",
                        fontSize: "9px",
                        color: "#27343C",
                        fontWeight: "400",
                        lineHeight: "10.67px",
                        marginTop: "30px",
                      }}
                    >
                      Phone Number{" "}
                      {checkNum && (
                        <span /* style={{ color: "#EF6C00" }} */>*</span>
                      )}
                    </Typography>

                    <div className="phone-number-box">
                      <PhoneNumberInput
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                      />
                      {checkNum == true ? (
                        <div className={classes.mandatoryText}>
                          *Add correct number
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/*  {(name && phoneNumber && role) === "" ? (
                      <div className={`{classes.mandatoryText} inval-numb-txt`}>
                        All Details are mandatory
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* <input
                    type="tel"
                    style={customizeMUIComponents.businessAccessInputBox}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  /> */}
                  </div>
                  {/*  {checkNum == true ? (
                    <div className={classes.mandatoryText}>
                      *Add correct number
                    </div>
                  ) : (
                    ""
                  )}*/}
                  {(name && phoneNumber && role) === "" ? (
                    <div className={`{classes.mandatoryText} inval-numb-txt`}>
                      All Details are mandatory
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="ca" style={customizeMUIComponents.row}>
                    <AddUserButton
                      marginTop="50px"
                      backgroundColor="#D1D1D1"
                      variant="outlined"
                      onClick={() => setOpen(true)}
                    >
                      Cancel
                    </AddUserButton>

                    <Dialog open={open} onClose={() => handleClose(false)}>
                      <DialogTitle className="discard-header">
                        Discard all saved changes ?
                      </DialogTitle>
                      {/* <DialogContent>
                      <DialogContentText>
                        Discard all saved changes ?
                      </DialogContentText>
                    </DialogContent> */}
                      <DialogActions className="ms-3">
                        <AddUserButton
                          marginTop="-60px"
                          onClick={() => handleClose(true)}
                          color="primary"
                        >
                          No
                        </AddUserButton>
                        <AddUserButton
                          marginTop="-60px"
                          onClick={() => navigate(-1)}
                          color="primary"
                        >
                          Yes
                        </AddUserButton>
                      </DialogActions>
                    </Dialog>

                    <AddUserButton
                      marginTop="50px"
                      backgroundColor="#D1D1D1"
                      variant="contained"
                      onClick={handleUser}
                    >
                      {title}
                    </AddUserButton>
                  </div>
                </div>
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default AddUser;
