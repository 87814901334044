// @ts-nocheck

const initialState = {
  profiles: {},
  currentProfileId: null,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE_DATA":
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.payload.profileId]: action.payload.data,
        },
        currentProfileId: action.payload.profileId,
      };
    case "CLEAR_CURRENT_PROFILE":
      return {
        ...state,
        currentProfileId: null,
      };
    default:
      return state;
  }
};

export default menuReducer;
