// @ts-nocheck

import { useNavigate } from "react-router-dom"
import BackIcon from "../../assets/icons/Social_icons/arrowSvg.svg"


export const SearchHeader = ({setInputVal, inputVal}) => {
  const navigate = useNavigate()

  return (
    <div className="header-outer-div">
      <div className="">
        <img
          style={{ marginLeft: "-10px" }}
          src={BackIcon}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="search-input-div">
        <input 
          value={inputVal}
          onChange={(e)=>setInputVal(e.target.value)}
        />
      </div>
    </div>
  )
}