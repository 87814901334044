// @ts-nocheck

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import classes from "../../styleSheets/FoodMenu.module.css";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import { TextLimiter } from "../../utils/TextLimiter";

export const Cat_Subcat_Layout = ({
  type,
  categoryName,
  setCategoryName,
  error,
  onClickBtn,
}) => {
  return (
    <div
      className="inner-div"
      style={{ width: "100%", position: "fixed", bottom: "0" }}
    >
      <Box className={classes.edit_dish} sx={customizeMUIComponents.textModal}>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "200px",
            }}
          >
            {/* <Typography sx={customizeMUIComponents.text1}>Name {type}</Typography> */}
            <p
              style={{
                color: "grey",
                textAlign: "left",
                maxWidth: "300px",
                width: "100%",
                fontSize: "14px",
              }}
            >
              {type} Name
            </p>

            <input
              type="text"
              style={customizeMUIComponents.inputBox}
              className={classes.add_cat_subcat_input}
              placeholder="Name"
              value={categoryName}
              onChange={(e) => {
                if(type == "Category") setCategoryName(e.target.value.substring(0, 50));
                else setCategoryName(e.target.value)
              }}
              required
            />
            {type === "Category" ? (
              <p
                className={`text-danger mt-2  ${error ? "opacity-100" : "opacity-0"
                  }`}
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  maxWidth: "300px",
                  width: "100%",
                }}
              >
                This category name already exists, change it
              </p>
            ) : (
              <p
                className={`text-danger mt-2  ${error ? "opacity-100" : "opacity-0"
                  }`}
                style={{ fontSize: "14px", textAlign: "center" }}
              >
                This Subcategory name already exists in this category, change it
              </p>
            )}

            <div style={{marginRight: "-260px", marginTop: "-5px"}} className={`pb-2 ${type !== "Category" && "d-none"}`}>
              <TextLimiter value={categoryName} maxLimit={50} />
            </div>


            <div style={customizeMUIComponents.row}>
              <Button
                variant="contained"
                sx={customizeMUIComponents.editButton2}
                onClick={onClickBtn}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      </Box>
    </div>
  );
};
