//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./ServiceCard.css";
import { useNavigate, useParams } from "react-router-dom";
import { USER } from "../constants/localStorageKeys";

const ServiceCard = ({ fastSocialAPiData }) => {
  const navigate = useNavigate();
  const paramObj = useParams();
  const userName = paramObj.userName;

  const [services, setServices] = useState(
    fastSocialAPiData["Basic Details"]?.account?.services || null,
  );

  const [businessUserName, setBusinessUserName] = useState(
    localStorage.getItem(USER.USERNAME),
  );

  useEffect(() => {
    const fetchServices = async () => {
      try {
        if (!services) {
          const fetchedServices = await getAllData(userName, navigate);
          setServices(
            fetchedServices["Basic Details"]?.account?.services || {},
          );
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchServices();
  }, [userName, services, navigate]);

  const handleOnClick = (route) => {
    navigate(route);
  };

  return (
    <div className="card links-card">
      <h5 className="card-title">Services</h5>
      <div className="card-body">
        <div className="links-container">
          {services &&
            Object.entries(services)
              .filter(
                ([serviceName, serviceDetails]) =>
                  serviceName !== "fastProfileVerification" &&
                  serviceDetails.enabled &&
                  !serviceDetails.suspend,
              )
              .map(([serviceName, serviceDetails]) => {
                let route;
                let name;

                switch (serviceName) {
                  case "fastProfileVerification":
                    route = `/${userName}`;
                    name = "Fast Social";
                    break;
                  case "digitalMenu":
                    route = `/edit/menu/${userName}`;
                    name = "Digital Menu";
                    break;
                  case "googleTableReservation":
                    route = `/reserve/${fastSocialAPiData["Basic Details"].accountId}`;
                    name = "Table Reservation";
                    break;
                  case "whatsAppBusiness":
                    route = "/contact";
                    name = "WhatsApp Business";
                    break;
                  default:
                    return null;
                }

                return (
                  <button
                    key={serviceName}
                    className="btn custom-link"
                    onClick={() => handleOnClick(route)}
                  >
                    {name}
                  </button>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
