import { useState, useEffect } from "react";
import "./Components.scss";

const SlotItem = ({ id, time, updateSlot, isAvailable,  ...rest }) => {

  const [isChecked, setIsChecked] = useState(false);

  const convertTo24HourFormat = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    let formattedTime = date.toLocaleTimeString('en-US', options);
  
    // Check if the time is '24:00' and replace it with '00:00'
    formattedTime = formattedTime === '24:00' ? '00:00' : formattedTime;
  
    return formattedTime;
  };
  

  const handleChange = () => {

    
    // Call the slot update API
    updateSlot(isChecked ? 'unavailable':'available', convertTo24HourFormat(time));
    setIsChecked(isChecked => !isChecked);
   
  };

  const convertTo12HourFormat = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString('en-US', options);
  };
  
 

  useEffect(() => {
    // Set the local state based on the prop
    setIsChecked(isAvailable);
  }, [isAvailable]);


  return (
    <div className="slot-item">
    <div className="slot-time">{convertTo12HourFormat(time)}</div>

    <div className="slot-body">
      
      <label
        htmlFor={id}
        className={`slot-switch ${isChecked ? "booked" : ""}`}
      >
        <div className="switch-round" />
        
        <input
          id={id}
          type="checkbox"
          onChange={handleChange}
          {...rest}
        />
      </label>
      <span className="liveLabel">Live</span>
    </div>
  </div>
  );
};

export default SlotItem;
