// @ts-nocheck
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux"
// import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DragDrop from "../../utils/drag-n-drop";
import ImageCropper from "../../utils/image-cropper";
import { Upload_Images } from "../utils/Upload-Image";
import { cloudinaryFetch } from "../../API/Cloudinary-fetch-images";

import { baseURL } from "../../constants/Api";
import { DigitalOceanFetch } from "../API/digitalocean-fetch-images";
import { setApiData, setFastSocialData } from "../redux/actions";
import { addHomePageImages } from "../API/authMenuAPIs";
import { addUpdateMedia } from "../API/fast_social_apis";
import _ from "lodash"
export const Add_fast_social_images= ({
  imgEdit,
  setImgEdit,
  images,
  setImages,
  indexPosition,
  accountId
}) => {
  const api = useSelector(state => state?.fastSocial?.apiData)
  const dispatch = useDispatch()
  const [serchParams] = useSearchParams();
  const categoryId = serchParams.get("categoryId")
  const [isUploading, setIsUploading] = useState(false);

  const restaurantName = localStorage.getItem("RestrauntName");

  async function uploadImgFunc(tosendImage) {
   const dataReceived = await addUpdateMedia(tosendImage, categoryId, indexPosition,accountId)
   const tempData = _.cloneDeep(api)
   tempData["Media Section"].media = dataReceived.responseData
   dispatch(setFastSocialData(tempData))
   setImgEdit(false);
   setImages(null);
   setIsUploading(false);
  }

  return (
    <div>
      <Upload_Images
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        forType={"FastSocial"}
        images={images}
        setImages={setImages}
        uploadImgFunc={uploadImgFunc}
        aspectRatio={1 / 1}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        maxSizeMB={1}
      />
    </div>
  );
};
export default Add_fast_social_images