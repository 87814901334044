export default () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <g clipPath="url(#clip0_122_450)">
      <path
        d="M20.84 4.16008L16.207 8.79307C15.2581 7.84424 13.9475 7.25728 12.5 7.25728V0.705566C15.7568 0.705566 18.7058 2.02537 20.84 4.16008Z"
        fill="url(#paint0_linear_122_450)"
      />
      <path
        d="M8.79258 8.7929C7.84375 9.74173 7.25679 11.0524 7.25679 12.4999H0.705078C0.705078 9.2431 2.02488 6.29408 4.15959 4.15991L8.79258 8.7929Z"
        fill="url(#paint1_linear_122_450)"
      />
      <path
        d="M12.5001 0.705566V7.25728C11.0527 7.25728 9.74197 7.84424 8.79315 8.79307L4.16016 4.16008C6.29432 2.02537 9.24334 0.705566 12.5001 0.705566Z"
        fill="url(#paint2_linear_122_450)"
      />
      <path
        d="M24.2936 12.4999H17.7418C17.7418 11.0524 17.1549 9.74173 16.2061 8.7929L20.839 4.15991C22.9738 6.29408 24.2936 9.2431 24.2936 12.4999Z"
        fill="url(#paint3_linear_122_450)"
      />
      <path
        d="M24.2936 12.4999C24.2936 15.7566 22.9738 18.7057 20.839 20.8398L16.2061 16.2068C17.1549 15.258 17.7418 13.9473 17.7418 12.4999H24.2936Z"
        fill="url(#paint4_linear_122_450)"
      />
      <path
        d="M8.79258 16.2068L4.15959 20.8398C2.02488 18.7057 0.705078 15.7566 0.705078 12.4999H7.25679C7.25679 13.9473 7.84375 15.258 8.79258 16.2068Z"
        fill="url(#paint5_linear_122_450)"
      />
      <path
        d="M20.84 20.8398C18.7058 22.9745 15.7568 24.2943 12.5 24.2943V17.7426C13.9475 17.7426 15.2581 17.1556 16.207 16.2068L20.84 20.8398Z"
        fill="url(#paint6_linear_122_450)"
      />
      <path
        d="M12.5001 17.7426V24.2943C9.24334 24.2943 6.29432 22.9745 4.16016 20.8398L8.79315 16.2068C9.74197 17.1556 11.0527 17.7426 12.5001 17.7426Z"
        fill="url(#paint7_linear_122_450)"
      />
      <path
        d="M18.8266 6.17335L16.207 8.793C15.2581 7.84418 13.9475 7.25722 12.5 7.25722V3.5531C14.9709 3.5531 17.2071 4.55437 18.8266 6.17335Z"
        fill="url(#paint8_linear_122_450)"
      />
      <path
        d="M8.79264 8.79288C7.84381 9.7417 7.25685 11.0524 7.25685 12.4998H3.55273C3.55273 10.0289 4.55396 7.79278 6.17298 6.17322L8.79264 8.79288Z"
        fill="url(#paint9_linear_122_450)"
      />
      <path
        d="M12.4995 3.5531V7.25722C11.052 7.25722 9.74133 7.84418 8.79251 8.793L6.17285 6.17335C7.79241 4.55437 10.0285 3.5531 12.4995 3.5531Z"
        fill="url(#paint10_linear_122_450)"
      />
      <path
        d="M21.4469 12.4998H17.7428C17.7428 11.0524 17.1559 9.7417 16.207 8.79288L18.8267 6.17322C20.4457 7.79278 21.4469 10.0289 21.4469 12.4998Z"
        fill="url(#paint11_linear_122_450)"
      />
      <path
        d="M21.4469 12.4999C21.4469 14.9708 20.4457 17.2069 18.8267 18.8265L16.207 16.2068C17.1559 15.258 17.7428 13.9473 17.7428 12.4999H21.4469Z"
        fill="url(#paint12_linear_122_450)"
      />
      <path
        d="M8.79264 16.2068L6.17298 18.8265C4.55401 17.2069 3.55273 14.9708 3.55273 12.4999H7.25685C7.25685 13.9473 7.84381 15.258 8.79264 16.2068Z"
        fill="url(#paint13_linear_122_450)"
      />
      <path
        d="M18.8266 18.8264C17.2071 20.4454 14.9709 21.4467 12.5 21.4467V17.7426C13.9475 17.7426 15.2581 17.1556 16.207 16.2068L18.8266 18.8264Z"
        fill="url(#paint14_linear_122_450)"
      />
      <path
        d="M12.4995 17.7426V21.4467C10.0285 21.4467 7.79241 20.4454 6.17285 18.8264L8.79251 16.2068C9.74133 17.1556 11.052 17.7426 12.4995 17.7426Z"
        fill="url(#paint15_linear_122_450)"
      />
      <path
        d="M22.2014 5.79248L17.3889 10.605C17.6169 11.1928 17.7422 11.8317 17.7422 12.5C17.7422 13.9474 17.1553 15.2581 16.2065 16.2069C15.2576 17.1558 13.9469 17.7427 12.4995 17.7427C11.8313 17.7427 11.1923 17.6174 10.6045 17.3893L5.79199 22.2019C7.6964 23.5211 10.0076 24.2944 12.4995 24.2944C15.7563 24.2944 18.7053 22.9746 20.8395 20.8399C22.9742 18.7058 24.294 15.7567 24.294 12.5C24.294 10.0081 23.5206 7.69689 22.2014 5.79248Z"
        fill="url(#paint16_linear_122_450)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_122_450"
        x1="11.2233"
        y1="0.262037"
        x2="19.0209"
        y2="8.05959"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF5D9" />
        <stop offset="1" stopColor="#FFCE47" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_122_450"
        x1="2.20826"
        y1="8.1058"
        x2="5.69851"
        y2="11.596"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FA953" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_122_450"
        x1="7.90094"
        y1="2.00361"
        x2="12.4877"
        y2="6.59042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E08E" />
        <stop offset="1" stopColor="#6FA953" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_122_450"
        x1="16.2654"
        y1="5.30481"
        x2="23.7099"
        y2="12.7494"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE47" />
        <stop offset="1" stopColor="#FF9356" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_122_450"
        x1="17.9192"
        y1="12.0227"
        x2="22.2349"
        y2="16.3385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9356" />
        <stop offset="1" stopColor="#FF755C" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_122_450"
        x1="1.5864"
        y1="12.5481"
        x2="6.66469"
        y2="17.6263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" />
        <stop offset="1" stopColor="#45A2E0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_122_450"
        x1="12.4917"
        y1="18.3888"
        x2="16.752"
        y2="22.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF755C" />
        <stop offset="1" stopColor="#FF4181" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_122_450"
        x1="5.21962"
        y1="16.1806"
        x2="15.5213"
        y2="26.4824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4181" />
        <stop offset="1" stopColor="#843561" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_122_450"
        x1="17.2095"
        y1="8.26143"
        x2="12.4104"
        y2="3.46237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF5D9" />
        <stop offset="1" stopColor="#FFCE47" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_122_450"
        x1="7.727"
        y1="12.2007"
        x2="1.77896"
        y2="6.25263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FA953" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_122_450"
        x1="11.8869"
        y1="7.41393"
        x2="6.59344"
        y2="2.1205"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E08E" />
        <stop offset="1" stopColor="#6FA953" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_122_450"
        x1="21.3905"
        y1="12.4423"
        x2="15.8024"
        y2="6.85415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE47" />
        <stop offset="1" stopColor="#FF9356" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_122_450"
        x1="21.0053"
        y1="16.5316"
        x2="17.2719"
        y2="12.7983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9356" />
        <stop offset="1" stopColor="#FF755C" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_122_450"
        x1="9.19381"
        y1="18.1421"
        x2="2.30097"
        y2="11.2492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" />
        <stop offset="1" stopColor="#45A2E0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_122_450"
        x1="16.7312"
        y1="21.2045"
        x2="11.7072"
        y2="16.1805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF755C" />
        <stop offset="1" stopColor="#FF4181" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_122_450"
        x1="12.6129"
        y1="21.5612"
        x2="6.48148"
        y2="15.4298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4181" />
        <stop offset="1" stopColor="#843561" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_122_450"
        x1="17.5634"
        y1="17.5639"
        x2="14.0001"
        y2="14.0006"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#843561" stopOpacity="0" />
        <stop offset="1" stopColor="#3A2F4D" />
      </linearGradient>
      <clipPath id="clip0_122_450">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
