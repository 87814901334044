// @ts-nocheck
import React from "react"
import placeholder from "../../RestaurantMenu/Images/placeholders/item_placeholder.jpg";
import classes from "../../styleSheets/FoodMenu.module.css";

import { checkImageUrl } from "../../utils/checkImageUrl"
import { imgBaseUrl } from "../../constants/ImageBaseUrl"
import { IconsArr, Item_names, Item_prices } from "./Item-details-extract";
import MenuModal from "../Modals Layout/MenuModal";
import "../../styleSheets/item-section.css";
import { USER } from "../../constants/localStorageKeys";
import { useSelector } from "react-redux";


export const MappedItem = ({index, item, handleOpen, itemData, id, setitemData, isDragOverlay, callApis}) => {
  const menuAccessState = useSelector(state => state.menuAccess.apiData )



  return (
    <div key={index}>
      {/* <div className={classes.column}> */}
      <div className={`${classes.itemHeader} w-100`}>
        </div>
      <div className="pb-3">
        <div>
          <div style={{ marginTop: !isDragOverlay && "20px", display: "flex" }}>
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <div
                    className={`my-auto ${item.publish === 0 && "opacity-50"
                      }`}
                    onClick={() => handleOpen(item?._id)}
                  >
                    {item.image ? (
                      <>
                        {checkImageUrl(item?.image) == "" ? (
                          <img
                            src={item.image}
                            className="mx-4"
                            style={{width:"2.4rem",borderRadius:"3px"}}
                          />
                        ) : (
                          <img
                            src={`${imgBaseUrl}/${item?.image}`}
                            className="mx-4"
                            style={{width:"2.4rem",borderRadius:"3px"}}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        className="mx-4"
                        style={{width:"2.4rem",borderRadius:"3px"}}
                      ></div>
                    )}
                  </div>
                  <div
                    onClick={() => handleOpen(item?._id)}
                    className={`my-auto ${item.publish === 0 && "opacity-50"
                      }`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "4px",
                    }}
                  >
                    <div
                      className="my-auto"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className={`item_name_list d-flex align-items-center my-auto ms-2`}
                        style={{ overflowWrap: "anywhere" }}
                      >
                        <Item_names
                          varietyArr={item?.varietyArr}
                          baseName={item?.itemName}
                        />
                        <div
                          className="ms-3"
                          style={{ marginTop: "-1px" }}
                        >
                          <IconsArr item={item} />
                        </div>
                      </div>
                    </div>
                    <div className={`item_price_list my-auto ms-2`} style={{ wordBreak: "break-all" }}>
                      ₹{" "}
                      <Item_prices
                        varietyArr={item?.varietyArr}
                        price={item?.price}
                      />{" "}
                    </div>
                  </div>
                </div>
              </>
                    
          </div>
        </div>
      </div>
    </div>

  )

}