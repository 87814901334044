// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./cart.module.css";
import TopHeader from "../../utils/TopHeader";
import SelectedItems from "./SelectedItems";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const Cart = () => {
  const [duplicateItems, setDuplicateItems] = useState(
    JSON.parse(localStorage.getItem("CartData")) || [],
  );

  /*   if (duplicateItems.length === 0) {
    return <div style={{ textAlign: "center" }}>No items in the cart.</div>;
  } */

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(duplicateItems));
  }, [duplicateItems]);

  const updateCart = (indexToRemove) => {
    const updatedItems = duplicateItems.filter(
      (_, index) => index !== indexToRemove,
    );
    setDuplicateItems(updatedItems);
  };

  const updateBill = (index, itemCount) => {
    const updatedItems = [...duplicateItems];
    updatedItems[index] = {
      ...updatedItems[index],
      itemCount,
    };
    setDuplicateItems(updatedItems);
  };

  const getItemPrice = (item) => {
    if (item.price === "" && item.varietyArr && item.varietyArr.length > 0) {
      return item.varietyArr[0].price;
    }
    return item.price;
  };

  const calculateTotal = () => {
    let total = 0;
    duplicateItems.forEach((item) => {
      total += (item.itemCount || 1) * parseFloat(getItemPrice(item));
    });
    return total;
  };

  const Total = calculateTotal();
  const tax = (Total * 0.1).toFixed(2); // 5% tax
  const subtotal = (parseFloat(Total) + parseFloat(tax)).toFixed(2);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/cart`, "Cart Page");
  }, []);

  return (
    <>
      {duplicateItems.length === 0 ? (
        <>
          <TopHeader name="Summary" />
          <div
            style={{
              textAlign: "center",
            }}
          >
            <p>No item in the cart.</p>
          </div>
        </>
      ) : (
        <>
          <TopHeader name="Summary" />
          <div className={`container mt-4 ${styles.container}`}>
            <div className={`${styles.cartItems} mb-4`}>
              {duplicateItems.map((item, index) => (
                <SelectedItems
                  key={index}
                  item={item}
                  index={index}
                  updateCart={updateCart}
                  updateBill={updateBill}
                  getItemPrice={getItemPrice}
                />
              ))}
            </div>
            {/* <div className="d-flex mb-4">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Promo Code"
          />
          <button className="btn btn-primary">Apply</button>
        </div> */}
            <div className={`p-3 ${styles.billing} mb-4`}>
              <div className="d-flex justify-content-between mb-2">
                <span>Cart total</span>
                <span>₹{Total}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Service Charge</span>
                <span>₹{tax}</span>
              </div>
              {/*  <div className="d-flex justify-content-between mb-2">
                <span>Promo discount</span>
                <span>-₹0.00</span>
              </div> */}
              <div className="d-flex justify-content-between">
                <strong>Subtotal</strong>
                <strong>₹{subtotal}</strong>
              </div>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="btn btn-primary btn-block mb-4">
            Proceed to Checkout
          </button>
        </div> */}
          </div>
          {/* Disclaimer Section */}
          <div className={`p-3 ${styles.disclaimer}`}>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.85rem",
                color: "#666",
              }}
            >
              This view is only for customer simulation purposes.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
