import { baseURL } from "../../constants/Api";

export const tableReserveBaseUrl = (() => {
  return baseURL
})();

export const tableReserveApiEndpoints = {
  getRestaurants: "v1/bookings/getAvailableRestaurants",
  GET_TIME_SLOTS: "v1/bookings/getRestaurantSlots",
  CREATE_BOOKINGS: "v1/bookings/createABooking",
  FETCH_BOOKINGS: "v1/bookings/fetchMyBookings",
  CANCEL_BOOKINGS: "v1/bookings/cancelBooking"

}