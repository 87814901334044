// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import BackBtn from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import { useNavigate } from "react-router-dom";
import TopHeader from "../utils/TopHeader";
import { useEffect } from "react";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Contact = ({ hideIcon }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/contact`, "Contact Page");
  }, []);

  return (
    <div style={{ width: "360px", margin: "auto" }}>
      <TopHeader name="Contact Us" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          justifyContent: "center",
          alignItems: "center",
          margintop: "150px",
        }}
      >
        <CloseIcon
          onClick={() => {
            window.location.reload();
          }}
          className={`${hideIcon && "d-none"}`}
        ></CloseIcon>
        <div className="container">
          <h1 style={{ textAlign: "center" }} className="pb-2">
            {" "}
            Contact Us
          </h1>
          <div className=" text-center">
            <div>
              You can call us at{" "}
              <a
                href="tel:9634339954"
                className=" text-decoration-none fw-bold text-black"
              >
                9634339954
              </a>{" "}
              <br /> or <br /> mail us at{" "}
              <a
                href="mailto: contact@eatoes.com"
                className=" text-decoration-none fw-bold text-black"
              >
                contact@eatoes.com
              </a>{" "}
              or with your business name, location, contact name, and mobile
              number.
            </div>

            <div style={{ paddingTop: "1em" }}>
              {" "}
              Our team will connect with you at earliest.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
