// @ts-nocheck
import { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import Modal from "@mui/material/Modal";
import "../App.css";
import Box from "@mui/material/Box";
import OtpInput from "../Components/OTPInput";
import { login, mobileVerification } from "../API/authAPIs";
import Logo from "../assets/new_assets/4.png";
import Image1 from "../assets/images/loginPageImage1.png";
import Image2 from "../assets/images/loginPageImage2.png";
import Image3 from "../assets/images/loginPageImage3.png";
import Image4 from "../assets/images/loginPageImage4.png";
import Dashboard from "./Dashboard";
import {
  useNavigate,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Button } from "@mui/material";
import { Terms } from "../pages/T&C";
import { USER } from "../constants/localStorageKeys";
import ModalAlert from "../utils/ModalAlert";
import Collage from "../LoginComponents/Collage";
import { getbusinessProfiles } from "../API/authMenuAPIs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ResentOtpMessage } from "../utils/resent_otp_message";
import { MENU_API, baseURL } from "../constants/Api";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  overflow: "scroll",
  bgcolor: "#EEF0EF",
  p: 1,
};

export function Login({ fetchProfile, setFetchProfile }) {
  const location = useLocation();
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showotp, setShowotp] = useState(false);
  const [open, setOpen] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPFromApi, setOTPFromApi] = useState("");
  const isSignUp = false;
  const isLogin = true;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(900));
  const [errMessage, setErrMessage] = useState(false);
  const [err2, setErr2] = useState(false);
  const [showResentMessage, setShowResentMessage] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const toRedirect = queryParams.get("to_redirect");
  const path = queryParams.get("path");

  const handleOpen = () => {
    isMatch ? navigate("/Terms") : setOpen(true);
  };
  const handleClose = () => setOpen(false);
  let navigate = useNavigate();

  // Initialize analytics
  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/Login`, "Login Page");
    setAnalytics(analyticsInstance);
  }, []);

  const onGetOTP = async () => {
    if (analytics) {
      analytics.sendEvent("User Interaction", "Get OTP Clicked", mobileNumber);
    }
    setOTP("");
    setError("");
    const loginRes = await login(mobileNumber, isSignUp, isLogin);

    if (!mobileNumber.match("[0-9]{10}")) {
      setErrMessage(true);
      setErr2(false);
      setError("Please enter 10 digit mobile number");
    } else if (!loginRes) {
      setErrMessage(false);
      setErr2(false);
      setError("Your Number is Not Registered. Please SignUp");
    } else {
      setShowotp(true);
      console.log("response from login api", loginRes);
      const theOTPRecieved = loginRes?.body?.otp;
      if (theOTPRecieved) {
        setOTPFromApi(theOTPRecieved);
      } else {
        console.error("Something went wrong!! NO OTP RECIEVED");
      }
    }
  };

  const onSubmitOTP = async () => {
    if (analytics) {
      analytics.sendEvent("User Interaction", "Submit OTP Clicked", OTP);
    }
    if (OTP !== OTPFromApi) {
      setErr2(true);
      setError("Entered OTP is incorrect");

      const response = await mobileVerification(mobileNumber, OTP);
      if (response?.body?.validateOtpResponse) {
        const userData = response?.body?.userData;
        const token = response?.body?.token?.access?.token;
        token && localStorage.setItem(USER.JWT_ACCESS_TOKEN, token);

        setFetchProfile(true);
        mobileNumber && localStorage.setItem(USER.MOBILE_NUMBER, mobileNumber);
        userData?.userName
          ? localStorage.setItem("adminUserName", userData.userName)
          : localStorage.setItem("adminUserName", "");
        userData?.name
          ? localStorage.setItem("adminName", userData.name)
          : localStorage.setItem("adminName", "");
        localStorage.setItem("adminId", userData.uuid);

        console.log(mobileNumber, "mobile");
        setError("");
        const data = await getbusinessProfiles(
          `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
        );
        if (toRedirect) navigate(path);
        else if (data) navigate(`/${data[0].userName}`);
        else navigate("/");
      } else {
        setError("Looks like something went wrong. Please, try again.");
        console.log("response is not valid");
      }
    }
  };

  const onChange = (value: string) => {
    console.log("onchange", value);
    setOTP(value);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <Terms setOpen={setOpen} />
        </Box>
      </Modal>
      <>
        {isMatch && showotp && (
          <div
            style={{ margin: "20px 0 0 20px" }}
            onClick={() => {
              setShowotp(false);
            }}
          >
            <ArrowBackIcon />
          </div>
        )}
      </>
      <div className="container1">
        <Collage />

        <div className="container">
          {isMatch ? (
            <>
              {showotp == false ? (
                <>
                  <div className="text1">Log In</div>
                  <div className="text2">
                    Enter your registered mobile number
                  </div>

                  <div
                    style={{ marginTop: 35, marginBottom: 10 }}
                    className="text_1"
                  >
                    Enter mobile no.
                  </div>

                  <input
                    className="inputfield"
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    maxLength={10}
                    style={{
                      letterSpacing: "2px",
                      fontSize: "16px",
                      paddingLeft: "10px",
                    }}
                  />
                  {error && (
                    <div
                      className="tx-scarlet"
                      style={{ marginTop: "6px", fontSize: "10px" }}
                    >
                      {error}
                    </div>
                  )}
                  <div className="align">
                    <button className="button" onClick={onGetOTP}>
                      Get OTP
                    </button>
                    <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                      <div className="row-flex ">
                        <div className="text3">
                          {" "}
                          Already have an account?{" "}
                          <a
                            style={{ color: "#03A884", textDecoration: "none" }}
                            href={` ${toRedirect ? `/SignUp${location.search}` : "/SignUp"}`}
                          >
                            Sign Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="footer">
                    <p className="footer_text"> Moving forward accepts our</p>
                    <button
                      style={{
                        color: "#3F4DB8",
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "600",
                        border: "none",
                        background: "none",
                      }}
                      onClick={handleOpen}
                    >
                      Terms Of Use
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="text1">OTP Verification</div>
                  <div className="text2">
                    We’ve sent OTP to{" "}
                    <span style={{ color: "#3F4DB8" }}>{mobileNumber}</span>
                  </div>
                  <div className="otpinput">
                    <OtpInput value={OTP} valueLength={6} onChange={onChange} />
                    {error && (
                      <div
                        className="tx-scarlet"
                        style={{ marginTop: "6px", fontSize: "10px" }}
                      >
                        {error}
                      </div>
                    )}
                  </div>

                  <div className="align">
                    <button className="button" onClick={onSubmitOTP}>
                      Log In
                    </button>
                    <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                      <div className="row-flex ">
                        <div className="text3">
                          {" "}
                          Didn’t received OTP?{" "}
                          <a
                            style={{ color: "#03A884", textDecoration: "none" }}
                            onClick={onGetOTP}
                          >
                            Resend OTP
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="image">
                <img
                  src={Logo}
                  className="image4"
                  style={{ width: "300px", height: "80px" }}
                />
              </div>
              <div className="box" style={{ background: "#EEF0EF" }}>
                {showotp == false ? (
                  <>
                    <div className="text1">Log In</div>
                    <div className="text2">
                      Enter your registered mobile number
                    </div>

                    <div
                      style={{ marginTop: 35, marginBottom: 10 }}
                      className="text_1"
                    >
                      Enter mobile no.
                    </div>

                    <input
                      className="inputfield"
                      type="tel"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      maxLength={10}
                      style={{
                        letterSpacing: "2px",
                        fontSize: "16px",
                        paddingLeft: "10px",
                      }}
                    />
                    {error && (
                      <div
                        className="tx-scarlet"
                        style={{ marginTop: "6px", fontSize: "10px" }}
                      >
                        {error}
                      </div>
                    )}
                    <div className="align">
                      <button className="button" onClick={onGetOTP}>
                        Get OTP
                      </button>
                      <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                        <div className="row-flex ">
                          <div className="text3">
                            {" "}
                            Already have an account?{" "}
                            <a
                              style={{
                                color: "#03A884",
                                textDecoration: "none",
                              }}
                              href={` ${toRedirect ? `/SignUp${location.search}` : "/SignUp"}`}
                              onClick={() => {
                                if (analytics) {
                                  analytics.sendEvent(
                                    "Navigation",
                                    "Sign Up Clicked",
                                    "Navigating to Sign Up page",
                                  );
                                }
                              }}
                            >
                              Sign Up
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="footer"
                      style={{
                        background: "#EEF0EF",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p className="footer_text"> Moving forward accepts our</p>
                      <button
                        style={{
                          color: "#3F4DB8",
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "600",
                          border: "none",
                          background: "none",
                          textAlign: "left",
                        }}
                        onClick={handleOpen}
                      >
                        Terms Of Use
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text1">OTP Verification</div>
                    <div className="text2">
                      We’ve sent OTP to{" "}
                      <span style={{ color: "#3F4DB8" }}>{mobileNumber}</span>
                    </div>
                    <div className="otpinput">
                      <OtpInput
                        value={OTP}
                        valueLength={6}
                        onChange={onChange}
                      />
                      {error && (
                        <div
                          className="tx-scarlet"
                          style={{ marginTop: "6px", fontSize: "10px" }}
                        >
                          {error}
                        </div>
                      )}
                    </div>

                    <div className="align">
                      <button className="button" onClick={onSubmitOTP}>
                        Log In
                      </button>
                      <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                        <div className="row-flex ">
                          <div className="text3">
                            {" "}
                            Didn’t received OTP?{" "}
                            <a
                              style={{
                                color: "#03A884",
                                textDecoration: "none",
                              }}
                              onClick={onGetOTP}
                            >
                              Resend OTP
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
