// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCategory, addSubCategory } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { Overlay_Animation } from "../../utils/Overlay-animation";
import { Cat_Subcat_Layout } from "../CategoryPage/Cat-Subcat-Layout";
import { setApiData } from "../../redux/actions";
import classes from "../../styleSheets/FoodMenu.module.css";
import { BusinessProfileDropdownList } from "./BusinessDropdownList";
import Right from "../../assets/images/images/RightIcon.svg";
import { SwitchProfilePopup } from "../../pages/SwitchProfilePopup";
import { Toaster } from "react-hot-toast";

export const BusinessDropdown = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  profileType,
  setProfileType,
  profile,
  setProfile,
  analytics,
  setAnalytics,
  hideName = false,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleOverlayShow = () => setShowOverlay(true);
  const [openNew, setOpenNew] = useState(false);
  const [profileUserName, setProfileUserName] = useState("");

  const selectedImage = "";

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes(`add_cat_subcat_div`) ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay(false);
      setShowDiv(false);
      setCategoryName("");
      setError(false);
    }
  };

  return (
    <div
      id="edit"
      style={{ borderRadius: "50px 50px 0px 0px", overflow: "hidden" }}
    >
      <div
        id="handleOverlayDiv"
        style={{ width: "20px", height: "20px" }}
        onClick={handleOverlayShow}
      >
        <img src={Right} className="my-auto mb-1" />
      </div>
      <Overlay_Animation
        className="add_cat_subcat_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <BusinessProfileDropdownList
            hideName={true}
            businessName={businessName}
            setBusinessName={setBusinessName}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            businessProfileName={businessProfileName}
            setBusinessProfileName={setBusinessProfileName}
            businessUserName={businessUserName}
            setBusinessUserName={setBusinessUserName}
            id={id}
            profileType={profileType}
            setProfileType={setProfileType}
            profile={profile}
            setProfile={setProfile}
            setShowOverlay={setShowOverlay}
            setShowDiv={setShowDiv}
            setOpenNew={setOpenNew}
            setProfileUserName={setProfileUserName}
            analytics={analytics}
            setAnalytics={setAnalytics}
          />
        }
      />

      <SwitchProfilePopup
        open={openNew}
        setOpen={setOpenNew}
        profileUserName={profileUserName}
      />
      <Toaster />
    </div>
  );
};
