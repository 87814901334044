import React, { useEffect, useRef, useState } from "react";
import ProfileItem from "./ProfileItem";
import styles from "./ProfileList.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { ADMIN_API, baseURL } from "../constants/Api";
import SearchBar from "../Components/SearchBar";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const ProfileList = () => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const optionsMenuRef = useRef(null);

  useEffect(() => {
    const fetching = async () => {
      try {
        const response = await fetch(
          `${baseURL}${ADMIN_API.FETCH_ALL_PROFILES}`,
        );
        const data = await response.json();
        if (data.message === "All profiles fetched successfully") {
          setProfiles(data.fetchedProfiles);
          setFilteredProfiles(data.fetchedProfiles);
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
      } finally {
        setLoading(false);
      }
    };
    fetching();
  }, []);

  const deleteProfile = async (profileId) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}${ADMIN_API.DELETE_PROFILE}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ profileId }),
      });
      const data = await response.json();
      if (data.success) {
        setProfiles((prevProfiles) =>
          prevProfiles.filter((profile) => profile.profileId !== profileId),
        );
        setFilteredProfiles((prevProfiles) =>
          prevProfiles.filter((profile) => profile.profileId !== profileId),
        );
      } else {
        console.error("Error deleting profile:");
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateServiceStatus = (profileId, serviceName, newStatus) => {
    setProfiles((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.profileId === profileId
          ? {
              ...profile,
              services: {
                ...profile.services,
                [serviceName]: {
                  ...profile.services[serviceName],
                  enabled: newStatus,
                },
              },
            }
          : profile,
      ),
    );

    setFilteredProfiles((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.profileId === profileId
          ? {
              ...profile,
              services: {
                ...profile.services,
                [serviceName]: {
                  ...profile.services[serviceName],
                  enabled: newStatus,
                },
              },
            }
          : profile,
      ),
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target)
      ) {
        document.querySelectorAll(".dropdown-menu.show").forEach((menu) => {
          menu.classList.remove("show");
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredProfiles(
      profiles.filter(
        (profile) =>
          profile.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          profile.userName?.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
  }, [searchQuery, profiles]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/eatoesAllaccounts`, "EatoesAllAccounts Page");
  }, []);

  return (
    <div className={styles.parentDiv}>
      <div className={`container ${styles.noPadding}`}>
        <div className={styles.header}>
          <FaArrowLeft className={styles.backArrow} />
          <span>All Accounts</span>
        </div>
        <SearchBar
          searchQuery={searchQuery}
          placeholder="Search Profile..."
          setSearchQuery={setSearchQuery}
        />
        {loading ? (
          <div className={styles.spinnerContainer}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className={styles.profileList} ref={optionsMenuRef}>
            {filteredProfiles.map((profile, index) => (
              <ProfileItem
                key={profile?.profileId || index}
                profile={profile}
                deleteProfile={deleteProfile}
                updateServiceStatus={updateServiceStatus}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileList;
