// @ts-nocheck
import _ from "lodash"

export const updateNestedRedux = (reduxCurrentData, nestedPath, newData, dispatch, reduxAction) => {
  //made a deepclone of current redux using the lodash
  const tempData = _.cloneDeep(reduxCurrentData)

  // setting the data through path using lodash's set method
  _.set(tempData, nestedPath, newData)

  console.log(tempData)

  //updated the data in redux
  dispatch(reduxAction(tempData))
}