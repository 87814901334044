// @ts-nocheck
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DraggableItem } from "./draggableItem";
import { USER } from "../../constants/localStorageKeys";
import { useSelector } from "react-redux";

export const UseSortableFile = (props) => {
  const token = localStorage.getItem("accessToken");
  const resName = localStorage.getItem(USER.USERNAME);
  const name = localStorage.getItem("name");
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({ id: props.propId, disabled: !menuAccessState });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };
  return (
    <DraggableItem
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};
