// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../CategoryPage/Header";
import SubCategoryHeader from "./SubCategoryHeader";
import SubCategoryHeaderSection from "./SubCategoryHeaderSection";
import classes from "../../styleSheets/FoodMenu.module.css";
import SearchBar from "./SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import { USER } from "../../constants/localStorageKeys";
import { baseURL, MENU_API } from "../../constants/Api";
import { menuAPI } from "../../API/MenuApi";
import { getUserProfile } from "../../API/authMenuAPIs";
import { useLocation, useNavigate } from "react-router-dom";
import { menuAccessAPI } from "../../API/menu_role_access";
import { useDispatch } from "react-redux";
import { setMenuAccess } from "../../redux/actions";
import axios from "axios";
const SubCategory = () => {
  const [reservoirData, setReservoirData] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  const [active, setActive] = useState(false);
  const sidebar = localStorage.getItem(USER.sidebar);

  const [data, setData] = useState();
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const callApis = async (savedToken) => {
    // const checkHere = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    // console.log("check hereeeeeeeeeeeeeeeeeeeeee", checkHere)
    const data1 = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`)
    setData(data1);
    setReservoirData(data1);

    console.log("heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy", data1);

    if (savedToken) {
      const returnedData = await getUserProfile(
        `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
        navigate,
      );
      setUserData(returnedData);

      const menuAccessData = await menuAccessAPI(data1, navigate);
      dispatch(setMenuAccess(menuAccessData));
      if (menuAccessData) {
        localStorage.setItem(USER.USERNAME, data1?.restrauntName);
        localStorage.setItem(USER.businessId, data1?.user);
        localStorage.setItem(USER.businessName, data1?.title);
      }
    } else {
      dispatch(setMenuAccess(false));
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
    callApis(savedToken);
  }, []);
  console.log("sidebar", sidebar == "true");
  console.log("menu data", data);
  return (
    <>
      <div className={`${classes.container1}`}>
        <div
          className={`${classes.container2}`}
          style={{ background: "white" }}
        >
          <div style={{ background: "#D9D9D9" }}>
            <SubCategoryHeader
              userData={userData}
              setUserData={setUserData}
              data={data}
            />

            {!active && (
              <SubCategoryHeaderSection
                setWholeData={setData}
                data={data}
                openSearch={active}
                setOpenSearch={setActive}
                reservoirData={reservoirData}
              />
            )}
            {active && <SearchBar active={active} setActive={setActive} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategory;
