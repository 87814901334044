//@ts-nocheck
import React, { useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import "../styleSheets/social_profile_page.css";
import { useSelector } from "react-redux";
import "../styleSheets/Arrow.css";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Social_profile_page = ({ data }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");
  const api = useSelector((state) => state.fastSocial.apiData);
  console.log(api);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      `/FastSocial/socialprofilepage`,
      "SocialProfile Page",
    );
  }, []);

  return (
    <div className="Socialformpage d-flex justify-content-center">
      <div className="mobile-pc-width">
        <div className="d-flex p-0 m-auto mb-5 social-profile-header">
          <div
            className="w-100 text-white d-flex justify-content-between align-items-center"
            style={{ background: "#27343c" }}
          >
            <KeyboardBackspaceIcon
              className="back-arrow-click ms-2"
              onClick={() => {
                navigation(-1);
              }}
            />
            <div className="mx-auto">
              <h1 className="socio-prof-header me-3 text-white">
                {api?.["Basic Details"]?.account?.userName} | Social profile
              </h1>
            </div>
          </div>
        </div>
        <div className="mx-4 ">
          {data.map((data, index) => (
            <div
              key={`data-${index}`}
              className="box container m-0 p-2 mb-3 boxes-social-profile"
            >
              <div
                className="d-flex justify-content-between mb-4 cursor-pointer"
                onClick={() =>
                  navigation({
                    pathname: `/FastSocial/${data.route}page`,
                    search: createSearchParams({ accountId: state }).toString(),
                  })
                }
              >
                <div>
                  <div className="option p-1 txt-box-social">{data.option}</div>
                </div>
                <div>
                  <ArrowForwardIcon className="right-arrow" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Social_profile_page;
