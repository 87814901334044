
import React, { useEffect } from "react";
import { Tabs } from "./tabs";
import "../../styleSheets/tableReserve/progressTabs.css";
import { useLocation } from "react-router-dom";
import { formatDateToCustomString, formatTimeSlot } from "../../utils/functionUtils";
import TickIcon from "../../assets/new_assets/tick-icon.svg"

export const ProgressTabs = () => {
  // const [peopleActive, setActive] = use
  let { pathname, state } = useLocation();
  console.log(useLocation());
  useEffect(() => {
    if (pathname.endsWith("/")) pathname = pathname.slice(0, -1);
    console.log(pathname === "/reserve");
  }, [pathname]);

  console.log(pathname);
  return (
    <div className="progress-wrapper">
      <Tabs
        active={pathname === "/reserve" || state?.selectedPeople}
        type="People"
        name={state?.selectedPeople ?  <img src={TickIcon}/> : "1"}
        value={`${state?.selectedPeople ? state?.selectedPeople + " People" : "People"}`}
      />
      <Tabs
        active={pathname === "/reserve/select-date" || state?.selectedDate}
        type="Date"
        name={state?.selectedDate ?  <img src={TickIcon}/> : "2"}
        value={`${state?.selectedDate ? formatDateToCustomString(state?.selectedDate) : "Date"} `}
      />
      <Tabs
        active={pathname === "/reserve/select-time" || state?.selectedTime}
        type="People"
        name={state?.selectedTime ?  <img src={TickIcon}/> : "3"}
        value={`${state?.selectedTime ? formatTimeSlot(state?.selectedTime) : "Time"} `}
      />
    </div>
  );
};
