import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";

type wholeDataArrType = {
  src: string;
  downloadFilename: string;
};

type componentPropsTypes = {
  wholeData: Array<wholeDataArrType>;
  index: number;
  open: boolean;
  setOpen: Function;
};

export function MultipleImgLightbox({
  wholeData,
  index,
  open,
  setOpen,
}: componentPropsTypes) {
  return (
    <>
      <Lightbox
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
        open={open}
        plugins={[Zoom, Download]}
        index={index}
        carousel={{
          finite: true,
        }}
        zoom={{
          maxZoomPixelRatio: 2,
        }}
        close={() => setOpen(false)}
        slides={wholeData}
      />
    </>
  );
}
