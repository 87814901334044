// @ts-nocheck

import { useEffect } from "react"

export const ResentOtpMessage = ({ toShow, setToShow, marginClass }) => {
  useEffect(() => {
    if (!toShow) return
    const timeoutId = setTimeout(() => {
      setToShow(false)
    }, 5000)

    return () => clearTimeout(timeoutId)
  }, [toShow])
  return (
    <div className={`${!toShow && "opacity-0"} text-center text-success ${marginClass} p-2 d-flex rounded-1 mt-2 shadow-sm`}
      style={{
        width: "fit-content",
        border: "1px solid #6a75ca",
        transition: "all ease 0.5s",


      }}
    >
      <div className="ms-1">OTP has been resent</div>
      <img src="/assets/cross-icon.svg" className="ms-5 cursor-pointer" onClick={() => setToShow(false)} />

    </div>
  )
}