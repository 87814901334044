import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./AddQRUser.module.css";
import { baseURL, QR_API } from "../constants/Api";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    redirectUrl: "",
    menuUrl: "",
    logo: "",
    delivery: "",
    officialWebsite: "",
    instagram: "",
    googleReview: "",
    isOptedWhatsapp: false,
    isSpinWheelOpted: false,
    isMenuBypassOpted: false,
    active: false,
    apiKey: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`Changing ${name} to ${type === "checkbox" ? checked : value}`);
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}${QR_API.SUBMIT}`, formData);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/AddQRUser`, "AddQRUser Page");
  }, []);

  return (
    <div className={styles.parentContainer}>
      <TopHeader name="Add QR Users" />
      <div className={styles.formContainer}>
        <div className="container">
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="code">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="redirectUrl">Redirect URL</label>
              <input
                type="url"
                className="form-control"
                id="redirectUrl"
                name="redirectUrl"
                value={formData.redirectUrl}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="menuUrl">Menu URL</label>
              <input
                type="url"
                className="form-control"
                id="menuUrl"
                name="menuUrl"
                value={formData.menuUrl}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="logo">Logo</label>
              <input
                type="text"
                className="form-control"
                id="logo"
                name="logo"
                value={formData.logo}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="delivery">Delivery</label>
              <input
                type="text"
                className="form-control"
                id="delivery"
                name="delivery"
                value={formData.delivery}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="officialWebsite">Official Website</label>
              <input
                type="url"
                className="form-control"
                id="officialWebsite"
                name="officialWebsite"
                value={formData.officialWebsite}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="instagram">Instagram</label>
              <input
                type="url"
                className="form-control"
                id="instagram"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
              />
            </div>
            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="googleReview">Google Review</label>
              <input
                type="url"
                className="form-control"
                id="googleReview"
                name="googleReview"
                value={formData.googleReview}
                onChange={handleChange}
              />
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isOptedWhatsapp">Opted for WhatsApp</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isOptedWhatsapp"
                  name="isOptedWhatsapp"
                  checked={formData.isOptedWhatsapp}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isSpinWheelOpted">Opted for Spin Wheel</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isSpinWheelOpted"
                  name="isSpinWheelOpted"
                  checked={formData.isSpinWheelOpted}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="isMenuBypassOpted">Opted for Menu Bypass</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="isMenuBypassOpted"
                  name="isMenuBypassOpted"
                  checked={formData.isMenuBypassOpted}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div
              className={`form-group ${styles.formGroup} ${styles.checkboxGroup}`}
            >
              <label htmlFor="Activate">Activate</label>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  checked={formData.active}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            <div className={`form-group ${styles.formGroup}`}>
              <label htmlFor="apiKey">API Key</label>
              <input
                type="text"
                className="form-control"
                id="apiKey"
                name="apiKey"
                value={formData.apiKey}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
