// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Right from "../assets/images/images/RightIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { getbusinessProfiles } from "../API/authMenuAPIs";
import { MENU_API, baseURL } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import DoneIcon from "@mui/icons-material/Done";
import { resetAllReduxStates, setApiData } from "../redux/actions";
import CircularUnderLoad from "./CircularLoader";
import dgMenu from "../styleSheets/menuPlans.module.css";
// import Select from "react-dropdown-select";

const BusinessProfileDropdown = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  profileType,
  setProfileType,
  profile,
  setProfile,
  hideName = false,
}) => {
  const { pathname, search } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  // const [profile, setProfile] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const navigateOnClick = (profileUniqueIdentifier) => {
    if (pathname.includes("/menu")) {
      localStorage.setItem("menu-back-route", pathname + search);
      navigate(`/edit/menu/${profileUniqueIdentifier}`);
    }
    if ("userName" in paramObj) {
      navigate(`/${profileUniqueIdentifier}`);
    }
  };

  console.log("profile:", profile);

  useEffect(() => {
    callApis();
  }, []);

  const callApis = async () => {
    // const data = await getbusinessProfiles(
    //   `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,navigate
    // );
    setLoader(true);
    const selectedProfileId = localStorage.getItem(USER.businessId);
    const data = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
      navigate
    );

    setProfile?.(data);

    if (selectedProfileId == null || selectedProfileId === "undefined") {
      setBusinessProfileName(data[0]?.name);
      setBusinessUserName(data[0]?.userName);
      setSelectedProfile(data[0]?.profileId);
      setProfileType(data[0]?.profile_type);
      localStorage.setItem(USER.USERNAME, data[0]?.userName);
      localStorage.setItem(USER.businessName, data[0]?.name);
      localStorage.setItem(USER.businessId, data[0]?.profileId);
      localStorage.setItem(USER.PROFILE_TYPE, data[0]?.profile_type);
    } else {
      setBusinessProfileName(localStorage.getItem(USER.businessName));
      setBusinessUserName(localStorage.getItem(USER.USERNAME));
      setSelectedProfile(localStorage.getItem(USER.businessId));
      setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
    }
    setLoader(false);
  };
  console.log(businessProfileName);

  //   var arr=[]
  //  arr.push(profile[0])
  // useEffect(() => {
  //   // callApis();

  //   // setBusinessProfileName(localStorage.getItem(USER.businessName))
  //   // setSelectedProfile(localStorage.getItem(USER.businessId))
  //   // setBusinessUserName(localStorage.getItem(USER.USERNAME))
  //   console.log(setProfileType);
  // }, [profile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  console.log(profile, "prof");

  // if((!businessProfileName||businessProfileName=='undefined'||businessProfileName==null)||(!businessUserName||businessUserName==(null||'undefined'))||(!selectedProfile||selectedProfile==(null||'undefined')) )
  // {

  // localStorage.setItem(USER.USERNAME,arr[0]?.userName)
  // localStorage.setItem(USER.businessName,arr[0]?.name)
  // localStorage.setItem(USER.businessId,arr[0]?.profileId)
  // setBusinessProfileName(USER.USERNAME,arr[0]?.userName)
  // setSelectedProfile(USER.businessName,arr[0]?.name)
  // setBusinessUserName(USER.businessId,arr[0]?.profileId)

  // }

  const get = localStorage.getItem(USER.USERNAME);

  return (
    <div>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderRadius: "5px, 5px, 5px, 5px",
          boxShadow: "inset 0 0 500px rgba(0, 0, 0, 0.5)",
          // boxShadow:' 0px -4px 15px rgba(0, 0, 0, 0.04)'
        }}
      >
        <div>
          {profile?.length === 0 ? (
            <div className={dgMenu.centerDiv}>
              <CircularUnderLoad />
            </div>
          ) : (
            profile?.map((item, index) => {
              return (
                <div
                  key={crypto.randomUUID()}
                  style={{
                    borderBottom: "1px solid #D4D4D440",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    setBusinessProfileName(item?.name);
                    setSelectedProfile(item?.profileId);
                    setBusinessUserName(item?.userName);
                    setProfileType(item?.profile_type);
                    localStorage.setItem(USER.USERNAME, item?.userName);
                    localStorage.setItem(USER.businessId, item?.profileId);
                    localStorage.setItem(USER.businessName, item?.name);
                    localStorage.setItem(USER.PROFILE_TYPE, item?.profile_type);
                    setAnchorEl(null);
                    // dispatch(setApiData({}))
                    // dispatch(resetAllReduxStates())
                    navigateOnClick(item?.userName);
                    console.log(item?.name, "userName");
                  }}
                  onKeyUp={() => false}
                >
                  <div
                    style={{
                      margin: "5px 5px 5px 5px",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    {item?.profileId === selectedProfile ? (
                      <>
                        {item.name}
                        <DoneIcon
                          sx={{ paddingTop: "5px" }}
                          fontSize="small"
                        />
                      </>
                    ) : (
                      <>{item.name}</>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <div
            style={{
              margin: "5px 15px 5px 15px",
              color: "#0080ff",
              textAlign: "center",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/allprofiles")}
            onKeyUp={() => false}

          >
            Create new accounts
          </div>
        </div>
      </Menu>
      {
        true && (
          // <>{(profile?.length <= 0) ? (
          //   <></>
          // ) : (
          <>
            {loader && <div> </div>}
            {!loader && (businessProfileName === "undefined" ||
              businessProfileName == null ||
              !profile) ? (
              null
            ) : (
              <div
                style={{
                  color: "#27343C",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px",
                  fontSize: "12px",
                  cursor: "pointer",
                  // backgroundColor: "#FFFFFF",
                  marginRight: "0px",
                  padding: "0px",
                  borderRadius: "5px",

                  // width: "132px",
                  justifyContent: "center",
                  textWrap: "noWrap",
                }}
                onClick={handleClick}
                onKeyUp={() => false}

              >
                {!hideName && (
                  <div
                    style={{
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    {businessProfileName}
                  </div>
                )}
                <img src={Right} className="my-auto" alt="right" />
              </div>
            )}
          </>


        )

        // )
      }
      {/* </>}  */}
    </div>
  );
};

export default BusinessProfileDropdown;
