import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { searchRestaurant } from "../../API/reservationAPIs";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const CreateRestaurant = () => {
  
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      "/reservation/createRestaurant",
      "ReservationCreateRestaurant Page",
    );
  }, []);
  return (
    <div className="Listpage">
      <div className="searchBar-con">
        <input type="text" placeholder="Search for your restaurant" />
        <div className="searchbarIcon-con">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="lineFW" />
      <h1 className="text-start">Result Found</h1>
    </div>
  );
};

export default CreateRestaurant;
