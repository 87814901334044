// @ts-nocheck

let timeoutId

export const emailValidator = (e, setIsValid) => {
  const inputValue = e.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if(!inputValue){
      setIsValid(true)
      }
      else if (!emailRegex.test(inputValue)) {
        setIsValid(false);
      }
       else {
        setIsValid(true);
      }
    }, 1500);
}