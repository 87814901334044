// @ts-nocheck
// const baseURL = "https://api-connect.eatoes.com";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { displayRazorPay } from "../payment/Razorpay";
import { CURRENT_BALANCE_API, WEEKLY_EXPENSES_API } from "../constants/Api";
import moment from "moment";
import classes from "../styleSheets/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../constants/Api";
import swal from "sweetalert2";
import { useMediaQuery, useTheme } from "@mui/material";
// import { wallet } from '../API/TransactionAPI';
import RechargeAmount from "../payment/RechargeAmount";
import { Alert } from "@mui/material";
import WalletProgressBar from "./WalletProgressBar";
import { getExpenses, getbalance } from "../API/WalletAPIs";
import { USER } from "../constants/localStorageKeys";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Transactionlist from "./Transactionlist";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { setApiData } from "../redux/actions";
const WalletCard = ({ selectedProfile, businessUserName, setId }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(901));
  const isDesktop = useMediaQuery(theme.breakpoints.up(900));
  const [showRechargeAmount, setShowRechargeAmount] = useState(false);
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [expandList, setexpandList] = useState(false);
  const dispatch = useDispatch();
  const desktop = isDesktop;
  const callApis = async () => {
    const selectedbusinessId = localStorage.getItem(USER.businessId);
    console.log(selectedbusinessId, "sell");
    const bal = await getbalance(
      `${baseURL}${CURRENT_BALANCE_API.CURRENT_BALANCE}/?id=${selectedProfile}`,
      navigate,
    );
    setData(bal);
    const expenses = await getExpenses(
      `${baseURL}${WEEKLY_EXPENSES_API.WEEKLY_EXPENSES}/?id=${selectedProfile}`,
      navigate,
    );
    setData1(expenses);
    console.log("balance", expenses);
  };

  useEffect(() => {
    if (selectedProfile) {
      callApis();
    }
  }, [selectedProfile]);

  const HandleRechargeClick = () => {
    setShowRechargeAmount(false);
    setTimeout(() => setShowRechargeAmount(true), 0);
  };

  return (
    <div>
      <div>
        <Card
          sx={{ boxShadow: "none", bgcolor: "#27343C" }}
          className={classes.card}
        >
          <CardContent>
            {/* <WalletProgressBar selectedProfile={selectedProfile} /> */}
            <div className={classes.cardrow}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <div className={classes.text1}>Available Balance</div>
                <div className={classes.text2}>₹ {data?.balance}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                }}
              >
                <div className={classes.text1}>Due Amount</div>

                <div className={classes.text2}>₹ {data1?.amountTobePaid}</div>
              </div>
            </div>
            <div></div>
            {isDesktop ? (
              <>
                <div>
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className={classes.border}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "3vw",
                        }}
                      >
                        <div className={classes.text3}>
                          Available Wallet Balance
                        </div>
                        <div className={classes.text4}>₹ {data?.balance}</div>
                      </div>
                      <div
                        style={{
                          borderLeft: "0.7px solid #D1D1D1",
                          paddingRight: "3vw",
                          textAlign: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "3vw",
                        }}
                      >
                        <div className={classes.text3}>Weekly Expenses</div>
                        <div className={classes.text4}>
                          ₹ {data1?.weekly_expenses}
                        </div>
                      </div>{" "}
                      <div
                        style={{
                          borderLeft: "0.7px solid #D1D1D1",
                          paddingRight: "3vw",
                          textAlign: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "3vw",
                        }}
                      >
                        <div className={classes.text3}>Monthly Expenses</div>
                        <div className={classes.text4}>
                          ₹ {data1?.monthly_expenses}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    data?.creditedOn == 0 ? classes.margin : classes.hide1
                  }
                ></div>
                <div
                  className={
                    data?.creditedOn != 0 ? classes.text5 : classes.hide1
                  }
                >
                  Last Recharged : {data?.creditedOn}
                </div>
              </>
            ) : (
              <div>
                {/* {expandList == false && (
                  <div
                    className={classes.txt_10_grey}
                    onClick={() => {
                      setexpandList(true);
                    }}
                  >
                    Expand
                    <KeyboardArrowDownIcon fontSize="small" />
                  </div>
                )} */}
                {/* {expandList && (
                  <div>
                    <div className={classes.center}>
                      <button
                        onClick={RechargeAmount}
                        className={classes.button2}
                      >
                        Recharge Now
                      </button>
                    </div>
                    <Transactionlist selectedProfile={selectedProfile} />
                    <div
                      className={classes.txt_10_grey}
                      onClick={() => {
                        setexpandList(false);
                      }}
                    >
                      Close
                      <KeyboardArrowUpIcon fontSize="small" />
                    </div>
                  </div>
                )} */}
              </div>
            )}
            <div className={classes.center}>
              <button
                onClick={HandleRechargeClick}
                className={classes.button1}
                style={{ background: "rgb(126, 202, 176)" }}
              >
                Recharge Now
              </button>
            </div>
          </CardContent>
        </Card>

        {/* {isMatch&& <Transactionlist selectedProfile={selectedProfile} />} */}
        {/* {isMatch && (
          <div className={classes.center} style={{ margin: "30px" }}>
            <button
              onClick={() => {
                dispatch(setApiData({}))
                navigate(`/edit/menu/${localStorage.getItem("RestrauntName")}`), setId("2");
              }}
              className={classes.button2}
            >
              Digital Menu
            </button>
          </div>
        )} */}
        {showRechargeAmount && (
          <RechargeAmount onClose={() => setShowRechargeAmount(false)} />
        )}
      </div>
    </div>
  );
};

export default WalletCard;
