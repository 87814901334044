// @ts-nocheck

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsUtil from "../utils/GoogleAnalytics";
export const Terms = ({ setOpen, hideIcon }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(900));
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/terms-and-conditions`, "T&C Page");
  }, []);

  return (
    <>
      <div style={{ padding: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <ArrowBackIcon
            onClick={() => {
              setOpen ? setOpen(false) : navigate(-1);
            }}
            className={`me-auto ${hideIcon && "d-none"}`}
          />
          <h1
            className={`${hideIcon ? "mx-auto" : "me-auto"} text-nowrap`}
            style={{ paddingLeft: "10px" }}
          >
            {" "}
            Terms of Use
          </h1>
        </div>

        <p>
          This STANDARD MERCHANT AGREEMENT (this “Merchant Agreement”) is
          entered into by and between you and Eatoes Private Limited(“Eatoes”).
          Specific business terms associated with Merchant’s subscription to the
          Platform are set forth in one or more ordering documents executed by
          the parties that reference this Merchant Agreement (“Order Form”) and
          are hereby incorporated into this Merchant Agreement by reference
          (collectively, the Merchant Agreement and the Order Form shall be the
          “Agreement”). For the purposes of this Merchant Agreement, “you” or
          “Merchant,” means the party identified as the merchant in the
          applicable Order Form. By executing an Order Form that references this
          Merchant Agreement, each party signifies that it has read,
          understands, and agrees to be bound by this Merchant Agreement. This
          Agreement shall be effective as of the date the first applicable Order
          Form is executed and/or the date on which the Merchant commences using
          the Platform, whichever is earlier. This Merchant Agreement governs
          all Order Forms and any conflicting or additional terms and conditions
          are of no force or effect unless agreed to in writing signed by an
          authorized officer of Eatoes. Subject to the terms of this Agreement,
          Eatoes will allow Merchant to offer rewards to Merchant’s customers
          using the customer engagement and retention platform known as
          “Eatoes,” as may be further described at www.eatoes.com and includes
          the Dashboard, Equipment and Software (all as defined in Section 1)
          (collectively, the “Platform”).
        </p>

        <p>
          <b>1. EATOES SOFTWARE AND HARDWARE.</b> The Platform consists of two
          (2) components: (a) an online dashboard hosted by Eatoes that allows
          Merchants to manage and create rewards programs and track customer
          data (“Dashboard”), and (b) software that is either pre-loaded or
          downloaded onto the Merchant’s point of sale machine that interacts
          with the Dashboard (“Software”). Subject to the terms and conditions
          of this Agreement, Eatoes hereby grants to Merchant (a) a
          non-exclusive, non-assignable, non-transferable, non sublicensable,
          limited right and license to use and display the Software for
          Merchant’s business purposes, and (b) the right to access and use the
          Dashboard, in the case of both (a) and (b), solely in accordance with
          the documentation related to the Software or the Platform generally
          published by Eatoes. RESTRICTIONS. Merchant agrees that (a) it will
          not decompile, reverse engineer, or otherwise attempt to obtain the
          source code or underlying ideas or information of or relating to the
          Software, (b) it will not sell, lease, license, sublicense,
          distribute, rent, transfer, assign, timeshare, or otherwise
          commercially exploit or provide the Platform to any third party, (c)
          it will only use the Software and Equipment in order to interface with
          the Dashboard as contemplated by this Agreement, (iv) create internet
          “links” to or from the Dashboard, or “frame” or “mirror” the
          Dashboard, (d) reverse assemble, reverse compile, reverse engineer,
          decompile or otherwise attempt to discover the object code, source
          code, SDKs, non-public APIs or underlying ideas or algorithms of the
          Platform, except as and only to the extent this restriction is
          prohibited by law, (e) remove or obscure any copyright, trademark or
          other proprietary notices, legends or Eatoes branding contained in or
          on the Platform, (f) use the Platform in any way that violates any
          applicable federal, state, local or international law or regulation,
          (g) introduce any viruses, Trojan horses, worms, logic bombs or other
          material which is malicious or technologically harmful, (h) attempt to
          gain unauthorized access to, interfere with, damage or disrupt any
          parts of the Dashboard, the server on which the Dashboard is stored,
          or any server, computer or database connected to the Platform, and (i)
          use the Platform to send or store materials that is obscene,
          threatening, libelous, or otherwise unlawful or tortious material,
          including material harmful to children or in violation of third party
          privacy rights. Eatoes may, in its sole discretion, immediately
          suspend Merchant’s access to the Platform if Merchant violates the
          terms of this Section.
        </p>
        <p>
          <b>2. PROFESSIONAL SERVICES.</b> From time to time, upon the mutual
          agreement of the parties, Eatoes and Merchant may enter into ordering
          documents or statements of work (“Statement of Work”) as mutually
          agreed and executed between the Parties, for the purpose of Eatoes
          providing certain professional services to Merchant (“Professional
          Services”). Merchant shall pay Eatoes a fee (if applicable) for any
          Professional Services provided in accordance with this Agreement and
          the applicable Statement of Work agreed between Eatoes and the
          Merchant from time to time.
        </p>
        <p>
          <b>3. REMOTE ACCESS. </b> Merchant understands and agrees that when
          the Software is installed, remote access software may also be used by
          Eatoes for the sole purposes of Eatoes being enabled to maintain and
          provide remote technical support for such Software
        </p>
        <p>
          <b> 4. MERCHANT INFORMATION AND REWARDS PROGRAMS.</b> Merchant grants
          to Eatoes a non-exclusive, worldwide license and right to display,
          reproduce and use Merchant’s trademarks and logos (collectively,
          “Merchant Marks”) and business description solely in connection with
          the operation of the Platform. As part of the Platform, Merchant will
          create rewards programs that Merchant will offer to its customers
          through the Platform (“Merchant Rewards Program”) Merchant represents,
          warrants and covenants that it will honor any Merchant Rewards
          Programs it offers, even after any expiration or termination of this
          Agreement. Merchant further represents, warrants and covenants that
          the Merchant Rewards Programs will comply with all applicable laws and
          regulations. Merchant agrees to receive periodic SMS, Email and
          Whatsapp communication from Eatoes during use of the service.
          Following any expiration or termination of this Agreement and if
          requested by Merchant within seven (7) days of such expiration or
          termination, Eatoes will provide Merchant with any relevant
          information in Eatoes’ possession regarding the then-current status of
          the Customers in relation to the Merchant Rewards Program.
        </p>
        <p>
          <b>5. CUSTOMERS.</b> Merchant acknowledges and agrees that when one of
          its customers signs up to be a user of the Platform (or when Merchant
          enrolls a customer or enables a customer to enroll as a user of the
          Eatoes service), whether through the Eatoes’ web site or otherwise,
          such customer shall be deemed a customer of Eatoes (“Customer”), and
          Eatoes shall have the perpetual and irrevocable right to use, in
          connection with its business activities, all information made
          available to Eatoes here under and will continue to have the right to
          communicate with Customers and provide the Platform in relation to
          such Customers, in each case even after the expiration or termination
          of this Agreement. To the extent Merchant enrolls a Customer as a user
          of the Eatoes service (or enables a Customer to enroll) or provides
          Eatoes with data and information (including a cellular or mobile
          number or other personally identifiable information) of any person who
          provides such information to Merchant, Merchant represents and
          warrants that Merchant has (a) informed each such person of the Eatoes
          Terms of Use and Privacy Policy and that such person may receive text
          messages or other communications from Eatoes, and (b) obtained all
          necessary consents and rights required (i) to share that information
          with Eatoes, and (ii) so that Eatoes may so communicate with each such
          person and otherwise use such data and/or information in any manner
          that complies with Eatoes’ Privacy Policy. In connection with
          Merchant’s use of Customer information and the Platform, and
          Merchant’s administration of its Merchant Rewards Program(s), it shall
          comply with all applicable law and any written policies provided by
          Eatoes to Merchant from time to time, including Eatoes’ Privacy Policy
          located at www.Eatoes.io/privacy-policy. Merchant shall include an
          opt-out notice in any communications that it delivers to any
          Customers, and will comply with any opt-out requests made by
          Customers.
        </p>
        <p>
          <b>6. DATA. </b>Regardless of where the Software is installed, as part
          of providing the Platform, Eatoes may collect data related to the use
          and performance of the Platform. The parties acknowledge that Eatoes
          is free to collect and analyze such data and other information
          relating to the provision, use and performance of the Platform for the
          purpose of improving the Platform provided that Merchant cannot be
          identified and such data is used solely in an aggregate or other
          de-identified form.
        </p>
        <p>
          <b>7. FEEDBACK. </b>Merchants may from time to time provide
          suggestions, comments or other feedback with respect to the products
          or services of the Receiving Party (“Feedback”). Both Parties agree
          that all Feedback is and shall be given entirely voluntarily. Eatoes
          shall be free to use, disclose, reproduce, license or otherwise
          distribute and exploit any Feedback as it sees fit, entirely without
          obligation or restriction of any kind.
        </p>
        <p>
          <b>8. OWNERSHIP. </b>Merchant acknowledges and agrees that Eatoes and
          its licensors are the owners of all right, title and interest in and
          to the Platform and all updates, upgrades, and derivative works
          thereto, including anything developed or created by Eatoes as part of
          the Professional Services, and all intellectual property rights
          therein including anything developed or created by Eatoes as part of
          the Professional Services, and that Merchant will not obtain or claim
          any ownership interest in the foregoing.
        </p>

        <p>
          <b>9. PAYMENT.</b> Merchant will pay Eatoes in accordance with the
          payment terms set forth on Eatoes’s website. Fees may be due in
          advance or shall be paid in arrears, as described on the applicable
          Order Form or the website, as may be applicable. If Eatoes will bill
          Merchant for fees owed hereunder, Merchant’s payment will be
          considered delinquent if a payment is not received within thirty (30)
          days following the receipt of an invoice for such payments. If
          Merchant is paying via credit card, Merchant authorizes Eatoes to
          charge the fees due hereunder (and any other charges Merchant may
          incur in connection with the Platform (such as taxes)) to Merchant’s
          provided payment instrument in advance on a periodic basis in
          accordance with the terms on each Order Form or the website, as may be
          applicable. Merchant is responsible for keeping its payment instrument
          and all associated information (such as the expiration date of a
          credit card) current at all times. If a payment is not successfully
          settled, due to expiration, insufficient funds, or otherwise, and
          Merchant does not promptly update its payment instrument information,
          (a) Eatoes reserves the right to suspend Merchant’s access to the
          Platform until such time as payment is successfully settled, including
          past due payments, if any and (b) Merchant authorizes Eatoes to
          continue charging its payment instrument, as it may be updated. If
          Merchant’s use of the Platform exceeds the capacities or limits on an
          Order Form or the website or otherwise requires the payment of
          additional fees, Merchant hereby authorizes Eatoes to charge such
          additional fees to Merchants payment instrument in the following pay
          cycle. All fees shall be paid in Indian Rupees (INR) or any other
          currency as may be mutually agreed between Eatoes and the Merchant.
          All fees paid are non-refundable. Merchant agrees to pay the costs and
          expenses incurred by Eatoes or on behalf of Eatoes in connection with
          the collection of any unpaid charges and fees. Merchant agrees to
          cover any additional bank charges associated with all Eatoes payments
          and fees. Eatoes is not obligated to pay any item presented for
          payment if the Merchant's account does not contain sufficient
          collected funds. Merchants will be responsible for all taxes in
          connection with this Agreement (excluding taxes based on Eatoes’ net
          income). Billing will commence on the first billing date, as specified
          in the Order Form and/or the website, as may be applicable.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>10. TERM AND TERMINATION.</b>
        </p>
        <p>
          <p>1. Term.</p> The term of this Agreement shall start on the
          effective date or the subscription start date (as applicable) listed
          on the Order Form or the website, as applicable (“Effective Date”) and
          continue for the initial term listed on the Order Form or the website,
          as applicable (“Initial Term”). The Order Form and this Agreement will
          automatically renew for successive periods equal to the Initial Term,
          as stated in the Order Form (each, a “Renewal Period”) (collectively,
          the Initial Term and any Renewal Periods shall be the “Term”) unless
          either party provides the other with notice of its intent not to renew
          at least thirty (30) days before the end of the then-current Term.
        </p>
        <p>
          <b>2. Termination.</b> Either party may terminate this Agreement if
          the other party breaches or defaults on any of its obligations under
          this Agreement (such that the breach or breaches, individually or in
          the aggregate, deprive the other party of the intended benefit of this
          Agreement) and said breach or default continues un-remedies for a
          period of ninety (90) days after the breaching party’s receipt of
          written notice. Written notices of breach must specify the grounds of
          such breach or default in reasonable detail. Except as provided in
          this Section and Section 11.1 above, Merchant may not otherwise
          terminate this Agreement or any Order Form except as may be explicitly
          set forth on the Order Form.
        </p>
        <p>
          <b>3. Effect of Termination</b>. Upon termination of this Agreement,
          Merchant’s rights to use and access the Platform will immediately
          cease and Merchant will cease using and accessing the Software and the
          Dashboard and return all Equipment and pay any associated fees in
          accordance with Exhibit A. Sections 5 through 15 will survive the
          termination or expiration of this Agreement.
        </p>
        <p>
          <b>11. INDEMNITY.</b>Merchant will defend any third party claim or
          action brought against Eatoes relating to (a) the Merchant Rewards
          Programs, (b) Merchant’s breach of Section 5 (Merchant Rewards
          Programs), or (c) Merchant’s breach of the representations and
          warranties contained in Section 6 (Customers) and Merchant will pay
          (i) any settlements entered into between Eatoes and the third party
          claimant or (ii) final judgments awarded to the third party claimant
          by a court of competent jurisdiction. Eatoes shall (a) provide
          Merchant with prompt written notice of any claim, (b) grant Merchant
          sole control of the defense and settlement of the claim, and (c)
          provide reasonable information and assistance to Eatoes in the defense
          or settlement of the claim at Eatoes’ expense.
        </p>
        <p>
          12. DISCLAIMER.THE Eatoes PLATFORM AND PROGRAM AND ALL PRODUCTS,
          SERVICES (INCLUDING PROFESSIONAL SERVICES), CONTENT AND ITEMS PROVIDED
          BY Eatoes HEREUNDER (INCLUDING, WITHOUT LIMITATION, THE EQUIPMENT (AS
          APPLICABLE)) ARE PROVIDED “AS IS” AND Eatoes DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF UNINTERRUPTED USE AND
          ALL IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
          PARTICULAR PURPOSE AND NONINFRINGEMENT.
        </p>
        <p>
          <b>13. LIMITATION OF LIABILITY.</b>TO THE FULLEST EXTENT ALLOWED BY
          APPLICABLE LAW, AND REGARDLESS OF THE FORM OF ACTION (E.G., WHETHER IN
          CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE) NEITHER Eatoes NOR ANY OF
          ITS LICENSORS OR SUPPLIERS SHALL BE LIABLE TO MERCHANT OR TO ANY OTHER
          PARTY IN CONNECTION WITH ANY SUBJECT MATTER OF THIS AGREEMENT FOR (A)
          ANY SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL OR INDIRECT DAMAGES
          OF ANY KIND (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS,
          LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
          FAILURE OR MALFUNCTION), OR (B) ANY AMOUNTS IN THE AGGREGATE THAT
          EXCEED THE FEES PAID BY MERCHANT TO Eatoes HEREUNDER IN THE TWELVE
          (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT THAT GAVE RISE TO
          THE APPLICABLE CLAIM. THE FOREGOING LIMITATIONS SHALL APPLY WHETHER OR
          NOT Eatoes (OR ITS LICENSOR OR SUPPLIER, AS APPLICABLE) HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS SHALL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
          REMEDY.
        </p>
        <p>
          <b>14. GENERAL PROVISIONS.</b>The terms of this Agreement and any and
          all non-public information disclosed by Eatoes to Merchant pursuant to
          this Agreement are confidential, and Merchant agrees not to disclose
          the terms of this Agreement or any such information to any third
          party, or use any such information other than for the purposes
          expressly set forth herein. Customer agrees that Eatoes may use
          Customer’s name and logo to identify Customer as a customer of Eatoes
          on Eatoes’ website and in other advertising, marketing and promotional
          materials. Merchant may not assign this Agreement or any of its rights
          or delegate any of its duties under this Agreement without Eatoes’
          prior written consent, except to a successor to all or substantially
          all of its business or assets; Eatoes may assign this Agreement and/or
          any of its rights and/or delegate any of its duties under this
          Agreement without consent. This Agreement contains the entire
          understanding of the parties with respect to the transactions and
          matters contemplated hereby, and supersedes all previous
          communications, understanding and agreements (whether oral or
          written). The Agreement consists of this Merchant Agreement, all
          exhibits attached hereto and all Order Forms, and represents the
          entire agreement between the parties; any additional or conflicting
          conditions, printed or otherwise, appearing on any orders, copy
          instructions or other documents will be of no effect. Merchant shall
          comply with all applicable domestic and international laws, statutes,
          ordinances and regulations regarding Merchant's use of the Software
          and the Services.
        </p>
        <p>
          15.Merchant and Eatoes are independent contractors, and no agency,
          partnership, joint venture, employee-employer or franchisor-franchisee
          relationship is intended or created by this Agreement. Each Party
          shall be responsible for the fulfillment of their respective liability
          to their vendors or employees and this contract in no way allows the
          one party’s vendors or employees to raise any claim against the other
          Party. It is expressly acknowledged and agreed that Eatoes is only
          providing [loyalty program] services hereunder, and is not issuing,
          managing, or dealing in the issuance or management of any other goods,
          services or pre-paid payment instrument. Any registrations and
          compliance required under applicable law for the issuance and
          management of pre-paid payment instruments have been/will be obtained
          by the Merchant, as may be applicable.CAMPAIGN TERMS OF USE.The
          following terms shall, in addition to all of the other terms set forth
          in this Agreement, govern your use of Eatoes’s Marketing Campaign
          platform (the Marketing Campaign Platform), which, at a general level,
          allows you to communicate with Eatoes members.
        </p>
        <p>
          <b>16.General Rules</b>
          You shall, as a condition to making any use of the Marketing Campaign
          Platform, be required to follow these rules: 1. You won’t send Spam!
          While you are responsible for making yourself aware of current
          anti-SPAM and similar regulations that may be in effect from time to
          time, as a general rule, you should assume that if you send people
          mass email without their permission, you’re spamming. 2. You won’t
          violate Eatoes’s Acceptable Use guidelines, established below, as it
          may be in effect from time to time, which is part of this Agreement.
          If you violate any of these rules, Eatoes, in addition to any other
          remedies that Eatoes may have hereunder or otherwise, may suspend or
          terminate your account.
        </p>
        <p>
          <b>17.Compliance with Laws</b>
          You represent and warrant that your use of the Marketing Campaign
          Platform, which includes communication through emails and SMSs will
          comply with all applicable laws and regulations. You also acknowledge
          and agree that the promotional messages/emails sent by You directly is
          only your liability, responsibility and that Eatoes is not
          liable/responsible or answerable for any form of communication,
          communicated between You and the customer, directly or indirectly. You
          agree that all claims, queries and complaints arising out of such
          communication, shall be directly addressed and resolved by you.
          Acceptable Use You shall refrain from using the Marketing Campaign
          Platform to send anything offensive, to promote anything illegal, or
          to harass anyone. Not in limitation of the foregoing, you may not
          send: 1. Pornography or other sexually explicit Emails 2. Emails
          offering to sell illegal goods or services 3. Emails that violate
          Information Technology Act, 2000 or any other applicable laws or
          regulations
        </p>
        <p>
          <b>18. Acceptable Use - Prohibited Actions</b>
          Eatoes works hard to keep its system clean, but Eatoes also counts on
          its customers to pitch in. As such, you may not: 1. Send Spam (as
          noted above). 2. Share your password. 3. Use any of the Marketing
          Campaign Platform to create a competing product. 4. Import or
          incorporate any of this information into any lists, emails or uploads
          to our servers: Passwords, security credentials, or sensitive personal
          information of any kind. 5. Send any messages through the Marketing
          Campaign Platform that are not appropriate for its intended use. 6.
          Use an outside unsubscribe process.
        </p>
        <p>
          <b>19. GOVERNING LAW AND JURISDICTION.</b>This agreement is governed
          and construed in accordance with the laws of the Republic of India.
          Merchant hereby irrevocably consents to the exclusive jurisdiction and
          venue of courts in Ahmedabad, Gujarat, India, in all disputes arising
          out of or relating to the use of the Eatoes products/sites/services.
          Use of the Eatoes software services is unauthorized in any
          jurisdiction that does not give effect to all provisions of these
          terms and conditions, including without limitation this paragraph. If
          any dispute arises between Merchant and Eatoes during Merchant's use
          of the software/services or thereafter, in connection with the
          validity, interpretation, implementation or alleged breach of any
          provision of this Agreement, the dispute shall be referred to a panel
          of 3 arbitrators out of which 1 arbitrator shall be appointed by
          Eatoes, 1 arbitrator shall be appointed by the Merchant and the third
          arbitrator shall be jointly appointed by the 2 arbitrators appointed
          by the respective parties. The venue, place and seat of arbitration
          shall be Ahmedabad. The Arbitration & Conciliation Act, 1996, shall
          govern the arbitration proceedings. The arbitration proceedings shall
          be in the English language. All expenses with respect to Arbitrator
          fees and his expenses shall be borne by the Parties equally. All other
          expenses of Lawyers fees and other expenses shall be borne by the
          respective parties.
        </p>
      </div>

      {/* </Box>
      </Modal> */}
    </>
  );
};
export default Terms;
