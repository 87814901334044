import React, { ReactNode } from "react";

interface ComponentProps {
  type: string;
  name: string | ReactNode;
  active?: boolean;
  value: string;
}

export const Tabs = ({ name, active, value, type }: ComponentProps) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className={`progress-tabs ${active && "progress-tabs-active"}`}>{name}</div>
      <div
        className={`progress-tabs-value-div text-nowrap ${
          active && "progress-tabs-value-active-div"
        }`}
      >
        {value}
      </div>
    </div>
  );
};
