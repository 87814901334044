import React from "react";
import PropTypes from "prop-types";

const SubTitle = ({ text }) => {
  return <p className="mb-4 custom-p">{text}</p>;
};

SubTitle.propTypes = {
  text: PropTypes.node.isRequired,
};

export default SubTitle;
