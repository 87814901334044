//@ts-nocheck
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
// import { api } from './MockData';
import "../styleSheets/Listsection.css"
import { useSelector } from 'react-redux';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 400,
  bgcolor: 'rgb(43,49,55)',
  borderRadius: "10px",
  boxShadow: 24,
  color: "white",
  p: 2,
};

export default function SpringModal({ type }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const api = useSelector(state => state.fastSocial.apiData)
  if (Object.keys(api) === 0) {
    return
  }
  return (
    <>
      <div className=' d-flex justify-content-center align-items-center pt-1'>
        <Typography sx={{ fontSize: "19px" }} onClick={() => handleOpen()} className="p-0 m-0 list-item fw-light ms-2 cursor-pointer">Check</Typography>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className='mx-3' style={{ fontSize: "14px" }}>
                {type === "address" && <div className='text-center'>{api['Contact Details'].address.content}</div>}
                {type === "time" && api['Contact Details'].operationalHours.openAll && <div className='text-center'>Open 24x7</div>}
                {Object.keys(api['Contact Details'].operationalHours).filter(data => data !== "live" && data !== "openAll").map((day, index) => {
                  return (
                    <div key={index}>
                      {type === "time" && api['Contact Details'].operationalHours[`${day}`].check && <div className='d-flex justify-content-between my-4'>
                        <div style={{ textTransform: "capitalize", }}>{day}</div>
                        <div>
                          <div>
                            {api['Contact Details'].operationalHours[`${day}`].timings.shift1.open} - {(api['Contact Details'].operationalHours[`${day}`].timings.shift1.close)}
                          </div>
                          {api['Contact Details'].operationalHours[`${day}`].timings.shift1.open &&
                            api['Contact Details'].operationalHours[`${day}`].timings.shift2.open
                            &&
                            <div>
                              {api['Contact Details'].operationalHours[`${day}`].timings.shift2.open} - {api['Contact Details'].operationalHours[`${day}`].timings.shift2.close}
                            </div>}
                        </div>
                      </div>}
                    </div>
                  )
                })}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>

    </>
  );
}
