//@ts-nocheck
import React, { useState } from "react";
import "../styleSheets/Listsection.css";
import { useSelector } from "react-redux";
import Pop_up_2option from "../utils/Pop_up_2option";
import { USER } from "../constants/localStorageKeys";
import { formatWhatsappNumber } from "../utils/functionUtils";
import { useNavigate } from "react-router-dom";

const serviceObj = {
  whatsAppBusiness: "WhatsApp Api",
  googleTableReservation: "Reserve Your Table",
  fastProfileVerification: "Fast Social",
  digitalMenu: "Digital Menu",
};

const ListSection = ({ values }) => {
  const navigate = useNavigate();
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const services = api["Basic Details"]?.account?.services;
  const rolesData = useSelector((state) => state.roles.apiData);

  const handleLinkClick = (name, url) => {
    console.log("Url of handle", name, "----", url);
    if (name.includes("button_wp_icon") && !url.startsWith("https://")) {
      const modifiedUrl = formatWhatsappNumber(url);
      return `https://wa.me/${modifiedUrl}`;
    }
    if (name.includes("button_phone_icon")) return `tel: ${url}`;
    else if (!url.startsWith("https://")) return `https://${url}`;
    else return url;
  };

  let name;
  rolesData.responseData
    ? (name = [
        {
          name: "Edit Links",
          route: `/FastSocial/Linkspage?accountId=${api?.Links?.accountId}`,
        },
      ])
    : (name = []);
  if (Object.keys(api) === 0) {
    return;
  }
  return (
    <div
      className="text-white d-flex flex-column justify-content-center"
      style={{ gap: "24px" }}
    >
      {services && Object.keys(services)?.filter(val => val.enabled).length !== 0  && (
        <div className="mx-3 card text-white rounded-4 shadow">
          <div className="mx-3 my-3">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h1 className="fw-semibold fs-1">Services</h1>
              <div>
                <Pop_up_2option name={name} id={"listsection"} />
              </div>
            </div>
            {Object.keys(services)?.filter(val => val.enabled)?.map((value, index) => (
              <div
                key={index}
                className="items-list d-flex justify-content-center my-4 fs-6 rounded-3 fw-lighter cursor-pointer"
              >
                <div
                  className="m-auto list-item text-center"
                  onClick={() =>
                    navigate(
                      `/reserve/${api["Basic Details"]?.account?.profileId}`
                    )
                  }
                >
                  {serviceObj[value]}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {api?.Links?.link?.filter((link) => link.live)?.length != 0 && (
        <div
          className="mx-3 mt-4 card text-white rounded-4 shadow"
          id="listsection"
        >
          <div className="mx-3 my-3">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h1 className="fw-semibold fs-1">Links</h1>
              <div>
                <Pop_up_2option name={name} id={"listsection"} />
              </div>
            </div>
            {api?.Links?.live &&
              api?.Links?.link
                ?.filter((link) => link.live)
                ?.map((link, index) => (
                  <a
                    rel="noopener noreferrer"
                    href={handleLinkClick(link.icon, link.url)}
                    key={index}
                    className="text-decoration-none list-item"
                    target="_blank"
                  >
                    {" "}
                    <div className="items-list d-flex justify-content-center my-4 fs-6 rounded-3 fw-lighter">
                      <div className="m-auto list-item text-center">
                        {link?.name}
                      </div>
                    </div>
                  </a>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ListSection;
