// @ts-nocheck
import { useEffect, useState} from "react";
import "swiper/css/navigation";
import classes from "../../styleSheets/FoodMenu.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay} from "swiper";
import { Navigation } from "swiper";
import "swiper/css";
import placeholder from "../../RestaurantMenu/Images/placeholders/carouselPlaceholder.jpeg";
import Loader from "../../assets/images/images/Loader.jpeg";
import DeleteCarousel from "./DeleteCarousel";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { useSelector } from "react-redux";
import "../css/carousel.css"

const Carousel = ({ name, selectedProfile, data, setData, loadingImage, setCoverDragState, carouselData, setCarouselData }) => {
  console.log("data object----->", data);
  const menuAccessState = useSelector(state => state.menuAccess.apiData )
  console.log(name, "carousel");
  // const [loadingImage, setLoadingImage] = useState(false);
  const [delImage, setDelImage] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  console.log(activeSlideIndex, "sloides");
  // const activeIndex=swiper.activeIndex;
  const callApis = async () => {
    // setLoadingImage(true)
    // const data = await carouselImage(`${baseURL}${MENU_API.MENU}${name}`);
    setCarouselData(data?.HomePageImagesurl);
    // setLoadingImage(false)
  };
  useEffect(() => {
    console.log("courosel edit");

    callApis();
  }, [data]);  

  return (
    <div className="main-carousel">
      <Swiper
        slidesPerView={1.2}
        loop={true}
        initialSlide={1}
        autoHeight={true}
        centeredSlides={true}
        spaceBetween={25}
        autoplay={{
          delay: 2500,
          disableOnInteraction:false,
          pauseOnMouseEnter:true,
        }}
        modules={[Autoplay,Navigation]}
        breakpoints={{
          768: {
            spaceBetween:50
          },
        }}
        className="mt-2 mb-4 py-3"
      >
        {loadingImage ? (
          <SwiperSlide >
            <div style={{ display: "flex", justifyContent: "center"}}>
              <div className={classes.img}>
                <img className={classes.carouselImage} src={Loader}></img>
              </div>
            </div>
          </SwiperSlide>
        ) : (
          <>
            {carouselData == undefined ||
            carouselData == null ||
            carouselData.length == 0 ||
            !carouselData ? (
              <SwiperSlide >
                {menuAccessState ? (
                  <div className={classes.layer_container}>
                    <div className={classes.myLayer}>
                      <img src="/assets/add-carousel.svg" />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "black",
                  }}
                >
                  {/* <AddCarouselImage
                    imgEdit={imgEdit}
                    setImgEdit={setImgEdit}
                    loading={loading}
                    setLoading={setLoading}
                    images={images}
                    setImages={setImages}
                    carouselData={carouselData}
                    setCarouselData={setCarouselData}
                    setWholeData={setData}
                  /> */}
                  {/* {imgEdit ? <AddCarouselImage imgEdit={imgEdit} setImgEdit={setImgEdit} loading={loading} setLoading={setLoading} images={images} setImages={setImages} carouselData={carouselData} setCarouselData={setCarouselData} /> : ""} */}
                  <div className={classes.img}>
                    <img
                      className={classes.carouselImage}
                      src={placeholder}
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
            ) : (
              carouselData?.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                  >
                    {/* {menuAccessState ? (
                      <div className={classes.layer_container}>
                        <div
                          className={classes.myLayer}
                          onClick={handleAddImage}
                          style={{marginTop:"1.6rem" ,marginRight:"0.1rem"}}
                        >
                          <img src="/assets/add-carousel.svg" />
                        </div>
                        <div
                          className={classes.myLayer2}
                          onClick={handleDeleteImage}
                          style={{marginBottom: "-2rem" ,marginRight:"0.1rem"}}
                        >
                          <img src="/assets/delete-carousel.svg" />
                        </div>

                        <div
                          className={`${classes.myLayer2}`}
                          style={{marginRight:"0.1rem"}}
                          onClick={()=>setCoverDragState(true)}
                        >
                          <img style={{height: "12px", marginTop: "0.7px"}} src={ThreeLinesMenuIcon} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "black",
                        borderRadius:"25px",
                        marginTop:"5px"
                      }}
                    >
                      {/* {
                        <AddCarouselImage
                          imgEdit={imgEdit}
                          setImgEdit={setImgEdit}
                          loading={loading}
                          setLoading={setLoading}
                          images={images}
                          setImages={setImages}
                          carouselData={carouselData}
                          setCarouselData={setCarouselData}
                          setWholeData={setData}
                        />
                      } */}

                      <div className={`${classes.img}`}>
                        {item.image ? (
                          <>
                            {checkImageUrl(item?.image) == "" ? (
                              <img
                                src={item?.image}
                                alt="Cover Image"
                                style={{ width: "100%" }}
                                className={classes.carouselImage}
                              />
                            ) : (
                              <img
                                src={`${imgBaseUrl}/${item?.image}`}
                                alt="Cover Image"
                                style={{ width: "100%",borderRadius:"25px" }}
                                className={classes.carouselImage}
                              />
                            )}
                          </>
                        ) : (
                          <img
                            className={classes.carouselImage}
                            src={placeholder}
                          ></img>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            )}
          </>
        )}
      </Swiper>
      {delImage ? (
        <>
          <DeleteCarousel
            type="Caraousel"
            objId={carouselData[activeSlideIndex]._id}
            data={carouselData}
            setData={setCarouselData}
            delImage={delImage}
            setDelImage={setDelImage}
          />
        </>
      ) : (
        ""
      )}
      
    </div>
  );
};

export default Carousel;
