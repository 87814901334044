import React from "react";

export const Card = () => {
  return (
    <div className="mt-4 rounded-2">
      <div className="d-flex flex-column gap-2 card-div rounded-top-2 py-3">
        <div className="card-txt1">Build Trust With Eatoes Verified</div>
        <div className="profile-pic-div m-auto">
          <img
            src="https://i.pinimg.com/736x/98/58/74/9858745cd157f2797065e639c5b3bf23.jpg"
            alt="Profile Image"
            className="w-100 h-100 m-auto object-fit-cover rounded-circle"
          />
        </div>
        <div className="card-txt1">testamit</div>
      </div>
      <div className="card-txt2 py-3 px-3 bg-white border rounded-bottom-2 fs">
        A verified badge
        <br />
        Your audience can trust that you are a real person.
        <br />
        <br />
        Increased account security
        <br />
        Worry less about impersonation with proactive identity monitoring.
      </div>

      <div></div>
    </div>
  );
};
