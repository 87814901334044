// @ts-nocheck
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  textWrap: "nowrap",
  outline: "none",
  borderRadius: "50px",
  height: "fit-content",
  boxShadow: 24,
  p: 2,
};

export function SwitchProfilePopup({ open, setOpen, profileUserName }) {
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        handleClose();
      }, 3000); // 3 seconds
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>Switched to {profileUserName || "new profile"}</Box>
      </Modal>
    </div>
  );
}
