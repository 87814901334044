//@ts-nocheck
import React from "react";
import TopHeader from "../utils/TopHeader";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ExtraSetting } from "./MockData";
import { FaArrowRight } from "react-icons/fa";

const Extrasetting = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const catId = queryParams.get("objId");
  const subCatId = queryParams.get("itemId");

  /*   const fullDataParam = queryParams.get("fullDataParam");
  const fullData = fullDataParam ? JSON.parse(fullDataParam) : null; // Parse back to object */

  /*  console.log("Full Data in settings:", fullData); */ // You can use fullData here

  return (
    <>
      <TopHeader name="Extra Settings" />
      <div style={containerStyle}>
        <div className="container mt-3">
          <div
            className="row mb-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {ExtraSetting.map((options, index) => (
              <div
                className="col-12 mt-3"
                style={buttonStyle}
                key={index}
                onClick={() => {
                  navigation({
                    pathname: `/FastSocial/${options.route}page`,
                    search: createSearchParams({ catId, subCatId }).toString(),
                  });
                }}
              >
                <span>{options.option}</span>
                <FaArrowRight style={{ backgroundColor: "#27343C" }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const buttonStyle = {
  backgroundColor: "#27343C",
  color: "white",
  padding: "12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  width: "90%",
};

export default Extrasetting;
