// @ts-nocheck
import React, {useState} from "react"
import { Upload_Images } from "../../utils/Upload-Image"

export const EventMediaUpload = ({
  imgEdit,
  setImgEdit,
  images,
  setImages,
  setMedia,
  setMediaObj,
  accountId
}) => {
  const [aspectRatio, setAspectRatio] = useState(9/16)
  const [isUploading, setIsUploading] = useState(false)

  const uploadImgFunc = async(tosendImage) => {
    setImgEdit(false);
    setImages(null);
    setMediaObj(tosendImage)
    setMedia(URL.createObjectURL(tosendImage))
    setIsUploading(false);
    setAspectRatio(9/16)

  }

  return (
    <div>
      <Upload_Images
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        forType={"FastSocial"}
        images={images}
        setImages={setImages}
        uploadImgFunc={uploadImgFunc}
        aspectRatio={aspectRatio}
        setAspectRatio={setAspectRatio}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        maxSizeMB={1}
      />
    </div>
  )
}