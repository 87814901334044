//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styleSheets/Event_list.css";
import arrow from "../assets/icons/Social_icons/arrowSvg.svg";
import _3dot from "../assets/icons/Social_icons/3dot.svg";
// import Pop_up_edit from "../utils/Pop_up_edit";
import classes from "../styleSheets/FoodMenu.module.css";
import Pop_up_2option from "../utils/Pop_up_2option";
import { USER } from "../constants/localStorageKeys";
import { formatDate } from "../utils/functionUtils";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { useSelector } from "react-redux";
import { SingleImgLightbox } from "../utils/Lightbox/SingleImgLightbox";

const Event_list = ({ eventlist, data }) => {
  const rolesData=useSelector(state=>state.roles.apiData)
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const nevigate = useNavigate();

  const handleBtnClick = (action) => {
    if(action.url){
      window.open(action.type == "call" ?  `tel: ${action.url}` : action.url, "_blank");
    }else{

    }
  }

  let name 
  rolesData?.responseData ? name=[{name:'Edit Events',route:`/FastSocial/Updateseventspage?accountId=${eventlist?.[0].accountId}`}]:name=[]

  return (
    <div className={`${classes.container1}`}>
      <div className={`${classes.container2}`}>
        <div className="text-white Social pb-5">
          <div className="p-3 pb-2 pt-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-semibold fs-4 d-flex align-items-center">
                <img
                  src={arrow}
                  onClick={() => nevigate(-1)}
                  className="me-3 cursor-pointer "
                />
                Events
              </div>
              <div>
                <Pop_up_2option name={name} />
              </div>
            </div>
          </div>
          {eventlist
            ?.filter((event) => event.live == true)
            ?.map((event, index) => (
              <div key={index} className="m-3 mt-4 card text-white rounded-4 pb-3 shadow">
                <div className="mx-4 my-4">
                  <div className=" d-flex justify-content-between align-items-center">
                    <h1 className="fw-semibold fs-1">
                      {formatDate(event?.date)}
                    </h1>
                    <div>
                      <Pop_up_2option  key={index} name={name} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {event.date && (
                        <div className="eventname mt-3 ">{event?.name}</div>
                      )}
                    </div>
                    {/* <div className="d-flex flex-column align-item-start">
              <div className="age-text mt-5 pe-1">*For Age</div>
              <div className="age mt-0 ms-2 fs-1">{events[0].eventage}</div>
            </div> */}
                  </div>
                  {event.media && (
                    <SingleImgLightbox
                      thumbnail={`${imgBaseUrl}/${event.media}`}
                      actualSrc={`${imgBaseUrl}/${event.media}`}
                      imgClassName="h-100 w-100 rounded-4 mt-4 mb-2 ratio-1x1"
                    />
                  )}
                  <div className="d-flex justify-content-between">
                    {/* <div>
              <div className="by-text fw-6">by</div>
              <div className="hotel-title-event fw-6">{data.hotelname}</div>
              <div className="hotel-type-event">{data.hotelType}</div>
            </div> */}
                    <div
                      onClick={() => handleBtnClick(event?.action)}
                      className={`${
                        !event.action.title && "d-none"
                      } d-flex justify-content-center align-items-center check-button p-2 p-md-3 w-100`}
                    >
                      <button className="check-button-text border border-0 bg-transparent text-center shadow-lg">
                        {event?.action?.title}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Event_list;
