// @ts-nocheck
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import { addIcon } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
export const AddIcon = ({
  imgEdit,
  setImgEdit,
  loading,
  setLoading,
  images,
  setImages,
}) => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setImgEdit(false), setImages();
  };
  const [addImage, setAddImage] = useState(false);

  const handleSave = async () => {
    console.log(images, "images");
    if (!images) alert("Please upload an image first");
    else if (images.size / 1024 > 4096) {
      alert("Image Should be less than 4MB");
    } else {
      const data = new FormData();
      data.append("file", images);
      data.append("upload_preset", "connectUpload");
      data.append("cloud_name", "connectwebapp");
      setLoading(true);

      await fetch("https://api.cloudinary.com/v1_1/connectwebapp/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "cloud");
          setImages(data.secure_url);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
      setAddImage(true);
    }
  };
  const save = async () => {
    await addIcon(images, navigate);
    setAddImage(false);
    window, location.reload(true);
  };

  return (
    <div>
      <Modal
        open={imgEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.imageModal}>
          <div></div>
          {loading ? (
            <>Loading...</>
          ) : (
            <div>
              {addImage ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontSize: "10px" }}>
                    {" "}
                    Are you sure you want to upload this image?
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {" "}
                    <button
                      style={{
                        width: "50px",
                        height: "18px",
                        fontSize: "10px",
                        border: "1px solid #FF8D5F",
                        color: "#FF8D5F",
                        margin: "5px 5px 0 0",
                        background: "none",
                        textTransform: "none",
                      }}
                      onClick={save}
                    >
                      yes
                    </button>{" "}
                    <button
                      style={{
                        width: "50px",
                        height: "18px",
                        fontSize: "10px",
                        border: "1px solid #FF8D5F",
                        color: "#FF8D5F",
                        margin: "5px 5px 0 0",
                        background: "none",
                        textTransform: "none",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  <input
                    type="file"
                    onChange={(e) => setImages(e.target.files[0])}
                    style={{ fontSize: "8px" }}
                  />
                  <div style={{ display: "flex", justifyContent: "centre" }}>
                    <button
                      style={{
                        width: "30px",
                        height: "18px",
                        fontSize: "10px",
                        border: "1px solid #FF8D5F",
                        color: "#FF8D5F",
                        marginTop: "5px",
                        background: "none",
                        textTransform: "none",
                      }}
                      onClick={handleSave}
                    >
                      Ok
                    </button>
                    <button
                      style={{
                        width: "50px",
                        height: "18px",
                        fontSize: "10px",
                        border: "1px solid #FF8D5F",
                        color: "#FF8D5F",
                        margin: "5px 5px 0 5px",
                        background: "none",
                        textTransform: "none",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
