// @ts-nocheck
export function FastSocialVerificationStatus(profile) {
    
    {if(profile?.fastProfileVerification?.enabled === false )
        
        return 'Verify Now'
     else if (profile?.fastProfileVerification?.enabled && profile?.fastProfileVerification?.suspend)
    { return 'Expired' }
    else if(profile?.fastProfileVerification?.enabled && !(profile?.fastProfileVerification?.suspend)) 
     return 'Verified' }
}

export function DigitalMenuStatus(profile) {
    
    {if(profile?.digitalMenu.enabled === false || !profile )
        
        return 'Activate Now'
     else if (profile?.digitalMenu?.enabled && profile?.digitalMenu?.suspend)
    { return 'Expired' }
    else if(profile?.digitalMenu?.enabled && !(profile?.digitalMenu?.suspend)) 
     return 'Activated' }
}

 
export function TableReservationStatus(profile) {
    
    {if(profile?.googleTableReservation.enabled === false || !profile )
         return 'Activate Now'
     else if (profile?.googleTableReservation?.enabled && profile?.googleTableReservation?.suspend)
    { return 'Expired' }
    else if(profile?.googleTableReservation?.enabled && !(profile?.googleTableReservation?.suspend)) 
     return 'Activated' }
}

export function WhatsAppStatus(profile) {
   
    
    {if(profile?.whatsAppBusiness.enabled === false || !profile )
         return 'Activate Now'
     else if (profile?.whatsAppBusiness?.enabled && profile?.whatsAppBusiness?.suspend)
    { return 'Expired' }
    else if(profile?.whatsAppBusiness?.enabled && !(profile?.whatsAppBusiness?.suspend)) 
     return 'Activated' }
}
