// @ts-nocheck

const initialState = {
    apiData: {},
  };
  
  const fastSocialReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FAST_SOCIAL_DATA':
        return {
          ...state,
          apiData: action.payload,
          timestamp: new Date()
        };
      default:
        return state;
    }
  };
  
  export default fastSocialReducer;
  