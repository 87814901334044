import ReactGA from "react-ga4";

class AnalyticsUtil {
  constructor(trackingId: string) {
    ReactGA.initialize(trackingId);
    console.log("Initialised with trackingId", trackingId)
  }

  sendPageView(path: string, title: string) {
    ReactGA.send({ hitType: "pageview", page: path, title: title });
    console.log(`Page view sent for ${path} with title ${title}`);
  }

  sendEvent(category: string, action: string, label: string) {
    ReactGA.event({
      category,
      action,
      label,
    });
    console.log(
      `Event sent: Category: ${category}, Action: ${action}, Label: ${label}`,
    );
  }
}

export default AnalyticsUtil;
