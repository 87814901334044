// @ts-nocheck

import React, { useCallback, useEffect, useState } from "react";

import Cropper from "react-easy-crop";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { Button, CircularProgress } from "@mui/material";
import EventImageCropperSelect from "../Social_components/event_image_cropper";

const ImageCropper = ({
  file,
  uploadImage,
  aspectRatio,
  isUploading,
  setIsUploading,
  setAspectRatio,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(0);
  const [checkImg, setCheckImg] = useState("");
  const [fileType, setFileType] = useState("");

  const determineFileType = (file) => {
    const fileExtension = file.name?.split(".").pop().toLowerCase();
    if (["mp4", "mov", "avi", "gif"].includes(fileExtension)) {
      console.log(fileExtension, "FILE NAME");

      setFileType("video");
    } else {
      setFileType("image");
    }
  };

  useEffect(() => {
    if (file) {
      determineFileType(file);
    }
  }, [file]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedArea(croppedArea);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setIsUploading(true);

    if (fileType === "image") {
      const image = new Image();
      image.src = file;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        const context = canvas.getContext("2d");
        context.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
        );

        canvas.toBlob((blob) => {
          const helloFile = new File([blob], "croppedImage.jpeg", {
            type: "image/jpeg",
          });
          // setCroppedImage(file);
          console.log("cropppeddd", helloFile);
          setCheckImg(URL.createObjectURL(helloFile));
          uploadImage(helloFile);
        }, "image/jpeg");
      };
    } else if (fileType === "video") {
      // Video handling logic (You could either trim or process the video)
      // For now, let's just upload the video as is
      uploadImage(file);
    }
  };

  return (
    <>
      <div className="position-relative" style={{ height: "50vh" }}>
        {fileType === "image" ? (
          <Cropper
            image={file}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            classes={"cropper-div"}
            onCropComplete={onCropComplete}
          />
        ) : fileType === "video" ? (
          <video controls width="100%" height="100%">
            <source src={URL.createObjectURL(file)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div>Invalid file type</div>
        )}
      </div>

      <div style={customizeMUIComponents.row} className="mt-4 mb-1">
        {setAspectRatio && fileType === "image" && (
          <EventImageCropperSelect setAspectRatio={setAspectRatio} />
        )}
        <div className="">
          {!isUploading ? (
            <Button
              variant="contained"
              sx={customizeMUIComponents.editButton2}
              onClick={handleClick}
            >
              Upload {fileType === "image" ? "Image" : "Video"}
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
