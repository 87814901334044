import { useState, useEffect } from "react";
import Icons from "../../Components/Icons";
import Datepicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { baseURL } from "../../constants/Api";
import noResults from "../../assets/icons/no-results.png";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import clock from "../../assets/icons/clock.svg";
import phone from "../../assets/icons/phone.svg";
import user from "../../assets/icons/user.svg";
import partysize from "../../assets/icons/partysize.svg";
import calendar from "../../assets/icons/calendar.svg";
import notes from "../../assets/icons/notes.svg";

import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import verticalDivider from "../../assets/images/vertical_divider.svg";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";

type BookingType = {
  id: string;
  uuid: string;
  bookingDate: string;
  bookingTime: string;
  bookingDuration: number;
  bookingStatus: string;
  bookingNotes: string;
  bookingId: string;
  slot: {
    slotDate: string;
    slotStartTime: string;
    slotEndTime: string;
  };
  partySize: string;
  bookingUserFullName: string;
  phoneNumber: string;
  status:
    | "confirmed"
    | "canceled"
    | "declined_by_merchant"
    | "pending_merchant_confirmation";
};

const BookingManagement = () => {
  const [date, setDate] = useState(new Date());

  const [restaurantId, setRestaurantId] = useState("");
  const [totalBookingsCount, setTotalBookingsCount] = useState(0);
  const [acceptedBookingsCount, setAcceptedBookingsCount] = useState(0);
  const [declinedBookingsCount, setDeclinedBookingsCount] = useState(0);
  const [pendingBookingsCount, setPendingBookingsCount] = useState(0);
  const [cancelledBookingsCount, setCancelledBookingsCount] = useState(0);

  const [acceptedBookings, setAcceptedBookings] = useState<BookingType[]>([]);
  const [declinedBookings, setDeclinedBookings] = useState<BookingType[]>([]);
  const [cancelledBookings, setCancelledBookings] = useState<BookingType[]>([]);
  const [pendingBookings, setPendingBookings] = useState<BookingType[]>([]);

  const [currentTab, setCurrentTab] = useState("All");

  const [bookings, setBookings] = useState([]);

  const formatDateToYYYYMMDD = (inputDate: Date) =>
    dayjs(inputDate).format("YYYY-MM-DD");

  useEffect(() => {
    const localRestaurantId = localStorage.getItem("restaurantId");
    if (!localRestaurantId) return;

    setRestaurantId(localRestaurantId);
    fetchBookings(localRestaurantId, date);
  }, [date]);

  const notifyAccepted = () => {
    toast.success("Booking accepted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyDeclined = () => {
    toast.success("Booking declined", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const acceptFailed = () => {
    toast.error("Failed to accept booking. Please try again later", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const declineFailed = () => {
    toast.error("Failed to decline booking. Please try again later", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const setTheDate = (inputDate: any) => {
    const restroId = localStorage.getItem("restaurantId");
    if (!restroId) return;

    // Call API to fetch slots of that date
    fetchBookings(restroId, inputDate, "none");

    setDate(inputDate);
  };

  const fetchBookings = async (
    restroId: string,
    theDate: Date,
    newTab?: "declined" | "accepted" | "none",
  ) => {
    if (restroId !== "") {
      try {
        const authToken = localStorage.getItem("accessToken");

        const apiUrl = `${baseURL}/v1/bookings/getAllBookings/${restroId}/${formatDateToYYYYMMDD(theDate)}`;

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch slots. Status: ${response.status}`);
        }

        const data = await response.json();

        setBookings(data.bookings || []);

        setTotalBookingsCount(data.bookings.length);

        // Filter bookings based on status
        const acceptedBookings = data.bookings.filter(
          (booking: { status: string }) =>
            booking?.status?.toLowerCase?.() === "confirmed" ||
            booking?.status?.toLowerCase?.() === "active",
        );

        // Set the accepted bookings count and array
        setAcceptedBookingsCount(acceptedBookings.length || 0);
        setAcceptedBookings(acceptedBookings);

        // Filter bookings based on status
        const pendingBookings = data.bookings.filter(
          (booking: { status: string }) =>
            booking?.status?.toLowerCase?.() ===
            "pending_merchant_confirmation",
        );

        // Set the accepted bookings count and array
        setPendingBookingsCount(pendingBookings.length || 0);
        setPendingBookings(pendingBookings);

        // Filter bookings based on status
        const declinedBookings = data.bookings.filter(
          (booking: { status: string }) =>
            booking?.status?.toLowerCase?.() === "declined_by_merchant",
        );

        // Set the declined bookings count and array
        setDeclinedBookingsCount(declinedBookings.length || 0);
        setDeclinedBookings(declinedBookings);

        // Filter bookings based on status
        const cancelledBookings = data.bookings.filter(
          (booking: { status: string }) =>
            booking?.status?.toLowerCase?.() === "canceled",
        );

        // Set the declined bookings count and array
        setCancelledBookingsCount(cancelledBookings.length || 0);
        setCancelledBookings(cancelledBookings);

        if (newTab === "declined") {
          setCurrentTab("Declined");
        } else if (newTab === "accepted") {
          setCurrentTab("Accepted");
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    }
  };

  const acceptBooking = async (id: string) => {
    if (id !== "") {
      try {
        const authToken = localStorage.getItem("accessToken");
        const apiUrl = `${baseURL}/v1/bookings/acceptABooking`;

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ idOfBooking: id }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to accept booking. Status: ${response.status}`,
          );
        }

        const data = await response.json();

        console.log("data:", data);

        notifyAccepted();

        if (data) {
          fetchBookings(restaurantId, date, "accepted");
        }
      } catch (error) {
        console.error("Error accepting booking:", error);
        acceptFailed();
      }
    }
  };

  const declineBooking = async (id: string) => {
    if (id !== "") {
      try {
        const authToken = localStorage.getItem("accessToken");
        const apiUrl = `${baseURL}/v1/bookings/declineABooking`;

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ idOfBooking: id }), // Pass id as a raw JSON body
        });

        if (!response.ok) {
          throw new Error(
            `Failed to decline booking. Status: ${response.status}`,
          );
        }

        const data = await response.json();

        notifyDeclined();

        if (data) {
          fetchBookings(restaurantId, date, "declined");
        }
      } catch (error) {
        console.error("Error declining booking:", error);
        declineFailed();
      }
    }
  };

  const changeToPrevDate = () => {
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    setDate(prevDate);

    fetchBookings(restaurantId, prevDate, "none");
  };

  const changeToNextDate = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    setDate(nextDate);

    fetchBookings(restaurantId, nextDate, "none");
  };

  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <div className="BookingManagementPage">
      <div className="filter-bar-top">
        <div className="filter-calendar-btn d-flex align-items-center fw-semibold">
          <button
            type="button"
            onClick={changeToPrevDate}
            style={{ borderWidth: 0, backgroundColor: "transparent" }}
          >
            <img src={leftArrow} style={{ width: 10 }} alt="left-arrow" />
          </button>

          <Datepicker
            value={date}
            onChange={setTheDate}
            clearIcon={null}
            calendarIcon={
              <>
                <span
                  className="me-3"
                  style={{ textTransform: "uppercase", color: "#6E6D7A" }}
                >
                  {formattedDate}
                </span>

                <FaRegCalendarAlt size={20} style={{ color: "#6E6D7A" }} />
              </>
            }
          />

          <button
            type="button"
            onClick={changeToNextDate}
            style={{ borderWidth: 0, backgroundColor: "transparent" }}
          >
            <img src={rightArrow} style={{ width: 10 }} alt="img" />
          </button>
        </div>
      </div>

      <div
        className="BookingManagementFilterLine"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <div className="BookingManagementFilterLine-l">
          <BookingFilterItem
            name="All"
            size={totalBookingsCount}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className="filterSepLine" style={{ marginTop: 5 }} />

          <BookingFilterItem
            type="five"
            name="Pending"
            size={pendingBookingsCount}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className="filterSepLine" style={{ marginTop: 5 }} />

          <BookingFilterItem
            type="two"
            name="Accepted"
            size={acceptedBookingsCount}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            style={
              currentTab === "Accepted"
                ? { backgroundColor: "grey", padding: 8, borderRadius: 10 }
                : {}
            }
          />
          <div className="filterSepLine" style={{ marginTop: 5 }} />

          <BookingFilterItem
            type="four"
            name="Declined"
            size={declinedBookingsCount}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <div className="filterSepLine" style={{ marginTop: 5 }} />

          <BookingFilterItem
            type="four"
            name="Cancelled"
            size={cancelledBookingsCount}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            style={
              currentTab === "Cancelled"
                ? { backgroundColor: "grey", padding: 8, borderRadius: 10 }
                : {}
            }
          />
        </div>

        <div
          className="filter-bar-end only-desktop"
          style={{ display: "none" }}
        >
          <div className="filter-calendar-btn d-flex align-items-center fw-semibold">
            <Datepicker
              value={date}
              onChange={setTheDate}
              clearIcon={null}
              calendarIcon={
                <>
                  <span className="me-3">
                    {date.getDate() === new Date().getDate() &&
                    date.getMonth() === new Date().getMonth() &&
                    date.getFullYear() === new Date().getFullYear()
                      ? "Today"
                      : date.toLocaleDateString()}
                  </span>
                  {Icons.Calendar}
                </>
              }
            />
          </div>
        </div>
      </div>

      <div className="BookingItem-con">
        {currentTab === "All" && (
          <>
            {pendingBookings?.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "rgb(39, 52, 60)",
                  color: "rgb(126, 202, 176)",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Pending&nbsp;&nbsp;|&nbsp;&nbsp;{pendingBookings.length || null}
              </span>
            )}

            {pendingBookings?.map?.((booking) => (
              <div
                key={booking.uuid}
                className={`BookingItem ${
                  booking.status &&
                  booking.status.toLowerCase() ===
                    "pending_merchant_confirmation"
                }`}
              >
                <div className="BookingItem-right" style={{ width: "110%" }}>
                  <div className="BookingItem-right-t">
                    <BookingDetailsitem
                      booking={booking}
                      acceptBooking={acceptBooking}
                      declineBooking={declineBooking}
                    />
                  </div>
                </div>
              </div>
            ))}

            {acceptedBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "#03A884",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Accepted&nbsp;&nbsp;|&nbsp;&nbsp;
                {acceptedBookings.length || null}
              </span>
            )}

            {acceptedBookings?.map?.((booking) => (
              <div
                key={booking.uuid}
                className={`BookingItem ${
                  (booking.status &&
                    booking.status.toLowerCase() === "confirmed") ||
                  (booking.status &&
                    booking.status.toLowerCase() === "accepted")
                }`}
              >
                <div className="BookingItem-right" style={{ width: "110%" }}>
                  <div className="BookingItem-right-t">
                    <BookingDetailsitem
                      booking={booking}
                      acceptBooking={acceptBooking}
                      declineBooking={declineBooking}
                    />
                  </div>
                </div>
              </div>
            ))}

            {declinedBookings?.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "#F24822",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Declined&nbsp;&nbsp;|&nbsp;&nbsp;
                {declinedBookings?.length || null}
              </span>
            )}

            {declinedBookings?.map?.((booking) => (
              <div
                key={booking.uuid}
                className={`BookingItem ${
                  booking.status &&
                  booking.status.toLowerCase() === "declined_by_merchant"
                }`}
              >
                <div className="BookingItem-right" style={{ width: "110%" }}>
                  <div className="BookingItem-right-t">
                    <BookingDetailsitem
                      booking={booking}
                      acceptBooking={acceptBooking}
                      declineBooking={declineBooking}
                    />
                  </div>
                </div>
              </div>
            ))}

            {cancelledBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "red",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Cancelled&nbsp;&nbsp;|&nbsp;&nbsp;
                {cancelledBookings.length || null}
              </span>
            )}

            {cancelledBookings?.map?.((booking) => (
              <div
                key={booking.uuid}
                className={`BookingItem ${
                  booking.status && booking.status.toLowerCase() === "canceled"
                }`}
              >
                <div className="BookingItem-right" style={{ width: "110%" }}>
                  <div className="BookingItem-right-t">
                    <BookingDetailsitem
                      booking={booking}
                      acceptBooking={acceptBooking}
                      declineBooking={declineBooking}
                    />
                  </div>
                </div>
              </div>
            ))}

            {bookings.length === 0 && (
              <div
                className="emptyBookings"
                style={{
                  marginTop: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={noResults}
                  alt="No bookings icon"
                  className="emptyBookingsIcon"
                  style={{ width: 70, height: 70 }}
                />
                <p
                  className="emptyBookingsText"
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  No bookings found for this date
                </p>
              </div>
            )}
          </>
        )}

        {currentTab === "Accepted" && (
          <>
            {acceptedBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "#03A884",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Accepted&nbsp;&nbsp;|&nbsp;&nbsp;
                {acceptedBookings.length || null}
              </span>
            )}
          </>
        )}

        {currentTab === "Accepted" &&
          (acceptedBookings.length > 0 ? (
            acceptedBookings.map((booking) => (
              <>
                <div
                  key={booking.uuid}
                  className={`BookingItem accepted ${booking.status && booking.status.toLowerCase() === "confirmed" ? "confirmed" : ""}`}
                >
                  <div className="BookingItem-right" style={{ width: "110%" }}>
                    <div className="BookingItem-right-t">
                      <BookingDetailsitem
                        booking={booking}
                        acceptBooking={acceptBooking}
                        declineBooking={declineBooking}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div
              className="emptyBookings"
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={noResults}
                alt="No bookings icon"
                className="emptyBookingsIcon"
                style={{ width: 70, height: 70 }}
              />
              <p
                className="emptyBookingsText"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                No accepted bookings found for this date
              </p>
            </div>
          ))}

        {currentTab === "Declined" && (
          <>
            {declinedBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "#F24822",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Declined&nbsp;&nbsp;|&nbsp;&nbsp;
                {declinedBookings.length || null}
              </span>
            )}
          </>
        )}

        {currentTab === "Declined" &&
          (declinedBookings.length > 0 ? (
            declinedBookings.map((booking) => (
              <>
                <div
                  key={booking.uuid}
                  className={`BookingItem declined ${booking.status && booking.status.toLowerCase() === "declined" ? "declined" : ""}`}
                >
                  <div className="BookingItem-right" style={{ width: "110%" }}>
                    <div className="BookingItem-right-t">
                      <BookingDetailsitem
                        booking={booking}
                        acceptBooking={acceptBooking}
                        declineBooking={declineBooking}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div
              className="emptyBookings"
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={noResults}
                alt="No bookings icon"
                className="emptyBookingsIcon"
                style={{ width: 70, height: 70 }}
              />
              <p
                className="emptyBookingsText"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                No declined bookings found for this date
              </p>
            </div>
          ))}

        {currentTab === "Cancelled" && (
          <>
            {cancelledBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#fff",
                  color: "red",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Canclled&nbsp;&nbsp;|&nbsp;&nbsp;
                {cancelledBookings.length || null}
              </span>
            )}
          </>
        )}

        {currentTab === "Cancelled" &&
          (cancelledBookings.length > 0 ? (
            cancelledBookings.map((booking) => (
              <>
                <div
                  key={booking.uuid}
                  className={`BookingItem cancelled ${booking.status && booking.status.toLowerCase() === "canceled" ? "cancelled" : ""}`}
                >
                  <div className="BookingItem-right" style={{ width: "110%" }}>
                    <div className="BookingItem-right-t">
                      <BookingDetailsitem
                        booking={booking}
                        acceptBooking={acceptBooking}
                        declineBooking={declineBooking}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div
              className="emptyBookings"
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={noResults}
                alt="No bookings icon"
                className="emptyBookingsIcon"
                style={{ width: 70, height: 70 }}
              />
              <p
                className="emptyBookingsText"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                No cancelled bookings found for this date
              </p>
            </div>
          ))}

        {currentTab === "Pending" && (
          <>
            {pendingBookings.length > 0 && (
              <span
                style={{
                  textAlign: "left",
                  backgroundColor: "#27343C",
                  color: "rgb(126, 202, 176)",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  marginBottom: 20,
                  alignSelf: "flex-start",
                }}
              >
                Pending&nbsp;&nbsp;|&nbsp;&nbsp;{pendingBookings.length || null}
              </span>
            )}
          </>
        )}

        {currentTab === "Pending" &&
          (pendingBookings.length > 0 ? (
            pendingBookings.map((booking) => (
              <>
                <div
                  key={booking.uuid}
                  className={`BookingItem pending ${booking.status && booking.status.toLowerCase() === "pending" ? "pending" : ""}`}
                >
                  <div className="BookingItem-right" style={{ width: "110%" }}>
                    <div className="BookingItem-right-t">
                      <BookingDetailsitem
                        booking={booking}
                        acceptBooking={acceptBooking}
                        declineBooking={declineBooking}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div
              className="emptyBookings"
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={noResults}
                alt="No bookings icon"
                className="emptyBookingsIcon"
                style={{ width: 70, height: 70 }}
              />
              <p
                className="emptyBookingsText"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                No pending bookings found for this date
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

const handleClick = (
  clickedName: string,
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>,
) => {
  console.log(`Button ${clickedName} clicked!`);
  setCurrentTab(clickedName);
};

function BookingFilterItem({
  type,
  name,
  size,
  currentTab,
  setCurrentTab,
}: {
  type?: string;
  name: string;
  size: number;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  style?: React.CSSProperties;
}) {
  let bgColor = "#27343C";
  let fontColor = "#7ECAB0";

  if (currentTab === "Accepted") {
    bgColor = "#03A884";
    fontColor = "#fff";
  } else if (currentTab === "Pending") {
    bgColor = "#27343C";
    fontColor = "#7ECAB0";
  } else if (currentTab === "Cancelled") {
    bgColor = "red";
    fontColor = "#fff";
  } else if (currentTab === "Declined") {
    bgColor = "#F24822";
    fontColor = "#fff";
  }

  return (
    <button
      type="button"
      className={`BookingManagementFilter ${type}`}
      onClick={() => handleClick(name, setCurrentTab)}
      style={{
        backgroundColor: currentTab === name ? bgColor : "white",
        color: currentTab === name ? fontColor : "#D1D1D1",
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 5,
      }}
    >
      {name}
      <div
        className="numberBooking"
        style={{
          color: currentTab === name ? fontColor : "#D1D1D1",
          fontSize: 14,
        }}
      >
        {size ? "|" : null}&nbsp;&nbsp;{size || null}
      </div>
    </button>
  );
}

function formatTime(timeString: string) {
  return dayjs(`2000-01-01T${timeString}`).format("HH:mm");
}

function BookingDetailsitem({
  booking,
  acceptBooking,
  declineBooking,
}: {
  booking: BookingType;
  acceptBooking: (id: string) => void;
  declineBooking: (id: string) => void;
}) {
  return (
    <div>
      <div className="onlyMobile" style={{ flexDirection: "column" }}>
        <div
          style={{
            background: "#fff",
            padding: 10,
            borderRadius: 5,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div
            className="BookingItem-right-t-Item"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="BookingItem-left-item"
              style={{
                width: "36%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={notes}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span style={{ width: "auto", height: "auto", fontSize: 10 }}>
                #{booking.bookingId}
              </span>
            </div>

            <img src={verticalDivider} style={{ height: 16 }} alt="img" />

            <div
              className="BookingItem-left-item"
              style={{
                width: "36%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={calendar}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking.slot.slotDate}
              </span>
            </div>

            <img src={verticalDivider} style={{ height: 16 }} alt="img" />

            <div
              className="d-flex align-items-center"
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={partysize}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.partySize}
              </span>
            </div>

            <div
              className="d-flex align-items-center"
              style={{
                width: "36%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={user}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.bookingUserFullName}
              </span>
            </div>

            <img
              src={verticalDivider}
              style={{ height: 16, marginTop: 11 }}
              alt="img"
            />

            <div
              className="d-flex align-items-center"
              style={{
                width: "36%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={phone}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.phoneNumber}
              </span>
            </div>

            <img
              src={verticalDivider}
              style={{ height: 16, marginTop: 11 }}
              alt="img"
            />

            <div
              className="d-flex align-items-center"
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={clock}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />
              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.slot &&
                  formatTime(booking.slot.slotStartTime).toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        <div className="BookingItem-right-b">
          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-green"
              onClick={() => acceptBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Confirm Booking
            </button>
          )}

          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-red"
              onClick={() => declineBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Decline Booking
            </button>
          )}
        </div>

        <div className="buttons-phone" style={{ justifyContent: "flex-start" }}>
          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-green"
              style={{
                width: 100,
                height: 15,
                background: "#03A884",
                borderWidth: 0,
              }}
              onClick={() => acceptBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Accept
            </button>
          )}

          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-red"
              style={{ width: 100, height: 15, marginLeft: 15 }}
              onClick={() => declineBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Decline
            </button>
          )}
        </div>
      </div>

      <div className="onlyDesktop" style={{ flexDirection: "column" }}>
        <div
          style={{
            background: "#fff",
            padding: 10,
            borderRadius: 5,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div
            className="BookingItem-right-t-Item"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="BookingItem-left-item"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={notes}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span style={{ width: "auto", height: "auto", fontSize: 10 }}>
                #{booking.bookingId}
              </span>
            </div>

            <img src={verticalDivider} style={{ height: 16 }} alt="img" />

            <div
              className="BookingItem-left-item"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={calendar}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking.slot.slotDate}
              </span>
            </div>

            <img src={verticalDivider} style={{ height: 16 }} alt="img" />

            <div
              className="d-flex align-items-center"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={partysize}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.partySize}
              </span>
            </div>

            <div
              className="d-flex align-items-center"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={user}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.bookingUserFullName}
              </span>
            </div>

            <img
              src={verticalDivider}
              style={{ height: 16, marginTop: 11 }}
              alt="img"
            />

            <div
              className="d-flex align-items-center"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={phone}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />

              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.phoneNumber}
              </span>
            </div>

            <img
              src={verticalDivider}
              style={{ height: 16, marginTop: 11 }}
              alt="img"
            />

            <div
              className="d-flex align-items-center"
              style={{
                width: "31%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <img
                src={clock}
                width={21}
                height={21}
                style={{ marginLeft: 8, marginRight: 8 }}
                alt="img"
              />
              <span
                style={{
                  marginLeft: 3,
                  width: "auto",
                  height: "auto",
                  fontSize: 10,
                }}
              >
                {booking?.slot &&
                  formatTime(booking.slot.slotStartTime).toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        <div
          className="BookingItem-right-b"
          style={{ marginBottom: 20, marginTop: 10 }}
        >
          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-green"
              onClick={() => acceptBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Confirm Booking
            </button>
          )}

          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-red"
              onClick={() => declineBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Decline Booking
            </button>
          )}
        </div>

        <div className="buttons-phone" style={{ justifyContent: "flex-start" }}>
          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-green"
              style={{
                width: 100,
                height: 15,
                background: "#03A884",
                borderWidth: 0,
              }}
              onClick={() => acceptBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Accept
            </button>
          )}

          {booking.status.toLowerCase() === "pending_merchant_confirmation" && (
            <button
              type="button"
              className="custom-btn btn-red"
              style={{ width: 100, height: 15, marginLeft: 15 }}
              onClick={() => declineBooking(booking.id)}
              disabled={
                booking.status &&
                (booking.status.toLowerCase() === "confirmed" ||
                  booking.status.toLowerCase() === "canceled" ||
                  booking.status.toLowerCase() === "declined_by_merchant")
              }
            >
              Decline
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BookingManagement;
