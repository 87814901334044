// @ts-nocheck
import React, { useState, useEffect, CSSProperties } from "react";
import classes from "../styleSheets/fastSocialVerification.module.css";
import dgMenu from "../styleSheets/menuPlans.module.css";
import backButton from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import line from "../assets/icons/svgCommonIcons/headerLine.svg";
import { Grid } from "@mui/material";
import { getMenuPlanDetails } from "../API/MenuApi";
import {
  CURRENT_BALANCE_API,
  WALLET_SERVICES_API,
  baseURL,
} from "../constants/Api";
import divider from "../assets/icons/svgSpecificIcons/divider.svg";
import { useNavigate } from "react-router-dom";
import { USER } from "../constants/localStorageKeys";
import { getbalance } from "../API/WalletAPIs";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import RechargeAmount from "../payment/RechargeAmount";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { digitalMenu } from "../API/WalletServicesAPI";
import tick from "../assets/images/images/tick.svg";
import ClipLoader from "react-spinners/ClipLoader";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#7ECAB0",
};
const DigitalMenuPlans = () => {
  const [planData, setPlanData] = useState([]);
  const [balance, setBalance] = useState("");
  const navigate = useNavigate();
  const profileId = localStorage.getItem(USER.businessId);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const businessUserName = localStorage.getItem(USER.USERNAME);
  const handleConfirmButton = async () => {
    if (balance < 371) setOpenModal(true);
    else {
      setOpenModal1(true);
      setLoading(true);
      const digitalMenuResponse = await digitalMenu(navigate);
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleClose1 = () => {
    setOpenModal1(false);
    localStorage.setItem("menu-back-route", "/QuickAccess");
    navigate(`/edit/menu/${businessUserName}`);
    /* navigate("/DigitalMenuActivation"); */
  };
  const callApi = async () => {
    const planDetails = await getMenuPlanDetails(
      `${baseURL}${WALLET_SERVICES_API.GET_MENU_PLAN_DETAILS}`,
    );

    setPlanData(planDetails);
    const balanceResponse = await getbalance(
      `${baseURL}${CURRENT_BALANCE_API.CURRENT_BALANCE}/?id=${profileId}`,
      navigate,
    );
    setBalance(balanceResponse?.balance);
    console.log(balance);
  };
  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/DigitalMenuPlans`, "DigitalMenuPlans Page");
  }, []);

  return (
    <>
      <TopHeader name="Price Detail" />
      <div className={dgMenu.mainContainer}>
        {/* <Grid container spacing={0}>
        <Grid item xs={2} onClick={()=>{navigate('/QuickAccess')}}><img  className={classes.backButton}src={backButton}/></Grid>
        <Grid item xs={8}className={classes.headerText}>Price Details</Grid>
        </Grid>  */}
        <div className={dgMenu.subContainer}>
          <Grid container>
            <Grid item xs={10} className={dgMenu.headerText1}>
              Activation Charge Per Month
            </Grid>
            <Grid item xs={2} className={dgMenu.headerText2}>
              ₹299
            </Grid>
          </Grid>
          <div>
            <img style={{ width: "100%", marginBottom: "10px" }} src={line} />
          </div>
          <Grid container>
            <Grid item xs={5} className={dgMenu.headerText3}>
              Plan
            </Grid>
            <Grid item xs={5} className={dgMenu.headerText3}>
              No. of dishes
            </Grid>
            <Grid item xs={2} className={dgMenu.headerText5}>
              Charge
            </Grid>
          </Grid>
        </div>
        <div className={dgMenu.plansContainer}>
          {planData?.map((item, index) => {
            return (
              <Grid container sx={{ padding: "20px 0 0" }} key={index}>
                <Grid item xs={5} className={dgMenu.tableText}>
                  {item?.planName}
                </Grid>
                {!item?.maxDishes ? (
                  <Grid item xs={5} className={dgMenu.tableText}>
                    Above {item?.minDishes - 1}{" "}
                  </Grid>
                ) : (
                  <Grid item xs={5} className={dgMenu.tableText}>
                    {item?.minDishes} - {item?.maxDishes}
                  </Grid>
                )}
                <Grid className={dgMenu.tableText} item xs={2}>
                  {item?.charges}
                </Grid>
                {item?.maxDishes ? (
                  <img
                    style={{ margin: "20px 0 0 0", width: "100%" }}
                    src={divider}
                  />
                ) : (
                  <div style={{ padding: "20px 0  " }}></div>
                )}
              </Grid>
            );
          })}
        </div>
        <div className={dgMenu.textContainer}>
          Menu prices will be automatically charged as per the number of
          different dish count in the menu. By tapping “Next”, you agree that
          the information you provide for your eatoes digital menu is subject to
          our <a className={dgMenu.linkText}>Privacy Policy</a>
        </div>
        <button className={dgMenu.button} onClick={handleConfirmButton}>
          Agree and Continue
        </button>
        {openModal && (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={customizeMUIComponents.lowBalanceModal}>
              <div className={classes.paddingModal}>
                <div className={classes.modalTitle}>
                  Attention: Low Balance Alert!
                </div>
                <div>
                  <div className={classes.modalContent}>
                    Your account balance is running low. To continue enjoying
                    our services and access the full menu, it's time to recharge
                    your account. Don't miss out on the amazing features and
                    options we offer!
                  </div>
                  <div className={classes.modalContent}>
                    Your current balance may restrict access to certain menu
                    items. To ensure uninterrupted service, please recharge your
                    account now.{" "}
                  </div>
                  <div className={classes.modalContent}>
                    Thank you for choosing Eatoes. We appreciate your continued
                    support!
                  </div>
                </div>
                <div className={classes.buttonDiv}>
                  <button
                    className={classes.rechargeButton}
                    onClick={() => {
                      setOpenModal(false);
                      RechargeAmount();
                      navigate("/wallet");
                    }}
                  >
                    Recharge Now
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        )}
        {openModal1 && (
          <Modal open={openModal1} onClose={handleClose1}>
            <Box sx={customizeMUIComponents.lowBalanceModal}>
              <div className={classes.paddingModal}>
                {loading ? (
                  <>
                    <ClipLoader
                      color={"#40525D"}
                      loading={loading}
                      cssOverride={override}
                      size={150}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>
                ) : (
                  <>
                    <div className={classes.centerDiv}>
                      <img
                        style={{
                          width: "130px",
                          height: "130px",
                          marginBottom: "20px",
                        }}
                        src={tick}
                      />
                    </div>
                    <div className={classes.modal_text}>Payment</div>
                    <div className={classes.modal_text}>successful</div>
                  </>
                )}
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

export default DigitalMenuPlans;
