// @ts-nocheck
import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import AnalyticsUtil from "../../../utils/GoogleAnalytics";
import { AnalyticEvents } from "../../../utils/GoogleAnalytics/Events";

export const MuiSwitch = ({
  thumbColor,
  borderColor,
  trackColor,
  switchState,
  setSwitchState,
  type,
  label,
  analytics,
  setAnalytics,
}) => {
  const handleSwitchUpdate = () => {
    setSwitchState((prevState) => {
      analytics.sendEvent(
        AnalyticEvents.Menu.Filter,
        AnalyticEvents.MenuAction.Selected,
        label,
      );
      return {
        ...prevState,
        [type]: !prevState[type],
      };
    });
  };

  return (
    <div>
      <Switch
        checked={switchState}
        // className="mt-1"
        onChange={(e) => handleSwitchUpdate(e)}
        sx={{
          ".MuiSwitch-thumb": {
            backgroundColor: thumbColor,
            border: `2px solid ${borderColor}`,
          },
          ".MuiSwitch-track": {
            backgroundColor: "white",
            opacity: "1",
          },
          ".Mui-checked + .MuiSwitch-track": {
            backgroundColor: `${trackColor} !important`,
            opacity: "1 !important",
          },
        }}
      />
      <div
        className="text-center"
        style={{ fontSize: "10px", marginTop: "-6px" }}
      >
        {label}
      </div>
    </div>
  );
};
