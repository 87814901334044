import { useEffect, useState } from "react";
import "../index.css";
import { USER } from "../constants/localStorageKeys";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const LandingPage = () => {
  const [authenticated, setauthenticated] = useState<string | null>(null);

  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
    if (savedToken) {
      setauthenticated(savedToken);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/Home`, "Landing Page");
  }, []);

  return (
    <iframe
      src={"https://www.connectapp.in"}
      title={"title"}
      width={"100%"}
      style={{ border: "none", height: "100vh" }}
      allowFullScreen
    />
  );
};
export default LandingPage;
