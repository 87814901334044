// @ts-nocheck

import { arrayMove } from "@dnd-kit/sortable";
import { MenuDragDropApi } from "../../API/Drag_Drop/menu_drag_drop_api";
import { setApiData } from "../../redux/actions";
import {AutoScrollActivator} from "@dnd-kit/core"
import {restrictToParentElement} from "@dnd-kit/modifiers"


export function areIdSequencesEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]._id.toString() !== arr2[i]._id.toString()) {
      return false;
    }
  }

  return true;
}



export const autoScrollProp = {
  
    enabled: true,
    layoutShiftCompensation: {
      x: true,
      y: true
    },
    activator: AutoScrollActivator.DraggableRect,
    acceleration: 4
  
}

export const modifierProp = [restrictToParentElement]


export const handleDragStart = (event, setActiveId) => {
  setActiveId(event.active.id);
}

export const handleDragEnd = async(setActiveId, oldData, setOldData, newData, type, navigate, setLoadingState, dispatch) => {
  setActiveId(null);


    const ifDataSame = areIdSequencesEqual(oldData, newData)
    if(ifDataSame) return

    setLoadingState(true)
    const dataReceived = await MenuDragDropApi(type, oldData, newData, navigate)
    setLoadingState(false)
    setOldData(dataReceived?.body?.menu)    
    if(type == "category" || type == "carousel"){
      dispatch(setApiData(dataReceived?.body?.menu))
    }
}

export function handleDragOver(event, setItems) {
  const {active, over} = event;

  if (active.id !== over.id) {
    setItems((items) => {
      const oldIndex = items.findIndex((i) => i._id === active.id._id);
      const newIndex = items.findIndex((i) => i._id === over.id._id);
      return arrayMove(items, oldIndex, newIndex);
    });
  }
}