import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createBookingAPI } from "../../API/tableReserve/tableReserveAPI";
import { TncModal } from "../tncModal";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
interface ComponentProps {
  reserveId: string;
}

export const UserInfo = ({ reserveId }: ComponentProps) => {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: localStorage.getItem("adminName") || "",
    mobileNum: localStorage.getItem("usermobilenumber") || "",
    email: "",
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const onChangeHandler = (e: any, targetKey: string) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [targetKey]: e.target.value,
    }));
  };

  const onSubmitHandler = async () => {
    const dataReceived = await createBookingAPI(
      state.selectedDate,
      state.selectedPeople,
      state.selectedTime,
      reserveId,
      userInfo,
      navigate,
    );

    navigate("/my-bookings");
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`reserve/user-info`, "UserInfo Page");
  }, []);

  return (
    <div className="mt-5 d-flex flex-column" style={{ gap: "21px" }}>
      <div className="user-info-name-div">
        <input
          value={userInfo.name}
          // onChange={(e) => onChangeHandler(e, "name")}
          className="user-info-input"
          placeholder="Enter full name"
          readOnly
          style={{ cursor: "not-allowed" }}
        />
      </div>

      <div className="user-info-name-div">
        <input
          value={userInfo.mobileNum}
          // onChange={(e) => onChangeHandler(e, "mobileNum")}
          className="user-info-input"
          placeholder="Enter mobile number"
          readOnly
          style={{ cursor: "not-allowed" }}
        />
      </div>

      <div className="user-info-name-div">
        <input
          value={userInfo.email}
          onChange={(e) => onChangeHandler(e, "email")}
          className="user-info-input"
          placeholder="Enter email id"
        />
      </div>

      <div className="user-info-tnc-div">
        Moving next, you agree to all{" "}
        <span onClick={() => setOpen(true)}>TnC’s</span>
      </div>

      <div className="user-info-submit-btn-div">
        <button
          onClick={() => {
            // ONLY USED FOR TESTING PURPOSE
            // navigate("../temp-route", {
            //   state: { ...state, userInfo },
            // });

            onSubmitHandler();
          }}
        >
          Submit Request
        </button>
      </div>

      <TncModal open={open} setOpen={setOpen} />
    </div>
  );
};
