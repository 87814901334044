//@ts-nocheck
import React from "react";
import rightArrow from "../assets/icons/Social_icons/solidArrow.svg";
import dotArrow from "../assets/icons/Social_icons/dot.svg";
import "../styleSheets/Bio_page.css";
import "../styleSheets/medipage.css";
import "../styleSheets/AddLinksPage.css";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { createSearchParams, useNavigate } from "react-router-dom";
import Menu_box from "./Menu_box";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import { GreenButton } from "../Components/Button.style";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { useEffect } from "react";

function Media_page() {
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/Photospage`, "Photos Page");
  }, []);
  return (
    <div className=" Socialformpage d-flex justify-content-center">
      <div className="mobile-pc-width mx-auto">
        <div className="d-flex container m-auto mb-5 social-profile-header">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <KeyboardBackspaceIcon
              className="back-arrow-click ms-2"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="mx-auto">
              <h1 className="socio-prof-header me-3">
                {api?.["Basic Details"]?.account?.userName} | Media
              </h1>
            </div>
          </div>
        </div>

        <div className="mx-3">
          <div className="categories mx-3 mt-4">All media categories</div>

          {/*this is the media div ------------------------   */}
          {api["Media Section"]?.mediaCategories?.map((data, index) => (
            <div key={index} className="mx-3 mt-3 d-flex">
              <div
                className="box   d-flex   align-items-center cursor-pointer"
                onClick={() =>
                  navigate({
                    pathname: "/FastSocial/Photoaddpage",
                    search: createSearchParams({
                      name: data.name,
                      categoryId: data._id,
                      accountId: data.accountId,
                    }).toString(),
                  })
                }
              >
                <span className=" text-center me-auto mx-2">{data.name}</span>
                <div className="d-flex pe-3 ">
                  <img src={rightArrow} className="img-fluid" />
                </div>
              </div>

              <div className="dot">
                <Menu_box
                  mediaCategoryId={data._id}
                  options={[
                    {
                      optionName: "Rename",
                      optionType: "model",
                    },
                  ]}
                  image={dotArrow}
                  optionValue={data.name}
                />
              </div>
            </div>
          ))}
          <div className=" ms-5 mt-5 d-flex justify-content-center align-items-center ">
            <GreenButton
              backgroundColor="#7ECAB0"
              varient="contained"
              className="text-black Link-savebutton"
              onClick={handleOpenDialog}
            >
              Save
            </GreenButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media_page;
