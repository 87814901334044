//@ts-nocheck
import { useState } from "react";

import Pop_up_2option from "../utils/Pop_up_2option";
import Poptab from "./Photo_tab";
import { useSelector } from "react-redux";
import { USER } from "../constants/localStorageKeys";

const ContactPhotoSection = () => {
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const rolesData = useSelector((state) => state.roles.apiData);

  let name;
  rolesData.responseData
    ? (name = [
        {
          name: "Edit Photos",
          route: `/FastSocial/Photospage?accountId=${api?.["Media Section"]?.mediaCategories[0]?.accountId}`,
        },
      ])
    : (name = []);
  if (Object.keys(api).length === 0) {
    return;
  }
  return (
    <div>
      {api["Media Section"]?.media?.length !== 0 && (
        <div className="mx-3" id="photosection">
          <div className="card rounded-4 shadow">
            <div className="mx-3 my-3">
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h1 className="fw-semibold fs-1 text-white">Photos</h1>
                <div className="">
                  <Pop_up_2option name={name} id={"photosection"} />
                </div>
              </div>
              <Poptab />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactPhotoSection;
