// @ts-nocheck

import React, { useEffect } from 'react'
import { Box, Modal } from '@mui/material'
import { customizeMUIComponents } from '../styleSheets/muiCustomStyles'

export const PopupModal = ({open, setOpen, message}) => {
  const handleClose = () => setOpen(false)

  useEffect(()=>{
    if(open){
      setTimeout(()=>{
        setOpen(false)
      }, 3000)
    }

  }, [open])
  
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.popupModal}>
          <div style={{ fontSize: "12px", color: "#FFF" }}>
            {message}
          </div>
        </Box>
      </Modal>
    </>
  )
}