import { color } from "@mui/system";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import wallet from "../assets/icons/svgCommonIcons/wallet.svg";
import food from "../assets/images/foodIcon.png";
// import { useNavigate } from 'react-router-dom';
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { USER } from "../constants/localStorageKeys";
import classes from "../styleSheets/Dashboard.module.css";
import { Link, NavLink } from "react-router-dom";
import BusinessProfile from "../pages/BusinessProfile";
import { setApiData, setFastSocialData } from "../redux/actions";
import { getbusinessProfiles } from "../API/authAPIs";
import { MENU_API, baseURL } from "../constants/Api";
import { getUserProfile } from "../API/authMenuAPIs";
// import Link from '@mui/material/Link';
import newWalletIcon from "../assets/new_assets/1.png";
import fastSocialIcon from "../assets/new_assets/2.png";
import newMenuIcon from "../assets/new_assets/3.png";
import axios from "axios";

type SidebarProps = {
  businessName: string;
  setBusinessName: React.Dispatch<React.SetStateAction<string>>;
  selectedProfile: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProfile: React.Dispatch<React.SetStateAction<string>>;
  businessProfileName: string;
  setBusinessProfileName: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  businessUserName: string;
  setBusinessUserName: React.Dispatch<React.SetStateAction<string>>;
  className: string;
};

const Sidebar = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  id,
  setId,
  businessUserName,
  setBusinessUserName,
  className,
}: Partial<SidebarProps>) => {
  const profileUniqueIdentifier = localStorage.getItem(USER.USERNAME);
  const profileName = localStorage.getItem(USER.businessName);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [restaurantAvailable, setRestaurantAvailable] = useState(false);
  const { pathname, search } = useLocation();

  const conditionalReduxClear = (presentState: string) => {
    if (pathname === presentState) return;
    dispatch(setFastSocialData({}));
  };
  const [admin, setAdmin] = useState();
  const [profile, setProfile] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const [data, profData] = await Promise.all([
        getbusinessProfiles(`${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`),
        getUserProfile(`${baseURL}${MENU_API.GET_USERS_PROFILE}`, navigate),
      ]);
      setAdmin(profData);
      setProfile(data);
      console.log("profiledata", data);
    };

    fetchData();
  }, [navigate]); // Empty dependency array to only run once on mount

  const currentPath = window.location.pathname;

  const isCurrent = (path: string) => {
    return currentPath === path;
  };

  const buttonStyle = (path: string): React.CSSProperties => ({
    color: isCurrent(path) ? "#fff" : "#08C8CD",
    fontSize: 16,
    marginLeft: 40,
    marginTop: 7,
    backgroundColor: isCurrent(path) ? "#08C8CD" : "transparent",
    borderWidth: 0,
    textAlign: "left",
    padding: 5,
  });

  const checkRestaurantForProfile = async () => {
    try {
      const profileId = localStorage.getItem(USER.businessId);

      // Make the API call
      const response = await axios.get(
        `${baseURL}/v1/restaurant/checkRestaurantForProfile/${profileId}`,
      );

      // Check the result and update the state
      if (response.data?.restaurant) {
        setRestaurantAvailable(true);
        localStorage.setItem("restaurantId", response.data.restaurant.id);
      } else {
        setRestaurantAvailable(false);
      }
    } catch (error) {
      console.error("Error checking restaurant for profile:", error);
      // Handle errors if needed
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    checkRestaurantForProfile();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(
    () => {
      checkRestaurantForProfile();
    },
    [
      /* selectedProfile */
    ],
  );

  return (
    <div
      className={classes.sidebar}
      style={{ background: "#FFFFFF", paddingTop: "1vh" }}
    >
      <div className={classes.sidebarWidth}>
        <div
          style={{
            paddingTop: "4em",
            paddingLeft: "3vw",
            display: "flex",
            flexDirection: "column",
            border: "0.5px",
            justifyContent: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "18px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
              setId?.("1");
            }}
            onKeyUp={() => false}
          >
            <>
              <img
                // src={wallet}
                src={newWalletIcon}
                style={{
                  marginRight: "7px",
                  marginTop: "2px",
                  width: "30px",
                  height: "30px",
                }}
                alt="Wallet"
              />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0D0C22",
                  textDecoration: "none",
                  lineHeight: "2",
                }}
              >
                Wallet{" "}
              </div>
            </>
          </div>
          {
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setApiData({}));
                localStorage.setItem("menu-back-route", pathname + search);
                navigate(`/edit/menu/${localStorage.getItem("RestrauntName")}`);
                setId?.("2");
              }}
              onKeyUp={() => false}
            >
              <img
                src={newMenuIcon}
                style={{
                  marginRight: "7px",
                  marginTop: "2px",
                  width: "30px",
                  height: "30px",
                }}
                alt="menu"
              />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0D0C22",
                  textDecoration: "none",
                  cursor: "pointer",
                  lineHeight: "2",
                }}
              >
                Menu{" "}
              </div>
            </div>
          }

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "18px",
              cursor: "pointer",
            }}
            onClick={() => {
              conditionalReduxClear(`/${profileUniqueIdentifier}`);
              navigate(`/${profileUniqueIdentifier}`);
              setId?.("3");
            }}
            onKeyUp={() => false}
          >
            <img
              src={fastSocialIcon}
              style={{
                marginRight: "7px",
                marginTop: "2px",
                width: "30px",
                height: "30px",
              }}
              alt="social"
            />
            <div
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#0D0C22",
                textDecoration: "none",
                cursor: "pointer",
                margin: "auto 0",
              }}
            >
              {profileName}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "18px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (restaurantAvailable) {
                navigate("/reservation/manage-slots");
                setId?.("3");
              } else {
                navigate("/reservation");
              }
            }}
            onKeyUp={() => false}
          >
            <>
              <img
                src={food}
                style={{
                  marginRight: "7px",
                  marginTop: "2px",
                  width: "25px",
                  height: "20px",
                }}
                alt="food"
              />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0D0C22",
                  textDecoration: "none",
                }}
              >
                Booking Management
              </div>
            </>
          </div>

          {restaurantAvailable && (
            <>
              <button
                type="button"
                style={buttonStyle("/reservation/bookings")}
                onClick={() => navigate("/reservation/bookings")}
              >
                Booking Management
              </button>
              <button
                type="button"
                style={buttonStyle("/reservation/manage-slots")}
                onClick={() => navigate("/reservation/manage-slots")}
              >
                Slot Management
              </button>
              <button
                type="button"
                style={buttonStyle("/reservation/manage-working-hours")}
                onClick={() => navigate("/reservation/manage-working-hours")}
              >
                Working Hours
              </button>
              <button
                type="button"
                style={buttonStyle("/reservation/profile")}
                onClick={() => navigate("/reservation/profile")}
              >
                Profile
              </button>

              <button
                type="button"
                style={buttonStyle("/reservation/terms-and-conditions")}
                onClick={() => navigate("/reservation/terms-and-conditions")}
              >
                Terms & Conditions
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
