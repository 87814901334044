// @ts-nocheck
import React from "react"
import { CircularProgress } from "@mui/material"

export const HomePageLoader = ({ isLoading }) => {
  return (
    <div className="position-absolute d-flex justify-content-center align-items-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "10%" }}>

      {isLoading && <CircularProgress style={{ width: "100%", height: "100%", color: "white" }} />}
    </div>
  )
}