// @ts-nocheck
import React, { useState, useEffect } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import ItemSection from "./ItemSection";
import { MENU_API, baseURL } from "../../constants/Api";
import MenuModal from "../Modals Layout/MenuModal";
import { USER } from "../../constants/localStorageKeys";
import "../../styleSheets/itemsection-header.css";
import { useSelector } from "react-redux";
const Items = (id: any) => {
  const menuAccessState = useSelector(state => state.menuAccess.apiData )
  const [data1, setData1] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const token = localStorage.getItem("accessToken");
  const name = localStorage.getItem("name");
  const resName = localStorage.getItem(USER.USERNAME);

  const fetchData = () => {
    setData1(id?.data?.items);
  };
  useEffect(() => {
    fetchData();
  }, [id?.data]);

  var arr = [];
  var itemId = id?.id;
  //  var id1= id.id1.id
  if (itemId == "" || id?.id1?.id != id?.id2) {
    itemId = id?.a;
  }
  {
    data1?.map((item) => {
      if (itemId == item.subCategory_id) {
        arr.push(item);
      }
    });
  }
  useEffect(() => {
    const filteredItems = data1?.filter(item => itemId == item.subCategory_id);
    setItemCount(filteredItems?.length)
  }, [data1, itemId]);
  return (
    <>
      <div
        // className={classes.column}
        style={{ overflow: "hidden" }}
      >
        
        <ItemSection
          setIsDeleted={setIsDeleted}
          setItemCount={setItemCount}
          id={itemId}
          data={id?.data}
          setWholeData={id?.setWholeData}
        />
      </div>
    </>
  );
};

export default Items;
