import React, { Children, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logout } from "./Logout";
import { USER } from "../constants/localStorageKeys";
import { Terms } from "../pages/T&C";

import { Modal, Box, AppBar, Toolbar } from "@mui/material";
import { CssBaseline } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import logo from "../assets/images/logo.png";
import bellIcon from "../assets/icons/svgSpecificIcons/bellIcon.svg";
import accountIcon from "../assets/icons/svgSpecificIcons/accountIcon.svg";
import swal from "sweetalert2";
// import { USER } from '../constants/localStorageKeys'

import MenuItem from "@mui/material/MenuItem";
import { baseURL } from "../constants/Api";
import { MENU_API } from "../constants/Api";
import lgcard from "../styleSheets/logout.module.css";
import classes from "../styleSheets/Dashboard.module.css";
import { getbusinessProfiles } from "../API/authMenuAPIs";
import newLogo from "../assets/new_assets/4.png";

import BusinessProfileDropdown from "./BusinessProfileDropdown";

type NavbarProps = {
  businessName: string;
  setBusinessName: React.Dispatch<React.SetStateAction<string>>;
  selectedProfile: string;
  setSelectedProfile: React.Dispatch<React.SetStateAction<string>>;
  businessProfileName: string;
  setBusinessProfileName: React.Dispatch<React.SetStateAction<string>>;
  businessUserName: string;
  setBusinessUserName: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  profileType: string;
  setProfileType: React.Dispatch<React.SetStateAction<string>>;
  profile?: string;
  setProfile?: React.Dispatch<React.SetStateAction<string>>;
  fetchProfile?: string;
  setFetchProfile?: React.Dispatch<React.SetStateAction<string>>;
};

const Navbar = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  profileType,
  setProfileType,
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
}: NavbarProps) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const isMobile = window.innerWidth <= 900;

  // const navigate = useNavigate();
  // const resName = localStorage.getItem(USER.USERNAME);

  console.log("id", id);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ outline: "none" }} className={lgcard.card_wallet}>
          <Logout
            businessName={businessName}
            setBusinessName={setBusinessName}
            profile={profile}
            setProfile={setProfile}
            fetchProfile={fetchProfile}
            setFetchProfile={setFetchProfile}
          />
        </Box>
      </Modal>

      <CssBaseline />
      <div className={classes.sidebar}>
        <AppBar
          style={{
            background: "#F7F7F7",
            fontSize: "1vw",
            fontWeight: 500,
            // width: "100vw",
            border: "0.7px solid #D1D1D1",
            padding: "0.5em 0 0.5em 0 ",
            position: "sticky",
          }}
          className="z-0"
        >
          <Toolbar style={{ paddingLeft: "3vw", paddingRight: "3vw" }}>
            <img
              src={newLogo}
              style={{ height: "60px", width: "89px", objectFit: "contain" }}
              alt="logo"
            />

            <div style={{ display: "flex", marginLeft: "auto" }}>
              <BusinessProfileDropdown
                businessName={businessName}
                setBusinessName={setBusinessName}
                selectedProfile={selectedProfile}
                setSelectedProfile={setSelectedProfile}
                businessProfileName={businessProfileName}
                setBusinessProfileName={setBusinessProfileName}
                businessUserName={businessUserName}
                setBusinessUserName={setBusinessUserName}
                id={id}
                profileType={profileType}
                setProfileType={setProfileType}
                profile={profile}
                setProfile={setProfile}
              />
              {/* <img
                src={bellIcon}
                className="ms-5"
                style={{ height: "50px", width: "50px", cursor: "pointer" }}
              /> */}

              <img
                src={accountIcon}
                style={{
                  height: "50px",
                  width: "50px",
                  marginLeft: "10px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                className="ms-5"
                onClick={handleOpen}
                onKeyUp={()=> false}
                alt="account"
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default Navbar;
