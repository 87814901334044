//@ts-nocheck
import React from 'react';
import EventCard from './EventCard';


const events = [
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://cdn-p.smehost.net/sites/a6700d2fbaf642099802a57af8b10fe6/wp-content/uploads/2023/10/21-768x768.jpg',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf8mLZvBhmYbDCSYayr0yB6B0O9Xv0CZjsZw&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://cdn-p.smehost.net/sites/a6700d2fbaf642099802a57af8b10fe6/wp-content/uploads/2023/10/21-768x768.jpg',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf8mLZvBhmYbDCSYayr0yB6B0O9Xv0CZjsZw&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://cdn-p.smehost.net/sites/a6700d2fbaf642099802a57af8b10fe6/wp-content/uploads/2023/10/21-768x768.jpg',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf8mLZvBhmYbDCSYayr0yB6B0O9Xv0CZjsZw&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  {
    title: 'DJ Night by Stephen',
    description: 'By Iron Hill Brewery',
    buttonLabel: 'Buy Now',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBft4lGkS8_piMFBlUt7TqG7JfqcwEA6ShGQ&s',
  },
  // Add more events as needed
];

const UpcomingEvents = () => {
  return (
    <div style={styles.upcomingEventsContainer}>
      <div style={styles.header}>
        <h2 style={styles.headerTitle}>Upcoming Events</h2>
        <a href="/events" style={styles.viewAllLink}>View all</a>
      </div>
      <div style={styles.eventsGrid}>
        {events.map((event, index) => (
          <EventCard
            key={index}
            title={event.title}
            description={event.description}
            buttonLabel={event.buttonLabel}
            image={event.image}
            style={styles.eventCard} // Apply the eventCard style here
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  upcomingEventsContainer: {
    padding: '20px',
   
    borderTop:'1px solid #E0E0E0',
    marginTop:'35px',
  },
  header: { 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: '600',
  },
  viewAllLink: {
    color: '#1a73e8',
    fontSize: '14px',
    fontWeight:'600',     
    textDecoration: 'none',
},

  eventsGrid: {
    display: 'flex',
    backgroundColor:'',
    overflowX: 'scroll', // Enable horizontal scrolling
    gap: '20px',
    padding: '10px 0',
    scrollbarWidth: '0px', // For Firefox
  },
};

export default UpcomingEvents;