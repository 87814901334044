// @ts-nocheck

import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import WalletCard from "../Components/WalletCard";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Wallet from "./Wallet";
import MainMenu from "../RestaurantMenu/MainMenu";
import SubCategory from "../RestaurantMenu/SubCategoryPage/SubCategory";
import dgMenu from "../styleSheets/menuPlans.module.css";
import Transactionlist from "../Components/Transactionlist";
import Graph from "../Components/Graph";
import classes from "../styleSheets/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { USER } from "../constants/localStorageKeys";
import { Route, Link, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { baseURL } from "../constants/Api";
import { getbusinessProfiles } from "../API/authMenuAPIs";
import { MENU_API } from "../constants/Api";
import { useLocation } from "react-router-dom";

import {
  event_lists,
  header,
  like,
  search,
} from "../Social_components/MockData";
import Landing from "../Social_components/Landing";
import Event_list from "../Social_components/Event_list";
import Search from "../Social_components/Search";
import LikePage from "../Social_components/LikePage";
import PrivateRoutes from "../utils/PrivateRoutes";
import CircularUnderLoad from "../Components/CircularLoader";
import ProfileFooter from "../Components/ProfileFooter";
import { useDispatch, useSelector } from "react-redux";
import AccessFooter from "../Components/AccessFooter";

export default function Dashboard({
  profile,
  setProfile,
  fetchProfile,
  setFetchProfile,
  loading,
  setLoading,
}) {
  // console.log('Hey, I am Dashboard component')
  const [businessName, setBusinessName] = useState("");
  const location = useLocation();

  console.log({ location });

  const navigate = useNavigate();
  const [id, setId] = useState("1");
  const isMobile = window.screen.width;
  // console.log(isMobile,profile, "width");
  // window.history.pushState(null, 'page', window.location.href);
  // window.onpopstate = function () {
  //     window.history.go(1);
  const num = localStorage.getItem(USER.MOBILE_NUMBER);
  // console.log(num,'iddd')
  const businessId = localStorage.getItem("businessId");
  const [selectedProfile, setSelectedProfile] = useState("");
  const businessName1 = localStorage.getItem(USER.businessName);
  const businessUserName1 = localStorage.getItem(USER.USERNAME);
  const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
  const [profileType, setProfileType] = useState("");
  const [businessProfileName, setBusinessProfileName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");

  // const[loading,setLoading] =useState(false)
  //  const[profile,setProfile]=useState([])
  const callApi = async () => {
    // setLoading(true)
    const selectedProfileId = localStorage.getItem(USER.businessId);

    {
      if (selectedProfileId == null || selectedProfileId == "undefined") {
        setBusinessProfileName(profile[0]?.name);
        setBusinessUserName(profile[0]?.userName);
        setSelectedProfile(profile[0]?.profileId);
        setProfileType(profile[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, profile[0]?.userName);
        localStorage.setItem(USER.businessName, profile[0]?.name);
        localStorage.setItem(USER.businessId, profile[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, profile[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    console.log(profile, "profile");

    callApi();
  }, [profile]);

  const name2 = localStorage.getItem(USER.USERNAME);
  const userName = localStorage.getItem("adminUserName");

  return (
    <>
      {loading ? (
        <div
          className={dgMenu.centerdiv}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50%",
          }}
        >
          <div>
            <CircularUnderLoad />
          </div>
          <div>Please Wait...</div>
        </div>
      ) : (
        <>
          <section className=" ">
            {jwtToken && (
              <Navbar
                businessName={businessName}
                setBusinessName={setBusinessName}
                selectedProfile={selectedProfile}
                setSelectedProfile={setSelectedProfile}
                businessProfileName={businessProfileName}
                setBusinessProfileName={setBusinessProfileName}
                businessUserName={businessUserName}
                setBusinessUserName={setBusinessUserName}
                id={id}
                profileType={profileType}
                setProfileType={setProfileType}
                profile={profile}
                setProfile={setProfile}
                fetchProfile={fetchProfile}
                setFetchProfile={setFetchProfile}
              />
            )}
          </section>

          <section className={classes.sectionContainer}>
            {jwtToken && (
              <Sidebar
                businessName={businessName}
                setBusinessName={setBusinessName}
                selectedProfile={selectedProfile}
                setSelectedProfile={setSelectedProfile}
                businessProfileName={businessProfileName}
                setBusinessProfileName={setBusinessProfileName}
                id={id}
                setId={setId}
                businessUserName={businessUserName}
                setBusinessUserName={setBusinessUserName}
                profileType={profileType}
                setProfileType={setProfileType}
              />
            )}

            <Routes>
              <Route
                element={<PrivateRoutes toShowAccessFooterOrNot={false} />}
              >
                <Route
                  path="/"
                  element={
                    jwtToken ? (
                      <Landing
                        adminUserName={userName}
                        event={event_lists}
                        header={header}
                        businessName={businessName}
                        setBusinessName={setBusinessName}
                        selectedProfile={selectedProfile}
                        setSelectedProfile={setSelectedProfile}
                        businessProfileName={businessProfileName}
                        setBusinessProfileName={setBusinessProfileName}
                        businessUserName={businessUserName}
                        setBusinessUserName={setBusinessUserName}
                        id={id}
                        setId={setId}
                        profileType={profileType}
                        setProfileType={setProfileType}
                        profile={profile}
                        setProfile={setProfile}
                        fetchProfile={fetchProfile}
                        setFetchProfile={setFetchProfile}
                      />
                    ) : (
                      <navigate
                        to={{ pathname: "https://eatoes.com/Home" }}
                        replace
                      />
                    )
                  }
                />
                <Route
                  path="/wallet"
                  element={
                    <Wallet
                      businessName={businessName}
                      setBusinessName={setBusinessName}
                      selectedProfile={selectedProfile}
                      setSelectedProfile={setSelectedProfile}
                      businessProfileName={businessProfileName}
                      setBusinessProfileName={setBusinessProfileName}
                      id={id}
                      setId={setId}
                      businessUserName={businessUserName}
                      setBusinessUserName={setBusinessUserName}
                      profileType={profileType}
                      setProfileType={setProfileType}
                      profile={profile}
                      setProfile={setProfile}
                      fetchProfile={fetchProfile}
                      setFetchProfile={setFetchProfile}
                    />
                  }
                />
                <Route
                  path="/edit/menu/:name2"
                  element={
                    <MainMenu
                      name2={businessUserName}
                      selectedProfile={selectedProfile}
                      setSelectedProfile={setSelectedProfile}
                      setId={setId}
                      setBusinessProfileName={setBusinessProfileName}
                      setBusinessUserName={setBusinessUserName}
                    />
                  }
                />
                <Route
                  path="/edit/menu/SubCategory"
                  element={<SubCategory />}
                />
              </Route>

              <Route
                path="/:userName/*"
                element={
                  <Landing
                    event={event_lists}
                    header={header}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    selectedProfile={selectedProfile}
                    setSelectedProfile={setSelectedProfile}
                    businessProfileName={businessProfileName}
                    setBusinessProfileName={setBusinessProfileName}
                    businessUserName={businessUserName}
                    setBusinessUserName={setBusinessUserName}
                    id={id}
                    setId={setId}
                    profileType={profileType}
                    setProfileType={setProfileType}
                    profile={profile}
                    setProfile={setProfile}
                    fetchProfile={fetchProfile}
                    setFetchProfile={setFetchProfile}
                  />
                }
              />
            </Routes>
          </section>
          {/* {location.pathname === "/" && <ProfileFooter />} */}
          {jwtToken ? (
            <AccessFooter
              customDesign={true}
              businessName={businessName}
              setBusinessName={setBusinessName}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              businessProfileName={businessProfileName}
              setBusinessProfileName={setBusinessProfileName}
              businessUserName={businessUserName}
              setBusinessUserName={setBusinessUserName}
              id={id}
              setId={setId}
              profileType={profileType}
              setProfileType={setProfileType}
              profile={profile}
              setProfile={setProfile}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
