// @ts-nocheck
import React, { useState } from "react";
import {useDispatch} from "react-redux"
import "../../styleSheets/more-options.css";
import { Upload_Images } from "../../utils/Upload-Image";
import { cloudinaryFetch } from "../../API/Cloudinary-fetch-images";
import { changePictureApi } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { DigitalOceanFetch } from "../../API/digitalocean-fetch-images";
import { setApiData } from "../../redux/actions";

export const ChangePictures = ({ type, data, setData, objId, imgEdit, setImgEdit }) => {
  const dispatch = useDispatch()
  const [images, setImages] = useState("");
  const handleImgEdit = () => {
    setImgEdit((prev) => ({...prev, imgEdit: false}));
  };
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  const uploadImgFunc = async (tosendImage) => {
    const imgUrl = await DigitalOceanFetch(tosendImage);

    const dataRetured = await changePictureApi(type, imgUrl, objId, navigate);
    if (type === "Category") {await setData(dataRetured?.body?.menu?.categories); dispatch(setApiData(dataRetured?.body?.menu))}
    if (type === "SubCategory")
      await setData(dataRetured?.body?.menu?.subCategories);
    if (type === "Item") await setData(dataRetured?.body?.menu?.items);
    setIsUploading(false);
    setImages("");
    setImgEdit((prev) => ({...prev, imgEdit: false}));
  };

  return (
    <>

      <Upload_Images
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        images={images}
        setImages={setImages}
        uploadImgFunc={uploadImgFunc}
        aspectRatio={1 / 1}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        maxSizeMB={0.5}
      />
    </>
  );
};
