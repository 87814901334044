// @ts-nocheck
import "../../styleSheets/EditEventsForm.css"

export const Event_action_website_view = ({url, setUrl, handleStatesChange}) => {
  return (
    <div className="">
      <input value={url} onChange={(e)=>handleStatesChange(e, setUrl)} type="text" placeholder="https://www.example.com/test" className="w-100 field-border p-2 rounded event_custom_placeholder" />
    </div>
  )
}

