// @ts-nocheck
import React, { useEffect } from "react";

import classes from "../styleSheets/UpdateProfile.module.css";
import classes1 from "../styleSheets/chooseCategory.module.css";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { USER } from "../constants/localStorageKeys";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const ExpandBusiness = () => {
  const [searchParams] = useSearchParams();
  const businessName = searchParams.get("name");
  const businessId = searchParams.get("id");
  const businessUserName = searchParams.get("userName");
  const location = useLocation();
  const navigate = useNavigate();
  localStorage.setItem(USER.businessName, businessName);
  localStorage.setItem(USER.businessId, businessId);
  localStorage.setItem(USER.USERNAME, businessUserName);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/BusinessProfile`, "BusinessProfile Page");
  }, []);
  return (
    <div>
      <Box sx={{ width: "100%", marginTop: "30px" }}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <p> </p>
          </Grid>
          <Grid className={classes.center} item xs={2}>
            <ArrowBackIcon
              onClick={() => {
                return navigate(-1);
              }}
              className={classes1.arrow}
            />
          </Grid>
          <Grid className={classes.center} item xs={5}>
            <p className={classes.mainText2}>{businessName}</p>
          </Grid>
          <Grid className={classes.center} item xs={3}>
            <button className={classes.btn_expand}>edit profile</button>
          </Grid>
          <Grid item xs={1}>
            <p> </p>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.main}>
        <div className={classes.businessDiv}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <Grid container spacing={2.5}>
              <Grid item xs={1}>
                <p> </p>
              </Grid>
              <Grid className={classes.center} item xs={2}>
                <AdminPanelSettingsIcon />
              </Grid>
              <Grid item xs={5}>
                <p className={classes.authenticateText}>User Authentication</p>
              </Grid>
              <Grid className={classes.center} item xs={3}>
                <NavigateNextIcon />
              </Grid>
              {/* <Grid item xs={1}>
                                <p> </p>
                            </Grid> */}
            </Grid>
          </Box>
        </div>
        <div className={classes.expand_Container}>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Grid container spacing={0}>
              <Grid item xs={1}>
                <p> </p>
              </Grid>
              <Grid item xs={7}>
                <p className={classes.authenticateText}>Activate Services</p>
              </Grid>
              <Grid className={classes.center} item xs={3}>
                <Button
                  variant="outlined"
                  // onClick={handleOpen}
                  //  className={classes.btn3}
                  sx={{
                    borderRadius: "20px",
                    height: "25px",
                    width: "40px",
                    border: "black 2px line",
                  }}
                >
                  {" "}
                </Button>
              </Grid>
              <Grid item xs={1}>
                <p> </p>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className={classes.expand_option}>
          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Grid container spacing={0}>
              <Grid item xs={1}>
                <p> </p>
              </Grid>
              <Grid item xs={11}>
                <p
                  onClick={() => {
                    localStorage.setItem(
                      "menu-back-route",
                      location.pathname + location.search,
                    );
                    navigate(`/edit/menu/${businessUserName}`);
                  }}
                >
                  Digital Menu
                </p>
                <p>Google table reservation</p>
                <p>WhatsApp Business API</p>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ExpandBusiness;
