//@ts-nocheck
import { useState } from 'react'
// import { api } from './MockData'
import Pop_up_2option from '../utils/Pop_up_2option'
import"../styleSheets/contactfasthome.css"
import contactphone_icon from "../assets/icons/Social_icons/contactphone_icon.svg";
import mail_icon from "../assets/icons/Social_icons/mail_icon.png";
import map_icon from "../assets/icons/Social_icons/map_icon.svg";
import facebook_icon from "../assets/icons/Social_icons/facebook_icon.svg";
import instagram_icon from "../assets/icons/Social_icons/instagram_icon.svg";
import hollow_wp_icon from "../assets/icons/Social_icons/whatsapp_icon.png";
import SpringModal from './Contactfastmodal'
import { useSelector } from 'react-redux';
import { USER } from '../constants/localStorageKeys';
import { formatWhatsappNumber } from '../utils/functionUtils';

const Contactfasthome = () => {
  
  const api = useSelector(state => state?.fastSocial?.apiData)
  const rolesData=useSelector(state=>state.roles.apiData)

  function openWhatsAppChat() {
    const phoneNumber = formatWhatsappNumber(`${api["Contact Details"]?.links?.whatsapp?.cCode}${api['Contact Details'].links.whatsapp.url}`);
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${encodedPhoneNumber}`;
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isAndroid) {
      const intentUrl = `intent://send/${encodedPhoneNumber}#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end`;
      window.location.href = intentUrl;
  }else if(isIOS){
    const whatsappUrl = `https://wa.me/${encodedPhoneNumber}`
    window.open(whatsappUrl, "_blank")
  }
   else {
      window.open(whatsappWebUrl, '_blank');
  }
}



let name 
rolesData.responseData?name=[{name:'Edit Details',route:`/FastSocial/Contactsdetailspage?accountId=${api?.["Contact Details"]?.accountId}`}]:name=[]
if(Object.keys(api).length === 0){
  return
}
  return (
    <div className=' mb-5' id="contactsection">
      <div className='card mx-3 rounded-4 shadow'>
      <div className="mx-3 my-3">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h1 className="fw-semibold fs-1 text-white">
                Contact details
              </h1>
              <div>
                <Pop_up_2option name={name} id={'contactsection'}/>
              </div>
            </div>
            <div className='d-flex'>
            {api['Contact Details']?.links?.call?.live&&<div className='mx-2'>
              <a href={`tel:${api["Contact Details"]?.links?.call?.cCode} ${api['Contact Details'].links.call.url}`} target='_blank'><img src={contactphone_icon} className='contact-icons rounded-2'/></a>
            </div>}
            {api['Contact Details'].links.email.live&&<div className='mx-2'>
              <a href={`mailto: ${api['Contact Details'].links.email.url}`} target='_blank'><img src={mail_icon} className='contact-icons rounded-2'/></a>
            </div>}
            {api['Contact Details'].links.facebook.live&&<div className='mx-2'>
              <a href={`https://www.facebook.com/${api['Contact Details'].links.facebook.url}`} target='_blank'><img src={facebook_icon} className='contact-icons'/></a>
            </div>}
            {api['Contact Details'].links.instagram.live&&<div className='mx-2'>
              <a href={`https://www.instagram.com/${api['Contact Details'].links.instagram.url}`} target='_blank'><img src={instagram_icon} className='contact-icons'/></a>
            </div>}
            {api['Contact Details'].links.map.live&&<div className='mx-2'>
              <a href={api['Contact Details'].links.map.url} target='_blank'><img src={map_icon} className='contact-icons'/></a>
            </div>}
            {api['Contact Details'].links.whatsapp.live&&<div className='mx-2'>
              <img src={hollow_wp_icon} className='contact-icons cursor-pointer' onClick={openWhatsAppChat}/>
            </div>}
            </div>
            {api['Contact Details'].address.live&&<div className='d-flex text-white fs-3 fw-bold ms-2 mt-3'>
                Address :<SpringModal type={"address"}/>
            </div>}
            {api['Contact Details'].operationalHours.live&&<div className='d-flex text-white fs-3 fw-bold ms-2 mt-2'>
                Business Hours :<SpringModal type={"time"}/>
            </div>}
            </div>
      </div>
    </div>
  )
}

export default Contactfasthome
