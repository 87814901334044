// @ts-nocheck

import { combineReducers } from "redux";
import menuReducer from "./reducer_components/menu_reducer";
import fastSocialReducer from "./reducer_components/fast_social_reducer";
import rolesManagerReducer from "./reducer_components/Roles_Manager_reducer";
import { menu_access_reducer } from "./reducer_components/menu_access_reducer";
import { userDetailsReducer } from "./reducer_components/userDetailsReducer";
import { selectedProfileReducer } from "./reducer_components/selectedProfile";


const appReducer = combineReducers({
  menu: menuReducer,
  fastSocial: fastSocialReducer,
  roles:rolesManagerReducer,
  menuAccess: menu_access_reducer,
  userDetails: userDetailsReducer,
  selectedProfile: selectedProfileReducer
});

const rootReducer = (state, action) => {
  if(action.type == "RESET_REDUX"){
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}


export default rootReducer;
