// @ts-nocheck
import React, { useState, useEffect } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import { MENU_API, baseURL } from "../../constants/Api";
import { itemsAPI } from "../../API/MenuApi";
import { USER } from "../../constants/localStorageKeys";
import placeholder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import "../../styleSheets/Search-Bar.css";
import MenuModal from "../Modals Layout/MenuModal";
import { ItemOpen } from "../ItemsSection/Item-open";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { checkImageUrl } from "../../utils/checkImageUrl";
import "../../styleSheets/item-section.css";
import {
  IconsArr,
  Item_names,
  Item_prices,
} from "../ItemsSection/Item-details-extract";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SearchBar = ({ active, setActive }) => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const token = localStorage.getItem("accessToken");
  const resName = localStorage.getItem(USER.USERNAME);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const [query, setQuery] = useState("");
  const [itemid, setitemId] = useState();
  //  const data=itemsAPI(`${baseURL}${MENU_API.MENU}${name}`)
  const [open, setOpen] = useState(false);
  const [itemData, setitemData] = useState([]);

  const callApis = async () => {
    const data = await itemsAPI(`${baseURL}${MENU_API.MENU}${name}`);
    setitemData(data);
  };

  useEffect(() => {
    callApis();
  }, []);

  const handleClick = () => {
    setActive(false);
  };
  const handleOpen = (id) => {
    setOpen(true);
    setitemId(id);
  };

  return (
    <div>
      <ItemOpen
        open={open}
        setOpen={setOpen}
        itemData={itemData}
        itemid={itemid}
      />
      {active && (
        <div className={classes.column}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className="Searchbar-Div mx-2 my-3 rounded-3"
          >
            <div className="ms-4 me-3 my-auto">
              <img src="/assets/search-icon.svg" alt="Search Icon" />
            </div>
            <div className="w-100 my-auto pt-1">
              <input
                type="text"
                placeholder="Item Search"
                value={query}
                className="Searchbar-Input"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="my-auto">
              <img
                className="me-4"
                src="/assets/searchbar-cross-icon.svg"
                alt="Close Icon"
                onClick={handleClick}
              />
            </div>
          </div>
          {itemData
            ?.filter((i) => {
              if (query === "") {
                return i;
              } else if (
                i?.itemName?.toLowerCase().includes(query.toLowerCase())
              ) {
                return i;
              }
            })
            ?.map((i, index) => {
              return (
                <>
                  <div>
                    <div>
                      <div>
                        <div style={{ marginTop: "20px", display: "flex" }}>
                          <div
                            className={`w-100 ${
                              i.publish === 0 && "opacity-50"
                            }`}
                            onClick={() => handleOpen(i._id)}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                            }}
                          >
                            <div>
                              {i.image ? (
                                <>
                                  {checkImageUrl(i.image) == "" ? (
                                    <img
                                      src={i.image}
                                      className={`${classes.itemImg1} rounded`}
                                      style={{
                                        width: "2.2rem",
                                        height: "2.2rem",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`${imgBaseUrl}/${i.image}`}
                                      className={`${classes.itemImg1} rounded`}
                                      style={{
                                        width: "2.2rem",
                                        height: "2.2rem",
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <img
                                  src={placeholder}
                                  className={`${classes.itemImg1} rounded`}
                                  style={{ width: "2.2rem", height: "2.2rem" }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                // alignItems: "center"
                              }}
                            >
                              <div
                                className={`item_name_list d-flex align-items-center my-auto ms-2`}
                              >
                                <Item_names
                                  varietyArr={i?.varietyArr}
                                  baseName={i?.itemName}
                                />
                                <div
                                  className="ms-3"
                                  style={{ marginTop: "-1px" }}
                                >
                                  <IconsArr item={i} />
                                </div>
                              </div>

                              <div className={`item_price_list my-auto ms-2`}>
                                ₹{" "}
                                <Item_prices
                                  varietyArr={i?.varietyArr}
                                  price={i?.price}
                                />{" "}
                              </div>
                            </div>
                          </div>
                          <div className={`ms-auto my-auto me-2`}>
                            {" "}
                            {menuAccessState && (
                              <MenuModal
                                objData={i}
                                objName={i?.itemName}
                                objImage={i?.image}
                                type="Item"
                                objId={i?._id}
                                objPrice={i?.price}
                                objType={i?.type}
                                data={i}
                                setData={setitemData}
                                publish={i?.publish}
                                objDescription={i?.description}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
