// @ts-nocheck
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "../styleSheets/imageUpload.css";
import imageCompression from "browser-image-compression";

const fileTypes = ["JPG", "PNG", "JPEG", "MP4", "MOV", "AVI"]; // Add video file types

function DragDrop({ setImages, maxSizeMB }) {
  console.log(maxSizeMB, "maxSizeMB value");
  const [fileMessage, setFileMessage] = useState(
    <>
      <u>Upload</u> or drag and drop an image or video
    </>,
  );
  const [fileError, setFileError] = useState(false);
  const [fileSuccess, setFileSuccess] = useState(false);
  const compressProgressFunction = (progress) =>
    setFileMessage(
      <>
        <span className="text-bold">{progress}% uploaded...</span>
      </>,
    );

  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    onProgress: compressProgressFunction,
  };

  const children = (
    <div
      style={{ border: "2px dashed grey" }}
      className={`text-center rounded-5 ${fileError && "text-danger"} ${
        fileSuccess && "text-success"
      }`}
    >
      <CloudUploadOutlinedIcon
        className="user-select-none"
        style={{ fontSize: "7rem" }}
      />
      <p>{fileMessage}</p>
      <p>
        Supported file types:{" "}
        <span className={`text-bold`}>JPEG, JPG, PNG, MP4, MOV, AVI</span>
      </p>
      <p>
        Preferred file limit for video and image :{" "}
        <span className="text-bold">200 MB & 1 MB</span>
      </p>
    </div>
  );

  const handleChange = async (file) => {
    setFileError(false);
    setFileSuccess(true);

    // Handle video files separately
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (["mp4", "mov", "avi"].includes(fileExtension)) {
      if (file.size > 200 * 1024 * 1024) {
        setFileError(true);
        setFileSuccess(false);
        setFileMessage(
          <>
            Video should be of less than or equal to 200 MB, <u>Upload</u>{" "}
            another
          </>,
        );
        return;
      }

      // For videos, no compression, just pass the file to the setImages
      setImages(file);
      console.log("Video file:", file);
    } else {
      // Handle image files
      if (file.size > 1 * 1024 * 1024) {
        try {
          const compressedFile = await imageCompression(file, options);
          console.log(
            `Compressed image size: ${compressedFile.size / 1024 / 1024} MB`,
          );
          await setImages(compressedFile);
        } catch (err) {
          console.log("Error during image compression", err);
        }
        return;
      }

      setImages(file);
      console.log("Image file:", file);
    }
  };

  const handleTypeError = (err) => {
    setFileError(true);
    setFileSuccess(false);
    setFileMessage(
      <>
        Invalid file type, <u>Upload</u> another
      </>,
    );
    console.log(err);
  };

  const handleSizeError = (err) => {
    setFileError(true);
    setFileSuccess(false);
    setFileMessage(
      <>
        Image should be less than or equal to 1 MB, <u>Upload</u> another
      </>,
    );
  };

  return (
    <FileUploader
      children={children}
      onTypeError={handleTypeError}
      onSizeError={handleSizeError}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      classes="drag-n-drop"
    />
  );
}

export default DragDrop;
