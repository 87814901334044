// @ts-nocheck

import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux"
import {
  addCategory,
  addSubCategory,
  updateCategory,
  updateSubCategory,
} from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { Overlay_Animation } from "../../utils/Overlay-animation";
import { Cat_Subcat_Layout } from "../CategoryPage/Cat-Subcat-Layout";
import "../../styleSheets/more-options.css";
import { setApiData } from "../../redux/actions";

export const Edit_Cat_SubCat = ({ type, setData, data, objId, objData, showOverlay, setShowOverlay }) => {
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate();
  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch()


  const selectedImage = "";

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes(`add_cat_subcat_div`) ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay((prev)=>({...prev, editSubCat: false}));
      setShowDiv(false);
      setError(false);
      if (type === "Category") setCategoryName(objData?.category);
      if (type === "SubCategory") setCategoryName(objData?.subCategory);
    }
  };

  useEffect(() => {
    if (type === "Category") setCategoryName(objData?.category);
    if (type === "SubCategory") setCategoryName(objData?.subCategory);
  }, [objData]);

  const editDetails = async () => {
    setError(false);
    if (type == "Category") {
      const errorFound = data?.find(
        (el) => el?.category?.toLowerCase() === categoryName?.toLowerCase(),
      );
      if (errorFound) {
        console.log("Error found");
        setError(true);
        return;
      }

      const catData = await updateCategory(objId, categoryName, navigate);
      await setData(catData?.body?.menu?.categories);
      dispatch(setApiData(catData?.body?.menu))

      // setCategoryName('')
      setShowOverlay((prev)=>({...prev, editSubCat: false}));
      setShowDiv(false);
    }

    if (type == "SubCategory") {
      const errorFound = data?.find(
        (el) =>
          el?.subCategory?.toLowerCase() === categoryName?.toLowerCase() &&
          el?.category_id === objId,
      );
      if (errorFound) {
        console.log("Error found");
        setError(true);
        return;
      }

      const subData = await updateSubCategory(objId, categoryName, navigate);
      await setData(subData.body.menu.subCategories);
      // setCategoryName('')
      setShowOverlay((prev)=>({...prev, editSubCat: false}));
      setShowDiv(false);
    }
  };

  return (
    <div id="edit">
      <Overlay_Animation
        className="add_cat_subcat_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <Cat_Subcat_Layout
            type={type}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
            error={error}
            onClickBtn={editDetails}
          />
        }
      />
    </div>
  );
};
