// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";

import classes from "../../styleSheets/FoodMenu.module.css";
import icon from "../../RestaurantMenu/Images/repete.png";
import icon1 from "../../RestaurantMenu/Images/revolt.jpg";
import moment from "moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { USER } from "../../constants/localStorageKeys";
import { MENU_API, baseURL } from "../../constants/Api";
import { menuAPI, authMenuApi } from "../../API/MenuApi";
import { useNavigate } from "react-router-dom";
import utilityFunctions from "../../utils/UtilityFunction";
import iconPlaceHolder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { AddCarouselImage } from "./AddCarouselImage";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { HeaderOptions } from "../CommonFiles/Header-Options";
import { useSelector } from "react-redux";
const Header = ({ selectedProfile, data, setData, userData, setUserData }) => {
  const menuAccessState = useSelector(state => state.menuAccess.apiData )
  const [authenticated, setAuthenticated] = useState("");
  const [menuData, setMenuData] = useState("");
  const [images, setImages] = React.useState();
  const [imgEdit, setImgEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);

  const handleAddImage = () => {
    setImgEdit(true);
  };
  useEffect(() => {
    if (savedToken) {
      setAuthenticated(savedToken);
    }
    console.log("working of callApis");
    callApis();
  }, []);
  const name = localStorage.getItem("name");
  const { getImages } = utilityFunctions();
  const resName = localStorage.getItem(USER.USERNAME);
  // const data=menuAPI(`${baseURL}${MENU_API.MENU}${name}`)
  const callApis = async () => {
    // const data = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    setMenuData(data);
    // console.log("setcategory menuData----------------------->", data);
  };
  useEffect(() => {
    callApis();
    console.log("api running");
  }, [data]);

  const isMobile = window.innerWidth;
  console.log(name, "menuData");
  // console.log()

  return (
    <div className={`${classes.header} position-sticky top-0 z-2 rounded-bottom-4`}>
      <div className={classes.headerMargin}>
        <div className={classes.row}>
          {authenticated && isMobile <= 900 && (
            <div
              style={{ color: "white", margin: "5px 5px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <KeyboardArrowLeftIcon />
            </div>
          )}
          <span className="my-auto">
            {menuAccessState ? (
              <>
                {(menuData?.cloudinary_Iconurl == "null" ||
                  !menuData?.cloudinary_Iconurl) && (
                  <>
                    <img
                      src={iconPlaceHolder}
                      className={classes.menuIcon}
                      // onClick={handleAddImage}
                    />{" "}
                  </>
                )}
                {menuData?.cloudinary_Iconurl && (
                  <>
                    {checkImageUrl(menuData?.cloudinary_Iconurl) == "" ? (
                      <img
                        src={menuData?.cloudinary_Iconurl}
                        className={classes.menuIcon}
                      />
                    ) : (
                      <img
                        src={`${imgBaseUrl}/${menuData?.cloudinary_Iconurl}`}
                        className={classes.menuIcon}
                      />
                    )}{" "}
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                {menuData?.cloudinary_Iconurl && (
                  <>
                    {checkImageUrl(menuData?.cloudinary_Iconurl) == "" ? (
                      <img
                        src={menuData?.cloudinary_Iconurl}
                        className={classes.menuIcon}
                      />
                    ) : (
                      <img
                        src={`${imgBaseUrl}/${menuData?.cloudinary_Iconurl}`}
                        className={classes.menuIcon}
                      />
                    )}{" "}
                  </>
                )}
                {(menuData?.cloudinary_Iconurl == "null" ||
                  !menuData?.cloudinary_Iconurl) && (
                  <>
                    <img src={iconPlaceHolder} className={classes.menuIcon} />{" "}
                  </>
                )}
              </>
            )}
          </span>

          <>
            <div className={classes.headerText}>
              <span> {menuData?.title}</span>
              <div className={classes.headerText1}>{menuData?.address}</div>
            </div>
            {/* <div style={{border: "2px solid red"}} className={classes.headerText2}>{time}</div> */}
            <div className="ms-auto">
              <HeaderOptions userImage={userData?.image} />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Header;
