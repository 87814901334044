export const USER = {
  MOBILE_NUMBER: "usermobilenumber",
  JWT_ACCESS_TOKEN: "accessToken",
  JWT_REFRESH_TOKEN: "refreshToken",
  NAME: "name",
  USERNAME: "RestrauntName",
  businessName: "businessName",
  sidebar: "sidebar",
  businessId: "businessId",
  PROFILE_TYPE: "profileType",
  TRANSACTION_ID:"transactionId"
};
