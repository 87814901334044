import closeIcon from "../assets/icons/svgSpecificIcons/closeIcon.svg";
import classes from "../styleSheets/ViewDemo.module.css";

const Header1 = () => {
  return (
    <div className={classes.container}>
      <img
        src={closeIcon}
        onClick={() => {
          history.back();
        }}
        className={classes.closeIcon}
      />
    </div>
  );
};

export default Header1;
