import React, { useState, useEffect } from "react";
import { CURRENT_BALANCE_API } from "../constants/Api";
import { Button } from "@mui/material";
import closeIcon from "../assets/svgSpecificIcons/closeIcon.svg";
import Code from "../assets/icons/svgSpecificIcons/qr-code.png";
import userImage from "../assets/icons/svgSpecificIcons/userImage.svg";
import Header1 from "../Components/Header1";
import classes from "../styleSheets/ViewDemo.module.css";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const ViewDemo = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/ViewDemo`, "ViewDemo Page");
  }, []);

  return (
    <>
      <Header1 />
      <div className={classes.text1}>Experience the product</div>

      <div className={classes.content}>
        <div className={classes.column}>
          <div style={{ textAlign: "center" }}>
            <img src={userImage} className={classes.image} />
          </div>
          <div style={{ textAlign: "center" }}>
            <a href="https://wa.me/message/X65GAZOTWTGCC1 ">
              <button className={classes.button}>Get a Link</button>
            </a>
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.column}>
          <div style={{ textAlign: "center" }}>
            <img src={Code} className={classes.image} />
            <div className={classes.text2}>Scan the QR Code</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDemo;
