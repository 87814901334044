// @ts-nocheck
import "../../styleSheets/eventsMenu.css";
import React, { useRef, useState, useEffect } from "react";
import { Events } from "./event";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import "../css/carousel.css"

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { AddSlide } from "./addSlide";
import { useSelector } from "react-redux";

export const EventsInMenu = () => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const reduxFastSocialData = useSelector((state) => state.fastSocial.apiData);
  const reduxEventData = useSelector(
    (state) => state.fastSocial.apiData.Events
  );
  const [eventData, setEventData] = useState(reduxEventData || []);
  const [carouselState,setCarouselState]=useState(true)
  useEffect(()=>{
    if (eventData.length<3){
      setCarouselState(false)
    }else{
      setCarouselState(true)
    }
  },[eventData])

  if (!reduxEventData) {
    return;
  }

  return (
    <div className={`mx-3 ${carouselState&&'event-carousel'}`}>

      {/* <Swiper
      loopFillGroupWithBlank={carouselState}
      loop={carouselState}
      initialSlide={1}
      centeredSlides={carouselState}
        slidesPerView={2.3}
        spaceBetween={15}
        navigation={carouselState}
        autoHeight={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter:true,
        }}
        modules={[Navigation,Autoplay]}
        className="event-menu-swiper p-4 px-2"
        breakpoints={{
          768: {
            width: 768,
            slidesPerView: 3.5,
          },
        }}
      >
        {menuAccessState && (
          <SwiperSlide>
            <AddSlide
              accountId={reduxFastSocialData["Basic Details"].accountId}
            />
          </SwiperSlide>
        )}

        {eventData?.map((data) => {
          return (
            <SwiperSlide>
              <Events
                eventData={data}
                accountUserName={
                  reduxFastSocialData["Basic Details"].account.userName
                }
              />
            </SwiperSlide>
          );
        })}

      </Swiper> */}
    </div>
  );
};
