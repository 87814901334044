//@ts-nocheck
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import backArrow from "../assets/icons/Social_icons/backArrow.svg";
import camera_icon from "../assets/icons/Social_icons/photo_camera_icon.svg";
import "../styleSheets/Photopage.css";
import delete_button from "../assets/icons/Social_icons/deleteButton.svg";
import rotate from "../assets/icons/Social_icons/rotate.png";
import "../styleSheets/AddLinksPage.css";
import Add_fast_social_images from "./Add_fast_social_images";
import { useSelector } from "react-redux";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import Delete from "../RestaurantMenu/Modals Layout/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Photopage = ({ photo }) => {
  const [uploadIndexController, setUploadIndexController] = useState(0);
  const [toDeleteId, setToDeleteId] = useState(0);
  const api = useSelector((states) => states.fastSocial.apiData);
  const extractedData = api["Media Section"]?.media;
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const name = serchParams.get("name");
  const categoryId = serchParams.get("categoryId");
  const state = serchParams.get("accountId");
  const [carouselData, setCarouselData] = useState([]);
  const [images, setImages] = React.useState();
  const [imgEdit, setImgEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgData, setImgData] = useState([]);
  const [deleteState, setDeleteState] = useState(false);
  const emptyArr = new Array(9).fill("");
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleImageUpload = (indexPosition) => {
    setImgEdit(true);
    setUploadIndexController(indexPosition);
  };

  const handleRotate = () => {
    setRotationAngle((prevAngle) => (prevAngle + 90) % 360);
  };

  useEffect(() => {
    if (extractedData) {
      setImgData(extractedData.filter((img) => img.category._id == categoryId));
    }
  }, [api]);

  if (Object.keys(api).length === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/Photoaddpage`, "PhotoAdd Page");
  }, []);

  return (
    <div className="Socialformpage d-flex justify-content-center w-100 h-100">
      <div className="mobile-pc-width">
        <div className="d-flex align-items-center">
          <div className="small-devices-padding">
            <div
              className="d-flex justify-content-between pt-3 ps-md-0  mb-5"
              style={{ position: "sticky", top: "0", left: "0" }}
            >
              <KeyboardBackspaceIcon
                className="back-arrow-click ms-3"
                onClick={() => navigate(-1)}
              />
              <div className=" me-auto ms-auto ">
                <span className="me-5 text-black">{name}</span>
              </div>
            </div>
            <div className="text-media mx-2 mb-3 mt-4">
              You can upload maximum of 9 media
            </div>
            <div className="row mx-2 mx-md-0 row-cols-3 ps-0 pe-0">
              {emptyArr.map((emptyEl, index) => {
                const findEl = imgData.find(
                  (img) => img.indexPosition === index,
                );
                let imgUrl;
                if (findEl) imgUrl = findEl?.mediaUrl;
                else imgUrl = camera_icon;
                return (
                  <div
                    key={index}
                    className="d-flex flex-column justify-content-center align-items-center mb-4 p-1"
                  >
                    <div
                      className="  d-flex justify-content-center align-items-center h-100"
                      style={{ maxWidth: "110px", minWidth: "110px" }}
                    >
                      <div
                        className="image-div d-flex justify-content-center align-items-center w-100 "
                        style={{
                          maxHeight: "110px",
                          minHeight: "110px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          onClick={() => handleImageUpload(index)}
                          className={`w-100 h-100 photo ${!findEl && "p-4"}`}
                          src={`${findEl ? `${imgBaseUrl}/${imgUrl}` : imgUrl}`}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        className={`rotate-button ${!findEl && "invisible"}`}
                      >
                        <img
                          src={rotate}
                          onClick={handleRotate}
                          className="h-75"
                        />
                      </div>
                      <div className={`del-button ${!findEl && "invisible"}`}>
                        <img
                          onClick={() => {
                            setDeleteState(true);
                            setToDeleteId(findEl._id);
                          }}
                          src={delete_button}
                          className="h-75 ms-5"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Add_fast_social_images
        indexPosition={uploadIndexController}
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        loading={loading}
        setLoading={setLoading}
        images={images}
        setImages={setImages}
        carouselData={carouselData}
        setCarouselData={setCarouselData}
        accountId={state}
      />
      <Delete
        data={api}
        type={"Photo"}
        open={deleteState}
        setOpen={setDeleteState}
        objId={toDeleteId}
      />
    </div>
  );
};

export default Photopage;
