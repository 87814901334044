//@ts-nocheck
import React from 'react';
import PostComponent from '../Components/FeedComponents/PostCompnent'; 
import UpcomingEvents from '../Components/FeedComponents/UpcomingEvents';
import EatoesHeader from '../Components/EatoesHeader';
import FeedFooter from '../Components/FeedComponents/FeedFooter';

// Sample data for posts
const posts = [
  {
    id: 1,
    profilePic: 'https://www.jammable.com/_next/image?url=https%3A%2F%2Fimagecdn.voicify.ai%2Fmodels%2F6261b6d9-0374-438a-8004-920632c5c88b.png&w=640&q=75',
    name: 'carti',
    date: '2022-10-8',
    postPic: 'https://images.topmediai.com/topmediai/assets/article/playboi-cover.jpg',
    likes: 10,
    shares: 5,
  },
  {
    id: 2,
    profilePic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHau3hRzOuXuaeQybuhkjyYtT9t7XKfwdhog&s',
    name: 'Central Cee',
    date: '2022-10-9',
    postPic: 'https://media.vogue.co.uk/photos/6662e4a03df2097914e05936/master/w_1600%2Cc_limit/JULY24-COVERASSET-3.jpg',
    likes: 100,
    shares: 15,
  },
  {
    id: 3,
    profilePic: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Pulitzer2018-portraits-kendrick-lamar.jpg',
    name: 'Kendrick Lamar',
    date: '2022-10-9',
    postPic: 'https://i.scdn.co/image/ab67616d0000b2738b52c6b9bc4e43d873869699',
    likes: 100,
    shares: 1500,
  },
  // Add more posts as needed
];

const Feed = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <EatoesHeader />
      <div style={{ 
        backgroundColor: '#F4F4F4', 
        marginTop: '10px',
        flexGrow: 1,
        paddingBottom: '140px', // Add padding to account for the footer height
        overflowY: 'auto' // Enable scrolling for the content
      }}>
        {/* Display only the first post */}
        {posts.length > 0 && (
          <PostComponent
            key={posts[0].id}
            profilePic={posts[0].profilePic}
            name={posts[0].name}
            date={posts[0].date}
            postPic={posts[0].postPic}
            likes={posts[0].likes}
            shares={posts[0].shares}
          />
        )}
        
        {/* Display Upcoming Events */}
        <UpcomingEvents />

        {/* Display all other posts, excluding the first */}
        {posts.slice(1).map(post => (
          <PostComponent
            key={post.id}
            profilePic={post.profilePic}
            name={post.name}
            date={post.date}
            postPic={post.postPic}
            likes={post.likes}
            shares={post.shares}
          />
        ))}
      </div>
      {/* Footer */}
      <FeedFooter 
        businessProfileName="Iron Hill Bengaluru"
        onBusinessProfileClick={() => {/* handle business profile click */}}
        leftItem={{
          label: "Home",
          onClick: () => {/* handle home click */},
        }}
        rightItem={{
          label: "Profile",
          onClick: () => {/* handle profile click */},
        }}
      />
    </div>
  );
};

export default Feed;