// @ts-nocheck
import React, { useState, useEffect, memo } from "react";
import { Box } from "@mui/material";
import classes from "../../styleSheets/FoodMenu.module.css";
import utilityFunctions from "../../utils/UtilityFunction";
import foodImage from "../Images/FoodPlaceholder.jpeg";
import Items from "../ItemsSection/Items";
import { MENU_API, baseURL } from "../../constants/Api";
import { subCategoryAPI } from "../../API/MenuApi";
import { USER } from "../../constants/localStorageKeys";
import { Add } from "../Modals Layout/Add";
import MenuModal from "../Modals Layout/MenuModal";
import {
  CatTransformedImage,
  SubCatTransformImage,
} from "../../utils/TransformedImages";
import { Add_Cat_Subcat } from "../Modals Layout/Add-Cat-Subcat";
import "../../styleSheets/subcategory-sidebar.css";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import {
  AutoScrollActivator,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  autoScrollProp,
  handleDragEnd,
  handleDragOver,
  handleDragStart,
  modifierProp,
} from "../../utils/dnd/handlers";
import { SortableContext } from "@dnd-kit/sortable";
import { UseSortableFile } from "../../utils/dnd/useSortableFile";
import { MappedSubcategory } from "./mappedSubcategory";
import { DraggableItem } from "../../utils/dnd/draggableItem";
import { Loader } from "../../utils/Loader/loader";
import { useNavigate } from "react-router-dom";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { useSelector } from "react-redux";

const SubCategorySidebar = memo((id: any) => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const navigate = useNavigate();
  const element = document.getElementById("section-1");
  const name = localStorage.getItem("name");
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: "smooth" });
  }

  const [activeId, setActiveId] = useState(null);
  const resName = localStorage.getItem(USER.USERNAME);
  const [id1, setId1] = useState("");
  const [subCat, setSubCat] = useState("");
  const [active, setActive] = useState(false);
  const [subCatData, setSubCatData] = useState([]);
  const newArr = [];
  const [a, setA] = useState("");
  const token = localStorage.getItem("accessToken");
  const { getImages } = utilityFunctions();
  const [loaderOpen, setLoaderOpen] = useState(false);

  // const data = subCategoryAPI(`${baseURL}${MENU_API.MENU}${name}`);

  const callApis = async () => {
    setSubCatData(id?.data?.subCategories);
  };
  useEffect(() => {
    callApis();
  }, [id?.data]);
  //  a=id1;
  return (
    <div>
      <div className={classes.row}>
        <div className={classes.sidebar}>
          {menuAccessState ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "0.9px solid #D7D2CF",
                borderTop: "0.9px solid #D7D2CF",
              }}
            >
              <button className={classes.subCatButton}>
                {" "}
                <Add_Cat_Subcat
                  objName=""
                  objImage=""
                  type="SubCategory"
                  objId={id.id}
                  data={subCatData}
                  setData={setSubCatData}
                  setWholeData={id?.setWholeData}
                />
              </button>{" "}
            </div>
          ) : (
            ""
          )}
          <div style={{ paddingBottom: "0px" }}>
            <DndContext
              autoScroll={autoScrollProp}
              modifiers={modifierProp}
              sensors={useSensors(
                useSensor(MouseSensor, {
                  activationConstraint: {
                    delay: 500,
                    tolerance: 5,
                  },
                }),
                useSensor(TouchSensor, {
                  activationConstraint: {
                    delay: 500,
                    tolerance: 5,
                  },
                }),
              )}
              onDragStart={(e) => handleDragStart(e, setActiveId)}
              onDragEnd={() =>
                handleDragEnd(
                  setActiveId,
                  id?.data?.subCategories,
                  id?.setWholeData,
                  subCatData,
                  "subcategory",
                  navigate,
                  setLoaderOpen,
                )
              }
              onDragOver={(e) => handleDragOver(e, setSubCatData)}
              collisionDetection={closestCenter}
            >
              <SortableContext items={subCatData || []}>
                {subCatData?.map((item, index) => {
                  const img = item?.image;
                  if (id?.id == item?.category_id) {
                    if (menuAccessState) {
                      newArr.push(item?._id);
                      if (!a) setA(newArr[0]);
                    }
                    if (!menuAccessState && item?.publish != 0) {
                      newArr.push(item._id);
                      if (!a) setA(newArr[0]);
                    }

                    return !menuAccessState && item.publish == 0 ? null : (
                      <UseSortableFile
                        propId={item}
                        MappedEl={
                          <MappedSubcategory
                            index={index}
                            id1={id1}
                            item={item}
                            a={a}
                            active={active}
                            setId1={setId1}
                            setActive={setActive}
                            setSubCat={setSubCat}
                            analytics={id.analytics}
                            setAnalytics={id.setAnalytics}
                          />
                        }
                      />
                    );
                  }
                })}

                <DragOverlay>
                  {activeId ? (
                    <DraggableItem
                      id={activeId}
                      isDragOverlay={true}
                      MappedEl={
                        <MappedSubcategory
                          index={subCatData.findIndex(
                            (item) => item._id === activeId._id,
                          )}
                          id1={id1}
                          item={activeId}
                          a={a}
                          active={active}
                          setId1={setId1}
                          setActive={setActive}
                          setSubCat={setSubCat}
                          analytics={id.analytics}
                          setAnalytics={id.setAnalytics}
                        />
                      }
                      isDragging
                    />
                  ) : null}
                </DragOverlay>
              </SortableContext>
            </DndContext>
          </div>
        </div>
        <Items
          id1={id}
          id={id1}
          id2={subCat}
          a={a}
          subdata={subCatData}
          setsubData={setSubCatData}
          data={id?.data}
          setWholeData={id?.setWholeData}
          analytics={id.analytics}
          setAnalytics={id.setAnalytics}
        />
      </div>

      <Loader
        open={loaderOpen}
        setOpen={setLoaderOpen}
        message={"Loading...."}
      />
    </div>
  );
});

export default SubCategorySidebar;
