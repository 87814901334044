import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
// Make sure you have react-bootstrap installed and imported in your project

const EatoesHeader = () => {
  const headerStyle = {
    height: '60px',
    minHeight: '60px',
    backgroundColor: '#27343C'
  };

  const brandStyle = {
    color: 'white',
    fontFamily: 'Inter, sans-serif',
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '22.4px',
    // Removed textAlign and margin properties to keep it left-aligned
  };

  return (
    <Navbar fixed="top" className="eatoes-header" style={headerStyle}>
      <Container>
        <Navbar.Brand style={brandStyle}>eatoes</Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default EatoesHeader;
