//@ts-nocheck
import React, { useState, useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Bio_page.css";
import "../styleSheets/responsive.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import DialogBox from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import { updateBio } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import { GreenButton } from "../Components/Button.style";
import "../styleSheets/Arrow.css";
import pencil from "../assets/icons/Social_icons/pencil.png";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Bio_page = () => {
  const dispatch = useDispatch();
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  var textarea = document.getElementById("textInput");

  const [openDialog, setOpenDialog] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleOpenDialog = async () => {
    const receivedData = await updateBio(inputValue, state);
    updateNestedRedux(
      api,
      "Basic Details",
      receivedData.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue.substring(0, 500));
    // ye actually mein inputValue ka limit 500 set kr deta h
  };

  textarea?.addEventListener("keydown", autosize);
  function autosize() {
    var el = this;
    setTimeout(function () {
      el.style.cssText = "height:auto; padding:0";
      el.style.cssText = "height:" + el.scrollHeight + "px";
    }, 0);
  }

  useEffect(() => {
    setInputValue(api["Basic Details"]?.bio);
  }, [api]);

  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/Biopage`, "Bio Page");
  }, []);
  return (
    <div className="Socialformpage d-flex justify-content-center ">
      <div className="mobile-pc-width mx-auto">
        <div className="d-flex container m-auto mb-5 social-profile-header">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <KeyboardBackspaceIcon
              className="back-arrow-click ms-2"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="mx-auto">
              <h1 className="socio-prof-header me-3">
                {api?.["Basic Details"]?.account?.userName} | Bio
              </h1>
            </div>
          </div>
        </div>

        <div
          className="justify-content-center position-relative mb-4 mx-auto mt-4"
          style={{ width: "281px" }}
        >
          <div className="enter-ann-txt">Enter your Bio</div>

          <textarea
            type="text"
            id="textInput"
            className="mb-2 ps-2 mt-1 announcement-box"
            placeholder="Type Your Bio"
            value={inputValue}
            onChange={handleInputChange}
          />
          <div>
            <img src={pencil} className="position-absolute pencil-img" />
          </div>

          <div className="me-5 position-absolute words-count-txt">
            {500 - (inputValue?.length ? inputValue?.length : 0)} words
          </div>
        </div>

        <div
          className="ms-5 mt-5 d-flex justify-content-center align-items-center "
          style={customizeMUIComponents.row}
        >
          <GreenButton
            backgroundColor="#7ECAB0"
            onClick={handleOpenDialog}
            varient="contained"
            className="text-white Link-savebutton"
          >
            Save
          </GreenButton>
        </div>
      </div>
    </div>
  );
};

export default Bio_page;
