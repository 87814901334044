import React, { useEffect, useState } from "react";
import { ProgressTabs } from "./progressTabs";
import { Diners } from "./selectComponents/diners";
import "../styleSheets/tableReserve/selectComponents.css";
import { DateSelect } from "./selectComponents/dateSelect";
import { TimeSelect } from "./selectComponents/timeSelect";
import { UserInfo } from "./selectComponents/userInfo";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { TempPage } from "../utils/tempPage";
import { getAvailableRestaurantsAPI } from "../API/tableReserve/tableReserveAPI";
import BackArrow from "../assets/icons/Social_icons/arrowSvg.svg"
import Pop_up_2option from "../utils/Pop_up_2option";
import { web_url } from "../constants/web_url";

interface dataObj {
  profileId: String;
}

export const TableReserveIndex = () => {
  const {uniqueId} = useParams()
  const navigate = useNavigate();
  const [reserveId, setReserveId] = useState<string>("");
  const [businessName, setBusinessName] = useState("")
  // console.log(typeof navigate)
  const [numberOfDiners, setNumberOfDiners] = useState<number | null>(null);

  const getReserveId = async () => {
    const dataReceived = await getAvailableRestaurantsAPI(navigate);
    console.log(dataReceived, "available restaurant");
    const searchData = dataReceived.find(
      (el: dataObj) => el.profileId.toString() === uniqueId
    );
    if (!searchData?.id) {
      alert("table reserve service is not available in this restaurant");
      navigate("/")
    }else{
      setReserveId(searchData.id)
      setBusinessName(searchData?.name)
    }
  };

  useEffect(() => {
    getReserveId();
  }, []);
  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "auto",
        height: "100svh",
        backgroundColor: "#212328",
        padding: "13px",
      }}
    >
      <div className="py-3 text-white d-flex align-items-center justify-content-center mb-4">
        <div className="me-auto">
          <img src={BackArrow} className="cursor-pointer" onClick={() => navigate(-1)} />
        </div>
        <div className="bookings-heading me-auto" style={{marginLeft: "-12px"}}>
          {businessName}
        </div>
        <div style={{marginRight: "8px"}}><Pop_up_2option name={[]} urlProp={`${web_url}/reserve/${uniqueId}`}  /></div>
      </div>
      <div
        className="border bg-white overflow-auto"
        style={{ padding: "11px 10px 60px 10px", borderRadius: "10px", height: "540px" }}
      >
        <ProgressTabs />

        {/* <Bookings/> */}

        <Routes>
          <Route path="/" element={<Diners setNumberOfDiners={setNumberOfDiners} />} />
          {/* <Route path="/" element={<Bookings />} /> */}
          <Route path="/select-date" element={<DateSelect />} />
          <Route path="/select-time" element={<TimeSelect numberOfDiners={numberOfDiners} reserveId={reserveId} />} />
          <Route path="/user-info" element={<UserInfo reserveId={reserveId}/>} />
          <Route path="/temp-route" element={<TempPage />} />
        </Routes>
      </div>
    </div>
  );
};
