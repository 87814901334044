//@ts-nocheck
import React, { useEffect, useState } from "react";
import arrow from "../assets/icons/Social_icons/arrowSvg.svg";
import "../styleSheets/Search.css";
import { useLocation, useNavigate } from "react-router-dom";
import _3dot from "../assets/icons/Social_icons/3dot.svg";
import Pop_up_2option from "../utils/Pop_up_2option";
import classes from "../styleSheets/FoodMenu.module.css";
import { SearchHeader } from "./search_components/header";
import { SearchResultStr } from "./search_components/resultStr";
import { searchProfile } from "../API/fast_social_apis";

const Search = ({ data }) => {
  const [inputVal, setInputVal] = useState("")
  const [dataArr, setDataArr] = useState([])

  useEffect(()=>{
    if(!inputVal){
      setDataArr([])
      return
    }
    const callApi = async() =>{
      const dataReceived = await searchProfile(inputVal)
      setDataArr(dataReceived)
    }
    callApi()
  }, [inputVal])

  return (
    <div className={`${classes.container1}`}>
      <div className={`${classes.container2}`}>
        <div className="text-white Social">
          <div className="w-100">
            <div className="search-main-div pt-5">
              <SearchHeader inputVal={inputVal} setInputVal={setInputVal} />

              {dataArr?.map(data => {
                return(
                  <SearchResultStr data={data}  />
                )
              })}


            
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Search;
