// @ts-nocheck
import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import "../App.css";
import Box from "@mui/material/Box";
import OtpInput from "../Components/OTPInput";
import {
  createSocialProfile,
  login,
  mobileVerification,
} from "../API/authAPIs";
import Logo from "../assets/new_assets/4.png";
import Image1 from "../assets/images/loginPageImage1.png";
import Image2 from "../assets/images/loginPageImage2.png";
import Image3 from "../assets/images/loginPageImage3.png";
import Image4 from "../assets/images/loginPageImage4.png";
import Dashboard from "./Dashboard";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { Terms } from "../pages/T&C";
import { USER } from "../constants/localStorageKeys";
import ModalAlert from "../utils/ModalAlert";
import Collage from "../LoginComponents/Collage";
import { useTheme, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ResentOtpMessage } from "../utils/resent_otp_message";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export function SignUp({ fetchProfile, setFetchProfile }) {
  const location = useLocation();
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showotp, setShowotp] = useState(false);
  const [open, setOpen] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPFromApi, setOTPFromApi] = useState("");
  const [errMessage, setErrMessage] = useState(false);
  const [err2, setErr2] = useState(false);
  const [showResentMessage, setShowResentMessage] = useState(false);
  const isSignUp = true;
  const isLogin = false;
  const handleOpen = () => {
    isMatch ? navigate("/Terms") : setOpen(true);
  };
  const handleClose = () => setOpen(false);
  let navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(900));

  const queryParams = new URLSearchParams(location.search);
  const toRedirect = queryParams.get("to_redirect");
  const path = queryParams.get("path");

  // Initialize AnalyticsUtil
  const analytics = new AnalyticsUtil(import.meta.env.VITE_GOOGLE_TRACKING_ID);

  const onGetOTP = async () => {
    setOTP("");
    setError("");
    const loginRes = await login(mobileNumber, isSignUp, isLogin);

    if (!mobileNumber.match("[0-9]{10}")) {
      setErrMessage(true);
      setErr2(false);
      setError("Please enter 10 digit mobile number");
    } else if (!loginRes) {
      setErrMessage(false);
      setErr2(false);
      setError("This mobile number is already registered, Please Log In");
    } else {
      setShowotp(true);
      console.log("response from login api", loginRes);
      const theOTPRecieved = loginRes?.body?.otp;
      if (theOTPRecieved) {
        setOTPFromApi(theOTPRecieved);
        analytics.sendEvent("OTP", "Requested", "OTP request initiated");
      } else {
        console.error("Something went wrong!! NO OTP RECIEVED");
      }
    }
  };

  const onSubmitOTP = async () => {
    if (OTP !== OTPFromApi) {
      setErr2(true);
      setError("Entered OTP is incorrect");
    }
    if (OTP === OTPFromApi) {
      const response = await mobileVerification(mobileNumber, OTP);
      if (response?.body?.validateOtpResponse) {
        const userData = response?.body?.userData;
        const token = response?.body?.token?.access?.token;
        token && localStorage.setItem(USER.JWT_ACCESS_TOKEN, token);
        setFetchProfile(true);
        userData?.userName
          ? localStorage.setItem("adminUserName", userData.userName)
          : localStorage.setItem("adminUserName", "");
        userData?.name
          ? localStorage.setItem("adminName", userData.name)
          : localStorage.setItem("adminName", "");
        const isMobile = window.innerWidth <= 900;
        setError("");

        const respoData = await createSocialProfile(
          userData?.name,
          userData?.userName,
          "",
          "personal",
          [],
          "",
          "",
          "",
          navigate,
        );

        if (toRedirect) {
          navigate(path);
          analytics.sendEvent(
            "Navigation",
            "Redirected",
            `Redirected to ${path}`,
          );
        } else {
          console.log(
            "in page signuppppppppppppp: profileId: ",
            respoData?.body?.profileId,
            "respoooooooooo: ",
            respoData,
          );
          navigate("/UpdateProfile", {
            state: { from: "signup", profileId: respoData?.body?.profileId },
          });
          analytics.sendEvent(
            "Navigation",
            "Profile Update",
            "Navigated to UpdateProfile",
          );
        }
      } else {
        setError("Looks like something went wrong. Please, try again.");
        console.log("response is not valid");
      }
    }
  };

  const onChange = (value: string) => {
    console.log("onchange", value);
    setOTP(value);
  };

  useEffect(() => {
    analytics.sendPageView("/SignUp", "SignUp Page");
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <Terms setOpen={setOpen} />
        </Box>
      </Modal>
      <>
        {isMatch && showotp && (
          <div
            style={{ margin: "20px 0 0 20px" }}
            onClick={() => {
              setShowotp(false);
              analytics.sendEvent(
                "OTP",
                "BackClicked",
                "User clicked back from OTP screen",
              );
            }}
          >
            <ArrowBackIcon />
          </div>
        )}
      </>
      <div className="container1">
        <Collage />

        <div className="container">
          {isMatch ? (
            <>
              {showotp == false ? (
                <>
                  <div className="text1">Sign Up</div>
                  <div className="text2">Enter your mobile no to register</div>

                  <div
                    style={{ marginTop: 35, marginBottom: 10 }}
                    className="text_1"
                  >
                    Enter mobile no.
                  </div>

                  <input
                    className="inputfield"
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    maxLength={10}
                    style={{
                      letterSpacing: "2px",
                      fontSize: "16px",
                      paddingLeft: "10px",
                    }}
                  />
                  {error && (
                    <div
                      className="tx-scarlet"
                      style={{ marginTop: "6px", fontSize: "10px" }}
                    >
                      {error}
                    </div>
                  )}

                  <div className="align">
                    <button className="button" onClick={onGetOTP}>
                      Get OTP
                    </button>
                    <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                      <div className="row-flex ">
                        <div className="text3">
                          {" "}
                          Already have an account?{" "}
                          <a
                            style={{ color: "#03A884", textDecoration: "none" }}
                            href="/Login"
                          >
                            Login
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="footer">
                    <p className="footer_text"> Moving forward accepts our</p>
                    <button
                      style={{
                        color: "#3F4DB8",
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "600",
                        border: "none",
                        background: "none",
                      }}
                      onClick={handleOpen}
                    >
                      Terms Of Use
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="text1">OTP Verification</div>
                  <div className="text2">
                    We’ve sent OTP to{" "}
                    <span style={{ color: "#3F4DB8" }}>{mobileNumber}</span>
                  </div>
                  <div
                    style={{ marginTop: "4em", marginBottom: 5 }}
                    className="text_1"
                  >
                    Enter OTP
                  </div>
                  <div style={{ flexDirection: "row", textAlign: "left" }}>
                    <OtpInput value={OTP} valueLength={6} onChange={onChange} />
                    {error && (
                      <div
                        className="tx-scarlet"
                        style={{ marginTop: "6px", fontSize: "10px" }}
                      >
                        {error}
                      </div>
                    )}
                  </div>
                  <div>
                    <button className="button" onClick={onSubmitOTP}>
                      Submit
                    </button>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="row-flex ">
                      <p className="text3"> Didn’t receive OTP,</p>
                      <button
                        className="text-button tx-scarlet marginTop"
                        onClick={async () => {
                          setShowResentMessage(true);
                          analytics.sendEvent(
                            "OTP",
                            "ResentRequested",
                            "Resend OTP button clicked",
                          );
                        }}
                      >
                        Resend
                      </button>
                    </div>
                    <ResentOtpMessage
                      marginClass={"m-auto"}
                      toShow={showResentMessage}
                      setToShow={setShowResentMessage}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <img src={Logo} className="logo" />
              <div className="text1">Sign Up</div>
              <div className="text2">Enter your mobile no to register</div>
              <div
                style={{ marginTop: 35, marginBottom: 10 }}
                className="text_1"
              >
                Enter mobile no.
              </div>
              <input
                className="inputfield"
                type="tel"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                maxLength={10}
                style={{
                  letterSpacing: "2px",
                  fontSize: "16px",
                  paddingLeft: "10px",
                }}
              />
              {error && !err2 && (
                <div
                  className="tx-scarlet"
                  style={{ marginTop: "6px", fontSize: "10px" }}
                >
                  {error}
                </div>
              )}
              <div className="align">
                {showotp === false ? (
                  <>
                    <button className="button" onClick={onGetOTP}>
                      Get OTP
                    </button>
                    <div style={{ paddingBottom: "2em", paddingTop: "20px" }}>
                      <div className="row-flex ">
                        <div className="text3">
                          {" "}
                          Already have an account?{" "}
                          <a
                            style={{ color: "#03A884", textDecoration: "none" }}
                            href="/Login"
                          >
                            Login
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div
                      style={{ marginTop: "1em", marginBottom: 5 }}
                      className="text2"
                    >
                      Enter OTP
                    </div>
                    <div style={{ flexDirection: "row", textAlign: "left" }}>
                      <OtpInput
                        value={OTP}
                        valueLength={6}
                        onChange={onChange}
                      />
                      {error && err2 && (
                        <div
                          className="tx-scarlet"
                          style={{ marginTop: "6px", fontSize: "10px" }}
                        >
                          {error}
                        </div>
                      )}
                    </div>
                    <div>
                      <button className="button" onClick={onSubmitOTP}>
                        Submit
                      </button>
                    </div>
                    <div style={{ paddingBottom: "5px", paddingTop: "20px" }}>
                      <div className="row-flex ">
                        <p className="text3"> Didn’t receive OTP,</p>
                        <button
                          className="text-button tx-scarlet marginTop"
                          onClick={async () => {
                            setShowResentMessage(true);
                            analytics.sendEvent(
                              "OTP",
                              "ResentRequested",
                              "Resend OTP button clicked",
                            );
                          }}
                        >
                          Resend
                        </button>
                      </div>
                      <ResentOtpMessage
                        marginClass={""}
                        toShow={showResentMessage}
                        setToShow={setShowResentMessage}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="footer">
                <p className="footer_text"> Moving forward accepts our</p>
                <button
                  style={{
                    color: "#3F4DB8",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    border: "none",
                    background: "none",
                  }}
                  onClick={handleOpen}
                >
                  Terms Of Use
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
