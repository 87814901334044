// @ts-nocheck

const initialState = {
  apiData: {},
};

export const selectedProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_PROFILE':
      return {
        ...state,
        apiData: action.payload,
        timestamp: new Date()
      };
    default:
      return state;
  }
};
