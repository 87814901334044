import React from "react";
import { TextField, Box, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

const GreenArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: "green",
}));

const PhoneNumberInput = ({
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        border: "1px solid #7ECAB0",
        padding: "5px 10px",
        width: "295px",
        height: "45px",
      }}
    >
      <TextField
        select
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: {
            fontWeight: "bold",
            fontSize: "14px",
          },
        }}
        SelectProps={{
          renderValue: (value) => (
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>{value}</div>
          ),
          IconComponent: GreenArrowDownIcon,
        }}
        style={{ width: "80px", marginRight: "10px", color: "#7ECAB0" }}
      >
        <MenuItem value="+91">+91</MenuItem>
        <MenuItem value="+62">+62</MenuItem>
        {/* Add more country codes as needed */}
      </TextField>
      <div
        style={{
          height: "20px",
          width: "1px",
          backgroundColor: "#ced4da",
          margin: "0 10px",
        }}
      />
      <TextField
        variant="standard"
        placeholder="Enter Your Mobile No"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "14px",
          },
        }}
        style={{ flexGrow: 1 }}
      />
    </Box>
  );
};

export default PhoneNumberInput;
