enum Wallet {
 Recharge = "Recharge",
}

enum WalletAction {
  Amount = "RechargeAmount",
  Cancel = "Cancel",
  OK = "OK"
}

enum Menu {
  Category = "Menu Category",
  SubCategory = "Menu SubCategory",
  Item = "Menu Item",
  Filter = "Menu Filter",
  DropDownCategory = "Menu DropDown Category",
}

enum MenuAction {
  Open = "opened",
  Close = "Closed",
  Selected = "Selected",
}

enum Profile {
  Footer = "Footer Profile"
}

enum ProfileAction {
  clicked = "Profile Clicked",
  Activated = "Service Activated",
  Deactivate = "Service DeActivated",
}

enum Services {
  TableReservation = "TableReservation Clicked",
  DigitalMenu = "DigitalMenu Clicked",
  WhatsAppBusiness = "WhatsAppBusiness Clicked",
  SocialProfile = "SocialProfile Clicked",
}
export const AnalyticEvents = {
  Wallet,
  WalletAction,
  Menu,
  MenuAction,
  Profile,
  ProfileAction,
  Services
};


