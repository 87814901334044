// @ts-nocheck
import * as React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import Bin from "../../RestaurantMenu/Images/Bin.png";
import { deleteMenu } from "../../API/authMenuAPIs";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styleSheets/confirmation-dialog.css";
import "../../styleSheets/more-options.css";
import { setApiData, setFastSocialData } from "../../redux/actions";
import {
  deleteEvent,
  deleteLink,
  deletePhoto,
} from "../../API/fast_social_apis";
import { updateNestedRedux } from "../../utils/updateNestedRedux";
import { AddUserButton } from "../../Components/Button.style";

function Delete({
  type,
  objId,
  data,
  setData,
  setItemCount,
  setIsDeleted,
  open,
  setOpen,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleClose = () => {
    if (type == "Link" || type == "Photo") {
      setOpen(false);
      return;
    }

    setOpen((prev) => ({ ...prev, delete: false }));
  };

  async function handleDelete() {
    if (type == "Link") {
      if (!objId.isFromAPI) {
        setIsDeleted();
        setOpen(false);
        return;
      }
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get("accountId");
      const dataReceived = await deleteLink(accountId, objId);
      updateNestedRedux(
        data,
        `Links`,
        dataReceived.responseData,
        dispatch,
        setFastSocialData,
      );
      setOpen(false);
      return;
    }

    if (type == "Photo") {
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get("accountId");
      const dataReceived = await deletePhoto(accountId, objId);
      updateNestedRedux(
        data,
        `["Media Section"].media`,
        dataReceived.responseData,
        dispatch,
        setFastSocialData,
      );
      setOpen(false);

      return;
    }

    if (type == "Event") {
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get("accountId");
      const dataReceived = await deleteEvent(accountId, objId);
      updateNestedRedux(
        data,
        `Events`,
        dataReceived.responseData,
        dispatch,
        setFastSocialData,
      );
      setOpen(false);

      return;
    }

    const menuData = await deleteMenu(objId, type, navigate);
    console.log(menuData.body.categories);
    if (type == "Category") {
      await setData(menuData.body.categories);
      setOpen((prev) => ({ ...prev, delete: false }));
      dispatch(setApiData(menuData.body));
    }
    if (type == "SubCategory") {
      await setData(menuData.body.subCategories);
      setOpen((prev) => ({ ...prev, delete: false }));
    }
    if (type == "Item") {
      await setData(menuData.body.items);
      if (setItemCount && setIsDeleted) {
        setItemCount((itemCount) => itemCount - 1);
        setIsDeleted(true);
      }
      setOpen((prev) => ({ ...prev, delete: false }));
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.deleteModal} className="rounded-3">
          <div className="p-2">
            <p className="heading">Do you want to delete this {type}?</p>
            <p className="action-undone">This action can't be undone</p>
          </div>

          <div className="d-flex justify-content-between pt-2 pb-1">
            <AddUserButton
              backgroundColor="#D1D1D1"
              marginTop="-5px"
              onClick={() => navigate(0)}
            >
              No
            </AddUserButton>
            <AddUserButton
              backgroundColor="#D1D1D1"
              marginTop="-5px"
              onClick={handleDelete}
            >
              Yes
            </AddUserButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default Delete;
