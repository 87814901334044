// @ts-nocheck
import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import "./style.css"

export const Loader = ({message, open, setOpen, freezeWhileLoading = true}) => {
  const loaderCloseHandler = () => !freezeWhileLoading && setOpen(false)
  return (
    <Modal
      open={open}
      onClose={loaderCloseHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="loader_outermost_div px-2 d-flex justify-content-center align-items-center rounded-1">
        {open && <CircularProgress style={{width: "30px", height: "30px"}}/>}
        <span className="ms-3 loader_message">{message}</span>
      </div>

    </Modal>

  )
}