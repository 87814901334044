// @ts-nocheck

import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import "../../styleSheets/pop_up_2option.css";
import OptionIcon from "../../assets/icons/Social_icons/3dot.svg";
import { cancelBookingAPI } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";

export const BookingPopups = ({bookingData}) => {
  const navigate = useNavigate()

  const [headerOptionEl, setHeaderOptionEl] = useState(null);

  const optionState = Boolean(headerOptionEl);
  const handleClick = (event) => {
    const Option = event.currentTarget.querySelector("img");
    setHeaderOptionEl(Option);
  };
  const handleClose = () => {
    setHeaderOptionEl(null);
  };


  const onClickCancelHandler = async() => {
    const dataReceived = await cancelBookingAPI(bookingData?.id, navigate)
  }

  return (
    <div className="z-3">
      <IconButton
        id="pop-2option"
        aria-controls={optionState ? "header-section" : undefined}
        aria-haspopup="true"
        aria-expanded={optionState ? "true" : undefined}
        onClick={handleClick}
        disableRipple
        className="p-2"
      >
        <img src={OptionIcon} className="pb-2" />
      </IconButton>

      <Menu
        onKeyDownCapture={(e) => e.stopPropagation()}
        anchorEl={headerOptionEl}
        open={optionState}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="pop-up-class-2"
      >
        <div className=" rounded-5 d-flex flex-column justify-content-center p-0 w-fit pop-up-2">
          <div>
            <button className="m-2 d-flex justify-content-center align-items-center booking-popup-button">
              Share Booking
            </button>
            <button onClick={onClickCancelHandler} className="m-2 d-flex justify-content-center align-items-center booking-popup-button">
              Cancel Booking
            </button>
          </div>
        </div>
      </Menu>
    </div>
  );
};
