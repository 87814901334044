// @ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./cart.module.css";
import image from "../../../src/assets/images/Baskin AND Robin.png";

const SelectedItems = ({
  item,
  index,
  updateCart,
  updateBill,
  getItemPrice,
}) => {
  const [ItemCount, setItemCount] = useState(1);

  useEffect(() => {
    updateBill(index, ItemCount);
  }, [ItemCount]);

  const HandleItemCount = (e) => {
    let newCount = ItemCount;
    if (e.target.textContent === "+") {
      newCount += 1;
    } else if (newCount > 1) {
      newCount -= 1;
    } else if (newCount === 1 && e.target.textContent === "-") {
      updateCart(index);
      return;
    }

    setItemCount(newCount);
  };

  return (
    <div
      className={`d-flex align-items-center p-2 mb-3 border rounded ${styles.border}`}
    >
      <img
        src={item.image ? item.image : image}
        alt={item.itemName}
        className={`me-3 ${styles.itemImage}`}
      />
      <div className="flex-grow-1">
        <h5 className="mb-1" style={{ fontSize: "small" }}>
          {item?.itemName}
        </h5>
        <p className="mb-1" style={{ fontSize: "smaller" }}>
          ₹ {getItemPrice(item)}
        </p>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        style={{ gap: "10px", marginLeft: "10px" }}
      >
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={HandleItemCount}
          style={{
            padding: "2px",
            fontSize: "10px",
            height: "20px",
            width: "20px",
          }}
        >
          -
        </button>
        <span className="my-2" style={{ textAlign: "center" }}>
          {" "}
          {ItemCount}
        </span>
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={HandleItemCount}
          style={{
            padding: "2px",
            fontSize: "10px",
            height: "20px",
            width: "20px",
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default SelectedItems;
