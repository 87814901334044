// @ts-nocheck

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCategory, addSubCategory } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { Overlay_Animation } from "../../utils/Overlay-animation";
import { Cat_Subcat_Layout } from "../CategoryPage/Cat-Subcat-Layout";
import { setApiData } from "../../redux/actions";

export const Add_Cat_Subcat = ({
  type,
  setData,
  data,
  objId,
  setWholeData,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleOverlayShow = () => setShowOverlay(true);

  const selectedImage = "";

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes(`add_cat_subcat_div`) ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay(false);
      setShowDiv(false);
      setCategoryName("");
      setError(false);
    }
  };

  const editDetails = async () => {
    setError(false);
    if (type == "Category") {
      const errorFound = data?.find(
        (el) => el?.category?.toLowerCase() === categoryName?.toLowerCase(),
      );
      if (errorFound) {
        console.log("Error found");
        setError(true);
        return;
      }

      const catData = await addCategory(categoryName, selectedImage, navigate);
      await setData(catData?.body.menu.categories);
      await setWholeData(catData?.body.menu);
      dispatch(setApiData(catData?.body.menu));
      setCategoryName("");
      setShowOverlay(false);
      setShowDiv(false);
    }

    if (type == "SubCategory") {
      const errorFound = data?.find(
        (el) =>
          el?.subCategory?.toLowerCase() === categoryName?.toLowerCase() &&
          el?.category_id === objId,
      );
      if (errorFound) {
        console.log("Error found");
        setError(true);
        return;
      }

      const subData = await addSubCategory(
        categoryName,
        selectedImage,
        objId,
        navigate,
      );
      await setData(subData.body.menu.subCategories);
      await setWholeData(subData.body.menu);
      setCategoryName("");
      setShowOverlay(false);
      setShowDiv(false);
    }
  };

  return (
    <div id="edit">
      <div
        id="handleOverlayDiv"
        style={{ cursor: "pointer" }}
        onClick={handleOverlayShow}
      >
        Add {type === "Category" && "New"} {type}{" "}
      </div>
      <Overlay_Animation
        className="add_cat_subcat_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <Cat_Subcat_Layout
            type={type}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
            error={error}
            onClickBtn={editDetails}
          />
        }
      />
    </div>
  );
};
