//@ts-nocheck
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApiData, setFastSocialData, setMenuAccess } from "../redux/actions";
import Carousel from "./CategoryPage/Carousel";
import CategorySection from "./CategoryPage/CategorySection";
import Header from "./CategoryPage/Header";
import { useParams, useNavigate, Navigate } from "react-router-dom";
// import { NoSim } from "@mui/icons-material";
import { USER } from "../constants/localStorageKeys";
import { ANNOUNCEMENT, MENU_API, baseURL } from "../constants/Api";
import { menuAPI } from "../API/MenuApi";
import classes from "../styleSheets/FoodMenu.module.css";
// import Sidebar from "../Components/Sidebar";
// import CategorySectionLoader from "./Loaders/CategorySectionLoader";
import Loader from "../assets/images/images/Loader.jpeg";
import { getUserProfile } from "../API/authMenuAPIs";
import { CarouselImageDragDrop } from "./CategoryPage/Carousel_image_drag_drop";
import { CSSTransition } from "react-transition-group";
import "../styleSheets/react-transition.css";
import { menuAccessAPI } from "../API/menu_role_access";
import Marquee from "react-fast-marquee";
// import { getSocialProfile } from "../API/authAPIs";
import { getAllData } from "../API/fast_social_apis";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import ProfileFooter from "../Components/ProfileFooter";
import { EventsInMenu } from "./eventsMenu";

const MainMenu = ({
  selectedProfile,
  setSelectedProfile,
  setId,
  setBusinessProfileName,
  setBusinessUserName,
}: {
  selectedProfile: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProfile: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setBusinessProfileName: React.Dispatch<React.SetStateAction<string>>;
  setBusinessUserName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // console.log('i am just checkingggggg')
  const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);
  const [carouselData, setCarouselData] = useState([]);
  const [coverDragState, setCoverDragState] = useState(false);
  const [authenticated, setAuthenticated] = useState<string>("");
  const [message, setMessage] = useState<object>("");
  const isMobile = window.screen.width;
  const [data, setData] = useState<{
    active?: boolean;
    selectedPublicTheme?: "v1" | "v2" | "v3";
    announcement?: string;
  }>({});
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const apiData = useSelector<{ menu: { apiData: string } }>(
    (state) => state.menu.apiData,
  );

  const timestamp = useSelector<{ menu: { timestamp: number } }>(
    (state) => state.menu.timestamp,
  );
  const menuAccessState = useSelector<{ menuAccess: { apiData: string } }>(
    (state) => state.menuAccess.apiData,
  );
  const [showFooter, setShowFooter] = useState<boolean>(true);

  const fastSocialAPiData = useSelector((state) => state.fastSocial.apiData);
  // const [digitalMenuStatus, setDigitalMenuStatus] = useState(true);
  // console.log(name2, "nnn");

  // console.log(resName, "resssNamee");
  const { name1, name2 } = useParams();

  // let name;
  console.log(name2, "NAME2");
  console.log(name1, "NAME1");

  const name = name2 || name1;
  console.log(name, "MENU NAME");

  const announceFunction = async () => {
    const response = await fetch(`${baseURL}/${ANNOUNCEMENT.FETCH_ALL}`);
    const announcement = await response.json();
    if (announcement && announcement.length > 0) {
      setMessage(announcement[0].Message);
      console.log(message, "MESSAGE");
    } else {
      setMessage("No announcement available");
    }
  };

  const callApis = useCallback(
    async (savedToken?: string) => {
      if (timestamp) {
        const currentTime = new Date();
        const timeDiff = +currentTime - +timestamp;
        const minutesDiff = timeDiff / (1000 * 60);
        if (minutesDiff <= 20 && apiData && Object.keys(apiData).length !== 0) {
          setData(apiData);
          if (savedToken) {
            const returnedData = await getUserProfile(
              `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
              navigate,
            );
            setUserData(returnedData);
          }

          return;
        }
      }

      setLoading(true);
      const data1 = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
      /* if (!data1) {
        Navigate("/DigitalMenuActivation");
      } */
      setData(data1);

      dispatch(setApiData(data1));
      console.log(data1, "+++++++++++++");

      const fastSocialData = await getAllData(name, navigate);
      dispatch(setFastSocialData(fastSocialData));
      if (savedToken) {
        const returnedData = await getUserProfile(
          `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
          navigate,
        );
        setUserData(returnedData);
        const menuAccessData = await menuAccessAPI(data1, navigate);
        dispatch(setMenuAccess(menuAccessData));
        if (menuAccessData) {
          console.log(setBusinessProfileName, "=========================");
          setBusinessProfileName && setBusinessProfileName(data1?.title);
          setSelectedProfile && setSelectedProfile(data1?.user);
          setBusinessUserName && setBusinessUserName(data1?.restrauntName);
          localStorage.setItem(USER.USERNAME, data1?.restrauntName);
          localStorage.setItem(USER.businessId, data1?.user);
          localStorage.setItem(USER.businessName, data1?.title);
        } else {
          if (
            data1?.selectedPublicTheme &&
            data1?.selectedPublicTheme === "v2"
          ) {
            navigate(`/menu/v2/${name}`);
          } else {
            navigate(`/menu/${name}`);
          }
        }
      } else {
        dispatch(setMenuAccess(false));
        if (data1?.selectedPublicTheme && data1?.selectedPublicTheme === "v2") {
          navigate(`/menu/v2/${name}`);
        } else if (
          data1?.selectedPublicTheme &&
          data1?.selectedPublicTheme === "v3"
        ) {
          navigate(`/menu/v3/${name}`);
        } else {
          navigate(`/menu/${name}`);
        }
      }

      setLoading(false);
    },
    [
      apiData,
      dispatch,
      name,
      navigate,
      setBusinessProfileName,
      setBusinessUserName,
      setSelectedProfile,
      timestamp,
    ],
  );
  const resName = localStorage.getItem(USER.USERNAME);

  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN) || "";
    callApis(savedToken);
    announceFunction();
    console.log(data, "setcategory data");
    if (savedToken) {
      setAuthenticated(savedToken);
      setId?.("2");
      if (!menuAccessState) {
        if (data?.selectedPublicTheme && data?.selectedPublicTheme === "v2") {
          navigate(`/menu/v2/${name}`);
        } else if (
          data?.selectedPublicTheme &&
          data?.selectedPublicTheme === "v3"
        ) {
          navigate(`/menu/v3/${name}`);
        } else {
          navigate(`/menu/${name}`);
        }
      }
    } else navigate(`/menu/${name}`);
  }, [name, menuAccessState]);

  useEffect(() => {
    const analyticsInstance = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analyticsInstance.sendPageView(`/menu/edit/${name}`, "Menu Category");
    setAnalytics(analyticsInstance);
  }, [name]);

  return (
    <>
      <div className={classes.container1}>
        <div className={classes.container2}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <div>
                {" "}
                <img
                  className={classes.loader}
                  style={{ textAlign: "center" }}
                  src={Loader}
                  alt="loader"
                />
              </div>
              <div>Loading</div>
            </div>
          ) : (
            <>
              {data?.active === false ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "40vh 0 0 ",
                  }}
                >
                  Services Not Available
                </div>
              ) : (
                <>
                  <Header
                    userData={userData}
                    setUserData={setUserData}
                    selectedProfile={selectedProfile}
                    data={data}
                    setData={setData}
                    Name={name}
                  />
                  {fastSocialAPiData["Basic Details"]?.announcement && (
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="text-announcement text-center p-2 text-nowrap overflow-hidden">
                        <Marquee /* pauseOnHover={true} */>
                          <div style={{ margin: "0 6rem" }}>
                            {fastSocialAPiData["Basic Details"]?.announcement}
                          </div>
                        </Marquee>
                      </div>
                    </a>
                  )}
                  <CSSTransition
                    in={coverDragState}
                    timeout={300}
                    classNames="cover-drag-1"
                    unmountOnExit
                  >
                    <CarouselImageDragDrop
                      data={data}
                      setData={setData}
                      carouselData={carouselData}
                      setCarouselData={setCarouselData}
                      setCoverDragState={setCoverDragState}
                    />
                  </CSSTransition>

                  <CSSTransition
                    in={!coverDragState}
                    timeout={600}
                    classNames="cover-drag-2"
                    unmountOnExit
                  >
                    <div>
                      <Carousel
                        name={name}
                        selectedProfile={selectedProfile}
                        data={data}
                        setData={setData}
                        // loading={loading}
                        setCoverDragState={setCoverDragState}
                        carouselData={carouselData}
                        setCarouselData={setCarouselData}
                      />
                      <CategorySection
                        announcementMessage={message}
                        name={name}
                        // selectedProfile={selectedProfile}
                        data={data}
                        setData={setData}
                        loadingImage={loading}
                        analytics={analytics}
                        setAnalytics={setAnalytics}
                      />
                    </div>
                  </CSSTransition>

                  {!menuAccessState ? (
                    <ProfileFooter fastSocialAPiData={fastSocialAPiData} />
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default MainMenu;
