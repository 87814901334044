// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../styleSheets/user-business-box.css";
import classes from "../styleSheets/UpdateProfile.module.css";
import classes1 from "../styleSheets/chooseCategory.module.css";
import "../styleSheets/Arrow.css";
import { GreenButton, AddUserButton } from "../Components/Button.style";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import {
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  CssBaseline,
} from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import {
  addNewUser,
  deleteUser,
  editUser,
  getbusinessProfiles,
} from "../API/authAPIs";
import { MENU_API, baseURL, ROLES } from "../constants/Api";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import DeleteUser from "./DeleteUser";
import { USER } from "../constants/localStorageKeys";
import CircularUnderLoad from "../Components/CircularLoader";
import loaderStyler from "../styleSheets/loader.module.css";
import EditUser from "./EditUser";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const BusinessAccess = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get("id");
  const [loader, setLoader] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkRole, setCheckRole] = useState(false);
  const [checkNum, setCheckNum] = useState(false);

  const [profiles, setProfiles] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [id, setId] = useState("");
  const [delUser, setDeleteUser] = useState(false);
  const [checkNumber, setCheckNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const handleCloseWarning = () => {
    setOpenWarning(false);
  };
  const checkEditAccess = async () => {
    let output;
    const userDetails = profiles?.filter((i) => {
      return (
        i.mobileNumber === Number(localStorage.getItem(USER.MOBILE_NUMBER))
      );
    });
    const accountUserRole = ROLES(userDetails[0]?.role);
    console.log(accountUserRole, role);
    if (
      (accountUserRole === "Manager" && role === "Admin") ||
      ((accountUserRole === "Staff" || accountUserRole === "Accounts") &&
        (role === "Admin" || role === "Manager"))
    ) {
      return (output = false);
    } else {
      return (output = true);
    }
  };

  const handleOpen = async (title) => {
    const result = await checkEditAccess();
    console.log(result);
    if (result === false) {
      setOpenWarning(true);
    } else {
      setOpen(true);
      setTitle(title);
    }
  };
  const handleClose = () => {
    setOpen(false);
    // setAnchorEl(false)
  };

  const handleClick = (event, id, name, number, role) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setName(name);
    setPhoneNumber(number);
    setCheckNumber(number);
    setRole(ROLES(role));
    console.log("anchor", delUser);
  };

  const businessId = localStorage.getItem(USER.businessId);
  const handleClose1 = () => {
    setAnchorEl(null);
    setId("");
    setName("");
    setPhoneNumber("");
    setRole("");
    setCheckNumber("");
  };
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const callAPIs = async () => {
    setLoader(true);
    const businessData = await getbusinessProfiles(
      `${baseURL}${MENU_API.BUSINESS_USERS_PROFILE}/?id=${profileId}`,
      navigate,
    );
    console.log(businessData, "business");
    setProfiles(businessData);
    setLoader(false);
  };
  useEffect(() => {
    callAPIs();
  }, []);
  //  console.log(profiles,'business')
  const controlProps = (item) => ({
    checked: role === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleUser = async () => {
    if (!phoneNumber.toString().match(/^\d{10}$/)) {
      setCheckNum(true);
      return;
    }
    if (name === "") {
      setCheckName(true);
      return;
    }
    if (role === "") {
      setCheckRole(true);
      return;
    }

    if (title == "Add") {
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i].mobileNumber == phoneNumber) {
          alert("User with this number already exists");
          return;
        }
      }
      setLoading(true);
      const AddRole = ROLES(role);

      const addUser = await addNewUser(
        name,
        phoneNumber,
        AddRole,
        profileId,
        navigate,
      );

      setProfiles(addUser.body);
      setName("");
      setRole("");
      setPhoneNumber("");
      setLoading(false);
      setOpen(false);
    }
    if (title == "Edit") {
      for (var i = 0; i < profiles.length; i++) {
        if (
          profiles[i].mobileNumber == phoneNumber &&
          profiles[i].mobileNumber != checkNumber
        ) {
          alert("User with this number already exists");
          return;
        }
      }
      setLoading(true);
      const Role = await ROLES(role);
      const edit = await editUser(
        id,
        name,
        phoneNumber,
        Role,
        profileId,
        navigate,
      );
      console.log(profiles, "ab");
      console.log(edit.body, "up");
      setProfiles(edit.body);
      console.log(profiles, "up");
      setName("");
      setRole("");
      setPhoneNumber("");
      setLoading(false);
      setOpen(false);
      setAnchorEl(false);
    }
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/BusinessAccess`, "BusinessAccess Page");
  }, []);

  return (
    <>
      {editOpen ? (
        <EditUser
          profiles={profiles}
          setProfiles={setProfiles}
          id={id}
          name={name}
          phoneNumber={phoneNumber}
          profileId={profileId}
          checkNumber={checkNumber}
          role={role}
          setName={setName}
          setPhoneNumber={setPhoneNumber}
          setEditOpen={setEditOpen}
          setRole={setRole}
        />
      ) : (
        <div>
          <Menu
            id="demo-positioned-menu"
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <Typography sx={customizeMUIComponents.addUserText}></Typography> */}
            {/* <Typography sx ={customizeMUIComponents.popupModal}>
          
        </Typography> */}
            <div
              className="edit-div-btn1"
              style={{
                margin: "10px",
                cursor: "pointer",
                width: "80px",
                height: "45px",
              }}
              onClick={() => {
                setEditOpen(true);
                setAnchorEl(null);
              }}
            >
              Edit User
            </div>
            <div
              style={{ margin: "10px", cursor: "pointer" }}
              // onClick={() => setDeleteUser(true)}
            >
              {
                <DeleteUser
                  id={id}
                  delUser={delUser}
                  setDeleteUser={setDeleteUser}
                  setAnchorEl={setAnchorEl}
                  setProfile={setProfiles}
                  setName={setName}
                  setRole={setRole}
                  setPhoneNumber={setPhoneNumber}
                  profileId={profileId}
                  profiles={profiles}
                  role={role}
                />
              }
            </div>
          </Menu>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className={classes.edit_dish}
              // sx={{ height:"100%"}}
            >
              {loading ? (
                <>Updating..</>
              ) : (
                <>
                  <Typography
                    className="edit-user-popup"
                    sx={customizeMUIComponents.popupModal}
                  >
                    <Typography sx={customizeMUIComponents.title}>
                      {title} User
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <FormControl
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                          }}
                        >
                          <RadioGroup
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                width: "20px",
                                height: "20px",
                                backgroundColor: "red",
                                display: "inline-flex",
                                justifyContent: "left",
                              },
                            }}
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="none"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Admin"
                              control={
                                <Radio
                                  size="small"
                                  {...controlProps("Admin")}
                                  sx={{
                                    color: "#6A75CA",
                                    "&.Mui-checked": {
                                      color: "#6A75CA",
                                    },
                                  }}
                                />
                              }
                              label="Admin"
                            />
                            <FormControlLabel
                              value="Manager"
                              control={
                                <Radio
                                  size="small"
                                  {...controlProps("Manager")}
                                  sx={{
                                    color: "#6A75CA",
                                    "&.Mui-checked": {
                                      color: "#6A75CA",
                                    },
                                  }}
                                />
                              }
                              label="Manager"
                            />
                            <FormControlLabel
                              value="Staff"
                              control={
                                <Radio
                                  size="small"
                                  {...controlProps("Staff")}
                                  sx={{
                                    color: "#6A75CA",
                                    "&.Mui-checked": {
                                      color: "#6A75CA",
                                    },
                                  }}
                                />
                              }
                              label="Staff"
                            />
                            <FormControlLabel
                              value="Accounts"
                              control={
                                <Radio
                                  size="small"
                                  {...controlProps("Accounts")}
                                  sx={{
                                    color: "#6A75CA",
                                    "&.Mui-checked": {
                                      color: "#6A75CA",
                                    },
                                  }}
                                />
                              }
                              label="Accounts"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <input
                        type="text"
                        className="edit-user-name-box"
                        style={customizeMUIComponents.businessAccessInputBox}
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                      />

                      <div>
                        <Typography sx={customizeMUIComponents.addUserText}>
                          Select User Role{" "}
                          {checkRole && (
                            <span style={{ color: "#EF6C00" }}>*</span>
                          )}
                        </Typography>
                        <div>
                          <FormControl
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <RadioGroup
                              sx={{
                                "& .PrivateSwitchBase-input": {
                                  width: "20px",
                                  height: "20px",
                                  backgroundColor: "red",
                                  display: "inline-flex",
                                  justifyContent: "left",
                                },
                              }}
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="none"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                value="Admin"
                                control={
                                  <Radio
                                    size="small"
                                    {...controlProps("Admin")}
                                    sx={{
                                      color: "#27343C",
                                      "&.Mui-checked": {
                                        color: "#27343C",
                                      },
                                    }}
                                  />
                                }
                                label="Admin"
                              />
                              <FormControlLabel
                                value="Manager"
                                control={
                                  <Radio
                                    size="small"
                                    {...controlProps("Manager")}
                                    sx={{
                                      color: "#27343C",
                                      "&.Mui-checked": {
                                        color: "#27343C",
                                      },
                                    }}
                                  />
                                }
                                label="Manager"
                              />
                              <FormControlLabel
                                value="Staff"
                                control={
                                  <Radio
                                    size="small"
                                    {...controlProps("Staff")}
                                    sx={{
                                      color: "#27343C",
                                      "&.Mui-checked": {
                                        color: "#27343C",
                                      },
                                    }}
                                  />
                                }
                                label="Staff"
                              />
                              <FormControlLabel
                                value="Accounts"
                                control={
                                  <Radio
                                    size="small"
                                    {...controlProps("Accounts")}
                                    sx={{
                                      color: "#27343C",
                                      "&.Mui-checked": {
                                        color: "#27343C",
                                      },
                                    }}
                                  />
                                }
                                label="Accounts"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <Typography sx={customizeMUIComponents.addUserText}>
                          Phone Number{" "}
                          {checkNum && (
                            <span style={{ color: "#EF6C00" }}>*</span>
                          )}
                        </Typography>

                        <div className="phone-box">
                          <div style={{ display: "flex" }}>
                            <select
                              value={countryCode}
                              onChange={(e) => setCountryCode(e.target.value)}
                              style={{
                                paddingLeft: "5px",
                                color: "#27343C",
                                padding: "1px",
                                marginRight: "1px",
                                borderStyle: "none",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "12px",
                                textAlign: "center",
                              }}
                            >
                              <option value="+91">+91</option>
                            </select>
                            <span className="vertical-bar-1">|</span>
                            <div className="enter-num-txt">
                              <input
                                type="tel"
                                style={{
                                  paddingLeft: "20px",
                                  color: "#000000",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "12px",
                                  borderStyle: "none",
                                  outline: "none",
                                }}
                                placeholder="Enter Your Mobile No"
                                value={phoneNumber}
                                onChange={(e) => {
                                  setPhoneNumber(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <input
                    type="tel"
                    style={customizeMUIComponents.businessAccessInputBox}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  /> */}
                      </div>
                      {checkNum == true ? (
                        <div className={classes.mandatoryText}>
                          *Add correct number
                        </div>
                      ) : (
                        ""
                      )}
                      {(name && phoneNumber && role) === "" ? (
                        <div
                          className={`{classes.mandatoryText} inval-numb-txt`}
                        >
                          All Details are mandatory
                        </div>
                      ) : (
                        ""
                      )}

                      <div style={customizeMUIComponents.row}>
                        <AddUserButton
                          backgroundColor="#7ECAB0"
                          marginTop="10px"
                          variant="outlined"
                          onClick={handleClose}
                        >
                          Cancel
                        </AddUserButton>
                        <AddUserButton
                          backgroundColor="#7ECAB0"
                          marginTop="10px"
                          variant="contained"
                          onClick={handleUser}
                        >
                          {title}
                        </AddUserButton>
                      </div>
                    </div>
                  </Typography>
                </>
              )}
            </Box>
          </Modal>
          <div className={``}>
            <div className="bdy-align-txt">
              <div
                className={`d-flex h-100 align-items-center position-relative`}
              >
                <div className="ms-2">
                  <KeyboardBackspaceIcon
                    className="back-arrow-click"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div
                  className={` text-white position-absolute translate-middle`}
                  style={{ top: "50%", left: "50%" }}
                >
                  User Business Access
                </div>
              </div>
            </div>
            <div className={classes.main}>
              {loader ? (
                <div className={loaderStyler.accessContainer}>
                  <CircularUnderLoad />
                </div>
              ) : (
                <>
                  <div
                    className={`${classes.innerDiv} d-flex justify-content-center align-items-center details-box`}
                  >
                    {profiles?.map((item, index) => {
                      return (
                        <div key={index}>
                          <Box
                            sx={{
                              width: "284px",
                              height: "96px",
                              MaringTop: "133px",
                              borderRadius: "5px",
                              backgroundColor: "#000000",
                            }}
                          >
                            <div className={`${classes.accessDiv} main1`}>
                              <div className={`${classes.column} main1-texts`}>
                                <div
                                  className={`${classes.accessText} left-div-top `}
                                >
                                  Name:
                                </div>
                                <div
                                  className={`${classes.accessText} left-div-middle`}
                                >
                                  Phone No.:
                                </div>
                                <div
                                  className={`${classes.accessText} left-div-bottom`}
                                >
                                  Role:
                                </div>
                              </div>

                              <div className={classes.column}>
                                <div className={classes.accessText}>
                                  {!item.name ? (
                                    <div></div>
                                  ) : (
                                    <div>{item?.name}</div>
                                  )}
                                </div>
                                <div className={`${classes.accessText}`}>
                                  {!item?.mobileNumber ? (
                                    <div></div>
                                  ) : (
                                    <>{item?.mobileNumber}</>
                                  )}
                                </div>
                                <div className={`${classes.adminText}`}>
                                  {!item?.role ? (
                                    <div></div>
                                  ) : (
                                    <>{ROLES(item?.role)}</>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "0",
                                  width: "30px",
                                  height: "30px",
                                  marginRight: "-5px",
                                }}
                              >
                                <MoreVertIcon
                                  onClick={(e) => {
                                    handleClick(
                                      e,
                                      item.userId,
                                      item.name,
                                      item.mobileNumber,
                                      item.role,
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "220px",
                  width: "100%",
                }}
              >
                <Link to={`/AddUser?id=${profileId}`}>
                  <GreenButton
                    backgroundColor="#D1D1D1"
                    onClick={() => handleOpen("Add")}
                  >
                    Add User
                  </GreenButton>
                </Link>

                {/* <Modal
              open={openWarning}
              onClose={handleCloseWarning}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={customizeMUIComponents.warningModal}>
                <Typography sx={customizeMUIComponents.title}>
                  This Action cannot be performed
                </Typography>
              </Box>
            </Modal>
              Add User
            </Button> */}
                <Modal
                  open={openWarning}
                  onClose={handleCloseWarning}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={customizeMUIComponents.warningModal}>
                    <Typography sx={customizeMUIComponents.title}>
                      This Action cannot be performed
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessAccess;
