//@ts-nocheck
import React, { useState } from 'react';

const PostFooter = ({ initialLikes = 54, initialShares = 12 }) => {
  const [likes, setLikes] = useState(initialLikes);
  const [isLiked, setIsLiked] = useState(false);

  const handleLikeClick = () => {
    if (isLiked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setIsLiked(!isLiked);
  };

  return (
    <div className="d-flex align-items-center p-3 ">
      {/* Heart Icon and Likes */}
      <div className="d-flex align-items-center me-4" onClick={handleLikeClick} style={{ cursor: 'pointer' }}>
        {isLiked ? (
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30772 6.20562C5.20785 5.30576 6.42853 4.80024 7.70132 4.80024C8.97411 4.80024 10.1948 5.30576 11.0949 6.20562L12.5013 7.61082L13.9077 6.20562C14.3505 5.74717 14.8802 5.38149 15.4658 5.12993C16.0514 4.87837 16.6812 4.74595 17.3186 4.74042C17.9559 4.73488 18.588 4.85633 19.1779 5.09767C19.7678 5.33902 20.3037 5.69544 20.7544 6.14612C21.2051 6.59681 21.5615 7.13274 21.8029 7.72264C22.0442 8.31254 22.1657 8.9446 22.1601 9.58194C22.1546 10.2193 22.0222 10.8491 21.7706 11.4348C21.519 12.0204 21.1534 12.55 20.6949 12.9928L12.5013 21.1876L4.30772 12.9928C3.40786 12.0927 2.90234 10.872 2.90234 9.59922C2.90234 8.32643 3.40786 7.10575 4.30772 6.20562Z" fill="#828282"/>
          </svg>
        ) : (
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30772 6.20562C5.20785 5.30576 6.42853 4.80024 7.70132 4.80024C8.97411 4.80024 10.1948 5.30576 11.0949 6.20562L12.5013 7.61082L13.9077 6.20562C14.3505 5.74717 14.8802 5.38149 15.4658 5.12993C16.0514 4.87837 16.6812 4.74595 17.3186 4.74042C17.9559 4.73488 18.588 4.85633 19.1779 5.09767C19.7678 5.33902 20.3037 5.69544 20.7544 6.14612C21.2051 6.59681 21.5615 7.13274 21.8029 7.72264C22.0442 8.31254 22.1657 8.9446 22.1601 9.58194C22.1546 10.2193 22.0222 10.8491 21.7706 11.4348C21.519 12.0204 21.1534 12.55 20.6949 12.9928L12.5013 21.1876L4.30772 12.9928C3.40786 12.0927 2.90234 10.872 2.90234 9.59922C2.90234 8.32643 3.40786 7.10575 4.30772 6.20562Z" stroke="#828282" stroke-width="2"/>
          </svg>
        )}
        <span className="ms-2" style={{ color: '#828282' }}>{likes}</span>
      </div>

      {/* Share Icon and Shares */}
      <div className="d-flex align-items-center">
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5002 7.45863L9.90019 1.09961L9.90019 4.69961C1.5 6.49961 1.5 14.8996 1.5 14.8996C1.5 14.8996 5.1 10.0996 9.90019 10.6996L9.90019 14.4196L19.5002 7.45863Z" stroke="#828282" stroke-width="2" stroke-linejoin="round"/>
        </svg>
        <span className="ms-2" style={{ color: '#828282' }}>{initialShares}</span>
      </div>
    </div>
  );
};

export default PostFooter;
