import { useState, useEffect } from "react";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { baseURL } from "../../constants/Api";
import { FloatingContainer } from "../../Components/FloatingInput";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import dayjs from "dayjs";
import customParser from "dayjs/plugin/customParseFormat";

dayjs.extend(customParser);

const WorkingHoursManagement = () => {
  const [restaurantId, setRestaurantId] = useState("");

  const notifyModified = () => toast("Working hours Modified");
  const notifyError = (msg: string) => toast(msg, { type: "error" });

  const [mondayStartHour, setMondayStartHour] = useState("10");
  const [mondayStartMinute, setMondayStartMinute] = useState("00");
  const [mondayStartAMPM, setMondayStartAMPM] = useState<"AM" | "PM">("AM");
  const [mondayEndHour, setMondayEndHour] = useState("11");
  const [mondayEndMinute, setMondayEndMinute] = useState("00");
  const [mondayEndAMPM, setMondayEndAMPM] = useState<"AM" | "PM">("PM");

  const [tuesdayStartHour, setTuesdayStartHour] = useState("10");
  const [tuesdayStartMinute, setTuesdayStartMinute] = useState("00");
  const [tuesdayStartAMPM, setTuesdayStartAMPM] = useState<"AM" | "PM">("AM");
  const [tuesdayEndHour, setTuesdayEndHour] = useState("11");
  const [tuesdayEndMinute, setTuesdayEndMinute] = useState("00");
  const [tuesdayEndAMPM, setTuesdayEndAMPM] = useState<"AM" | "PM">("PM");

  const [wednesdayStartHour, setWednesdayStartHour] = useState("10");
  const [wednesdayStartMinute, setWednesdayStartMinute] = useState("00");
  const [wednesdayStartAMPM, setWednesdayStartAMPM] = useState<"AM" | "PM">(
    "AM",
  );
  const [wednesdayEndHour, setWednesdayEndHour] = useState("11");
  const [wednesdayEndMinute, setWednesdayEndMinute] = useState("00");
  const [wednesdayEndAMPM, setWednesdayEndAMPM] = useState<"AM" | "PM">("PM");

  const [thursdayStartHour, setThursdayStartHour] = useState("10");
  const [thursdayStartMinute, setThursdayStartMinute] = useState("00");
  const [thursdayStartAMPM, setThursdayStartAMPM] = useState<"AM" | "PM">("AM");
  const [thursdayEndHour, setThursdayEndHour] = useState("11");
  const [thursdayEndMinute, setThursdayEndMinute] = useState("00");
  const [thursdayEndAMPM, setThursdayEndAMPM] = useState<"AM" | "PM">("PM");

  const [fridayStartHour, setFridayStartHour] = useState("10");
  const [fridayStartMinute, setFridayStartMinute] = useState("00");
  const [fridayStartAMPM, setFridayStartAMPM] = useState<"AM" | "PM">("AM");
  const [fridayEndHour, setFridayEndHour] = useState("11");
  const [fridayEndMinute, setFridayEndMinute] = useState("00");
  const [fridayEndAMPM, setFridayEndAMPM] = useState<"AM" | "PM">("PM");

  const [saturdayStartHour, setSaturdayStartHour] = useState("10");
  const [saturdayStartMinute, setSaturdayStartMinute] = useState("00");
  const [saturdayStartAMPM, setSaturdayStartAMPM] = useState<"AM" | "PM">("AM");
  const [saturdayEndHour, setSaturdayEndHour] = useState("11");
  const [saturdayEndMinute, setSaturdayEndMinute] = useState("00");
  const [saturdayEndAMPM, setSaturdayEndAMPM] = useState<"AM" | "PM">("PM");

  const [sundayStartHour, setSundayStartHour] = useState("10");
  const [sundayStartMinute, setSundayStartMinute] = useState("00");
  const [sundayStartAMPM, setSundayStartAMPM] = useState<"AM" | "PM">("AM");
  const [sundayEndHour, setSundayEndHour] = useState("11");
  const [sundayEndMinute, setSundayEndMinute] = useState("00");
  const [sundayEndAMPM, setSundayEndAMPM] = useState<"AM" | "PM">("PM");

  const hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const minutes = ["00", "15", "30", "45"];

  useEffect(() => {
    const localRestaurantId = localStorage.getItem("restaurantId");
    if (!localRestaurantId) return;

    setRestaurantId(localRestaurantId);
    fetchSlots(localRestaurantId, new Date());
  }, []);

  const formatDateToYYYYMMDD = (inputDate: Date) =>
    dayjs(inputDate).format("YYYY-MM-DD");

  const convertToHHMM = (
    hhValue: string,
    mmValue: string,
    ampmValue: "AM" | "PM",
  ) => dayjs(`${hhValue}:${mmValue} ${ampmValue}`, "hh:mm A").format("HH:mm");

  type HHMMType = {
    hour: string;
    minute: string;
    ampm: "AM" | "PM";
  };

  const fetchSlots = async (restroId: string, theDate: Date) => {
    if (restroId !== "") {
      try {
        const apiUrl = `${baseURL}/v1/restaurant/fetchRestaurantSlots/${restroId}/${formatDateToYYYYMMDD(theDate)}`;

        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error(`Failed to fetch slots. Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.restaurant.mondayStartTime && data.restaurant.mondayEndTime) {
          // setMondayHours([data.restaurant.mondayStartTime, data.restaurant.mondayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.mondayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.mondayEndTime) as HHMMType;
          setMondayStartHour(startHour);
          setMondayStartMinute(startMinute);
          setMondayStartAMPM(startAMPM);

          setMondayEndHour(endHour);
          setMondayEndMinute(endMinute);
          setMondayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.tuesdayStartTime &&
          data.restaurant.tuesdayEndTime
        ) {
          // setTuesdayHours([data.restaurant.tuesdayStartTime, data.restaurant.tuesdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.tuesdayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.tuesdayEndTime) as HHMMType;

          setTuesdayStartHour(startHour);
          setTuesdayStartMinute(startMinute);
          setTuesdayStartAMPM(startAMPM);

          setTuesdayEndHour(endHour);
          setTuesdayEndMinute(endMinute);
          setTuesdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.wednesdayStartTime &&
          data.restaurant.wednesdayEndTime
        ) {
          //setWednesdayHours([data.restaurant.wednesdayStartTime, data.restaurant.wednesdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.wednesdayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.wednesdayEndTime) as HHMMType;

          setWednesdayStartHour(startHour);
          setWednesdayStartMinute(startMinute);
          setWednesdayStartAMPM(startAMPM);

          setWednesdayEndHour(endHour);
          setWednesdayEndMinute(endMinute);
          setWednesdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.thursdayStartTime &&
          data.restaurant.thursdayEndTime
        ) {
          //setThursdayHours([data.restaurant.thursdayStartTime, data.restaurant.thursdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.thursdayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.thursdayEndTime) as HHMMType;

          setThursdayStartHour(startHour);
          setThursdayStartMinute(startMinute);
          setThursdayStartAMPM(startAMPM);

          setThursdayEndHour(endHour);
          setThursdayEndMinute(endMinute);
          setThursdayEndAMPM(endAMPM);
        }

        if (data.restaurant.fridayStartTime && data.restaurant.fridayEndTime) {
          //setFridayHours([data.restaurant.fridayStartTime, data.restaurant.fridayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.fridayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.fridayEndTime) as HHMMType;

          setFridayStartHour(startHour);
          setFridayStartMinute(startMinute);
          setFridayStartAMPM(startAMPM);

          setFridayEndHour(endHour);
          setFridayEndMinute(endMinute);
          setFridayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.saturdayStartTime &&
          data.restaurant.saturdayEndTime
        ) {
          //setSaturdayHours([data.restaurant.saturdayStartTime, data.restaurant.saturdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.saturdayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.saturdayEndTime) as HHMMType;

          setSaturdayStartHour(startHour);
          setSaturdayStartMinute(startMinute);
          setSaturdayStartAMPM(startAMPM);

          setSaturdayEndHour(endHour);
          setSaturdayEndMinute(endMinute);
          setSaturdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.sundayStartTime &&
          data.restaurant.sundayStartTime
        ) {
          // setSundayHours([data.restaurant.sundayStartTime, data.restaurant.sundayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.sundayStartTime) as HHMMType;
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.sundayEndTime) as HHMMType;

          setSundayStartHour(startHour);
          setSundayStartMinute(startMinute);
          setSundayStartAMPM(startAMPM);

          setSundayEndHour(endHour);
          setSundayEndMinute(endMinute);
          setSundayEndAMPM(endAMPM);
        }
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    }
  };

  const copyToAll = async () => {

    // Left
    setTuesdayStartHour(mondayStartHour);
    setTuesdayStartMinute(mondayStartMinute);
    setTuesdayStartAMPM(mondayStartAMPM);

    setWednesdayStartHour(mondayStartHour);
    setWednesdayStartMinute(mondayStartMinute);
    setWednesdayStartAMPM(mondayStartAMPM);

    setThursdayStartHour(mondayStartHour);
    setThursdayStartMinute(mondayStartMinute);
    setThursdayStartAMPM(mondayStartAMPM);

    setFridayStartHour(mondayStartHour);
    setFridayStartMinute(mondayStartMinute);
    setFridayStartAMPM(mondayStartAMPM);

    setSaturdayStartHour(mondayStartHour);
    setSaturdayStartMinute(mondayStartMinute);
    setSaturdayStartAMPM(mondayStartAMPM);

    setSundayStartHour(mondayStartHour);
    setSundayStartMinute(mondayStartMinute);
    setSundayStartAMPM(mondayStartAMPM);

    // Right hours

    setTuesdayEndHour(mondayEndHour);
    setTuesdayEndMinute(mondayEndMinute);
    setTuesdayEndAMPM(mondayEndAMPM);

    setWednesdayEndHour(mondayEndHour);
    setWednesdayEndMinute(mondayEndMinute);
    setWednesdayEndAMPM(mondayEndAMPM);

    setThursdayEndHour(mondayEndHour);
    setThursdayEndMinute(mondayEndMinute);
    setThursdayEndAMPM(mondayEndAMPM);

    setFridayEndHour(mondayEndHour);
    setFridayEndMinute(mondayEndMinute);
    setFridayEndAMPM(mondayEndAMPM);

    setSaturdayEndHour(mondayEndHour);
    setSaturdayEndMinute(mondayEndMinute);
    setSaturdayEndAMPM(mondayEndAMPM);

    setSundayEndHour(mondayEndHour);
    setSundayEndMinute(mondayEndMinute);
    setSundayEndAMPM(mondayEndAMPM);

    const theStartVal = convertToHHMM(
      mondayStartHour,
      mondayStartMinute,
      mondayStartAMPM,
    );
    const theEndVal = convertToHHMM(
      mondayEndHour,
      mondayEndMinute,
      mondayEndAMPM,
    );

    // Call API to set
    if (restaurantId !== "") {
      try {
        await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantAllDayWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "mon",
            startTime: theStartVal,
            endTime: theEndVal,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
        // notifyModified();
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const hardcodedSlots = hours
    .map((h) => minutes.map((m) => `${h}:${m}`))
    .reduce((a, b) => a.concat(b));


  const getDayOfWeek = (date: Date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

  const filteredSlots = [];

  for (const hardCodedSlot of hardcodedSlots) {
    // Check each item in this array (e.g. "06:00") and see if this slot should be shown
    const hrValue = +hardCodedSlot.split(":")[0];

    const theDay = getDayOfWeek(new Date());

    if (theDay === "Monday") {
      const theStartVal = convertToHHMM(
        mondayStartHour,
        mondayStartMinute,
        mondayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        mondayEndHour,
        mondayEndMinute,
        mondayEndAMPM,
      );
      const mondayHours = [theStartVal, theEndVal];

      const theStartHr = +mondayHours[0].split(":")[0];
      const theEndHr = +mondayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Tuesday") {
      const theStartVal = convertToHHMM(
        tuesdayStartHour,
        tuesdayStartMinute,
        tuesdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        tuesdayEndHour,
        tuesdayEndMinute,
        tuesdayEndAMPM,
      );
      const tuesdayHours = [theStartVal, theEndVal];

      const theStartHr = +tuesdayHours[0].split(":")[0];
      const theEndHr = +tuesdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Wednesday") {
      const theStartVal = convertToHHMM(
        wednesdayStartHour,
        wednesdayStartMinute,
        wednesdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        wednesdayEndHour,
        wednesdayEndMinute,
        wednesdayEndAMPM,
      );
      const wednesdayHours = [theStartVal, theEndVal];

      const theStartHr = +wednesdayHours[0].split(":")[0];
      const theEndHr = +wednesdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Thursday") {
      const theStartVal = convertToHHMM(
        thursdayStartHour,
        thursdayStartMinute,
        thursdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        thursdayEndHour,
        thursdayEndMinute,
        thursdayEndAMPM,
      );
      const thursdayHours = [theStartVal, theEndVal];

      const theStartHr = +thursdayHours[0].split(":")[0];
      const theEndHr = +thursdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Friday") {
      const theStartVal = convertToHHMM(
        fridayStartHour,
        fridayStartMinute,
        fridayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        fridayEndHour,
        fridayEndMinute,
        fridayEndAMPM,
      );
      const fridayHours = [theStartVal, theEndVal];

      const theStartHr = +fridayHours[0].split(":")[0];
      const theEndHr = +fridayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Saturday") {
      const theStartVal = convertToHHMM(
        saturdayStartHour,
        saturdayStartMinute,
        saturdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        saturdayEndHour,
        saturdayEndMinute,
        saturdayEndAMPM,
      );
      const saturdayHours = [theStartVal, theEndVal];

      const theStartHr = +saturdayHours[0].split(":")[0];
      const theEndHr = +saturdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    } else if (theDay === "Sunday") {
      const theStartVal = convertToHHMM(
        sundayStartHour,
        sundayStartMinute,
        sundayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        sundayEndHour,
        sundayEndMinute,
        sundayEndAMPM,
      );
      const sundayHours = [theStartVal, theEndVal];

      const theStartHr = +sundayHours[0].split(":")[0];
      const theEndHr = +sundayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardCodedSlot);
      }
    }
  }

  function parseHHMM(time: `${string}:${string}`) {
    const [hourStr, minuteStr] = time.split(":");

    const hour = +hourStr;
    const minute = +minuteStr;

    const ampm = hour < 12 ? "AM" : "PM";

    const adjustedHour = (hour % 12 || 12).toString().padStart(2, "0");

    const adjustedMinute = minute.toString().padStart(2, "0");

    return { hour: adjustedHour, minute: adjustedMinute, ampm };
  }

  type UpdateHours = (
    day: ShortDayOfTheWeek,
  ) => (startTime: string, endTime: string) => Promise<void>;

  const updateDayHours: UpdateHours =
    (day: ShortDayOfTheWeek) => async (startTime: string, endTime: string) => {
      if (dayjs(startTime) > dayjs(endTime)) {
        notifyError("End time cannot be before start time");
        return;
      }
      if (restaurantId !== "") {
        try {
          const { data } = await axios.post(
            `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
            {
              restaurantId: restaurantId,
              day: day,
              startTime: startTime,
              endTime: endTime,
            },
          ); // Handle the response data as needed
          //setResponse(data);
          // notifyModified();
        } catch (error) {
          // Handle error, e.g., display an error message
          console.error("Error making POST request:", error);
        }
      }
    };

  const updateMondayHours = updateDayHours("mon");
  const updateTuesdayHours = updateDayHours("tue");
  const updateWednesdayHours = updateDayHours("wed");
  const updateThursdayHours = updateDayHours("thu");
  const updateFridayHours = updateDayHours("fri");
  const updateSaturdayHours = updateDayHours("sat");
  const updateSundayHours = updateDayHours("sun");

  type ShortDayOfTheWeek =
    | "mon"
    | "tue"
    | "wed"
    | "thu"
    | "fri"
    | "sat"
    | "sun";

  type TimeChangeHandler = (
    dayOfWeek: ShortDayOfTheWeek,
    startOrEnd: "start" | "end",
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;

  const handleHourChange: TimeChangeHandler = (
    dayOfWeek,
    startOrEnd,
    event,
  ) => {
    const selectedHour = event.target.value;

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          mondayStartHour,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          tuesdayStartHour,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          wednesdayStartHour,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          thursdayStartHour,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          fridayStartHour,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          saturdayStartHour,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          sundayStartHour,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  const handleMinuteChange: TimeChangeHandler = (
    dayOfWeek,
    startOrEnd,
    event,
  ) => {
    const selectedMinute = event.target.value;

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          selectedMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayEndMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          selectedMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          selectedMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayEndMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          selectedMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          selectedMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayEndMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          selectedMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          selectedMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayEndMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          selectedMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          selectedMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayEndMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          selectedMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          selectedMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayEndMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          selectedMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          selectedMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayEndMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          selectedMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  const handleAMPMChange: TimeChangeHandler = (
    dayOfWeek,
    startOrEnd,
    event,
  ) => {
    const selectedAMPM = event.target.value as "AM" | "PM";

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          selectedAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          selectedAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          selectedAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          selectedAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          selectedAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          selectedAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          selectedAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  return (
    <main className="SlotsManagementPage roboto">
      <div className="main-container" style={{ maxWidth: "100%" }}>
        <div
          style={{
            marginBottom: 30,
            width: "100%",
            marginTop: 50,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <FloatingContainer label={"Hours"}>
            <div className="restaurant-hours">
              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Monday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={mondayStartHour}
                    onChange={(event) =>
                      handleHourChange("mon", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("mon", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("mon", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={mondayEndHour}
                    onChange={(event) => handleHourChange("mon", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("mon", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayEndAMPM}
                    onChange={(event) => handleAMPMChange("mon", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Tuesday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={tuesdayStartHour}
                    onChange={(event) =>
                      handleHourChange("tue", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("tue", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("tue", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndHour}
                    onChange={(event) => handleHourChange("tue", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("tue", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndAMPM}
                    onChange={(event) => handleAMPMChange("tue", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Wednesday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={wednesdayStartHour}
                    onChange={(event) =>
                      handleHourChange("wed", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("wed", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("wed", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndHour}
                    onChange={(event) => handleHourChange("wed", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("wed", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndAMPM}
                    onChange={(event) => handleAMPMChange("wed", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Thursday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={thursdayStartHour}
                    onChange={(event) =>
                      handleHourChange("thu", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("thu", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("thu", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={thursdayEndHour}
                    onChange={(event) => handleHourChange("thu", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("thu", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayEndAMPM}
                    onChange={(event) => handleAMPMChange("thu", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Friday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={fridayStartHour}
                    onChange={(event) =>
                      handleHourChange("fri", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("fri", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("fri", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={fridayEndHour}
                    onChange={(event) => handleHourChange("fri", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("fri", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayEndAMPM}
                    onChange={(event) => handleAMPMChange("fri", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Saturday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={saturdayStartHour}
                    onChange={(event) =>
                      handleHourChange("sat", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("sat", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("sat", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={saturdayEndHour}
                    onChange={(event) => handleHourChange("sat", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("sat", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayEndAMPM}
                    onChange={(event) => handleAMPMChange("sat", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Sunday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={sundayStartHour}
                    onChange={(event) =>
                      handleHourChange("sun", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("sun", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("sun", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={sundayEndHour}
                    onChange={(event) => handleHourChange("sun", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("sun", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayEndAMPM}
                    onChange={(event) => handleAMPMChange("sun", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>
            </div>
          </FloatingContainer>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "1rem",
            }}
          >
            <button
              type="button"
              onClick={copyToAll}
              className="copy-time btn btn-dark"
              style={{ fontSize: 12 }}
            >
              Copy Monday Timings to All
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default WorkingHoursManagement;
