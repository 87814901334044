// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import "../../styleSheets/FoodMenu.module.css";
import { Add } from "../Modals Layout/Add";
import { USER } from "../../constants/localStorageKeys";
import { add_Excel } from "../../API/authMenuAPIs";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import CloseIcon from "@mui/icons-material/Close";
import excel from "../../RestaurantMenu/Images/excel_logo/excelLogo.png";
import AddIcon from "@mui/icons-material/Add";
import { baseDeploymentUrl } from "../../constants/Api";
import QrCode from "../../Components/QrCode";
import DownloadIcon from "@mui/icons-material/Download";
import { CSSTransition } from "react-transition-group";

import "../../styleSheets/react-transition.css";
import { Add_Cat_Subcat } from "../Modals Layout/Add-Cat-Subcat";
import "../../styleSheets/excel-sheet-upload.css";
import { DownloadMenuAsExcelAPI } from "../../API/download-menu-as-excel";
import { useNavigate, useParams } from "react-router-dom";
import ChangeTheme from "../../Components/ChangeTheme";
/* import ChangeTheme from "../../Components/ChangeTheme"; */

export const ActionBtn = ({ categoryData, setCategoryData, setWholeData, data }) => {
  const { name1, name2 } = useParams();
  const [open, setOpen] = useState(false);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [excelData, setExcelData] = useState();
  const [fileName, setFileName] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const actionBtnRef = useRef(null);
  const [actionBtnTop, setActionBtnTop] = useState("40%");
  const navigate = useNavigate();

  const businessid = localStorage.getItem(USER.businessId);

  const handleOverlayShow = () => setShowOverlay(true);
  const handleEnter = () => setShowDiv(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemoveOverlay = (event) => {
    console.log("classname---->", event.target.classame);

    if (event.target.className.includes("action_btn_div")) {
      // if (event.target.className == 'overlay_div') {
      setShowOverlay(false);
      setShowDiv(false);
    }
  };

  const handleExcel = async (e) => {
    e.preventDefault();
    if (!fileName) return;
    console.log("excel file", excelData);
    if (!excelData) {
      alert("upload a file first");
    } else {
      const formData = new FormData();

      formData.append("MenuFile", excelData);
      formData.append("userId", businessid);
      setUploadMenu(true);
      await add_Excel(formData);
      setUploadMenu(false);
      window.location.reload();
    }
  };

  const EXCEL_FILE_URL = `${baseDeploymentUrl}sample_excel_file.xlsx`;

  const downloadExcelFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (actionBtnRef.current) {
        const rect = actionBtnRef.current.getBoundingClientRect();
        setActionBtnTop(rect.top);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`${classes["action-btn-div"]} me-auto`}>
        <button
          style={{ marginLeft: "-20px" }}
          className={`${classes["action-btn"]}`}
          onClick={handleOverlayShow}
          ref={actionBtnRef}
        >
          Action
          <img src="/assets/action-arrow-icon.svg" className="ms-3 me-3" />
        </button>
      </div>

      <CSSTransition
        in={showOverlay}
        timeout={300}
        classNames="overlay"
        unmountOnExit
        onEnter={handleEnter}
      >
        <div
          className={`${classes.overlay_div} action_btn_div`}
          onClick={handleRemoveOverlay}
        >
          <CSSTransition
            in={showDiv}
            timeout={300}
            classNames="inner-content"
            unmountOnExit
          >
            <div
              style={{ top: actionBtnTop }}
              className={`${classes.category_btn_container}`}
            >
              <button className={classes.category_btn}>
                {/* {"Generate barcode "} */}
                <QrCode />
              </button>
              <button className={`${classes.category_btn}`}>
                {" "}
                <Add_Cat_Subcat
                  objName=""
                  objImage=""
                  type="Category"
                  data={categoryData}
                  setData={setCategoryData}
                  setWholeData={setWholeData}
                />
              </button>{" "}
              <button className={classes.category_btn} onClick={handleOpen}>
                {"Menu Excel Sheet"}
              </button>
              <button className={classes.category_btn}>
                <ChangeTheme />
              </button>
              {/*  <button className={classes.category_btn}>Help</button> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  className={classes.box_style}
                  sx={customizeMUIComponents.excelUploadBox}
                >
                  <div>
                    {uploadMenu ? (
                      "Uploading....."
                    ) : (
                      <div className={classes.input_div}>
                        <form>
                          <input
                            id="excel"
                            type="file"
                            accept=".xlsx"
                            onChange={(event) => {
                              setExcelData(event.target.files[0]);
                              setFileName(event.target.files[0].name);

                              console.log(
                                "excel sheet",
                                event.target.files[0].name,
                              );
                            }}
                            hidden
                          />

                          <label for="excel" className={`Upload-input`}>
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                              {fileName ? (
                                <div className="mt-3">
                                  <img
                                    src={excel}
                                    alt={fileName}
                                    style={{ width: "55px", height: "55px" }}
                                  />
                                  <p className="Upload-Label mt-1">
                                    {fileName}
                                  </p>
                                </div>
                              ) : (
                                <>
                                  <img
                                    className="Upload-Img me-2 mt-1"
                                    src="/assets/upload-icon.svg"
                                  />
                                  <span className="Upload-Label">
                                    Upload excel sheet
                                  </span>
                                </>
                              )}
                            </div>
                          </label>

                          {uploadMenu ? (
                            <button className="Loading-Btn">
                              Uploading...
                            </button>
                          ) : (
                            <button
                              className={`Upload-Btn ${
                                !fileName && "opacity-0"
                              }`}
                              onClick={handleExcel}
                            >
                              Upload
                            </button>
                          )}
                        </form>

                        <div className="d-flex justify-content-evenly">
                          <div
                            onClick={() => {
                              DownloadMenuAsExcelAPI(navigate, name1 || name2, data);
                            }}
                            className=""
                            style={{ marginTop: "5px", marginBottom: "20px" }}
                          >
                            <img
                              className="Download-Img me-1 mt-1"
                              src="/assets/sample-excel-download.svg"
                            />
                            <span className="Download-Texts">
                              Download existing excel sheet
                            </span>
                          </div>

                          <div
                            onClick={() => {
                              downloadExcelFile(EXCEL_FILE_URL);
                            }}
                            className=""
                            style={{ marginTop: "5px", marginBottom: "20px" }}
                          >
                            <img
                              className="Download-Img me-1 mt-1"
                              src="/assets/sample-excel-download.svg"
                            />
                            <span className="Download-Texts">
                              Download sample excel sheet
                            </span>
                          </div>
                        </div>

                        <div style={{ marginBottom: "18px" }}>
                          <img
                            className="Youtube-Icon me-1"
                            src="/assets/excel-yt-icon.svg"
                          />
                          <span className="Youtube-Text">
                            Learn how to upload a menu
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </Modal>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>

      {/* -------- Will refactor these codes later -------------- */}

      <div className={`${classes.category_btn_container_2}`}>
        <button className={classes.category_btn}>
          {/* {"Generate barcode "} */}
          <QrCode />
        </button>
        <button className={`${classes.category_btn}`}>
          {" "}
          <Add_Cat_Subcat
            objName=""
            objImage=""
            type="Category"
            data={categoryData}
            setData={setCategoryData}
            setWholeData={setWholeData}
          />
        </button>{" "}
        <button className={classes.category_btn} onClick={handleOpen}>
          {"Menu Excel Sheet"}
        </button>
        {/* ------ TO DO (TEMPORARY) -------- */}
        {/* <button className={classes.category_btn}>
          Help
        </button> */}
        <button className={classes.category_btn}>
          <ChangeTheme />
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className={classes.box_style}
            sx={customizeMUIComponents.excelUploadBox}
          >
            <div>
              {/* {true ? <div className="text-center">Uploading.....</div> : */}
              <div className={classes.input_div}>
                <form>
                  <input
                    id="excel"
                    type="file"
                    accept=".xlsx"
                    onChange={(event) => {
                      setExcelData(event.target.files[0]);
                      setFileName(event.target.files[0].name);

                      console.log("excel sheet", event.target.files[0].name);
                    }}
                    hidden
                  />

                  <label for="excel" className={`Upload-input`}>
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      {fileName ? (
                        <div className="mt-3">
                          <img
                            src={excel}
                            alt={fileName}
                            style={{ width: "55px", height: "55px" }}
                          />
                          <p className="Upload-Label mt-1">{fileName}</p>
                        </div>
                      ) : (
                        <>
                          <img
                            className="Upload-Img me-2 mt-1"
                            src="/assets/upload-icon.svg"
                          />
                          <span className="Upload-Label">
                            Upload excel sheet
                          </span>
                        </>
                      )}
                    </div>
                  </label>

                  {uploadMenu ? (
                    <button className="Loading-Btn">Uploading...</button>
                  ) : (
                    <button
                      className={`Upload-Btn ${
                        !fileName && "opacity-0 pe-none"
                      }`}
                      onClick={handleExcel}
                    >
                      Upload
                    </button>
                  )}
                </form>

                <div className="d-flex justify-content-around">
                  <div
                    onClick={() => {
                      DownloadMenuAsExcelAPI(navigate, name1 || name2, data);
                    }}
                    className=""
                    style={{ marginTop: "5px", marginBottom: "20px" }}
                  >
                    <img
                      className="Download-Img me-1 mt-1"
                      src="/assets/sample-excel-download.svg"
                    />
                    <span className="Download-Texts">
                      Download existing excel sheet
                    </span>
                  </div>

                  <div
                    onClick={() => {
                      downloadExcelFile(EXCEL_FILE_URL);
                    }}
                    className=""
                    style={{ marginTop: "5px", marginBottom: "20px" }}
                  >
                    <img
                      className="Download-Img me-1 mt-1"
                      src="/assets/sample-excel-download.svg"
                    />
                    <span className="Download-Texts">
                      Download sample excel sheet
                    </span>
                  </div>
                </div>

                <div style={{ marginBottom: "18px" }}>
                  <img
                    className="Youtube-Icon me-1"
                    src="/assets/excel-yt-icon.svg"
                  />
                  <span className="Youtube-Text">
                    Learn how to upload a menu
                  </span>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
