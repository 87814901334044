// @ts-nocheck
import React, { useState, useEffect } from "react";
import {useDispatch} from "react-redux"
// import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import { addHomePageImages } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DragDrop from "../../utils/drag-n-drop";
import ImageCropper from "../../utils/image-cropper";
import { Upload_Images } from "../../utils/Upload-Image";
import { cloudinaryFetch } from "../../API/Cloudinary-fetch-images";

import { baseURL } from "../../constants/Api";
import { DigitalOceanFetch } from "../../API/digitalocean-fetch-images";
import { setApiData } from "../../redux/actions";
export const AddCarouselImage = ({
  imgEdit,
  setImgEdit,
  loading,
  setLoading,
  images,
  setImages,
  carouselData,
  setCarouselData,
  setWholeData,
  insertIndex
}) => {
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => { setImgEdit(false) }
  // const [addImage, setAddImage] = useState(false)
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch()

  const restaurantName = localStorage.getItem("RestrauntName");
  //  const[size,setSize]=useState();

  async function uploadImgFunc(tosendImage) {
    const imgUrl = await DigitalOceanFetch(tosendImage);
    // console.log('imgUrl----->', imgUrl)

    const homePageImages = await addHomePageImages(imgUrl, navigate, insertIndex);
    // console.log('clicked yes on save image----->', homePageImages)
    console.log(homePageImages);
    setCarouselData(homePageImages?.body.menu.HomePageImagesurl);
    setWholeData(homePageImages?.body.menu)
    setImgEdit(false);
    setImages(null);
    setIsUploading(false);
    dispatch(setApiData(homePageImages?.body?.menu))
  }

  return (
    <div>
      {/* <Modal show={imgEdit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Carousel Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!images ? <DragDrop setImages={setImages} /> : <ImageCropper file={URL.createObjectURL(images)} uploadImage = {uploadImage} />}
        </Modal.Body>
      </Modal> */}

      <Upload_Images
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        forType="CarouselImg"
        images={images}
        setImages={setImages}
        uploadImgFunc={uploadImgFunc}
        aspectRatio={2 / 1}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        maxSizeMB={1}
      />
    </div>
  );
};
