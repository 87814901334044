//@ts-nocheck
import React, { useState } from "react";
import backArrow from "../assets/icons/Social_icons/backArrow.svg";
import "../styleSheets/Bio_page.css";
import "../styleSheets/AddLinksPage.css";
import "../styleSheets/medipage.css";
import "../styleSheets/tnc_page.css";
import checked from "../assets/icons/Social_icons/checked_box.svg";
import unchecked from "../assets/icons/Social_icons/unchecked_box.svg";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useNavigate } from "react-router-dom";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Tnc_page = ({ tnc }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();
  const [check, setCheck] = useState({
    check0: true,
    check1: true,
    check2: true,
    check3: true,
    check4: true,
  });

  const handelClick = (tickname) => {
    setCheck((prev) => ({ ...prev, [tickname]: !prev[tickname] }));
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/tncs`, "TnC Page");
  }, []);
  return (
    <div className="Socialformpage d-flex justify-content-center ">
      <div className="container custom-width">
        <div className=" d-flex pt-5">
          <div className=" w-100 mx-2 d-flex justify-content-between align-items-center mb-5">
            <div onClick={() => navigate(-1)}>
              <img src={backArrow} />
            </div>
            <div>
              <h1 className="bioh fs-5 ms-4">update/Events</h1>
            </div>

            <div>
              <span className="fs-6 check-text">All tnC's</span>
            </div>
          </div>
        </div>

        <div>All terms and conditions</div>

        {/* this is terms and condititon box-----------------------------------   */}
        <div className=" p-3 mt-2 event_box ">
          {tnc.map((data, index) => (
            <div key={index} className="d-flex ">
              <div className="p-2" onClick={() => handelClick(`check${index}`)}>
                {check[`check${index}`] ? (
                  <img src={checked} />
                ) : (
                  <img src={unchecked} />
                )}
              </div>
              <div className="p-2">{data.option}</div>
            </div>
          ))}
        </div>
        {/* this is the end of terms and condititon box------------------------   */}

        {/*button  div ----------------------------------------------*/}
        <div className="  container m-4 mt-5 d-flex justify-content-center align-items-center ">
          <Button
            varient="contained"
            sx={customizeMUIComponents.editButton2}
            className="text-white Link-savebutton"
            onClick={handleOpenDialog}
          >
            Save
          </Button>
          <div className=""></div>
        </div>
        {/*button end ------------ -------------------------------------*/}
      </div>
    </div>
  );
};

export default Tnc_page;
