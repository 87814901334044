import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { searchRestaurant } from "../../API/reservationAPIs";
import { useNavigate } from "react-router-dom";
import { USER } from "../../constants/localStorageKeys";
import { FaCheck } from "react-icons/fa";
import { baseURL } from "../../constants/Api";
import locationIcon from "../../assets/icons/location.svg";
import imagePlaceholder from "../../assets/images/image-placeholder.png";
import tick from "../../assets/icons/tick.svg";

import axios from "axios";

import "./Pages.scss";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const ListPage = () => {
  //const { name } = useParams();

  const [restaurantName, setRestaurantName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [dropdownState, setDropDownState] = useState(false);

  const [formattedAddress, setFormattedAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [name, setName] = useState("");
  const [rating, setRating] = useState("");
  const [placeId, setPlaceId] = useState("");

  const [businessId, setBusinessId] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const handleSearch = useCallback(async () => {
    if (searchKey.length > 2) {
      try {
        // Replace the direct Google Places API call with your server endpoint
        const response = await axios.get(
          `${baseURL}/v1/search/searchRestaurants/${searchKey}`,
        );

        setSearchData(response.data);
        setDropDownState(true);
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    } else {
      setSearchData([]);
      setDropDownState(false);
    }
  }, [searchKey]);

  const handlePageChange = (item) => {
    // Save the result in local storage and move to next page
    console.log('item data', item)
    console.log('PlaceID: ', item.place_id)

    localStorage.setItem("result_formatted_address", item.formatted_address);
    localStorage.setItem("result_latitude", item.latitude);
    localStorage.setItem("result_longitude", item.longitude);
    localStorage.setItem("result_name", item.name);
    localStorage.setItem("result_rating", item.rating);
    localStorage.setItem("result_placeId", item.place_id);


    setFormattedAddress(item.formatted_address);
    setLatitude(item.latitude);
    setLongitude(item.longitude);
    setName(item.name);
    setRating(item.rating);
    item.place_id && setPlaceId(item.place_id)

    setDropDownState(false);
    setSearchKey("");
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleSearch();
  }, [searchKey, handleSearch]);

  /*
  useEffect(() => {
    handleSearch();

  }, [restaurantName]);
  */

  useEffect(() => {
    populateEarlierResult().then(() => populateInfo());
  }, []);

  const populateInfo = async () => {
    console.log("username:", localStorage.getItem(USER.USERNAME));
    console.log("businessId:", localStorage.getItem(USER.businessId));
    console.log("businessname:", localStorage.getItem(USER.businessName));
    console.log("profileType:", localStorage.getItem(USER.PROFILE_TYPE));
    console.log("accessToken:", localStorage.getItem(USER.JWT_ACCESS_TOKEN));

    setBusinessId(localStorage.getItem(USER.businessId));
    setAccessToken(localStorage.getItem(USER.JWT_ACCESS_TOKEN));
  };

  const populateEarlierResult = async () => {
    setFormattedAddress(localStorage.getItem("result_formatted_address"));
    setLatitude(localStorage.getItem("result_latitude"));
    setLongitude(localStorage.getItem("result_longitude"));
    setName(localStorage.getItem("result_name"));
    setRating(localStorage.getItem("result_rating"));
    const new_placeId = localStorage.getItem("result_placeId") || ''
    new_placeId && setPlaceId(new_placeId);
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView("/reservation/list/", "ReservationList Page");
  }, []);

  return (
    <div className="Listpage">
      <form
        className={`searchBar-con ${dropdownState ? "dropdownOpen" : ""}`}
        style={{ alignSelf: "center" }}
      >
        {dropdownState && (
          <div
            className="searchBarResultCon"
            style={{
              padding: 10,
              zIndex: 1000,
              position: "fixed",
              top: 200,
              backgroundColor: "white",
            }}
          >
            {searchData && searchData.length > 0 ? (
              searchData.map((item) => (
                <div
                  key={item.place_id}
                  onClick={() => handlePageChange(item)}
                  onKeyUp={() => false}
                  className="searchBarResult-item"
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <img src={locationIcon} width={20} height={20} alt="img" />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: 5,
                    }}
                  >
                    <span style={{ fontWeight: 500, fontSize: 14 }}>
                      {item.name}
                    </span>
                    <div className="subtitle">{item.formatted_address}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className="searchBarResult-item">No location found</div>
            )}
          </div>
        )}

        <input
          type="text"
          className="searchBox"
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search for Your Restaurant"
          style={{ color: "#000", "::placeholder": { color: "#DADADA" } }}
        />

        <div
          onClick={handlePageChange}
          onKeyUp={() => false}
          className="searchbarIcon-con"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="#DCDCDC"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </form>

      <div
        style={{
          backgroundColor: "#B3B3B3",
          height: 1,
          width: "90%",
          alignSelf: "center",
          marginTop: 30,
        }}
      >
        &nbsp;
      </div>

      <div style={{ marginLeft: 20, marginRight: 20, alignSelf: "center" }}>
        <h1 className="text-start" style={{ color: "#000000", fontSize: 18 }}>
          Result Found
        </h1>

        <div className="restaurantSearchResultCon">
          <RestaurantSearchItem
            name={name}
            rating={rating}
            addres={formattedAddress}
            latitude={latitude}
            longitude={longitude}
            businessId={businessId}
            placeId={placeId}
          />
        </div>
      </div>
    </div>
  );
};

export default ListPage;

function RestaurantSearchItem({
  name,
  rating,
  addres: address,
  latitude,
  longitude,
  businessId,
  placeId
}) {
  const navigate = useNavigate();

  const connectRestaurant = async () => {
    try {
      // Assuming you have the restaurant data to send in the request body
      const restaurantData = {
        name: name,
        formattedAddress: address,
        latitude: latitude,
        longitude: longitude,
        rating: rating,
        profileId: businessId,
        place_id: placeId || localStorage.getItem('result_placeId')
      };

      if (!restaurantData.place_id) return;

      // Make a POST request to the API endpoint
      const response = await axios.post(
        `${baseURL}/v1/restaurant/createRestaurant/`,
        restaurantData,
      );

      // Handle the response
      console.log("created:", response.data.restaurant); // The response from the server

      localStorage.setItem(
        "formatted_address",
        response.data.restaurant.formattedAddress,
      );
      localStorage.setItem("latitude", response.data.restaurant.latitude);
      localStorage.setItem("longitude", response.data.restaurant.longitude);
      localStorage.setItem("name", response.data.restaurant.name);
      localStorage.setItem("rating", response.data.restaurant.rating);
      localStorage.setItem("merchantId", response.data.restaurant.merchantId);
      localStorage.setItem("restaurantId", response.data.restaurant.id);

      // Refresh the whole page, to fetch the updated sidebar
      // window.location.href = "/reservation/profile";
      navigate("/reservation/profile");
    } catch (error) {
      // Handle errors
      console.error("Error connecting to the restaurant API:", error);
    }
  };

  return (
    <div
      className="restaurantSearch-item"
      style={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 24px 0px rgba(117, 117, 117, 0.18)",
      }}
    >
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          boxShadow: "none",
        }}
      >
        {/* <div className="restaurantSearch-item-L" style={{ width: "40%" }}>
          <div
            className="img"
            style={{ objectFit: "cover", width: 100, height: 100 }}
          >
            <img
              src={imagePlaceholder}
              alt=""
              style={{ objectFit: "cover", width: 100, height: 100 }}
            />
          </div>
        </div> */}

        <div className="restaurantSearch-item-R" style={{ width: "100%" }}>
          <div className="restaurantSearch-item-R-T">
            <h2 style={{ color: "#000000", fontSize: 14 }}> {name}</h2>
            <div className="sLine" style={{ backgroundColor: "#B3B3B3" }} />
            <div
              className="ratingCon"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontSize: 11, color: "#28353D8F" }}>{rating}</h3>

              <div
                className="starCon"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                {" "}
                {Array.from({ length: 5 }).map((i, n) => (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    key={crypto.randomUUID()}
                  >
                    <path
                      d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                      {...(n < Math.floor(rating)
                        ? {
                            fill: "#F7B808",
                          }
                        : {
                            fill: "#DDDEE2",
                            fillOpacity: "0.8",
                          })}
                    />
                  </svg>
                ))}
              </div>

              {/* <h4 style={{ fontSize: 11 }}>(33)</h4> */}
            </div>
            <h5 style={{ color: "#28353D8F", fontSize: 11, marginTop: 10 }}>
              {address}
            </h5>
          </div>
          <div className="restaurantSearch-item-R-B">
            <button
              type="button"
              disabled={!placeId}
              className="custom-btn custom-btn-dark mt-3"
              style={{
                backgroundColor: "#D1D1D1",
                color: "#27343C",
                borderRadius: 50,
                fontWeight: "normal",
              }}
              onClick={() => placeId && connectRestaurant()}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div className="info">
        {placeId && <img src={tick} width="16" alt="img" />}
        <span style={{ color: "#28353D", fontSize: 11, marginLeft: 10 }}>
          To get started, a Google business account is a prerequisite
        </span>
      </div>
    </div>
  );
}
