// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  addCategory,
  addItem,
  addSubCategory,
  updateItems,
} from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import "../../styleSheets/react-transition.css";
import "../../styleSheets/Modal-Box.css";
import { Overlay_Animation } from "../../utils/Overlay-animation";
import { Item_Layout } from "../ItemsSection/Item-Layout";
import "../../styleSheets/more-options.css";

export const Edit_Item = ({ type, setData, data, objId, objData, showOverlay, setShowOverlay, callApis, setWholeData }) => {
  // console.log('type of component---->', type)
  // console.log('data prop----->', data)
  // console.log('objId---->', objId)
  const [categoryName, setCategoryName] = useState("");
  const [dishName, setDishName] = useState("");
  const [price, setPrice] = useState("");
  const [dishType, setDishType] = useState("na");
  const [varietyInputsState, setVarietyInputsState] = useState([]);

  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(false);
  const [chefR, setChefR] = useState(false);
  const [addDesc, setAddDesc] = useState(false);
  const [descInput, setDescInput] = useState("");
  const navigate = useNavigate();
  const maxLimit = 250;


  const handleDescChange = (e) => {
    const inputValue = e.target.value;

    setDescInput(inputValue.substring(0, maxLimit));
  };

  const handleEnter = () => setShowDiv(true);

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes("add_item_div") ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay((prev)=>({...prev, editItem: false}));
      setShowDiv(false);
      setCategoryName("");
      setError(false);
    }
  };

  const selectedImage = "";
  // console.log('data obtained in typesssss ----->', data)

  const editDetails = async () => {
    console.log("editdetails called");
    const response = await updateItems(
      objId,
      dishName,
      price,
      dishType,
      descInput,
      varietyInputsState,
      chefR,
      navigate,
    );
    await setData(response.body.menu.items);
    await setWholeData(response.body.menu)

    setShowOverlay((prev)=>({...prev, editItem: false}));
    setShowDiv(false);
    await callApis()
  };

  useEffect(() => {
    setDishName(objData?.itemName);
    setPrice(objData?.price);
    setDishType(objData?.type);
    setVarietyInputsState(() => {
      if (objData?.varietyArr) return objData?.varietyArr;
      else return [];
    });
    setChefR(() => {
      if (objData?.chefRecommend === true || objData?.chefRecommend === false)
        return objData?.chefRecommend;
      else return false;
    });
    setAddDesc(() => {
      if (objData?.description) return true;
      else return false;
    });
    setDescInput(objData?.description);
  }, [objData]);

  return (
    <div id="edit">

      <Overlay_Animation
        className="add_item_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <Item_Layout
            dishName={dishName}
            setDishName={setDishName}
            price={price}
            setPrice={setPrice}
            setDishType={setDishType}
            varietyInputsState={varietyInputsState}
            setVarietyInputsState={setVarietyInputsState}
            chefR={chefR}
            setChefR={setChefR}
            addDesc={addDesc}
            setAddDesc={setAddDesc}
            descInput={descInput}
            handleDescChange={handleDescChange}
            onClickBtn={editDetails}
            dishType={dishType}
          />
        }
      />
    </div>
  );
};
