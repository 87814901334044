//@ts-nocheck
import React, { useState, useEffect } from "react";
import "../styleSheets/Bio_page.css";
import "../styleSheets/responsive.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import DialogBox from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import { updateAnnouncement } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import { GreenButton } from "../Components/Button.style";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import pencil from "../assets/icons/Social_icons/pencil.png";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Announcementpage = () => {
  const dispatch = useDispatch();
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");
  var textarea = document.getElementById("textInput");
  const [openDialog, setOpenDialog] = useState(false);

  // textarea?.addEventListener('keydown', autosize);
  // function autosize(){
  //   var el = this;
  //     setTimeout(function(){
  //       el.style.cssText = 'height:auto; padding:0';
  //       el.style.cssText = 'height:' + el.scrollHeight+10 + 'px';
  //     },0);

  // }
  const handleOpenDialog = async () => {
    const dataReceived = await updateAnnouncement(inputValue, state);
    updateNestedRedux(
      api,
      "Basic Details",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue.substring(0, 500));
  };

  textarea?.addEventListener("keydown", autosize);
  function autosize() {
    var el = this;
    setTimeout(function () {
      el.style.cssText = "height:auto; padding:0";
      el.style.cssText = "height:" + el.scrollHeight + "px";
    }, 0);
  }

  useEffect(() => {
    setInputValue(api["Basic Details"]?.announcement), state;
  }, [api]);

  if (Object.keys(api) === 0) {
    return;
  }

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/FastSocial/Announcementpage`, "Announcement Page");
  }, []);
  return (
    <div className="Socialformpage d-flex justify-content-center ">
      <div className="mobile-pc-width mx-auto">
        <div className="d-flex container m-auto mb-5 social-profile-header">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <KeyboardBackspaceIcon
              className="back-arrow-click ms-2"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="mx-auto">
              <h1 className="socio-prof-header me-3">
                {api?.["Basic Details"]?.account?.userName} | Announcement
              </h1>
            </div>
          </div>
        </div>

        <div
          className="justify-content-center position-relative mb-4 mx-auto mt-4"
          style={{ width: "281px" }}
        >
          <div className="enter-ann-txt">Enter your Announcement</div>
          <textarea
            type="text"
            id="textInput"
            className="mb-2 mt-1 p-2 announcement-box"
            placeholder="Type Your Announcement"
            value={inputValue}
            onChange={handleInputChange}
          />
          <div className=" position-absolute pencil-img">
            <img src={pencil} className=" w-100 h-100" />
          </div>
          <div className="me-5 position-absolute words-count-txt">
            {500 - (inputValue?.length ? inputValue?.length : 0)} words
          </div>
        </div>

        <div
          className="ms-3 mt-5 d-flex justify-content-center align-items-center"
          style={customizeMUIComponents.row}
        >
          <GreenButton backgroundColor=" #7ECAB0" onClick={handleOpenDialog}>
            Save
          </GreenButton>
        </div>
      </div>
    </div>
  );
};

export default Announcementpage;
