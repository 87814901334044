import React, { useEffect } from "react";
import { Header } from "../../utils/NewVersion/header";
import { Card } from "./Card";
import "../../styleSheets/NewVersion/verify.css";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

export const Verify = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/verify`, "Verify Page");
  }, []);

  return (
    <div
      className="m-auto py-3 verify px-3"
      style={{ maxWidth: "500px", height: "100svh" }}
    >
      <Header sectionName="Verification" />
      <Card />

      <div className="mt-4 card-txt1">
        By tapping "Next", you agree that the information you provide for your
        eatoes verified subscription is subject to our{" "}
        <span className=" text-primary cursor-pointer">Privacy Policy</span>
      </div>
    </div>
  );
};
