import React, { useState, useRef, useEffect } from "react";
import OptionsMenu from "./OptionsMenu";
import styles from "./ProfileItem.module.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import dummyIcon from "../assets/images/logo.png";

const ProfileItem = ({ profile, deleteProfile, updateServiceStatus }) => {
  const [showOptions, setShowOptions] = useState(false);
  const optionsMenuRef = useRef(null);

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.profileItem}`}
    >
      <div className="d-flex align-items-center">
        <img
          src={dummyIcon}
          className={`rounded-circle ${styles.profileImg}`}
        />
        <div className={styles.divider}></div>
        <div className={styles.containers}>
          <span className="ml-3">{profile.name}</span>
          <span className={styles.profileUserName}>{profile.userName}</span>
        </div>
      </div>
      <div className={`dropdown ${styles.options}`} ref={optionsMenuRef}>
        <button
          className="btn btn-link"
          type="button"
          onClick={handleOptionsClick}
        >
          <BsThreeDotsVertical />
        </button>
        {showOptions && (
          <OptionsMenu
            services={profile.services}
            profileId={profile.profileId}
            deleteProfile={deleteProfile}
            updateServiceStatus={updateServiceStatus}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileItem;
