// @ts-nocheck
import React, { useState } from "react";
import classes from "../styleSheets/FoodMenu.module.css";
import { CSSTransition } from "react-transition-group";
import "../styleSheets/react-transition.css";

export const Overlay_Animation = (props) => {
  const {
    className,
    showOverlay,
    showDiv,
    setShowDiv,
    handleRemoveOverlay,
    componentToRender,
  } = props;

  const handleEnter = () => setShowDiv(true);

  return (
    <>
      <CSSTransition
        in={showOverlay}
        timeout={300}
        classNames="overlay"
        unmountOnExit
        onEnter={handleEnter}
      >
        <div
          className={`${classes.overlay_div} ${className}`}
          onClick={handleRemoveOverlay}
        >
          <CSSTransition
            in={showDiv}
            timeout={300}
            classNames="bottom-content"
            unmountOnExit
          >
            {componentToRender}
          </CSSTransition>
        </div>
      </CSSTransition>
    </>
  );
};
