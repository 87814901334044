// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApiData, setFastSocialData, setMenuAccess } from "../redux/actions";
import Carousel from "./CategoryPage/Carousel";
import CategorySection from "./CategoryPage/CategorySection";
import Header from "./CategoryPage/Header";
import { useParams, useNavigate } from "react-router-dom";
import { NoSim } from "@mui/icons-material";
import { USER } from "../constants/localStorageKeys";
import { MENU_API, baseURL } from "../constants/Api";
import { menuAPI, authMenuApi } from "../API/MenuApi";
import classes from "../styleSheets/FoodMenu.module.css";
import Sidebar from "../Components/Sidebar";
import CategorySectionLoader from "./Loaders/CategorySectionLoader";
import Loader from "../assets/images/images/Loader.jpeg";
import { getUserProfile } from "../API/authMenuAPIs";
import { CarouselImageDragDrop } from "./CategoryPage/Carousel_image_drag_drop";
import { CSSTransition } from "react-transition-group";
import "../styleSheets/react-transition.css";
import { menuAccessAPI } from "../API/menu_role_access";
import Marquee from "react-fast-marquee";
import { getSocialProfile } from "../API/authAPIs";
import { getAllData } from "../API/fast_social_apis";
import axios from "axios";
import ProfileFooter from "../Components/ProfileFooter";

const MainMenuTheme2 = ({
  selectedProfile,
  setSelectedProfile,
  setId,
  setBusinessProfileName,
  setBusinessUserName,
}) => {
  // console.log('i am just checkingggggg')
  const [carouselData, setCarouselData] = useState([]);
  const [coverDragState, setCoverDragState] = useState(false);
  const [authenticated, setAuthenticated] = useState();
  const isMobile = window.screen.width;
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.menu.apiData);
  const timestamp = useSelector((state) => state.menu.timestamp);
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const [digitalMenuStatus, setDigitalMenuStatus] = useState(true);
  // console.log(name2, "nnn");

  // console.log(resName, "resssNamee");
  const { name1, name2 } = useParams();

  var name;

  name = name2 ? name2 : name1;

  const callApis = async (savedToken) => {
    if (timestamp && apiData?.categories && apiData?.categories?.length > 0) {
      const currentTime = new Date();
      const timeDiff = currentTime - timestamp;
      const minutesDiff = timeDiff / (1000 * 60);
      if (minutesDiff <= 20 && apiData && Object.keys(apiData).length !== 0) {
        setData(apiData);
        if (savedToken) {
          const returnedData = await getUserProfile(
            `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
            navigate,
          );
          setUserData(returnedData);
        }
        return;
      }
    }

    setLoading(true);
    // const data1 = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    const data1 = await menuAPI(`${baseURL}${MENU_API.MENU}${name}`);
    setData(data1);

    dispatch(setApiData(data1));

    const fastSocialData = await getAllData(name, navigate);
    dispatch(setFastSocialData(fastSocialData));
    if (savedToken) {
      const returnedData = await getUserProfile(
        `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
        navigate,
      );
      setUserData(returnedData);
      const menuAccessData = await menuAccessAPI(data1, navigate);
      dispatch(setMenuAccess(menuAccessData));
      if (menuAccessData) {
        setBusinessProfileName(data1?.title);
        setSelectedProfile(data1?.user);
        setBusinessUserName(data1?.restrauntName);
        localStorage.setItem(USER.USERNAME, data1?.restrauntName);
        localStorage.setItem(USER.businessId, data1?.user);
        localStorage.setItem(USER.businessName, data1?.title);
      }
    } else {
      dispatch(setMenuAccess(false));
    }

    setLoading(false);
  };
  const resName = localStorage.getItem(USER.USERNAME);

  useEffect(() => {
    const savedToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
    callApis(savedToken);
    console.log(data, "setcategory data");
    if (savedToken) {
      setAuthenticated(savedToken);
      setId && setId("2");
      // if(!menuAccessState) navigate(`/menu/${name}`)
      // else navigate(`/edit/menu/${name}`)
    }
  }, [name, coverDragState, menuAccessState]);

  console.log("data?.announcement", data?.announcement);

  return (
    <>
      <div className={classes.container1}>
        <div className={classes.container2} style={{ background: "white" }}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <div>
                {" "}
                <img
                  className={classes.loader}
                  style={{ textAlign: "center" }}
                  src={Loader}
                />
              </div>
              <div>Loading</div>
            </div>
          ) : (
            <>
              {false ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "40vh 0 0 ",
                  }}
                >
                  Services Not Available
                </div>
              ) : (
                <>
                  <Header
                    userData={userData}
                    setUserData={setUserData}
                    selectedProfile={selectedProfile}
                    data={data}
                    setData={setData}
                  />

                  {data?.announcement && (
                    <div className="text-announcement text-center text-nowrap overflow-hidden">
                      <Marquee pauseOnHover={true}>
                        <div className="p-2" style={{ margin: "0 6rem" }}>
                          {data?.announcement}
                        </div>
                      </Marquee>
                    </div>
                  )}

                  <CSSTransition
                    in={coverDragState}
                    timeout={300}
                    classNames="cover-drag-1"
                    unmountOnExit
                  >
                    <CarouselImageDragDrop
                      data={data}
                      setData={setData}
                      carouselData={carouselData}
                      setCarouselData={setCarouselData}
                      setCoverDragState={setCoverDragState}
                    />
                  </CSSTransition>

                  <CSSTransition
                    in={!coverDragState}
                    timeout={600}
                    classNames="cover-drag-2"
                    unmountOnExit
                  >
                    <div>
                      <Carousel
                        name={name}
                        selectedProfile={selectedProfile}
                        data={data}
                        setData={setData}
                        loading={loading}
                        setCoverDragState={setCoverDragState}
                        carouselData={carouselData}
                        setCarouselData={setCarouselData}
                      />
                      <CategorySection
                        name={name}
                        selectedProfile={selectedProfile}
                        data={data}
                        setData={setData}
                        loading={loading}
                      />
                    </div>
                  </CSSTransition>
                  {!menuAccessState ? <ProfileFooter /> : ""}
                </>
              )}
            </>
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default MainMenuTheme2;
