// @ts-nocheck
import React, { useEffect, useState } from "react";
import "../../styleSheets/tableReserve/bookings.css";
import { BookingCard } from "./bookingCard";
import { getBookings } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../assets/icons/Social_icons/arrowSvg.svg";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

export const BookingIndex = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const callApi = async () => {
    const dataReceived = await getBookings(navigate);
    setData(dataReceived);
  };
  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/my-bookings`, "Booking Page");
  }, []);

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "auto",
        height: "100svh",
        backgroundColor: "#212328",
        padding: "13px",
        overflow: "auto",
      }}
      className="bookings-customScroll"
    >
      <div className="py-3 text-white d-flex align-items-center justify-content-center mb-4">
        <div className="me-auto">
          <img
            src={BackArrow}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div
          className="bookings-heading me-auto"
          style={{ marginLeft: "-41px" }}
        >
          Bookings
        </div>
      </div>
      <div
        className="border bg-white"
        style={{ padding: "11px 10px 60px 10px", borderRadius: "10px" }}
      >
        {data && data.length !== 0 ? (
          data?.map((bookingData) => <BookingCard bookingData={bookingData} />)
        ) : (
          <div className=" text-black text-center">No Bookings found</div>
        )}
      </div>
    </div>
  );
};
