// @ts-nocheck
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import BusinessAccess from "./BusinessAccess";
import classes from "../styleSheets/UpdateProfile.module.css";
import React, { useEffect, useState } from "react";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { USER } from "../constants/localStorageKeys";
import { addNewUser, editUser, getbusinessProfiles } from "../API/authAPIs";
import { MENU_API, ROLES, baseURL } from "../constants/Api";
import "../styleSheets/AddUser.css";
import { AddUserButton } from "../Components/Button.style";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import TopHeader from "../utils/TopHeader";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const EditUser = ({
  profiles,
  setProfiles,
  id,
  name,
  phoneNumber,
  profileId,
  checkNumber,
  role,
  setName,
  setPhoneNumber,
  setEditOpen,
  setRole,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const controlProps = (item) => ({
    checked: role === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  console.log("stateeeeeeeeeeeeeeee", state);

  // const profiles = JSON.parse(stateProfiles);
  // const setProfiles = JSON.parse(stateSetProfiles);
  // const id = JSON.parse(stateId);
  // const name = JSON.parse(stateName);
  // const phoneNumber = JSON.parse(statePhoneNumber);
  // const profileId = JSON.parse(stateProfileId);
  // const checkNumber = JSON.parse(stateCheckNumber);
  // const role = JSON.parse(stateRole);
  // const setName = JSON.parse(stateSetName);
  // const setPhoneNumber = JSON.parse(stateSetPhoneNumber);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState("Add");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkRole, setCheckRole] = useState(false);
  const [checkNum, setCheckNum] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [delUser, setDeleteUser] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const handleClose = () => {
    setOpen(false);
    // setAnchorEl(false)
  };

  const handleUser = async () => {
    if (true) {
      for (var i = 0; i < profiles.length; i++) {
        if (
          profiles[i].mobileNumber == phoneNumber &&
          profiles[i].mobileNumber != checkNumber
        ) {
          alert("User with this number already exists");
          return;
        }
      }
      setLoading(true);
      const Role = await ROLES(role);
      const edit = await editUser(
        id,
        name,
        phoneNumber,
        Role,
        profileId,
        navigate,
      );
      console.log(profiles, "ab");
      console.log(edit.body, "up");
      setProfiles(edit.body);
      console.log(profiles, "up");

      setLoading(false);
      setEditOpen(false);
    }
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`edit-user`, "EditUser Page");
  }, []);

  return (
    <>
      <TopHeader name="Edit User" />
      <div
        className={`${classes.mainContainer} justify-content-center mx-auto sudhar-js`}
      >
        {/* <div className={`${classes.subcontainer}  add-user-body`}>
        <div className={` ${classes.row}`}>
          <div className={`{classes.title} top-head-txt`}>Edit Usersssss</div>
        </div>
      </div> */}

        <div className="add-user-items">
          <Box
            className={`${classes.edit_dish}`}
            sx={customizeMUIComponents.ModalLabel}
          >
            {loading ? (
              <>Updating..</>
            ) : (
              <>
                <div
                  className="ms-2"
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      width: "146px",
                      height: "15px",
                      fontSize: "9px",
                      color: "#27343C",
                      fontWeight: "400",
                      lineHeight: "10.67px",
                      marginTop: "30px",
                    }}
                  >
                    Your Full Name{" "}
                    {checkName && <span style={{ color: "#EF6C00" }}>*</span>}
                  </Typography>

                  <div>
                    <input
                      type="text"
                      className="f-name-box"
                      placeholder="Enter Your Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div>
                    <Typography
                      sx={{
                        width: "146px",
                        height: "15px",
                        fontSize: "9px",
                        color: "#27343C",
                        fontWeight: "400",
                        lineHeight: "10.67px",
                      }}
                    >
                      Select User Role{" "}
                      {checkRole && <span style={{ color: "#EF6C00" }}>*</span>}
                    </Typography>
                    <div>
                      <FormControl
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "12px",
                            color: "#27343C",
                            width: "63px",
                            height: "15px",
                          },
                        }}
                      >
                        <RadioGroup
                          sx={{
                            "& .PrivateSwitchBase-input": {
                              width: "20px",
                              height: "0px",
                              backgroundColor: "red",
                              display: "inline-flex",
                              justifyContent: "left",
                            },
                          }}
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="none"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Admin"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Admin")}
                                sx={{
                                  color: "#27343C",
                                  marginLeft: "20px",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Admin"
                          />
                          <FormControlLabel
                            value="Manager"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Manager")}
                                sx={{
                                  color: "#27343C",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Manager"
                          />
                          <FormControlLabel
                            value="Accounts"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Accounts")}
                                sx={{
                                  color: "#27343C",
                                  marginLeft: "20px",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Accounts"
                          />
                          <FormControlLabel
                            value="Staff"
                            control={
                              <Radio
                                size="small"
                                {...controlProps("Staff")}
                                sx={{
                                  color: "#27343C",
                                  "&.Mui-checked": {
                                    color: "#27343C",
                                  },
                                }}
                              />
                            }
                            label="Staff"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <Typography
                      sx={{
                        width: "146px",
                        height: "15px",
                        fontSize: "9px",
                        color: "#27343C",
                        fontWeight: "400",
                        lineHeight: "10.67px",
                        marginTop: "15px",
                      }}
                    >
                      Phone Number{" "}
                      {checkNum && <span style={{ color: "#EF6C00" }}>*</span>}
                    </Typography>

                    <div className="phone-box">
                      <div className=" p-1 m-1" style={{ display: "flex" }}>
                        <select
                          value={countryCode}
                          className="country-code-box"
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <option
                            className="drop-down-country-code"
                            value="+91"
                          >
                            +91
                          </option>
                          <option
                            className="drop-down-country-code"
                            value="+62"
                          >
                            +62
                          </option>
                        </select>
                        <span className="vertical-bar">|</span>
                        <div className="enter-num-txt">
                          <input
                            type="tel"
                            style={{
                              paddingLeft: "5px",
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "12px",
                              borderStyle: "none",
                              outline: "none",
                            }}
                            placeholder="Enter Your Mobile No"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <input
                    type="tel"
                    style={customizeMUIComponents.businessAccessInputBox}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  /> */}
                  </div>
                  {checkNum == true ? (
                    <div className={classes.mandatoryText}>
                      *Add correct number
                    </div>
                  ) : (
                    ""
                  )}
                  {(name && phoneNumber && role) === "" ? (
                    <div className={`{classes.mandatoryText} inval-numb-txt`}>
                      All Details are mandatory
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="ca" style={customizeMUIComponents.row}>
                    <AddUserButton
                      marginTop="50px"
                      backgroundColor="#D1D1D1"
                      variant="outlined"
                      onClick={() => setEditOpen(false)}
                    >
                      Cancel
                    </AddUserButton>

                    <Dialog open={open} onClose={() => handleClose(false)}>
                      <DialogTitle className="discard-header">
                        Discard all saved changes ?
                      </DialogTitle>
                      {/* <DialogContent>
                      <DialogContentText>
                        Discard all saved changes ?
                      </DialogContentText>
                    </DialogContent> */}
                      <DialogActions className="ms-3">
                        <AddUserButton
                          marginTop="-60px"
                          onClick={() => handleClose(true)}
                          color="primary"
                        >
                          No
                        </AddUserButton>
                        <AddUserButton
                          marginTop="-60px"
                          onClick={() => navigate(-1)}
                          color="primary"
                        >
                          Yes
                        </AddUserButton>
                      </DialogActions>
                    </Dialog>

                    <AddUserButton
                      marginTop="50px"
                      backgroundColor="#D1D1D1"
                      variant="contained"
                      onClick={handleUser}
                    >
                      Update
                    </AddUserButton>
                  </div>
                </div>
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default EditUser;
