//@ts-nocheck
import { baseURL } from "../constants/Api";

export const DigitalOceanFetch = async (file) => {
  const chunkSize = 10 * 1024 * 1024; // 5MB per chunk
  const totalChunks = Math.ceil(file.size / chunkSize);
  const folderName = localStorage.getItem("RestrauntName"); // Retrieve folder name
  const originalname = file.name; // Original file name

  let start = 0;
  let chunkNumber = 0;
  let progress = 0;

  const uploadChunk = async (chunk, chunkNumber) => {
    const formData = new FormData();
    formData.append("file", chunk); // Current chunk
    formData.append("chunkNumber", chunkNumber); // Current chunk number
    formData.append("totalChunks", totalChunks); // Total chunks
    formData.append("folderName", folderName); // Target folder in S3
    formData.append("originalname", originalname); // Original file name

    try {
      const response = await fetch(`${baseURL}/v1/DO-upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Chunk ${chunkNumber + 1} failed to upload.`);
      }

      const data = await response.json();
      return data; // Response includes message or final URL
    } catch (error) {
      console.error("Error uploading chunk:", error);
      throw error;
    }
  };

  try {
    while (start < file.size) {
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end); // Extract the current chunk

      const data = await uploadChunk(chunk, chunkNumber);

      progress = ((chunkNumber + 1) / totalChunks) * 100;
      console.log(`Progress: ${progress.toFixed(2)}%`);

      // Check if it's the last chunk and contains the imgURL
      if (data.imgURL && chunkNumber === totalChunks - 1) {
        return data.imgURL; // Return the final URL
      }

      chunkNumber++;
      start = end; // Move to the next chunk
    }
  } catch (error) {
    console.error("Error during file upload:", error);
    throw error;
  }
};
