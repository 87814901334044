// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../../styleSheets/more-options.css";
import { Upload_Images } from "../../utils/Upload-Image";
import { cloudinaryFetch } from "../../API/Cloudinary-fetch-images";
import { changePictureApi, changeVideoApi } from "../../API/authMenuAPIs";
import { useNavigate } from "react-router-dom";
import { DigitalOceanFetch } from "../../API/digitalocean-fetch-images";
import { setApiData } from "../../redux/actions";
import { toast } from "react-hot-toast";

export const ChangeVideos = ({
  type,
  data,
  setData,
  objId,
  videoEdit,
  setVideoEdit,
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState("");
  const [select, setSelect] = useState<string>("Video");
  const handleImgEdit = () => {
    setVideoEdit((prev) => ({ ...prev, videoEdit: false }));
  };
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  let dataRetured;

  const uploadImgFunc = async (tosendImage) => {
    if (tosendImage.type.startsWith("video")) {
      const imgUrl = await DigitalOceanFetch(tosendImage);
      const mediaType = tosendImage.type.startsWith("image")
        ? "image"
        : "video";
      if (mediaType === "image") {
        toast.error("Only Videos Are Allowed!");
        return;
      } else {
        dataRetured = await changeVideoApi(type, imgUrl, objId, navigate);
      }
      if (type === "Category") {
        await setData(dataRetured?.body?.menu?.categories);
        dispatch(setApiData(dataRetured?.body?.menu));
      }
      if (type === "SubCategory")
        await setData(dataRetured?.body?.menu?.subCategories);
      if (type === "Item") await setData(dataRetured?.body?.menu?.items);
      setIsUploading(false);
      setImages("");
      setVideoEdit((prev) => ({ ...prev, videoEdit: false }));
    } else {
      toast.error("Only Videos Are Allowed!");
      setVideoEdit(false);
    }
  };

  return (
    <>
      <Upload_Images
        imgEdit={videoEdit}
        setImgEdit={setVideoEdit}
        images={images}
        setImages={setImages}
        uploadImgFunc={uploadImgFunc}
        aspectRatio={1 / 1}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        maxSizeMB={0.5}
        select={select}
      />
    </>
  );
};
