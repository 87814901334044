// @ts-nocheck
import React, { useState } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { USER } from "../../constants/localStorageKeys";
import { category } from "../../API/MenuApi";
import { MENU_API, baseURL } from "../../constants/Api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { Edit } from "./Edit";
import Delete from "./Delete";
import Unpublish from "./Unpublish";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "../../styleSheets/more-options.css";
import { ChangePictures } from "../CommonFiles/ChangePictures";
import { Edit_Cat_SubCat } from "./Edit-Cat-Subcat";
import { Edit_Item } from "./Edit-Item";

const MenuModal = ({
  objName,
  objImage,
  type,
  objId,
  objPrice,
  objType,
  data,
  setData,
  publish,
  objDescription,
  objData,
  setWholeData,
  setItemCount,
  setIsDeleted,
  callApis,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const resName = localStorage.getItem(USER.USERNAME);

  // const data = category(`${baseURL}${MENU_API.MENU}${resName}`);
  const open = Boolean(anchorEl);
  const [openEdit, setOpenEdit] = React.useState(false);

  const [menuItemStates, setMenuItemStates] = useState({
    editSubCat: false,
    editItem: false,
    pubUnpub: false,
    imgEdit: false,
    delete: false,
  });

  const handleMenuItemStates = (e) => {
    setAnchorEl(null);
    const changedState = e.target.id;
    const updatedMenuItemStates = { ...menuItemStates, [changedState]: true };
    setMenuItemStates(updatedMenuItemStates);
  };

  const handleClick = (event) => {
    // console.log('event console logged------>', event)
    const moreVertIcon = event.currentTarget.querySelector("svg"); // Find the MoreVertIcon element
    setAnchorEl(moreVertIcon);

    // console.log(data[index],'index')
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className={`${type === "Category" && "p-0"}`}
          disableRipple={type === "Category"}
        >
          <MoreVertIcon sx={{ padding: "0px" }} fontSize="small" />
        </IconButton>
        <Menu
          className="z-1"
          onKeyDownCapture={(e) => e.stopPropagation()}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="main-div">
            {type === "Item" ? (
              <div
                id="editItem"
                className="options d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={handleMenuItemStates}
              >
                Edit
              </div>
            ) : (
              <div
                id="editSubCat"
                className="options d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={handleMenuItemStates}
              >
                Rename
              </div>
            )}

            <div
              id="pubUnpub"
              className="options d-flex align-items-center"
              onClick={handleMenuItemStates}
            >
              {publish == "0" ? (
                <div id="pubUnpub" style={{ cursor: "pointer" }}>
                  Publish
                </div>
              ) : (
                <div id="pubUnpub" style={{ cursor: "pointer" }}>
                  Unpublish
                </div>
              )}
            </div>

            <div
              id="imgEdit"
              onClick={handleMenuItemStates}
              className="options d-flex align-items-center"
            >
              Change Picture
            </div>

            <div
              id="delete"
              className="options d-flex align-items-center"
              onClick={handleMenuItemStates}
            >
              Delete
            </div>
          </div>
        </Menu>

        <div>
          <Edit_Item
            showOverlay={menuItemStates.editItem}
            setShowOverlay={setMenuItemStates}
            type={type}
            setData={setData}
            data={data}
            objId={objId}
            objData={objData}
            callApis={callApis}
            setWholeData={setWholeData}
          />

          <Edit_Cat_SubCat
            showOverlay={menuItemStates.editSubCat}
            setShowOverlay={setMenuItemStates}
            type={type}
            setData={setData}
            data={data}
            objId={objId}
            objData={objData}
          />

          <Unpublish
            open={menuItemStates.pubUnpub}
            setOpen={setMenuItemStates}
            objData={objData}
            setWholeData={setWholeData}
            type={type}
            objId={objId}
            data={data}
            setData={setData}
            publish={publish}
          />

          <ChangePictures
            imgEdit={menuItemStates.imgEdit}
            setImgEdit={setMenuItemStates}
            type={type}
            data={data}
            setData={setData}
            objId={objId}
            setAnchorEl={setAnchorEl}
          />

          <Delete
            open={menuItemStates.delete}
            setOpen={setMenuItemStates}
            setIsDeleted={setIsDeleted}
            setItemCount={setItemCount}
            type={type}
            objId={objId}
            data={data}
            setData={setData}
            setAnchorEl={setAnchorEl}
          />
        </div>
      </div>
    </>
  );
};

export default MenuModal;
