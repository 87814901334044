// @ts-nocheck
import { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import { USER } from "../../constants/localStorageKeys";
import classes from "../../styleSheets/FoodMenu.module.css";

import { Box } from "@mui/material";
import { ActionBtn } from "./action-btn";
import { IntegrationBadge } from "./Integration-Badge";
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  AutoScrollActivator,
} from "@dnd-kit/core";
import {
  autoScrollProp,
  handleDragEnd,
  handleDragOver,
  handleDragStart,
  modifierProp,
} from "../../utils/dnd/handlers";
import { SortableContext } from "@dnd-kit/sortable";
import { UseSortableFile } from "../../utils/dnd/useSortableFile";
import { MappedCategory } from "./mappedCategory";
import { DraggableItem } from "../../utils/dnd/draggableItem";
import "../../styleSheets/dnd-utility-classes.css";
import { createPortal } from "react-dom";
import { Loader } from "../../utils/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { EventsInMenu } from "../eventsMenu";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";
import Marquee from "react-fast-marquee";
//const [analytics, setAnalytics] = useState<AnalyticsUtil | null>(null);

const CategorySection = ({
  name,
  data,
  setData,
  loadingImage,
  analytics,
  setAnalytics,
  announcementMessage,
}: {
  name?: string;
  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  loadingImage?: boolean;
  analytics;
  setAnalytics;
}) => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const { name2, name1 } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [Id, setId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const token = localStorage.getItem("accessToken");
  const [activeId, setActiveId] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  const resName = localStorage.getItem(USER.USERNAME);
  const dispatch = useDispatch();
  const sensorData = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    }),
  );

  function handleclick(id) {
    const checkName = name1 ? name1 : name2;
    setActive(true);

    setId(id);
    const isMobile = window.screen.width;
    console.log(isMobile, "vvv");
    menuAccessState
      ? navigate({
          pathname: "/edit/menu/SubCategory",
          search: createSearchParams({ name: checkName, id: id }).toString(),
        })
      : navigate({
          pathname: "/menu/SubCategory",
          search: createSearchParams({ name: checkName, id: id }).toString(),
        });
  }

  useEffect(() => {
    setCategoryData(data?.categories);
  }, [data?.categories]);

  return (
    <>
      {announcementMessage.message && (
        <a
          href={announcementMessage.link}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div
            className="text-announcement text-center mt-4 p-2 text-nowrap overflow-hidden"
            style={{ backgroundColor: "#7ECAB0" }}
          >
            <Marquee>
              <div style={{ margin: "0 6rem", color: "black" }}>
                {announcementMessage.message}
              </div>
            </Marquee>
          </div>
        </a>
      )}
      <div
        className={`${classes["category-section-main-div"]} ${classes["space-in-cat-section"]} `}
      >
        <EventsInMenu />
        <div className={classes["menu-category-heading"]}>
          <div className={classes["categories-center-lines"]} />
          <div className={classes.center}>Categories</div>
          <div className={classes["categories-center-lines"]} />
        </div>
        <div
          className={classes.subContainer}
          style={{
            paddingLeft: `${menuAccessState ? "20px" : "0"}`,
            overflowX: "hidden",
          }}
        >
          {loadingImage ? (
            <div className={classes.center}>Loading...</div>
          ) : (
            <>
              {menuAccessState ? (
                <ActionBtn
                  categoryData={categoryData}
                  setCategoryData={setCategoryData}
                  setWholeData={setData}
                  data={data}
                />
              ) : (
                ""
              )}

              {categoryData?.length === 0 ? (
                <>
                  {" "}
                  <div className={classes.center} style={{ fontSize: "1rem" }}>
                    There are no Categories Yet!!
                  </div>
                </>
              ) : (
                <Box sx={{ width: "100%" }}>
                  {/* <Grid className="border border-info" container spacing={1}> */}
                  <div className="row p-0 m-0 row-cols-2 overflow-x-hidden">
                    <DndContext
                      autoScroll={autoScrollProp}
                      // modifiers={modifierProp}
                      sensors={sensorData}
                      onDragStart={(e) => handleDragStart(e, setActiveId)}
                      onDragEnd={() =>
                        handleDragEnd(
                          setActiveId,
                          data?.categories,
                          setData,
                          categoryData,
                          "category",
                          navigate,
                          setLoaderState,
                          dispatch,
                        )
                      }
                      onDragOver={(e) => handleDragOver(e, setCategoryData)}
                      collisionDetection={closestCenter}
                    >
                      <SortableContext items={categoryData || []}>
                        {categoryData?.map((item, index) => {
                          const img = item.cloudinary_url;
                          const type = "category";
                          return (
                            <div
                              className={`
                              d-flex 
                              justify-content-center
                              ${!menuAccessState && item.publish === 0 && "d-none"}
                              `}
                              onContextMenu={(e) => e.preventDefault()}
                              key={item._id}
                            >
                              <UseSortableFile
                                propId={item}
                                isCategorySection={true}
                                MappedEl={
                                  <MappedCategory
                                    index={index}
                                    item={item}
                                    handleclick={handleclick}
                                    categoryData={categoryData}
                                    setCategoryData={setCategoryData}
                                    fullData={data}
                                    analytics={analytics}
                                    setAnalytics={setAnalytics}
                                  />
                                }
                              />
                            </div>
                          );
                        })}

                        <DragOverlay>
                          {activeId ? (
                            <div className="row">
                              <div className=" d-flex justify-content-center">
                                <DraggableItem
                                  id={activeId}
                                  isDragOverlay={true}
                                  isCategorySection={true}
                                  // style={{paddingLeft: "4rem"}}

                                  MappedEl={
                                    <MappedCategory
                                      index={categoryData.findIndex(
                                        (item) => item._id === activeId._id,
                                      )}
                                      item={activeId}
                                      handleclick={handleclick}
                                      categoryData={categoryData}
                                      setCategoryData={setCategoryData}
                                      isDragging={true}
                                    />
                                  }
                                  isDragging
                                />
                              </div>
                            </div>
                          ) : null}
                        </DragOverlay>
                      </SortableContext>
                    </DndContext>
                  </div>

                  {/* </Grid>{" "} */}
                </Box>
              )}
            </>
          )}
        </div>
      </div>

      <Loader
        open={loaderState}
        setOpen={setLoaderState}
        message={"Loading..."}
      />
    </>
  );
};

export default CategorySection;
