// @ts-nocheck
import React, { useState, useEffect } from "react";
import classes from "../styleSheets/chooseCategory.module.css";

import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CrossIcon from "../assets/cross-icon.svg";
import CheckIcon from "../assets/checked-cat.svg";
import UncheckedIcon from "../assets/unchecked-cat.svg";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MENU_API, baseURL } from "../constants/Api";
import { getAllCategories } from "../API/authMenuAPIs";
import searchBar from "../assets/images/images/Group 882.jpg";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import Box from "@mui/material/Box";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import Modal from "@mui/material/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import classes1 from "../styleSheets/UpdateProfile.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createCategory } from "../API/authAPIs";
import { Button, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckBox from "../Social_components/CheckBox";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import AnalyticsUtil from "../utils/GoogleAnalytics";
const ChooseCategoryPage = ({
  Type,
  setType,
  array,
  setArray,
  setCategorySearch,
}) => {
  const [categories, setCategories] = useState([]);
  const [arr, setArr] = useState(array);
  const [value, setValue] = useState("");
  const [createCat, setCreateCat] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [flag, setflag] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const arr1 = [];
  var add;
  var b = [];
  const navigate = useNavigate();
  const callApis = async () => {
    const data = await getAllCategories(
      `${baseURL}${MENU_API.GET_ALL_CATEGORIES}/?name=${value}`,
      navigate,
    );
    setCategories(data.categories);
    setflag(data.matched);
    console.log(data, "categories");
  };

  useEffect(() => {
    if (!value) {
      setCategories([]);
    }
    const getdata = setTimeout(() => {
      callApis();
    }, 500);
    return () => clearTimeout(getdata);
  }, [value]);
  useEffect(() => {
    setTimeout(handleClose1, 2000);
  }, [open]);
  async function handleChange(e) {
    if (arr.length > 2) {
      alert("you can add only 3 categories");
    }
    setValue(e.target.value);
    console.log(e.target.value, "checking vall");
  }
  const handleClose1 = () => {
    setOpen(false);
    // setAnchorEl(false)
  };
  async function handleCreate() {
    const create = await createCategory(value, navigate);
    setOpen(true);

    setValue("");

    await handleClick(value);
  }

  const handleClick = async (item) => {
    add = false;
    console.log(value);
    console.log(arr.length);
    for (var i = 0; i < arr.length; i++) {
      if (item.toLowerCase() == arr[i].toLowerCase()) {
        const newArr = arr.filter(
          (element) => element.toLowerCase() !== item.toLowerCase(),
        );
        setArr(newArr);
        return;
      }
    }
    if (arr.length >= 3) {
      alert("you can add only 3 categories");
    } else {
      arr1.push(item);
      if (item) {
        setArr([item].concat(arr));
        setArray([item].concat(array));
      }
    }
    //  arr.push(item)
    setCreateCat(false);
    console.log("businessProfiles", arr, arr1, b);
  };

  console.log(arr.length, "array");

  function handleClose(index) {
    console.log(index);

    setArr([...arr.slice(0, index), ...arr.slice(index + 1)]);
    setArray([...array.slice(0, index), ...array.slice(index + 1)]);
  }

  async function handleSave() {
    console.log(array, "arr", setArray, "arr", setCategorySearch);

    // [category].concat(arr)
    console.log(arr, "ggj");
    setCategorySearch(false);
  }

  console.log("arrrrrrrr hai $$$$$$$$$$$$$$$$$$", arr);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/ChooseCategory`, "ChooseCategory Page");
  }, []);
  return (
    <div
      style={{
        maxWidth: "450px",
        minHeight: "100vh",

        margin: "auto",
        backgroundColor: "rgb(228 227 227 / 44%)",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.popupModal}>
          <div style={{ fontSize: "12px", color: "#FFF" }}>
            The category has been created
          </div>
        </Box>
      </Modal>
      <div
      // className={classes1.subContainer}
      >
        <div className={classes.subContainer}>
          <div className={classes.rectangularBar}>
            <div
              style={{
                height: "55px",
                backgroundColor: "#27343C",
                padding: "1px",
              }}
              className={classes.row}
            >
              <div
                style={{
                  height: "33px",
                  width: "33px",
                  borderRadius: "50%",
                  backgroundColor: "#40525D",
                  margin: "10px",
                }}
              >
                <ArrowBackRoundedIcon
                  sx={{
                    color: "#fff",
                    margin: "6px",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={() => {
                    return setCategorySearch(false);
                  }}
                />
              </div>
              {/* <ArrowCircleLeftIcon
                          sx={{
                            color: "#40525D",

                            margin: "13px",

                            fontSize: "32px",
                          }}
                          onClick={() => {
                            navigate(-1);
                          }}

                          // className={classes1.arrow}
                        />  */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            height: "55px",
            width: "360px",
            margin: "20px auto",
            display: "flex",
            backgroundColor: "#fff",
            borderRadius: "5px",
            justifyContent: "space-between",
            padding: "25px",
          }}
          className={classes.profile}
        >
          <FormControlLabel
            sx={{
              fontSize: "13px",
              color: "#0D0C22",
              fontWeight: "300",
            }}
            value="personal"
            control={
              <Radio
                checked={Type === "personal"}
                onChange={() => {
                  setType("personal");
                }}
                sx={{
                  height: "0px",
                  width: "0px",
                  marginRight: "4px",
                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                    {
                      color: "#40525D",
                    },
                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                    color: "#7ECAB0",
                  },
                }}
              />
            }
            label="Personal"
          />
          <FormControlLabel
            sx={{
              fontSize: "13px",
              color: "#0D0C22",
              fontWeight: "300",
            }}
            value="business"
            control={
              <Radio
                checked={Type === "business"}
                onChange={() => {
                  setType("business");
                }}
                sx={{
                  height: "0px",
                  width: "0px",
                  marginRight: "4px",
                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                    {
                      color: "#40525D",
                    },
                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                    color: "#7ECAB0",
                  },
                }}
              />
            }
            label="Business"
          />
        </div>
      </div>

      {Type === "personal" ? (
        <div className="text-center" style={{ fontSize: "13px" }}>
          Your profile category will be saved as personal
        </div>
      ) : (
        <>
          <div
            style={{
              height: "55px",
              width: "360px",
              padding: "20px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "auto",
              color: "white",
              backgroundColor: "#40525D",
              borderRadius: "5px",
            }}
            className={classes.categories}
          >
            {/* <div
            style={{
              width: "450px",
              color: "#fff",
              margin: "15px 180px",
              fontSize: "small",
            }}
          >
            {" "}
            Drinks{" "}
          </div> */}
            {arr?.map((item, index) => {
              return (
                <div style={{ fontSize: "10px", padding: "10px" }}>
                  {item}
                  <img
                    src={CrossIcon}
                    onClick={() => {
                      const tempData = [...arr];
                      tempData.splice(index, 1);
                      setArr(tempData);
                    }}
                    className="ms-1"
                  />
                </div>
              );
            })}
          </div>

          <div
            style={{
              margin: "8px 35px",
              color: "#27343C",
              padding: "10px",
              textAlign: "center",
              fontSize: "13px",
            }}
            className={classes.title}
          >
            <p style={{ margin: "auto", alignContent: "center" }}>
              You Need To Add Minimum 1 And Maximum 3 Category For Your Business
              profile
            </p>
          </div>

          <div
            className={classes.stack}
            style={{
              height: "55px",
              width: "360px",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              justifyContent: "space-between",
              margin: "20px auto",
            }}
          >
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
              sx={{
                padding: "4px",

                alignItems: "center",
                display: "flex",
                width: "100%",
              }}
            >
              {/* {arr?.map((item, index) => {
            return <div className={classes1.stackCat1}>{item}</div>;
          })} */}
              {/* { arr.length < 3&&<div style={{height:'30px'}}>|</div> } */}
              {arr.length < 3 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "6px",
                  }}
                >
                  <img
                    src={searchBar}
                    alt="search"
                    style={{
                      height: "15px",
                      width: "15px",
                      // border: "2px solid red",

                      color: "#EF6C00",
                    }}
                    onClick={() => handleClose(index)}
                  />
                </div>
              )}

              <div style={{ marginTop: "-2px" }}>
                {arr.length < 3 && (
                  <input
                    className={
                      arr.length == 0 ? classes.inputBox : classes.inputBox2
                    }
                    style={{ width: "100%" }}
                    value={value}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                )}
              </div>
            </Stack>
          </div>
          <div>
            {categories.length === 0 && value !== "" && (
              <div
                style={{
                  color: "rgba(3, 168, 132, 1)",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleCreate}
              >
                {" "}
                Click here to create new category:{" "}
                <span style={{ color: "#40525D", marginLeft: "5px" }}>
                  {value}
                </span>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            <div>
              {categories?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleClick(item?.name)}
                    className="d-flex align-items-center"
                    style={{ marginLeft: "50px" }}
                  >
                    {/* <CheckBox /> */}
                    {arr.includes(item?.name) ? (
                      <img src={CheckIcon} />
                    ) : (
                      <img src={UncheckedIcon} />
                    )}
                    {value != "" && (
                      <div
                        className={`ms-2 ${classes.business_category_name} `}
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        {item?.name}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <Button
          onClick={() => setCategorySearch(false)}
          variant="contained"
          sx={{
            marginBottom: "35px",
            border: "none",
            borderRadius: "25px",
            width: "100px",
            textTransform: "capitalize",
            height: "35px",
            backgroundColor: "#0003",
            color: "black",
          }}
        >
          save
        </Button>
      </div>
    </div>
  );
};

export default ChooseCategoryPage;
