import React from "react";
import styles from "./QRUserOptionsMenu.module.css";

const QRUserOptionsMenu = ({
  userCode,
  deleteQRUser,
  active,
  toggleUserStatus,
  UpdateUserData,
}) => {
  const handleDeleteQRUser = () => {
    deleteQRUser(userCode);
  };

  const handleToggleStatus = () => {
    toggleUserStatus(userCode, active);
  };

  const handleUpdateQRUser = () => {
    console.log("Update User Details clicked");
    UpdateUserData(userCode);
  };
  return (
    <div className={`dropdown-menu ${styles.optionsMenu} show`}>
      <button className="dropdown-item" onClick={handleDeleteQRUser}>
        Delete QR User
      </button>
      <button className="dropdown-item" onClick={handleToggleStatus}>
        {active ? "Deactivate" : "Activate"} QR User
      </button>
      <button className="dropdown-item" onClick={handleUpdateQRUser}>
        Update User Details
      </button>
    </div>
  );
};

export default QRUserOptionsMenu;
