// @ts-nocheck
import React, { useEffect, useState } from "react";
import placeholder from "../../RestaurantMenu/Images/placeholders/item_placeholder.jpg";
import classes from "../../styleSheets/FoodMenu.module.css";

import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { IconsArr, Item_names, Item_prices } from "./Item-details-extract";
import MenuModal from "../Modals Layout/MenuModal";
import "../../styleSheets/item-section.css";
import { USER } from "../../constants/localStorageKeys";
import { useSelector } from "react-redux";
import { json, useNavigate } from "react-router-dom";

import Cart from "./cart";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";
import videoIcon from "../../assets/images/play-button.png";
export const MappedItem = ({
  index,
  item,
  handleOpen,
  itemData,
  id,
  storedItems,
  setStoredItems,
  setitemData,
  isDragOverlay,
  callApis,
  analytics,
  setAnalytics,
  totalAddedCart,
  setTotalAddedCart,
}) => {
  const [buttonText, setButtonText] = useState("ADD");
  const [buttonColor, setButtonColor] = useState("blue");
  const token = localStorage.getItem("accessToken");
  const resName = localStorage.getItem(USER.USERNAME);
  const name = localStorage.getItem("name");
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const toUseImgSrc = item.image
    ? checkImageUrl(item?.image) === ""
      ? encodeURI(item.image)
      : encodeURI(`${imgBaseUrl}/${item?.image}`)
    : "";

  const navigate = useNavigate();
  console.log("ITEM ME KYA KYA HAI :", item);

  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("CartData")) || [];
    const isItemInCart = cartData.some(
      (storedItem) => storedItem._id === item._id,
    );

    if (isItemInCart) {
      setButtonText("Remove");
      setButtonColor("red");
    } else {
      setButtonText("ADD");
      setButtonColor("blue");
    }
  }, [item]);

  const HandleAddingToCart = (e, item) => {
    e.stopPropagation();

    const cartData = JSON.parse(localStorage.getItem("CartData")) || [];

    const isItemInCart = cartData.some(
      (storedItem) => storedItem._id === item._id,
    );

    if (isItemInCart) {
      setButtonText("ADD");
      setButtonColor("blue");

      const ToDeleteIndex = cartData.findIndex(
        (storedItem) => storedItem._id === item._id,
      );
      cartData.splice(ToDeleteIndex, 1);
      setTotalAddedCart(cartData);
      localStorage.setItem("CartData", JSON.stringify(cartData));
    } else {
      setButtonText("Remove");
      setButtonColor("red");

      cartData.push(item);
      setTotalAddedCart(cartData);
      localStorage.setItem("CartData", JSON.stringify(cartData));
    }
  };

  return (
    <>
      <div key={index} onContextMenu={(e) => e.preventDefault()}>
        <div style={{ position: "relative" }}>
          <div>
            <div
              style={{ marginTop: !isDragOverlay && "20px", display: "flex" }}
            >
              {menuAccessState ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`my-auto ${item.publish === 0 && "opacity-50"}`}
                      onClick={() => handleOpen(item?._id)}
                    >
                      <div
                        onContextMenu={(e) => e.preventDefault()}
                        style={{
                          backgroundImage: `url(${imageLoaded ? toUseImgSrc : placeholder})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={classes.itemImg1}
                      >
                        {!item.image && item.video && (
                          <div className="video-icon flex justify-content-center align-items-center">
                            <img
                              src={videoIcon}
                              alt="Video Icon"
                              style={{
                                width: "40px",
                                height: "40px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                          </div>
                        )}
                        <img
                          src={toUseImgSrc}
                          alt={item?.itemName}
                          style={{ display: "none" }}
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                    <div
                      onClick={(e) => {
                        handleOpen(item?._id, e);
                        analytics.sendEvent(
                          AnalyticEvents.Menu.Item,
                          AnalyticEvents.MenuAction.Open,
                          item?.itemName,
                        );
                      }}
                      className={`my-auto ${item.publish === 0 && "opacity-50"}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "4px",
                      }}
                    >
                      <div
                        className="my-auto"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className={`item_name_list d-flex align-items-center my-auto ms-2`}
                          style={{
                            overflowWrap: "anywhere",
                          }}
                        >
                          <Item_names
                            varietyArr={item?.varietyArr}
                            baseName={item?.itemName}
                          />
                          <div className="ms-3" style={{ marginTop: "-1px" }}>
                            <IconsArr item={item} />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`item_price_list my-auto ms-2`}
                        style={{ wordBreak: "break-all" }}
                      >
                        ₹{" "}
                        <Item_prices
                          varietyArr={item?.varietyArr}
                          price={item?.price}
                        />{" "}
                      </div>
                    </div>

                    <div className={`ms-auto my-auto me-1`}>
                      {" "}
                      {menuAccessState && (
                        <MenuModal
                          itemCheck={true}
                          hideExtraSetting={false}
                          objData={itemData[index]}
                          objName={itemData[index]?.itemName}
                          objImage={itemData[index]?.image}
                          type={"Item"}
                          setWholeData={id?.setWholeData}
                          objId={itemData[index]?._id}
                          objPrice={itemData[index]?.price}
                          objType={itemData[index]?.type}
                          data={itemData}
                          setData={setitemData}
                          publish={itemData[index]?.publish}
                          objDescription={itemData[index]?.description}
                          setItemCount={id?.setItemCount}
                          setIsDeleted={id?.setIsDeleted}
                          callApis={callApis}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                item?.publish != 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <div
                      className="my-auto"
                      onClick={(e) => handleOpen(item?._id)}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${imageLoaded ? toUseImgSrc : placeholder})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={classes.itemImg1}
                      >
                        {!item.image && item.video && (
                          <div className="video-icon flex justify-content-center align-items-center">
                            <img
                              src={videoIcon}
                              alt="Video Icon"
                              style={{
                                width: "40px",
                                height: "40px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                          </div>
                        )}
                        <img
                          src={toUseImgSrc}
                          alt={item?.itemName}
                          style={{ display: "none" }}
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                    <div
                      className="my-auto"
                      onClick={(e) => {
                        handleOpen(item?._id, e);
                        analytics.sendEvent(
                          AnalyticEvents.Menu.Item,
                          AnalyticEvents.MenuAction.Open,
                          item?.itemName,
                        );
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className={`item_name_list d-flex align-items-center my-auto ms-2`}
                          style={{ overflowWrap: "anywhere" }}
                        >
                          <Item_names
                            varietyArr={item?.varietyArr}
                            baseName={item?.itemName}
                          />
                          <div className="ms-3" style={{ marginTop: "-1px" }}>
                            <IconsArr item={item} />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`item_price_list my-auto ms-2`}
                        style={{ wordBreak: "break-all" }}
                      >
                        ₹{" "}
                        <Item_prices
                          varietyArr={item?.varietyArr}
                          price={item?.price}
                        />{" "}
                      </div>
                      <div style={{ marginLeft: "10px", padding: "5px" }}>
                        <button
                          type="button"
                          style={{
                            width: "fit-content",
                            height: "30px",
                            fontSize: "smaller",
                            marginBottom: "5px",
                            border: "none",
                            borderRadius: "10px",
                            color: buttonColor,
                            backgroundColor: "#f8efeb ",
                            padding: "5px",
                          }}
                          onClick={(e) => HandleAddingToCart(e, item, index)}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
