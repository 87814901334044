// @ts-nocheck
import React, { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Card2 from "../Components/Card2";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const RefundCancellation = ({ hideIcon }) => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/refund-policy`, "RefundPolicy Page");
  }, []);

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", padding: "30px" }}
      >
        <CloseIcon
          onClick={() => {
            window.location.reload();
          }}
          className={`${hideIcon && "d-none"}`}
        ></CloseIcon>
        <h1 style={{ textAlign: "center" }}> Refund And Cancellation Policy</h1>
        <p>
          Thank you for purchasing our services at “Connect App” operated by
          eatoes web private limited. Due to the nature of our business and the
          services we provide, customers are not eligible for a refund. However,
          you can cancel your monthly subscription at any time and we will not
          charge you for the upcoming months.
        </p>
        <p>
          After you cancel your subscription with “Connect App” you will still
          be able to use our services of the paid plan till the time you have
          already paid for. After that, you will be downgraded to our
          forever-free plan.
        </p>
        <p>
          For further clarification on the refund and cancellation policy please
          contact support@eatoes.com.
        </p>
      </div>
    </>
  );
};

export default RefundCancellation;
