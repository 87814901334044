// @ts-nocheck

import { MENU_API, baseURL } from "../../constants/Api";
import { USER } from "../../constants/localStorageKeys";
import { sessionExpireLogic } from "../../utils/functionUtils";

export const MenuDragDropApi = async (type, oldData, newData, navigate) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    type: type,
    oldData: oldData,
    reorderedData: newData,
    userId: userId,
  });

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.MENU_DRAG_DROP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();

    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic()
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};