// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import { useEffect } from "react";
export const PrivacyPolicy = ({ hideIcon }) => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/privacy-policy`, "PrivacyPolicy Page");
  }, []);

  return (
    <div style={{ justifyContent: "center", padding: "30px" }}>
      <CloseIcon
        onClick={() => {
          window.location.reload();
        }}
        className={`${hideIcon && "d-none"}`}
      ></CloseIcon>
      <h1 style={{ textAlign: "center" }}> Privacy Policy</h1>
      <p>
        This PRIVACY POLICY (the “ Privacy Policy”) explains how eatoes web
        private limited. (“Eatoes", “we”, “our” or “us”) collects, uses, shares,
        and protects the personal information of persons who interact with our
        website, [www.eatoes.com/home] (“Site”), mobile application (“App”), or
        platform (“Platform”) (the Site, App, and Platform shall collectively be
        the “Service”), and the transactional information of persons who make
        purchases from merchants that have subscribed to our Platform (“Rewards
        Providers”).
      </p>
      <p>
        Even if you do not sign up as a customer of "Connect App", we may still
        collect, process, use, store, and share your transactional data in
        accordance with this Privacy Policy. By using the Service, you agree to
        the policies and practices described in this Privacy Policy and the
        Terms of Use. If you do not agree with our policies and practices, you
        may choose not to use the Service.
      </p>
      <p>
        "Connect App" may offer certain products and/or services for a fee or
        for evaluation purposes. In such situations, additional terms or
        requirements may apply to our collection and use of personal
        information, and if there is any conflict between such additional terms
        and this Privacy Policy, the additional terms will control.
      </p>
      <h4 style={{ textAlign: "left" }}>Information We Collect</h4>
      <p>
        When you interact with us through the Service, we may collect personal
        information from and about you. You may interact with us directly, such
        as when you sign up for a "Connect App" account through the Site or App,
        as well as indirectly, such as when you sign up for a "Connect App"
        account through our Platform operated by one of our Rewards Providers.
        When you make a purchase or otherwise interact with one of our Rewards
        Providers, we may also collect transactional information from you.
      </p>
      <h4 style={{ textAlign: "left" }}>Information You Provide</h4>
      <p>
        We collect personal information from you when you voluntarily provide
        it, such as when you register for access to the Service and create an
        account, use certain aspects of the Service, contact us with inquiries
        or respond to one of our surveys. For example, we may collect your name,
        phone number, email address, photograph, birthdate, location
        information, Third-Party Service (defined below) login credentials,
        other information (such as your online activity) based on your
        registration and privacy settings on those Third-Party Services, and the
        messages you send or receive through the Service.
      </p>
      <h4>Information Automatically Collected</h4>
      <p>
        We collect transactional information automatically when you transact
        with one of our Rewards Providers. Transactional information may include
        details from your receipt (e.g., subtotal, total, and tip amount),
        payment card information (e.g., the last four digits of your payment
        card number and payment card expiration date), bill, and order ticket
        data (e.g., items purchased and their purchase price) from point of sale
        printers and devices (“Terminals”) used by Rewards Providers. When you
        interact with the Service, we also may use various technologies,
        including cookies, web beacons, pixel tags, log files, local shared
        objects (Flash cookies), HTML5 cookies, or other tracking technologies,
        to automatically collect certain general user data and information,
        including aggregate measures of the Service’s use and aggregate
        technical and other data about your use of the Service that does not
        personally identify you. For example, we may track the total number of
        visitors or users of the Service, the number of visitors to each page of
        our Site or App, the domain names of our visitors’ Internet service
        providers, and how end user customers use and interact with the Service.
      </p>
      <h4>Tracking Options and Do Not Track Disclosures</h4>
      <p>
        Our cookies and other tracking technologies help provide additional
        functionality to the Service and help us analyze Service usage more
        accurately. For instance, our Service may set a cookie on your browser
        that saves your login credentials. You can set your browser or operating
        system settings to limit certain tracking or to decline cookies, but by
        doing so, you may not be able to use certain Service features. Please
        refer to your web browser’s or operating system’s website or “Help”
        section for more information on how to delete and/or disable your
        browser or operating system from receiving cookies or controlling your
        tracking preferences.
      </p>
      <p>
        Our system may not respond to Do Not Track requests or headers from some
        or all browsers.
      </p>
      <h4>Combination of Information & Collected from Third-Party Services</h4>
      <p>
        We may combine the personal information we receive from or about you,
        including information you provide to us and information we automatically
        collect through the Service, as well as information across other
        computers or devices that you use, with information we collect or
        receive about you from other online and offline sources, or from other
        third party sources.
        <br />
        In addition, one of the special features of the Service is that it
        allows you to integrate various online third-party services, such as
        social media and social networking services (“Third-Party Services”),
        directly into your "Connect App" experience. To take advantage of this
        feature, we will ask you to provide us your username and password for
        the relevant Third-Party Services. By enabling such Third-Party
        Services, you are allowing us to pass your Third-Party Service log-in
        information to these Third-Party Services for this purpose. When you add
        a Third-Party Service account to the Service, we will collect your
        Third-Party Service login information and other relevant information
        necessary to enable the Service to access that Third-Party Service and
        your data contained within that Third-Party Service. However, please
        remember that the Third-Party Services may use, store and disclose your
        personal information differently, as described in their policies, and
        "Connect App" shall have no liability or responsibility for the privacy
        practices or other actions of any Third-Party Service.
      </p>
      <h4>Information Use</h4>
      <p>
        "Connect App" uses the personal information you provide in a manner that
        is consistent with this Privacy Policy. If you provide information for a
        certain reason, we may use that information in connection with the
        reason for which it was provided. For instance, if you contact us by
        email, we will use the information you provide to answer your question
        or resolve your problem. Also, if you provide information to obtain
        access to the "Connect App" Service, we will use your information to
        provide you with access to such services and to monitor your use of such
        services.
        <br />
        "Connect App" uses transactional data to facilitate your transactions
        with Rewards Providers. If you are a "Connect App" account holder,
        transactional data will be used by Rewards Providers to automatically
        award you rewards points and/or to provide you with other offers that
        may be of interest to you. We may also remove your personal information
        from transactional data and aggregate it with other anonymous data, pool
        and/or combine aggregated data with other information, and share
        aggregated data with Rewards Providers, affiliates, service providers,
        agents and business partners. "Connect App" may also disclose
        de-identified, aggregated data to describe our services to current and
        prospective business partners, and to other third parties for other
        lawful purposes.
        <br />
        "Connect App" and its subsidiaries and affiliates, if any (the “Related
        Companies”) may use data collected automatically through your use of the
        Service to analyze, improve, support and operate the Service, to help us
        improve the content and functionality of the Service, to better
        understand our users, for distribution in general benchmarking data and
        industry and/or other usage and demographic reports, and other business
        purposes.
        <br />
        "Connect App" may also use the personal information collected from and
        about you through the Service to improve the "Connect App" Service.
        "Connect App" and its Related Companies may use this information to
        contact you in the future to tell you about services we believe will be
        of interest to you. "Connect App" may also use your information in other
        ways, with your consent or as disclosed to you at the time of
        collection.
        <br />
        By voluntarily providing us with personal information or by utilizing
        the Service, you are consenting to our use of your personal information
        in accordance with this Privacy Policy. You acknowledge and agree that
        such personal information may be transferred from your current location
        to the offices and servers of "Connect App" and the authorized third
        parties referred to herein located in the United States.
        <br />
        By making a purchase at a Rewards Provider that uses "Connect App"
        Terminals, your transactional information will be used in accordance
        with this Privacy Policy.
        <br />
        Transactional data of "Connect App" account holders may be used to
        provide you with "Connect App" rewards points and other offers from our
        Rewards Providers.
        <br />
        Transactional data of non-account holders will be collected, processed,
        used, stored and shared to facilitate the transaction and to fulfil any
        other lawful purpose. The Service will automatically send you SMS
        messages with updates and other information.
      </p>

      <h4>Information Disclosure</h4>
      <p>
        "Connect App" is not in the business of selling your personal
        information We consider personal information to be a vital part of our
        relationship with you.
        <br />
        There are, however, certain circumstances in which we may share your
        information for a business or commercial purpose with certain service
        providers or partners, or where you may share your information with
        third parties, as set forth below:
      </p>
      <div style={{ paddingLeft: "10%" }}>
        <p>
          <b>1. Rewards Providers:</b>To the extent you have obtained or
          redeemed a reward offered by a Rewards Provider, added a Rewards
          Provider as a membership to your account, made a purchase at or
          through a Rewards Provider, or otherwise participated in any other
          activity sponsored or offered on behalf of the Rewards Provider, we
          may provide your personal and transactional information to such
          Rewards Providers (a) for the Rewards Provider to redeem and validate
          your reward, send you customized offers, troubleshoot redemption,
          combat fraud and otherwise communicate with you; (b) as part of legal
          proceedings affecting "Connect App" or the Rewards Provider; or (c) to
          send you marketing information or otherwise facilitate{" "}
        </p>
        <p>
          <b>2. Business Transfers: </b>As we develop our business, we might
          sell or buy businesses or assets. In the event of a corporate sale,
          merger, reorganization, dissolution or similar event, your information
          may be part of the transferred assets. We will use reasonable efforts
          to try to require that the transferee use personal information
          collected through the Service or through "Connect App" Terminals in a
          manner that is consistent with this Privacy Policy.
        </p>
        <p>
          <b>3. Related Companies: </b>We may also share your personal
          information with our Related Companies but will only do so in a manner
          that is consistent with this Privacy Policy.
        </p>
        <p>
          <b>4. Agents, Consultants and Service Providers: </b> "Connect App",
          like many businesses, sometimes hires agents or service providers to
          perform certain business-related functions. Examples of such functions
          include maintaining databases, sending emails and SMS messages,
          offering and developing certain features of the Service, and
          performing maintenance and security. When we employ an agent or
          service provider to perform a function of this nature, we only provide
          them with the information that they need to perform their specific
          function.
        </p>
        <p>
          <b>5. Your Public Profile:</b>If you make any information available
          through your public profile, it will be made available to members of
          the "Connect App" community. Please note that your first name and last
          initial and photo are, by default, available on your public profile,
          while your phone number, email, and birthday are kept nonpublic
          (consistent with this Privacy Policy). Please exercise caution when
          disclosing personal information in these public areas of the Service.
        </p>
        <p>
          <b>6. Legal Requirements: </b>"Connect App" may disclose your
          information if required to do so by law or in the good faith belief
          that such action is necessary to (i) comply with a legal obligation or
          assist government enforcement agencies, or governmental authorities
          (ii) protect and defend the rights or property of "Connect App",
          Rewards Providers, or our users, (iii) act in urgent circumstances to
          protect the personal safety of users of the Service or the public,
          (iv) protect against legal liability; (v) protect Rewards Providers
          from fraudulent, abusive, or unlawful uses; or (vi) protect the
          security or integrity of the Service.
        </p>
        <p>
          <b>7. Aggregate or Anonymous Non-Personal Information:</b>We may also
          share aggregate, anonymous, or de-identified non-personal information
          with third parties for their or our marketing or analytics uses, and
          as described in the “Information Use” section above.
        </p>
      </div>
      <p>
        The Service will automatically send you SMS messages with updates and
        other information.
      </p>
      <h4>Exclusions</h4>
      <p>
        This Privacy Policy does not apply to any information other than
        information collected through the Service and/or from "Connect App"
        Terminals at Rewards Providers. This Privacy Policy shall not apply to
        any unsolicited information you provide to "Connect App" through the
        Service or any other means. This includes, but is not limited to,
        information posted to any public areas of the Service, such as public
        forums, any ideas for new products or modifications to existing
        products, and other unsolicited submissions. All such unsolicited
        information shall be deemed non-confidential, and "Connect App" shall be
        free to reproduce, use, disclose, distribute and exploit it without
        limitation or attribution.
      </p>
      <div style={{ paddingLeft: "10%" }}>
        <p>
          <b>1. Children:</b>"Connect App" does not direct the Service to, nor
          does it not knowingly collect personal information from children under
          the age of 13. If we learn that a child under the age of 13 has
          provided personally identifiable information to "Connect App" through
          the Service, we will endeavor to delete that information from our
          databases.
        </p>
        <p>
          <b>2. Links to Other Websites:</b>This Privacy Policy applies only to
          the Service and to "Connect App" Terminals at Rewards Providers. The
          Service may contain links to other websites not operated or controlled
          by "Connect App" (the “Third-Party Sites”). The policies and
          procedures we describe here do not apply to the Third-Party Sites. The
          links do not imply that "Connect App" endorses or has reviewed the
          Third Party Sites. We suggest contacting those sites directly for
          information on their privacy policies.
        </p>
      </div>
      <h4>Security</h4>
      <p>
        "Connect App" takes reasonable steps to protect the personal information
        provided via the Service from loss, misuse, and unauthorized access,
        disclosure, alteration, or destruction. However, no Internet, email,
        mobile or other electronic transmission is ever fully secure or error
        free, so you should take special care in deciding what information you
        provide.
      </p>
      <h4>Changes To This Privacy Policy</h4>
      <p>
        The Service and our business may change from time to time. As a result,
        it may be necessary for "Connect App" to make changes to this Privacy
        Policy. "Connect App" reserves the right to update or modify this
        Privacy Policy at any time and from time to time without prior notice.
        If we change this Privacy Policy in a material way, we will provide
        appropriate notice to you. Please review this Privacy Policy
        periodically, and especially before you provide any information to us.
        This Privacy Policy was last updated on the date indicated above. Your
        continued use of the Service after any changes or revisions to this
        Privacy Policy shall indicate your agreement to the terms of such
        revised Privacy Policy.
      </p>
      <h4>Access To Information; Contacting Us</h4>
      <p>
        You may request, by contacting us as specified below, that we disclose,
        update, correct, or delete certain personal information that we have
        collected through the Service. We will take reasonable steps to honor
        your request in accordance with applicable laws. You may also update,
        correct, or delete certain information associated with your account, and
        you are responsible for maintaining the accuracy of this information. To
        make such a request, please send an email to support@eatoes.com.
        "Connect App" may take steps to verify your identity before responding
        to your request. Please also feel free to contact us if you have any
        questions about this Privacy Policy or the practices described therein.
        You may contact us by sending us a message at support@eatoes.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
