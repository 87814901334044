// @ts-nocheck
import * as React from "react";
import AddDetail from "./AddDetail";
import Accordion from "@mui/material/Accordion";
import TimeSlot from "./TimeSlot";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SelectTime from "./SelectTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import LiveButton from "./LiveButton";
import AddIcon from "@mui/icons-material/Add";
import { color } from "html2canvas/dist/types/css/types/color";
import Header1 from "../Components/Header1";

function AccordionUse() {
  const [addressOpen, setAddressOpen] = React.useState(false);
  const [locationOpen, setLocationOpen] = React.useState(false);
  const [paymentOpen, setPaymentOpen] = React.useState(false);
  const [workingOpen, setWorkingOpen] = React.useState(false);
  const [isSundayLive, setIsSundayLive] = React.useState(false);
  const [isMondayLive, setIsMondayLive] = React.useState(false);
  const [isTuesdayLive, setIsTuesdayLive] = React.useState(false);
  const [isWednesdayLive, setIsWednesdayLive] = React.useState(false);
  const [isThursdayLive, setIsThursdayLive] = React.useState(false);
  const [isFridayLive, setIsFridayLive] = React.useState(false);
  const [isSaturdayLive, setIsSaturdayLive] = React.useState(false);
  return (
    <div></div>

    // <div>
    //   <Accordion
    //     expanded={addressOpen}
    //     onChange={() => setAddressOpen(!addressOpen)}
    //   >
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel1bh-content"
    //       id="panel1bh-header"
    //     >

    //       I am not opened
    //       {addressOpen ? <div className="ms-auto">Hiii</div> :  <div className="ms-auto">Hello</div>}

    //     </AccordionSummary>
    //     <AccordionDetails>
    //       Openedddddddddddddddddd
    //     </AccordionDetails>
    //   </Accordion>
    // </div>
  );
}

export default AccordionUse;
