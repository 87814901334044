import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  searchRestaurant,
  searchRestaurantID,
} from "../../API/reservationAPIs";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const ListPageID = () => {
  const { id } = useParams();
  const [searchData, setSearchData] = useState([]);
  const [dropdownState, setDropDownState] = useState(false);

  useEffect(() => {
    const handleSearch = async () => {
      const searchData = await searchRestaurantID(id);
      console.log(searchData);
      setSearchData(searchData.data.result);
      setDropDownState(true);

      // navigate('/list')
    };
    handleSearch();
  }, [id]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/reservation/list/i/${id}`, "ListPageID Page");
  }, [id]);

  return (
    <div className="Listpage">
      <div className="searchBar-con">
        <input type="text" placeholder="Search for your restaurant" />
        <div className="searchbarIcon-con">
          <svg
            aria-hidden="true"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="lineFW" />
      <h1 className="text-start">Results Found</h1>

      <div className="restaurantSearchResultCon">
        <RestaurantSearchItem
          name={searchData.name}
          rating={searchData.rating}
          addres={searchData.formatted_address}
        />
      </div>
    </div>
  );
};

export default ListPageID;

function RestaurantSearchItem({ name, rating, addres }) {
  return (
    <div className="restaurantSearch-item">
      <div className="restaurantSearch-item-L">
        <div className="img" />
      </div>
      <div className="restaurantSearch-item-R">
        <div className="restaurantSearch-item-R-T">
          {" "}
          <h2> {name}</h2>
          <div className="sLine" />
          <div className="ratingCon">
            <h3>{rating}</h3>
            <div className="starCon">
              {" "}
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                  fill="#F7B808"
                />
              </svg>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                  fill="#F7B808"
                />
              </svg>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                  fill="#F7B808"
                />
              </svg>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                  fill="#F7B808"
                />
              </svg>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M16.8984 7.81775L12.9399 11.0833L14.1767 16.625L9.77344 13.4585L5.37019 16.625L6.60694 11.0833L2.64844 7.81775L7.39819 7.12475L9.77344 2.375L12.1487 7.12475L16.8984 7.81775Z"
                  fill="#DDDEE2"
                  fill-opacity="0.8"
                />
              </svg>
            </div>

            <h4>(33)</h4>
          </div>
          <h5>{addres}</h5>
        </div>
        <div className="restaurantSearch-item-R-B">
          <button type="button" className="custom-btn custom-btn-dark mt-3">
            Get started
          </button>
        </div>
      </div>
    </div>
  );
}
