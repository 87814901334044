import React, { useEffect, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { dinersData } from "../../devData/tableReserve";
import { useNavigate } from "react-router-dom";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
interface DinersProps {
  setNumberOfDiners: React.Dispatch<React.SetStateAction<number | null>>;
}

export const Diners = ({setNumberOfDiners}: DinersProps) => {
  const radios = dinersData;
  const [radioValue, setRadioValue] = useState(null);
  const navigate = useNavigate();

  const onChangeHandler = (e: any) => {
    setRadioValue(e.target.value);
    setNumberOfDiners(Number(e.target.value))
    navigate("select-date", {
      state: {
        selectedPeople: Number(e.target.value),
      },
    });
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`reserve/`, "Dinner Page");
  }, []);

  console.log("check partysize------------------", typeof dinersData[0].value);
  return (
    <div className="diner-select">
      <header className="select-headers">Number of diners?</header>

      <div className=" row row-cols-3 diner-row">
        {radios.map((radio, idx) => (
          <div className="col">
            <ToggleButton
              key={idx}
              id={`tabSelector-${idx}`}
              type="radio"
              variant="outline-primary"
              name="tabSelector"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={onChangeHandler}
              className="diner-radio"
            >
              {radio.name}
            </ToggleButton>
          </div>
        ))}
      </div>
    </div>
  );
};
