import axios from "axios";
import { BACKENDDATA } from "../utils/BackendLinks";

const refreshAPI = async () => {
  let data = {
    refreshToken: localStorage.getItem("refresh_token"),
  };
  let output;
  await axios
    .post(BACKENDDATA.baseUrl + "/api/v1/users/refresh-tokens", data)
    .then((res) => {
      output = res.data;
      localStorage.setItem("refresh_token", output.tokens.refresh.token);
      localStorage.setItem("access_token", output.tokens.access.token);

      window.location.reload();
    })
    .catch(function (error) {
      output = error;
    });
  return output;
};
const loginAPI = async (idToken, walletAddress) => {
  let data = {
    idToken: idToken,
    wallet_address: walletAddress,
  };
  let output;
  await axios
    .post(BACKENDDATA.baseUrl + "/api/v1/users/auth", data)
    .then((res) => {
      output = res.data;
    })
    .catch(function (error) {
      output = error;
    });
  return output;
};
const signUpAPI = async (usename, wallet_address) => {
  let data = {
    username: usename,
    wallet_address: wallet_address,
    role: "subscriber",
  };
  let output;
  await axios
    .post(BACKENDDATA.baseUrl + "/api/v1/users/auth/register", data)
    .then((res) => {
      output = res.data;
    })
    .catch(function (error) {
      output = error;
    });
  return output;
};

const Logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("loggedIn");
  localStorage.setItem("loggedOutCalled", true);
};

const searchRestaurant = async (restaurant) => {
  let config = {};
  let output;
  await axios
    .post(
      BACKENDDATA.baseUrl + `/v1/places/search?textQuery=${restaurant}`,
      config
    )
    .then((res) => {
      console.log("RES", res);
      output = res;
    })
    .catch(function (error) {
      output = error.response.data;
    });
  return output;
};

const searchRestaurantID = async (id) => {
  let config = {};
  let output;
  const data = {
    placeId: id,
  };
  await axios
    .post(BACKENDDATA.baseUrl + `/v1/places/detail`, data)
    .then((res) => {
      output = res;
    })
    .catch(function (error) {
      output = error.response.data;
    });
  return output;
};

export { loginAPI, Logout, signUpAPI, searchRestaurant, searchRestaurantID };
