// @ts-nocheck
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import "../../styleSheets/confirmation-dialog.css";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

export function Warning({ message, warningState, setWarningState }) {
  const handleClose = () => {
    setWarningState(false)
  };

  const handleCloseAndAddSubcat = () => {
    setWarningState(false)
    const handleOverlayDivEl = document.getElementById("handleOverlayDiv")
    handleOverlayDivEl.click()
  };

  return (
    <div>
      <Modal
        open={warningState}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="warning-modal p-4 rounded-3 d-flex flex-column align-items-center justify-content-center">
          <div>
            <PriorityHighRoundedIcon className="warning-icon rounded-5" />
            <p className="mt-2" style={{ fontSize: "17px" }}>
              {message}
            </p>
          </div>

          <div className="d-flex justify-content-between pt-2 pb-1">
            <button
              className="no-btn me-2 text-white"
              style={{ backgroundColor: "#e89f29" }}
              onClick={handleCloseAndAddSubcat}
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
