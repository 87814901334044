import React, { useState, useEffect } from "react";
import classes from "../styleSheets/fastSocialVerification.module.css";
import dgMenu from "../styleSheets/menuPlans.module.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backButton from "../assets/icons/svgCommonIcons/Group 875 (1).svg";
import { USER } from "../constants/localStorageKeys";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const MenuActivateScreen = () => {
  const navigate = useNavigate();
  const businessUserName = localStorage.getItem(USER.USERNAME);
  const handleCreateMenu = () => {
    localStorage.setItem("menu-back-route", "/QuickAccess");
    navigate(`/edit/menu/${businessUserName}`);
  };

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      `DigitalMenuActivation`,
      "DigitalMenuActivation Page",
    );
  }, []);
  return (
    <div className={dgMenu.activationContainer} style={{ height: "100vh" }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={2}
          onClick={() => {
            navigate("/QuickAccess");
          }}
        >
          <img className={classes.backButton} src={backButton} />
        </Grid>
        <Grid item xs={8} className={classes.headerText}>
          Confirmation
        </Grid>
      </Grid>

      <div className={dgMenu.activationSubContainer}>
        <div className={dgMenu.activationText}>
          Your digital menu has been activated. Now you can create menu.
        </div>
      </div>
      <div className={dgMenu.centerDiv}>
        <button className={classes.rechargeButton} onClick={handleCreateMenu}>
          CREATE MENU
        </button>
      </div>
    </div>
  );
};

export default MenuActivateScreen;
