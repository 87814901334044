//@ts-nocheck
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import placeholder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import { checkImageUrl } from "../../utils/checkImageUrl";

const ImageBox = ({ item }) => {
  return item?.cloudinary_url ? (
    <>
      {checkImageUrl(item?.cloudinary_url) == "" ? (
        <img
          src={item?.cloudinary_url}
          className={`rounded-3`}
          style={{
            width: "5.5rem",
            height: "6rem",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
          onClick={() => handleclick(item._id)}
        />
      ) : (
        <img
          src={`${imgBaseUrl}/${item?.cloudinary_url}`}
          className={`rounded-3`}
          style={{
            width: "5.5rem",
            height: "6rem",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
          onClick={() => handleclick(item._id)}
        />
      )}
    </>
  ) : (
    <img
      className={`rounded-3`}
      style={{
        width: "5.5rem",
        height: "6rem",
        boxShadow: "0px 4px 4px 0px #00000040",
      }}
      src={placeholder}
      onClick={() => handleclick(item._id)}
    />
  );
};
export default ImageBox;
