import styled from "styled-components";


export const GreenButton = styled.button`
    width: 128px;
    height:36px;
    padding:12px, 69px, 12px, 69px;
    margin-top: ${(props) => props.marginTop};
    background-color: ${(props) => props.backgroundColor};
    border-radius: 50px;
    gap:10px;
    font-size: 12px;
    color: #27343C;
    border-style: none;
    
`;

export const AddUserButton = styled.button`
    width: 114px !important;
    height:36px;
    padding:12px, 69px, 12px, 69px;
    margin-top: ${(props) => props.marginTop};
    background-color: ${(props) => props.backgroundColor};
    border-radius: 50px;
    gap:10px;
    color: #27343C;
    font-size: 12px;
    border-style: none;
    margin-right: 15px;
`;


