//@ts-nocheck
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Rename_model from './Rename_modal';

const ITEM_HEIGHT = 48;

export default function Menu_box({options,image,optionValue, mediaCategoryId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rename,setRename]=React.useState(false);
  const navigate=useNavigate()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseOption=(option)=>{

    setAnchorEl(null)
    if(option.optionType==="model"){
      setRename(true)
    }
    if(option.optionType==="navigate"){
      navigate({
        pathname: option.optionRoute,
        search: createSearchParams({id:option.optionParams}).toString(),
      })
    }
  }


  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
      >
       <img src={image} className='pt-1'/>
      </IconButton>
      <Menu
      
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className='ms-1'
      >
        {options.map((option) => (
          <MenuItem key={option.optionName} onClick={()=>handleCloseOption(option)} className='m-2'>
            {option.optionName}
          </MenuItem>
        ))}
      </Menu>
      {rename&&<Rename_model mediaCategoryId={mediaCategoryId} setRename={setRename} value={optionValue}/>}

    </div>
  );
}
