//@ts-nocheck
import React, { useState, useEffect } from "react";
import map_icon from "../assets/icons/Social_icons/map_icon.svg";
import mail_icon from "../assets/icons/Social_icons/round_mail_icon.svg";
import facebook_icon from "../assets/icons/Social_icons/facebook_icon.svg";
import instagram_icon from "../assets/icons/Social_icons/instagram_icon.svg";
import hollow_wp_icon from "../assets/icons/Social_icons/hollow_wp_icon.svg";
import contactphone_icon from "../assets/icons/Social_icons/contactphone_icon.svg";
import { Button } from "@mui/material";
import "../styleSheets/AddLinksPage.css";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { ContactPageLinks } from "./contact_page_links";
import { DefaultInput } from "./contact_page_inputs/default_input";
import { LabelledInput } from "./contact_page_inputs/labelled_input";
import { PhoneInput } from "./contact_page_inputs/phone_input";
import { ContactPageAddressTimings } from "./contact_page_address_timings";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { ContactWholeLive } from "./contact_whole_live";
import { emailValidator } from "../utils/regex_validators";
import { updateContactDetails } from "../API/fast_social_apis";
import { updateNestedRedux } from "../utils/updateNestedRedux";
import { setFastSocialData } from "../redux/actions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styleSheets/Arrow.css";
import { GreenButton } from "../Components/Button.style";
import "../styleSheets/AddLinksPage.css";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Socialcontactpage = () => {
  const dispatch = useDispatch();
  const api = useSelector((state) => state?.fastSocial?.apiData);
  const [allContactStates, setAllContactStates] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleOpenDialog = async () => {
    const dataReceived = await updateContactDetails(allContactStates, state);
    updateNestedRedux(
      api,
      "Contact Details",
      dataReceived.responseData,
      dispatch,
      setFastSocialData,
    );
    navigate(-1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();

  const regexValidator = (e, type) => {
    if (type == "email") emailValidator(e, setIsEmailValid);
  };

  const handleStates = (e, path, type, regex) => {
    setAllContactStates((prevState) => {
      const tempState = _.cloneDeep(prevState);
      if (type == "switch") _.set(tempState, path, !_.get(tempState, path));
      else if (type == "radio")
        _.set(tempState, path, JSON.parse(e.target.value));
      else _.set(tempState, path, e.target.value);
      return tempState;
    });
    if (regex) regexValidator(e, regex);
  };

  useEffect(() => {
    if (api["Contact Details"]) {
      const extractedContactDetails = _.cloneDeep(api["Contact Details"]);

      const toDeleteFromState = ["profile", "profileId", "roles", "_id", "__v"];
      toDeleteFromState.forEach((key) => delete extractedContactDetails?.[key]);

      setAllContactStates(extractedContactDetails);
    }
  }, [api]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      `/FastSocial/Contactsdetailspage`,
      "ContactDetail Page",
    );
  }, []);

  return (
    <div className="Socialformpage">
      <div className="mobile-pc-width">
        <div className="d-flex container m-auto mb-5 social-profile-header">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <KeyboardBackspaceIcon
              className="back-arrow-click ms-2"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="mx-auto">
              <h1 className="socio-prof-header me-5">
                {api?.["Basic Details"]?.account?.userName} | Contact Details
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="ps-4 pe-1">
        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.call.live"}
          switchName={"switch1"}
          contentPath={"links.call.title"}
          handleStates={handleStates}
          icon={contactphone_icon}
          placeholder={"Call Us"}
          sizeException={true}
          elementToRender={
            <PhoneInput
              wholeData={allContactStates}
              setWholeData={setAllContactStates}
              cCodePath={"links.call.cCode"}
              urlPath={"links.call.url"}
              handleStates={handleStates}
            />
          }
        />

        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.whatsapp.live"}
          contentPath={"links.whatsapp.title"}
          handleStates={handleStates}
          switchName={"switch2"}
          icon={hollow_wp_icon}
          placeholder={"Whatsapp"}
          elementToRender={
            <PhoneInput
              type={"whatsapp"}
              wholeData={allContactStates}
              setWholeData={setAllContactStates}
              cCodePath={"links.whatsapp.cCode"}
              urlPath={"links.whatsapp.url"}
              handleStates={handleStates}
            />
          }
        />

        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.map.live"}
          contentPath={"links.map.title"}
          handleStates={handleStates}
          switchName={"switch3"}
          icon={map_icon}
          placeholder={""}
          elementToRender={
            <DefaultInput
              wholeData={allContactStates}
              urlPath={"links.map.url"}
              handleStates={handleStates}
              type={"url"}
              placeholder={"url"}
            />
          }
        />

        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.instagram.live"}
          contentPath={"links.instagram.title"}
          handleStates={handleStates}
          switchName={"switch4"}
          icon={instagram_icon}
          placeholder={"Instagram"}
          elementToRender={
            <LabelledInput
              wholeData={allContactStates}
              urlPath={"links.instagram.url"}
              handleStates={handleStates}
              type={"instagram"}
            />
          }
        />

        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.facebook.live"}
          contentPath={"links.facebook.title"}
          handleStates={handleStates}
          switchName={"switch5"}
          icon={facebook_icon}
          placeholder={"Facebook"}
          elementToRender={
            <LabelledInput
              wholeData={allContactStates}
              urlPath={"links.facebook.url"}
              handleStates={handleStates}
              type={"facebook"}
            />
          }
        />

        <ContactPageLinks
          wholeData={allContactStates}
          switchPath={"links.email.live"}
          contentPath={"links.email.title"}
          handleStates={handleStates}
          switchName={"switch6"}
          icon={mail_icon}
          placeholder={""}
          elementToRender={
            <DefaultInput
              wholeData={allContactStates}
              urlPath={"links.email.url"}
              handleStates={handleStates}
              isEmailValid={isEmailValid}
              type={"email"}
              placeholder={"add email"}
            />
          }
        />

        <ContactPageAddressTimings
          wholeData={allContactStates}
          addressSwitchPath={"address.live"}
          timingsSwitchPath={"operationalHours.live"}
          handleStates={handleStates}
        />

        <div className="d-flex justify-content-center py-5">
          <GreenButton
            backgroundColor="#7ECAB0"
            varient="contained"
            onClick={handleOpenDialog}
          >
            Save
          </GreenButton>
        </div>
      </div>
    </div>
  );
};

export default Socialcontactpage;
