import React from 'react';

const ActionButtons: React.FC = () => {
  return (
    <div style={{ 
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      backgroundColor: 'white',
      padding: '15px',
      marginTop: '120px',
      borderBottom: '1px solid #828282',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{
        display: 'inline-flex',
        gap: '15px',
        
      }}>
        {['Menu', 'Reserve Table', 'Pay Bill Now', 'WhatsApp Business API'].map((text, index) => (
          <button key={index} style={{
            backgroundColor: 'white',
            border: '1px solid #7ECAB0',
            borderRadius: '20px',
            padding: '10px 16px',
            color: '#7ECAB0',
            fontSize: '12px',
            fontWeight: 'bold',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
          }}>
            {text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ActionButtons;