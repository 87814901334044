// @ts-nocheck

import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import veg from "../../RestaurantMenu/Images/veg.png";
import nonveg from "../../RestaurantMenu/Images/nonVeg.png";
import classes from "../../styleSheets/FoodMenu.module.css";
import { IconsArr, VarietyIcons } from "./Item-details-extract";
import TruncateMarkup from "react-truncate-markup";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";

// const varietyArr = [
//   { name: 'Pizza', price: '12' },
//   { name: 'Burger', price: '123' }

// ]
// sample data for test

export const ItemOpen = ({ open, setOpen, itemData, itemid }) => {
  const [isTruncate, setIsTruncate] = React.useState(false);
  const [clickedExpand, setClickedExpand] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsTruncate(false);
    setClickedExpand(false);
  };

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // padding:'1em',
    // height: { xs: 180, sm: 200 },
    width: { xs: 250, sm: 300 },
    // overflow: 'scroll',
    bgcolor: "background.paper",
    outline: "none",
    borderRadius: "10px",
    boxShadow: 24,
    p: 0,
    zIndex: 100,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            {itemData?.map((item) => {
              if (item?._id == itemid) {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className=""
                      style={{ justifyContent: "center", textAlign: "center" }}
                    >
                      {item.image ? (
                        <>
                          {checkImageUrl(item?.image) == "" ? (
                            <img
                              src={item.image}
                              className={"img-fluid px-2 py-2 rounded-4"}
                              style={{
                                marginTop: "2px",
                                marginBottom: "-10px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${imgBaseUrl}/${item?.image}`}
                              className={"img-fluid px-2 py-2 rounded-4"}
                              style={{
                                marginTop: "2px",
                                marginBottom: "-10px",
                              }}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div style={{ margin: "0 18px" }}>
                      <div>
                        <div className="mb-2 pt-2">
                          {/* {(item?.type == 'Veg') && <img src={veg} className={classes.foodIcon1} />}
                            {((item?.type == 'Non-Veg') || (item?.type == 'Contains Egg')) && <img src={nonveg} className={classes.foodIcon1} />} */}
                          <IconsArr item={item} />
                        </div>
                        <div className="d-flex mb-3">
                          <div
                            className={`item_basename_modal me-auto`}
                            style={{ overflowWrap: "anywhere" }}
                          >
                            {item?.itemName}
                          </div>
                          <div className={`item_price_modal ms-2 text-nowrap`}>
                            {item?.price ? `₹ ${item.price}` : null}
                          </div>
                        </div>

                        <div className="">
                          {item?.varietyArr?.map((variety) => {
                            return (
                              <div
                                className="d-flex  justify-content-center align-items-center my-2"
                                style={{}}
                              >
                                <span
                                  className={`item_varietyname_modal  me-2`}
                                  style={{ overflowWrap: "anywhere" }}
                                >
                                  {variety?.name}
                                </span>
                                <VarietyIcons variety={variety} />
                                <span
                                  className={`item_price_modal ms-auto text-nowrap`}
                                >
                                  ₹ {variety?.price}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {item?.description && (
                          <>
                            <div className={`item_desc_title_modal my-2`}>
                              Description
                            </div>
                            {clickedExpand ? (
                              <div className={"item_desc_modal mb-3"}>
                                {item?.description}
                              </div>
                            ) : (
                              <TruncateMarkup
                                lines={4}
                                onTruncate={(wasTrucated) =>
                                  setIsTruncate(wasTrucated)
                                }
                              >
                                <div
                                  className={
                                    "item_desc_modal hide_scrollbar mb-3"
                                  }
                                >
                                  {item?.description}
                                </div>
                              </TruncateMarkup>
                            )}
                          </>
                        )}
                        <img
                          onClick={() => {
                            setClickedExpand(true);
                            setIsTruncate(false);
                          }}
                          src="/assets/down-arrow.svg"
                          style={{ width: "1rem" }}
                          className={`mx-auto user-select-none ${
                            !isTruncate && "d-none"
                          }`}
                        />
                      </div>
                    </div>

                    <hr className="mb-0" />
                    <div
                      onClick={handleClose}
                      className="my-auto py-2 py-sm-3"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="item_close_modal my-auto">CLOSE</p>
                    </div>
                  </div>
                );
              }
            })}
          </Box>
        </>
      </Modal>
    </>
  );
};
