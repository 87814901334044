//@ts-nocheck
import ThemeRadioGroup from "../utils/ThemeRadioGroup";
import { Overlay_Animation } from "../utils/Overlay-animation";
import { useState } from "react";

const ChangeTheme = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDiv, setShowDiv] = useState(false);

  const handleOverlayShow = () => setShowOverlay(true);

  const handleRemoveOverlay = (event) => {
    if (
      event.target.className.includes(`change_menu_theme_div`) ||
      event.target.className.includes("inner-div")
    ) {
      setShowOverlay(false);
      setShowDiv(false);
    }
  };

  return (
    <div>
      <div onClick={handleOverlayShow}>Change Menu Theme</div>
      <Overlay_Animation
        className="change_menu_theme_div"
        showOverlay={showOverlay}
        showDiv={showDiv}
        setShowDiv={setShowDiv}
        handleRemoveOverlay={handleRemoveOverlay}
        componentToRender={
          <ThemeRadioGroup
            setShowOverlay={setShowOverlay}
            setShowDiv={setShowDiv}
          />
        }
      />
    </div>
  );
};

export default ChangeTheme;
