import React from 'react';

const SearchIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9284 17.0396L20.4016 20.3996M19.2816 11.4396C19.2816 15.7695 15.7715 19.2796 11.4416 19.2796C7.11165 19.2796 3.60156 15.7695 3.60156 11.4396C3.60156 7.1097 7.11165 3.59961 11.4416 3.59961C15.7715 3.59961 19.2816 7.1097 19.2816 11.4396Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
);

const ArrowIcon: React.FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 12.6L17.1 8L18.5 9.4L12.5 15.4L6.5 9.4L7.9 8L12.5 12.6Z" fill="white"/>
  </svg>
);

const HamburgerIcon: React.FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.125 18V16H21.875V18H3.125ZM3.125 13V11H21.875V13H3.125ZM3.125 8V6H21.875V8H3.125Z" fill="white"/>
  </svg>
);

interface HeaderProps {
  onHamburgerClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onHamburgerClick }) => {
  return (
    <div style={{
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '60px',
      color: 'white', 
      padding: '10px 15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      <SearchIcon />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>ironhill.india</span>
        <ArrowIcon />
      </div>
      <div onClick={onHamburgerClick} style={{ cursor: 'pointer' }}>
        <HamburgerIcon />
      </div>
    </div>
  );
};

export default Header;