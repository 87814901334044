// @ts-nocheck

import { useState } from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import "../styleSheets/event_cropper.css"

function EventImageCropperSelect({setAspectRatio}) {
  const radios = [
    { name: '9:16', value: 9/16 },
    {name: "16:9", value: 16/9},
    { name: '1:1', value: 1/1 },
  ];
  const [radioValue, setRadioValue] = useState(radios[0].value);


  return (
    <>
      <ButtonGroup className='event-cropper-select me-auto mt-auto' style={{height: "min-content"}}>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant='outline-primary'
            name="radio"
            value={radio.value}
            checked={radioValue == radio.value}
            onChange={(e) => {setRadioValue(e.target.value); setAspectRatio(e.target.value)}}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}

export default EventImageCropperSelect;