import React, { useState, useRef, useEffect } from "react";
import QRUserOptionsMenu from "./QRUserOptionsMenu";
import styles from "./QRUserItem.module.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import dummyIcon from "../assets/images/logo.png";

const QRUserItem = ({
  user,
  deleteQRUser,
  toggleUserStatus,
  UpdateUserData,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const optionsMenuRef = useRef(null);

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.qrUserItem}`}
    >
      <div className="d-flex align-items-center">
        <img
          src={dummyIcon}
          className={`rounded-circle ${styles.profileImg}`}
          alt="profile"
        />
        <div className={styles.divider}></div>
        <div className={styles.containers}>
          <span className="ml-3">{user.name}</span>
          <span className={styles.profileUserName}>{user.code}</span>
        </div>
      </div>
      <div className={`dropdown ${styles.options}`} ref={optionsMenuRef}>
        <button
          className="btn btn-link"
          type="button"
          onClick={handleOptionsClick}
        >
          <BsThreeDotsVertical />
        </button>
        {showOptions && (
          <QRUserOptionsMenu
            userCode={user.code}
            deleteQRUser={deleteQRUser}
            active={user.active}
            toggleUserStatus={toggleUserStatus}
            UpdateUserData={UpdateUserData}
          />
        )}
      </div>
    </div>
  );
};

export default QRUserItem;
