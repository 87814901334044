// @ts-nocheck
import React from "react";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
export const checkImageUrl = (url) => {
  let baseUrl: string;
  if (url?.includes("https://res.cloudinary.com")) {
    baseUrl = "";
  } else {
    baseUrl = imgBaseUrl;
  }
  return baseUrl;
};
