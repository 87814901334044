//@ts-nocheck
import React, { useState, useEffect } from "react";
import "../styleSheets/adminprofile.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { createSearchParams, useNavigate } from "react-router-dom";
import backarrow from "../assets/icons/Social_icons/backArrow.svg";
import person from "../assets/icons/Social_icons/new_user_icon.svg";
import solidarrow from "../assets/icons/Social_icons/solidArrow.svg";
import cameraimg from "../assets/icons/Social_icons/cameraimg.svg";
import { MENU_API, baseURL } from "../constants/Api";
import { getbusinessProfiles } from "../API/authAPIs";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { checkImageUrl } from "../utils/checkImageUrl";
import { getUserProfile } from "../API/authMenuAPIs";
import { useDispatch } from "react-redux";
import { setFastSocialData } from "../redux/actions";
import CircularUnderLoad from "../Components/CircularLoader";
import loaderStyler from "../styleSheets/loader.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../styleSheets/Arrow.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Menu } from "@mui/material";
import Share from "../Components/Share";
import { web_url } from "../constants/web_url";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import TopHeader from "../utils/TopHeader";

export default function AllProfile() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [activeProfile, setActiveProfile] = useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const fastEnvData = import.meta.env.VITE_FAST_SOCIAL;
  const handelFastsocialFlow = (item, type) => {
    if (type === "user") {
      navigate({
        pathname: "/FastSocial",
        search: createSearchParams({
          profileIdData: item.uuid,
          type: type,
          name: item.userName,
        }).toString(),
      });
    }
    if (fastEnvData !== "false" && type === "business") {
      dispatch(setFastSocialData({}));
      navigate({
        pathname: "/FastSocial",
        search: createSearchParams({
          profileIdData: item.profileId,
          type: type,
          name: item.userName,
        }).toString(),
      });
    }
  };
  const navigate = useNavigate();
  const [loader, setLoader] = useState();
  const [profile, setProfile] = useState([]);
  const [admin, setAdmin] = useState();
  const [id, setId] = useState("1");
  const [selectedProfile, setSelectedProfile] = useState(
    localStorage.getItem("businessId"),
  );
  const [businessName, setBusinessName] = useState(
    localStorage.getItem("businessName"),
  );
  const [profileType, setProfileType] = useState(
    localStorage.getItem("profileType"),
  );
  const [businessProfileName, setBusinessProfileName] = useState(
    localStorage.getItem("businessName"),
  );
  const [businessUserName, setBusinessUserName] = useState(
    localStorage.getItem("adminUserName"),
  );
  const callApis = async () => {
    setLoader(true);
    const data = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
    );
    const profData = await getUserProfile(
      `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
      navigate,
    );
    setAdmin(profData);
    setProfile(data);

    setLoader(false);
    console.log("businessProfiles", data);
    console.log("adminprofile", profData);
  };
  useEffect(() => {
    callApis();
  }, []);

  console.log("active profileeeeeeeeeeeeee", activeProfile);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/allprofiles`, "AllProfile Page");
  }, []);

  return (
    <div className="Socialformpage d-flex justify-content-center pb-10">
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          className="p-3 d-flex flex-column gap-4 bg-white"
          style={{ fontSize: "14px", lineHeight: "19px", minWidth: "150px" }}
        >
          <div
            onClick={() =>
              navigate(`/CreateBusiness?id=${activeProfile?.profileId}`)
            }
          >
            Edit Profile
          </div>
          <div
            onClick={() =>
              navigate(
                `/FastSocial/socialprofilepage?accountId=${activeProfile?.profileId}`,
              )
            }
          >
            Edit Social Profile
          </div>
          <div
            onClick={() =>
              navigate(`/BusinessAccess?id=${activeProfile?.profileId}`)
            }
          >
            Check Access
          </div>
          <div
            onClick={() =>
              navigate(
                `/FastSocial?profileIdData=${activeProfile?.profileId}&type=business&name=${activeProfile?.userName}`,
              )
            }
          >
            All Services
          </div>

          <div>
            <Share
              type="whole-menu"
              url={`${web_url}/${activeProfile?.userName}`}
            />
          </div>
        </div>
      </Menu>
      {loader ? (
        <div className={loaderStyler.userContainer}>
          <CircularUnderLoad />
        </div>
      ) : (
        <div className="mobile-pc-width">
          <div
            style={{
              background: "#27343C",
              padding: "10px 0",
              position: "relative",
            }}
          >
            {/* <div className="d-flex align-items-center">
              <div className="cursor-pointer ms-2" onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon className="back-arrow-click" />
              </div>
              <div>
                <h1
                  className="text-white fw-normal"
                  style={{
                    position: "absolute",
                    fontSize: "16px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  All Social Profilessazdgfvdsefgbdrf
                </h1>
              </div>
            </div> */}
            <TopHeader name="All Social Profile" />
          </div>

          <div className="mt-4 px-4">
            {profile?.map((data, index) => (
              <div
                key={index}
                className="admin-boxs mt-3 cursor-pointer bg-white rounded-3"
                onClick={() => handelFastsocialFlow(data, "business")}
              >
                <div className="d-flex justify-content-between align-items-center mx-2">
                  {data?.image ? (
                    checkImageUrl(data?.image) == "" ? (
                      <div className="admin_image-div">
                        <div className="admin_image">
                          <img
                            src={`${data?.image}`}
                            alt=""
                            className="w-100 admin_image"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="admin_image-div">
                        <div className="admin_image">
                          <img
                            src={`${imgBaseUrl}/${data?.image}`}
                            alt=""
                            className="w-100 admin_image"
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <AccountCircleIcon sx={{ fontSize: "35px" }} />
                  )}
                  <div
                    className=" ms-4 me-4"
                    style={{
                      width: "5px",
                      height: "35px",
                      borderRight: "2px solid #D1D1D1",
                    }}
                  ></div>
                  <div className="headings ">{data?.name}</div>
                </div>

                <MoreVertIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                    setActiveProfile(data);
                  }}
                />
              </div>
            ))}
          </div>

          <div
            className="admin_button_class mx-auto d-flex justify-content-center align-items-center text-white cursor-pointer mb-5 mt-4 shadow-sm"
            onClick={() => navigate("/CreateBusiness")}
          >
            Create new Social Profile
          </div>
        </div>
      )}
    </div>
  );
}
