import React from "react";
import { RestaurantDetails } from "./restaurantDetails";
import { BookingDetails } from "./bookingDetails";

export const BookingCard = ({bookingData}: {bookingData: object}) => {
  return(
    <div className="bookings-wrapper mb-4">

      <RestaurantDetails bookingData={bookingData} />

      <BookingDetails bookingData={bookingData}/>
      
    </div>
  )
}