// @ts-nocheck
import { baseURL, MENU_API } from "../constants/Api";
import { USER } from "../constants/localStorageKeys";
import { sessionExpireLogic } from "../utils/functionUtils";

export const getbusinessProfiles = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (response.status == 401) {
      sessionExpireLogic();
      // navigate("/Login");
    }

    const data = res?.body;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};
export const getAllCategories = async (url, navigate) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (response.status == 401) {
      sessionExpireLogic();
      navigate("/Login");
    }
    console.log(response.status, "status");
    const data = res?.body;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};

export const getUserProfile = async (url, navigate) => {
  const token = localStorage.getItem("accessToken");
  /*   const localProfileData = localStorage.getItem("userProfile");

  if (localProfileData) {
    console.log("Using cached profile data");
    return JSON.parse(localProfileData);
  } */

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    console.log("Fetched profile data from API", res);

    if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    }

    const data = res?.body;

    if (data) {
      localStorage.setItem("userProfile", JSON.stringify(data));
    }

    return data;
  } catch (err) {
    console.log("menuApi Error", err);
    return null;
  }
};

export const updateCategory = async (
  _id: any,
  category: String,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    _id: _id,
    category: category,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.EDIT_CATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const changePictureApi = async (type, image, _id, navigate) => {
  const userId = localStorage.getItem(USER.businessId);
  // ------------------ only for testing purpose
  // const userId = '64875dfd748792d14d4f4b86'
  // ----------------------------------------
  const body = JSON.stringify({
    type: type,
    image: image,
    _id: _id,
    userId: userId,
  });

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.CHANGE_PICTURE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const changeVideoApi = async (type, video, _id, navigate) => {
  const userId = localStorage.getItem(USER.businessId);
  // ------------------ only for testing purpose
  // const userId = '64875dfd748792d14d4f4b86'
  // ----------------------------------------
  const body = JSON.stringify({
    type: type,
    video: video,
    _id: _id,
    userId: userId,
  });

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${baseURL}${MENU_API.CHANGE_VIDEO}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);

    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const updateSubCategory = async (
  _id: any,
  subCategory: String,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    _id: _id,
    subCategory: subCategory,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.EDIT_SUBCATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const updateItems = async (
  _id: String,
  itemName: String,
  price: String,
  type: String,
  description: String,
  varietyArr: Array,
  chefR: Boolean,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    _id: _id,
    itemName: itemName,
    type: type,
    price: price,
    description: description,
    varietyArr: varietyArr,
    chefRecommend: chefR,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.EDIT_ITEMS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("order api response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const addCategory = async (
  category: String,
  image: String,
  navigate: any,
  id: any,
) => {
  var userId;
  if (id) {
    userId = id;
  } else {
    userId = localStorage.getItem(USER.businessId);
  }

  const body = JSON.stringify({
    category: category,
    image: image,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_CATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("category response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
export const addSubCategory = async (
  category: String,
  image: String,
  id: String,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    subCategory: category,
    image: image,
    id: id,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_SUBCATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("category response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const addItem = async (
  objId: String,
  ItemName: String,
  itemPrice: String,
  selectedValue: String,
  objDescription: String,
  varietyArr: Array,
  chefR: Boolean,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    id: objId,
    name: ItemName,
    // image:image,
    type: selectedValue,
    description: objDescription,
    price: itemPrice,
    userId: userId,
    varietyArr: varietyArr,
    chefRecommend: chefR,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_ITEM}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      console.log("category response data", data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const addBusinessName = async (name: String, navigate: any) => {
  const body = JSON.stringify({
    name: name,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_BUSINESS_NAME}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const addHomePageImages = async (
  image: String,
  navigate: any,
  insertIndex: number,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    image: image,
    userId: userId,
    insertIndex,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_HOMEPAGEIMAGES}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else if (response.status == 401) {
      sessionExpireLogic();
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
export const addIcon = async (image: String, navigate: any) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    icon: image,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_ICON}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else if (response.status == 401) {
      sessionExpireLogic();
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const deleteMenu = async (id: String, type: String, navigate: any) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    _id: id,
    type: type,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.DELETE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else if (response.status == 401) {
      sessionExpireLogic();
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
export const addPublishStatus = async (
  id: String,
  type: String,
  publish: Number,
  navigate: any,
) => {
  const userId = localStorage.getItem(USER.businessId);
  const body = JSON.stringify({
    _id: id,
    type: type,
    publish: publish,
    userId: userId,
  });
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `${baseURL}${MENU_API.UPDATE_PUBLISH_STATUS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      },
    );
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else if (response.status == 401) {
      sessionExpireLogic();
      alert("Session expired");
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const add_Excel = async (formData) => {
  console.log(formData, "formm");
  const userId = localStorage.getItem(USER.businessId);

  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${baseURL}${MENU_API.ADD_EXCEL}`, {
      method: "POST",
      headers: {
        // 'content-type': excelData.type,
        // 'content-length': `${excelData.size}`,
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    console.log("*********", response);
    if (response && response.status == 200) {
      // console.log('category response data',data);
      return data;
    } else {
      alert("Oops !! Something went wrong .");
      console.error("excel api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
