import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";


type componentPropsTypes = {
  thumbnail: string;
  actualSrc: string;
  imgStyleObj?: object;
  imgClassName?: string;
};
export function SingleImgLightbox({
  thumbnail,
  actualSrc,
  imgStyleObj = {},
  imgClassName,
}: componentPropsTypes) {
  const [open, setOpen] = useState(false);
  const handleOpenLightbox = () => {
    setOpen(true);
  };
  return (
    <>
      <img
        className={`object-fit-cover cursor-pointer ${imgClassName}`}
        onClick={handleOpenLightbox}
        src={thumbnail}
        style={imgStyleObj}
      />

      <Lightbox
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
        open={open}
        render={{
          buttonNext: () => null,
          buttonPrev: () => null,
          slide: () => null,
        }}
        plugins={[Zoom, Download]}
        zoom={{
          maxZoomPixelRatio: 2,
        }}
        carousel={{
          finite: true,
        }}
        close={() => setOpen(false)}
        slides={[
          {
            src: actualSrc,
            downloadFilename: "image-eatoes"
          },
        ]}
      />
    </>
  );
}
