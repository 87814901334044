import React from "react";
import AddIcon from "../../assets/icons/svgCommonIcons/addIconWhite.svg"
import { useNavigate } from "react-router-dom";

export const AddSlide = ({...props}) => {
  const {accountId} = props
  const navigate = useNavigate()

  return(
    <div className="menu-event-outer-div menu-event-add-slide"
    onClick={() => navigate(`/FastSocial/editeventform?accountId=${accountId}`)}
    >
      <img src={AddIcon} />
    </div>
  )
}