// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import "swiper/css/navigation";
import classes from "../../styleSheets/FoodMenu.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import SwiperCore, { Navigation } from "swiper";
import { carouselImage } from "../../API/MenuApi";
import utilityFunctions from "../../utils/UtilityFunction";
import "swiper/css";
import revoltHomePage1 from "./Images/revoltHomePage1.jpg";
import { MENU_API, baseURL } from "../../constants/Api";
import placeholder from "../../RestaurantMenu/Images/placeholders/carouselPlaceholder.jpeg";
import { USER } from "../../constants/localStorageKeys";
import ImageEdit from "../Modals Layout/ImageEdit";
// import Loader from '../../assets/Images/images/Loader.jpeg'
import Loader from "../../assets/images/images/Loader.jpeg";
import { AddCarouselImage } from "./AddCarouselImage";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteCarousel from "./DeleteCarousel";
import { TransformedImage } from "../../utils/TransformedImages";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { checkImageUrl } from "../../utils/checkImageUrl";
import ThreeLinesMenuIcon from "../../assets/icons/svgCommonIcons/three_lines_menu_icon.svg";
import { useSelector } from "react-redux";

const Carousel = ({
  name,
  selectedProfile,
  data,
  setData,
  loadingImage,
  setCoverDragState,
  carouselData,
  setCarouselData,
}: {
  name?: string;
  selectedProfile: React.Dispatch<React.SetStateAction<string>>;
  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  loadingImage?: boolean;
  setCoverDragState: React.Dispatch<React.SetStateAction<boolean>>;
  carouselData: T;
  setCarouselData: React.Dispatch<React.SetStateAction<T>>;
}) => {
  console.log("data object----->", data);
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const savedRestrauntName = localStorage.getItem("name");
  const resName = localStorage.getItem(USER.USERNAME);
  const [imgEdit, setImgEdit] = useState(false);
  const [images, setImages] = React.useState();
  console.log(name, "carousel");
  const { getImages } = utilityFunctions();
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  // const [loadingImage, setLoadingImage] = useState(false);
  const [delImage, setDelImage] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  console.log(activeSlideIndex, "sloides");
  // const activeIndex=swiper.activeIndex;
  const callApis = async () => {
    // setLoadingImage(true)
    // const data = await carouselImage(`${baseURL}${MENU_API.MENU}${name}`);
    setCarouselData(data?.HomePageImagesurl);
    // setLoadingImage(false)
  };
  useEffect(() => {
    console.log("courosel edit");

    callApis();
  }, [data]);
  const handleAddImage = () => {
    setImgEdit(true);
  };
  const handleDeleteImage = () => {
    console.log("yes");

    setDelImage(true);
    console.log(delImage);
  };
  function handleSlideChange(swiper) {
    setActiveSlideIndex(swiper.activeIndex);
  }

  const swiperArr = [Pagination];
  if (!menuAccessState) swiperArr.push(Autoplay);

  return (
    <div>
      <Swiper
        onSlideChange={handleSlideChange}
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={swiperArr}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {loadingImage ? (
          <SwiperSlide className={classes.carouselPagination}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={classes.img}>
                <img className={classes.carouselImage} src={Loader} alt="loader"/>
              </div>
            </div>
          </SwiperSlide>
        ) : (
          <>
            {carouselData === undefined ||
            carouselData == null ||
            carouselData.length === 0 ||
            !carouselData ? (
              <SwiperSlide className={classes.carouselPagination}>
                {menuAccessState ? (
                  <div className={classes.layer_container}>
                    <div className={classes.myLayer} onClick={handleAddImage}>
                      <img src="/assets/add-carousel.svg" />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "black",
                  }}
                >
                  {/* <AddCarouselImage
                    imgEdit={imgEdit}
                    setImgEdit={setImgEdit}
                    loading={loading}
                    setLoading={setLoading}
                    images={images}
                    setImages={setImages}
                    carouselData={carouselData}
                    setCarouselData={setCarouselData}
                    setWholeData={setData}
                  /> */}
                  {/* {imgEdit ? <AddCarouselImage imgEdit={imgEdit} setImgEdit={setImgEdit} loading={loading} setLoading={setLoading} images={images} setImages={setImages} carouselData={carouselData} setCarouselData={setCarouselData} /> : ""} */}
                  <div className={classes.img}>
                    <img
                      className={classes.carouselImage}
                      src={placeholder}
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
            ) : (
              carouselData?.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className={classes.carouselPagination}
                  >
                    {menuAccessState ? (
                      <div className={classes.layer_container}>
                        <div
                          className={classes.myLayer}
                          onClick={handleAddImage}
                        >
                          <img src="/assets/add-carousel.svg" />
                        </div>
                        <div
                          className={classes.myLayer2}
                          onClick={handleDeleteImage}
                        >
                          <img src="/assets/delete-carousel.svg" />
                        </div>

                        <div
                          className={`${classes.myLayer2}`}
                          style={{ marginBottom: "35px" }}
                          onClick={() => setCoverDragState(true)}
                        >
                          <img
                            style={{ height: "12px", marginTop: "0.7px" }}
                            src={ThreeLinesMenuIcon}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "black",
                      }}
                    >
                      {/* {
                        <AddCarouselImage
                          imgEdit={imgEdit}
                          setImgEdit={setImgEdit}
                          loading={loading}
                          setLoading={setLoading}
                          images={images}
                          setImages={setImages}
                          carouselData={carouselData}
                          setCarouselData={setCarouselData}
                          setWholeData={setData}
                        />
                      } */}

                      <div className={classes.img}>
                        {item.image ? (
                          <>
                            {checkImageUrl(item?.image) == "" ? (
                              <img
                                src={item?.image}
                                alt="Cover Image"
                                style={{ width: "100%" }}
                                className={classes.carouselImage}
                              />
                            ) : (
                              <img
                                src={`${imgBaseUrl}/${item?.image}`}
                                alt="Cover Image"
                                style={{ width: "100%" }}
                                className={classes.carouselImage}
                              />
                            )}
                          </>
                        ) : (
                          <img
                            className={classes.carouselImage}
                            src={placeholder}
                          ></img>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            )}
          </>
        )}
      </Swiper>
      {delImage ? (
        <>
          <DeleteCarousel
            type="Caraousel"
            objId={carouselData[activeSlideIndex]._id}
            data={carouselData}
            setData={setCarouselData}
            delImage={delImage}
            setDelImage={setDelImage}
          />
        </>
      ) : (
        ""
      )}
      <AddCarouselImage
        imgEdit={imgEdit}
        setImgEdit={setImgEdit}
        loading={loading}
        setLoading={setLoading}
        images={images}
        setImages={setImages}
        carouselData={carouselData}
        setCarouselData={setCarouselData}
        setWholeData={setData}
      />
    </div>
  );
};

export default Carousel;
