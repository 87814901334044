import React from "react";
import Pop_up_2option from "../../utils/Pop_up_2option";
import { cancelBookingAPI } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";
import { BookingPopups } from "./popup";

export const RestaurantDetails = ({...props}) => {
  const {bookingData} = props
  return (
    <div>
      <div className=" d-flex align-items-center">
      <div className="restaurant-details-heading">{bookingData?.slot?.restaurant?.name}</div>
      {/* <div onClick={onClickCancelHandler} className="ms-auto text-danger cursor-pointer" style={{fontSize: "12px"}}>Cancel Dev</div> */}


      <div className="ms-auto">
      <BookingPopups bookingData={bookingData}/>
      </div>

      </div>

      <div className="restaurant-details-address">
        {bookingData?.slot?.restaurant?.formattedAddress}
      </div>

    </div>
  );
};
