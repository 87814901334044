import React, { useEffect, useRef, useState } from "react";
import QRUserItem from "./QRUserItem";
import styles from "./QRUserList.module.css";
import axios from "axios";
import SearchBar from "../Components/SearchBar";
import TopHeader from "../utils/TopHeader";
import { baseURL, QR_API } from "../constants/Api";
import AnalyticsUtil from "../utils/GoogleAnalytics";
import QRupdateUser from "./QRupdateUser";
import { useNavigate } from "react-router-dom";

const QRUserList = () => {
  const [qrUsers, setQRUsers] = useState([]);
  const [filteredQRUsers, setFilteredQRUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const optionsMenuRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQRUsers = async () => {
      try {
        const response = await axios.get(`${baseURL}${QR_API.FETCH}`);
        setQRUsers(response.data);
        setFilteredQRUsers(response.data);
      } catch (error) {
        console.error("Error fetching QR users:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchQRUsers();
  }, []);

  const deleteQRUser = async (code) => {
    setLoading(true);
    try {
      await axios.delete(`${baseURL}${QR_API.DELETE}/${code}`);
      setQRUsers((prevUsers) => prevUsers.filter((user) => user.code !== code));
      setFilteredQRUsers((prevUsers) =>
        prevUsers.filter((user) => user.code !== code),
      );
    } catch (error) {
      console.error("Error deleting QR user:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleUserStatus = async (code, active) => {
    try {
      console.log("Current Active state:", active);
      const response = await axios.patch(`${baseURL}${QR_API.STATUS}/${code}`, {
        active: !active,
      });
      console.log("Updated Active State:", response.data.active);
      setQRUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.code === code ? { ...user, active: response.data.active } : user,
        ),
      );
      setFilteredQRUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.code === code ? { ...user, active: response.data.active } : user,
        ),
      );
    } catch (error) {
      console.error("Error toggling user status:", error);
    }
  };

  const UpdateUserData = async (code) => {
    try {
      const response = await axios.get(`${baseURL}${QR_API.FETCH}/${code}`);

      setSelectedUser(response.data);
      navigate("/updateQRuser", { state: { userData: response.data } });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleAddNewQRuser = () => {
    navigate("/AddQRUser");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target)
      ) {
        document.querySelectorAll(".dropdown-menu.show").forEach((menu) => {
          menu.classList.remove("show");
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredQRUsers(
      qrUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.code.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
  }, [searchQuery, qrUsers]);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/QRUserProfile`, "QRUserProfile Page");
  }, []);
  return (
    <div className={styles.parentContainer}>
      <TopHeader name="All QR User" />
      <div className={styles.parentDiv}>
        <div className={`container ${styles.noPadding}`}>
          <SearchBar
            searchQuery={searchQuery}
            placeholder="Search QR User..."
            setSearchQuery={setSearchQuery}
          />
          {loading ? (
            <div className={styles.spinnerContainer}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className={styles.qrUserList} ref={optionsMenuRef}>
              {filteredQRUsers.map((user, index) => (
                <QRUserItem
                  key={user?.code || index}
                  user={user}
                  deleteQRUser={deleteQRUser}
                  toggleUserStatus={toggleUserStatus}
                  UpdateUserData={UpdateUserData}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <button
        type="button"
        className={`btn btn-primary ${styles.footerButton}`}
        onClick={handleAddNewQRuser}
      >
        ADD NEW QR USER
      </button>
    </div>
  );
};

export default QRUserList;
