// @ts-nocheck

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import classes from "../../styleSheets/FoodMenu.module.css";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
import { Price_Variety_Inputs } from "./Price-Variety-Inputs";
import { TextLimiter } from "../../utils/TextLimiter";

const maxLimit = 250;

export const Item_Layout = (props) => {
  const {
    dishName,
    setDishName,
    price,
    setPrice,
    dishType,
    setDishType,
    varietyInputsState,
    setVarietyInputsState,
  } = props;
  const { chefR, setChefR, addDesc, setAddDesc } = props;
  const { descInput, handleDescChange, onClickBtn } = props;
  return (
    <div
      className="inner-div"
      style={{ width: "100%", position: "fixed", bottom: "0" }}
    >
      <Box
        className={classes.edit_dish}
        sx={customizeMUIComponents.addItemModal}
      >
        <>
          <div
            className="d-flex justify-content-center align-items-center flex-column mx-auto"
            style={{ maxWidth: "300px" }}
          >
            <div className=" w-100">
              <input
                placeholder="Dish Name"
                className="mb-3 mt-3"
                value={dishName}
                onChange={(e) => setDishName(e.target.value)}
                type="text"
                style={customizeMUIComponents.inputBox}
              />
            </div>

            <Price_Variety_Inputs
              price={price}
              setPrice={setPrice}
              setDishType={setDishType}
              varietyInputsState={varietyInputsState}
              setVarietyInputsState={setVarietyInputsState}
              dishType={dishType}
            />

            <div className=" w-100 d-flex justify-content-between align-content-center mt-2">
              <div
                onClick={() => setChefR((prev) => !prev)}
                className=" d-flex align-content-center justify-content-center"
              >
                <div className="my-auto me-1">
                  {chefR ? (
                    <img src="/assets/chef-true.svg" />
                  ) : (
                    <img src="/assets/chef-false.svg" />
                  )}
                </div>
                <div className="my-auto">
                  <span style={customizeMUIComponents.ModalLabel}>
                    Chef's Recommended
                  </span>
                </div>
              </div>

              <div
                onClick={() => setAddDesc((prev) => !prev)}
                className=" d-flex align-content-center justify-content-center"
              >
                <div className="my-auto me-1">
                  {addDesc ? (
                    <img src="/assets/tick-icon.svg" />
                  ) : (
                    <img src="/assets/cross-icon.svg" />
                  )}
                </div>
                <div className="my-auto ">
                  <span style={customizeMUIComponents.ModalLabel}>
                    Add Description
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`mt-4 mb-4 w-100 ${
                addDesc ? "opacity-100" : "opacity-0"
              }`}
            >
              <textarea
                value={descInput}
                onChange={handleDescChange}
                style={customizeMUIComponents.descriptionBox}
                className="desc-textarea"
              />
              <TextLimiter value={descInput} maxLimit={maxLimit} />
            </div>

            <div style={customizeMUIComponents.row}>
              <Button
                variant="contained"
                sx={customizeMUIComponents.editButton2}
                onClick={onClickBtn}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      </Box>
    </div>
  );
};
