//@ts-nocheck
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { heading } from "../Social_components/MockData";
import { createSearchParams } from "react-router-dom";

const FollowButton = ({ isOwnProfile, profileId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Id = profileId;
  return (
    /* 

      //Uncomment After the Follow Logic is Done.

        <div className="d-flex justify-content-center mt-4 mb-4">
          <button
            className="btn btn-primary px-5 d-flex justify-content-center align-items-center"
            style={{
              width: "315px", // Set button width to 80%
              height: "28px",
              borderRadius: "25px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
              border: "2px solid #fff", // Border color changed to white
              backgroundColor: "#007bff",
            }}
            onClick={() =>
              navigation({
                pathname: `/FastSocial/socialprofilepage`,
                search: createSearchParams({ accountId: state }).toString(),
              })
            }
          >
            {isOwnProfile ? "Edit Profile" : "Follow"}
          </button>
        </div>
      */
    <>
      {isOwnProfile ? (
        <div className="d-flex justify-content-center mt-4 mb-4">
          <button
            className="btn btn-primary px-5 d-flex justify-content-center align-items-center"
            style={{
              width: "90%", // Set button width to 80%
              height: "30px",
              borderRadius: "20px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
              border: "2px solid #fff", // Border color changed to white
              backgroundColor: "#007bff",
            }}
            onClick={() => {
              navigate({
                pathname: "/CreateBusiness",
                search: createSearchParams({ id: Id }).toString(),
              });
            }}
          >
            Edit Profile
          </button>
        </div>
      ) : null}
    </>
  );
};

export default FollowButton;
