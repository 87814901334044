// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { USER } from "../../constants/localStorageKeys";
import classes from "../../styleSheets/FoodMenu.module.css";
import { Add_Item } from "../Modals Layout/Add-item";
import { ItemOpen } from "./Item-open";
import "../../styleSheets/item-section.css";
import { DndContext, useSensors, useSensor, closestCenter, MouseSensor, TouchSensor, DragOverlay, AutoScrollActivator } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { UseSortableFile } from "../../utils/dnd/useSortableFile";
import { MappedItem } from "./mappedItem";
import { handleDragEnd, handleDragStart, handleDragOver, modifierProp, autoScrollProp } from "../../utils/dnd/handlers";
import { DraggableItem } from "../../utils/dnd/draggableItem";
import { Loader } from "../../utils/Loader/loader";
import { useNavigate } from "react-router-dom";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { MenuFilter } from "./Menu_Filter/menu_filter";
import { menuFilterData } from "./Menu_Filter/filter_handlers";
import { useSelector } from "react-redux";

const ItemSection = (id: any) => {
  const menuAccessState = useSelector(state => state.menuAccess.apiData )
  const [filterStates, setFilterStates] = useState({
    veg: false,
    nonVeg: false,
    egg: false,
    chefRecommend: false,
    "": true,
    na: true,

    // for handling old data:-
    get Veg(){
      return this.veg
    },
    get "Non-Veg"(){
      return this.nonVeg
    },
    get "Contains Egg"(){
      return this.egg
    }
  })
  const navigate = useNavigate()
  const token = localStorage.getItem("accessToken");
  const [itemid, setitemId] = useState();
  const [open, setOpen] = useState(false);
  const [itemData, setitemData] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const name = localStorage.getItem("name");

  const [activeId, setActiveId] = useState(null);
  const [loadingState, setLoadingState] = useState(false)

  const resName = localStorage.getItem(USER.USERNAME);
  const itemSectionDiv = useRef(null)
  const [filterPosition, setFilterPosition] = useState(0)

  // const varietyArr = [
  //   {name: 'Pizza', price: '12'},
  //   {name: 'Burger', price: '123'}

  // ]
  // sample data for testing


  const handleOpen = (id) => {
    setOpen(true);
    setitemId(id);
  };
  const handleClose = () => setOpen(false);

  const callApis = async () => {
    setLoadingImage(true);
 
    // const filteredData = menuFilterData(id?.data?.items, filterStates)

    setitemData(id?.data?.items);
    setLoadingImage(false);
  };


  useEffect(() => {
    const handleResize = () => {
      const divWidth = itemSectionDiv?.current?.offsetWidth;
      setFilterPosition(divWidth)
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    callApis();
  }, [id?.data, filterStates]); 
  
  

  return (
    <>
    <div className=" position-relative" ref={itemSectionDiv}>
      <ItemOpen
        open={open}
        setOpen={setOpen}
        itemData={itemData}
        itemid={itemid}
        // varietyArr={varietyArr}
      />
      {loadingImage ? (
        <div className={classes.center}> Loading...</div>
      ) : (
        <div
        style={{background:"#FFF6F2"}}
        className=" rounded-bottom-4"
        >
          <DndContext
            autoScroll={autoScrollProp}
            modifiers={modifierProp}
            sensors={useSensors(
              useSensor(MouseSensor, {
                activationConstraint: {
                  delay: 500,
                  tolerance: 5
                }
              }),
              useSensor(TouchSensor, {
                activationConstraint: {
                  delay: 500,
                  tolerance: 5
                }
              })
            )}
            onDragStart={(e) => handleDragStart(e, setActiveId)}
            onDragEnd={() => handleDragEnd(setActiveId, id?.data?.items, id?.setWholeData, itemData, "item", navigate, setLoadingState)}
            onDragOver={(e)=>handleDragOver(e, setitemData)}
            collisionDetection={closestCenter}
          >
            <SortableContext items={itemData || []}>
              {itemData?.map((item, index) => {
                if (id.selectedSubSubCatId == item?.subSubCategory_id) {
                  const arr = [];
                  arr.push(item);

                  return (
                    (!menuAccessState) && item.publish == 0 ? null :
                    <UseSortableFile
                      propId={item}
                      MappedEl={<MappedItem
                        index={index}
                        item={item}
                        handleOpen={handleOpen}
                        itemData={itemData}
                        id={id}
                        setitemData={setitemData}
                        callApis={callApis}
                      />}
                    />
                  );
                }

              })}
              <DragOverlay>
                {activeId ? <DraggableItem
                  id={activeId}
                  isDragOverlay={true}
                  MappedEl={<MappedItem
                    isDragOverlay={true}
                    index={itemData.findIndex((item)=> item._id === activeId._id)}
                    item={activeId}
                    handleOpen={handleOpen}
                    itemData={itemData}
                    id={id}
                    setitemData={setitemData}
                    callApis={callApis}
                  />

                  }
                  isDragging />
                  : null
                }
              </DragOverlay>
            </SortableContext>

          </DndContext>
        </div>
      )}
    </div>
    <Loader open={loadingState} setOpen={setLoadingState} message={"Loading...."} />

    </>
  );
};

export default ItemSection;
