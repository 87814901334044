// @ts-nocheck
import React, { useState, useEffect, memo } from "react";
import "../../styleSheets/subcategory-sidebar.css";
import { MappedSubcategory } from "./mappedSubcategory";
import { Loader } from "../../utils/Loader/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubCategorySidebar = (id: any) => {
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const {expanded, setExpanded} = id
  const name = localStorage.getItem("name");
  const newPanelOpen=(panel)=>{
    setTimeout(()=>{
        setExpanded(panel);
      },100
    )
  }
  const element=document.getElementById("subcatdiv");
  const handleChange =
    (panel: string) =>{ 
      if(expanded==panel){
        setExpanded(false)
      }else{
        newPanelOpen(panel)
      }
      element?.scrollIntoView({behavior:"smooth"})
      
    }

  const [id1, setId1] = useState("");
  const [subCat, setSubCat] = useState("");
  const [active, setActive] = useState(false);
  const [subCatData, setSubCatData] = useState([]);
  const newArr = [];
  const [a, setA] = useState("");
  const [loaderOpen, setLoaderOpen] = useState(false);

  // const data = subCategoryAPI(`${baseURL}${MENU_API.MENU}${name}`);

  const callApis = async () => {
    setSubCatData(id?.data?.subSubCategories);
    console.log("subsubsubsubsubusbu", id?.data?.subSubCategories)
  };
  useEffect(() => {
    callApis();
  }, [id?.data]);


  return (
    <div style={{marginBottom:"10rem"}}>
      <div className="mx-3" id="subcatdiv">
        {subCatData?.map((item, index) => {
          if (id?.subcatId == item?.subCategory_id) {
            if (menuAccessState) {
              newArr.push(item?._id);
              if (!a) setA(newArr[0]);
            }
            if (!menuAccessState && item?.publish != 0) {
              newArr.push(item._id);
              if (!a) setA(newArr[0]);
            }

            return !menuAccessState && item.publish == 0 ? null : (
                  <MappedSubcategory
                    index={index}
                    id1={id1}
                    item={item}
                    a={a}
                    setId1={setId1}
                    setActive={setActive}
                    setSubCat={setSubCat}
                    id={id}
                    subCat={subCat}
                    subCatData={subCatData}
                    setSubCatData={setSubCatData}
                    expanded={expanded}
                    handleChange={handleChange}
                    subcatId={id.subcatId}
                  />
            );
          }
        })}
      </div>

      <Loader
        open={loaderOpen}
        setOpen={setLoaderOpen}
        message={"Loading...."}
      />
    </div>
  );
};

export default SubCategorySidebar;
