// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { MuiSwitch } from "./muiSwitch";
import ChefTrueIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_chef_true.svg";
import ChefFalseIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_chef_false.svg";
import CloseIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_close.svg";
import filterIcon from "../../../RestaurantMenu/Images/filtertheme2.svg";
import "../../css/filterStyles.css";

export const MenuFilter = ({ filterStates, setFilterStates }) => {
  const [open, setOpen] = useState(false);

  const filterRef = useRef(null);

  useEffect(() => {
    if (!open) return;
    const handleOutsideClick = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <div
      ref={filterRef}
      className={`div-container2 z-1 rounded-4 ${open ? "collapse_div2" : "open_div2"}`}
      style={{ background: "white" }}
      onClick={() => {
        if (!open) setOpen(true);
      }}
    >
      {open ? (
        <div
          className="div-content text-white"
          style={{
            opacity: open ? 1 : 0,
            transition: open ? "opacity 1s ease" : "opacity 0.1s ease", // Conditional transition time
          }}
        >
          <div className={`d-flex flex-column`} style={{ gap: "10px" }}>
            <MuiSwitch
              thumbColor="#FFB800"
              trackColor="#FFB800"
              borderColor="#D6A336"
              switchState={filterStates.egg}
              setSwitchState={setFilterStates}
              type="egg"
              label="Egg"
            />

            <MuiSwitch
              thumbColor="#03A884"
              trackColor="#6ACAA2"
              borderColor="#1BD760"
              switchState={filterStates.veg}
              setSwitchState={setFilterStates}
              type="veg"
              label="Veg"
            />

            <MuiSwitch
              thumbColor="#E23600"
              borderColor="#FF836A"
              trackColor="#FF8D5F"
              switchState={filterStates.nonVeg}
              setSwitchState={setFilterStates}
              type="nonVeg"
              label="Non-Veg"
            />

            <div
              className="m-auto d-flex"
              onClick={() =>
                setFilterStates((prev) => ({
                  ...prev,
                  chefRecommend: !prev.chefRecommend,
                }))
              }
            >
              {filterStates.chefRecommend ? (
                <img src={ChefTrueIcon} className="ms-1" />
              ) : (
                <img src={ChefFalseIcon} className="ms-1" />
              )}
              <div
                className="ms-2 mb-1 text-black text-center"
                style={{
                  fontSize: "10px",
                  marginBottom: "-5px",
                  marginTop: "5px",
                }}
              >
                Chef-R
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column pt-2">
          <img src={filterIcon} style={{ height: "18.46px", width: "20px" }} />
          <span style={{ fontSize: "0.6rem" }}>filter</span>
        </div>
      )}
    </div>
  );
};
