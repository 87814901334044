// @ts-nocheck
import React, { useState, useEffect } from "react";
import "../styleSheets/adminprofile.css";
import { createSearchParams, useNavigate } from "react-router-dom";
import backarrow from "../assets/icons/Social_icons/backArrow.svg";
import person from "../assets/icons/Social_icons/pencil_noBg.svg";
import solidarrow from "../assets/icons/Social_icons/solidArrow.svg";
import cameraimg from "../assets/icons/Social_icons/cameraimg.svg";
import { MENU_API, baseURL } from "../constants/Api";
import { getbusinessProfiles } from "../API/authAPIs";
import { imgBaseUrl } from "../constants/ImageBaseUrl";
import { checkImageUrl } from "../utils/checkImageUrl";
import { getUserProfile } from "../API/authMenuAPIs";
import { useDispatch, useSelector } from "react-redux";
import { setFastSocialData, setUserDetails } from "../redux/actions";
import CircularUnderLoad from "../Components/CircularLoader";
import loaderStyler from "../styleSheets/loader.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../styleSheets/Arrow.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { USER } from "../constants/localStorageKeys";
import AccessFooter from "../Components/AccessFooter";
import { useMediaQuery, useTheme } from "@mui/material";
import AnalyticsUtil from "../utils/GoogleAnalytics";

const Admin_profile = ({ data }) => {
  const dispatch = useDispatch();
  const fastEnvData = import.meta.env.VITE_FAST_SOCIAL;
  const handelFastsocialFlow = (item, type) => {
    if (type === "user") {
      navigate({
        pathname: "/FastSocial",
        search: createSearchParams({
          profileIdData: item.uuid,
          type: type,
          name: item.userName,
        }).toString(),
      });
    }
    if (fastEnvData !== "false" && type === "business") {
      dispatch(setFastSocialData({}));
      navigate({
        pathname: "/FastSocial",
        search: createSearchParams({
          profileIdData: item.profileId,
          type: type,
          name: item.userName,
        }).toString(),
      });
    }
  };
  const [loader, setLoader] = useState();
  const [profile, setProfile] = useState();
  const [admin, setAdmin] = useState([]);
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down(900));

  // console.log('Hey, I am Dashboard component')
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();
  const [id, setId] = useState("1");
  const isMobile = window.screen.width;
  // console.log(isMobile,profile, "width");
  // window.history.pushState(null, 'page', window.location.href);
  // window.onpopstate = function () {
  //     window.history.go(1);
  const num = localStorage.getItem(USER.MOBILE_NUMBER);
  // console.log(num,'iddd')
  const businessId = localStorage.getItem("businessId");
  const [selectedProfile, setSelectedProfile] = useState("");
  const businessName1 = localStorage.getItem(USER.businessName);
  const businessUserName1 = localStorage.getItem(USER.USERNAME);
  const jwtToken = localStorage.getItem(USER.JWT_ACCESS_TOKEN);
  const [profileType, setProfileType] = useState("");
  const [businessProfileName, setBusinessProfileName] = useState("");
  const [businessUserName, setBusinessUserName] = useState("");
  const userDetailsRedux = useSelector((state) => state.userDetails.apiData);
  // const[loading,setLoading] =useState(false)
  //  const[profile,setProfile]=useState([])

  const callApis = async () => {
    setLoader(true);
    const data = await getbusinessProfiles(
      `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
    );
    console.log("DATA HAI", data);

    const profData = await getUserProfile(
      `${baseURL}${MENU_API.GET_USERS_PROFILE}`,
      navigate,
    );
    setAdmin(localStorage.getItem("userProfile"));

    setProfile(data);

    dispatch(
      setUserDetails({
        businessData: data,
        userDetails: profData,
      }),
    );

    setLoader(false);
    console.log("businessProfiles", data);
    console.log("adminprofile", profData);
  };

  const callApi = async () => {
    // setLoading(true)
    const selectedProfileId = localStorage.getItem(USER.businessId);

    {
      if (selectedProfileId == null || selectedProfileId == "undefined") {
        setBusinessProfileName(profile[0]?.name);
        setBusinessUserName(profile[0]?.userName);
        setSelectedProfile(profile[0]?.profileId);
        setProfileType(profile[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, profile[0]?.userName);
        localStorage.setItem(USER.businessName, profile[0]?.name);
        localStorage.setItem(USER.businessId, profile[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, profile[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    if (Object.keys(userDetailsRedux).length === 0) callApis();
    else {
      setAdmin(userDetailsRedux.userDetails);
      setProfile(userDetailsRedux.businessData);
    }
  }, [userDetailsRedux]);

  useEffect(() => {
    console.log(profile, "profile");
    callApi();
  }, [profile]);
  console.log("user selecteeeeeeeeeee", userDetailsRedux);
  console.log("adminnnnnnnnnn", admin);
  console.log("profieeee", profile);

  // if(!profile || !admin) return

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/adminprofile`, "Adminprofile Page");
  }, []);

  return (
    <>
      <div
        style={{ background: "#f5f5f7", height: "100svh" }}
        className="d-flex justify-content-center"
      >
        {loader ? (
          <div className={loaderStyler.userContainer}>
            <CircularUnderLoad />
          </div>
        ) : (
          <div className="mobile-pc-width position-relative">
            <div
              className="mb-3 d-flex py-3"
              style={{ backgroundColor: "#27343C", color: "white" }}
            >
              <div className="mx-auto">
                <h1
                  className="settings-header-txt text-white"
                  style={{ margin: "0" }}
                >
                  Settings
                </h1>
              </div>
            </div>

            <div className="mx-3">
              <div className="top-bar-user d-flex justify-content-between ">
                User Profile Settings
                <div
                  className="admin_person me-2 align-items-center"
                  onClick={() => navigate("/UpdateProfile")}
                >
                  <img src={person} alt="" />
                </div>
              </div>
              <div className="admin-box  d-flex justify-content-evenly mx-auto position-relative shadow-sm">
                <div
                  className="admin-image-container"
                  style={{ width: "70px", height: "70px" }}
                >
                  {admin?.image ? (
                    <div className="admin-image shadow-sm">
                      {checkImageUrl(admin?.image) === "" ? (
                        <img
                          src={`${admin?.image}`}
                          alt=""
                          className=" admin-image"
                          style={{ width: "70px", height: "70px" }}
                        />
                      ) : (
                        <img
                          src={`${imgBaseUrl}/${admin?.image}`}
                          alt=""
                          className=" admin-image"
                          style={{ width: "70px", height: "70px" }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="">
                      <img
                        src={cameraimg}
                        alt="Camera Image"
                        className="w-100 admin_image"
                      />
                    </div>
                  )}
                </div>
                <div className="heading2 mt-3">
                  <p style={{ margin: 0, marginBottom: "6px" }}>Name:</p>
                  <p style={{ margin: 0, marginBottom: "6px" }}>Username:</p>
                  <p style={{ margin: 0, marginBottom: "6px" }}>
                    Mobile Number:
                  </p>
                </div>
                <div>
                  <div className="contents mt-2">
                    <div className="heading1 ">
                      <p
                        style={{ margin: 0, marginBottom: "6px" }}
                        className=""
                      >
                        {admin?.name}
                      </p>
                      <p
                        style={{ margin: 0, marginBottom: "6px" }}
                        className=""
                      >
                        {admin?.userName}
                      </p>
                      <p
                        style={{ margin: 0, marginBottom: "6px" }}
                        className=""
                      >
                        {admin?.mobileNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" ms-0 d-wrap justify-content-center">
                <div className="w-100 align-items-center">
                  {data &&
                    data.map((data, index) => (
                      <div
                        onClick={() => navigate(data.route)}
                        key={`data-${index}`}
                        className="box my-3 box-admin py-4 px-4"
                      >
                        <div className="d-flex justify-content-between h-100 align-items-center cursor-pointer">
                          <div className="option  txt-box-admin">
                            {data.option}
                          </div>
                          <div>
                            <ArrowForwardIcon className="right-arrow" />
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    className="ms-3 log-out-txt"
                    onClick={() => {
                      localStorage.clear();
                      navigate("/Login");
                    }}
                  >
                    Log Out
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isMatch && (
        <AccessFooter
          customDesign={true}
          businessName={businessName}
          setBusinessName={setBusinessName}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          businessProfileName={businessProfileName}
          setBusinessProfileName={setBusinessProfileName}
          businessUserName={businessUserName}
          setBusinessUserName={setBusinessUserName}
          id={id}
          setId={setId}
          profileType={profileType}
          setProfileType={setProfileType}
          profile={profile}
          setProfile={setProfile}
        />
      )}
    </>
  );
};

export default Admin_profile;
