// @ts-nocheck
import React from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import placeholder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import TruncateMarkup from "react-truncate-markup";
import { checkImageUrl } from "../../utils/checkImageUrl";

export const MappedCategory = ({ item, handleclick }) => {
  return (
    item.publish != 0 && (
      <>
        <div className={classes.categoryContainer}>
          <div
            className="d-flex shadow rounded-4  mb-5"
            style={{ background: "#A3BD83" }}
          >
            <div className={`${classes["category-whole-container"]}`}>
              {item.cloudinary_url ? (
                <>
                  {checkImageUrl(item?.cloudinary_url) == "" ? (
                    <img
                      src={item?.cloudinary_url}
                      className={`${classes.catImage} rounded-4`}
                      onClick={() => handleclick(item._id)}
                    />
                  ) : (
                    <img
                      src={`${imgBaseUrl}/${item?.cloudinary_url}`}
                      className={`${classes.catImage} rounded-4`}
                      onClick={() => handleclick(item._id)}
                    />
                  )}
                </>
              ) : (
                <img
                  className={`${classes.catImage} rounded-4`}
                  src={placeholder}
                  onClick={() => handleclick(item._id)}
                />
              )}
              <div
                className={`${classes.categoryText}`}
                style={{
                  overflowWrap: "break-word",
                  cursor: "default",
                  padding: "10px 0",
                }}
              >
                <TruncateMarkup lines={2}>
                  <span className="fw-bold mx-3">{item?.category}</span>
                </TruncateMarkup>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
