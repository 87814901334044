// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { MuiSwitch } from "./muiSwitch";
import ChefTrueIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_chef_true.svg";
import ChefFalseIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_chef_false.svg";
import CloseIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_close.svg";
import OptionIcon from "../../../assets/icons/svgSpecificIcons/menu_filter_option.svg";
import "../../../styleSheets/menu_filter.css";
import { AnalyticEvents } from "../../../utils/GoogleAnalytics/Events";
import AnalyticsUtil from "../../../utils/GoogleAnalytics";

export const MenuFilter = ({
  filterStates,
  setFilterStates,
  filterPosition,
  analytics,
  setAnalytics,
}) => {
  const [open, setOpen] = useState(false);

  const filterRef = useRef(null);

  useEffect(() => {
    if (!open) return;
    const handleOutsideClick = (event) => {
      console.log(event, "eeeeeeeeeeeeeeeeeeeeee");

      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <div
      ref={filterRef}
      style={{ direction: "ltr", marginBottom: "50px" }}
      className={`div-container menu-filter-container ${open ? "collapse_div" : "open_div"}`}
      onClick={() => {
        if (!open) setOpen(true);
      }}
    >
      {open ? (
        <div
          className="div-content text-white"
          style={{
            opacity: open ? 1 : 0,
            transition: open ? "opacity 1s ease" : "opacity 0.1s ease", // Conditional transition time
          }}
        >
          <div className={`d-flex`} style={{ gap: "10px" }}>
            <MuiSwitch
              thumbColor="#FFB800"
              trackColor="#FFB800"
              borderColor="#D6A336"
              switchState={filterStates.egg}
              setSwitchState={setFilterStates}
              type="egg"
              label="Egg"
              analytics={analytics}
              setAnalytics={setAnalytics}
            />

            <MuiSwitch
              thumbColor="#03A884"
              trackColor="#6ACAA2"
              borderColor="#1BD760"
              switchState={filterStates.veg}
              setSwitchState={setFilterStates}
              type="veg"
              label="Veg"
              analytics={analytics}
              setAnalytics={setAnalytics}
            />

            <MuiSwitch
              thumbColor="#E23600"
              borderColor="#FF836A"
              trackColor="#FF8D5F"
              switchState={filterStates.nonVeg}
              setSwitchState={setFilterStates}
              type="nonVeg"
              label="Non-Veg"
              analytics={analytics}
              setAnalytics={setAnalytics}
            />

            <div
              className="m-auto"
              onClick={() => {
                setFilterStates((prev) => ({
                  ...prev,
                  chefRecommend: !prev.chefRecommend,
                }));
              }}
            >
              {filterStates.chefRecommend ? (
                <img
                  src={ChefTrueIcon}
                  className="ms-1"
                  onClick={() => {
                    analytics.sendEvent(
                      AnalyticEvents.Menu.Filter,
                      AnalyticEvents.MenuAction.Close,
                      "Chef Recommendation Closed",
                    );
                  }}
                />
              ) : (
                <img
                  src={ChefFalseIcon}
                  className="ms-1"
                  onClick={() => {
                    analytics.sendEvent(
                      AnalyticEvents.Menu.Filter,
                      AnalyticEvents.MenuAction.Selected,
                      "Chef Recommendation Opened",
                    );
                  }}
                />
              )}
              <div
                className="text-center"
                style={{
                  fontSize: "10px",
                  marginBottom: "-5px",
                  marginTop: "5px",
                }}
              >
                Chef-R
              </div>
            </div>

            <img
              onClick={() => setOpen(false)}
              src={CloseIcon}
              className="pt-2"
              style={{ marginLeft: "23px" }}
            />
          </div>
        </div>
      ) : (
        <img src={OptionIcon} />
      )}
    </div>
  );
};
