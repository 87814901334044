import { useState, useEffect } from "react";
import Icons from "../../Components/Icons";
import Datepicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { baseURL } from "../../constants/Api";
import noResults from "../../assets/icons/no-results.png";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import deleteIcon from "../../assets/images/delete.svg";
import pencil1 from "../../assets/images/pencil1.svg";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Box, Modal } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const TermsAndConditions = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      "/reservation/terms-and-conditions",
      "ReservationTermsAndCondition Page",
    );
  }, []);

  const [date, setDate] = useState(new Date());

  const [restaurantId, setRestaurantId] = useState("");

  const [tnCClauses, setTnCClauses] = useState([]);
  const [name, setName] = useState("");
  const [editText, setEditText] = useState("");
  const [addText, setAddText] = useState("");
  const [editingClauseId, setEditingClauseId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [expandedItems, setExpandedItems] = useState([]);

  const [modalHeight, setModalHeight] = useState(300);

  const formatDateToYYYYMMDD = (inputDate) => {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const notifyCreated = () => toast("TnC clause added");
  const notifyUpdated = () => toast("TnC clause updated");
  const notifyDeleted = () => toast("TnC clause deleted");

  useEffect(() => {
    setRestaurantId(localStorage.getItem("restaurantId"));

    fetchTnC(localStorage.getItem("restaurantId"));

    fetchProfile(localStorage.getItem("restaurantId"));
  }, []);

  const addTnCClause = async () => {
    try {
      const response = await fetch(`${baseURL}/v1/restaurant/addTnCClause`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ restaurantId, clause: addText }),
      });

      const data = await response.json();
      // Handle success or display any messages from data
      notifyCreated();
      setAddText("");

      fetchTnC(localStorage.getItem("restaurantId"));
    } catch (error) {
      console.error("Error adding clause:", error);
      // Handle error or display error message
    }
  };

  const updateTnCClause = async () => {
    try {
      const response = await fetch(`${baseURL}/v1/restaurant/updateTnCClause`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clauseId: editingClauseId, clause: editText }),
      });
      const data = await response.json();

      notifyUpdated();
      setEditText("");

      fetchTnC(localStorage.getItem("restaurantId"));

      // Handle success or display any messages from data
    } catch (error) {
      console.error("Error updating clause:", error);
      // Handle error or display error message
    }
  };

  const deleteTnCClause = async (clauseId) => {
    try {
      const response = await fetch(`${baseURL}/v1/restaurant/deleteTnCClause`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clauseId }),
      });
      const data = await response.json();

      notifyDeleted();

      fetchTnC(localStorage.getItem("restaurantId"));

      // Handle success or display any messages from data
    } catch (error) {
      console.error("Error deleting clause:", error);
      // Handle error or display error message
    }
  };

  const handleClose = () => setIsEditModalOpen(false);

  const handleCloseAdd = () => setIsAddModalOpen(false);
  const handleCloseDelete = () => setIsDeleteModalOpen(false);

  const handleInputChange = (e) => {
    setEditText(e.target.value);
    adjustInputHeight(e.target);
  };

  const handleAddInputChange = (e) => {
    setAddText(e.target.value);
    adjustInputHeight(e.target);
  };

  const adjustInputHeight = (input) => {
    input.style.height = "auto";
    input.style.height = `${input.scrollHeight}px`;

    setModalHeight(input.scrollHeight + 200);
  };

  const fetchTnC = async (restroId) => {
    if (restroId !== "") {
      try {
        const authToken = localStorage.getItem("accessToken");

        const apiUrl = `${baseURL}/v1/restaurant/getAllTnCClauses/${restroId}`;

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch clauses. Status: ${response.status}`,
          );
        }

        const data = await response.json();
        setTnCClauses(data.tnCClauses || []);
      } catch (error) {
        console.error("Error fetching bookings:", error.message);
      }
    }
  };

  const handleEdit = (clauseId, clauseText) => {
    setEditingClauseId(clauseId);
    setEditText(clauseText);
    setIsEditModalOpen(true);
  };

  const handleDelete = (clauseId) => {
    deleteTnCClause(clauseId);
    setIsDeleteModalOpen(false);
  };

  const handleSaveAdd = () => {
    addTnCClause();

    setIsAddModalOpen(false);
  };

  const handleSaveEdit = () => {
    updateTnCClause();

    setIsEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleExpandCollapse = (clauseId) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(clauseId))
        return prevExpandedItems.filter((id) => id !== clauseId);

      return [...prevExpandedItems, clauseId];
    });
  };

  const openAddNewModal = async () => {
    setIsAddModalOpen(true);
  };

  const fetchProfile = async (restroId) => {
    if (restroId !== "") {
      try {
        const apiUrl = `${baseURL}/v1/restaurant/getRestaurantProfile/${restroId}/`;

        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error(`Failed to fetch slots. Status: ${response.status}`);
        }

        const data = await response.json();

        setName(data.restaurant.name);
      } catch (error) {
        console.error("Error fetching slots:", error.message);
      }
    }
  };

  return (
    <div className="BookingManagementPage" style={{marginBottom: 100}}>
      <div
        className="pageHeading"
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: 30,
        }}
      >
        <span
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#27343C",
            fontWeight: 400,
          }}
        >
          Terms and Conditions by <strong>{name}</strong>
        </span>
      </div>

      <div
        style={{ alignSelf: "flex-start", width: "90%", marginInline: "auto" }}
      >
        {tnCClauses.map((clause) => (
          <div key={clause.id} style={{ marginBottom: 10 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                style={{
                  width: "100%",
                  border: "1px solid #229378",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 5,
                  paddingRight: "1rem",
                }}
                onClick={() => handleExpandCollapse(clause.id)}
              >
                <span
                  style={{
                    color: "#27343C",
                    fontWeight: 400,
                    textAlign: "left",
                    paddingLeft: "0.5rem",
                    whiteSpace:
                      "nowrap" /* Prevents text from wrapping to the next line */,
                    overflow: "hidden" /* Hides any content that overflows */,
                    textOverflow: "ellipsis",
                  }}
                >
                  {clause.clause}
                </span>

                <div
                  style={{
                    textWrap: "nowrap",
                  }}
                >
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(clause.id, clause.clause);
                    }}
                    style={{
                      border: "0px solid #229378",
                      paddingRight: "0.25rem",
                    }}
                  >
                    <CiEdit size={20} style={{ color: "#229378" }} />
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteId(clause.id);
                      setIsDeleteModalOpen(true);
                    }}
                    style={{ border: "0px solid #229378", marginLeft: 5 }}
                  >
                    <img alt="img" src={deleteIcon} style={{ width: 15 }} />
                  </button>
                </div>
              </button>
            </div>
            {expandedItems.includes(clause.id) && (
              <div style={{ marginLeft: 0, paddingTop: 5, fontSize: 13 }}>
                {clause.clause}
              </div>
            )}
          </div>
        ))}
      </div>

      <button
        type="button"
        className="createNewButton"
        onClick={(e) => {
          openAddNewModal();
        }}
        style={{
          border: "0px solid #229378",
          marginTop: 30,
          backgroundColor: "#D1D1D1",
          borderRadius: 50,
          padding: "12px 20px 12px 20px",
          color: "#27343C",
          fontSize: 12,
        }}
      >
        Create New
      </button>

      <div
        className="pageHeading"
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: 30,
        }}
      >
        <span
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#27343C",
            fontWeight: 400,
          }}
        >
          Eatoes TnC
        </span>
      </div>

      <ul
        style={{
          textAlign: "left",
          fontSize: 14,
          color: "#27343C",
          fontWeight: 400,
          width: "95%",
          marginInline: "auto",
        }}
      >
        <li>
          We don't confirm bookings, seating, or deals. Responsibility lies with
          the restaurant.
        </li>
        <li>
          Check directly with the restaurant for information, seating, and
          deals.
        </li>
        <li>
          Changes or cancellations must be made directly from eatoes platform.
        </li>
        <li>Eatoes isn't responsible for reservation changes.</li>
        <li>
          We're not liable for restaurant information accuracy or dining issues.
        </li>
        <li>We may use cookies for better user experience.</li>
        <li>We can change terms without notice."</li>
      </ul>

      <Modal
        open={isEditModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll", top: 100, left: 10, height: modalHeight }}
      >
        <div
          style={{
            height: 350,
            backgroundColor: "#fff",
            width: "95%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 5,
            textAlign: "center",
          }}
        >
          <div style={{ margin: 10, paddingTop: 20 }}>
            <h3 style={{ textAlign: "left", fontSize: 18 }}>
              Updating TnC Clause
            </h3>

            <textarea
              value={editText}
              style={{
                border: "1px solid #7ECAB0",
                height: "auto",
                width: "100%",
                marginTop: 20,
                padding: 15,
                borderRadius: 5,
              }}
              onChange={handleInputChange}
              rows={1}
              onInput={(e) => adjustInputHeight(e.target)}
            />

            <img
              alt="img"
              src={pencil1}
              style={{ width: 22, position: "absolute", top: 46, right: 50 }}
            />
          </div>

          <div style={{ margin: 10 }}>
            <button
              type="button"
              onClick={handleSaveEdit}
              style={{
                border: "0px solid #229378",
                alignSelf: "flex-start",
                marginTop: 30,
                backgroundColor: "#D1D1D1",
                borderRadius: 10,
                padding: "12px 20px 12px 20px",
                color: "#27343C",
                fontSize: 12,
              }}
            >
              Save TnC Clause
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isAddModalOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow: "scroll",
          top: 100,
          left: 5,
          width: "90%",
          height: modalHeight,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 350,
            backgroundColor: "#fff",
            width: "85%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 5,
            textAlign: "center",
          }}
        >
          <div style={{ margin: 10, paddingTop: 20 }}>
            <h3 style={{ textAlign: "left", fontSize: 18 }}>
              Adding TnC Clause
            </h3>

            <textarea
              value={addText}
              placeholder="Type your announcement"
              style={{
                border: "1px solid #7ECAB0",
                height: "auto",
                width: "100%",
                marginTop: 20,
                padding: 15,
                borderRadius: 5,
              }}
              onChange={handleAddInputChange}
              rows={1}
              onInput={(e) => adjustInputHeight(e.target)}
            />

            <img
              alt="img"
              src={pencil1}
              style={{ width: 22, position: "absolute", top: 46, right: 50 }}
            />
          </div>

          <div style={{ margin: 10 }}>
            <button
              type="button"
              onClick={handleSaveAdd}
              style={{
                border: "0px solid #229378",
                alignSelf: "flex-start",
                marginTop: 30,
                backgroundColor: "#D1D1D1",
                borderRadius: 10,
                padding: "12px 20px 12px 20px",
                color: "#27343C",
                fontSize: 12,
              }}
            >
              Save TnC Clause
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow: "scroll",
          top: "30%",
          left: 10,
          height: 150,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 350,
            backgroundColor: "#fff",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 5,
            paddingTop: 20,
            textAlign: "center",
          }}
        >
          <p>Are you sure you want to delete this clause?</p>

          <div>
            <button
              type="button"
              style={{
                backgroundColor: "#08c8cd",
                borderWidth: 0,
                color: "#fff",
                padding: 5,
                borderRadius: 10,
                width: 100,
              }}
              onClick={() => handleDelete(deleteId)}
            >
              Yes
            </button>

            <button
              type="button"
              style={{
                backgroundColor: "red",
                borderWidth: 0,
                color: "#fff",
                padding: 5,
                borderRadius: 10,
                width: 100,
                marginLeft: 10,
              }}
              onClick={() => setIsDeleteModalOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
