import React, { useState, useEffect } from "react";
import styles from "./OptionsMenu.module.css";
import { ADMIN_API, baseURL } from "../constants/Api";

const token = localStorage.getItem("accessToken");
const OptionsMenu = ({
  services,
  profileId,
  /*  deleteProfile, */
  updateServiceStatus,
}) => {
  const [ButtonDM, setButtonDM] = useState(true);
  const [ButtonGR, setButtonGR] = useState(true);
  const [ButtonFS, setButtonFS] = useState(true);
  const [ButtonWB, setButtonWB] = useState(true);

  //digitalMenu
  useEffect(() => {
    if (
      services.digitalMenu.suspend === false &&
      services.digitalMenu.enabled === true
    ) {
      setButtonDM(false);
    } else {
      setButtonDM(true);
    }
  }, [services.digitalMenu.suspend, services.digitalMenu.enabled]);

  // googleTableReservation
  useEffect(() => {
    if (
      services.googleTableReservation.suspend === false &&
      services.googleTableReservation.enabled === true
    ) {
      setButtonGR(false);
    } else {
      setButtonGR(true);
    }
  }, [
    services.googleTableReservation.suspend,
    services.googleTableReservation.enabled,
  ]);

  //fastProfileVerification
  useEffect(() => {
    if (
      services.fastProfileVerification.suspend === false &&
      services.fastProfileVerification.enabled === true
    ) {
      setButtonFS(false);
    } else {
      setButtonFS(true);
    }
  }, [
    services.fastProfileVerification.suspend,
    services.fastProfileVerification.enabled,
  ]);

  //whatsAppBusiness
  useEffect(() => {
    if (
      services.whatsAppBusiness.suspend === false &&
      services.whatsAppBusiness.enabled === true
    ) {
      setButtonWB(false);
    } else {
      setButtonWB(true);
    }
  }, [services.whatsAppBusiness.suspend, services.whatsAppBusiness.enabled]);
  /* const handleDeleteProfile = () => {
    deleteProfile(profileId);
  }; */

  const handleServiceToggle = async (serviceName, currentStatus) => {
    const serviceStatus = currentStatus ? "activate" : "deactivate";
    try {
      const response = await fetch(`${baseURL}${ADMIN_API.ACTIVATE_SERVICE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          profileId,
          serviceName,
          serviceStatus,
        }),
      });
      const data = await response.json();
      if (data.success) {
        // Update the service status locally
        updateServiceStatus(profileId, serviceName, !currentStatus);
      } else {
        console.error(
          `Error ${serviceStatus}ing ${serviceName}:`,
          data.message,
        );
      }
    } catch (error) {
      console.error(`Error ${serviceStatus}ing ${serviceName}:`, error);
    }
  };

  return (
    <div className={`dropdown-menu ${styles.optionsMenu} show`}>
      {/* <button className="dropdown-item" onClick={handleDeleteProfile}>
        Delete Profile
      </button> */}
      <button
        className="dropdown-item"
        onClick={() => handleServiceToggle("googleTableReservation", ButtonGR)}
      >
        {ButtonGR ? "Activate TR" : "Deactivate TR"}
      </button>
      <button
        className="dropdown-item"
        onClick={() => handleServiceToggle("digitalMenu", ButtonDM)}
      >
        {ButtonDM ? "Activate Menu" : "Deactivate Menu"}
      </button>
      <button
        className="dropdown-item"
        onClick={() => handleServiceToggle("fastProfileVerification", ButtonFS)}
      >
        {ButtonFS ? "Activate FS" : "Deactivate FS"}
      </button>
      <button
        className="dropdown-item"
        onClick={() => handleServiceToggle("whatsAppBusiness", ButtonWB)}
      >
        {ButtonWB ? "Activate WB" : "Deactivate WB"}
      </button>
    </div>
  );
};

export default OptionsMenu;
