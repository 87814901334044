// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Right from "../../assets/images/images/RightIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { getbusinessProfiles } from "../../API/authMenuAPIs";
import { MENU_API, baseURL } from "../../constants/Api";
import { USER } from "../../constants/localStorageKeys";
import DoneIcon from "@mui/icons-material/Done";
import { resetAllReduxStates, setApiData } from "../../redux/actions";
import CircularUnderLoad from "../../Components/CircularLoader";
import dgMenu from "../../styleSheets/menuPlans.module.css";
import { Box } from "@mui/material";
import { customizeMUIComponents } from "../../styleSheets/muiCustomStyles";
// import Select from "react-dropdown-select";
import classes from "../../styleSheets/FoodMenu.module.css";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { SwitchProfilePopup } from "../../pages/SwitchProfilePopup";
import toast, { Toaster } from "react-hot-toast";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";

export const BusinessProfileDropdownList = ({
  businessName,
  setBusinessName,
  selectedProfile,
  setSelectedProfile,
  businessProfileName,
  setBusinessProfileName,
  businessUserName,
  setBusinessUserName,
  id,
  profileType,
  openNew,
  setOpenNew,
  setProfileType,
  profile,
  setProfile,
  hideName = false,
  setShowOverlay,
  setShowDiv,
  setProfileUserName,
  analytics,
  setAnalytics,
}) => {
  const { pathname, search } = useLocation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const paramObj = useParams();

  const navigateOnClick = (profileUniqueIdentifier) => {
    navigate(`/${profileUniqueIdentifier}`);
  };

  /*
  const navigateOnClick = (profileUniqueIdentifier) => {
    console.log(profileUniqueIdentifier,paramObj,pathname,"testttttttttttt")
    if (pathname.includes("/menu")){
      localStorage.setItem("menu-back-route", pathname + search);

      navigate(`/edit/menu/${profileUniqueIdentifier}`);
      
    }
      
    if ("userName" in paramObj) {
      navigate(`/${profileUniqueIdentifier}`);
    }
  };*/

  //   var arr=[]
  //  arr.push(profile[0])
  useEffect(() => {
    const callApis = async () => {
      // const data = await getbusinessProfiles(
      //   `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,navigate
      // );
      setLoader(true);
      const selectedProfileId = localStorage.getItem(USER.businessId);
      const data = await getbusinessProfiles(
        `${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`,
        navigate,
      );

      setProfile(data);

      if (selectedProfileId == null || selectedProfileId === "undefined") {
        setBusinessProfileName(data[0]?.name);
        setBusinessUserName(data[0]?.userName);
        setSelectedProfile(data[0]?.profileId);
        setProfileType(data[0]?.profile_type);
        localStorage.setItem(USER.USERNAME, data[0]?.userName);
        localStorage.setItem(USER.businessName, data[0]?.name);
        localStorage.setItem(USER.businessId, data[0]?.profileId);
        localStorage.setItem(USER.PROFILE_TYPE, data[0]?.profile_type);
      } else {
        setBusinessProfileName(localStorage.getItem(USER.businessName));
        setBusinessUserName(localStorage.getItem(USER.USERNAME));
        setSelectedProfile(localStorage.getItem(USER.businessId));
        setProfileType(localStorage.getItem(USER.PROFILE_TYPE));
      }
      setLoader(false);
    };

    callApis();

    // setBusinessProfileName(localStorage.getItem(USER.businessName))
    // setSelectedProfile(localStorage.getItem(USER.businessId))
    // setBusinessUserName(localStorage.getItem(USER.USERNAME))
    // console.log(setProfileType);
  }, [
    navigate,
    setBusinessProfileName,
    setBusinessUserName,
    setSelectedProfile,
    setProfileType,
    setProfile,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // if((!businessProfileName||businessProfileName=='undefined'||businessProfileName==null)||(!businessUserName||businessUserName==(null||'undefined'))||(!selectedProfile||selectedProfile==(null||'undefined')) )
  // {

  // localStorage.setItem(USER.USERNAME,arr[0]?.userName)
  // localStorage.setItem(USER.businessName,arr[0]?.name)
  // localStorage.setItem(USER.businessId,arr[0]?.profileId)
  // setBusinessProfileName(USER.USERNAME,arr[0]?.userName)
  // setSelectedProfile(USER.businessName,arr[0]?.name)
  // setBusinessUserName(USER.businessId,arr[0]?.profileId)

  // }

  const get = localStorage.getItem(USER.USERNAME);
  return (
    <div
      className="inner-div no-padding-here"
      style={{
        width: "100%",
        position: "fixed",
        bottom: "0",
        borderRadius: "50px 50px 0 0",
        cursor: "default",
      }}
    >
      <Box
        className={classes.edit_dish}
        sx={customizeMUIComponents.businessModal}
      >
        <>
          <div
            className="no-scrollbar"
            style={{ maxHeight: "500px", overflow: "auto" }}
          >
            <div>
              {profile?.length === 0 ? (
                <div className={dgMenu.centerDiv}>
                  <CircularUnderLoad />
                </div>
              ) : (
                profile?.map((item, index) => {
                  return (
                    <div
                      key={crypto.randomUUID()}
                      style={{
                        cursor: "pointer",
                        margin: "40px 0",
                        color: "white",
                      }}
                      onClick={async () => {
                        // setProfileUserName(item?.userName)
                        toast(`Switched to ${item?.userName}`);
                        if (setShowDiv && setShowOverlay) {
                          setShowDiv(false);
                          setShowOverlay(false);
                        }
                        setBusinessProfileName(item?.name);
                        setSelectedProfile(item?.profileId);
                        setBusinessUserName(item?.userName);
                        setProfileType(item?.profile_type);
                        localStorage.setItem(USER.USERNAME, item?.userName);
                        localStorage.setItem(USER.businessId, item?.profileId);
                        localStorage.setItem(USER.businessName, item?.name);
                        localStorage.setItem(
                          USER.PROFILE_TYPE,
                          item?.profile_type,
                        );
                        setAnchorEl(null);
                        // dispatch(setApiData({}))
                        // dispatch(resetAllReduxStates())
                        navigate(`/${item?.userName}`);
                        // navigateOnClick(item?.userName);
                        // setOpenNew(true)
                      }}
                      onKeyUp={() => false}
                    >
                      <div
                        style={{
                          margin: "5px 5px 5px 5px",
                          // textAlign: "center",
                          fontSize: "15px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {item?.image ? (
                            checkImageUrl(item?.image) === "" ? (
                              <img
                                src={`${item?.image}`}
                                alt=""
                                className=""
                                style={{ width: "43px", marginRight: "20px" }}
                              />
                            ) : (
                              <img
                                src={`${imgBaseUrl}/${item?.image}`}
                                alt=""
                                style={{ width: "43px", marginRight: "20px" }}
                                className=""
                              />
                            )
                          ) : (
                            <AccountCircleIcon
                              sx={{ fontSize: "43px", marginRight: "20px" }}
                            />
                          )}
                        </div>
                        {item?.profileId === selectedProfile ? (
                          <>
                            <div
                              className="me-auto"
                              onClick={() => {
                                /* analytics.sendEvent(
                                  AnalyticEvents.Profile.Footer,
                                  AnalyticEvents.ProfileAction.clicked,
                                  item.name,
                                ); */ navigate(`/${item?.userName}`);
                              }}
                              onKeyUp={() => false}
                            >
                              {item.name}
                            </div>
                            <div
                              style={{
                                border: "1px solid #7ECAB0",
                                background: "#7ECAB0",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                analytics.sendEvent(
                                  AnalyticEvents.Profile.Footer,
                                  AnalyticEvents.ProfileAction.clicked,
                                  item.name,
                                );
                              }}
                              onKeyUp={() => false}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              className="me-auto"
                              onClick={() => {
                                analytics.sendEvent(
                                  AnalyticEvents.Profile.Footer,
                                  AnalyticEvents.ProfileAction.clicked,
                                  item.name,
                                );
                              }}
                              onKeyUp={() => false}
                            >
                              {item.name}
                            </div>{" "}
                            <div
                              style={{
                                border: "1px solid #7ECAB0",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                analytics.sendEvent(
                                  AnalyticEvents.Profile.Footer,
                                  AnalyticEvents.ProfileAction.clicked,
                                  item.name,
                                );
                              }}
                              onKeyUp={() => false}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
              <div
                style={{
                  margin: "5px 15px 5px 15px",
                  color: "#7ECAB0",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/allprofiles")}
                onKeyUp={() => false}
              >
                Create new account
              </div>
            </div>
            {
              true && (
                // <>{(profile?.length <= 0) ? (
                //   <></>
                // ) : (
                <>
                  {loader && <div> </div>}
                  {!loader && (
                    <>
                      {businessProfileName === "undefined" ||
                      businessProfileName == null ||
                      !profile ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            color: "#27343C",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            fontSize: "16px",
                            cursor: "pointer",
                            // backgroundColor: "#FFFFFF",
                            marginRight: "0px",
                            padding: "0px",
                            borderRadius: "5px",

                            justifyContent: "center",
                            textWrap: "noWrap",
                          }}
                          onClick={handleClick}
                          onKeyUp={() => false}
                        >
                          {!hideName && (
                            <div
                              style={{
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              {businessProfileName}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )

              // )
            }
            {/* </>}  */}
          </div>
        </>
      </Box>
    </div>
  );
};
