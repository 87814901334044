// @ts-nocheck
import { useState, useEffect } from "react";
import { TRANSACTIONS_API } from "../constants/Api";
import { CURRENT_BALANCE_API, baseURL } from "../constants/Api";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { data } from "./TransactionAPI";
const token = localStorage.getItem("accessToken");

export const getbalance = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.wallet;
    return data;
  } catch (err) {
    console.log("menuApi Error", err);
  }
};

export const getExpenses = async (url) => {
  const token = localStorage.getItem("accessToken");
  
  
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.expenses;
    return data;
  } catch (err) {
    console.log("Expenses API error", err);
  }
};
export const getTransactions = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.transactions;
    return data;
  } catch (err) {
    console.log("Transaction Error", err);
  }
};
export const getWalletTransactions = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.transactions;
    return data;
  } catch (err) {
    console.log(" Wallet Transaction Error", err);
  }
};

export const expenseGraph = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.graphData?.data;
    return data;
  } catch (err) {
    console.log("expenseGraph Error", err);
  }
};

export const weeklyExpenseGraph = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body?.graphData?.weekly;
    return data;
  } catch (err) {
    console.log("expenseGraph Error", err);
  }
};

export const getBill = async (url) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    const data = res?.body
    return data;
  } catch (err) {
    console.log("bill Api Error", err);
  }
};