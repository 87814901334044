// @ts-nocheck
import React, {useState, useEffect} from "react";
import _ from "lodash";

export const LabelledInput = ({type, urlPath, wholeData, handleStates}) => {
  const [urlState, setUrlState] = useState("")
  useEffect(()=>{
    setUrlState(_.get(wholeData, urlPath))
  }, [wholeData])

  return (
    <>
        <label
          htmlFor={type}
          className="ms-1 d-flex static-input-link align-items-center bg-white"
          style={{border:"1px solid #7ECAB0"}}
        >
          https://www.{type}.com/
          <input
            type="url"
            style={{ height:"15px", fontSize:"12px", fontWeight:"400", outline:"none", border:"0px", paddingLeft:"0px"}}
            name={"url"}
            placeholder="username"
            id={type}
            value={urlState}
            onChange={(e)=>{
              setUrlState(e.target.value)
              handleStates(e, urlPath)}
            }
          />
        </label>
        <hr/>
    </>
  )
}