// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Form, FormControl } from "react-bootstrap";
import Select from "react-select";

import "../../styleSheets/Modal-Box.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#D1D1D1"
      : state.data.value === "na"
        ? "#6A75CA"
        : state.data.value === "veg"
          ? "#03A884"
          : state.data.value === "nonVeg"
            ? "#E23600"
            : state.data.value === "egg"
              ? "#F5B206"
              : state.data.value === "vegan"
                ? "#0F896E"
                : "transparent",
    color: state.isSelected ? "#1D1D1F" : "white",
    fontSize: "12px",
    whiteSpace: "nowrap",
    boxShadow: "0 0 1px black",

    "&:active": {
      backgroundColor: "#D1D1D1",
      color: "#1D1D1F",
    },
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    minWidth: "70px",
    minHeight: "30px",
    maxHeight: "30px",

    backgroundColor: state?.selectProps?.value?.value === "na"
      ? "#6A75CA"
      : state?.selectProps?.value?.value === "veg"
        ? "#03A884"
        : state?.selectProps?.value?.value === "nonVeg"
          ? "#E23600"
          : state?.selectProps?.value?.value === "egg"
            ? "#F5B206"
            : state?.selectProps?.value?.value === "vegan"
              ? "#0F896E"
              : "transparent",

    color: "white",
    border: "none",
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: "3px",
  }),

  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white'
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: '2px'
  }),
};

const options = [
  { value: "na", label: "NA" },
  { value: "veg", label: "Veg" },
  { value: "nonVeg", label: "Non-Veg" },
  { value: "egg", label: "Egg" },
  { value: "vegan", label: "Vegan" },
];

export const ItemTypeBtns = ({
  forType,
  index,
  setDishType,
  setVarietyInputsState,
  dishType,
  varietyInputsState,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOldData = (type) => {
    if(type == "Veg") return 1
    else if(type == "Non-Veg") return 2
    else if(type == "Contains Egg") return 3
    else -1
  }

  useEffect(() => {
    if (forType === "varieties") {
      let indexObtained = options.findIndex(
        (el) => el.value === varietyInputsState[index]?.type,
      );
      if(indexObtained == -1) indexObtained = handleOldData(varietyInputsState[index]?.type)
      setSelectedOption(options[indexObtained]);
    } else {
      let indexObtained = options.findIndex((el) => el.value === dishType);
      if(indexObtained == -1) indexObtained = handleOldData(dishType)
      setSelectedOption(options[indexObtained]);
    }
  }, [dishType, varietyInputsState]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);

    if (forType === "varieties") {
      setVarietyInputsState((prev) => {
        const newArr = [...prev];
        newArr[index].type = option.value;
        return newArr;
      });
    } else {
      setDishType(option.value);
    }
  };

  return (
    <div className="my-auto select-div">
      <Select
        className="select-main ms-2 mt-1"
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        styles={customStyles}
        isSearchable={false}
      />
      <p className="text-center ps-2 m-0 mt-1 text-white text-nowrap" style={{ fontSize: '9.3px' }}>change dish type</p>
    </div>
  );
};
