//@ts-nocheck
import React, { useState, useRef } from 'react';
import { FaEllipsisV } from 'react-icons/fa'; // For the three dots icon
import profilePic from '../4.png';
import ReportPopup from './ReportPopup'; // Ensure the correct import path

const PostHeader = ({ profilePic, name, date }) => {
  const [isReportOpen, setReportOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleReportClick = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    setReportOpen((prev) => !prev); // Toggle the report popup
  };

  const handleClosePopup = () => {
    setReportOpen(false);
  };

  return (
    <div className="d-flex flex-column p-3" style={{ width: '100%', position: 'relative' }}>
      {/* Top: Profile Picture and Info */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={profilePic}
            alt="Profile"
            className="rounded-circle"
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
          />
          <div className="ms-3">
            <h6 className="mb-0 text-bold">{name}</h6>
            <small className="text-muted">{date}</small>
          </div>
        </div>

        {/* Right: Three Dots Icon */}
        <div className="position-relative">
          <FaEllipsisV
            ref={buttonRef}
            size={20}
            className="text-black"
            style={{ cursor: 'pointer' }}
            onClick={handleReportClick} // Open report popup on click
          />
        </div>
      </div>

      {/* Caption */}
      <p style={{ color: 'black', margin: '10px 0 0', fontSize: '14px', textAlign: 'left' }}>
        I'm fundraising for running the day area marathon with the children.
      </p>

      {isReportOpen && (
        <ReportPopup onClose={handleClosePopup} buttonRef={buttonRef} />
      )}
    </div>
  );
};

// Styles for the backdrop
const backdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
  zIndex: 999, // Make sure it's above other elements
};

export default PostHeader;
