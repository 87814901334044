// @ts-nocheck
import React, { useEffect, useState } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";

import { USER } from "../../constants/localStorageKeys";
import { Grid } from "@mui/material";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import placeholder from "../../RestaurantMenu/Images/placeholders/image.jpeg";
import TruncateMarkup from "react-truncate-markup";
import MenuModal from "../Modals Layout/MenuModal";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { useSelector } from "react-redux";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";
import { toast, Toaster } from "react-hot-toast";

export const MappedCategory = ({
  index,
  item,
  handleclick,
  categoryData,
  setCategoryData,
  isDragging,
  analytics,
  setAnalytics,
  fullData,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image is loaded

  const token = localStorage.getItem("accessToken");
  const resName = localStorage.getItem(USER.USERNAME);
  const name1 = localStorage.getItem("name");
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);
  const toUseImgSrc = item.cloudinary_url
    ? checkImageUrl(item?.cloudinary_url) === ""
      ? encodeURI(item?.cloudinary_url)
      : encodeURI(`${imgBaseUrl}/${item?.cloudinary_url}`)
    : placeholder;

  const currentTime = new Date();

  const isCategoryWorking =
    categoryData[index]?.openTime && categoryData[index]?.closeTime
      ? currentTime >= new Date(categoryData[index]?.openTime) &&
        currentTime <= new Date(categoryData[index]?.closeTime)
      : true;

  const handleDisabled = () => {
    const startTime = new Date(categoryData[index]?.openTime);
    const starthour = startTime.getHours().toString().padStart(2, "0");
    const startminute = startTime.getMinutes().toString().padStart(2, "0");

    const endTime = new Date(categoryData[index]?.closeTime);
    const endhour = endTime.getHours().toString().padStart(2, "0");
    const endminute = endTime.getMinutes().toString().padStart(2, "0");

    toast.success(
      `${item.category} is available from ${starthour}:${startminute} to ${endhour}:${endminute}`,
      {
        position: "top-center",
        autoClose: 5000,
      },
    );
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return menuAccessState ? (
    <div className={`d-flex ${isDragging && "ps-3 pt-3"}`}>
      <div className={classes.categoryContainer}>
        <div
          className={`${!isCategoryWorking ? "opacity-50" : ""} ${
            item.publish === 0 && "opacity-50"
          } ${classes["category-whole-container"]}`}
        >
          <div
            className={classes.catImage}
            onContextMenu={(e) => e.preventDefault()}
            onClick={() => {
              if (isCategoryWorking) {
                handleclick(item._id);
              } else {
                handleDisabled();
              }
              analytics.sendEvent(
                AnalyticEvents.Menu.Category,
                AnalyticEvents.MenuAction.Selected,
                item?.category,
              );
            }}
            style={{
              backgroundImage: `url(${imageLoaded ? toUseImgSrc : placeholder})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={toUseImgSrc}
              alt={item?.category}
              style={{ display: "none" }} // Preload the image invisibly
              onLoad={handleImageLoad}
            />
          </div>
          <div
            className={`${classes.categoryText} ${isDragging && "pb-2 pt-2"} `}
            style={{ overflowWrap: "break-word", cursor: "default" }}
          >
            <TruncateMarkup lines={2}>
              <span>{item?.category}</span>
            </TruncateMarkup>
          </div>
        </div>
      </div>
      {menuAccessState && (
        <MenuModal
          objData={categoryData[index]}
          objName={categoryData[index].category}
          objImage={categoryData[index].cloudinary_url}
          type={"Category"}
          objId={categoryData[index]._id}
          data={categoryData}
          fullData={fullData}
          setData={setCategoryData}
          publish={categoryData[index].publish}
        />
      )}
      <Toaster />
    </div>
  ) : (
    item.publish != 0 && (
      <>
        <div className={classes.categoryContainer}>
          <div
            className={`${classes["category-whole-container"]} ${
              !isCategoryWorking ? "opacity-50" : ""
            }`}
          >
            <div
              className={classes.catImage}
              onClick={() => {
                if (isCategoryWorking) {
                  handleclick(item._id);
                } else {
                  handleDisabled();
                }
                analytics.sendEvent(
                  AnalyticEvents.Menu.Category,
                  AnalyticEvents.MenuAction.Selected,
                  item?.category,
                );
              }}
              style={{
                backgroundImage: `url(${imageLoaded ? toUseImgSrc : placeholder})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img
                src={toUseImgSrc}
                alt={item?.category}
                style={{ display: "none" }}
                onLoad={handleImageLoad}
              />
            </div>
            <div
              className={classes.categoryText}
              style={{ overflowWrap: "break-word", cursor: "default" }}
            >
              <TruncateMarkup lines={2}>
                <span>{item?.category}</span>
              </TruncateMarkup>
            </div>
          </div>
        </div>
        {menuAccessState && (
          <MenuModal
            objData={categoryData[index]}
            objName={categoryData[index].category}
            objImage={categoryData[index].cloudinary_url}
            type={"Category"}
            objId={categoryData[index]._id}
            data={categoryData}
            setData={setCategoryData}
            publish={categoryData[index].publish}
          />
        )}
      </>
    )
  );
};
