// @ts-nocheck
import * as React from "react";
import {useDispatch, useSelector} from "react-redux"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import { renameMediaCategory } from "../API/fast_social_apis";
import { setFastSocialData } from "../redux/actions";
import _ from "lodash"
import { useLocation } from "react-router-dom";
import { GreenButton } from "../Components/Button.style";

function Rename_model({setRename, value, mediaCategoryId }) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true);
  const [input,setInput]=React.useState(value)
  const api = useSelector(state => state.fastSocial.apiData)

  const handleClose = async() => {
    setOpen(false);
    setRename(false)
  };
  const location=useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("accountId");

  const handleData = async() => {
    setOpen(false);
    setRename(false)
    
     //the api is returning the updated data object 
     const dataReceived = await renameMediaCategory(input, mediaCategoryId,state)
     //made a deepclone of current redux using the lodash
     const tempData = _.cloneDeep(api)
     tempData["Media Section"].mediaCategories = dataReceived.responseData
     //updated the data in redux
     dispatch(setFastSocialData(tempData))

  }


  const handelchange=(e)=>{
    
   
    setInput(e.target.value)


  
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ {position: "absolute",
    top: "47.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20rem",
    height: "14.5rem",
    outline: "none",
    p: 4,
    alignItems: "center",
    textAlign: "center",
    }}>
          <input type="text" placeholder="edit name" value={input} className="rounded-2 border-0 p-3" onChange={handelchange} style={{outline:"none",width:"300px", marginLeft:"-22px"}}/>
          <div
        className="d-flex justify-content-center mt-5 pb-5"
        style={customizeMUIComponents.row}
      >
        <GreenButton backgroundColor="D1D1D1"      
          varient="contained"
          sx={customizeMUIComponents.editButton2}
          className="text-black Link-savebutton ms-5"
          onClick={handleData}
        >
          Save
        </GreenButton>
      </div>
        </Box>
      </Modal>
    </div>
  );
}
export default Rename_model;
