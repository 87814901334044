import { Backdrop, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import "../styleSheets/tableReserve/tncmodal.css"

export const TncModal = ({...props}) => {
  const {open, setOpen} = props
  
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 400,
          }}}
    >
      <Fade in={open} timeout={400}>
      <div className="tnc-modal-wrapper pt-5 pb-3 px-4">
        <div>
          This booking is just for reservation purpose. It doesn’t hold rights
          for discounts or priority check in
          <br />
          <br />
          eatoes isn’t responsible for bookings or any changes made by business.
          <br />
          <br />
          If you are unable to make it. Please cancel your booking in advance,
          so that it doesn’t affect your loyalty points.
        </div>

        <div className="tnc-modal-close mt-4 cursor-pointer" onClick={()=>setOpen(false)}>Close</div>
      </div>
      </Fade>
    </Modal>
  );
};
