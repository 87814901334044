// @ts-nocheck

import React, { useState } from "react";
import classes from "../../styleSheets/FoodMenu.module.css";
import foodImage from "../../RestaurantMenu/Images/FoodPlaceholder.jpeg";

import { USER } from "../../constants/localStorageKeys";
import { checkImageUrl } from "../../utils/checkImageUrl";
import { imgBaseUrl } from "../../constants/ImageBaseUrl";
import { useSelector } from "react-redux";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import { AnalyticEvents } from "../../utils/GoogleAnalytics/Events";

export const MappedSubcategory = ({
  index,
  id1,
  item,
  a,
  active,
  setId1,
  setActive,
  setSubCat,
  analytics,
  setAnalytics,
}) => {
  const token = localStorage.getItem("accessToken");
  const resName = localStorage.getItem(USER.USERNAME);
  const name = localStorage.getItem("name");
  const menuAccessState = useSelector((state) => state.menuAccess.apiData);

  // Prepare the image URL or fallback
  const toUseImgSrc = item.cloudinary_url
    ? checkImageUrl(item?.cloudinary_url) == ""
      ? encodeURI(item.cloudinary_url)
      : encodeURI(`${imgBaseUrl}/${item?.cloudinary_url}`)
    : foodImage;

  // States to track image load status
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  // Image load handler
  const handleImageLoad = () => {
    setImageLoaded(true);
    setImageError(false); // Reset imageError if it loads successfully
  };

  // Image error handler
  const handleImageError = () => {
    setImageError(true); // Set error if image fails to load
    setImageLoaded(true); // We can still show fallback
  };

  return (
    <>
      {menuAccessState ? (
        <div
          onContextMenu={(e) => e.preventDefault()}
          style={{ minHeight: "140px" }}
          key={index}
          className={`${
            (id1 == item?._id && id1 != "") || (a == item?._id && !active)
              ? classes.background1
              : classes.background2
          } 
        ${item.publish === 0 && "opacity-50"}`}
        >
          <div
            className={classes.subCatContainer1}
            onClick={() => {
              setId1(item?._id);
              setActive(true);
              setSubCat(item?.category_id);
              analytics.sendEvent(
                AnalyticEvents.Menu.SubCategory,
                AnalyticEvents.MenuAction.Selected,
                item?.subCategory,
              );
            }}
          >
            <div>
              <div>
                {/* Handle Image with onLoad and onError */}
                <img
                  src={toUseImgSrc}
                  alt={item.subCategory}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  className={`img-fluid p-1 rounded-4 mb-2`}
                  style={{
                    background: imageError
                      ? `url(${foodImage})` // Fallback image on error
                      : `none`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    aspectRatio: "1/1",
                    display: imageLoaded ? "block" : "none", // Hide while loading
                  }}
                />
              </div>
            </div>

            <div
              className={`Subcategory-name`}
              style={{ overflowWrap: "anywhere" }}
            >
              {item.subCategory}
            </div>
          </div>
        </div>
      ) : (
        item?.publish != 0 && (
          <div
            onContextMenu={(e) => e.preventDefault()}
            key={index}
            className={`${
              (id1 == item?._id && id1 != "") || (a == item?._id && !active)
                ? classes.background1
                : classes.background2
            }`}
          >
            <div
              className={classes.subCatContainer1}
              onClick={() => {
                setId1(item._id);
                setActive(true);
                setSubCat(item.category_id);
                analytics.sendEvent(
                  AnalyticEvents.Menu.SubCategory,
                  AnalyticEvents.MenuAction.Selected,
                  item?.subCategory,
                );
              }}
            >
              <div>
                <div>
                  {/* Handle Image with onLoad and onError */}
                  <img
                    src={toUseImgSrc}
                    alt={item.subCategory}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    className={`img-fluid p-1 rounded-4 mb-2`}
                    style={{
                      background: imageError
                        ? `url(${foodImage})` // Fallback image on error
                        : `none`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      aspectRatio: "1/1",
                      display: imageLoaded ? "block" : "none", // Hide while loading
                    }}
                  />
                </div>
              </div>

              <div
                className={`Subcategory-name`}
                style={{ overflowWrap: "anywhere" }}
              >
                {item?.subCategory}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
