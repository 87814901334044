// @ts-nocheck
import React, { useEffect, useState } from "react";
import HeaderSection from "../Social_components/HeaderSection";
import ListSection from "../Social_components/ListSection";
import EventSection from "../Social_components/EventSection";
import classes from "../styleSheets/FoodMenu.module.css";
import Contactfasthome from "./Contactfasthome";
import ContactPhotoSection from "./ContactPhotoSection";
import { FastSocialRoleManager } from "../API/fast_social_apis";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFastSocialData, setRoleslData } from "../redux/actions";
import { USER } from "../constants/localStorageKeys";
import Navbar from "../Components/Navbar";
import classe from "../styleSheets/Dashboard.module.css";

import Sidebar from "../Components/Sidebar";
import { HomePageLoader } from "./home_page_loader";
import { whetherToShowContactDetailsOrNot } from "../utils/functionUtils";
import { web_url } from "../constants/web_url";
import { web_name } from "../constants/website_name";
import ServiceCard from "./ServiceCard";
import { Footer } from "../Components/Footer";

import ProfileFooter from "../Components/ProfileFooter";
import StoriesFastSocials from "./Stories";

export const FastSocialHomePage = ({
  event,
  header,
  fastSocialAPiData,
  isLoading,
}) => {
  console.log(fastSocialAPiData, "fast data+++++++");
  const roleData = useSelector((state) => state.roles.apiData);
  const services = fastSocialAPiData?.["Basic Details"]?.account?.services;

  const checkServiceStatus = (services) => {
    if (!services) return false;
    return Object.values(services).some(
      (service) => service.enabled === true && service.suspend === false,
    );
  };

  console.log(roleData, "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL");
  return (
    <div className={`${classes.container1}`}>
      {roleData.responseData === "admin" ? (
        <div
          className={`${classes.container2}`}
          style={{ backgroundColor: "#212328" }}
        >
          <div
            className="Social position-relative"
            style={{ paddingBottom: "80px" }}
          >
            {isLoading ? (
              <HomePageLoader isLoading={isLoading} />
            ) : (
              <>
                <HeaderSection />
                <div
                  className="w-100 d-flex flex-column justify-content-center"
                  style={{ gap: "24px" }}
                >
                  <ListSection />
                  {checkServiceStatus(services) ? (
                    <ServiceCard fastSocialAPiData={fastSocialAPiData} />
                  ) : (
                    ""
                  )}

                  <StoriesFastSocials fastSocialAPiData={fastSocialAPiData} />
                  {fastSocialAPiData?.["Media Section"]?.mediaCategories
                    .length !== 0 && <ContactPhotoSection />}
                  <EventSection events={event} data={header} />
                  {/* //kindly uncomment line no 90-93 in MockData.tsx */}
                  {whetherToShowContactDetailsOrNot(
                    fastSocialAPiData["Contact Details"],
                  ) && <Contactfasthome />}
                </div>
                {/* <a
                href="https://www.eatoes.com/"
                className=" text-decoration-none text-white"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className="align-content-center py-3 my-4"
                  style={{
                    background: "#25282B",
                    fontSize: "17px",
                  }}
                >
                  Created with {web_name}
                </div>
              </a> */}
                <ProfileFooter />
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`${classes.container2}`}
          style={{ backgroundColor: "#212328" }}
        >
          <div
            className="Social position-relative"
            style={{ paddingBottom: "80px" }}
          >
            {isLoading ? (
              <HomePageLoader isLoading={isLoading} />
            ) : (
              <>
                <HeaderSection />
                <div
                  className="w-100 d-flex flex-column justify-content-center"
                  style={{ gap: "24px" }}
                >
                  <ListSection />
                  {checkServiceStatus(services) ? (
                    <ServiceCard fastSocialAPiData={fastSocialAPiData} />
                  ) : (
                    ""
                  )}
                  <StoriesFastSocials fastSocialAPiData={fastSocialAPiData} />
                  {fastSocialAPiData?.["Media Section"]?.mediaCategories
                    .length !== 0 && <ContactPhotoSection />}
                  <EventSection events={event} data={header} />
                  {/* //kindly uncomment line no 90-93 in MockData.tsx */}
                  {whetherToShowContactDetailsOrNot(
                    fastSocialAPiData?.["Contact Details"],
                  ) && <Contactfasthome />}
                </div>
                {/* <a
                href="https://www.eatoes.com/"
                className=" text-decoration-none text-white"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className="align-content-center py-3 my-4"
                  style={{
                    background: "#25282B",
                    fontSize: "17px",
                  }}
                >
                  Created with {web_name}
                </div>
              </a> */}
              </>
            )}
          </div>
          <ProfileFooter />
        </div>
      )}
    </div>
  );
};
